import {Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import { FirebaseOptions, FirebaseAppConfig } from '@firebase/app-types';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    public titleIXdb: AngularFireDatabase;
    public schooldb: AngularFireDatabase;
    // public consoledb: AngularFireDatabase;

    constructor(private db: AngularFireDatabase) {
    }

    public initTitleIXFirebaseApp(config: FirebaseOptions, firebaseAppName: string) {
        this.titleIXdb = this.db;
        /*
                this.titleIXdb = new AngularFireDatabase(config, firebaseAppName);
        */
    }

    public initSchoolFirebaseApp(options: FirebaseOptions, databaseURL: string) {
        const firebaseappconfig: FirebaseAppConfig = new Object;
        firebaseappconfig.name = '';
        firebaseappconfig.automaticDataCollectionEnabled = true;
        const ngzone = new NgZone({enableLongStackTrace: true});

        this.schooldb = new AngularFireDatabase(options,
                                                firebaseappconfig,
                                                databaseURL,
                                                PLATFORM_ID,
                                                ngzone);
    }
}


/*
//      constructor(options: FirebaseOptions, config: FirebaseAppConfig, name: string, databaseURL: string, platformId: Object, zone: NgZone);

export interface FirebaseAppConfig {
  name?: string;
  automaticDataCollectionEnabled?: boolean;
}

constructor(options: FirebaseOptions,
             nameOrConfig: string | FirebaseAppConfig | undefined,
            databaseURL: string,
            platformId: Object,
            zone: NgZone);

import {FirebaseAppConfig} from 'angularfire2';


FirebaseAppConfigToken this was an import in appmodule. i took it out
*/
