import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InterfaceService} from '../../services/interface.service';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-roe-todo',
  templateUrl: './roe-todo.component.html',
  styleUrls: ['./roe-todo.component.css']
})
export class RoeTodoComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  primarycolor: string;
  secondarycolor: string;
  str: string;

  constructor(public is: InterfaceService,
              public admin: AdminService) {
    super(admin, is);
  }

  ngOnInit() {
    super.ngOnInit();
    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();

    if (this.n.ty === 'ROE-shopping') {
      this.str = this.admin.t['shopadd']; // 'Add Shopping List';
    } else if (this.n.ty === 'ROE-notes') {
      this.str = this.admin.t['notesgo'] // 'Go to Notes';
    } else if (this.n.ty === 'ROE-comments') {
      this.str = this.admin.t['threadsgo']; // 'Go to Threads';
    } else {
      this.str = this.admin.t['todoadd'];
    }


  }





}
