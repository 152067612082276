import {Component, Input, OnInit} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';
import {BottomSheetComponent} from './bottom-sheet/bottom-sheet.component';
import {InterfaceService} from '../../services/interface.service';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
    @Input()
    code: string;

    constructor(private bottomSheet: MatBottomSheet,
                private is: InterfaceService) {
    }

    ngOnInit() {
    }

    openBottomSheet(): void {
        this.is.getObject(`Help/Console/${this.code}`).take(1).subscribe(h => {
            this.bottomSheet.open(BottomSheetComponent, {
                data: {help: h}
            });
        });
    }
}

