import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {BehaviorSubject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {InterfaceService} from '../../services/interface.service';

@Component({
    selector: 'app-com-child-dialog',
    templateUrl: './com-child-dialog.component.html',
    styleUrls: ['./com-child-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ComChildDialogComponent implements OnInit, OnDestroy {
    $sub1;
    errmsg: string;

    orgslist: any[];
    org: BehaviorSubject<string | null> = new BehaviorSubject('');
    startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
    endAt: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');

    form = new FormGroup({
        oControl: new FormControl(''),
    });

    get oControl() {
        return this.form.get('oControl');
    }


    constructor(private is: InterfaceService,
                public dialogRef: MatDialogRef<ComChildDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.$sub1 =
            this.is.getBehaviorList(this.startAt, this.endAt, `Community`).subscribe(coms => {
                this.orgslist = coms;
            }
        );
    }

    onCancel() {
        this.dialogRef.close('');
    }

    onSave() {
        this.is.getObject(`Orgs/${this.oControl.value}`).take(1).subscribe(val => {
            if (val && val.parentkey) {
                // todo: must give alert
                this.errmsg = 'This organization already has a parent. You cannot overwrite. If you need help contact Jack@Capptivation.com';
            } else {
                this.dialogRef.close(this.oControl.value);

            }
        });
    }

    updateorgslist(sc) {
        this.org.next(sc);
    }

    search() {
        if (this.oControl.value.length === 1) {
            this.oControl.setValue(this.oControl.value.toUpperCase());
        }
        const q = this.oControl.value;
        this.startAt.next(q);
        this.endAt.next(q + '\uf8ff');
    }

    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
    }

}
