import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './security/login/login.component';
import {LoginStartComponent} from './security/login-start/login-start.component';
import {OrgsComponent} from './capptivation/orgs/orgs.component';
import {ComorgComponent} from './capptivation/comorg/comorg.component';
import {AuthGuard} from './security/auth.guard';
import {OrgProfileComponent} from './org-profile/org-profile.component';
import {AdminListComponent} from './capptivation/admins-list/admin-list.component';
import {ErrmsgComponent} from './capptivation/errmsg/errmsg.component';
import {ImportArticlesComponent} from './capptivation/import/import-articles.component';
import {MakeHolidayComponent} from './capptivation/make-holiday/make-holiday.component';
import {ComParentComponent} from './com-parent/com-parent.component';
import {EditWidgetComponent} from './capptivation/edit-widget/edit-widget.component';
import {PubTopicsComponent} from './capptivation/pub-topics/pub-topics.component';
import {HealthParentComponent} from './health-parent/health-parent.component';
import {CorpParentComponent} from './corp-parent/corp-parent.component';
import {CorpComponent} from './capptivation/corp/corp.component';
import {CaFormsComponent} from './capptivation/ca-forms/ca-forms.component';
import {InvPermsComponent} from './inv-perms/inv-perms.component';
import {InvitesComponent} from './invites/invites.component';
import {InviteCreateComponent} from './invite-create/invite-create.component';
import {InstParentComponent} from './inst-parent/inst-parent.component';
import {LoginInstComponent} from './security/login-inst/login-inst.component';
import {AssetsComponent} from './capptivation/assets/assets.component';
import {BlogComponent} from './blog/blog.component';


/*
    {path: 'covidsurveys',  loadChildren: 'src/app/corp-surveys/corp-surveys.module#CorpSurveysModule'},


    {path: 'newcorp', component: CorpComponent, canActivate: [AuthGuard]},

 */

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'logininst', component: LoginInstComponent},
  {path: 'loginstart', component: LoginStartComponent},
  {path: 'createorg', component: OrgsComponent, canActivate: [AuthGuard]},
  {path: 'createadmin', component: AdminListComponent, canActivate: [AuthGuard]},
  {path: 'newcomorg/com', component: ComorgComponent, canActivate: [AuthGuard]},
  {path: 'newcomorg/corp', component: ComorgComponent, canActivate: [AuthGuard]},
  {path: 'editwidget', component: EditWidgetComponent, canActivate: [AuthGuard]},
  {path: 'errmsg', component: ErrmsgComponent, canActivate: [AuthGuard]},
  {path: 'caforms', component: CaFormsComponent, canActivate: [AuthGuard]},
  {path: 'import', component: ImportArticlesComponent, canActivate: [AuthGuard]},
  {path: 'archive', component: AssetsComponent, canActivate: [AuthGuard]},
  {path: 'createad', component: InvitesComponent, canActivate: [AuthGuard]},
  {path: 'create', component: InviteCreateComponent, canActivate: [AuthGuard]},
  {path: 'blogs', component: BlogComponent, canActivate: [AuthGuard]},
  {path: 'topics', component: PubTopicsComponent, canActivate: [AuthGuard]},
  {path: 'holiday', component: MakeHolidayComponent, canActivate: [AuthGuard]},

  {path: 'invperms', component: InvPermsComponent, canActivate: [AuthGuard]},

  {path: 'instparent', component: InstParentComponent, canActivate: [AuthGuard]},
  {path: 'instprofile', loadChildren: 'src/app/inst-profile/inst-profile.module#InstProfileModule'},
  {path: 'exhibits/inst', loadChildren: './org-exhibits/org-exhibits.module#OrgExhibitsModule'},
  {path: 'events/inst', loadChildren: './org-events/org-events.module#OrgEventsModule'},
  {path: 'comstorage/inst', loadChildren: './com-storage/com-storage.module#ComStorageModule'},
  {path: 'content/inst', loadChildren: './content/content.module#ContentModule'},
  {path: 'topics/inst', loadChildren: './org-topics/org-topics.module#OrgTopicsModule'},
  {path: 'roplus/inst', loadChildren: './ro-plus/ro-plus.module#RoPlusModule'},


  {path: 'corpparent', component: CorpParentComponent, canActivate: [AuthGuard]},
  {path: 'covidcom', loadChildren: 'src/app/corp-note-center/corp-note-center.module#CorpNoteCenterModule'},
  {path: 'covidnodes', loadChildren: 'src/app/covid-nodes/covid-nodes.module#CovidNodesModule'},
  {path: 'corptemplates', loadChildren: 'src/app/corp-note-shell/corp-note-shell.module#CorpNoteShellModule'},
  {path: 'covidadmins', loadChildren: 'src/app/corp-admins/corp-admins.module#CorpAdminsModule'},
  {path: 'pubtopics', loadChildren: 'src/app/pub-topics/pub-topics.module#PubTopicsModule'},
  {path: 'guides/corp', loadChildren: './org-guides/org-guides.module#OrgGuidesModule'},
  {path: 'surveys/corp', loadChildren: './org-surveys/org-surveys.module#OrgSurveysModule'},
  {path: 'covidemployees', loadChildren: 'src/app/corp-employees/corp-employees.module#CorpEmployeesModule'},
  {path: 'corpuse', loadChildren: 'src/app/corp-use/corp-use.module#CorpUseModule'},
  {path: 'covidcasemgt', loadChildren: 'src/app/corp-casemgt/corp-casemgt.module#CorpCasemgtModule'},

  {path: 'healthparent', component: HealthParentComponent, canActivate: [AuthGuard]},
  {path: 'hccalendar', loadChildren: 'src/app/hc-calendar/hc-calendar.module#HcCalendarModule'},
  {path: 'hcresults', loadChildren: 'src/app/hc-results/hc-results.module#HcResultsModule'},
  {path: 'hcsetup', loadChildren: 'src/app/hc-setup/hc-setup.module#HcSetupModule'},
  {path: 'hcresources', loadChildren: 'src/app/hc-resources/hc-resources.module#HcResourcesModule'},
  {path: 'hcinter', loadChildren: 'src/app/hc-inter/hc-inter.module#HcInterModule'},
  {path: 'hcadmins', loadChildren: 'src/app/hc-admins/hc-admins.module#HcAdminsModule'},
  {path: 'hccom', loadChildren: 'src/app/hc-notifications/hc-notifications.module#HcNotificationsModule'},

  {path: 'comparent', component: ComParentComponent, canActivate: [AuthGuard]},
  {path: 'compartners', loadChildren: './com-partners/com-partners.module#ComPartnersModule'},
  {path: 'comlocations', loadChildren: './com-profile/com-profile.module#ComProfileModule'},
  {path: 'comprograms', loadChildren: './com-programs/com-programs.module#ComProgramsModule'},
  {path: 'compeople', loadChildren: './com-people/com-people.module#ComPeopleModule'},
  {path: 'comevents', loadChildren: './com-events/com-events.module#ComEventsModule'},
  {path: 'exhibits/community', loadChildren: './org-exhibits/org-exhibits.module#OrgExhibitsModule'},
  {path: 'comcontact', loadChildren: './com-contact/com-contact.module#ComContactModule'},
  {path: 'comstorage/community', loadChildren: './com-storage/com-storage.module#ComStorageModule'},
  {path: 'comservicearea', loadChildren: './com-servicearea/com-servicearea.module#ComServiceareaModule'},
  {path: 'comlists', loadChildren: './com-list/com-list.module#ComListModule'},
  {path: 'comadmins', loadChildren: './com-marketing/com-marketing.module#ComMarketingModule'},
  {path: 'guides/com', loadChildren: './org-guides/org-guides.module#OrgGuidesModule'},
  {path: 'surveys/com', loadChildren: './org-surveys/org-surveys.module#OrgSurveysModule'},
  {path: 'connect', loadChildren: './com-connect/com-connect.module#ComConnectModule'},
  {path: 'comims', loadChildren: './com-ims/com-ims.modules#ComImsModule'},

  {path: 'orgchat', loadChildren: './org-comscenter/org-comscenter.module#OrgComscenterModule'},
  {path: 'usersshell', loadChildren: './users-shell/users-shell.module#UsersShellModule'},
  {path: 'profile', component: OrgProfileComponent, canActivate: [AuthGuard]},
  {path: 'rosurvivor', loadChildren: './ro-survivor/ro-survivor.module#RoSurvivorModule'},
  {path: 'legacy', loadChildren: './ro-legacy/ro-legacy.module#RoLegacyModule'},
  {path: 'topics/orgs', loadChildren: './org-topics/org-topics.module#OrgTopicsModule'},
  {path: 'roplus/orgs', loadChildren: './ro-plus/ro-plus.module#RoPlusModule'},
  {path: 'rob', loadChildren: './ro-rob/ro-rob.module#RoRobModule'},
  {path: 'map', loadChildren: './org-map/org-map.module#OrgMapModule'},
  {path: 'notification', loadChildren: './lib/notification/notification.module#NotificationModule'},
  {path: 'exhibits/orgs', loadChildren: './org-exhibits/org-exhibits.module#OrgExhibitsModule'},
  {path: 'gsp', loadChildren: './org-gsp/org-gsp.module#OrgGspModule'},
  {path: 'faq', loadChildren: './org-faq/org-faq.module#OrgFaqModule'},
  {path: 'resumes', loadChildren: './org-resume/org-resume.module#OrgResumeModule'},
  {path: 'evwidget', loadChildren: './lib/event-widget/event-widget.module#EventWidgetModule'},
  {path: 'events/orgs', loadChildren: './org-events/org-events.module#OrgEventsModule'},
  {path: 'directory', loadChildren: './lib/directory/directory.module#DirectoryModule'},
  {path: 'searchwidget', loadChildren: './lib/search-widget/search-widget.module#SearchWidgetModule'},
  {path: 'guides/org', loadChildren: './org-guides/org-guides.module#OrgGuidesModule'},
  {path: 'reports', loadChildren: './org-reports/org-reports.module#OrgReportsModule'},
  {path: 'surveys/org', loadChildren: './org-surveys/org-surveys.module#OrgSurveysModule'},
  {path: 'cares', loadChildren: './org-cares/org-cares.module#OrgCaresModule'},
  {path: 'incidents', loadChildren: './org-incidents/org-incidents.module#OrgIncidentsModule'},
  {path: 'lms', loadChildren: './org-lms/org-lms.module#OrgLmsModule'},
  {path: 'tandc', loadChildren: './org-tandc/org-tandc.module#OrgTandcModule'},
  {path: 'orgpartners', loadChildren: './org-partners/org-partners.module#OrgPartnersModule'},
  {path: 'comstorage/orgs', loadChildren: './com-storage/com-storage.module#ComStorageModule'},
  {path: 'admins', loadChildren: './org-marketing/org-marketing.module#OrgMarketingModule'},
  {path: 'shopping', loadChildren: './org-shopping-cart/org-shopping-cart.module#OrgShoppingCartModule'},
  {path: 'resources', loadChildren: './orgs-resources/orgs-resources.module#OrgsResourcesModule'},
  {path: 'comrec', loadChildren: './org-com/org-com.module#OrgComModule'},

  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

// {path: 'comdashboard', component: CommunityComponent},
// {path: 'comparent', loadChildren: './com-parent/com-parent.module#ComParentModule'},

