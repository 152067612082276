import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {CAFormUse, CAMolyInForm} from '../../services/ca';
import {animate, group, query, state, style, transition, trigger} from '@angular/animations';

export const left = [
  query(':enter, :leave', style({position: 'absolute', width: '100%'}), {optional: true}),
  group([
    query(':enter', [style({transform: 'translateX(-100%)'}), animate('.3s ease-out', style({transform: 'translateX(0%)'}))], {
      optional: true,
    }),
    query(':leave', [style({transform: 'translateX(0%)'}), animate('.3s ease-out', style({transform: 'translateX(100%)'}))], {
      optional: true,
    }),
  ]),
];

export const right = [
  query(':enter, :leave', style({position: 'absolute', width: '100%'}), {optional: true}),
  group([
    query(':enter', [style({transform: 'translateX(100%)'}), animate('.3s ease-out', style({transform: 'translateX(0%)'}))], {
      optional: true,
    }),
    query(':leave', [style({transform: 'translateX(0%)'}), animate('.3s ease-out', style({transform: 'translateX(-100%)'}))], {
      optional: true,
    }),
  ]),
];

@Component({
  selector: 'app-ca-formgen',
  templateUrl: './ca-formgen.component.html',
  styleUrls: ['./ca-formgen.component.scss'],
  animations: [
    trigger('animImageSlider', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false <=> true', [ animate(150) ])
    ])
  ]
})
export class CaFormgenComponent implements OnInit {
  @Input()
  curformuse: CAFormUse;

  @Input()
  molysin: CAMolyInForm[];

  @Input()
  primarycolor = '#0000FF';

  @Input()
  secondarycolor = '#ffffff';

  // todo: cludge
  indivukey = '-M90vORAvGP9bq37sK-v';

  counter = -2;
  editting = false;
  curlang = 'en';

  constructor(public admin: AdminService) { }

  ngOnInit() {
  }

  onSaveFormMoly(event, m) {
    // todo: indivukey is a cludge
    // this.is.setObjNoLog(`Individual/-M90vORAvGP9bq37sK-v/forms/${this.curformuse.ukey}/${m.moly.ukey}`, {data: event});

    // todo: somehow have to keep track of how much of the form is complete
  }


  onEditing(event) {
    this.editting = event;
  }

}
