import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import {BlockString, ComOrg, InterfaceService, LangStringBlock} from '../services/interface.service';
import {FirebaseService} from '../services/firebase.service';
import {AdminService} from '../services/admin.service';
import {TranslateService} from '../services/translate.service';
import {ComOrgTypes, Icons, Languages, TimeZones} from '../services/constants';



@Component({
    selector: 'app-comparent',
    templateUrl: './com-parent.component.html',
    styleUrls: ['./com-parent.component.scss']
})
export class ComParentComponent implements OnInit, OnDestroy {
    primarycolor = '#99add0';
    secondarycolor = '#000000';

    haveLogo: boolean;
    logoFile: File;
    curLogo: string;
    icons = Icons;
    icon: string;
    trans = false;
    editorContent: string;
    headContent: string;
    languages = Languages;
    tz = TimeZones;
    curlang: string;
    curlanguage: string;
    comorgtypes = ComOrgTypes;
    abbrContent: string;

    regulardays: any[];
    twentfourseven: boolean;
    tmporgtype = '';
    tmpstate = '';
    tmpcntry = '';


    $sub1;
    $sub2;
    $sub3;
    $sub4;

    fileTypes = [
        'image/jpeg',
        'image/pjpeg',
        'image/png'
    ];

    form = new FormGroup({
        name: new FormControl(''),
        active: new FormControl(''),
        emergency: new FormControl(''),
        emailextentions: new FormControl(''),
        religiousaffiliation: new FormControl(''),
        orgtype: new FormControl(''),
        timezone: new FormControl(''),
        publickey: new FormControl(''),
    });


    get publickey() {
        return this.form.get('publickey');
    }

    get timezone() {
        return this.form.get('timezone');
    }

    get emailextentions() {
        return this.form.get('emailextentions');
    }

    get religiousaffiliation() {
        return this.form.get('religiousaffiliation');
    }

    get orgtype() {
        return this.form.get('orgtype');
    }

    get active() {
        return this.form.get('active');
    }

    get emergency() {
        return this.form.get('emergency');
    }

    get name() {
        return this.form.get('name');
    }

    setValue(tmp: ComOrg) {
        if (!tmp.langDesc || !tmp.langDesc[this.curlang]) {
            tmp.langDesc = new LangStringBlock();
            this.languages.forEach(l => {
                tmp.langDesc[l.abbr] = new BlockString();
                tmp.langDesc[l.abbr].description = '';
                tmp.langDesc[l.abbr].block = false;
            });
        }

        if (!tmp.langHead || !tmp.langHead[this.curlang]) {
            tmp.langHead = new LangStringBlock();
            this.languages.forEach(l => {
                tmp.langHead[l.abbr] = new BlockString();
                tmp.langHead[l.abbr].description = '';
                tmp.langHead[l.abbr].block = false;
            });
        }

        if (!tmp.abbrDesc || !tmp.abbrDesc[this.curlang]) {
            tmp.abbrDesc = new LangStringBlock();
            this.languages.forEach(l => {
                tmp.abbrDesc[l.abbr] = new BlockString();
                tmp.abbrDesc[l.abbr].description = '';
                tmp.abbrDesc[l.abbr].block = false;
            });
        }

        if (!tmp.publickey) {
            tmp.publickey = '';
        }

        this.abbrContent = tmp.abbrDesc[this.curlang].description;

        this.editorContent = tmp.langDesc[this.curlang].description;
        this.headContent = tmp.langHead[this.curlang].description;

        this.icon = tmp.icon;

        this.form.setValue({
            name: tmp.orglegacy.name,
            active: tmp.active,
            emergency: tmp.disaster,
            emailextentions: tmp.emailextentions,
            religiousaffiliation: tmp.religiousaffiliation,
            orgtype: tmp.orgtype,
            timezone: tmp.timezone,
            publickey: tmp.publickey,
        });
    }

    constructor(public is: InterfaceService,
                private ts: TranslateService,
                private fs: FirebaseService,
                public dialog: MatDialog,
                public admin: AdminService) {
        this.curlang = this.admin.language;
        this.curlanguage = this.admin.fulllanguage;

    }

    makeDate(d) {
        return new Date(d);
    }

    ngOnInit() {
        if (this.is.curComOrg) {
            // this.is.setObjNoLog(`FakeCom/${this.is.curComOrg.ukey}`, this.is.curComOrg)
            this.tmporgtype = this.is.curComOrg.orgtype;
            if (this.is.curComOrg.locations && this.is.curComOrg.locations[0] && this.is.curComOrg.locations[0].state) {
                this.tmpstate = this.is.curComOrg.locations[0].state;
            }

            if (this.is.curComOrg.locations && this.is.curComOrg.locations[0] && !this.is.curComOrg.locations[0].name) {
                this.is.curComOrg.locations[0].name = this.is.curComOrg.ukey;
                this.is.setObjNoLog(`Community/${this.is.curComOrg.ukey}/locations/0/name`, this.is.curComOrg.ukey)
            }
            if (this.is.curComOrg.icon) {
                this.icon = this.is.curComOrg.icon;
            } else {
                this.icon = '';
            }
            this.haveLogo = false;

            if (this.is.curComOrg.orglegacy.photo) {
                this.curLogo = this.is.curComOrg.orglegacy.photo;
            } else {
                this.curLogo = './assets/uploadlogo.png';
                this.haveLogo = false;
            }

            this.setValue(this.is.curComOrg);
            this.primarycolor = this.is.curComOrg.orglegacy.primarycolor;
            this.secondarycolor = this.is.curComOrg.orglegacy.secondarycolor;

        } else {
            this.$sub1 = this.is.getObjectFS(`Community/${this.admin.admin.org}`).subscribe(org => {
                this.is.curComOrg = org;
                this.is.getCappGuides();
                this.tmporgtype = this.is.curComOrg.orgtype;

                this.setValue(this.is.curComOrg);
                this.primarycolor = this.is.curComOrg.orglegacy.primarycolor;
                this.secondarycolor = this.is.curComOrg.orglegacy.secondarycolor;

                if (this.is.curComOrg.icon) {
                    this.icon = this.is.curComOrg.icon;
                } else {
                    this.icon = '';
                }

                this.haveLogo = false;

                if (this.is.curComOrg.orglegacy.logo) {
                    this.curLogo = this.is.curComOrg.orglegacy.logo;
                } else {
                    this.curLogo = './assets/upload200.png';
                }
            });
        }

        this.is.getTrans();
    }


    onSave() {
        this.is.curComOrg.religiousaffiliation = this.religiousaffiliation.value;
        this.is.curComOrg.orgtype = this.orgtype.value;

        this.is.curComOrg.langDesc[this.curlang].description = this.editorContent;
        this.is.curComOrg.langHead[this.curlang].description = this.headContent;
        this.is.curComOrg.abbrDesc[this.curlang].description = this.abbrContent;

        this.is.curComOrg.orglegacy.name = this.name.value;
        this.is.curComOrg.active = this.active.value;
        this.is.curComOrg.disaster = this.emergency.value;
        this.is.curComOrg.timezone = this.timezone.value;
        this.is.curComOrg.publickey = this.publickey.value;
        this.is.curComOrg.icon = this.icon;
        this.is.curComOrg.lastupdate = new Date().getTime();

        if (this.curLogo) {
            this.is.curComOrg.orglegacy.photo = this.curLogo;
        } else {
            this.is.curComOrg.orglegacy.photo = '';
        }
        this.is.curComOrg.icon = this.icon;

        if (this.is.curComOrg.emailextentions !== this.emailextentions.value) {
            const earr = this.is.curComOrg.emailextentions.split(',');
            if (earr && earr.length > 0) {
                earr.forEach(e => {
                    const str = e.replace('.', '+');
                    this.is.deleteObjNoLog(`Domains/${this.is.curComOrg.ukey}${str}/email`);
                });
            }

            const xarr = this.emailextentions.value.split(',');
            if (xarr && xarr.length > 0) {
                xarr.forEach(e => {
                    const str = e.replace('.', '+');
                    this.is.setObjNoLog(`Domains/${this.is.curComOrg.ukey}${str}`, {email: str, org: this.is.curComOrg.ukey});
                });
            }
            this.is.getCappGuides();
        }
        this.is.curComOrg.emailextentions = this.emailextentions.value;




        // NEW
        this.is.setObjFS(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg);
        // end of new

        let a = '';
        let b = '';
        if (this.tmporgtype === `Not-for-profit State` || this.tmporgtype === 'Government State') {
            a = 'state';
        }
        if (this.is.curComOrg.orgtype === `Not-for-profit State` || this.is.curComOrg.orgtype === 'Government State') {
            b = 'state';
        }
        if (this.tmporgtype === `Not-for-profit National` || this.tmporgtype === 'Government National') {
            a = 'national';
        }
        if (this.is.curComOrg.orgtype === `Not-for-profit National` || this.is.curComOrg.orgtype === 'Government National') {
            b = 'national';
        }
        if (this.tmporgtype === `Not-for-profit International` ) {
            a = 'inter';
        }
        if (this.is.curComOrg.orgtype === `Not-for-profit International`) {
            b = 'inter';
        }

        if (a !== b) {
            if (a === 'state') {
                // todo: delete everything from state
                this.is.setObjNoLog(`LegacyComLookup/${this.is.curComOrg.locations[0].state}/${this.is.curComOrg.orglegacyextra.kwabid}`, this.is.curComOrg.ukey);
            }

            if (a === 'national') {
                // todo: delete everything from nationaal
            }

            if (a === 'international') {
                // todo: delete everything from international
            }

            if (b === 'state') {
                this.is.setObjNoLog(`LegacyComLookup/${this.is.curComOrg.locations[0].state}/${this.is.curComOrg.orglegacyextra.kwabid}`, this.is.curComOrg.ukey);
            }

            if (b === 'national') {
                this.is.setObjNoLog(`LegacyComLookup/${this.is.curComOrg.locations[0].country}/${this.is.curComOrg.orglegacyextra.kwabid}`, this.is.curComOrg.ukey);

            }

            if (b === 'international') {
                this.is.setObjNoLog(`LegacyComLookup/intl/${this.is.curComOrg.orglegacyextra.kwabid}`, this.is.curComOrg.ukey);
            }
        }
    }


    onLangChange(i) {
        this.curlanguage = this.languages[i].desc;
        const str = this.languages[i].abbr;

        this.is.curComOrg.langHead[this.curlang].description = this.headContent;
        this.headContent = this.is.curComOrg.langHead[str].description;

        this.is.curComOrg.langDesc[this.curlang].description = this.editorContent;
        this.editorContent = this.is.curComOrg.langDesc[str].description;

        this.curlang = str;
    }


    async onTransEl(desc) {
        await this.languages.forEach(lang => {
            if (lang.abbr !== 'en' && desc && desc['en'] && desc['en'].description) {
                this.admin.postTrans(lang.abbr, desc['en'].description).then(val => {
                    desc[lang.abbr].description = val['data'].translations[0].translatedText;
                });
            }
        });


    }

    onTranslate() {
        this.is.curComOrg.langDesc[this.curlang].description = this.editorContent;
        this.is.curComOrg.langHead[this.curlang].description = this.headContent;
        this.is.curComOrg.abbrDesc[this.curlang].description = this.abbrContent;

        if (this.is.curComOrg.langDesc[this.curlang].description && this.is.curComOrg.langDesc[this.curlang].description.length > 0) {
            this.onTransEl(this.is.curComOrg.langDesc);
        }

        if (this.is.curComOrg.langHead[this.curlang].description && this.is.curComOrg.langHead[this.curlang].description.length > 0) {
            this.onTransEl(this.is.curComOrg.langHead);
        }

        if (this.is.curComOrg.abbrDesc[this.curlang].description && this.is.curComOrg.abbrDesc[this.curlang].description.length > 0) {
            this.onTransEl(this.is.curComOrg.abbrDesc);
        }

        if (this.is.curComOrg.locations && this.is.curComOrg.locations.length > 0) {
            this.is.curComOrg.locations.forEach(loc => {
                this.onTransEl(loc.langDesc);
            });
        }


        if (this.is.curComOrg.programs && this.is.curComOrg.programs.length > 0) {
            this.is.curComOrg.programs.forEach(pro => {

                this.onTransEl(pro.langDesc);

                this.onTransEl(pro.nameDesc);
            });
        }

        setTimeout(() => {
            this.is.curComOrg.lasttrans = new Date().getTime();
            // NEW
            this.is.setObjFS(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg);
            // END NEW

//          OLD
//          this.is.setObj(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg, 'Translate', ``);
        }, 3000);

    }


    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
        if (this.$sub2) {
            this.$sub2.unsubscribe();
        }
        if (this.$sub3) {
            this.$sub3.unsubscribe();
        }
        if (this.$sub4) {
            this.$sub4.unsubscribe();
        }
    }

}
