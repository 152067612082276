import {Injectable} from '@angular/core';
import {PhoneObj} from '../../services/admin.service';

export const CirclePng = 'http://ron.capptivation.com/assets/emojis/emojis/emoji-circle.png';
export const OctagonPng = 'http://ron.capptivation.com/assets/emojis/emojis/emoji-octagon.png';
export const ConfidentialPng = 'http://ron.capptivation.com/assets/emojis/emojis/emoji-confidential.png';


@Injectable({
    providedIn: 'root'
})
export class CardsServiceService {

    constructor() {
    }

    makeCityLine(off) {
        let str = off.city;

        if (str.length > 0 && off.state) {
            str = str + ', ' + off.state + ' ';
        }

        if (off.state) {
            str = str + off.state;
        }
    }

    public makeName(staff) {
        let str = staff.fname;

        if (str && staff.mname) {
            str = str + ' ' + staff.mname;
        } else if (staff.mname) {
            str = staff.mname;
        }

        if (str && staff.lname) {
            str = str + ' ' + staff.lname;
        } else if (staff.lname) {
            str = staff.lname;
        }

        if (str && staff.zname) {
            str = str + ' ' + staff.zname;
        } else if (staff.zname) {
            str = staff.zname;
        }

        return str;
    }

    makePhone(p: PhoneObj) {
        let
            str = '';

        if (p.phdescription) {
            str = p.phdescription;
        }

        if (str.length > 0) {
            str = str + ': ';
        }

        if (p.digits) {
            str = str + p.digits;
        }

        return str;
    }

    makePhoneNumberList(off) {
        let str = '';

        if (off) {
            off.forEach(p => {
                const pst = this.makePhone(p);

                if (str.length > 0) {
                    str = str + '<br>';
                }

                str = str + pst;
            })
            ;
        }
        return str;
    }

    makeBuildingString(off) {
        let str = off.location;

        if (str && off.street1) {
            str = str + ', ' + off.street1;
        } else {
            str = off.street1;
        }
        return str;
    }
}
