import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InviteService} from '../services/invite.service';
import {AdminService} from '../services/admin.service';
import {RoehttpService} from '../services/roehttp.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {DomSanitizer} from '@angular/platform-browser';
import {FirebaseService} from '../services/firebase.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {
  Asset,
  Box,
  Fav,
  GuideMap,
  InterfaceService,
  ItineraryDetails,
  Key,
  PlaceLoc,
  Privacy,
  Tag,
  Topix,
  Wish
} from '../services/interface.service';
import * as geohash from 'ngeohash';
import * as _ from 'lodash';
import html2canvas from 'html2canvas';
import {MatSnackBar} from '@angular/material';
import {MapboxService} from '../services/mapbox.service';


declare var google: any;


const autoComleteFields = [
  'address_components',
  'formatted_address',
  'name',
  'place_id',
  'geometry',
  'utc_offset_minutes',
  'photos',
  'website'
];

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit, OnDestroy {
  imageChangedEvent: any = '';
  img;
  filename: string;

  indivukey = '-M7seJGDqVSWJndmt754';
  topicukey = '197travelstamps';
  blogger = 'Rodger Jones';
  deviceId = 1003663;

  topix: Topix;
  public: boolean;

  curasset: Asset;
  curlang = 'en';
  adate;
  gdate;
  dateerr: string;
  sdate;
  preview: boolean;
  picklong = false;
  pickfin = false;
  full = true;
  filter = 'All';
  sort = 'Reverse Chronological';
  filters = [
    'All',
    'Favorites',
    'By Adventure',
    'By Location',
    'By Date'
  ];
  sorts = [
    'Reverse Chronological',
    'Chronological',
    'Alphabetical',
    'Nearest'
  ];

  blogassets: Asset[];
  blogassetsadj: Asset[];
  pickassets: Asset[];

  cards: Wish[];
  curcard: Wish;

  guides: Wish[];
  curguide: Wish;
  addphoto = false;
  $sub1;

  stylename: string;
  styleid: string;
  styledef: string;


  googletype = 'establishment';
  googletypeasset = 'establishment';
  autoCompleteOptions = {
    fields: autoComleteFields,
    types: ['establishment'],
  };
  autocomplete;
  autocompleteasset;
  location: string;
  assetlocation: string;

  options = {
    key: 'wFE7nB4D3F4C3B10B6eyznxmE2cd1rsplpfG-7c1eeF6nablA3C11B8A4D2B4F3G2F3B2==',
    charCounterCount: true,
    language: 'en',
    height: 400,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'color', 'align', 'formatOL', 'formatUL',
      'outdent', 'indent', 'spellChecker', 'html']
  };

  hit;

  curtag: Tag;
  imgsize: number;
  factor;
  movedot = false;
  startmove = false;
  dotx = 0;
  doty = 0;
  framey = 0;
  framex = 0;
  newface = false;
  bx: Box;
  face;
  email: string;
  phone: string;
  facepick: boolean;
  curfav: Fav;


  mapsstyledetails = false;
  teststyle;

  @ViewChild('addressasset', {static: false}) addressasset: any;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;
  @ViewChild('circ', {static: false}) circ: ElementRef;

  constructor(private invs: InviteService,
              public admin: AdminService,
              private http: RoehttpService,
              private afStorage: AngularFireStorage,
              private cd: ChangeDetectorRef,
              private dom: DomSanitizer,
              private fs: FirebaseService,
              public snackBar: MatSnackBar,
              public mapbox: MapboxService,
              private db: AngularFireDatabase,
              private is: InterfaceService) {
  }

  onShowStyle(id) {
    this.is.getObject(`MapStyle/Styles/${id}`).take(1)
      .subscribe(style => {
        this.teststyle = JSON.parse(style);
      });

  }

  onAddMapStyle() {
    this.stylename = '';
    this.styleid = '';
    this.styledef = '';

    this.mapsstyledetails = true;
  }

  onSaveStyle() {
    const str = JSON.parse(this.styledef);
    const json = JSON.stringify(str);
    const key = new Key();
    key.id = this.styleid;
    key.name = this.stylename;
    this.is.setObjNoLog(`MapStyle/Defs/${this.styleid}`, key);
    this.is.setObjNoLog(`MapStyle/Styles/${this.styleid}`, json);
    this.mapsstyledetails = false;

  }

  onCancelStyle() {
    this.mapsstyledetails = false;

  }

  onFilter() {
    if (this.filter === 'All') {
      this.blogassetsadj = _.cloneDeep(this.blogassets);
    } else if (this.filter === 'Favorites') {
      this.blogassetsadj = this.blogassets.filter(a => a.fav);
    }
  }

  onSort() {
    if (this.sort === 'Reverse Chronological') {
      this.blogassetsadj = _.orderBy(this.blogassetsadj, ['date'], 'desc');
    } else if (this.sort === 'Chronological') {
      this.blogassetsadj = _.orderBy(this.blogassetsadj, ['pubdate'], 'asc');
    }
  }

  ngOnInit() {
    this.mapbox.getMapStyles();

    this.$sub1 = this.is.getList(`Topics/Travelblogs/${this.topicukey}/blogassets`).subscribe(assets => {
      this.blogassets = assets;
      this.onFilter();
      this.onSort();
    });


    this.is.getList(`Topics/Travelblogs/${this.topicukey}/blogguides`)
      .take(1)
      .subscribe(guides => {
        this.guides = guides;
      });

  }


  onClear() {

  }

  onCancel() {
    this.curasset = null;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    const files = event.target.files;
    if (files.length === 0
    ) {
      return;
    }

    this.filename = files[0].name;
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.img = reader.result;
      this.imgsize = 100;
      this.createAutosAsset();
      this.curasset = new Asset(this.indivukey, '', '');
      // todo: could be a movie in the future
      this.curasset.type = 'image';
      this.public = false;
      this.curasset['x'] = 0;
      this.curasset['y'] = 0;
      this.cd.detectChanges();
    };
  }

  // this master database of pubic photos is PublicPhotos/
  // changing the privacy.code updates the public status via a call to cloud function:  updatePublicAsset
  onChangePublic() {
    if (!this.curasset.privacy) {
      this.curasset.privacy = new Privacy();
    }
    if (this.public) {
      this.curasset.privacy.code = 'Public';
    } else {
      this.curasset.privacy.code = 'Private';
    }
  }

  onEditAsset(a) {
    this.curasset = a;
    if (this.curasset.privacy && this.curasset.privacy.code === 'Public') {
      this.public = true;
    } else {
      this.public = false;
    }
    if (this.curasset.location['name']) {
      this.assetlocation = this.curasset.location['name'];
    } else {
      this.assetlocation = this.curasset.location.location;
    }
    this.createAutosAsset();
    this.imgsize = 100;
    if (!this.curasset.finlocation) {
      this.curasset.finlocation = new PlaceLoc();
    }

    if (!this.curasset['x']) {
      this.curasset['x'] = 0;
    }
    if (!this.curasset['y']) {
      this.curasset['y'] = 0;
    }
    // todo: need to make this international time
    this.adate = new Date(this.curasset.pubdate);
  }


  onFav(fav, a) {
    a.fav = fav;
    this.is.setObjNoLog(`Topics/Travelblogs/${this.topicukey}/blogassets/${a.ukey}/fav`, a.fav);
  }

  // todo: could add the ability to crop and scale
  saveImg() {
    const filename = `Indivs/${this.indivukey}/blogassets/${this.filename}`;

    const fileRef = this.afStorage.ref(filename);
    fileRef.putString(this.img, 'data_url').then(res => {
      const s = `gs://library-titleix.appspot.com/${filename}`;
      this.afStorage.storage.refFromURL(s).getDownloadURL()
        .then(url => {
          this.curasset.url = url;
          this.is.setObjNoLog(`Topics/Travelblogs/${this.topicukey}/blogassets/${this.curasset.ukey}`, this.curasset);
          this.curasset = null;
        });
    });
  }

  onMapClick(event, who) {
    console.log('event', event);
    if (!this.picklong && !this.pickfin) {
      this.snackBar.open('Tap on the gray dot next to the long lat pair you would like to edit.', '', {
        duration: 1000,
      });
    } else if (this.picklong) {
      if (event && event.coords && event.coords.lng) {
        if (who === 'card') {
          this.hit.long = event.coords.lng;
          this.hit.lat = event.coords.lat;

        } else {
          this.curasset.location.long = event.coords.lng;
          this.curasset.location.lat = event.coords.lat;
        }
      }
    } else if (this.pickfin) {
      if (event && event.coords && event.coords.lng) {
        this.curasset.finlocation.long = event.coords.lng;
        this.curasset.finlocation.lat = event.coords.lat;
      }
    }
  }

  onSave() {
    this.dateerr = '';

    // todo: need all the error checks

    // todo: need to add a spinner since the getTimeZone function takes a bit
    if (!this.adate) {
      this.dateerr = 'Please enter the date the asset was created.';
      return;
    }
    this.curasset.pubdate = new Date(this.adate).getTime();

    if (!this.curasset.ukey) {
      this.curasset.ukey = this.db.createPushId();
      this.saveImg();
    } else {
      this.is.setObjNoLog(`Topics/Travelblogs/${this.topicukey}/blogassets/${this.curasset.ukey}`, this.curasset);
      this.curasset = null;
    }


    /*
        this.http.getTimeZone(this.curasset.location.lat, this.curasset.location.long, environment.GOOGLE_ROADS_API)
          .then(r => {
            if (r && r['rawOffset']) {
              this.curasset.location.utc_offset_minutes = (r['rawOffset'] / 60) + r['dstOffset'] / 60;
              this.curasset.location.tzone = r['timeZoneId'];
              this.curasset.location.hash = geohash.encode(this.curasset.location.lat, this.curasset.location.long);
              console.log('start of save', this.curasset.location);
              // todo: need to make this intl time

            }
          });
    */


    /* todo: make this work
        this.curasset.creators.push(this.creator);
        export class ObjName {
          type: string; // person, place, dog, cat
          fullname: string; //
          alias: string; // name of Obj as used in a specific case
          indivukey: string;
          photo: string;
          ukey: string;
        }
     */

  }


  onPickAsset(a, i) {
    if (!this.curcard.items) {
      this.curcard.items = [];
    }
    this.curcard.items.push(a);
    this.pickassets.splice(i, 1);
  }

  onAddGuide() {
    this.curguide = new Wish(this.indivukey, null);
    this.curguide.e = new ItineraryDetails();
    this.curguide.ty = 'BlogGuide';
    this.curguide.m = new GuideMap();
    this.curguide.cn = this.blogger;
    this.curguide.type = 'travelogue';
  }

  onCloseGuide(event) {
    if (!this.guides) {
      this.guides = [];
    }
    const x = this.guides.findIndex(g => g.ukey === this.curguide.ukey);
    if (x === -1) {
      this.guides.push(this.curguide);
    } else {
      this.guides[x] = this.curguide;
    }

    this.is.setObjNoLog(`Topics/Travelblogs/${this.topicukey}/blogguides/${this.curguide.ukey}`, this.curguide);
    this.curguide = null;
  }

  onCloseSection(event) {
    // event === true means save the section
    if (event) {
      if (!this.curguide.l) {
        this.curguide.l = [];
      }
      const x = this.curguide.l.findIndex(s => s.ukey === this.curcard.ukey);
      if (x === -1) {
        this.curguide.l.push(this.curcard);
      }

      console.log('reordering');
      this.curguide.l = _.orderBy(this.curguide.l, 'e.day', 'asc');
    }
    this.curcard = null;
  }

  onSection(event) {
    if (event) {
      this.curcard = event;
    } else {
      this.curcard = new Wish(this.indivukey, null);
      this.curcard.type = this.curguide.type;
      this.curcard.e = new ItineraryDetails();
      this.curcard.ty = 'BlogSection';
    }
    if (!this.curcard.attribs) {
      this.curcard.attribs = [];
    }
  }

  onEditGuide(c) {
    this.curguide = c;
    if (this.curguide.ty === 'BlogGuide' && !this.curguide.m) {
      this.curguide.m = new GuideMap();
    }
  }

  onDeleteGuide(c, i) {
  }

  onDeleteAsset(a: Asset) {
    console.log('asset', `Topics/Travelblogs/${this.topicukey}/blogassets/${a.ukey}`);
    this.is.deleteObjNoLog(`Topics/Travelblogs/${this.topicukey}/blogassets/${a.ukey}`);
//    this.afStorage.ref(filename);
  }

  onGetHitAsset() {
    this.hit = null;
    if (this.addressasset.nativeElement.value && this.addressasset.nativeElement.value.length) {
      const varr = this.addressasset.nativeElement.value.split(',');
      this.assetlocation = varr[0];
      if (this.assetlocation) {
        this.is.getQuery(`Places`, 'name', this.assetlocation)
          .take(1)
          .subscribe(lox => {
            if (lox && lox.length) {
              this.curasset.location = lox[0].location;
              this.curasset.location['name'] = this.assetlocation;
            } else {
              this.is.getQuery(`Places`, 'address', this.addressasset.nativeElement.value)
                .take(1)
                .subscribe(addarr => {
                  if (addarr && addarr.length) {
                    this.curasset.location = addarr[0].location;
                    this.curasset.location['name'] = this.assetlocation;
                  } else {
                    console.log('not in the data base');
                    this.onSavePlace();
                  }
                });
            }
          });
      }
    }
  }

  onSavePlace() {
    const place = this.autocompleteasset.getPlace();
    if (place) {
      const newloc = new PlaceLoc();
      const country = this.admin.getCountry(place.address_components, true);
      const state = this.admin.getState(place.address_components, true);
      const city = this.admin.getCity(place.address_components, true);
      if (country === 'US') {
        if (city) {
          newloc.loc = `${city}, ${state}`;
          newloc.location = `${city}, ${state}`;
        } else {
          newloc.loc = `${state}, ${country}`;
          newloc.location = `${state}, ${country}`;
        }
      } else {
        newloc.loc = `${city}, ${country}`;
        newloc.location = `${city}, ${country}`;
      }
      newloc['name'] = this.assetlocation;
      newloc.long = place.geometry.location.lng();
      newloc.lat = place.geometry.location.lat();
      if (place.geometry.bounds) {
        newloc.bounds = place.geometry.bounds;
      }
      if (place.geometry.viewport) {
        newloc.viewport = place.geometry.viewport;
      }
      newloc.hash = geohash.encode(newloc.lat, newloc.long);
      newloc.address = place.formatted_address; // this is an address
      newloc.utc_offset_minutes = place.utc_offset_minutes;
      newloc.keys = [];
      const key = new Key();
      key.id = place.place_id;
      key.name = 'Google';
      newloc.keys.push(key);
      // newloc.tzone: string;
      this.curasset.location = newloc;
      console.log('newloc', this.curasset);

      let website = '';
      if (place.website) {
        website = place.website;
      }

      // save record to Places
      if (place.photos && place.photos.length > 0) {
        const parr = [];
        let googlephotos;
        place.photos.forEach(p => {
          parr.push(p.getUrl());
        });
        this.http.getGoogleJustPhotos(parr).then(
          photosreturn => {
            const photos = Object.values(photosreturn);
            if (photos && photos.length > 0) {
              googlephotos = [];
              photos.forEach(p => {
                googlephotos.push({url: p, who: 'Google'});
              });
            }
            this.is.setObjNoLog(`Places/${place.place_id}`, {
              ukey: place.place_id,
              name: place.name,
              location: newloc,
              address: place.formatted_address,
              website,
              photos: googlephotos
            });
          });
      } else {
        this.is.setObjNoLog(`Places/${place.place_id}`,
          {
            ukey: place.place_id,
            name: place.name,
            address: place.formatted_address,
            location: newloc,
            website
          });
      }
    }
  }


  onChangeAsset(event) {
    if (this.googletypeasset === 'address') {
      this.autocompleteasset.setTypes([]);
    } else {
      this.autocompleteasset.setTypes([this.googletypeasset]);
    }
  }


  createAutosAsset() {
    console.log('create asset auto');
    if (!this.autocompleteasset) {
      const el = this.addressasset.nativeElement;
      this.hit = null;
      console.log('options', this.autoCompleteOptions);
      this.autocompleteasset = new google.maps.places.Autocomplete(el, this.autoCompleteOptions);

      this.autocompleteasset.addListener('place_changed', () => {
        this.onGetHitAsset();
      });
    }
  }


  onLoad(evt, a: Asset) {
    if (evt && evt.target && !a['width']) {
      a['width'] = 450;
      a['height'] = evt.target.naturalHeight * (a['width'] / evt.target.naturalWidth);
      this.factor = evt.target.naturalWidth / a['width'];
      console.log('factor', this.factor);
    }
  }


  private getClientX(event: any) {
    return event.clientX != null ? event.clientX : event.touches[0].clientX;
  }

  private getClientY(event: any) {
    return event.clientY != null ? event.clientY : event.touches[0].clientY;
  }

  endMove(event) {
    this.startmove = false;
  }

  startMove(event) {
    if (!this.movedot) {
      console.log('starat move');
      this.startmove = true;
      this.dotx = this.getClientX(event) - this.curasset['x'];
      this.doty = this.getClientY(event) - this.curasset['y'];
    }
  }

  imgMove(event) {
    console.log('moving');
    if (!this.movedot && this.startmove) {
      console.log('keep moving');
      this.curasset['x'] = this.getClientX(event) - this.dotx;
      this.curasset['y'] = this.getClientY(event) - this.doty;
    }
  }

  onDeleteTag() {

    // delete the curtag from the curasset
    // save just to Travelblogs
    this.is.deleteObjNoLog(`Topics/Travelblogs/${this.topicukey}/blogassets/${this.curasset.ukey}/tags/${this.curtag.indivukey}`);


    if (this.curasset.buckets && this.curasset.buckets.length) {
      this.curasset.buckets.forEach(b => {
        this.is.deleteObjNoLog(`${b.path}/${this.curasset.ukey}/tags/${this.curtag.indivukey}`);
      });
    }

    // todo: delete it from models - is this still a thing?
    // this.is.deleteObjNoLog(`ROEDevices/${this.deviceId}/favorites/${this.curfav.ukey}/models/${this.curasset.ukey}`);

    const x = this.curasset.tags.findIndex(t => t.indivukey === this.curtag.indivukey);
    if (x > -1) {
      this.curasset.tags.splice(x, 1);
      this.curtag = null;
    }
  }

  dropDot(event) {
    this.movedot = false;
  }

  movedDot(event) {
    this.movedot = true;
    this.framex = this.framex + event.delta.x;
    this.framey = this.framey + event.delta.y;
  }

  makeBox(box) {
    const hostbox = this.canvas.nativeElement.getBoundingClientRect();

    const fx = (100 / this.imgsize) * this.factor;
    // make an adjust for scale and then for offset
    this.bx = new Box();
    this.bx.x = (box.left - hostbox.left - this.curasset['x']) * fx;
    this.bx.y = (box.top - hostbox.top - this.curasset['y']) * fx;
    this.bx.w = box.width * fx;
    this.bx.h = box.height * fx;
  }

  onSelectFace() {
    this.createFace(false);
  }

  saveTag(ukey: string) {
    // save to a curasset tag;
    const tag = new Tag();
    tag.name = this.curfav.name;
    tag.indivukey = ukey;
    tag.type = 'blank'; // person, place, thing, blank = a person who doesn't have an indivukey
    if (this.bx) {
      tag.box = this.bx;
    }
    if (!this.curasset.tags) {
      this.curasset.tags = [];
    }
    this.curasset.tags.push(tag);
    this.onSaveToModels();

    // need to save curassets tags to appropriate spots
    if (!this.curasset.expukey) {
      // save just to Travelblogs
      this.is.setObjNoLog(`Topics/Travelblogs/${this.topicukey}/blogassets/${this.curasset.ukey}/tags/${tag.indivukey}`, tag);
    } else {
      // save to the Content//assets
      console.log('tag', tag);
      this.is.setObjNoLog(`Content/bodies/${this.curasset.expukey}/body/assets/${this.curasset.ukey}/tags/${tag.indivukey}`, tag);

      // save to all the buckets
      if (this.curasset.buckets && this.curasset.buckets.length) {
        this.curasset.buckets.forEach(b => {
          this.is.setObjNoLog(`${b.path}/${this.curasset.ukey}/tags/${tag.indivukey}`, tag);
        });
      }

    }

    this.face = null;
    this.facepick = false;
    this.newface = false;
  }


  createFace(sw: boolean) {
    this.face = null;
    const box = this.circ.nativeElement.getBoundingClientRect();
    this.makeBox(box);

    html2canvas(this.canvas.nativeElement, {
      useCORS: true,
      x: box.left,
      y: box.top,
      width: 48,
      height: 48
    }).then(canvas => {
      this.face = canvas.toDataURL('image/png');

      if (sw) {
        if (this.curfav.indivukey) {
          this.saveTag(this.curfav.indivukey);
        } else {
          this.saveTag(this.curfav.ukey);
        }
      } else {
        this.curfav = new Fav();
        this.email = '';
        this.phone = '';
        this.facepick = true;

      }
    });
  }

  onSaveToModels() {
    /* todo: put this back
        const ukey = this.db.createPushId();
        const filename = `Indivs/${this.indivukey}/${ukey}.png`;
        const fileRef = this.afStorage.ref(filename);
        fileRef.putString(this.face, 'data_url').then(res => {
          const s = `gs://library-titleix.appspot.com/${filename}`;
          this.afStorage.refFromURL(s).getDownloadURL().toPromise()
            .then(url => {
              // assetukey combine like `Individual/${this.rostate.indivukey}/assets/${assetukey} to get the asset`
              const model = new Model();
              model.url = url;
              model.path = this.curasset.url;
              model.ukey = ukey;
              model.assetukey = this.curasset.ukey;
              if (!this.curfav.models) {
                this.curfav.models = [];
              }
              this.curfav.models.push(model);

              this.is.setObj(`ROEDevices/${this.rostate.deviceId}/favorites/${this.curfav.ukey}/models/${this.curasset.ukey}`,
                model);
            });
        });
    */
  }

  onTagPhoto() {
    // todo: remove this line
    this.newface = false;

    // todo: complete
    /*
          this.facepick = false;
          const ref = this.bottomSheet.open(AdventuresTagPhotoComponent,
            {data: {curasset: this.curasset}});

          ref.afterDismissed().pipe(take(1)).subscribe(res => {
            if (res) {
              this.curfav = res;
              this.createFace(true);
            }
          });
    */
  }

  onNewFace() {
    this.newface = true;
  }

  onPickLong() {
    this.pickfin = false;
    this.picklong = !this.picklong;
  }

  onPickFin() {
    this.picklong = false;
    this.pickfin = !this.pickfin;
  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
  }

}
