import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {AdminService} from '../../../services/admin.service';
import {Asset} from '../../../services/interface.service';

@Component({
  selector: 'app-archive-pick',
  templateUrl: './archive-pick.component.html',
  styleUrls: ['./archive-pick.component.scss']
})
export class ArchivePickComponent implements OnInit {
  tmpassets: Asset[];
  type;

  filter: string;

  constructor(private admin: AdminService,
              private bottomSheetRef: MatBottomSheetRef,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.type = data.type;
    this.tmpassets = data.tmpassets;
  }

  ngOnInit() {

  }

  onFilter() {
    if (!this.filter || this.filter.length === 0) {
      this.tmpassets = this.data.tmpassets;
    } else {
      this.tmpassets = this.data.tmpassets.filter(b => b.alt[this.admin.curlang].toLowerCase().includes(this.filter.toLowerCase()));
    }
  }


  onSave(asset) {
    this.bottomSheetRef.dismiss(asset);
  }

  onCancel() {
    this.bottomSheetRef.dismiss();
  }
}
