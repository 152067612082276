import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import {AdminService} from '../../../services/admin.service';
import {FirebaseService} from '../../../services/firebase.service';
import {InterfaceService, Invitation} from '../../../services/interface.service';


@Component({
    selector: 'app-periods',
    templateUrl: './periods.component.html',
    styleUrls: ['./periods.component.scss']
})
export class PeriodsComponent implements OnInit {
    @Input()
    pth: string;

    perioddetails: boolean;
    tmpperiods: Invitation[];
    curperiod: Invitation;

    form = new FormGroup({
        title: new FormControl('', Validators.required),
        startdate: new FormControl(''),
        enddate: new FormControl(''),
    });

    get title() {
        return this.form.get('title');
    }

    get startdate() {
        return this.form.get('startdate');
    }

    get enddate() {
        return this.form.get('enddate');
    }

    constructor(private admin: AdminService,
                private titleIXdb: FirebaseService,
                private is: InterfaceService) {
        this.perioddetails = false;
    }

    ngOnInit() {
        this.is.getList(`${this.pth}/${this.admin.admin.org}/periods`).subscribe(periods => {
                this.tmpperiods = periods;
                this.tmpperiods = _.orderBy(this.tmpperiods, ['startdate'], ['asc']);
            }
        );

        if (!this.tmpperiods) {
            this.tmpperiods = new Array();
        }
    }

    setValue(tmp) {
        this.form.setValue({
            title: tmp.title,
            startdate: tmp.start,
            enddate: tmp.end,
        });
    }

    onDeletePeriod(i) {
        // Todo: need to ask if they are sure
        this.is.deleteObj(`${this.pth}/${this.admin.admin.org}/periods/${this.tmpperiods[i].ukey}`, `Event`, '');
    }

    onEditPeriod(i) {
        this.curperiod = this.tmpperiods[i];
        this.setValue(this.curperiod);
        this.perioddetails = true;
    }

    onAddPeriod() {
        this.curperiod.ukey = this.titleIXdb.titleIXdb.createPushId();
        this.curperiod.title = '';
        this.curperiod.start = new Date().getTime();
        this.curperiod.end = new Date().getTime();
        this.curperiod.allDay = true; // false means show time
        this.curperiod.gspId = '';
        this.curperiod.gspoints = 0;
        this.curperiod.pubev = false;

        this.setValue(this.curperiod);
        this.perioddetails = true;

    }

    onCancel() {
        this.perioddetails = false;
    }

    onSave() {
        this.curperiod.title = this.title.value;
        this.curperiod.start = new Date(this.startdate.value).getTime();
        this.curperiod.end = new Date(this.enddate.value).getTime();

        this.tmpperiods.push(this.curperiod);
        this.tmpperiods = _.orderBy(this.tmpperiods, ['start'], ['asc']);

        this.perioddetails = false;
        this.is.setObj(`${this.pth}/${this.admin.admin.org}/periods/${this.curperiod.ukey}`, this.curperiod, `Event`, this.curperiod.title);
    }
}
