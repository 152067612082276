import {ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {InterfaceService} from '../services/interface.service';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-help-bottom',
  templateUrl: './help-bottom.component.html',
  styleUrls: ['./help-bottom.component.scss'],
})
export class HelpBottomComponent implements OnInit {
  id: string;
  str: string;
  backcolor: string;

  constructor(private bottomSheetRef: MatBottomSheetRef,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.id = data.id;
    this.str = data.str;
    this.backcolor = data.backcolor;
  }

  onClick() {
    this.bottomSheetRef.dismiss();
  }

  ngOnInit(): void {

  }

}
