import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-cominherit',
    templateUrl: './cominherit.component.html',
    styleUrls: ['./cominherit.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CominheritComponent implements OnInit {
    attribs: any;


    constructor(public dialogRef: MatDialogRef<CominheritComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.attribs = data.attribs;
    }

    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close();
    }

    onSave() {
        this.dialogRef.close(this.attribs);
    }


}
