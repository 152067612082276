import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {Languages} from '../../services/constants';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
    lang = Languages;

    @Output()
    langchange =  new EventEmitter<number>();

    constructor(public admin: AdminService) {
    }

    ngOnInit() {
    }

    onLang(i) {

/*
        this.admin.fulllanguage = this.lang[i].desc;
        this.admin.language = this.lang[i].abbr;
*/

        this.langchange.emit(i);

    }
}
