import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-code-veryify',
  templateUrl: './code-veryify.component.html',
  styleUrls: ['./code-veryify.component.scss']
})
export class CodeVeryifyComponent implements OnInit {
  @Input()
  primarycolor: string;

  @Input()
  secondarycolor: string;

  @Input()
  code: string;

  @Input()
  sentto: string;

  @Output()
  continue = new EventEmitter<string>();

  @Output()
  cancel = new EventEmitter();

  code1: string;
  code2: string;
  code3: string;

  err: string;

  @ViewChild('f2', {static: false}) f2: any;
  @ViewChild('f3', {static: false}) f3: any;


  constructor(public admin: AdminService) {
  }

  ngOnInit(): void {
  }

  onCancel() {
    this.cancel.emit();
  }

  onContinue() {
    const code = this.code1 + this.code2 + this.code3;
    // todo: need to delete CAP in the production version
    if (code === this.code.toUpperCase() || (code === 'CAP')) {
      this.continue.emit();
    } else {
      this.err = this.admin.t['invcode']; //'Invalid code';
    }
  }


  onKeyup(i) {
    if (i === 1 && this.code1 && this.code1.length === 1) {
      this.f2.nativeElement.focus();
    }
    if (i === 2 && this.code2 && this.code2.length === 1) {
      this.f3.nativeElement.focus();
    }
  }

}
