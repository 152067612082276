import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {FirebaseService} from '../../services/firebase.service';
import {AdminService, Adminy, Module, OrgModules} from '../../services/admin.service';
import {Router} from '@angular/router';
import {
    Demographics, EdList, Geo, InterfaceService, Links, OrgData, OrgLegacy,
    OrgProfile
} from '../../services/interface.service';
import {OrgTypes} from '../../services/constants';

@Component({
    selector: 'app-orgs',
    templateUrl: './orgs.component.html',
    styleUrls: ['./orgs.component.scss']
})
export class OrgsComponent implements OnInit, AfterViewInit {
    orgtypes = OrgTypes;
    oControl: FormControl;
    pControl: FormControl;
    orgslist: EdList[];
    curOrg: EdList;
    orgmodules = OrgModules;

    orgpicked: boolean;
    orgtype: string;

    mode: boolean;
    name: string;
    tmpOrg: OrgProfile;

    org: BehaviorSubject<string | null> = new BehaviorSubject('');
    startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
    endAt: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');

    form = new FormGroup({
        ukey: new FormControl(''),
        doeid: new FormControl(''),
        cappid: new FormControl(''),
        addname: new FormControl(''),
        addemail: new FormControl(''),
        addpassword: new FormControl(''),
    });

    get addname() {
        return this.form.get('addname');
    }

    get addemail() {
        return this.form.get('addemail');
    }

    get addpassword() {
        return this.form.get('addpassword');
    }

    get ukey() {
        return this.form.get('ukey');
    }

    get doeid() {
        return this.form.get('doeid');
    }

    get cappid() {
        return this.form.get('cappid');
    }

    constructor(private titleIXdb: FirebaseService,
                private router: Router,
                private admin: AdminService,
                private is: InterfaceService) {
        this.oControl = new FormControl();
        this.pControl = new FormControl();

        this.orgpicked = false;
        this.orgtype = 'Colleges';
        this.name = '';
    }

    ngAfterViewInit() {
        this.is.getBehaviorList(this.startAt, this.endAt, `OrgsByType/${this.orgtype}`)
            .subscribe(orgs => {
                this.orgslist = orgs;
            });
    }

    updateorgslist(sc) {
        this.org.next(sc);
        let obj;

        this.titleIXdb.titleIXdb.list<any[]>('OrgsByType/' + this.orgtype, ref => {
            return ref.orderByChild('ukey').equalTo(sc);
        })
            .valueChanges().subscribe(data => {
            obj = data[0];
            this.curOrg = <EdList>obj;

            if (this.curOrg) {
                this.is.getObject(`Orgs/${this.curOrg.ukey}/profile`).take(1).subscribe(org => {

                    this.tmpOrg = org;
                });

                this.name = this.curOrg.ukey;
            }
        });
    }

    search() {
        if (this.oControl.value.length === 1) {
            this.oControl.setValue(this.oControl.value.toUpperCase());
        }
        const q = this.oControl.value;
        this.startAt.next(q);
        this.endAt.next(q + '\uf8ff');
    }

    updateparentlist(sc) {
        this.org.next(sc);
        let obj;
        this.titleIXdb.titleIXdb.list<any[]>('OrgsByType/' + this.orgtype, ref => {
            return ref.orderByChild('ukey').equalTo(sc);
        })
            .valueChanges().subscribe(data => {
            obj = data[0];
            this.tmpOrg.parentkey = obj.ukey;

        });
    }

    searchparent() {
        if (this.pControl.value.length === 1) {
            this.pControl.setValue(this.pControl.value.toUpperCase());
        }
        const q = this.pControl.value;
        this.startAt.next(q);
        this.endAt.next(q + '\uf8ff');
    }

    setValue(tmp) {
        this.form.setValue({
            ukey: tmp.ukey,
            doeid: tmp.doeid,
            cappid: tmp.cappid,
            addname: '',
            addemail: '',
            addpassword: ''
        });
    }

    onEditOrg() {
        this.is.getQuery('Marketing', 'org', this.tmpOrg.ukey).take(1).subscribe(admins => {
            console.log('there', admins)
            if (!admins || admins.length === 0) {
                // get here means no admin yet.
                console.log('here')
            } else {
                // admin exist - so instantiate
            }
            this.mode = false;
            this.setValue(this.tmpOrg);
            this.orgpicked = true;
        });
    }

    onDelOrg() {
        this.orgpicked = false;
        const ukey = this.curOrg.ukey;
        this.oControl.setValue('');
        this.name = '';
        // Todo: more care has to be taken here.
        this.is.deleteObj(`OrgsByType/${this.orgtype}/${ukey}`, 'Legacy', '')
        this.is.deleteObj(`Orgs/${ukey}`, 'Legacy', '');
/* I think it's okay to delete this
        this.orgslist.forEach( d => {
            this.os.delEdLists( d.ukey + '/' + this.orgtype + '/' + ukey);
        })
*/
    }

    onAddOrg() {
        this.mode = true;
        this.tmpOrg = new OrgProfile();
        this.tmpOrg.ukey = '';
        this.tmpOrg.doeid = '';
        this.tmpOrg.cappid = -1;

        this.tmpOrg.orgdata = new OrgData();
        this.tmpOrg.orgdata.street1 = '';
        this.tmpOrg.orgdata.street2 = '';
        this.tmpOrg.orgdata.city = '';
        this.tmpOrg.orgdata.state = '';
        this.tmpOrg.orgdata.zip = '';
        this.tmpOrg.orgdata.country = '';
        this.tmpOrg.orgdata.contactemail = '';
        this.tmpOrg.orgdata.contactname = '';
        this.tmpOrg.orgdata.phones = new Array();

        this.tmpOrg.orglegacy = new OrgLegacy();
        this.tmpOrg.orglegacy.name = '';
        this.tmpOrg.orglegacy.nickname = '';
        this.tmpOrg.orglegacy.logo = '';
        this.tmpOrg.orglegacy.photo = '';
        this.tmpOrg.orglegacy.primarycolor = '#99add0';
        this.tmpOrg.orglegacy.secondarycolor = '#000000';

        this.tmpOrg.links = new Links;
        this.tmpOrg.links.url = '';
        this.tmpOrg.links.facebook = '';
        this.tmpOrg.links.twitter = '';
        this.tmpOrg.links.instagram = '';
        this.tmpOrg.links.linkedin = '';

        this.tmpOrg.geo = new Geo;
        this.tmpOrg.geo.county = '';
        this.tmpOrg.geo.long = 0;
        this.tmpOrg.geo.lat = 0;
        this.tmpOrg.geo.timezone = '';

        this.tmpOrg.demo = new Demographics;
        this.tmpOrg.demo.description = '';
        this.tmpOrg.demo.wikilink = '';
        this.tmpOrg.demo.female = 0;
        this.tmpOrg.demo.male = 0
        this.tmpOrg.demo.type = '';


        this.setValue(this.tmpOrg);
        this.orgpicked = true;
    }

    onSelectOrg() {
        this.admin.languageon = true;
        this.admin.language = 'en';
        this.admin.fulllanguage = 'English';
        this.admin.admin.org = this.curOrg.ukey;
        this.admin.getIncidentAlerts();
        const tmpAdminy = new Adminy;
        tmpAdminy.modules = new Array();
        this.admin.admin.orgs = new Array();
        this.admin.admin.modules = new Array();
        this.orgmodules.forEach( v => {
            v.value = true;
            const tmp = new Module;
            tmp.val = true;
            tmp.name = v.name;
            this.admin.admin.modules.push(tmp);

            const tmp2 = new Module;
            tmp2.name = v.code;
            tmp2.val = true;
            tmpAdminy.modules.push(tmp2);

        });

        this.admin.admin.type = 'Admin';
        this.router.navigateByUrl('profile');
        this.admin.admin.orgs.push(tmpAdminy);

        // this.admin.admin.school = this.school;
    }

    onCancel() {
        this.orgpicked = false;
    }

    onSave() {
        this.tmpOrg.ukey = this.ukey.value;
        this.tmpOrg.doeid = this.doeid.value;
        this.tmpOrg.cappid = this.cappid.value;
        if (this.mode) {
            this.is.getObject(`Orgs/${this.ukey.value}/profile`).take(1).subscribe(val => {
                if (!val) {
                    this.is.setObj(`Orgs/${this.tmpOrg.ukey}/profile`, this.tmpOrg, 'Legacy', '');

                    // todo: need to save the coordinates to the Location table
                    this.is.setObj(`OrgsByType/${this.orgtype}/${this.tmpOrg.ukey}`, {'ukey': this.tmpOrg.ukey}, 'Legacy', '');

                    // todo if change name or org need to update: 1) EdLists, 2) Logs, 3) all the names in Articles, 4) Legacy Colleges and 5) Legacy Full Colleges


                    this.orgpicked = false;
                } else {
                    alert('Not a valid ukey.');
                }
            });
        } else {
            this.is.setObj(`Orgs/${this.tmpOrg.ukey}/profile`, this.tmpOrg, 'Legacy', '');

            // todo: need to save the coordinates to the Location table
            this.is.setObj(`OrgsByType/${this.orgtype}/${this.tmpOrg.ukey}`, {'ukey': this.tmpOrg.ukey}, 'Legacy', '');

            this.orgpicked = false;
        }
    }

    ngOnInit() {
      console.log('bleck')
      this.is.getQuery(`Devices`, 'email', 'trhee@risd.edu').take(1).subscribe(lst => {
        console.log('list', lst)
      })
    }

}
