import {Injectable} from '@angular/core';
import {Wish} from './interface.service';
import {RoehttpService} from './roehttp.service';

export interface viatorProducts {
  products: any[];
  totalCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class ViatorService {
  viatorTags = [
    {
      name: 'Adult Only',
      val: [
        {
          'ids': [11925],
          'name': 'Adults-only Shows'
        },
        {
          'ids': [18884],
          'name': 'Adults Only'
        }
      ]
    },
    {
      name: 'Adventure',
      val: [
        {
          'ids': [22046],
          'name': 'Adventure Tours'
        }
      ]
    },
    {
      name: 'Agritourism',
      val: [
        {
          'ids': [11971],
          'name': 'Plantation Tours'
        },
        {
          'ids': [12073, 13282],
          'name': 'Coffee & Tea Tours'
        },
        {
          'ids': [21561],
          'name': 'Coffee Farm Tour'
        }
      ]
    },
    {
      name: 'Antiquity',
      val: [
        {
          'ids': [12077],
          'name': 'Archaeology Tours'
        },
        {
          'ids': [21654],
          'name': 'Ancient Ruins'
        }
      ]
    },
    {
      name: 'Architecture',
      val: [
        {
          'ids': [12013],
          'name': 'Architecture Tours'
        }
      ]
    },
    {
      name: 'Beach',
      val: [
        {
          'ids': [12021],
          'name': 'Scuba Diving'
        },
        {
          'ids': [13210],
          'name': 'Scuba'
        },
        {
          'ids': [13211, 19081, 11912],
          'name': 'Snorkeling'
        },
        {
          'ids': [13218],
          'name': 'Wakeboarding'
        },
        {
          'ids': [13212],
          'name': 'Standup Paddleboarding'
        },
        {
          'ids': [13222],
          'name': 'Windsurfing'
        }
      ]
    },
    {
      name: 'Biking',
      val: [
        {
          'ids': [11911],
          'name': 'Mountain Bike Tours'
        },
        {
          'ids': [13018, 21702, 18902],
          'name': 'Bike Tours'
        },
        {
          'ids': [12009, 18900],
          'name': 'Bike Rentals'
        },
        {
          'ids': [21535],
          'name': 'Cycling Classes'
        },
        {
          'ids': [21716],
          'name': 'Mountain Bike Rentals'
        },
        {
          'ids': [21713],
          'name': 'E-Bike Tours'
        },
        {
          'ids': [21611],
          'name': 'Cycling'
        }
      ]
    },
    {
      name: 'Birds',
      val: [
        {
          'ids': [21423],
          'name': 'Bird Watching'
        }
      ]
    },
    {
      name: 'Camping',
      val: [
        {
          'ids': [13127, 21760],
          'name': 'Camping'
        }
      ]
    },
    {
      name: 'Christmas',
      val: [
        {
          'ids': [11892],
          'name': 'Christmas'
        }
      ]
    },
    {
      name: 'Classes',
      val: [
        {
          'ids': [12718],
          'name': 'Classes'
        },
        {
          'ids': [21915],
          'name': 'Classes & workshops'
        }
      ]
    },
    {
      name: 'Climbing',
      val: [
        {
          'ids': [11949, 13128],
          'name': 'Climbing'
        },
        {
          'ids': [21533],
          'name': 'Climbing Classes'
        },
        {
          'ids': [21473],
          'name': 'Ice Climbing'
        }
      ]
    },
    {
      name: 'Couples',
      val: [
        {
          'ids': [9624],
          'name': 'Romantic'
        },
        {
          'ids': [10836],
          'name': 'Good For Honeymoon'
        },
        {
          'ids': [11939],
          'name': 'Romantic Tours'
        }
      ]
    },
    {
      name: 'Crafts',
      val: [
        {
          'ids': [13117],
          'name': 'Craft Classes'
        },
        {
          'ids': [20208],
          'name': 'Pottery Classes'
        },
        {
          'ids': [13114],
          'name': 'Glassblowing Classes'
        }
      ]
    },
    {
      name: 'Cruises',
      val: [
        {
          'ids': [12056],
          'name': 'Ports of Call Tours'
        },
        {
          'ids': [21701],
          'name': 'Cruises & Sailing'
        }
      ]
    },
    {
      name: 'Culture',
      val: [
        {
          'ids': [12710],
          'name': 'Cultural Events'
        },
        {
          'ids': [13180],
          'name': 'Ballet'
        },
        {
          'ids': [20207],
          'name': 'Classical Music'
        },
        {
          'ids': [20230],
          'name': 'Opera Performances'
        },
        {
          'ids': [21509],
          'name': 'Arts & Design'
        },
        {
          'ids': [21511],
          'name': 'Culture'
        },
        {
          'ids': [21479],
          'name': 'Art & Culture Classes'
        },
        {
          'ids': [12028],
          'name': 'Cultural Tours'
        }
      ]
    },
    {
      name: 'Disney',
      val: [
        {
          'ids': [12084],
          'name': 'Disney® Parks'
        }
      ]
    },
    {
      name: 'Easter',
      val: [
        {
          'ids': [11957],
          'name': 'Easter'
        }
      ]
    },
    {
      name: 'Entertainment',
      val: [
        {
          'ids': [21516],
          'name': 'Shows & Performances'
        }
      ]
    },
    {
      name: 'Events',
      val: [
        {
          'ids': [12715],
          'name': 'Sporting Events'
        },
        {
          'ids': [12521],
          'name': 'Events'
        }
      ]
    },
    {
      name: 'Family',
      val: [
        {
          'ids': [6216],
          'name': 'Family'
        },
        {
          'ids': [9155],
          'name': 'Kids Activities'
        },
        {
          'ids': [11917],
          'name': 'Kid-Friendly'
        },
        {
          'ids': [11919],
          'name': 'Family-friendly Shows'
        }
      ]
    },
    {
      name: 'Festivals',
      val: [
        {
          'ids': [12713],
          'name': 'Food & Drink Festivals'
        },
        {
          'ids': [12714],
          'name': 'Music Festivals'
        },
        {
          'ids': [21614],
          'name': 'Festivals'
        },
        {
          'ids': [22105],
          'name': 'Lantern Festival'
        },
        {
          'ids': [22106],
          'name': 'Cherry Blossom'
        }
      ]
    },
    {
      name: 'Food',
      val: [
        {
          'ids': [12694],
          'name': 'Food & Drink Tours'
        },
        {
          'ids': [20245],
          'name': 'Street Food Tours'
        },
        {
          'ids': [21567, 13285],
          'name': 'Food Tours'
        }
      ]
    },
    {
      name: 'Gambling',
      val: [
        {
          'ids': [9171],
          'name': 'Casino'
        }
      ]
    },
    {
      name: 'Golf',
      val: [
        {
          'ids': [9183],
          'name': 'Golf course'
        },
        {
          'ids': [13131, 21615],
          'name': 'Golf'
        },
        {
          'ids': [12037],
          'name': 'Golf Tours & Tee Times'
        },
        {
          'ids': [21539],
          'name': 'Golf Classes'
        }
      ]
    },
    {
      name: 'Guide',
      val: [
        {
          'ids': [11947],
          'name': 'Viator Private Guides'
        }
      ]
    },
    {
      name: 'Halloween',
      val: [
        {
          'ids': [11893],
          'name': 'Halloween'
        }
      ]
    },
    {
      name: 'Hiking',
      val: [
        {
          'ids': [11902],
          'name': 'Hiking Tours'
        },
        {
          'ids': [13130, 18991],
          'name': 'Hiking'
        },
        {
          'ids': [21430],
          'name': 'Heli-Hiking'
        },
        {
          'ids': [21439],
          'name': 'Nature Walks'
        }
      ]
    },
    {
      name: 'Hunting and Fishing',
      val: [
        {
          'ids': [12036],
          'name': 'Fishing Charters'
        },
        {
          'ids': [13129, 18964],
          'name': 'Fishing'
        },
        {
          'ids': [21434],
          'name': 'Ice Fishing'
        }
      ]
    },
    {
      name: 'Independence Day',
      val: [
        {
          'ids': [21585],
          'name': 'Independence Day'
        }
      ]
    },
    {
      name: 'LGBTQ+',
      val: [
        {
          'ids': [20222],
          'name': 'LGBT Friendly Tours'
        }
      ]
    },
    {
      name: 'Market',
      val: [
        {
          'ids': [16548],
          'name': 'Specialty Food Market'
        },
        {
          'ids': [20226],
          'name': 'Markets'
        },
        {
          'ids': [13286],
          'name': 'Market Tours'
        }
      ]
    },
    {
      name: 'Monuments',
      val: [
        {
          'ids': [21656],
          'name': 'Monuments and Memorials'
        }
      ]
    },
    {
      name: 'Mother\'s Day',
      val: [
        {
          'ids': [11894],
          'name': 'Mother\'s Day'
        }
      ]
    },
    {
      name: 'Museums',
      val: [
        {
          'ids': [10847, 13109, 21514],
          'name': 'Museums'
        },
        {
          'ids': [11901],
          'name': 'Museum Tickets & Passes'
        }
      ]
    },
    {
      name: 'National Parks',
      val: [
        {
          'ids': [21629],
          'name': 'National Parks'
        }
      ]
    },
    {
      name: 'Natural world',
      val: [
        {
          'ids': [21630],
          'name': 'Natural Attractions'
        }
      ]
    },
    {
      name: 'Nightlife',
      val: [
        {
          'ids': [11907],
          'name': 'Comedy Shows'
        },
        {
          'ids': [11908],
          'name': 'Theatre Shows'
        },
        {
          'ids': [12054],
          'name': 'Nightlife'
        },
        {
          'ids': [12070],
          'name': 'Cabaret'
        },
        {
          'ids': [19030],
          'name': 'Nightclub / DJ'
        },
        {
          'ids': [12721],
          'name': 'Nightlife'
        },
        {
          'ids': [18953],
          'name': 'Evening Entertainment'
        }
      ]
    },
    {
      name: 'Off Road',
      val: [
        {
          'ids': [12035],
          'name': '4WD Tours'
        },
        {
          'ids': [13126],
          'name': '4WD, ATV & Off-road tours'
        },
        {
          'ids': [21421],
          'name': 'ATV Tours'
        }
      ]
    },
    {
      name: 'On the water',
      val: [
        {
          'ids': [21917, 13205, 18919],
          'name': 'Canoeing'
        },
        {
          'ids': [13298],
          'name': 'Kayaking'
        },
        {
          'ids': [12047],
          'name': 'Kayaking Tours'
        },
        {
          'ids': [13221],
          'name': 'White Water Rafting'
        },
        {
          'ids': [13207],
          'name': 'Jetskiing'
        },
        {
          'ids': [13217],
          'name': 'Tubing'
        }
      ]
    },
    {
      name: 'Outdoors',
      val: [
        {
          'ids': [12722, 21909, 10845],
          'name': 'Outdoor Activities'
        }
      ]
    },
    {
      name: 'Photography',
      val: [
        {
          'ids': [11969, 13023],
          'name': 'Photography Tours'
        }
      ]
    },
    {
      name: 'Road Trips',
      val: [
        {
          'ids': [22110],
          'name': 'Road Trip'
        }
      ]
    },
    {
      name: 'Safari',
      val: [
        {
          'ids': [12038],
          'name': 'Safari'
        },
        {
          'ids': [13105],
          'name': 'Safari Packages'
        }
      ]
    },
    {
      name: 'Sailing',
      val: [
        {
          'ids': [11888],
          'name': 'Sailing'
        },
        {
          'ids': [12988],
          'name': 'Sailing Trips'
        }
      ]
    },
    {
      name: 'Scenic Spots',
      val: [
        {
          'ids': [10835],
          'name': 'Good View'
        },
        {
          'ids': [13110, 21718],
          'name': 'Observation Decks'
        }
      ]
    },
    {
      name: 'Scuba',
      val: [
        {
          'ids': [12021],
          'name': 'Scuba Diving'
        },
        {
          'ids': [13210],
          'name': 'Scuba'
        },
        {
          'ids': [13211, 19081, 11912],
          'name': 'Snorkeling'
        }
      ]
    },
    {
      name: 'Shopping',
      val: [
        {
          'ids': [10851, 12725],
          'name': 'Shopping'
        },
        {
          'ids': [11905],
          'name': 'Shopping Passes & Offers'
        },
        {
          'ids': [12697, 12039],
          'name': 'Shopping Tours'
        }
      ]
    },
    {
      name: 'Short Trips',
      val: [
        {
          'ids': [11889, 12693],
          'name': 'Day Trips'
        }
      ]
    },
    {
      name: 'Sightseeing',
      val: [
        {
          'ids': [12692],
          'name': 'City Tours'
        },
        {
          'ids': [12029],
          'name': 'Historical Tours'
        },
        {
          'ids': [21606],
          'name': 'Castle Tours'
        },
        {
          'ids': [21638],
          'name': 'Religious Sites'
        },
        {
          'ids': [21656],
          'name': 'Monuments and Memorials'
        },
        {
          'ids': [21710],
          'name': 'City Attraction Tours'
        },
        {
          'ids': [21617],
          'name': 'Historical Landmarks'
        },
        {
          'ids': [21612],
          'name': 'Factory Tours'
        }
      ]
    },
    {
      name: 'Skiing',
      val: [
        {
          'ids': [9158],
          'name': 'Ski-In / Ski-Out'
        },
        {
          'ids': [13200],
          'name': 'Ski Lessons'
        },
        {
          'ids': [13201],
          'name': 'Snowboard Lessons'
        },
        {
          'ids': [19075, 21474],
          'name': 'Skiing'
        },
        {
          'ids': [19076],
          'name': 'Ski Rentals'
        },
        {
          'ids': [19077],
          'name': 'Ski School'
        },
        {
          'ids': [21525],
          'name': 'Skiing Classes'
        },
        {
          'ids': [21431],
          'name': 'Heli-Skiing'
        },
        {
          'ids': [21471],
          'name': 'Snowboarding'
        },
        {
          'ids': [12699],
          'name': 'Ski & Snowboard Tours'
        }
      ]
    },
    {
      name: 'Spa',
      val: [
        {
          'ids': [9169],
          'name': 'Spa'
        },
        {
          'ids': [12078],
          'name': 'Spas'
        },
        {
          'ids': [11975, 16546],
          'name': 'Onsens'
        },
        {
          'ids': [12079],
          'name': 'Hammams & Turkish Baths'
        },
        {
          'ids': [12080],
          'name': 'Thermal Spas & Hot Springs'
        },
        {
          'ids': [12082],
          'name': 'Arab Baths'
        },
        {
          'ids': [18994],
          'name': 'Hot Spring Bath'
        },
        {
          'ids': [16304],
          'name': 'Sauna'
        },
        {
          'ids': [19090],
          'name': 'Steam Room'
        }
      ]
    },
    {
      name: 'Surfing',
      val: [
        {
          'ids': [12048, 13202, 20246],
          'name': 'Surfing Lessons'
        }
      ]
    },
    {
      name: 'Sustainability',
      val: [
        {
          'ids': [11962],
          'name': 'Sustainable Tours'
        }
      ]
    },
    {
      name: 'Thanksgiving',
      val: [
        {
          'ids': [21591],
          'name': 'Thanksgiving'
        }
      ]
    },
    {
      name: 'Theme Parks',
      val: [
        {
          'ids': [11909, 12729],
          'name': 'Theme Parks'
        },
        {
          'ids': [11944],
          'name': 'Busch Gardens'
        },
        {
          'ids': [21756],
          'name': 'Amusement Parks'
        },
        {
          'ids': [21757],
          'name': 'Sea World'
        },
        {
          'ids': [21758],
          'name': 'Six Flags'
        },
        {
          'ids': [21799],
          'name': 'Universal Theme Parks'
        }
      ]
    },
    {
      name: 'Things to See and Do',
      val: [
        {
          'ids': [12519],
          'name': 'Tours'
        },
        {
          'ids': [12520],
          'name': 'Activities'
        }
      ]
    },
    {
      name: 'Tours',
      val: [
        {
          'ids': [12519],
          'name': 'Tours'
        }
      ]
    },
    {
      name: 'Trains',
      val: [
        {
          'ids': [12032],
          'name': 'Rail Tours'
        },
        {
          'ids': [12060],
          'name': 'Rail Services'
        },
        {
          'ids': [12708],
          'name': 'Trains'
        },
        {
          'ids': [13104],
          'name': 'Rail Packages'
        },
        {
          'ids': [21740],
          'name': 'Rail Passes'
        }
      ]
    },
    {
      name: 'Valentine\'s Day',
      val: [
        {
          'ids': [11898],
          'name': 'Valentine\'s Day'
        }
      ]
    },
    {
      name: 'Walking Tour',
      val: [
        {
          'ids': [12046, 19119, 13030],
          'name': 'Walking Tours'
        }
      ]
    },
    {
      name: 'Wellness',
      val: [
        {
          'ids': [21488],
          'name': 'Wellness'
        },
        {
          'ids': [21522],
          'name': 'Meditation'
        },
        {
          'ids': [21759],
          'name': 'Traditional Wellness'
        }
      ]
    },
    {
      name: 'Wildlife',
      val: [
        {
          'ids': [11903],
          'name': 'Nature and Wildlife Tours'
        },
        {
          'ids': [12052],
          'name': 'Whale Watching'
        },
        {
          'ids': [12983],
          'name': 'Dolphin & Whale Watching'
        },
        {
          'ids': [21454],
          'name': 'Dolphin Watching'
        },
        {
          'ids': [21470],
          'name': 'Wildlife Watching'
        },
        {
          'ids': [21469],
          'name': 'Wildlife Encounters'
        }
      ]
    },
    {
      name: 'Wine and Spirits',
      val: [
        {
          'ids': [13120],
          'name': 'Winemaking Workshops'
        },
        {
          'ids': [11891],
          'name': 'Wine Tastings'
        },
        {
          'ids': [11953],
          'name': 'Beer & Brewery Tours'
        },
        {
          'ids': [12033],
          'name': 'Pub Tours'
        },
        {
          'ids': [13279],
          'name': 'Bar & Pub Tours'
        },
        {
          'ids': [13280],
          'name': 'Brewery Tours'
        },
        {
          'ids': [13284],
          'name': 'Distillery Tours'
        },
        {
          'ids': [13289],
          'name': 'Wine Tastings & Tours'
        },
        {
          'ids': [13287],
          'name': 'Sake Tours'
        },
        {
          'ids': [19128],
          'name': 'Wine / Champagne'
        },
        {
          'ids': [20239],
          'name': 'Sake Tasting'
        },
        {
          'ids': [21575],
          'name': 'Wine, Beer & Spirits'
        },
        {
          'ids': [21574],
          'name': 'Wine Tours'
        },
        {
          'ids': [21572],
          'name': 'Sake Brewery Tour'
        },
        {
          'ids': [21652],
          'name': 'Wineries & Breweries'
        }
      ]
    },
    {
      name: 'Winter activities',
      val: [
        {
          'ids': [11904],
          'name': 'Winter Sports'
        },
        {
          'ids': [21475],
          'name': 'Snow Tubing'
        },
        {
          'ids': [11951],
          'name': 'Winter Sports Rentals'
        }
      ]
    }
  ];

  constructor(private http: RoehttpService) {

  }

  getViatorProducts(curwish) {
    const destId = '479';
    const tags = [21972];
    const highestPrice = 479;
    const startDate = '2022-11-05';
    const endDate = '2022-12-24';
    const start = 1;
    const count = 5;

    this.http.viatorProductsSearch(destId, tags, highestPrice, startDate, endDate, start, count).take(1).subscribe(res => {
      if (res && res.status === 'success') {
        curwish.m.viatorProducts = res.data;
        console.log('curwish viator', curwish);
      }
    });

  }

  getViatorTags(curwish: Wish) {
    let arr = [];
    if (curwish.types && curwish.types.length) {
      curwish.types.forEach(t => {
        const obj = this.viatorTags.find(v => v.name === t);
        if (obj) {
          arr = [...arr, obj];
        }
      });
    }

    return arr;
  }

}
