import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InterfaceService} from '../../services/interface.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-roe-driveinvite',
  templateUrl: './roe-driveinvite.component.html',
  styleUrls: ['./roe-driveinvite.component.scss']
})
export class RoeDriveinviteComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  primarycolor: string;
  secondarycolor: string;
  spinner = false;
  gmail: string;
  err: string;
  turnoff = false;

  constructor(private bottomSheet: MatBottomSheet,
              public is: InterfaceService,
              public admin: AdminService) {
    super(admin, is);
  }

  ngOnInit() {
    super.ngOnInit();

    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();
  }

}
