import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {InterfaceService} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-roe-delevent',
  templateUrl: './roe-delevent.component.html',
  styleUrls: ['./roe-delevent.component.scss']
})
export class RoeDeleventComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  primarycolor: string;
  secondarycolor: string;

  constructor(public admin: AdminService,
              public is: InterfaceService) {
    super(admin, is);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();

  }

}
