import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireFunctions} from '@angular/fire/functions';
import * as _ from 'lodash';
import {Exhibit, InterfaceService, Komment} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';


@Component({
  selector: 'app-exhibit-card',
  templateUrl: './exhibit-card.component.html',
  styleUrls: ['./exhibit-card.component.scss']
})
export class ExhibitCardComponent implements OnInit, OnDestroy {
  @Input()
  edition: string;

  @Input()
  exhibit: Exhibit;

  @Input()
  card = false;

  @Output()
  delete = new EventEmitter();

  keybht = 0;
  helpstr: string;
  newcomment: Komment;
  addcomment = false;
  comments: Komment[];
  likes = -1;
  $sub1;
  bcl = ['#dddddd', '#a6a6a5', '#c3c3c1', '#e4e4e3', '#929291'];
  commentx = '';
  primarycolor;
  likespath;
  secodarycolor;

  constructor(private is: InterfaceService,
              private auth: AngularFireAuth,
              private admin: AdminService,
              private cf: AngularFireFunctions,
              public cd: ChangeDetectorRef) {
    this.primarycolor = '#0000FF';
    this.secodarycolor = '#FFFFFF';
  }

  ngOnInit(): void {
    this.is.getList(`${this.exhibit.commentpath}/${this.exhibit.ukey}`).subscribe(cs => {
      this.comments = _.orderBy(cs, 'date', 'desc');
      this.cd.detectChanges();
    });


    const p = `OrgsPri/${this.admin.admin.org}/exhibits/${this.edition}/${this.exhibit.ukey}/likes`;
    this.$sub1 = this.is.getObject(p).subscribe(res => {
      this.likes = res;
      this.cd.detectChanges();
    });
  }

  onDeleteComment(c: Komment) {
     this.is.deleteObjNoLog(`${this.exhibit.commentpath}/${this.exhibit.ukey}/${c.date}`);
  }

  // triggers the cloud function:  updateExhibitHearts
  onHeart() {

    /*
          this.exhibit.heart = !this.exhibit.heart;
          const comment = new Komment(this.rostate.deviceId, this.name);
          if (this.exhibit.heart) {
            this.likes += 1;
            comment.desc['en'].description = `${this.name} liked this exhibit.`;
            this.is.setObj(`${this.exhibit.commentpath}/${this.exhibit.ukey}/${comment.date}`, comment);
          } else {
            this.likes -= 1;
          }

          // this function triggers: updateExhibitHearts
          this.is.setObj(`ROEDevices/${this.rostate.deviceId}/exhibits/${this.exhibit.ukey}/heart`, this.exhibit.heart);
    */
  }

  getImage() {
    let str;
    if (this.exhibit.type === 'Image') {
      str = this.exhibit.filepath;
    } else {
      str = this.exhibit.thumbpath;
    }
    return str;
  }


  onMap() {
    const str = 'https://maps.google.com/?q=' + this.exhibit.lat + ',' + this.exhibit.long;
    window.open(str, '_blank');

  }

  onDelete() {
    this.delete.emit();
  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }

  }
}
