import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {SlgDesc} from '../../services/interface.service';

@Component({
    selector: 'app-slg-descriptions',
    templateUrl: './slg-descriptions.component.html',
    styleUrls: ['./slg-descriptions.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SlgDescriptionsComponent implements OnInit {
    slgdesc: SlgDesc[];
    curlang: string;
    pos: number;

    constructor(public dialogRef: MatDialogRef<SlgDescriptionsComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.pos = 0;
        this.slgdesc = data.slgdesc;
        this.curlang = data.curlang;
    }

    onChangePos(n) {
        this.pos = this.pos + n;
        if (this.pos < 0) {
            this.pos = this.slgdesc.length - 1;
        }
        if (this.pos > this.slgdesc.length - 1) {
            this.pos = 0;
        }
    }

    ngOnInit() {
    }


    onCancel() {
        this.dialogRef.close();
    }

    onSave() {
        this.dialogRef.close({pos: this.pos});
    }

}
