import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.css']
})
export class DateTimeComponent implements OnInit {
  @Input()
  datefield: number;

  @Input()
  notearlier = true;

  @Input()
  placeholder = 'Date';

  @Output()
  dt = new EventEmitter<number>();

  date: string;
  datetime: string;
  day: number;
  time: number;

  times = [
    '6:00AM',
    '6:30AM',
    '7:00AM',
    '7:30AM',
    '8:00AM',
    '8:30AM',
    '9:00AM',
    '9:30AM',
    '10:00AM',
    '10:30AM',
    '11:00AM',
    '11:30AM',
    '12:00PM',
    '12:30PM',
    '1:00PM',
    '1:30PM',
    '2:00PM',
    '2:30PM',
    '3:00PM',
    '3:30PM',
    '4:00PM',
    '4:30PM',
    '5:00PM',
    '5:30PM',
    '6:00PM',
    '6:30PM',
    '7:00PM',
    '7:30PM',
  ];

  constructor(public admin: AdminService) {
  }


  isValidDate(d) {
    return (d instanceof Date) && !isNaN(d.getTime());
  }

  emitDate() {
    // check to see if valid
    const tst = new Date().getTime();
    let str = -1;
    if (this.datetime && this.datetime.includes(' ')) {
      const d = this.datetime.split(' ')[0];
      let t = this.datetime.split(' ')[1];
      const x = new Date(d);
      if (this.isValidDate(x) && (t.includes('PM') || t.includes('AM'))) {
        let j = 0;
        if (t.includes('PM')) {
          j = 12;
          t = t.replace('PM', '');
        } else {
          t = t.replace('PM', '');
        }
        if (t.includes(':') && !isNaN(parseInt(t.split(':')[0], 10)) && !isNaN(parseInt(t.split(':')[1], 10))) {

          let hrs = parseInt(t.split(':')[0], 10);
          if (hrs !== 12 && j === 12) {
            hrs = hrs + j;
          }

          const min = parseInt(t.split(':')[1], 10);
          if (hrs > -1 && hrs < 24 && min > -1 && min < 60) {
            const newd = new Date(x.getFullYear(), x.getMonth(), x.getDate(), hrs, min);
            str = newd.getTime();
          }
        }
      }
    }

    if (str < tst + 60 * 1000 && this.notearlier) {
      str = -1;
    }

    this.dt.emit(str);

  }

  ngOnInit() {
    if (!this.datefield) {
      this.day = new Date().getTime();
      this.time = new Date(this.day).getHours() * 60 + new Date(this.day).getMinutes();
      const d = new Date(this.day);
      this.datetime = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formatDate(d, 'h:mma', 'en-US')}`;
    } else {
      this.day = this.datefield;
      this.time = new Date(this.datefield).getHours() * 60 + new Date(this.datefield).getMinutes();
      const d = new Date(this.day);
      this.datetime = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formatDate(d, 'h:mma', 'en-US')}`;
    }
    this.emitDate();
  }

  onChange(event) {
    if (this.datetime && this.datetime.includes(' ')) {
      const d = new Date(event);
      const str = this.datetime.split(' ')[1];
      this.datetime = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${str}`;
    } else {
      const d = new Date();
      this.datetime = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;

    }
    this.emitDate();
  }

  onKeyUp() {
    this.emitDate();
  }

  onTime(t) {
    if (!t) {
      return;
    }

    if (this.datetime && this.datetime.includes(' ')) {
      const str = this.datetime.split(' ')[0];
      this.datetime = `${str} ${t}`;
    } else {
      const d = new Date();
      this.datetime = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${t}`;
    }
    this.emitDate();

  }

}
