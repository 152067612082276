import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BoundingBoxService {
    $radLat;  // latitude in radians
    $radLon;  // longitude in radians
    $degLat;	 // latitude in degrees
    $degLon;  // longitude in degrees

    $angular; // angular radius

    EARTHS_RADIUS_KM = 6371.01;
    EARTHS_RADIUS_MI = 3958.762079;

    $MIN_LAT = -90 * 0.017453293;   // -PI/2
    $MAX_LAT = 90 * 0.017453293;    //  PI/2
    $MIN_LON = -180 * 0.017453293;  // -PI
    $MAX_LON = 180 * 0.017453293;


    constructor() {
    }

    public boundingCoordinates($latitude, $longitude, $distance): any {
        this.$radLat = $latitude * 0.017453293;
        this.$radLon = $longitude * 0.017453293;
        this.$degLat = $latitude;
        this.$degLon = $longitude;


        const $radius = this.EARTHS_RADIUS_MI;
        if ($radius < 0 || $distance < 0) {
            console.log('error');
            return;
        } else {
            this.$angular = $distance / $radius;
            let $minLat = this.$radLat - this.$angular;
            let $maxLat = this.$radLat + this.$angular;
            let $minLon = 0;
            let $maxLon = 0;

            if ($minLat > this.$MIN_LAT && $maxLat < this.$MAX_LAT) {
                const $deltaLon = Math.asin(Math.sin(this.$angular) /
                    Math.cos(this.$radLat));

                $minLon = this.$radLon - $deltaLon;

                if ($minLon < this.$MIN_LON) {
                    $minLon += 2 * Math.PI;
                }

                $maxLon = this.$radLon + $deltaLon;

                if ($maxLon > this.$MAX_LON) {
                    $maxLon -= 2 * Math.PI;
                }
            } else {
                // a pole is within the distance
                $minLat = Math.max($minLat, this.$MIN_LAT);
                $maxLat = Math.min($maxLat, this.$MAX_LAT);
                $minLon = this.$MIN_LON;
                $maxLon = this.$MAX_LON;
            }

            return [$minLat / 0.017453293, $minLon / 0.017453293, $maxLat / 0.017453293, $maxLon / 0.017453293];

        }
    }
}
