import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  CAColRow,
  CAComment,
  CACreator, CAFormUse,
  CAMetaForm,
  CAMetaMoly, CAMolyInForm,
  CAMolyType,
  CAPeriodType,
  CAPrimatives,
  CAUnit,
  CAUnitDomain,
  CAUnitTypes
} from '../../services/ca';
import {AngularFireDatabase} from '@angular/fire/database';
import {AdminService} from '../../services/admin.service';
import {InterfaceService, LangStringBlock, Phone} from '../../services/interface.service';
import {Icons} from '../../services/constants';
import {RoehttpService} from '../../services/roehttp.service';
import * as _ from 'lodash';
import {CaServices} from '../ca-services/ca-services';
import {moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-ca-forms',
  templateUrl: './ca-forms.component.html',
  styleUrls: ['./ca-forms.component.scss']
})
export class CaFormsComponent implements OnInit, OnDestroy {
  curlang = 'en';
  icons = Icons;
  pos = -1;

  caprimatives = CAPrimatives;
  caunitdomain = CAUnitDomain;
  caunittypes = CAUnitTypes;
  periodtype = CAPeriodType;

  caunits: CAUnit[];
  curunit: CAUnit;
  curcreator: CACreator;
  curmoly: CAMetaMoly;
  camolys: CAMetaMoly[];
  molyz: CAMetaMoly;
  molys: CAMetaMoly[];
  reqrd = false;
  molylink: string;
  sub1$;
  sub2$;
  sub3$;
  sub4$;
  uses: LangStringBlock[];

  curForm: CAMetaForm;
  caforms: CAMetaForm[];

  caformuses: CAFormUse[];
  curformuse: CAFormUse;
  curcolrow: CAColRow;

  unitdetails = false;
  molydetails = false;
  atommolydetails = false;
  pickdetails = false;
  formusedetails = false;
  addcolrowtoformuse = false;
  colrowdetails = false;
  formcomplete = false;
  molynotedetails = false;
  molytypedetails = false;
  formmolydetails = false;
  addmolytoform = false;


  details;
  fpos = -1;
  poscr = -1;
  err: string;


  curnote: CAComment;
  deletelist;

  mtypes: CAMolyType[];
  curmolytype: CAMolyType;
  molysin: CAMolyInForm[];

  molycatdetails = false;
  mcats: string[];
  curmolycat: string;
  spinner = true;
  pickval: string;

  crudxs;

  phonelist: Phone[];
  primarycolor = '#0000FF';
  secondarycolor = '#ffffff';

  constructor(private db: AngularFireDatabase,
              private cs: CaServices,
              public admin: AdminService,
              private http: RoehttpService,
              private is: InterfaceService) {
  }

  ngOnInit() {

    this.caunits = [];
    this.sub1$ = this.is.getList(`CA/caunits`).subscribe(units => {
      if (units) {
        this.caunits = _.orderBy(units, unit => unit.name[this.curlang].description, 'asc');
      }
      this.spinner = false;
    });

    // todo: need to implement permissions that will be used to limit what elements can be viewed and editted.
    this.camolys = [];
    this.sub2$ = this.is.getList(`CA/camolys`).subscribe(molys => {
      if (molys) {
        this.camolys = _.orderBy(molys, moly => moly.name[this.curlang].description, 'asc');
      }
      this.spinner = false;
    });

    this.caforms = [];
    this.sub3$ = this.is.getList(`CA/caforms`).subscribe(forms => {
      if (forms) {
        this.caforms = _.orderBy(forms, form => form.name[this.curlang].description, 'asc');
      }
      this.spinner = false;
    });

    this.caformuses = [];
    this.sub4$ = this.is.getList(`CA/caformuses`).subscribe(forms => {
      if (forms) {
        this.caformuses = _.orderBy(forms, form => form.name[this.curlang].description, 'asc');
      }
      this.spinner = false;
    });

    if (!this.admin.curphone) {
      this.is.getList('Interfaces/phones').take(1).subscribe(p => {
          this.phonelist = p;
          this.admin.curphone = this.phonelist[0];
          this.admin.appwidth = this.phonelist[0].internalwidth;
        }
      );
    }


  }

  onTabChange() {
    this.curForm = null;
    this.curformuse = null;
    this.curmoly = null;
    this.curmoly = null;
    this.unitdetails = false;
    this.molydetails = false;
    this.atommolydetails = false;
    this.pickdetails = false;
    this.formusedetails = false;
    this.addcolrowtoformuse = false;
    this.colrowdetails = false;
    this.formcomplete = false;
    this.molynotedetails = false;
    this.molytypedetails = false;
    this.formmolydetails = false;
    this.addmolytoform = false;

  }

  onAddUnit() {
    this.unitdetails = true;
    this.curunit = new CAUnit();
    this.curcreator = new CACreator(this.cs.indivukey);
  }

  onEditUnit(t) {
    this.unitdetails = true;
    this.curunit = t;
    this.curcreator = new CACreator(this.cs.indivukey);
  }

  onDeleteUnit(i) {
    this.is.deleteObjNoLog(`CA/caunits/${this.caunits[i].ukey}`);
    this.caunits.splice(i, 1);
    // todo: have to be careful in deleting because somebody might be using it
  }

  onSaveUnit() {
    if (!this.curunit.creators) {
      this.curunit.creators = [];
    }
    this.curunit.creators.push(this.curcreator);
    if (!this.curunit.ukey) {
      this.curunit.ukey = this.db.createPushId();
    }
    this.is.setObjNoLog(`CA/caunits/${this.curunit.ukey}`, this.curunit);
    this.unitdetails = false;
  }


  onSavePick() {
    if (this.pickval && this.pickval.length > 0) {
      if (!this.curmoly.pickvals) {
        this.curmoly.pickvals = [];
      }
      this.curmoly.pickvals.push(this.pickval);
      this.pickval = '';
      this.pickdetails = false;
    }
  }

  dropPick(event) {
    moveItemInArray(this.curmoly.pickvals, event.previousIndex, event.currentIndex);
  }

  dropMolyAtoms(event) {
    moveItemInArray(this.curmoly.molys, event.previousIndex, event.currentIndex);
  }

  dropMolyForm(event) {
    moveItemInArray(this.curForm.molys, event.previousIndex, event.currentIndex);
    let pos = 0;
    this.curForm.molys.forEach(m => {
      m.pos = pos;
      pos += 1;
    });
  }

  onDeleteNote(i) {

  }

  onAddNote() {
    this.curnote = new CAComment();
    this.curnote.creator = 'Capptivation';
    this.curnote.cdate = new Date().getTime();
    this.molynotedetails = true;
  }

  onSaveNote() {
    if (!this.curmoly.notes) {
      this.curmoly.notes = [];
    }
    this.curmoly.notes.push(this.curnote);
    this.molynotedetails = false;
  }


  onEditMoly(i: number) {
    this.curmoly = this.camolys[i];
    if (this.curmoly.molys) {
      this.curmoly.molys = _.orderBy(Object.values(this.curmoly.molys), 'pos', 'asc');
    }
    this.uses = null;
    if (this.curmoly.molyuses) {
      this.uses = Object.values(this.curmoly.molyuses);
    }
    this.molydetails = true;
    this.deletelist = [];
    this.curcreator = new CACreator(this.cs.indivukey);
  }

  onDeleteMoly(i) {
    // update deletelist
    if (this.camolys[i].molys) {
      this.camolys[i].molys.forEach(m => {
        this.deletelist.push(m.ukey);
      });
      this.updateUses('molyuses');
    }
    this.is.deleteObjNoLog(`CA/camolys/${this.camolys[i].ukey}`);
    this.camolys.splice(i, 1);
  }

  onAddMoly() {
    this.molydetails = true;
    this.curmoly = new CAMetaMoly();
    this.uses = null;
    this.deletelist = [];
    this.curcreator = new CACreator(this.cs.indivukey);
  }

  onAddAtomtoMoly() {
    this.atommolydetails = true;
    this.molys = null;
  }

  onDeleteAtomMoly(i) {
    // create a delete list so that uses can be updated if saved
    this.deletelist.push(this.curmoly.molys[i].ukey);
    this.curmoly.molys.splice(i, 1);
  }

  onSaveAtomMoly() {
    if (this.molys && this.molys.length > 0) {
      if (!this.curmoly.molys) {
        this.curmoly.molys = [];
      }
      this.molys.forEach(a => {
        const b = _.cloneDeep(a);
        // deleting this stuff because it's all meta info
        b.creators = null;
        b.notes = null;
        b.molyuses = null;
        this.curmoly.molys.push(b);
      });
      this.atommolydetails = false;
    }
  }

  updateUses(loc) {
    this.deletelist.forEach(d => {
      this.is.deleteObjNoLog(`CA/camolys/${d}/${loc}/${this.curmoly.ukey}`);
    });
  }

  onSaveMoly() {
    if (!this.curmoly.creators) {
      this.curmoly.creators = [];
    }
    this.curmoly.creators.push(this.curcreator);
    if (!this.curmoly.ukey) {
      this.curmoly.ukey = this.db.createPushId();
    }

    const cm = _.cloneDeep(this.curmoly);
    cm.molys = null;
    this.is.setObjNoLog(`CA/camolys/${this.curmoly.ukey}`, cm);

    if (this.deletelist && this.deletelist.length > 0) {
      this.updateUses('molyuses');
    }

    // cycle through all the molys if the primitive === moly and update their usage
    if (this.curmoly.primitive === 'moly' && this.curmoly.molys && this.curmoly.molys.length > 0) {
      let pos = 0;
      this.curmoly.molys.forEach(m => {
        m.pos = pos;
        this.is.setObjNoLog(`CA/camolys/${this.curmoly.ukey}/molys/${m.ukey}`, m);
        pos += 1;
        const ml = this.camolys.find(cam => cam.ukey === m.ukey);
        if (ml) {
          if (!ml.molyuses) {
            ml.molyuses = [];
          }
          ml.molyuses[this.curmoly.ukey] = true;
          this.is.setObjNoLog(`CA/camolys/${ml.ukey}/molyuses/${this.curmoly.ukey}`, this.curmoly.name);
          this.uses = Object.values(this.curmoly.molyuses);

        }
      });
    }

    // cycle through all the uses and update the moly definitions.
    if (this.uses && this.uses.length > 0) {
      const b = _.cloneDeep(this.curmoly);
      // deleting this stuff because it's all meta info
      b.creators = null;
      b.notes = null;
      b.molyuses = null;
      const ukeys = Object.keys(this.curmoly.molyuses);
      ukeys.forEach(u => {
        this.is.setObjNoLog(`CA/camolys/${u}/molys/${this.curmoly.ukey}`, b);

      });
    }

    // this bit of code updates all the forms that a moly is part of with the changes

    // todo: there is an error in this logic. if a moly is changed and it is part of another moly that is party of a form => the form will not get any updates
    // right now to avoid this bug, the moly has to be changed first, then the parent moly resaved and then the form should get the change. NEED TO FIX
    if (this.curmoly.formuses) {
      const fuses = Object.keys(this.curmoly.formuses);
      const b = _.cloneDeep(this.curmoly);
      // deleting this stuff because it's all meta info
      b.creators = null;
      b.notes = null;
      b.molyuses = null;
      b.formuses = null;
      fuses.forEach(u => {
        this.is.setObjNoLog(`CA/caforms/${u}/molys/${this.curmoly.ukey}/moly`, this.curmoly);
      });
    }

    this.molydetails = false;
  }

  onAddPickVal() {
    this.pickdetails = true;
    this.pickval = '';
  }

  onDeletePick(i) {
    this.curmoly.pickvals.splice(i, 1);
  }

  // form starts here
  updateUsesIn(loc) {
    this.deletelist.forEach(d => {
      this.is.deleteObjNoLog(`CA/camolys/${d}/${loc}/${this.curForm.ukey}`);
    });
  }

  onAddForm() {
    this.curForm = new CAMetaForm();
    this.uses = null;
    this.deletelist = [];
    this.curcreator = new CACreator(this.cs.indivukey);
    this.formmolydetails = true;

    //  todo: implement perms
    //  perms: CAPermForm; // this is the description of the permissions re form CRUD
  }

  onAddFormUse() {
    this.curformuse = new CAFormUse();
    this.curcreator = new CACreator(this.cs.indivukey);
    this.curForm = null;
    this.formusedetails = true;

    //  todo: implement perms
    //  perms: CAPermForm; // this is the description of the permissions re form CRUD
  }

  onEditForm(i) {
    this.curForm = this.caforms[i];
    if (this.curForm.molys) {
      this.curForm.molys = _.orderBy(Object.values(this.curForm.molys), 'pos', 'asc');
    }
    this.formmolydetails = true;
    this.deletelist = [];
    this.curcreator = new CACreator(this.cs.indivukey);
  }

  onFormSelect(event) {
    this.curformuse.formukey = this.curForm.ukey;
    this.molysin = _.orderBy(Object.values(this.curForm.molys), 'pos', 'asc');
  }

  onEditFormUse(i) {
    this.curformuse = this.caformuses[i];
    this.curForm = this.caforms.find(f => f.ukey === this.curformuse.formukey);
    this.molysin = _.orderBy(Object.values(this.curForm.molys), 'pos', 'asc');
    this.formusedetails = true;
    this.curcreator = new CACreator(this.cs.indivukey);
  }

  onDeleteForm(i) {
    // update deletelist
    if (this.caforms[i].molys) {
      this.caforms[i].molys.forEach(m => {
        this.deletelist.push(m.moly.ukey);
      });
      this.updateUsesIn('formuses');
    }
    this.is.deleteObjNoLog(`CA/caforms/${this.caforms[i].ukey}`);
    this.caforms.splice(i, 1);
  }

  onDeleteFormUse(i) {
    this.is.deleteObjNoLog(`CA/caforms/${this.curformuse.formukey}/formuses/${this.curformuse.ukey}`);
    this.is.deleteObjNoLog(`CA/caformuses/${this.caformuses[i].ukey}`);
    this.caformuses.splice(i, 1);
  }

  onDeleteFormMoly(i) {
    this.deletelist.push(this.curForm.molys[i].moly.ukey);
    this.curForm.molys.splice(i, 1);
  }

  onAddFormMoly() {
    this.addmolytoform = true;
    this.molyz = null;
  }

  onSaveMetaForm() {
    if (!this.curForm.creators) {
      this.curForm.creators = [];
    }
    this.curForm.creators.push(this.curcreator);
    if (!this.curForm.ukey) {
      this.curForm.ukey = this.db.createPushId();
    }

    const cm = _.cloneDeep(this.curForm);
    cm.molys = null;
    this.is.setObjNoLog(`CA/caforms/${this.curForm.ukey}`, cm);

    if (this.deletelist && this.deletelist.length > 0) {
      this.updateUsesIn('formuses');
    }

    // cycle through all the molys and update their usage
    if (this.curForm.molys && this.curForm.molys.length > 0) {
      this.curForm.molys.forEach(m => {
        this.is.setObjNoLog(`CA/caforms/${this.curForm.ukey}/molys/${m.moly.ukey}`, m);
        const ml = this.camolys.find(cam => cam.ukey === m.moly.ukey);
        if (ml) {
          if (!ml.formuses) {
            ml.formuses = [];
          }
          ml.formuses[this.curForm.ukey] = true;
          this.is.setObjNoLog(`CA/camolys/${ml.ukey}/formuses/${this.curForm.ukey}`, this.curForm.name);
        }
      });
    }

    this.formmolydetails = false;
  }

  onSaveFormUse() {
    if (!this.curformuse.formukey) {
      this.err = 'Pick a form';
      return;
    }

    if (!this.curformuse.creators) {
      this.curformuse.creators = [];
    }
    this.curformuse.creators.push(this.curcreator);
    if (!this.curformuse.ukey) {
      this.curformuse.ukey = this.db.createPushId();
    }

    this.is.setObjNoLog(`CA/caformuses/${this.curformuse.ukey}`, this.curformuse);


    this.is.setObjNoLog(`CA/caforms/${this.curformuse.formukey}/formuses/${this.curformuse.ukey}`, this.curformuse.name);

    this.formmolydetails = false;
  }

  onDeleteMolyForm(i) {
    // create a delete list so that uses can be updated if saved
    this.deletelist.push(this.curForm.molys[i].moly.ukey);
    this.curForm.molys.splice(i, 1);
  }

  onSaveMolyForm() {
    if (this.molyz) {
      if (!this.curForm.molys) {
        this.curForm.molys = [];
      }
      let pos;
      if (this.curForm.molys.length > 0) {
        pos = this.curForm.molys.length;
      } else {
        pos = 0;
      }
      const b = _.cloneDeep(this.molyz);
      // deleting this stuff because it's all meta info
      b.creators = null;
      b.notes = null;
      b.molyuses = null;
      b.formuses = null;
      let req = false;
      if (this.reqrd) {
        req = true;
      }
      let link = '';
      if (this.molylink) {
        link = this.molylink;
      }
      this.curForm.molys.push({req, moly: b, pos, formonly: false, link});
      pos += 1;

      this.addmolytoform = false;
    }
  }

  onPickFormUse(i) {
    this.curformuse = this.caformuses[i];
    this.curForm = this.caforms.find(f => f.ukey === this.curformuse.formukey);
    this.molysin = _.orderBy(Object.values(this.curForm.molys), 'pos', 'asc');
    this.formcomplete = true;
  }

  onSaveFormMoly(event, m) {
    // todo: indivukey is a cludge
    // this.is.setObjNoLog(`Individual/-M90vORAvGP9bq37sK-v/forms/${this.curformuse.ukey}/${m.moly.ukey}`, {data: event});

    // todo: somehow have to keep track of how much of the form is complete
  }

  isHidden(moly): boolean {
    if (this.curmoly.hidden && moly.jsonkey && this.curmoly.hidden.includes(moly.jsonkey)) {
      return true;
    } else {
      return false;
    }
  }

  onHiddenChange(event, atom) {
    if (atom.jsonkey) {
      let arr = [];
      if (this.curmoly.hidden) {
        arr = this.curmoly.hidden.split(',')
      }
      if (event.checked) {
        arr.push(atom.jsonkey);

      } else {
        const x = arr.findIndex(a => a === atom.jsonkey);
        if (x > -1) {
          arr.splice(x, 1)
        }
      }
      this.curmoly.hidden = arr.join(',')
    }
  }

  ngOnDestroy() {
    if (this.sub1$) {
      this.sub1$.unsubscribe();
    }
    if (this.sub2$) {
      this.sub2$.unsubscribe();
    }
    if (this.sub3$) {
      this.sub3$.unsubscribe();
    }
    if (this.sub4$) {
      this.sub4$.unsubscribe();
    }
  }

}
