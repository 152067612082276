import {Injectable} from '@angular/core';
import {Plugins} from '@capacitor/core';
import {environment} from '../../environments/environment';
import {InterfaceService} from './interface.service';

const {Device} = Plugins;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  deviceInfo: any = undefined;

  constructor(private is: InterfaceService) {

  }

  async checkNativePermissionsAtRuntime(): Promise<boolean> {
    try {
      if (this.deviceInfo === undefined) {
        this.deviceInfo = await Device.getInfo();
      }
    } catch (err) {
      console.log(err.message);
      return false;
    }
    if (this.deviceInfo.platform === 'ios') {
      return true;
    }
    if (this.deviceInfo.platform === 'android') {
      const osVersion = parseInt(this.deviceInfo.osVersion, 10);
      if (!isNaN(osVersion) && osVersion > 5) {
        return true;
      }
    }
    return false;
  }

  async getOS(): Promise<string> {
    if (this.deviceInfo !== undefined) {
      return this.deviceInfo.platform;
    }
    try {
      this.deviceInfo = await Device.getInfo();
      return this.deviceInfo.platform;
    } catch (err) {
      return undefined;
    }
  }

  /**
   * Init Platform
   */
  async initPlatform(): Promise<string> {
    const os = await this.getOS();
    this.is.devicePlatform = os; // Richard's state manager
    // let storePremiumKey;
    return os;
  }
}
