import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BlockString, InterfaceService, Invitation, LangStringBlock} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';
import {FirebaseService} from '../../services/firebase.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Languages} from '../../services/constants';

@Component({
    selector: 'app-make-holiday',
    templateUrl: './make-holiday.component.html',
    styleUrls: ['./make-holiday.component.scss']
})
export class MakeHolidayComponent implements OnInit {
    holidaydetails: boolean;
    tmpholidays: Invitation[];
    curholiday: Invitation;
    languages = Languages;

    curlang = 'en';
    country = 'US'
    countries = [
        {c: 'US', l: 'en'},
        {c: 'CA', l: 'en'},
        {c: 'GB', l: 'en'}
    ]

    form = new FormGroup({
        title: new FormControl('', Validators.required),
        start: new FormControl(''),
    });

    get title() {
        return this.form.get('title');
    }

    get start() {
        return this.form.get('start');
    }

    onCountryChange() {
        this.curlang = this.countries[this.countries.findIndex(c => c.c === this.country)].l;
        this.tmpholidays = [];
        this.is.getList(`Holidays/${this.country}`).take(1).subscribe(h => {
            this.tmpholidays = h;
        });
    }

    constructor(private admin: AdminService,
                private is: InterfaceService,
                private titleIXdb: FirebaseService) {
        this.holidaydetails = false;
    }

    ngOnInit() {
        this.onCountryChange();
    }

    setValue(tmp) {
        this.form.setValue({
            title: tmp.title,
            start: tmp.start,
        });
    }

    onDeleteHoliday(i) {
        this.is.deleteObjNoLog(`Holidays/${this.country}/${this.tmpholidays[i].ukey}`);
        this.tmpholidays.splice(i, 1);
    }

    onAddHoliday() {
        this.curholiday = new Invitation();
        this.curholiday.ukey = this.titleIXdb.titleIXdb.createPushId();
        this.curholiday.title = '';

        this.curholiday.headlineDesc = new LangStringBlock();
        this.languages.forEach(l => {
            this.curholiday.headlineDesc[l.abbr] = new BlockString();
            this.curholiday.headlineDesc[l.abbr].description = '';
            this.curholiday.headlineDesc[l.abbr].block = false;
        });

        this.curholiday.start = new Date().getTime();
        this.curholiday.end = -1;
        this.curholiday.allDay = true;
        this.curholiday.gspoints = 0;
        this.curholiday.gspId = '';
        this.curholiday.pubev = false;

        this.setValue(this.curholiday);
        this.holidaydetails = true;
    }

    onCancel() {
        this.holidaydetails = false;
    }


    async onTransEl() {
        await this.languages.forEach(lang => {
            if (lang.abbr !== 'en') {
                this.admin.postTrans(lang.abbr, this.curholiday.headlineDesc['en'].description).then(val => {
                    this.curholiday.headlineDesc[lang.abbr].description = val['data'].translations[0].translatedText;
                });
            }
        });

        setTimeout(() => {
            this.holidaydetails = false;
            this.is.setObjNoLog(`Holidays/${this.country}/${this.curholiday.ukey}`, this.curholiday);
        }, 3000);

    }

    onSave() {
        this.curholiday.title = this.title.value;
        this.curholiday.headlineDesc[this.curlang].description = this.title.value;
        this.curholiday.start = new Date(this.start.value).getTime();

        this.tmpholidays.push(this.curholiday);
        this.tmpholidays = _.orderBy(this.tmpholidays, ['start'], ['asc']);
        this.onTransEl();

    }
}