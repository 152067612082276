import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CappTeamMsg, InterfaceService} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {MatBottomSheet} from '@angular/material';
import {AdminService} from '../../services/admin.service';

declare var confetti: any;

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {
  @Input()
  curmsg: CappTeamMsg;

  @Input()
  i: number;

  @Input()
  cando = true;

  @Input()
  showheader = true;

  @Input()
  creating = false;

  @Output()
  update = new EventEmitter();

  @Output()
  delete = new EventEmitter();

  @Output()
  profile = new EventEmitter();

  @Output()
  comment = new EventEmitter();

  @Output()
  group = new EventEmitter();

  @Output()
  archive = new EventEmitter();

  @Output()
  org = new EventEmitter();

  @Output()
  survey = new EventEmitter();

  @Output()
  predupdate = new EventEmitter();


  like = false;
  varr: any[];
  forward = false;
  towhom: string;
  tmz;
  frz;
  srz;
  cappteams;
  fetching = true;
  sharetext = '';
  startdate: Date;
  // todo: need to implement translation
  updates;
  attribs;
  phone: string;
  time: string;
  err: string;
  attributes;
  // todo: need to implement translation

  const;
  primarycolor: string;
  secondarycolor: string;

  days: string;

  constructor(private db: AngularFireDatabase,
              private is: InterfaceService,
              public admin: AdminService) {
  }

  ngOnInit(): void {
    if (!this.curmsg['checked']) {
      this.is.getObject(`Notifications/${this.curmsg.ukey}/data`)
        .take(1)
        .subscribe(stat => {
          this.curmsg['checked'] = true;
          if (stat && stat.action && stat.action === 'D') {
            this.delete.emit();
          }
        });
    }


    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();

    if (!this.curmsg.curlang) {
      this.curmsg.curlang = 'en';
    }


    /*
        if (this.admin.c) {
          this.updates = this.admin.c['CovidUpdates'];
          this.attribs = this.admin.c['Attributes'];
        }
    */

    this.onConfetti();
  }


  onSchoolColors() {
    const colorsz = [this.primarycolor, this.secondarycolor];
    confetti({
      particleCount: 100,
      angle: 60,
      spread: 55,
      origin: {x: 0},
      colors: colorsz
    });
    confetti({
      particleCount: 100,
      angle: 120,
      spread: 55,
      origin: {x: 1},
      colors: colorsz
    });
  }


  onBasicConfetti() {
    for (let x = 0; x < 9; x = x + 1) {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(),
          // since they fall down, start a bit higher than random
          y: Math.random() - 0.2
        }
      });
    }
  }

  onPop() {
    if (this.curmsg.c) {
      if (this.curmsg.c === 'showerpop') {
        this.onBasicConfetti();
      } else if (this.curmsg.c === 'pridepop') {
        this.onSchoolColors();
      }
    }
  }

  onConfetti() {
    if (this.curmsg.c && !this.curmsg.c.includes('pop')) {
      if (this.curmsg.c === 'shower') {
        this.onBasicConfetti();
      } else if (this.curmsg.c === 'pride') {
        this.onSchoolColors();
      }
      this.curmsg.c = `${this.curmsg.c}pop`;
    }
  }


  onOpenBrowser() {
    if (this.curmsg.e) {
      this.onUpdateUse('Social - Read article');

      window.open(this.curmsg.e, '_blank');
    }
  }

  onLike() {
  }

  onForward() {
  }

  onCancel() {
  }

  onToWhom(event) {
    this.tmz = null;
    this.frz = null;
    this.srz = null;
  }

  onBest() {
  }

  getBckClr(): string {

    return '#ffffff';
  }

  onSave() {

  }

  onArchive(bool: boolean) {
  }

  onUpdate() {
  }

  // this.admin.t['conflict']

  onComment() {
  }

  onDelete() {
  }

  onShare() {
  }

  onGroup() {
    this.group.emit();
  }

  onOrg() {
    this.org.emit();
  }

  onPulseProfile(event) {
    if (this.creating) {
      this.is.onPulse(event, 'h-emoji-uq');
    } else {
      if (this.curmsg.topics) {
        this.is.onPulse(event, 'h-profile');
      }
    }
  }

  onProfile() {
  }

  onVerfied() {
    // todo: could add this
    // this.bottomSheet.open(VerifiedKeyComponent, {});
  }

  onInstall() {
  }

  onAgent() {

  }

  onSurvey() {
  }

  onUpdateQ() {
  }

  onImHere() {
  }

  onUpdateUse(type: string) {
  }

  onMyWay() {
  }

  onAccept() {
  }

  onAcceptGoog() {
  }

  onWork(comingin: boolean) {
  }

  onJump() {
  }

  onAcceptPred() {
  }

  onNoThanks() {
  }


  showExplanation(str) {
  }

  showGraphs(p) {
  }

  onCreateProfile() {
  }

  onNewProfile() {
  }

  // End of Predictions

}
