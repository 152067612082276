import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import {Assets, InterfaceService, LangString, LangStringBlock} from '../../../services/interface.service';
import {FirebaseService} from '../../../services/firebase.service';
import {AdminService} from '../../../services/admin.service';
import {ConfirmationComponent} from '../../confirmation/confirmation.component';
import * as _ from 'lodash';
import {DomSanitizer} from '@angular/platform-browser';
import {Languages} from '../../../services/constants';

@Component({
    selector: 'app-graphics',
    templateUrl: './graphics.component.html',
    styleUrls: ['./graphics.component.scss']
})
export class GraphicsComponent implements OnInit, OnDestroy {
    @Input()
    loc: string;

    graphicdetails = false;
    spinner = false;
    folderdetails = false;
    languages = Languages;
    curlang: string;
    lastupdate: Date;
    assets: Assets[];
    tmpassets: Assets[];
    $sub1;
    $sub2;
    select = false;
    pos = -1;
    alt: string;
    photoFile: File;
    foldername: string;
    description: string;
    cursrc;
    curfolder: string;
    folders: string[];
    fileTypes = [
        'image/jpeg',
        'image/pjpeg',
        'image/png',
        'image/svg',
        'image/svg+xml',
    ];


    constructor(public is: InterfaceService,
                private dialog: MatDialog,
                private ds: DomSanitizer,
                private fs: FirebaseService,
                private admin: AdminService) {
        this.curfolder = 'Main';
        this.curlang = this.admin.language;
    }

    ngOnInit() {
      this.$sub1 = this.is.getList(`${this.loc}/${this.admin.admin.org}/archive/graphics`).subscribe(a => {
            console.log('back again')
            this.assets = a;


            if (!this.curfolder || this.curfolder === 'Main') {
                this.tmpassets = this.assets;
            } else {
                this.tmpassets = this.assets.filter(b => b.folder === this.curfolder);
            }
        });
        this.$sub2 = this.is.getList(`${this.loc}/${this.admin.admin.org}/archive/graphicsfolders`).subscribe(f => {
            this.folders = f;
            this.folders.push('Main');
            this.folders = _.orderBy(this.folders);
        });
    }

    onChange(str) {
        this.curfolder = str;
        if (!this.curfolder || this.curfolder === 'Main') {
            this.tmpassets = this.assets;
        } else {
            this.tmpassets = this.assets.filter(a => a.folder === this.curfolder);
        }

    }


    validFileType(file) {
        for (let i = 0; i < this.fileTypes.length; i++) {
            if (file.type === this.fileTypes[i]) {
                return true;
            }
        }
        return false;
    }


    onChangePhoto(files) {
        console.log('valid', files[0])
        if (this.validFileType(files[0])) {
            console.log('here')
            this.photoFile = files[0];
            const myReader: FileReader = new FileReader();
            myReader.onload = e => {
                const dataURL = myReader.result;
                console.log('there')
                this.cursrc = this.ds.bypassSecurityTrustUrl(dataURL.toString());
            };
            myReader.readAsDataURL(files[0]);
        }
    }

    onEditGraphic(i) {
        this.pos = i;
        this.graphicdetails = true;
        this.cursrc = this.tmpassets[i].url;
        this.description = this.tmpassets[i].description[this.curlang];
        this.alt = this.tmpassets[i].alt[this.curlang];
    }

    onDeleteGraphic(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this graphic?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                const filename = `${this.loc}/${this.admin.admin.org}/archive/graphics/${this.tmpassets[i].filename}`;

                const storageRef = firebase.storage();
                firebase.storage().ref().child(filename).delete().then(
                    v => {
                        this.is.deleteObj(`${this.loc}/${this.admin.admin.org}/archive/graphics/${this.tmpassets[i].ukey}`, 'Graphic', `Delete graphic`);
                    });

            }
        });

    }

    onAddGraphic() {
        this.description = '';
        this.alt = '';
        this.pos = -1;
        this.cursrc = null;
        this.graphicdetails = true;
    }

    onCancelGraphic() {
        this.graphicdetails = false;
    }

    onSaveGraphic() {
        if (this.photoFile && this.select) {
            this.spinner = true;
            const filename = `${this.loc}/${this.admin.admin.org}/archive/graphics/${this.photoFile.name}`;
            const storageRef = firebase.storage();
            firebase.storage().ref().child(filename).put(this.photoFile).then(
                v => {
                    const s = `gs://library-titleix.appspot.com/${filename}`;
                    storageRef.refFromURL(s).getDownloadURL().then(url => {
                        const as = new Assets();
                        as.url = url;
                        as.filename = this.photoFile.name;
                        as.xsize = -1;
                        as.ysize = -1;

                        as.description = new LangString();
                        this.languages.forEach(l => {
                            as.description[l.abbr] = '';
                        });
                        as.description[this.curlang] = this.description;

                        as.alt = new LangString();
                        this.languages.forEach(l => {
                            as.alt[l.abbr] = '';
                        });
                        as.alt[this.curlang] = this.alt;

                        as.folder = this.curfolder;
                        as.date = new Date().toISOString();
                        as.ukey = this.fs.titleIXdb.createPushId();
                        this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/graphics/${as.ukey}`, as, 'Legacy', ``);
                        this.spinner = false;
                    });
                }
            );
            this.graphicdetails = false;
        } else {
            this.select = false;
            console.log('here', `${this.loc}/${this.admin.admin.org}/archive/graphics/${this.assets[this.pos].ukey}/description/${this.curlang}`)
            this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/graphics/${this.assets[this.pos].ukey}/description/${this.curlang}`, this.description, 'Graphic', `Description`);
            this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/graphics/${this.assets[this.pos].ukey}/alt/${this.curlang}`, this.alt, 'Graphic', `Alt`);
            this.graphicdetails = false;

        }
    }

    onSaveFolder() {
        this.folderdetails = false;
        this.folders.push(this.foldername);
        const arr = this.folders.filter(f => f !== 'Main')
        this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/graphicsfolders`, arr, 'Archive', 'Created graphic folder');
    }

    onAddFolder() {
        this.folderdetails = true;
        this.foldername = '';
    }

    onSelect() {
        this.select = true;
        document.getElementById('photo').click();
    }

    onDeleteFolder(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this folder? Note: If you are, all files in this folder will be moved to the Main folder'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                if (this.assets && this.assets.length > 0) {
                    this.assets.forEach(a => {
                        if (a.folder === this.folders[i]) {
                            a.folder = 'Main';
                            this.is.setObjNoLog(`${this.loc}/${this.admin.admin.org}/archive/graphics/${a.ukey}`, a);
                        }
                    });
                    this.onChange('Main');
                }

                this.folders.splice(i, 1);
                const arr = this.folders.filter(f => f !== 'Main')
                this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/graphicsfolders`, arr, 'Archive', 'Deleted folder');

            }
        });

    }

    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
        if (this.$sub2) {
            this.$sub2.unsubscribe();
        }

    }
}
