import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import {CAMolyVal} from '../../../services/ca';
import {CaServices} from '../../ca-services/ca-services';
import {InterfaceService} from '../../../services/interface.service';
import {EmailType, IdentifierType, PhoneType, SocialMediaType} from '../../ca-services/ca-constants';

@Component({
    selector: 'app-identifiers',
    templateUrl: './identifiers.component.html',
    styleUrls: ['./identifiers.component.css']
})
export class IdentifiersComponent implements OnInit {
    @Input()
    label: string;

    @Input()
    iduser: string;

    @Input()
    val: string;

    @Input()
    formid: string;

    @Input()
    validator: string;

    @Input()
    vukey: string;

    @Input()
    pos = 0;

    @Input()
    parent: CAMolyVal;

    @Input()
    isform = true;

    @Input()
    date;

    @Output()
    close = new EventEmitter();

    types = IdentifierType;
    phones = PhoneType;
    emails = EmailType;
    social = SocialMediaType;

    type: string;
    identifier: string;
    description: string;
    validate = false;


    constructor(public gs: CaServices,
                private dialog: MatDialog,
                private fs: InterfaceService) {
    }

    ngOnInit() {
        if (this.val) {
            const carr = this.val.split(',');
            this.gs.getValuePos(this.iduser, carr[0], this.pos).then(val => {
                this.type = val;
            });
            this.gs.getValuePos(this.iduser, carr[1], this.pos).then(val => {
                this.identifier = val;
            });
            this.gs.getValuePos(this.iduser, carr[2], this.pos).then(val => {
                this.description = val;
            });
        }
    }

    getId(str): string {
        const x = this.gs.metadata.findIndex(m => m.label === str);
        return this.gs.metadata[x].idukey;
    }

    saveMolyVal(ukey, str, value, parent, cdate) {
        const tmp = new CAMolyVal();
        tmp.value = [];
        if (!value) {
            tmp.value.push({val: ''});
        } else {
            tmp.value.push({val: value});
        }
        const id = this.getId(str);
        tmp.idukey = id;
        tmp.u = ukey;
        tmp.cdate = cdate; // this is the date the val was edited or created
        tmp.cukey = this.iduser; // this is the ukey of the data creator todo: this is a cludge
        /*
                tmp.vdate = -1; // this is the date the val was validated
                tmp.vukey = ''; // this is the ukey of the data validator
                tmp.cukeys = []; // array of forms that can create the atom val
                tmp.rukeys = []; // array of forms that can read the atom val
                tmp.uukeys = []; // array of forms that can update the atom val
                tmp.dukeys = []; // array of forms that can delete the atom val
        */
        if (!parent && this.gs.validator) {
            tmp.cukeys[this.gs.validator.idukey] = this.validate;
            tmp.rukeys[this.gs.validator.idukey] = this.validate;
            tmp.uukeys[this.gs.validator.idukey] = this.validate;
            tmp.dukeys[this.gs.validator.idukey] = this.validate;
        }
        this.fs.setObjNoLog(`${this.gs.path}/${this.iduser}/data/${tmp.u}/${cdate}`, tmp);

        // user this to store parent string
        return tmp.u;
    }


    public onSave() {
        const cdate = new Date().getTime();
        let pntr = '';
        if (this.val) {
            this.saveMolyVal(this.formid + 'IdType', 'Identifier Type', this.type, this.formid, cdate);
            this.saveMolyVal(this.formid + 'IdId', 'Form Name', this.identifier, this.formid, cdate);
            this.saveMolyVal(this.formid + 'IdDesc', 'Form Name', this.description, this.formid, cdate);
            if (this.parent) {
                if (this.gs.validator) {
                    this.parent.cukeys[this.gs.validator.idukey] = this.validate;
                    this.parent.rukeys[this.gs.validator.idukey] = this.validate;
                    this.parent.uukeys[this.gs.validator.idukey] = this.validate;
                    this.parent.dukeys[this.gs.validator.idukey] = this.validate;
                }
                this.parent.cdate = cdate;
                this.fs.setObjNoLog(`${this.gs.path}/${this.iduser}/data/${this.parent.u}/${cdate}`, this.parent);
                this.fs.getQuery(`${this.gs.path}/${this.iduser}/datamap`, 'idukey', this.getId('Identifier'))
                    .pipe(take(1))
                    .subscribe(h => {
                        if (h) {
                            const x = h.findIndex(hh => hh.ukey === this.formid);
                            this.fs.setObjNoLog(`${this.gs.path}/${this.iduser}/datamap/${h[x]['ukeyx']}/val`, this.identifier);
                        }
                    });
            } else {
                // should never get hee because there should always be a parent
                this.saveMolyVal(this.formid, 'Identifier', this.val, '', cdate);
            }
        } else {
            const parent = this.fs.getId();
            this.formid = parent;
            pntr = this.saveMolyVal(parent + 'IdType', 'Identifier Type', this.type, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(parent + 'IdId', 'Form Name', this.identifier, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(parent + 'IdDesc', 'Form Name', this.description, parent, cdate);
            this.saveMolyVal(parent, 'Identifier', pntr, '', cdate);
            this.fs.setObjNoLog(`${this.gs.path}/${this.iduser}/datamap/${this.fs.getId()}`,
                {idukey: this.getId('Identifier'), ukey: parent, val: this.identifier});

        }

        if (this.validate) {
            // todo: make this an http call
            this.fs.setObjNoLog(`Meta/validate/${this.validator}/${this.iduser}/${this.formid}`, {
                date: cdate,
                data: this.getId('Identifier'),
                ukey: this.formid
            });
        }

    }

    onCancel() {
        this.close.emit();
    }

}
