import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FirebaseService} from '../../../services/firebase.service';
import {AdminService} from '../../../services/admin.service';
import {ConfirmationComponent} from '../../confirmation/confirmation.component';
import {MatDialog} from '@angular/material';
import {Assets, InterfaceService, LangString, LangStringBlock} from '../../../services/interface.service';
import {Languages} from '../../../services/constants';
import * as _ from 'lodash';
import * as firebase from 'firebase/app';
import 'firebase/storage';


@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit, OnDestroy {
    @Input()
    loc: string;

    graphicdetails = false;
    folderdetails = false;
    select = false;
    pos = -1;
    alt: string;
    lastupdate: Date;
    assets: Assets[];
    tmpassets: Assets[];
    $sub1;
    $sub2;
    languages = Languages;

    photoFile: File;
    curPhoto: string;
    foldername: string;
    description: string;
    cursrc;
    curfolder: string;
    folders: string[];
    curlang: string;

    constructor(public is: InterfaceService,
                private dialog: MatDialog,
                private fs: FirebaseService,
                private admin: AdminService) {
        this.curfolder = 'Main';
        this.curlang = this.admin.language;
    }

    ngOnInit() {
        this.$sub1 = this.is.getList(`${this.loc}/${this.admin.admin.org}/archive/videos`).subscribe(a => {


/*
            a.forEach(as => {
                const description = new LangString();
                this.languages.forEach(l => {
                    description[l.abbr] = '';
                });
                description[this.curlang] = as.description;

                const alt = new LangString();
                this.languages.forEach(l => {
                    alt[l.abbr] = '';
                });

                this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/videos/${as.ukey}/description`, description, 'Graphic', `Description`);
                this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/videos/${as.ukey}/alt`, alt, 'Graphic', `Alt`);
            })
*/



            this.assets = _.orderBy(a, 'description', 'asc');
            if (!this.curfolder || this.curfolder === 'Main') {
                this.tmpassets = this.assets;
            } else {
                this.tmpassets = this.assets.filter(b => b.folder === this.curfolder);
            }
        });
        this.$sub2 = this.is.getList(`${this.loc}/${this.admin.admin.org}/archive/videosfolders`).subscribe(f => {
            this.folders = f;
            this.folders.push('Main');
            this.folders = _.orderBy(this.folders);
        });
    }

    onChange(str) {
        this.curfolder = str;
        if (!this.curfolder || this.curfolder === 'Main') {
            this.tmpassets = this.assets;
        } else {
            this.tmpassets = this.assets.filter(a => a.folder === this.curfolder);
        }

    }


    onChangePhoto(files) {
            this.photoFile = files[0];
            console.log('photo', this.photoFile)
            const self = this;
            const myReader: FileReader = new FileReader();
            myReader.onload = function (e) {
                const dataURL = myReader.result;
                self.cursrc = dataURL.toString();
            };
            myReader.readAsDataURL(files[0]);
    }

    onEditGraphic(i) {
        this.pos = i;
        this.graphicdetails = true;
        this.curPhoto = this.assets[i].url;
        this.cursrc = this.assets[i].url;
        this.description = this.assets[i].description[this.curlang];
        this.alt = this.assets[i].alt[this.curlang];
    }

    onDeleteGraphic(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this video?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                const filename = `${this.loc}/${this.admin.admin.org}/archive/videos/${this.assets[i].filename}`;

                const storageRef = firebase.storage();
                firebase.storage().ref().child(filename).delete().then(
                    v => {
                        this.is.deleteObj(`${this.loc}/${this.admin.admin.org}/archive/videos/${this.assets[i].ukey}`, 'Graphic', `Delete graphic`);
                    });

            }
        });

    }

    onAddGraphic() {
        this.pos = -1;
        this.description = '';
        this.alt = '';
        this.cursrc = null;
        this.graphicdetails = true;
    }

    onCancelGraphic() {
        this.graphicdetails = false;
    }

    onSaveGraphic() {
        if (this.photoFile && this.select) {
            const filename = `${this.loc}/${this.admin.admin.org}/archive/videos/${this.photoFile.name}`;
            console.log('filename', filename)
            const storageRef = firebase.storage();
            firebase.storage().ref().child(filename).put(this.photoFile).then(
                v => {
                    const s = `gs://library-titleix.appspot.com/${filename}`;
                    storageRef.refFromURL(s).getDownloadURL().then(url => {
                        const as = new Assets();
                        as.url = url;
                        as.filename = this.photoFile.name;
                        as.xsize = -1;
                        as.ysize = -1;

                        as.description = new LangString();
                        this.languages.forEach(l => {
                            as.description[l.abbr] = '';
                        });
                        as.description[this.curlang] = this.description;

                        as.alt = new LangString();
                        this.languages.forEach(l => {
                            as.alt[l.abbr] = '';
                        });
                        as.alt[this.curlang] = this.alt;

                        as.folder = this.curfolder;
                        as.date = new Date().toISOString();
                        as.ukey = this.fs.titleIXdb.createPushId();
                        this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/videos/${as.ukey}`, as, 'Legacy', ``);
                    });
                }
            );
            this.graphicdetails = false;
        } else {
            this.select = false;
            this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/videos/${this.assets[this.pos].ukey}/description/${this.curlang}`, this.description, 'Video', `Description`);
            this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/videos/${this.assets[this.pos].ukey}/alt/${this.curlang}`, this.alt, 'Video', `Alt`);
            this.graphicdetails = false;
        }
    }

    onSaveFolder() {
        this.folderdetails = false;
        this.folders.push(this.foldername);
        const arr = this.folders.filter(f => f !== 'Main')
        this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/videosfolders`, arr, 'Archive', 'Created graphic folder');
    }

    onAddFolder() {
        this.folderdetails = true;
        this.foldername = '';
    }

    onSelectVideo() {
        this.select = true;
        document.getElementById('photo').click();
    }

    onDeleteFolder(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this folder? Note: If you are, all files in this folder will be moved to the Main folder'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                if (this.assets && this.assets.length > 0) {
                    this.assets.forEach(a => {
                        if (a.folder === this.folders[i]) {
                            a.folder = 'Main';
                            this.is.setObjNoLog(`${this.loc}/${this.admin.admin.org}/archive/videos/${a.ukey}`, a);
                        }
                    });
                    this.onChange('Main');
                }

                this.folders.splice(i, 1);
                const arr = this.folders.filter(f => f !== 'Main')
                this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/videosfolders`, arr, 'Archive', 'Deleted folder');

            }
        });

    }

    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
        if (this.$sub2) {
            this.$sub2.unsubscribe();
        }

    }
}
