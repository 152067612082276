import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';


@Component({
  selector: 'flip-box-template',
  templateUrl: './flip-box-template.component.html',
  styleUrls: ['./flip-box-template.component.scss']
})
export class FlipBoxTemplateComponent implements OnChanges {
  @Input()
  value = 0;

  numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  currentValue: number;
  previousValue: number;

  ngOnChanges(changes: SimpleChanges): void {
    this.currentValue = changes.value.currentValue;
    this.previousValue = changes.value.previousValue;
  }

  IsActive(num) {
    return this.currentValue === num;
  }

  IsBefore(num) {
    return this.previousValue !== this.currentValue && num === this.previousValue;
  }

  IsAnimated() {
    return this.previousValue !== this.currentValue;
  }

}
