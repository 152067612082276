import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {InterfaceService} from '../../services/interface.service';

@Component({
    selector: 'app-new-org',
    templateUrl: './new-org.component.html',
    styleUrls: ['./new-org.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class NewOrgComponent implements OnInit {
    name: string;
    errormsg = false;

    constructor(private is: InterfaceService,
                public dialogRef: MatDialogRef<NewOrgComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close('');
    }

    onSave() {
        this.is.getObject(`Orgs/${this.name}`).take(1).subscribe(val => {
            if (val) {
                // todo: must give alert
                this.errormsg = true;
            } else {
                this.dialogRef.close(this.name);

            }
        });

    }


}
