import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InterfaceService} from '../../services/interface.service';

@Component({
    selector: 'app-topic-pick',
    templateUrl: './topic-pick.component.html',
    styleUrls: ['./topic-pick.component.scss']
})
export class TopicPickComponent implements OnInit {
    @Input()
    topics: string[];

    @Input()
    loc = 'Orgs';

    @Output()
    tpc = new EventEmitter<any>();

    constructor(public is: InterfaceService) {
    }

    ngOnInit() {
        if (!this.is.topixs || this.is.topixs.length === 0) {
            this.is.getTopics(this.loc);
        }
    }

    onSelChange(event) {
        this.tpc.emit(this.topics)
    }

}
