import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MapItem} from '../services/interface.service';

@Component({
  selector: 'app-map-card-nugget',
  templateUrl: './map-card-nugget.component.html',
  styleUrls: ['./map-card-nugget.component.scss']
})
export class MapCardNuggetComponent implements OnInit {
  @Input()
  m: MapItem;

  @Input()
  curlang: string;

  @Input()
  config;

  @Output()
  edit = new EventEmitter();

  @Output()
  delete = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onEdit() {
    this.edit.emit();
  }

  onDelete() {
    this.delete.emit();
  }

  onError(i) {
    console.log('error', i);
  }
}
