import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ConfirmationComponent implements OnInit {
    text: string;

    constructor(public dialogRef: MatDialogRef<ConfirmationComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.text = data.text;
    }

    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close();
    }

    onSave() {
        this.dialogRef.close({answer: true});
    }

}
