import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {AdminService, CorpModules} from '../../services/admin.service';
import {InterfaceService} from '../../services/interface.service';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-login-start',
  templateUrl: './login-start.component.html',
  styleUrls: ['./login-start.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginStartComponent implements OnInit {

  constructor(private router: Router,
              private admin: AdminService,
              private afAuth: AngularFireAuth,
              private is: InterfaceService
  ) {

  }

  ngOnInit() {
    if (this.admin.loggedin) {
      this.afAuth.auth.signOut().then(p => {
        this.admin.loggedin = false;
        this.router.navigate(['loginstart']);
      });
    }
  }

  onStart() {

  }

  onRocClick() {
    if (this.admin.mock) {
      this.admin.mockRoc();
    } else {
      this.admin.loggedin = false;
      this.admin.roc = true;
      this.router.navigate(['rochome']);
    }
  }

  onComClick() {
    if (this.admin.mock) {
      this.admin.mockCom();
    } else {
      this.admin.loggedin = false;
      this.admin.preview = false;  // this is a total cludge to get around a router bug
      this.router.navigate(['login', {type: 'Marketing', loc: 'Community'}]);
    }
  }

  onHealthClick() {
    if (this.admin.mock) {
      this.admin.mockHealth();
    } else {
      this.admin.loggedin = false;
      this.admin.preview = false;  // this is a total cludge to get around a router bug
      this.router.navigate(['login', {type: 'Marketing'}]);
    }
  }

  onTestingClick(isgovt: boolean) {
    if (this.admin.mock) {
      this.admin.mockTesting();
    } else {
      this.admin.loggedin = false;
      this.admin.preview = false;  // this is a total cludge to get around a router bug
      this.router.navigate(['login', {type: 'Marketing'}]);
    }
  }

  onCorpClick(isgovt: boolean) {
    this.admin.isgovt = isgovt;
    if (this.admin.mock) {
      this.admin.mockCorp();
    } else {
      this.admin.loggedin = false;
      this.admin.preview = false;  // this is a total cludge to get around a router bug
      this.router.navigate(['logininst', {type: 'Marketing', loc: 'Inst'}]);
    }
  }

  onHRClick(isgovt: boolean) {
    this.admin.isgovt = isgovt;
    if (this.admin.mock) {
      this.admin.mockHR();
    } else {
      this.admin.loggedin = false;
      this.admin.preview = false;  // this is a total cludge to get around a router bug
      this.router.navigate(['login', {type: 'Marketing', loc: 'Corp'}]);
    }
  }

  onOrgClick() {
    if (this.admin.mock) {
      this.admin.mockOrg();
    } else {
      this.admin.loggedin = false;
      this.admin.preview = false;  // this is a total cludge to get around a router bug
      this.router.navigate(['login', {type: 'Marketing',  loc: 'Orgs'}]);
    }

  }

  onInvClick() {
    console.log('inv')
    if (this.admin.mock) {
      this.admin.mockInv();
    }
  }

  onLogin() {
    this.router.navigateByUrl('login');
  }

}
