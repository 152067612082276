import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CAAnnotation, CAMetaMoly, CAMolyData, CAMolyInForm, CAMolyType, CAMolyValue, CAValidate} from '../../services/ca';
import {AdminService} from '../../services/admin.service';
import {RoehttpService} from '../../services/roehttp.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {InterfaceService} from '../../services/interface.service';

@Component({
  selector: 'app-ca-email',
  templateUrl: './ca-email.component.html',
  styleUrls: ['./ca-email.component.scss']
})
export class CaEmailComponent implements OnInit {
  @Input()
  path: string;

  @Input()
  formusesukey: string;

  @Input()
  moly: CAMolyInForm;

  @Input()
  primarycolor = '#0000FF';

  @Input()
  secondarycolor = '#ffffff';

  @Output()
  save = new EventEmitter<string>();

  data: CAMolyData;
  emaildesc: CAMetaMoly;
  emailmoly: CAMetaMoly;
  emaildescdone = false;
  emaildone = false;

  description: string;
  email: string;
  olddescription: string;
  oldemail: string;
  code: string;

  firsttime = true;
  curlang = 'en';
  editemail = false;
  editdesc = false;
  emailconfirm = false;

  emaildata: CAMolyData;
  emaildescdata: CAMolyData;
  ukey: string;

  scope = 'global';

  constructor(public admin: AdminService,
              private is: InterfaceService,
              public db: AngularFireDatabase,
              private http: RoehttpService) {
  }

  ngOnInit() {
    const molys = Object.values(this.moly.moly.molys);
    this.emaildesc = molys.find(m => m.jsonkey === 'emaildescription');
    this.emailmoly = molys.find(m => m.jsonkey === 'emailaddress');

    // todo: first make an attempt to get the data and if not find it, then create it
    this.is.getObject(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`)
      .take(1)
      .subscribe(val => {
        if (!val) {
          this.setUp();
        } else {
          this.ukey = val.ukey;
          let pth;
          if (val.scope === 'global') {
            pth = `${this.path}/data/emails/${this.ukey}`;
          } else if (val.scope === 'local') {
            pth = `${this.path}/formvals/${this.formusesukey}/emails/${this.ukey}`
          }
          this.scope = val.scope;
          this.is.getObject(pth)
            .take(1)
            .subscribe(email => {
              this.firsttime = false;
              // this.emaildesc = email.emaildescription;
              this.emaildescdata = new CAMolyData();
              this.emaildescdata.name = this.emaildesc.name;
              this.emaildescdata.molyukey = this.emaildesc.ukey;
              this.emaildescdata.value = email.emaildescription.value;
              this.description = this.emaildescdata.value[this.emaildescdata.value.length - 1].data;
              this.olddescription = this.description;
              this.editdesc = false;
              this.emaildescdone = true;

              // this.emaildata = email.emailaddress;
              this.emaildata = new CAMolyData();
              this.emaildata.name = this.emailmoly.name;
              this.emaildata.molyukey = this.emailmoly.ukey;
              this.emaildata.value = email.emailaddress.value
              this.email =  this.emaildata.value[ this.emaildata.value.length - 1].data;
              this.oldemail = this.email;
              this.editemail = false;
              this.emaildone = true;
            });
        }
      });
  }

  onInbox(str) {
    this.scope = str;
    this.firsttime = true
  }

  setUp() {
    this.ukey = this.db.createPushId();

    this.emaildescdata = new CAMolyData();
    this.emaildescdata.name = this.emaildesc.name;
    this.emaildescdata.molyukey = this.emaildesc.ukey;
    this.description = '';
    this.olddescription = this.description;

    if (!this.emaildescdata || !this.emaildescdata.value || this.emaildescdata.value.length === 0) {
      this.editdesc = true;
    }

    this.emaildata = new CAMolyData();
    this.emaildata.name = this.emailmoly.name;
    this.emaildata.molyukey = this.emailmoly.ukey;
    this.email = '';
    this.oldemail = this.email;

    if (!this.emaildata || !this.emaildata.value || this.emaildata.value.length === 0) {
      this.editemail = true;
    }
  }


  validateEmail(email): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onSave() {
    // save description
    let loc;
    if (this.scope === 'global') {
      loc = 'data';
    } else {
      loc = `formvals/${this.formusesukey}`;
    }
    this.is.setObjNoLog(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`, {ukey: this.ukey, scope: this.scope});

    const p = `${this.path}/${loc}/emails/${this.ukey}`;
    console.log('path', p);
    if (this.firsttime) {
      this.is.setObjNoLog(`${p}/emaildescription`, this.emaildescdata);
      this.is.setObjNoLog(`${p}/emailaddress`, this.emaildata);
      this.firsttime = false;
    } else {
      console.log('path', `${p}/emaildescription/value/${this.emaildescdata.value.length - 1}`);
      this.is.setObjNoLog(`${p}/emaildescription/value/${this.emaildescdata.value.length - 1}`,
        this.emaildescdata.value[this.emaildescdata.value.length - 1]);
      this.is.setObjNoLog(`${p}/emailaddress/value/${this.emaildata.value.length - 1}`,
        this.emaildata.value[this.emaildata.value.length - 1]);

    }

    // emit success
    // todo: not sure if have to emit the ukey
    this.save.emit(this.ukey);
  }

  onClickDesc() {
    this.editdesc = true;
  }

  onOptionDesc(event) {
    console.log('option', event)
    console.log('description', this.description)
    this.editdesc = false;
    this.descriptionSave();
  }

  onBlurDesc(event) {
    console.log('option')
    this.editdesc = false;
    this.descriptionSave();
  }

  onKeyUpDesc(event) {
    // todo: check if Done is triggered
    if ((event.code === 'Enter' || event.key === 'Enter' || event.code === 'Done' || event.key === 'Done') &&
      (this.description.length > 3)) {
      this.editdesc = false;
      this.descriptionSave();
    }
  }

  descriptionSave() {
    this.emaildescdone = true;
    if (this.olddescription !== this.description) {

      if (!this.emaildescdata.value) {
        this.emaildescdata.value = [];
      }

      const v = new CAMolyValue();
      v.data = this.description;

      // todo: change to international time? save the timezone?
      v.date = new Date().getTime();
      console.log('value', this.emaildescdata.value)
      this.emaildescdata.value.push(v);
      this.olddescription = this.description;
      this.editdesc = false;

      if (this.emaildone && this.emaildescdone) {
        console.log('saving');

        this.onSave();
      }
    }
  }

  emailSave() {
    if (this.email !== this.oldemail) {
      this.email = this.email.toLowerCase();
      if (this.validateEmail(this.email)) {
        // if pass error check then see if submit code is required
        this.code = this.admin.randomString(false, 3);
        console.log('code', this.code);
        this.emailconfirm = true;
      }
    } else {
      this.editemail = false;
    }
  }

  onBlurEmail(event) {
    console.log('blurb email')
    this.editemail = false;
    this.emailSave();
  }

  onKeyUp(event) {
    if (event.code === 'Enter' || event.key === 'Enter') {
      // todo: do an error check
      this.emailSave();
    }
  }

  onContinueEmail() {
    /* todo: put back
       this.http.postROEValidEmail(this.email, this.code, 'name','Capptivation')
          .then(val => {
            console.log('valid email', val);
          });
    */

    if (!this.emaildata.value) {
      this.emaildata.value = [];
    }

    const v = new CAMolyValue();
    v.data = this.email;

    // todo: change to international time? save the timezone?
    v.date = new Date().getTime();
    this.emaildata.value.push(v);

    this.emaildone = true;
    this.emailconfirm = false;
    this.editemail = false;

    // need to save it
    if (this.emaildone && this.emaildescdone) {
      this.onSave();
    }
  }

}
