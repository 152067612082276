import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input, NgZone,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ContentMap,
  LangStringBlock,
  MapCategoryElement,
  MapItem,
  Location,
  InterfaceService, Asset, Topix,
} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {AgmMap, GoogleMapsAPIWrapper, MapsAPILoader} from '@agm/core';
import * as geohash from 'ngeohash';
import {SwiperComponent, SwiperConfigInterface, SwiperDirective} from 'ngx-swiper-wrapper';
import * as _ from 'lodash';
import {RoehttpService} from '../services/roehttp.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {environment} from '../../environments/environment';
import {MatSidenav} from '@angular/material';
import {Transmodes} from '../services/constants';

declare const google: any;

export class MapCondition {
  ukey: string;
  mapukey: string;  // this is the ukey of the map creator/admin
  maplayer: string; // eg Content, BirdWatching, Earthquake
  type: string; // type of condition (point, area, line)
  kind: string; // kind of condition (eg tree down)
  marker: string; // For each kind there is an associated map marker
  desc: LangStringBlock; // description of condition
  locations: Location[];
  status: ConditionStatus[];
  initialdate: number;
  lastdate: number; // last time ConditionStatus was updated

  constructor() {
    this.desc = new LangStringBlock();
    this.locations = [];
    this.status = [];

  }
}

// meaning of Locations changes depending on MapCondition.type
// if type = area then points define a polygon

export class ConditionStatus {
  indivukey: string;
  userprofile: string; // this is the user profile of the
  username: string; //
  userpic: string; //
  date: number;
  safety: string;
  code: string; // see condition codes
  desc: LangStringBlock; // description of condition status

  constructor() {
    this.desc = new LangStringBlock();
  }
}

export class Face {
  indivukey: string;
  date: number;  // date when location added
  long: number;
  lat: number;
  icon: string; // photo of the pesrson
}


export class MapLink {
  name: string; // eg Yelp
  url: string;
  icon: string; // path to the Yelp icon
  obj;
}

export class InfoWindowObject {
  isopen: boolean;
  long: number;
  lat: number;
  name: string;
  address: string;
  description: string;
  links: MapLink[];

  constructor(open) {
    this.isopen = open;
    this.links = [];
  }
}

@Component({
  selector: 'app-map-card',
  templateUrl: './map-card.component.html',
  styleUrls: ['./map-card.component.scss']
})
export class MapCardComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  loc = 'Inst';

  @Input()
  curmap: ContentMap;

  @Input()
  ukey: string;

  @Input()
  cnt: 0;

  @Input()
  height: number;

  @Input()
  curlang = this.admin.curlang;

  @Input()
  width: number;

  @Input()
  metopic: Topix;

  @Input()
  bylines: Topix[];

  faces: Face[];

  curinfowindow: InfoWindowObject;
  curelement: MapCategoryElement;
  bounds;

  nodedetails = false;
  location: string;
  finlocation: string;
  finloc = false;
  err: string;
  hide = false;
  template = false;
  timezoneoffset = 0;

  mocklng = -73.8423614;
  mocklat = 41.2810114;


  // curnode.location.location

  // todo: shold get the center from the map definition
  lngg = -73;
  latt = 42;
  ix = 0;
  hash: string;
  spinner = false;
  geocoder;
  clickable = true;
  userlngg: number;
  userlatt: number;
  oldzoom: number;
  addstatus = false;
  routeheightfactor = 0;
  eventdate: number;
  findate: number;


  details = false;
  curitem: MapItem;
  mapitems: MapItem[];
  factor = 10;
  pr;
  yelparr: any[];

  route = false;
  curnode: MapItem;

  conditions: MapCondition[];
  curcond: MapCondition;
  primarycolor: string;
  secondarycolor: string;
  desc: LangStringBlock;

  galdetails = false;
  gpos = -1;
  tempgal: Asset;
  galname: LangStringBlock;
  galdescription: LangStringBlock;
  googlephotos;

  map;
  drawingManager;
  placeIdArray = [];
  polylines = [];
  snappedCoordinates = [];
  place;
  routelat = -1;
  routelong = -1;

  autotype = 'address';
  autocomplete;
  listen;
  marker: string;
  startdate: number;
  itemtype = 'Places';


  markerUrl = 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Assets%2F';
  //'https://maps.gstatic.com/mapfiles/ms2/micons/';
  markerclrs =
    {
      blue: '#00F',
      red: '#F00',
      green: '#0F0',
      lightblue: '#add8e6',
      yellow: '#FFFF00',
      purple: '#800080',
      pink: '#FFC0CB'
    };

  redmarker = 'http://ron.capptivation.com/assets/mapmarkers/red-dot.png';
  bluemarker = 'http://ron.capptivation.com/assets/mapmarkers/blue-dot.png';
  centericon = 'http://ron.capptivation.com/assets/mapmarkers/topcenter.png';
  meicon = 'http://ron.capptivation.com/assets/mapmarkers/me.png';
  bluemeicon = 'http://ron.capptivation.com/assets/mapmarkers/blueman.png';
  friendsicon = 'http://ron.capptivation.com/assets/mapmarkers/friends.png';

  mapstyle;
  oldlng: number;

  // TODO NOTE: maybe use this to turn off clicable icons: map.setClickableIcons(!clickableIcons);
  // TODO: maybce calculate my own geodisc: https://www.npmjs.com/package/geographiclib

  curkind;
  conditionkind = [
    {kind: 'Tree down', type: 'point', marker: 'car'},
    {kind: 'Electric wire down', type: 'point', marker: 'car'},
    {kind: 'Car accident', type: 'point', marker: 'car'},
  ];


  // ChIJ8XZsQN_qwokRp6Z0rzzwsfU - Lyndhust Mansion, Tarrytown NY

  mapicons = [
    'map_airshow',
    'map_arch',
    'map_basketball',
    'map_beach',
    'map_cafe',
    'map_camp',
    'map_castle',
    'map_catheddral',
    'map_college',
    'map_fineart_museum',
    'map_library',
    'map_monument',
    'map_movie',
    'map_park',
    'map_rollercoaster',
    'map_scuba',
    'map_shop',
    'map_skating',
    'map_ski',
    'map_tennis'
  ];


  pinit = false;
  address: string;
  verify = false;
  cursafety: string;
  curupdate: string;
  ftime: boolean;
  temp = 'regular';
  timer;

  err2: string;
  err3: string;
  lpos = -1;
  googlename: string;
  makerequest = false;
  goodtogo = false;

  collabs;

  updatetype = [
    'Report received',
    'Update',
    'Work being done',
    'Issue resolved'
  ];

  safetycode = [
    'Safe',
    'Dangerous',
    'Avoid',
    'Caution',
    'Hazardous',
    'Police Activity'
  ];


  //  transit_mode: bus, subway, train, tram

  transmodes = Transmodes;

  placemodes = [
    {icon: 'account_balance', name: 'Eiffel Tower', country: 'fr'},
    {icon: 'anchor', name: 'Louvre', country: 'fr'},
    {icon: 'event_seat', name: 'Palais de Trois', country: 'fr'},
    {icon: 'add_road', name: 'Champs Elisee', country: 'fr'},
  ];

  // image
  // image_not_supported

  nolandmarks = [
    {
      'featureType': 'administrative',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'poi',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'transit',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    }
  ];

  greytemp = [
    {
      'stylers': [
        {
          'lightness': 65
        },
        {
          'visibility': 'simplified'
        }
      ]
    },
    {
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f5f5f5'
        }
      ]
    },
    {
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#616161'
        }
      ]
    },
    {
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'color': '#f5f5f5'
        }
      ]
    },
    {
      'featureType': 'administrative.land_parcel',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#bdbdbd'
        }
      ]
    },
    {
      'featureType': 'poi',
      'stylers': [
        {
          'saturation': -80
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    },
    {
      'featureType': 'road',
      'stylers': [
        {
          'saturation': -95
        },
        {
          'lightness': 10
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffffff'
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#dadada'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#616161'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    },
    {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'transit.station',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#c9c9c9'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    }
  ];


  public config: SwiperConfigInterface = {
    slidesPerView: 1,
    direction: 'horizontal',
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    a11y: true,
    preventClicks: true,
    preventClicksPropagation: false,
  };

  @ViewChild(AgmMap, {static: false}) mapElement: any;
  @ViewChild('serviceHelper', {static: false}) serviceHelper;
  @ViewChild('addresstext', {static: false}) addresstext: any;
  @ViewChild('routestart', {static: false}) routestart: any;
  @ViewChild('routeend', {static: false}) routeend: any;
  @ViewChild('swip', {static: false}) swip: SwiperComponent;
  @ViewChild('snav', {static: false}) snav: MatSidenav;
  @ViewChild('tempnav', {static: false}) tempnav: MatSidenav;


  poly;
  $sub1;

  constructor(private cd: ChangeDetectorRef,
              private http: RoehttpService,
              private is: InterfaceService,
              private gl: GoogleMapsAPIWrapper,
              private nz: NgZone,
              private db: AngularFireDatabase,
              private mapsAPILoader: MapsAPILoader,
              public admin: AdminService) {

  }

  ngOnDestroy(): void {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
  }


  onCurPosition() {
    // todo: maybe should require location permssion to use the
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userlatt = position.coords.latitude;
        this.userlngg = position.coords.longitude;
      });
    }
  }


  ngOnInit() {
    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();

    this.onCurPosition();

    if (this.curmap.type === 'Travel') {
      this.ftime = true;
      this.$sub1 = this.is.getList(`Content/bodies/${this.ukey}/faces`).subscribe(faces => {
        this.faces = faces;
        if (this.ftime) {
          const x = this.bylines.findIndex(b => b.edition.includes(this.admin.admin.indivukey));
          this.ftime = false;
          if (x > -1) {
            this.bylines[x].confirmemployee = !this.bylines[x].confirmemployee;
            this.onCurrloc(x);
          }
        }
      });

      if (this.curmap.routes && this.curmap.routes.length) {
        this.curmap.routes.forEach(r => {
          if (r.overviewpoly) {
            r['poly'] = new google.maps.geometry.encoding.decodePath(r.overviewpoly);
          }
        });
      }
    }

    // if map is a condition map then get the list of conditions
    if (this.curmap.type === 'Local') {
      this.is.getList(`Maps/Condition/${this.ukey}`)
        .subscribe(conditions => {
          this.conditions = [];
          const d = this.admin.getIntTime() - 24 * 60 * 60 * 1000;
          if (conditions && conditions.length > 0) {
            conditions.forEach(cond => {
              if (cond.status) {
                cond.status = _.orderBy(Object.values(cond.status), 'date', 'desc');
              }

              if (cond.lastdate < d) {
                this.is.deleteObjNoLog(`Maps/${cond.maplayer}/${cond.mapukey}/${cond.ukey}`);
              } else {
                this.conditions.push(cond);
              }
            });
          }
        });
    }

    this.onUpdate();

  }

  canDelete() {
    if (this.curcond && this.curcond.status && this.curcond.status.length === 1 && this.curcond.status[0].indivukey === this.admin.admin.indivukey) {
      const time = this.admin.getIntTime();
      if (this.curcond.lastdate > time - 3 * 60 * 1000) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onDeleteCond() {
    this.is.deleteObjNoLog(`Maps/${this.curcond.maplayer}/${this.curcond.mapukey}/${this.curcond.ukey}`);
    this.onCancelCond();
  }

  onUpdate() {
    if (!this.curmap.template) {
      this.mapstyle = '';
    } else {
      this.mapstyle = JSON.parse(this.curmap.template.json);
    }
    if (this.curmap.type !== 'Time Series') {
      this.mapitems = _.orderBy(this.curmap.mapitems, 'date', 'asc');
      if (this.mapitems && this.mapitems.length > 0) {
        this.startdate = this.mapitems[0].date;
      }
    } else {
      this.onDrop(true);
    }
  }

  // zoom 15 or less {x:12, y:14}
  // zoom 16 {x:13, y:14}

  onZoomChange(event) {
    this.factor = 20 - event;
    this.curmap.zoom = event;
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onUpdate();

    /*
        if (!this.bounds && this.curmap && this.curmap.mapitems && this.curmap.mapitems.length) {
          if (this.mapElement) {
            this.mapElement._mapsWrapper.getNativeMap().then(map => {
              this.bounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(this.curmap.center.location.lat, this.curmap.center.location.long),
                new google.maps.LatLng(this.curmap.center.location.lat, this.curmap.center.location.long)
              );
            });
          }
        } else {
          this.fixbnds(this.curmap.mapitems[this.curmap.mapitems.length- 1].location.lat, this.curmap.mapitems[this.curmap.mapitems.length - 1].location.long)
        }
    */
  }

  fixbnds(lat, long) {
    const bnds = this.bounds;
    const latLng = new google.maps.LatLng(lat, long);
    bnds.extend(latLng);
    this.bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(bnds.getSouthWest().lat(), bnds.getSouthWest().lng()),
      new google.maps.LatLng(bnds.getNorthEast().lat(), bnds.getNorthEast().lng())
    );
  }

  onGetCenter(loc) {
    this.latt = -1;
    this.lngg = -1;
    this.hash = '';
    if (this.mapElement) {
      this.onCalc(loc).then(obj => {
        this.latt = obj[0];
        this.lngg = obj[1];
        this.hash = geohash.encode(this.latt, this.lngg);
        this.cd.detectChanges();
      });
    }
  }


  getColor(mode) {
    return this.transmodes.find(t => t.mode === mode)['color'];
  }

  onInfoClose() {
    this.curinfowindow = null;
  }

  async onCalc(loc): Promise<object> {
    this.spinner = true;
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({'address': loc}, (results, status) => {
        if (status === 'OK') {
          resolve([results[0].geometry.location.lat(), results[0].geometry.location.lng()]);
        } else {
          reject(new Error('Couldnt\'t find the location ' + loc));
        }
      });
    });
  }


  setUpClock() {
    if (this.curmap.type === 'Travel') {
      this.oldlng = -1;
      this.onGetTimeZone(this.curmap.center.location.lat, this.curmap.center.location.long);
    }
  }

  onCheckTime(f) {
    // todo: if date is more than an hour old, don't show it;
    const d = this.admin.getIntTime() - 60 * 60 * 1000;
    return (f.date > d);
  }

  onTimer() {
    this.timer = setInterval(val => {
      console.log('time time');
      const i = this.bylines.findIndex(b => b.edition.includes(this.admin.admin.indivukey));
      if (i > -1) {
        this.myPos(i);
      }
    }, 10 * 60 * 60 * 1000);
  }

  myPos(i) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userlatt = position.coords.latitude;
        this.userlngg = position.coords.longitude;
      });

      // todo: get rid of these two lines
      this.userlatt = this.mocklat;
      this.userlngg = this.mocklng;


      const face = new Face();
      face.indivukey = this.admin.admin.indivukey;
      face.long = this.userlngg;
      face.lat = this.userlatt;
      face.icon = this.bylines[i].icon;
      face.date = this.admin.getIntTime();
      this.is.setObjNoLog(`Content/bodies/${this.ukey}/faces/${this.admin.admin.indivukey}`, face);
      this.bylines[i].confirmemployee = true;
      this.is.setObjNoLog(`Content/items/${this.ukey}/content/collaborators/${this.bylines[i]['pos']}/confirmemployee`, true);
      this.snav.close();
      this.hide = false;
    }

  }

  onCurrloc(i) {
    if (this.bylines[i].edition.includes(this.admin.admin.indivukey)) {
      if (this.bylines[i].confirmemployee) {
        this.is.deleteObjNoLog(`Content/bodies/${this.ukey}/faces/${this.admin.admin.indivukey}`);
        this.bylines[i].confirmemployee = false;
        this.is.setObjNoLog(`Content/items/${this.ukey}/content/collaborators/${this.bylines[i]['pos']}/confirmemployee`, false);
        this.snav.close();
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        this.hide = false;
      } else {
        this.myPos(i);
        this.onTimer();
      }
    }
  }

  ngAfterViewInit() {
    // this.initialize();
    this.mapsAPILoader.load()
      .then(map => {
        this.geocoder = new google.maps.Geocoder();

        if (this.geocoder && this.userlatt) {
          this.getAddress(this.userlatt, this.userlngg, false);
        }
      }).catch(err => {
    });

    this.setUpClock();


    // https://jsfiddle.net/api/post/library/pure/ - this calcualtes and displays a route
    // https://developers.google.com/maps/documentation/javascript/examples/event-poi
    // https://stackoverflow.com/questions/6777721/google-maps-api-v3-infowindow-close-event-callback
    // const gl = google.maps.Map()

  }

  getCountry(addrComponents) {
    for (let i = 0; i < addrComponents.length; i++) {
      if (addrComponents[i].types[0] === 'country') {
        return addrComponents[i].short_name.toLowerCase();
      }
      if (addrComponents[i].types.length === 2) {
        if (addrComponents[i].types[0] === 'political') {
          return addrComponents[i].short_name.toLowerCase();
        }
      }
    }
    return '';
  }

  getState(addrComponents) {
    for (let i = 0; i < addrComponents.length; i++) {
      if (addrComponents[i].types[0] === 'administrative_area_level_1') {
        return addrComponents[i].short_name.toLowerCase();
      }
    }
    return '';
  }

  getAddress(latitude, longitude, finloc, getcurcountry = false) {
    this.geocoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          if (this.route) {
            // if finloc === true then this is the destination of a route segment
            if (finloc) {
              this.finlocation = this.address;
              this.curnode.finlocation.long = longitude;
              this.curnode.finlocation.lat = latitude;
            } else {
              this.location = this.address;
              this.curnode.location.long = longitude;
              this.curnode.location.lat = latitude;
            }

            this.curnode.country = this.getCountry(results[0].address_components);
            if (this.curnode.country) {
              const state = this.getState(results[0].address_components);
              if (state) {
                this.curnode.state = `${this.curnode.country}/${state}`;
              }
            }
          }

          this.cd.detectChanges();
          if (this.pinit) {
            this.curcond.locations[0].location = this.address;
            this.pinit = false;
            this.onLogCond();
          } else if (!getcurcountry) {
            this.onPlaceDetails(results[0].place_id, latitude, longitude);
          }

        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  // YELP
  // client ID Qa6s4dYtNA7W9WmnIUn4Fg
  // API key: _y3yIba7Tr18x4loTzUmI-N5lin7c27BwFUKWCWCSV-nUWl6w1VhoCuVQEGapadmaai85oN9_w_8_h05CrpGixRfpF4VpM0d5xH02PX7HLwBQmr7TND-98S4v6btX3Yx

  onMapClick(event) {
    if (this.template) {
      this.tempnav.close().then(val => {
        this.template = false;
      });
    } else if (this.hide) {
      this.snav.close().then(val => {
        this.hide = false;
      });
    } else {
      // todo: only want to call this function when the user is using the consol
      if (this.route) {
        this.getAddress(event.coords.lat, event.coords.lng, this.finloc);
      } else if (this.clickable) {
        this.getAddress(event.coords.lat, event.coords.lng, false);
      } else if (this.pinit) {
        this.curcond.kind = this.curkind.kind;
        this.curcond.type = this.curkind.type;
        this.curcond.marker = `../assets/${this.curkind.marker}.svg`;

        const loc = new Location();
        loc.long = event.coords.lng;
        loc.lat = event.coords.lat;
        this.curcond.locations.push(loc);
        this.getAddress(event.coords.lat, event.coords.lng, false);
      }
    }
  }

  // todo: in additino to getting the url, we could also get the rating and show side by side of ratings of google, yelp, etc.
  findYelp(place): boolean {
    const x = this.yelparr.findIndex(y => (y.display_phone === place.formatted_phone_number) || (y.name === place.name));
    if (x > -1) {
      const lnk = new MapLink();
      lnk.name = 'yelp';
      lnk.url = this.yelparr[x].url;
      lnk.icon = '';
      this.curinfowindow.links.push(lnk);
      this.cd.detectChanges();
      return false;
    } else {
      return true;
    }
  }


  onBoundChange(event) {
  }


  // https://developers.google.com/places/supported_types#table1
  // https://developers.google.com/places/web-service/details
  onPlaceDetails(placeId, latitude, longitude) {
    const request = {
      placeId,
      fields: ['name', 'photo', 'website', 'formatted_phone_number', 'formatted_address', 'url', 'type', 'plus_code', 'utc_offset_minutes']
    };

    const service = new google.maps.places.PlacesService($('#service-helper').get(0));
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (this.route) {
          this.place = place;
          this.googlephotos = [];
          this.googlename = '';
          this.ix = 0;
          if (this.place.photos && this.place.photos.length > 0) {
            this.place.photos.forEach(p => {
              const str = p.html_attributions.join(',');
              const who = str.replace(/(<([^>]+)>)/gi, '');
              this.googlephotos.push({url: p.getUrl(), who});
            });
          }
          // todo: something is wrong with the change detection
          this.routeheightfactor = 1;
          this.nz.run(cb => {
            console.log('cd', cb);
          });
          this.cd.detectChanges();
        } else {
          this.curinfowindow = new InfoWindowObject(true);
          this.curinfowindow.name = place.name;
          this.curinfowindow.address = place.formatted_address;
          this.curinfowindow.lat = latitude;
          this.curinfowindow.long = longitude;
          const ty = place.types.join(',');
          // todo: what other types???
          if (ty &&
            (ty.includes('restaurant') ||
              ty.includes('bar') ||
              ty.includes('cafe') ||
              ty.includes('bakery') ||
              ty.includes('food'))) {

            let yelpsearch = true;
            if (this.yelparr && this.yelparr.length > 0) {
              yelpsearch = this.findYelp(place);
            }

            if (yelpsearch) {
              this.http.getMapLink(longitude, latitude).then(res => {
                if (res && res.businesses && res.businesses.length > 0) {
                  this.yelparr = res.businesses;
                  this.findYelp(place);
                }
              });
            }
          }

          if (place.website) {
            const lnk = new MapLink();
            lnk.name = 'web';
            lnk.url = place.website;
            lnk.icon = '';
            this.curinfowindow.links.push(lnk);
          }
          if (place.url) {
            const lnk = new MapLink();
            lnk.name = 'url';
            lnk.url = place.url;
            lnk.icon = '';
            this.curinfowindow.links.push(lnk);
          }
          this.cd.detectChanges();
        }
      }
    });
  }

  onPin() {
    this.curkind = null;
    this.cursafety = '';
    this.curupdate = '';
    this.verify = false;
    this.desc = new LangStringBlock();
    this.curcond = new MapCondition();
    this.curcond.maplayer = 'Condition';
    this.curcond.mapukey = this.ukey;
    this.curcond.ukey = this.db.createPushId();
    this.curcond.locations = [];

    if (!this.conditions) {
      this.conditions = [];
    }
    // this.conditions.push(this.curcond);

    this.clickable = false;
    this.pinit = false;
  }

  onMarkerClick(i) {
    this.curitem = this.curmap.mapitems[i];
    this.details = true;
  }

  onClose() {
    this.details = false;
  }

  openGoogle() {
    if (this.curitem.googleurl) {
      window.open(this.curitem.googleurl, '_blank');
    } else {
      window.open(`https://www.google.com/maps/search/?api=1&query=${this.curitem.location.lat},${this.curitem.location.long}`, '_blank');
    }
  }

  onRain(event) {
    event.stopPropagation();
    this.onDrop(false);
  }

  onContinueCond() {
    this.pinit = true;
    this.curmap.center.location.lat = this.userlatt;
    this.curmap.center.location.long = this.userlngg;
    this.oldzoom = this.curmap.zoom;
    this.curmap.zoom = 15;
  }

  finishLog() {
    const status = new ConditionStatus();
    status.desc = this.desc;
    status.safety = this.cursafety;
    status.username = this.pr.name;
    status.userpic = this.pr.profilepic;
    status.indivukey = this.admin.admin.indivukey;
    status.userprofile = 'Base';
    status.date = this.admin.getIntTime();
    this.curcond.lastdate = status.date;
    if (!this.curcond.status || this.curcond.status.length === 0) {
      status.code = 'Initial report';
      this.curcond.desc = this.desc;
      this.curcond.initialdate = this.curcond.lastdate;
      this.curcond.status.push(status);
      // triggers the updateConditionMap cloud function
      this.is.setObjNoLog(`Maps/${this.curcond.maplayer}/${this.curcond.mapukey}/${this.curcond.ukey}`, this.curcond);
    } else {
      // save the updated status
      status.code = this.curupdate;
      this.curcond.status.push(status);
      // triggers the updateConditionMap cloud function
      this.is.setObjNoLog(`Maps/${this.curcond.maplayer}/${this.curcond.mapukey}/${this.curcond.ukey}/lastdate`, status.date);
      this.is.setObjNoLog(`Maps/${this.curcond.maplayer}/${this.curcond.mapukey}/${this.curcond.ukey}/status/${status.date}`, status);
    }

    this.verify = false;
    this.clickable = true;
    this.addstatus = false;
    this.curmap.zoom = this.oldzoom;
    this.cd.detectChanges();
  }

  onLogCond() {
    // todo: using getField is a security problem and needs to be resolved.
    if (!this.pr) {
      this.http.getField(`Individual/${this.admin.admin.indivukey}/profiles/base`)
        .take(1)
        .subscribe(pr => {
          this.pr = pr;
          this.finishLog();
        });
    } else {
      this.finishLog();
    }
  }

  onCondMakerClick(event, c: MapCondition) {
    this.curcond = c;
    this.clickable = false;
  }

  onCancelCond() {
    if (this.route) {
      this.route = false;
      this.routeheightfactor = 0;
    } else {
      this.clickable = true;
      this.addstatus = false;
      this.pinit = false;
      this.verify = false;
      if (!this.curcond.status && this.curcond.status.length === 0) {
        this.conditions.pop();
      }
    }
    this.cd.detectChanges();
  }

  onDrop(ftime: boolean) {
    this.mapitems = [];
    const len = this.curmap.mapitems.length;
    let cnt;
    if (ftime) {
      cnt = 1;
      this.mapitems.push(this.curmap.mapitems[0]);
    } else {
      cnt = 0;
    }
    const dn = setInterval(val => {
      if (cnt === len) {
        clearInterval(dn);
      } else {
        this.mapitems.push(this.curmap.mapitems[cnt]);
        cnt = cnt + 1;
      }
    }, 2000);
  }

  onAddStatus() {
    this.addstatus = true;
    this.cursafety = '';
    this.curupdate = '';
    this.desc = new LangStringBlock();
  }

  getSafetyColor(c: MapCondition): string {
    if (c.status[0].safety === 'Safe') {
      return '#00FF00';
    } else {
      return '#FF0000';
    }
  }

  onRoute(type: string) {
    this.itemtype = type;
    this.snav.close();
    this.hide = false;
    this.route = true;
    this.pinit = false;
    this.place = null;
    this.nodedetails = false;
    this.address = '';
    this.location = '';
    this.finlocation = '';

    this.routelat = -1;
    this.routelong = -1;
    this.routeheightfactor = 1;
  }

  onMapReady(event) {
    this.map = event;

    google.maps.event.addListener(this.map, 'click', e => {
      if (e.placeId) {
        e.stop();
      }
    });

    /*
        if (this.curmap.type === 'Travel') {
          this.initialize();
        }
    */
  }


  runSnapToRoad(path) {
    const pathValues = [];
    for (let i = 0; i < path.getLength(); i++) {
      pathValues.push(path.getAt(i).toUrlValue());
    }

    $.get('https://roads.googleapis.com/v1/snapToRoads', {
      interpolate: true,
      key: 'AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ',
      path: pathValues.join('|')
    }, data => {
      console.log('snap data', data);
      // processSnapToRoadResponse(data);
      // drawSnappedPolyline();
    });
  }


  initialize() {
    // Adds a Places search box. Searching for a place will center the map on that
    // location.
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
      document.getElementById('bar'));

    // Enables the polyline drawing control. Click on the map to start drawing a
    // polyline. Each click will add a new vertice. Double-click to stop drawing.
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYLINE,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYLINE
        ]
      },
      polylineOptions: {
        strokeColor: '#696969',
        strokeWeight: 4,
        strokeOpacity: 0.3,
      }
    });

    drawingManager.setMap(this.map);

    // Snap-to-road when the polyline is completed.
    drawingManager.addListener('polylinecomplete', poly => {
      const path = poly.getPath();
      this.polylines.push(poly);
      this.placeIdArray = [];
      // this command closes the drawing manager
      // drawingManager.setMap(null);

      this.runSnapToRoad(path);
    });


    // Clear button. Click to remove all polylines.
    document.getElementById('clear').addEventListener('click', event => {
      event.preventDefault();
      for (let i = 0; i < this.polylines.length; ++i) {
        this.polylines[i].setMap(null);
      }
      this.polylines = [];
      return false;
    });
  }

  onAddNode() {
    this.curnode = new MapItem('', null);
    if (!this.curmap.mapitems || this.curmap.mapitems.length === 0) {
      this.curnode.ssoc[this.curlang].description = 'Start of trip';
      this.nodedetails = true;
    }
    this.lpos = -1;
    this.googlephotos = null;
    this.location = '';
    this.finlocation = '';
    this.nodedetails = true;
  }

  onRoutePick() {
    this.routeheightfactor = 0;
  }

  recalcRoute() {
    if (this.curmap.routes && this.curmap.routes.length > 1) {
      this.curmap.routes.forEach(route => {

      });
    }

    /*
            this.curmap.routes = _.orderBy(this.curmap.routes, 'date', 'desc')
            const y = this.curmap.routes.findIndex(m => !m.typepoint);
            const path = `${this.curmap.mapitems[y].location.lat}, ${this.curmap.mapitems[y].location.long} | ${this.curnode.location.lat}, ${this.curnode.location.long}`;
            $.get('https://roads.googleapis.com/v1/snapToRoads', {
              path,
              interpolate: true,
              key: environment.GOOGLE_ROADS_API
            }, data => {
              console.log('snap data', data);
              // processSnapToRoadResponse(data);
              // drawSnappedPolyline();
            });
    */
  }

  finishIt() {
    if (!this.curmap.routes) {
      this.curmap.routes = [];
    }

    if (this.curnode.ukey) {
      const x = this.curmap.routes.findIndex(r => r.ukey === this.curnode.ukey);
      this.curmap.routes[x] = this.curnode;
    } else {
      this.curnode.ukey = this.db.createPushId();
      this.curmap.routes.push(this.curnode);
    }

    this.nodedetails = false;
    this.curnode = null;
    this.details = false;
    this.cd.detectChanges();
  }


  onRouteSave() {
    // todo: this will get messed up if the start date changes
    /*
        if (this.startdate) {
          this.curnode.dayssince = this.admin.calcDaysSince(this.startdate, this.curnode.date);
        }
    */
    this.err = '';
    this.curnode.location.location = this.location;

    if (this.collabs) {
      this.curnode.companions = this.collabs.join(',');
    }

    if (this.itemtype === 'Trip') {
      // todo: need to do some error checks to make sure the form is filled out correctly
      if (!this.finlocation || this.curnode.location.lat === -1) {
        this.err = 'Please enter a valid destination';
        return;
      }

      if (!this.curnode.mode) {
        this.err = 'Please select a mode of transporation';
        return;
      }

      const origin = `${this.curnode.location.lat},${this.curnode.location.long}`;
      const destination = `${this.curnode.finlocation.lat},${this.curnode.finlocation.long}`;
      const modex = this.transmodes.findIndex(t => t.mode === this.curnode.mode);
      let mode;
      if (modex > -1) {
        mode = this.transmodes[modex].mode;
      }

      /*
      //  transit_mode: bus, subway, train, tram
      */

      if (mode === 'flight' ||
        mode === 'boat' ||
        mode === 'other') {

        // https://developers.google.com/maps/documentation/javascript/examples/geometry-headings
        const geodesicPoly = new google.maps.Polyline({
          strokeColor: '#CC0099',
          strokeOpacity: 1.0,
          strokeWeight: 3,
          geodesic: true,
          map: this.map,
        });
        const path = [
          {lat: this.curnode.location.lat, lng: this.curnode.location.long},
          {lat: this.curnode.finlocation.lat, lng: this.curnode.finlocation.long}
        ];
        this.poly = path;
        // geodesicPoly.setPath(path);
        this.finishIt();

      } else if (mode === 'driving' ||
        mode === 'walking' ||
        mode === 'bicycling' ||
        mode === 'bus' ||
        mode === 'subway' ||
        mode === 'train' ||
        mode === 'tram') {
        let transmode = '';
        if (mode === 'bus') {
          mode = 'transit';
          transmode = 'bus';
        } else if (mode === 'subway') {
          mode = 'transit';
          transmode = 'subway';
        } else if (mode === 'train') {
          mode = 'transit';
          transmode = 'train';
        } else if (mode === 'tram') {
          mode = 'transit';
          transmode = 'tram';
        }

        this.http.getRoute(origin, destination, mode, transmode)
          .then(r => {
              const rp = r.routes[0].overview_polyline.points;
              this.curnode['poly'] = new google.maps.geometry.encoding.decodePath(rp);
              this.curnode.overviewpoly = rp;
              this.finishIt();
          });
      } else {
        this.finishIt();
      }
    } else if (this.itemtype === 'Places') {
      if (!this.curmap.mapitems) {
        this.curmap.mapitems = [];
      }

      if (this.curnode.ukey) {
        const x = this.curmap.mapitems.findIndex(r => r.ukey === this.curnode.ukey);
        this.curmap.mapitems[x] = this.curnode;
      } else {
        this.curnode.ukey = this.db.createPushId();
        this.curmap.mapitems.push(this.curnode);
      }

      this.nodedetails = false;
      this.curnode = null;
      this.details = false;
      this.cd.detectChanges();
    }


    //  todo: have to get the profile photos of the traveleres
    /*
        if (this.curmap.mapitems && this.curmap.mapitems.length > 0) {
          this.curmap.mapitems.forEach(item => {
            if (item.companions && item.companions.length > 0) {

            }
          });
        }
    */

  }

  onIndexChange(event) {
    this.ix = event;
  }

  onAddGoogleToGallery() {
    this.err2 = '';
    if (!this.googlename || this.googlename.length === 0) {
      this.err2 = 'Please provide a name that will help identify this image.';
      return;
    }

    const p = this.googlephotos[this.ix];
    // todo: need actual values
    const asset = new Asset('',  '', '');
    asset.url = p.url;
    asset.description[this.curlang].description = this.googlename;
    asset.ukey = this.db.createPushId();
    asset.alt[this.curlang].description = this.place.formatted_address; // for ADA compliance
    asset.location.location = this.place.formatted_address;
    if (this.place && this.place.geometry && this.place.geometry.location) {
      asset.location.lat = this.place.geometry.location.lat();
      asset.location.long = this.place.geometry.location.lng();
    }
    asset.copyright = `Google Maps: ${p.who}`;
    asset.type = 'image'; // image, video, pdf, document
    this.curnode.items.push(asset);
    this.googlephotos.splice(this.ix, 1);
    this.googlename = '';
    this.cd.detectChanges();
  }

  onEditPhoto(i) {
    this.gpos = i;
    this.tempgal = this.curnode.items[i];
    this.galname = this.tempgal.name;
    this.galdescription = this.tempgal.description;
    this.galdetails = true;
  }

  onCancel() {
    if (this.nodedetails) {
      this.nodedetails = false;
      this.curnode = null;
    } else {
      this.route = false;
      this.routeheightfactor = 0;
      this.details = false;
      this.curnode = null;
    }
  }

  onDeletePhoto(i) {
    this.curnode.items.splice(i, 1);
    this.swip.directiveRef.update();
  }

  onAddGallery() {
    this.gpos = -1;
    // todo: use real parameters
    this.tempgal = new Asset('', '', '');
    this.galname = new LangStringBlock();
    this.galdescription = new LangStringBlock();
    this.galdetails = true;
  }

  onEditItem(i) {
    this.lpos = i;
    this.googlephotos = [];
    this.curnode = this.curmap.mapitems[i];
    if (this.curnode.items) {
      this.curnode.items = Object.values(this.curnode.items);
    } else {
      this.curnode.items = [];
    }

    if (this.curnode.date) {
      this.eventdate = this.curnode.date;
    }
    this.location = this.curnode.location.location;
    this.nodedetails = true;
    this.details = true;
  }

  onEditRoute(i) {
    this.lpos = i;
    this.googlephotos = [];
    this.curnode = this.curmap.routes[i];
    if (this.curnode.items) {
      this.curnode.items = Object.values(this.curnode.items);
    } else {
      this.curnode.items = [];
    }

    if (this.curnode.date) {
      this.eventdate = this.curnode.date;
    }
    this.location = this.curnode.location.location;

    if (this.curnode.findate) {
      this.findate = this.curnode.findate;
    }
    this.finlocation = this.curnode.finlocation.location;
    this.nodedetails = true;
    this.details = true;
  }

  onDeleteItem(i) {
    console.log('delete item');
  }

  onChangeDate(event) {
    if (this.curnode) {
      this.curnode.date = event;
    }
  }

  onChangeFinDate(event) {
    if (this.curnode) {
      this.curnode.findate = event;
    }
  }

  onGalleryItem(event) {
    this.tempgal = event;
    if (this.tempgal) {
      this.galname = this.tempgal.description;
    } else {
      this.galname = null;
    }
  }

  onSaveGal() {
    this.err3 = '';
    if (!this.curnode.items) {
      this.curnode.items = [];
    }

    if (this.tempgal) {
      if (this.galname[this.curlang].description) {
        this.tempgal.name = this.galname;
      }
      if (this.galdescription[this.curlang].description) {
        this.tempgal.description = this.galdescription;
      }

      if (this.gpos === -1) {
        this.curnode.items.push(this.tempgal);
      } else {
        this.curnode.items[this.gpos] = this.tempgal;
      }
    } else {
      if (!this.galname[this.curlang].description) {
        this.err3 = 'Please enter a name.';
        return;
      }
      if (!this.galdescription[this.curlang].description) {
        this.err3 = 'Please enter a description.';
        return;
      }
      // todo create an asset if there is a galname and a galdescription
      // todo: use real parameters
      const asset = new Asset('', '', '');
      asset.name = this.galname;
      asset.description = this.galdescription;
      asset.ukey = this.db.createPushId();
      asset.alt = this.galname; // for ADA compliance
      asset.type = 'text'; // image, video, pdf, document
      this.curnode.items.push(asset);
    }

    this.galdetails = false;
  }

  onAutoChange(finloc: boolean) {
    // true if this is a destination of a route
    this.finloc = finloc;
    this.getPlaceAutocomplete();
  }

  getPlaceAutocomplete() {
    this.place = null;
    let el;
    if (this.itemtype === 'Places') {
      el = this.addresstext.nativeElement;
    } else {
      if (this.finloc) {
        el = this.routeend.nativeElement;
      } else {
        el = this.routestart.nativeElement;
      }
    }
    this.autocomplete = new google.maps.places.Autocomplete(el,
      {});
    this.listen = google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.place = this.autocomplete.getPlace();
      if (this.place) {
        if (this.finloc) {
          this.finlocation = this.place.formatted_address;
          this.curnode.finlocation.location = this.place.formatted_address;
          this.curnode.finlocation.long = this.place.geometry.location.lng();
          this.curnode.finlocation.lat = this.place.geometry.location.lat();
          this.cd.detectChanges();
        } else {
          this.location = this.place.formatted_address;
          this.curnode.location.location = this.place.formatted_address;
          this.curnode.location.long = this.place.geometry.location.lng();
          this.curnode.location.lat = this.place.geometry.location.lat();
          this.curnode.timezoneoffset = this.place.utc_offset_minutes;
          this.curnode.country = this.getCountry(this.place.address_components);
          if (this.curnode.country) {
            const state = this.getState(this.place.address_components);
            if (state) {
              this.curnode.state = `${this.curnode.country}/${state}`;
            }
          }
          if (this.place.photos && this.place.photos.length > 0) {
            this.googlephotos = [];
            this.googlename = '';
            this.ix = 0;
            this.place.photos.forEach(p => {
              const str = p.html_attributions.join(',');
              const who = str.replace(/(<([^>]+)>)/gi, '');
              this.googlephotos.push({url: p.getUrl(), who});
            });
          }
          this.cd.detectChanges();
        }

      } else {
        console.log('error: no place');
      }
    });
  }


  onGetTimeZone(lat, lng) {
    const x = Math.abs(this.oldlng - lng) > 3;
    if (x && !this.makerequest) {
      this.makerequest = true;
      this.http.getTimeZone(lat, lng, environment.GOOGLE_ROADS_API)
        .then(r => {
          if (r && r['rawOffset']) {
            this.oldlng = lng;
            this.timezoneoffset = r['rawOffset'] / 60 + r['dstOffset'] / 60;
            this.goodtogo = true;
          } else {
            console.log('r r r', r);
            this.oldlng = -1;
            this.goodtogo = false;
          }
          this.makerequest = false;
          this.cd.detectChanges();
        });
    }
  }

  onCenterChange(event) {
    this.onGetTimeZone(event.lat, event.lng);
  }

}
