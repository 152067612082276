import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import * as _ from 'lodash';

import {trigger, transition, query, style, animate, group} from '@angular/animations';
import {AngularFireDatabase} from '@angular/fire/database';
import {DeviceService} from '../services/device.service';
import {CappTeamMsg, CappTeamParty, InterfaceService, Widget} from '../services/interface.service';
import {SocialComponent} from './social/social.component';
import {AdminService} from '../services/admin.service';
import {InstService} from '../services/inst.service';

const left = [
  query(':enter, :leave', style({position: 'absolute', width: '100%'}), {optional: true}),
  group([
    query(':enter', [style({transform: 'translateX(-100%)'}), animate('.3s ease-out', style({transform: 'translateX(0%)'}))], {
      optional: true,
    }),
    query(':leave', [style({transform: 'translateX(0%)'}), animate('.3s ease-out', style({transform: 'translateX(100%)'}))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({position: 'absolute', width: '100%'}), {optional: true}),
  group([
    query(':enter', [style({transform: 'translateX(100%)'}), animate('.3s ease-out', style({transform: 'translateX(0%)'}))], {
      optional: true,
    }),
    query(':leave', [style({transform: 'translateX(0%)'}), animate('.3s ease-out', style({transform: 'translateX(-100%)'}))], {
      optional: true,
    }),
  ]),
];


@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.css'],
  animations: [
    trigger('animImageSlider', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
  ]
})
export class NotifyComponent implements OnInit, OnDestroy {
  @Input()
  loc = 'Orgs';

  @Input()
  folder = 'notifications';

  @Input()
  appht = 0;

  @Output()
  menuopen = new EventEmitter();

  @Output()
  assessment = new EventEmitter();

  @Output()
  survey = new EventEmitter();

  shownote = true;
  addep = false;

  $sub0;
  $sub1;
  org: string;
  justadmin = false;
  addcontrol = false;
  curwidget: Widget;
  notices: CappTeamMsg[];
  curnotice: CappTeamMsg;
  spinner = false;
  add = false;
  err: string;
  assigns;
  parties: CappTeamParty[];
  me: CappTeamParty[];
  newval = false;

  readytoadd = false;
  addcomment = false;

  counter = 0;
  a = 0;
  b = 1;
  tog = 'social';
  path: string;
  ukey: string;

  confirm = false;
  holder: string;
  code: string;
  sento: string;

  $sub2;
  $key;
  kyht = 0;
  notepath = '';

  flters = [
    {name: 'Current', val: true},
    {name: 'Archived', val: false},
    {name: 'Past 24 hours', val: false},
    {name: 'Past week', val: false},
    {name: 'Past year', val: false},
  ];

  @ViewChild('social', {static: false}) private social: SocialComponent;

  constructor(private ds: DeviceService,
              private db: AngularFireDatabase,
              private cd: ChangeDetectorRef,
              private admin: AdminService,
              private inst: InstService,
              private is: InterfaceService) {
  }


  ngOnInit() {
    this.notices = [];
    this.parties = [];
    this.readytoadd = true;

    if (this.loc === 'Inst') {
      this.$sub2 = this.inst.curinst$.subscribe(val => {
          this.notepath = `${this.loc}Pri/${this.admin.admin.org}/${this.folder}`;
      });
    } else if (this.loc === 'ROEDevices') {
      this.notepath = `ROEDevices/${this.admin.admin.deviceid}/notifications`;
    } else {
      this.notepath = `${this.loc}Pri/${this.admin.admin.org}/${this.folder}`;
    }
    this.getNotes(this.notepath);

  }

  getNotes(pth) {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    this.spinner = true;
    this.$sub1 = this.is.getList(pth)
      .subscribe(notices => {
        this.notices = notices;
        this.notices = this.notices.map(m => {
          if (m['cappteammsg']) {
            return m['cappteammsg'];
          } else {
            return m;
          }
        });
        this.notices = _.orderBy(this.notices, 'date', 'desc'); // .filter(n => n.app === caller)
        this.spinner = false;
        this.cd.detectChanges();
      });
  }


  onDelete(i) {
    const ukey = this.notices[i].ukey;
    const d = this.notices[i].date;
    this.notices.splice(i, 1);
    this.is.deleteObjNoLog(`${this.notepath}/${ukey}`);
    this.is.deleteObjNoLog(`${this.notepath}/${d}`);
  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
  }
}

