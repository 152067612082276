import {Component, Input, OnInit} from '@angular/core';
import {InterfaceService, Wish} from '../services/interface.service';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss']
})
export class PublisherComponent implements OnInit {
  @Input()
  ukey: string;

  @Input()
  src: string;

  articles: Wish[];

  constructor(private is: InterfaceService) {
  }

  ngOnInit() {
    console.log('src', this.src)
    if (this.src === 'Topics') {
      this.is.getList(`Places/${this.ukey}/guides`).take(1).subscribe(articles => {
        this.articles = articles;
      });
    } else {
      this.is.getList(`Institutions/${this.ukey}/published`).take(1).subscribe(articles => {
        this.articles = articles;
      });
    }
  }

  onDeleteGuide(i) {
    this.articles.splice(i, 1);
  }
}
