import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminService} from '../../../services/admin.service';
import {InterfaceService, Invitation} from '../../../services/interface.service';
import {BizYear} from '../../../services/bizhours.service';

@Component({
    selector: 'app-biz-year',
    templateUrl: './biz-year.component.html',
    styleUrls: ['./biz-year.component.css']
})
export class BizYearComponent implements OnInit {
    holidays: Invitation[];

    @Input()
    curYear: BizYear;

    @Output()
    pickDay = new EventEmitter<any>();

    constructor(private admin: AdminService,
                private is: InterfaceService) {
    }

    ngOnInit() {
        this.is.getList('Orgs/' + this.admin.admin.org + '/holidays').take(1).subscribe(data => {
            this.holidays = new Array();
            this.holidays = data;
        });

        /*
                console.log('curyear x', this.curYear);
                const curday = new Date;
                const mth = curday.getMonth();
                let year = curday.getFullYear();

                this.curYear.twentyfourseven = false;
                this.curYear.regulardays = new Array();
                for (let i = 0; i < 7; i++) {
                    const newday = new BizDay();
                    this.curYear.regulardays.push(newday);
                }


                this.curYear.months = new Array();
                for (let i = 0; i < 12; i++) {
                    let month = new BizMonth;
                    month.month = new Date;
                    month.days = new Array();

                    month.month.setFullYear(year, mth + i, 1);
                    let y = month.month.getFullYear();
                    let m = month.month.getMonth();
                    for (let d = 0; d < this.daypermonth[m]; d++) {
                        let nbd = new BizDay;
                        nbd.day = new Date;
                        nbd.color = '#000000';
                        nbd.day.setFullYear(y, m, d + 1);
                        month.days.push(nbd);
                    }
                    this.curYear.months.push(month);
                }*/
    }

    clickDay(event) {
        this.pickDay.emit(event);
    }
}
