import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InterfaceService} from '../../services/interface.service';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-cttoi',
  templateUrl: './cttoi.component.html',
  styleUrls: ['./cttoi.component.css']
})
export class CttoiComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  primarycolor: string;
  secondarycolor: string;

  constructor(public is: InterfaceService,
              public admin: AdminService) {
    super(admin, is);
  }

  ngOnInit() {
    super.ngOnInit();

    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();
  }

}
