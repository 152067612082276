import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CappTeamMsg, InterfaceService} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-social-invite',
  templateUrl: './social-invite.component.html',
  styleUrls: ['./social-invite.component.scss']
})
export class SocialInviteComponent implements OnInit, AfterViewInit {
  @Input()
  curmsg: CappTeamMsg;

  @Input()
  showheader: boolean;

  @Input()
  primarycolor: string;

  @Input()
  secondarycolor: string;

  flipit = false;
  ht: number;
  days: number;
  note: string;


  @ViewChild('content', {static: false}) elementView: ElementRef;
  @ViewChild('contentback', {static: false}) elementViewBack: ElementRef;

  constructor(public admin: AdminService,
              private bottomSheet: MatBottomSheet,
              private db: AngularFireDatabase,
              private cd: ChangeDetectorRef,
              private is: InterfaceService) { }

  ngOnInit(): void {
    if (this.curmsg.e.rsvp) {
      this.days = this.admin.calcDays(this.curmsg.e.rsvp) + 1;
    }
  }

  ngAfterViewInit(): void {
    if (this.elementView.nativeElement) {
      this.ht = this.elementView.nativeElement.offsetHeight;
    }

    if (this.elementViewBack.nativeElement) {
      if (this.ht < this.elementViewBack.nativeElement.offsetHeight) {
        this.ht = this.elementViewBack.nativeElement.offsetHeight;
      }
    }

    if (this.ht < 300) {
      this.ht = 300;
    }
    this.cd.detectChanges();
  }

  onAttend(will: boolean) {
  }


  onAccept() {
  }

  onAcceptGoog() {
  }

}
