import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {BizYear} from '../../services/bizhours.service';
import {LocationData} from '../../services/interface.service';

@Component({
  selector: 'app-com-hours',
  templateUrl: './com-hours.component.html',
  styleUrls: ['./com-hours.component.scss']
})
export class ComHoursComponent implements OnInit {
    curLocation: LocationData;
    cu
    isdialog = true;


    constructor(public dialogRef: MatDialogRef<ComHoursComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.curLocation = data.curLocation as LocationData;
        console.log('data', data)
    }

    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close();
    }

    onSave(curLocation) {
        this.dialogRef.close(curLocation);
    }


}
