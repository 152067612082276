import {Component, Input, OnInit} from '@angular/core';
import {Wish} from '../../services/interface.service';

@Component({
  selector: 'app-blog-cardbig',
  templateUrl: './blog-cardbig.component.html',
  styleUrls: ['./blog-cardbig.component.scss']
})
export class BlogCardbigComponent implements OnInit {
  @Input()
  s: Wish;

  @Input()
  dayshow: boolean;

  curlang = 'en';
  daycolor = '#0000FF';
  titlecolor = '#0000FF';
  iconcolor = '#6868fc';

  config = {
    slidesPerView: 1,
    autoHeight: true,
    observer: true,
    direction: 'horizontal',
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    allowTouchMove: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    a11y: true,
    preventClicks: true,
    preventClicksPropagation: false,
  };

  constructor() { }

  ngOnInit() {
  }

}
