import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TopixMember} from '../../services/interface.service';

@Component({
  selector: 'app-hc-wellbeing',
  templateUrl: './hc-wellbeing.component.html',
  styleUrls: ['./hc-wellbeing.component.scss']
})
export class HcWellbeingComponent implements OnInit, OnChanges {
  @Input()
  curclient: TopixMember;

  constructor() { }

  ngOnInit() {
  }

    ngOnChanges(changes: SimpleChanges) {
        const carr = Object.keys(changes);
        if (carr && carr.length > 0) {
            carr.forEach(c => {
                if (c === 'curclient') {
                    console.log('do something')
                }
            });
        }
    }
}
