import {Component, Input, OnInit} from '@angular/core';
import {Invitation} from '../../../../services/interface.service';
import {validate} from 'codelyzer/walkerFactory/walkerFn';

@Component({
    selector: 'app-evt-wrapper',
    templateUrl: './evt-wrapper.component.html',
    styleUrls: ['./evt-wrapper.component.scss']
})
export class EvtWrapperComponent implements OnInit {
    @Input()
    curInv: Invitation;

    @Input()
    curlang: string;

    @Input()
    start;

    @Input()
    end;

    @Input()
    time;

    constructor() {
    }

    ngOnInit() {
        if (isNaN(this.end)) {
            this.end = '';
        }
    }

}
