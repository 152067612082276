import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {MatSnackBar} from '@angular/material';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FirebaseSyncService {

    constructor(private _db: AngularFireDatabase,
                public _snackBar: MatSnackBar) {
    }

    getQuery(pth, child, key): Observable<any[]> {
        return this._db.list(pth,
            ref => ref.orderByChild(child).equalTo(key))
            .valueChanges();
    }

    getObject(pth): Observable<any> {
        return this._db.object(pth).valueChanges();
    }

    setObj(pth, obj) {
        this._db.object(pth).set(obj)
            .then(res => {
                this._snackBar.open('Data saved', '', {
                    duration: 1000,
                });
            })
            .catch(err => {
                this._snackBar.open('Error:', err, {
                    duration: 1000,
                });
            });
    }
}
