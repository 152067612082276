import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Phone} from '../../services/interface.service';

@Component({
    selector: 'app-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
    @Input()
    curphone: Phone;

    constructor(private domSanitizer: DomSanitizer) {
    }

    ngOnInit() {
    }

    getImage() {
        return 'url(' + this.curphone.image + ')';
    }

}
