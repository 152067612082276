export const AddressType = [
  'Primary',
  'Vacation',
  'Home',
  'Work',
  'School',
  'Billing',
  'Delivery'
]

export const MolyType = [
  'Address',
  'Boolean',
  'Date',
  'Doc',
  'Gif',
  'Graphic',
  'Identifer',
  'Movie',
  'Number',
  'PDF',
  'Sound',
  'String',
];

export const EmailType = [
  'Work',
  'Business',
  'Email',
  'Family',
  'Primary',
  'Personal',
  'Other'
];

export const IdentifierType = [
  'phone',
  'email',
  'social media'
];

export const PhoneType = [
  'Cell',
  'Home',
  'Landline',
  'Pager',
  'Phone',
  'Fax',
  'Work',
  'Other'
];

export const SocialMediaType = [
  'Capptivation',
  'Facebook',
  'Instagram',
  'LinkedIn',
  'SnapChat'
];
