import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import * as _ from 'lodash';
import {DomSanitizer} from '@angular/platform-browser';
import {Asset, InterfaceService, LangString, ObjName} from '../../services/interface.service';
import {Languages} from '../../services/constants';
import {FirebaseService} from '../../services/firebase.service';
import {AdminService} from '../../services/admin.service';
import {ConfirmationComponent} from '../../lib/confirmation/confirmation.component';
import {ArchiveService} from '../../services/archive.service';
import {FileHandle} from '../../services/dragDrop.directive';
import {AgmMap} from '@agm/core';
import * as geohash from 'ngeohash';
import {AngularFireDatabase} from '@angular/fire/database';

declare var google: any;

@Component({
  selector: 'app-com-graphics',
  templateUrl: './com-graphics.component.html',
  styleUrls: ['./com-graphics.component.scss']
})
export class ComGraphicsComponent implements OnInit {
  @Input()
  loc: string;

  @Input()
  archive;

  latt = -1;
  lngg = -1;
  hash: string;
  spinner = false;
  publicview = true;
  use: string;
  sec = 0;
  first = false;

  copyright: string;
  creators: string;
  objects: string;
  pubdate;
  location: string;
  filter = '';
  graphicdetails = false;
  folderdetails = false;
  languages = Languages;
  curlang: string;
  lastupdate: Date;
  assets: Asset[];
  tmpassets: Asset[];
  select = false;
  pos = -1;
  alt: string;
  photoFile: File;
  foldername: string;
  description: string;
  cursrc;
  curfolder: string;
  folders: string[];
  fileTypes = [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg',
    'image/svg+xml',
  ];
  files: FileHandle[] = [];

  vfileTypes = [
    'video/ogg',
    'video/webm',
    'video/mp4'];

  path = `https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/`;
  suffix = `?alt=media`;
  ty: string;

  @ViewChild(AgmMap, {static: false}) mapElement: any;

  constructor(public is: InterfaceService,
              private ar: ArchiveService,
              private db: AngularFireDatabase,
              private dialog: MatDialog,
              private cd: ChangeDetectorRef,
              private ds: DomSanitizer,
              private fs: FirebaseService,
              private admin: AdminService) {
    this.curfolder = 'Main';
    this.curlang = this.admin.language;
  }

  onAddGraphic(ty: string) {
    this.photoFile = null;
    this.ty = ty;
    this.description = '';
    this.use = 'MIT';
    this.publicview = true;
    this.alt = '';
    this.pubdate = new Date();
    this.copyright = '';
    this.creators = '';
    this.objects = '';
    this.location = '';
    this.lngg = -1;
    this.latt = -1;
    this.pos = -1;
    this.cursrc = null;
    this.graphicdetails = true;
  }

  validFileType(file) {
    if (this.ty === 'image') {
      for (let i = 0; i < this.fileTypes.length; i++) {
        if (file.type === this.fileTypes[i]) {
          return true;
        }
      }
    } else {
      for (let i = 0; i < this.vfileTypes.length; i++) {
        if (file.type === this.vfileTypes[i]) {
          return true;
        }
      }
    }
    return false;
  }

  filesDropped(files: FileHandle[]): void {
    this.spinner = true;
    this.files = files;
    if (this.validFileType(files[0].file)) {
      this.photoFile = files[0].file;
      const myReader: FileReader = new FileReader();
      myReader.onload = e => {
        const dataURL = myReader.result;
        this.select = true;
        this.cursrc = this.ds.bypassSecurityTrustUrl(dataURL.toString());
        this.spinner = false;
      };
      myReader.readAsDataURL(files[0].file);
    } else {
      // todo: error message
      console.log('falsey 1');
      this.spinner = false;
    }
  }

  onSaveG(a: Asset, i: number) {
    this.pos = i;
    this.select = false;
    this.onSaveGraphic(a.type);
    // this.ar.onSaveArchiveGraphics(this.loc, this.archive.graphics);
  }


  onFilter() {
    if (!this.filter || this.filter.length === 0) {
      this.tmpassets = this.assets;
    } else {
      this.tmpassets = this.assets.filter(b => b.alt[this.curlang].toLowerCase().includes(this.filter.toLowerCase()));
    }
  }

  fixTmpAssets() {
    this.tmpassets = this.assets;
  }

  ngOnInit() {
    this.assets = [];
    if (this.archive && this.archive.graphics) {
      this.assets = Object.values(this.archive.graphics);
      this.fixTmpAssets();
    } else {
      this.fixTmpAssets();
    }

    if (!this.archive) {
      this.archive = {};
    }

    if (!this.archive.graphics) {
      this.archive.graphics = [];
    }
  }

  onChangePhoto(files) {
    this.spinner = true;
    if (this.validFileType(files[0])) {
      // OLD VIDEO //
      // if (this.validFileType(files[0].file)) {
      // Old Video //
      this.photoFile = files[0];
      const myReader: FileReader = new FileReader();
      myReader.onload = e => {
        const dataURL = myReader.result;
        this.cursrc = this.ds.bypassSecurityTrustUrl(dataURL.toString());
        // OLD VIDEO //
        // self.cursrc = dataURL.toString();
        // OLD Video //
        this.spinner = false;

      };
      myReader.readAsDataURL(files[0]);
    } else {
      console.log('falsey 3');
      this.spinner = false;
    }
  }

  onExpand(event, i) {
    if (event) {
      this.onEditGraphic(i);
    }
  }

  onEditGraphic(i) {
    this.pos = i;
    this.cursrc = this.tmpassets[i].url;
    this.creators = '';
    // todo: this is just a temporary cludge until i fill out how we are going to handle the international directory
    console.log('creaeteadf', this.tmpassets[i])
    this.creators = this.admin.convertObjNames(this.tmpassets[i].creators);
    this.objects = this.admin.convertObjNames(this.tmpassets[i].objects);
    // todo: this is just a temporary cludge until i fill out how we are going to handle the international directory

    this.description = this.tmpassets[i].description[this.curlang];
    this.alt = this.tmpassets[i].alt[this.curlang];
    const tz = new Date().getTimezoneOffset() * 60000;
    // removing the timezone impact
    this.pubdate = new Date(this.tmpassets[i].pubdate - tz);
    if (this.tmpassets[i].location.long && this.tmpassets[i].location.lat) {
      this.lngg = this.tmpassets[i].location.long;
      this.latt = this.tmpassets[i].location.lat;
    }
    this.copyright = this.tmpassets[i].copyright;
    this.location = this.tmpassets[i].location.location;
    this.use = this.tmpassets[i].use;

    // todo: this needs to be updated
    this.publicview = true;

  }

  onDeleteGraphic(i) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: {text: 'Are you sure you want to delete this graphic?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.answer) {
        const filename = `${this.loc}/${this.admin.admin.org}/archive/graphics/${this.tmpassets[i].filename}`;

        if (this.tmpassets[i].type === 'video') {
          const thumb = this.assets[i].filename.split('.')[0];
          const thumbname = `${this.loc}/${this.admin.admin.org}/archive/graphics/thumb_${thumb}.jpg`;
          firebase.storage().ref().child(thumbname).delete();
        }

        const storageRef = firebase.storage();
        firebase.storage().ref().child(filename).delete().then(
          v => {
            const x = this.assets.findIndex(a => a.ukey === this.tmpassets[i].ukey);
            if (x > -1) {
              this.assets.splice(x, 1);
              this.tmpassets.splice(i, 1);
            }
            this.archive.graphics = this.assets;
            this.ar.onSaveArchiveGraphics(this.loc, this.archive.graphics);
            // this.is.setObjFS(`Community/${this.archive.ukey}`, this.is.curComOrg);
          });

      }
    });
  }

  onCancelGraphic() {
    this.photoFile = null;
    this.graphicdetails = false;
  }

  onSaveGraphic(type) {
    if (type === 'image') {
      this.onSaveGraphicImage();
    } else {
      this.onSaveGraphicVideo();
    }
  }

  makeCreators() {
    this.assets[this.pos].creators = [];
    if (this.creators) {
      const carr = this.creators.split(',');
      if (carr && carr.length) {
        carr.forEach(c => {
          const p = new ObjName();
          p.fullname = c;
          p.type = 'person';
          p.ukey = this.db.createPushId();
          this.assets[this.pos].creators.push(p);
        });
      }
    }
  }

  makeObjects() {
    this.assets[this.pos].objects = [];
    if (this.objects) {
      const carr = this.objects.split(',');
      if (carr && carr.length) {
        carr.forEach(c => {
          const p = new ObjName();
          p.fullname = c;
          p.type = 'person';
          p.ukey = this.db.createPushId();
          this.assets[this.pos].objects.push(p);
        });
      }
    }
  }

  onSaveGraphicImage() {
    if (this.photoFile && this.select) {
      const filename = `${this.loc}/${this.admin.admin.org}/archive/graphics/${this.photoFile.name}`;
      const storageRef = firebase.storage();
      firebase.storage().ref().child(filename).put(this.photoFile).then(
        v => {
          const s = `gs://library-titleix.appspot.com/${filename}`;
          storageRef.refFromURL(s).getDownloadURL().then(url => {
            const as = new Asset('', '', '');
            as.url = url;
            as.thumb = `${this.path}${this.loc}%2F${this.admin.admin.org}%2Farchive%2Fgraphics%2Fthumb_${this.photoFile.name}${this.suffix}`;
            console.log('as thumber', as.thumb);
            as.filename = this.photoFile.name;
            as.description[this.curlang].description = this.description;
            as.alt[this.curlang].description = this.alt;

            // todo: needs to be fixed using the privacy class
            // as.public = this.publicview;
            as.use = this.use;

            const tz = new Date().getTimezoneOffset() * 60000;
            // storing as an international time
            as.pubdate = new Date(this.pubdate).getTime() + tz;
            as.copyright = this.copyright;
            as.location.location = this.location;
            as.type = 'image';

            if (this.lngg !== -1 && this.latt !== -1) {
              as.location.long = this.lngg;
              as.location.lat = this.latt;
            }

            as.date = new Date().getTime();
            as.ukey = this.fs.titleIXdb.createPushId();

            this.assets.push(as);
            this.fixTmpAssets();
            this.archive.graphics = this.assets;
            this.ar.onSaveArchiveGraphics(this.loc, this.archive.graphics);
          });
        }
      );
      this.select = false;
      this.graphicdetails = false;
    } else {
      this.select = false;
      if (this.description) {
        this.assets[this.pos].description[this.curlang] = this.description;
      }

      if (this.alt) {
        this.assets[this.pos].alt[this.curlang] = this.alt;
      }

      if (this.copyright) {
        this.assets[this.pos].copyright = this.copyright;
      }

      this.makeCreators();
      this.makeObjects();

      if (this.location) {
        this.assets[this.pos].location.location = this.location;
      }

      if (this.pubdate) {
        const tz = new Date().getTimezoneOffset() * 60000;
        // storing as an international time
        this.assets[this.pos].pubdate = new Date(this.pubdate).getTime() + tz;
      }


      this.assets[this.pos].location.long = this.lngg;
      this.assets[this.pos].location.lat = this.latt;
      if (this.hash)  {
        this.assets[this.pos].location.hash = this.hash;
      }
      this.fixTmpAssets();
      this.archive.graphics = this.assets;
      this.ar.onSaveArchiveGraphics(this.loc, this.archive.graphics);
      // this.is.setObjFS(`Community/${this.archive.ukey}`, this.is.curComOrg);
      this.graphicdetails = false;
    }
  }

  onSaveGraphicVideo() {
    if (this.photoFile && this.select) {
      this.spinner = true;
      this.cd.detectChanges();
      const filename = `${this.loc}/${this.admin.admin.org}/archive/graphics/${this.photoFile.name}`;
      const storageRef = firebase.storage();
      firebase.storage().ref().child(filename).put(this.photoFile).then(
        v => {
          const s = `gs://library-titleix.appspot.com/${filename}`;
          storageRef.refFromURL(s).getDownloadURL().then(url => {
            const as = new Asset('', '', '');
            as.url = url;
            as.description[this.curlang].description = this.description;
            as.alt[this.curlang].description = this.alt;
            as.copyright = this.copyright;
            as.location.location = this.location;
            as.type = 'video';

            // todo: need to fix using privacy class
            // as.public = this.publicview;
            as.use = this.use;

            if (this.lngg !== -1 && this.latt !== -1) {
              as.location.long = this.lngg;
              as.location.lat = this.latt;
            }

            as.date = new Date().getTime();
            as.ukey = this.fs.titleIXdb.createPushId();
            if (this.pubdate) {
              const tz = new Date().getTimezoneOffset() * 60000;
              // storing as an international time
              as.pubdate = new Date(this.pubdate).getTime() + tz;
            }

            const ext = 'jpg';
            const ukey = this.photoFile.name.split('.')[0];
            as.thumb = `${this.path}${this.loc}%2F${this.admin.admin.org}%2Farchive%2Fgraphics%2Fthumb_${ukey}.${ext}${this.suffix}`;
            as.filename = this.photoFile.name;
            if (this.sec) {
              const secfilename = `${this.loc}Pri/${this.admin.admin.org}/archive/graphics/thumb_${ukey}`;
              this.is.setObjNoLog(secfilename, this.sec);
            }
            const waiting = `${this.loc}Pri/${this.admin.admin.org}/archive/graphics/thumbcr_${ukey}`;

            // OLD VIDEO //
            // const waiting = `${this.loc}Pri/${this.admin.admin.org}/archive/videos/thumbcr_${ukey}`;
            // Old Video //

            const $sub1 = this.is.getObject(waiting).subscribe(val => {
              if (val) {
                this.assets.push(as);
                this.fixTmpAssets();
                this.archive.graphics = this.assets;
                this.ar.onSaveArchiveVideo(this.loc, this.archive.graphics);
                this.spinner = false;
                this.first = true;
                $sub1.unsubscribe();
                this.is.deleteObjNoLog(waiting);
              }
            });
          });
        }
      );
      this.graphicdetails = false;
    } else {
      this.select = false;
      if (this.description) {
        this.assets[this.pos].description[this.curlang] = this.description;
      }

      if (this.alt) {
        this.assets[this.pos].alt[this.curlang] = this.alt;
      }

      if (this.copyright) {
        this.assets[this.pos].copyright = this.copyright;
      }

      this.makeCreators();
      this.makeObjects();

      if (this.location) {
        this.assets[this.pos].location.location = this.location;
      }

      if (this.pubdate) {
        const tz = new Date().getTimezoneOffset() * 60000;
        // storing as an international time
        this.assets[this.pos].pubdate = new Date(this.pubdate).getTime() + tz;
      }

      this.assets[this.pos].location.long = this.lngg;
      this.assets[this.pos].location.lat = this.latt;
      if (this.hash) {
        this.assets[this.pos].location.hash = this.hash
      }
      this.fixTmpAssets();
      this.archive.graphics = this.assets;
      this.ar.onSaveArchiveGraphics(this.loc, this.archive.graphics);
      this.graphicdetails = false;
    }
  }

  onError(a) {
    if (this.first) {
      a['dont'] = true;
      this.spinner = true;
      setTimeout(() => {
        a['dont'] = false;
        this.first = false;
        this.spinner = false;
      }, 4000);
    }
  }

  onRefresh(a) {
    a['dont'] = true;
    this.spinner = true;
    setTimeout(() => {
      a['dont'] = false;
      this.spinner = false;
    }, 4000);
  }

  onSelect() {
    this.select = true;
    document.getElementById('photo').click();
  }

  onCalc() {
    this.latt = -1;
    this.lngg = -1;
    this.hash = '';
    this.spinner = true;
    if (this.mapElement) {
      this.mapElement._mapsWrapper.getNativeMap().then(map => {
        const geocoder = new google.maps.Geocoder();
        if (geocoder) {
          geocoder.geocode({'address': this.location}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.latt = results[0].geometry.location.lat();
              this.lngg = results[0].geometry.location.lng();
              this.hash = geohash.encode(this.latt, this.lngg);
              this.cd.detectChanges();
            } else {
              console.log('no good');
            }
          });
          this.spinner = false;
        } else {
          this.spinner = false;
        }
      }).catch(err => this.spinner = false);
    } else {
      this.spinner = false;
      console.log('no good');
    }
  }

}
