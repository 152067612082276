import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CAMolyData, CAMolyInForm} from '../../services/ca';

@Component({
  selector: 'app-ca-string-lite',
  templateUrl: './ca-string-lite.component.html',
  styleUrls: ['./ca-string-lite.component.scss']
})
export class CaStringLiteComponent implements OnInit {
  @Input()
  descdata: CAMolyData;

  @Input()
  moly: CAMolyInForm;

  @Input()
  primarycolor = '#0000FF';

  @Input()
  secondarycolor = '#ffffff';

  @Output()
  save = new EventEmitter<string>();

  curlang = 'en';
  editdesc = true;
  description;

  constructor() { }

  ngOnInit() {
    if (this.descdata.value && this.descdata.value.length > 0) {
      this.editdesc = false;
      this.description =  this.descdata.value[ this.descdata.value.length - 1];
    }
  }

  onClickDesc() {
    this.editdesc = true;
  }


  descriptionSave() {
    // this.descdata.value; // .push(this.description);
    this.save.emit(this.description);
  }

  onBlur(event) {
    this.descriptionSave();
  }

  onKeyUp(event) {
    // todo: check if Done is triggered
    this.descriptionSave();
    if (event.code === 'Enter' || event.key === 'Enter' || event.code === 'Done' || event.key === 'Done') {
      this.editdesc = false;
    }
  }

  onOptionDesc(event) {
    console.log('option decs', event);
  }

}
