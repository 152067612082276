import {Component, Input, OnInit} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {InterfaceService, OrgEdition, Topix} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {RoehttpService} from '../services/roehttp.service';
import {MatTableDataSource} from '@angular/material';

export class Milestone {
  code: string;
  name: string;
}

export class MilestoneVal {
  deviceId: string;
  date: number;
  val?: string; // maybe should be a number
  note?: string;
}

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.scss']
})
export class MilestonesComponent implements OnInit {
  @Input()
  topics: Topix[];

  @Input()
  eds: OrgEdition[];


  details: boolean;
  miledetails: boolean;
  milestones: Milestone[];
  curmilestone: Milestone;

  topic;

  users;
  usersmile;
  curt: string;
  d: number;


  constructor(public admin: AdminService,
              private http: RoehttpService,
              private is: InterfaceService,
              private db: AngularFireDatabase) {
  }

  ngOnInit() {
    this.d = new Date().getTime();

    if (this.topics) {
      this.topic = this.topics[0];
      this.onSelChange();
    }
  }

  onSelChange() {
    this.curt = '';
    if (this.eds && this.eds.length > 0) {
      this.eds.forEach(e => {
        if (e.topics && e.topics.length > 0) {
          e.topics.forEach(tp => {
            if (tp === this.topic) {
              this.curt = e.ukey;

              this.is.getList(`OrgsPri/${this.admin.admin.org}/milestonedefs/${this.curt}`)
                .take(1)
                .subscribe(ms => {
                  this.milestones = ms;
                });

              this.http.getAppUsers(this.curt).take(1).subscribe(res => {
                // Read result of the Cloud Function.
                if (res && res.length > 0) {
                  this.users = res;
                }
              });

            }
          });
        }
      });
    }

  }

  onAdd() {
    this.curmilestone = new Milestone();
    this.details = true;
  }

  onEdit(i) {
    this.curmilestone = this.milestones[i];
    this.details = true;
  }

  onDelete(i) {
    this.is.deleteObjNoLog(`OrgsPri/${this.admin.admin.org}/milestonedefs/${this.curt}/${this.milestones[i].code}`);
    this.milestones.splice(i, 1);
  }

  onSave() {
    if (!this.milestones) {
      this.milestones = [];
    }
    if (!this.curmilestone.code) {
      this.curmilestone.code = this.db.createPushId();
      this.milestones.push(this.curmilestone);
    } else {
      const x = this.milestones.findIndex(m => m.code === this.curmilestone.code);
      if (x > -1) {
        this.milestones[x] = this.curmilestone;
      }
    }
    this.is.setObjNoLog(`OrgsPri/${this.admin.admin.org}/milestonedefs/${this.curt}/${this.curmilestone.code}`, this.curmilestone);
    this.details = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.milestones, event.previousIndex, event.currentIndex);
    this.is.setObjNoLog(`OrgsPri/${this.admin.admin.org}/milestonedefs/${this.curt}`, this.milestones);

  }

  onMilestone(i) {
    if (this.users && this.users.length > 0) {
      this.miledetails = true;
      this.usersmile = [];
      this.curmilestone = this.milestones[i];
      this.is.getList(`OrgsPri/${this.admin.admin.org}/milestones/${this.curt}/${this.milestones[i].code}`)
        .take(1)
        .subscribe(res => {
          if (!res) {
            res = [];
          }
          if (this.users && this.users.length > 0) {
            this.users.forEach(u => {
              const x = res.findIndex(r => r.deviceId === u.deviceId);
              if (x > -1) {
                u['completed'] = true;
                u['date'] = res[x].date;
              } else {
                u['completed'] = false;
                u['date'] = this.d;
              }
              this.usersmile.push(u);
            });
          } else {
            console.log('no users');
          }
        });
    }
  }


  onChange(u, i) {
    u['completed'] = true;
    this.is.setObjNoLog(`OrgsPri/${this.admin.admin.org}/milestones/${this.curt}/${this.curmilestone.code}/${u.deviceId}`, u)
    // todo: probably should save this by deviceId so it's easy to get the profile of a single user in terms of topics and milestones
  }
}
