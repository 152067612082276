import {Component, OnInit} from '@angular/core';
import {AdminService} from '../services/admin.service';
import {InterfaceService, Topix, TopixMember} from '../services/interface.service';
import * as _ from 'lodash';
import {RoehttpService} from '../services/roehttp.service';

@Component({
    selector: 'app-health-parent',
    templateUrl: './health-parent.component.html',
    styleUrls: ['./health-parent.component.scss']
})
export class HealthParentComponent implements OnInit {
    topixs: Topix[];
    curtopix: Topix;
    teams: any;
    curteam;
    curclient: TopixMember;
    members: TopixMember[];

    constructor(private is: InterfaceService,
                private http: RoehttpService,
                private admin: AdminService) {
    }

    // todo: filter on only teams covered by the adminjr

    ngOnInit() {
        this.is.getQuery(`Topics/topics`, 'org', this.admin.admin.org)
            .take(1)
            .subscribe(topx => this.topixs = topx);
    }

    onSelChange(event) {
        this.curtopix = this.topixs.find(t => t.ukey === event.value);
        this.curclient = null;
        this.is.getList(`Topics/subs/${event.value}`)
            .take(1)
            .subscribe(mems => {
                this.members = mems;
                this.teams = _.orderBy(_.uniqBy(mems, 'cappteamukey'), 'cappteamname', 'asc');
            });
    }

    onTeamChange(event) {
        this.http.getField(`CappTeams/${event.value}`).take(1).subscribe(cp => {
            this.curteam = cp;

            this.curclient = this.members.find(t => t.cappteamukey === event.value && this.curteam.principal)
        })
    }

}
