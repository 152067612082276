import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminService} from '../../../services/admin.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import {InterfaceService, Invitation} from '../../../services/interface.service';
import {BizDay, BizhoursService, BizYear} from '../../../services/bizhours.service';
import {Days} from '../../../services/constants';

@Component({
  selector: 'app-biz-hours-container',
  templateUrl: './biz-hours-container.component.html',
  styleUrls: ['./biz-hours-container.component.css']
})
export class BizHoursContainerComponent implements OnInit {
  curDay: BizDay;
  headDate: string;
  days = Days;
  periods: Invitation[];
  curperiod: string;

  @Input()
  curYear: BizYear;

  @Input()
  isdialog: boolean;

  @Output()
  picked = new EventEmitter<boolean>();

  @Output()
  close = new EventEmitter<any>();

  @Output()
  save = new EventEmitter<BizYear>();

  constructor(private is: InterfaceService,
              private bz: BizhoursService,
              public admin: AdminService) {
    this.curperiod = 'All dates';

    if (this.admin.admin.org) {
      this.is.getList('Orgs/' + this.admin.admin.org + '/periods').take(1).subscribe(p => {
        this.periods = p;
        const allperiod = new Invitation();
        allperiod.title = 'All dates';
        allperiod.start = new Date().getTime();
        allperiod.end = allperiod.start + 365 * 24 * 60 * 60 * 1000; // moment().add(365, 'days').toISOString();
        this.periods.unshift(allperiod);
      });
    }
  }

  ngOnInit() {
  }

  onCancel() {
    this.close.emit();
  }

  onClear() {
    this.curYear.months.forEach(month => {
      month.days.forEach(day => {
        day.open = false;
        day.blocks.forEach(b => {
          b.description = '';
          b.open = false;
        });
      });
    });
  }

  public savebz() {
    this.bz.savebizhours(this.admin.admin.org, this.curYear);
  }

  onSave() {
    this.save.emit(this.curYear);
  }

  twentyFourClick() {
    this.curDay = null;
  }

  pickDay(event) {
    this.headDate = '';
    this.curDay = event.d;
    this.picked.emit(true);
  }

  regDay(i) {
    this.headDate = this.days[i];
    this.curDay = this.curYear.regulardays[i];
    this.picked.emit(true);
  }

  onDayCancel() {
    this.curDay = null;
    this.picked.emit(false);
  }

  updateCurDay(event) {
    this.curDay = event;
  }

  onDaySave() {
    this.picked.emit(false);
    let startdate: Date;
    let enddate: Date;
    if (this.periods) {
      this.periods.forEach(p => {
          if (p.title === this.admin.curperiod) {
            startdate = new Date(p.start);
            enddate = new Date(p.end);
          }
        }
      );
    } else {
      startdate = new Date();
      enddate = new Date();
      enddate.setTime(enddate.getTime() + 730 * 86400000);
    }

    this.curYear.months.forEach(month => {
      month.days.forEach(day => {
        if (day.day >= startdate && day.day <= enddate) {
          if (this.headDate === this.days[day.day.getDay()]) {
            day.open = this.curDay.open;
            const newBlocks = _.cloneDeep(this.curDay.blocks);
            // this.curDay.blocks.slice();
            day.blocks = newBlocks;
          }
        }
      });
    });

    this.headDate = '';
    this.curDay = null;
  }


}
