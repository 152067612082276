import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
    CappTeamMsg, InterfaceService, Invitation, LangStringBlock, NotificationForm, ROEChron, Topix,
    TopixMember
} from '../services/interface.service';
import * as _ from 'lodash';
import {AdminService} from '../services/admin.service';
import {MatTableDataSource} from '@angular/material';
import {Filters, Icons, RepeatOptions, Roles} from '../services/constants';
import {EditorComponent} from '../lib/editor/editor.component';
import {RoehttpService} from '../services/roehttp.service';
import {AngularFireDatabase} from '@angular/fire/database';

@Component({
    selector: 'app-org-programs',
    templateUrl: './org-programs.component.html',
    styleUrls: ['./org-programs.component.scss']
})
export class OrgProgramsComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    loc = 'notifications';

    @Input()
    notes: NotificationForm[];

    @Input()
    gennotes: NotificationForm[];

    topixs: Topix[];
    roles = Roles;
    curtopix: Topix;
    teams: any;
    lteams: any;
    principals = ['Either', 'Principal', 'Not Principal']
    role = 'All';
    principal = 'Principal';
    curteam = 'All';
    lcurteam = 'All';
    ltopix = '';
    members: TopixMember[];
    tmpmembers: TopixMember[];
    delete = false;
    continue = false;
    invits: Invitation[];
    curInv: Invitation;
    start: number;

    primarycolor = '#99add0';
    secondarycolor = '#000000';
    icons = Icons;
    icon: string;
    genchange = false;
    repeat = false;

    title: string;
    eventfor: string;
    curlang;
    tmpnotes: NotificationForm[];
    curnote: NotificationForm;
    $sub1;
    $sub2;
    $sub3;
    body: string;
    tmplangDesc: LangStringBlock;
    pushit = true;
    details = false;
    tpx = '';

    nshow: CappTeamMsg;

    chronrepeat: string;
    chronend = -1;
    chronstart = new Date().getTime();
    reps = ['Weekly']; //  todo: write code for all these RepeatOptions;

    memSource: MatTableDataSource<TopixMember>;
    memColumns = ['name', 'cappteamname', 'role'];
    roz = -1;

    survey: string;
    guide: string;
    report: string;
    event: string;
    widget = false;
    option: string;
    options = [
        'Event',
        'Quick Event',
        'Guide',
        'Report',
        'Survey'];

    repeats: ROEChron[];

    @ViewChild(EditorComponent, {static: false}) editor: EditorComponent;


    // todo: should we add an expiration date? WHY NOT

    constructor(private is: InterfaceService,
                private db: AngularFireDatabase,
                private http: RoehttpService,
                private admin: AdminService) {
        this.curlang = this.admin.language;
    }


    // 1. pick the list of who u want to send the notification to: using filters
    // 2. Can use multiple filters
    // 3. Then use manual delete
    // 4. This gets me to the list
    // 5. Then select type of notification: Program Note, Questionnaire ?? are there others: make an appointment, ASK Peter
    //          - need to make sure somewhere in the message is the name of the Program and who the Program Admin is. PLEASE DOCUMENT
    // 6. Create the message - ?  should send push notification
    // 7. use a cloud function(msgs, arr, pushit) to personalize notification (may be a V2 thing) and post the notification
    //          - should it use the Froala editor so have html markup in the notification? (may be a V2 thing)
    //          - be sure to post the the organizatoin
    //          - if pushit then do a push notification
    // 8. Display notifications here
    //          - need the ability to delete the notification if sent out incorrectly (this means in the msg.l that we store the arr with the msg)

    ngOnInit() {
        this.is.getQuery(`Topics/topics`, 'org', this.admin.admin.org)
            .take(1)
            .subscribe(topx => this.topixs = topx);

        console.log('notes', this.notes)

        if (!this.notes && this.loc === 'notifications') {
            this.$sub1 = this.is.getList(`OrgsPri/${this.admin.admin.org}/${this.loc}`).subscribe(notes => {
                this.notes = [];
                console.log('notes', this.loc, notes)
                if (notes && notes.length > 0) {
                    this.notes = _.orderBy(notes, ['date'], ['desc']);
                }
            });
        }

        if (!this.gennotes) {
            this.$sub3 = this.is.getList(`OrgsPri/${this.admin.admin.org}/genericnotifications`).take(1).subscribe(gnotes => {
                this.gennotes = [];
                if (gnotes && gnotes.length > 0) {
                    this.gennotes = _.orderBy(gnotes, ['date'], ['desc']);
                }
            });
        }
    }


    ngOnChanges(changes: SimpleChanges) {
        const carr = Object.keys(changes);
        if (carr && carr.length > 0) {
            carr.forEach(c => {
                if (c === 'notes') {
                    this.notes = _.orderBy(this.notes, ['date'], ['desc']);
                    this.onLFilter();
                }

                if (c === 'gennotes') {
                    this.gennotes = _.orderBy(this.gennotes, ['date'], ['desc']);
                }
            })
        }
    }

    onContinue() {
        if (!this.genchange) {
            this.curnote = new NotificationForm(``, '');
            this.title = '';
            this.body = '';
            this.tmplangDesc = new LangStringBlock();
        }
        this.continue = true;
    }

    onAdd() {
        this.chronrepeat = '';
        this.chronstart = new Date().getTime();
        this.chronend = this.chronstart;

        this.genchange = false;
        this.details = true;

    }

    onReset() {
        this.role = 'All';
        this.principal = 'Principal';
        this.curteam = 'All';
        this.delete = false;
        this.onFilter();
    }

    onFilter() {
        this.tmpmembers = _.cloneDeep(this.members);

        if (this.curteam && this.curteam !== 'All') {
            this.tmpmembers = this.tmpmembers.filter(m => m.cappteamukey === this.curteam);
        }

        if (this.principal && this.principal === 'Principal') {
            this.tmpmembers = this.tmpmembers.filter(m => m.isprincipal);
        }


        if (this.principal && this.principal === 'Not Principal') {
            this.tmpmembers = this.tmpmembers.filter(m => !m.isprincipal);
        }

        if (this.role && this.role !== 'All') {
            // todo: need to verify that it's necessary to use: toLowerCase
            this.tmpmembers = this.tmpmembers.filter(m => this.role && m.role === this.role.toLowerCase());
        }


        this.memSource.data = this.tmpmembers;
    }

    onPFilter() {
        this.is.getQuery(`ChronQueue`, 'program', `${this.admin.admin.org}/${this.ltopix}`)
            .take(1)
            .subscribe(rs => {
                console.log('rs', rs)
                this.repeats = rs;
            })
        this.onLFilter();
    }

    onLFilter() {
        this.tmpnotes = _.cloneDeep(this.notes);
        this.tmpnotes = this.tmpnotes.filter(n => n.to === this.ltopix);
        this.lteams = [];
        if (this.tmpnotes && this.tmpnotes.length > 0) {
            this.tmpnotes.forEach(n => {
                if (n.cappteammsg && n.cappteammsg.l && n.cappteammsg.l.cappteamname) {
                    this.lteams.push(n.cappteammsg.l);
                }
            });
        }

         //   _.orderBy(_.uniqBy(this.tmpnotes, 'cappteamukey'), 'cappteamname', 'asc');
        console.log('this.lteam', this.lteams)


        // todo filter by parties to a cappTeam

        if (this.lcurteam && this.lcurteam !== 'All') {
            this.tmpnotes = this.tmpnotes.filter(m => m.cappteammsg.l.cappteamukey === this.lcurteam);
        }
    }

    onDelete(row) {
        this.tmpmembers.splice(row, 1);
        this.memSource.data = this.tmpmembers;
        this.delete = true;
    }

    onChangeStart(ev) {
        this.curInv.start = ev;
    }


    onGenChange(event) {
        this.memSource = new MatTableDataSource([]);
        this.curnote = event.value;
        this.curtopix = this.topixs.find(t => t.topic === this.curnote.to)
        this.tpx = this.curtopix.ukey;
        this.delete = true;
        this.members = this.curnote.cappteammsg.parties;
        this.onFilter();
        this.title = this.curnote.subject;
        if (this.curnote.body) {
            this.body = this.curnote.body;
        } else {
            this.body = '';
        }
        if (this.curnote.cappteammsg.fullmsg) {
            this.tmplangDesc = this.curnote.cappteammsg.fullmsg;
        } else {
            this.tmplangDesc = new LangStringBlock();
        }
        this.genchange = true;
    }

    onDeleteReapeat(i) {
        // todo: need to implement
    }

    onDeleteNote(i) {
        if (this.tmpnotes[i].cappteammsg.l && this.tmpnotes[i].cappteammsg.l.type === 'ROE-EA') {
            this.is.deleteObjNoLog(`OrgsPri/${this.admin.admin.org}/events/${this.tmpnotes[i].cappteammsg.l.ukey}`);
        }

        if (this.loc === 'notifications') {
            this.http.deleteCappTeamNote(this.tmpnotes[i], `OrgsPri/${this.admin.admin.org}/${this.loc}/${this.tmpnotes[i].date}`);
        } else {
            this.is.deleteObjNoLog(`OrgsPri/${this.admin.admin.org}/${this.loc}/${this.tmpnotes[i].date}`);
        }
        // this.tmpnotes.splice(i, 1);
    }

    onTeamChange(event) {
        this.onFilter();
    }

    onPrincipalChange(event) {
        this.onFilter();
    }

    onRoleChange(event) {
        this.onFilter();
    }

    onOptionChange() {
        if (this.option === 'Survey') {
            if (!this.is.surveys || this.is.surveys.length === 0) {
                this.is.getOrgSurveys('Orgs');
            }
            return;
        }

        if (this.option === 'Guide') {
            if (!this.is.gl || this.is.gl.length === 0) {
                this.is.getOrgGuideList();
            }
        }

        if (this.option === 'Report') {
            if (!this.is.rl || this.is.rl.length === 0) {
                this.is.getOrgReportList();
            }
        }

        if (this.option === 'Quick Event') {
            if (this.curnote.cappteammsg && this.curnote.cappteammsg.l && this.curnote.cappteammsg.ty === 'ROE-EA') {
                this.curInv = this.curnote.cappteammsg.l;
                this.start = this.curInv.start;
                this.eventfor = '';

            } else {
                this.curInv = new Invitation();
            }
        }

        if (this.option === 'Event' && !this.invits) {
            this.$sub2 = this.is.getList(`OrgsPri/${this.admin.admin.org}/events`).subscribe(is => {
                this.invits = _.orderBy(is, [`start`], ['asc']);
            });

        }
    }

    onSelChange(event) {
        this.members = null;
        this.memSource = new MatTableDataSource([]);
        this.curtopix = this.topixs.find(t => t.ukey === event.value);
        this.is.getList(`Topics/subs/${event.value}`)
            .take(1)
            .subscribe(mems => {
                this.teams = _.orderBy(_.uniqBy(mems, 'cappteamukey'), 'cappteamname', 'asc');
                this.members = _.orderBy(mems, ['name', 'cappteamname'], ['asc', 'asc']);
                if (this.members && this.members.length > 0) {
                    // todo: make it so filter for the key of the program admin

                    this.tmpmembers = _.cloneDeep(this.members);
                    this.onFilter();
                    this.memSource.data = this.tmpmembers;
                }
            });
    }

    onSave() {
        this.curnote.caller = 'CappTeam';
        this.curnote.body = this.body;

        this.curnote.cappteammsg.fullmsg = this.editor.onSave();

        if (this.curtopix.topic) {
            this.curnote.to = this.curtopix.topic;
        }


        this.curnote.subject = this.title;
        if (this.icon) {
            this.curnote.icon = this.icon;
            this.curnote.cappteammsg.g = this.icon;
            this.curnote.cappteammsg.ty = 'ROE-A';
        }

        // todo: need to implement all types of attachments (eg. Guide, Report, event, a todo item
        if (this.widget) {
            this.curnote.cappteammsg.dt = this.option;
            if (this.option === 'Survey') {
                this.curnote.cappteammsg.dl = `Orgs/${this.admin.admin.org}/surveys/${this.survey}`;  // this is the full path to an widget, event  or app
                this.curnote.cappteammsg.ty = 'ROE-Survey';
            }

            if (this.option === 'Quick Event') {
                // todo: this is not working for translations
                this.curInv.pitchDesc = this.curnote.cappteammsg.fullmsg;
                this.curInv.headlineDesc['en'].description = this.title;
                this.curInv.title = this.title;
                this.curnote.cappteammsg.l = this.curInv;
                this.curnote.cappteammsg.ty = 'ROE-EA';

                if (!this.curInv.ukey) {
                    this.curInv.ukey = this.db.createPushId();
                }
            }

            if (this.option === 'Event') {
                this.curnote.cappteammsg.dl = `Orgs/${this.admin.admin.org}/events/${this.event}`;  // this is the full path to an widget, event  or app
                const x = this.invits.findIndex(inv => inv.ukey === this.event);
                this.curnote.cappteammsg.l = this.invits[x];
                this.curnote.cappteammsg.ty = 'ROE-EA';
            }

            if (this.option === 'Guide') {
                this.curnote.cappteammsg.dl = `Guides/${this.guide}`;  // this is the full path to an widget, event  or app
            }

            if (this.option === 'Report') {
                this.curnote.cappteammsg.dl = `Orgs/${this.admin.admin.org}/reports/${this.report}`;  // this is the full path to an widget, event  or app
            }
        }

        let name = '';
        if (this.curteam !== 'All') {
            name = this.teams.find(t => t.cappteamukey === this.curteam).cappteamname;
        }
        this.curnote.cappteammsg.parties = this.tmpmembers;
        this.curnote.cappteammsg.x = this.admin.admin.email;
        this.curnote.cappteammsg.l = {role: this.role, cappteamukey: this.curteam, principal: this.principal, cappteamname: name};
        this.curnote.cappteammsg.cn = 'Program Admin';
        this.curnote.cappteammsg.s = this.title;
        this.curnote.cappteammsg.msg = this.body;
        this.curnote.cappteammsg.app = `${this.admin.admin.org}/${this.curnote.caller}`;


        if (this.loc === 'notifications') {
            if (this.repeat) {
                const ch = new ROEChron();
                ch.ukey = this.db.createPushId();
                ch.type = 'notification';
                ch.repeat = this.chronrepeat;

                const d = new Date(this.chronstart).setHours(0, 0, 0, 0);
                ch.startdate = new Date(d).getTime();
                ch.program = `${this.admin.admin.org}/${this.ltopix}`
                if (ch.enddate) {
                    const dd = new Date(this.chronend).setHours(0, 0, 0, 0);
                    ch.enddate = new Date(dd).getTime();
                } else {
                    ch.enddate = -1;
                }
                ch.l = this.curnote;
                this.http.setField(`ChronQueue/${ch.ukey}`, ch);

                if (!this.repeats) {
                    this.repeats = [];
                }

                this.repeats.push(ch);
            } else {
                this.http.publishCappTeamNote(this.curnote, `OrgsPri/${this.admin.admin.org}/${this.loc}/${this.curnote.ukey}`, this.eventfor);
            }
        } else {
            this.is.setObjNoLog(`OrgsPri/${this.admin.admin.org}/${this.loc}/${this.curnote.ukey}`, this.curnote);
        }
        if (this.option === 'Quick Event') {
            this.curInv.title = `For ${this.eventfor}`;
            this.is.setObj(`OrgsPri/${this.admin.admin.org}/events/${this.curInv.ukey}`, this.curInv, 'Calendar', 'Add/edit event')
        }



        this.details = false;
        this.continue = false;
    }


    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
        if (this.$sub2) {
            this.$sub2.unsubscribe();
        }
        if (this.$sub3) {
            this.$sub3.unsubscribe();
        }
    }
}
