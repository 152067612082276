import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InterfaceService, Note} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';
import * as _ from 'lodash';
import {AngularFireDatabase} from '@angular/fire/database';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @Input()
  ukey: string;

  @Input()
  notes: Note[];

  @Output()
  open = new EventEmitter<boolean>()

  curnote: Note;
  notedetails = false;


  constructor(public admin: AdminService,
              private db: AngularFireDatabase,
              public is: InterfaceService) { }

  ngOnInit() {
  }

  onAddNote() {
    this.curnote = new Note();
    this.curnote.creator = this.admin.admin.name;
    this.notedetails = true;
    this.open.emit(true);
  }

  onEditNote(i) {
    this.curnote = this.notes[i];
    this.notedetails = true;
    this.open.emit(true);
  }

  onDeleteNote(i) {
    this.is.deleteObjNoLog(`Content/bodies/${this.ukey}/body/notes/${this.notes[i].ukey}`);
    this.notes.splice(i, 1);

  }

  onCancel() {
    this.notedetails = false;
    this.open.emit(false);
  }

  onSaveNote() {
    if (!this.curnote.summary || !this.curnote.note) {
      return;
    } else {
      this.curnote.date = new Date().getTime();
      if (!this.curnote.ukey) {
        if (!this.notes) {
          this.notes = [];
        }
        this.curnote.ukey = this.db.createPushId();
        this.notes.push(this.curnote);
      } else {
        const x = this.notes.findIndex(n => n.ukey === this.curnote.ukey);
        if (x > -1) {
          this.notes[x] = this.curnote;
        }
      }
      this.notes = _.orderBy(this.notes, 'date', 'desc');
      this.is.setObjNoLog(`Content/bodies/${this.ukey}/body/notes/${this.curnote.ukey}`, this.curnote);
      this.notedetails = false;
      this.open.emit(false);
    }
  }
}
