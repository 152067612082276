import {Component, OnInit, ViewChild} from '@angular/core';
import {Admin, AdminService, Adminy, InstModules, Module} from '../../services/admin.service';
import {RoehttpService} from '../../services/roehttp.service';
import {InterfaceService} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import UserCredential = firebase.auth.UserCredential;
import {Router} from '@angular/router';
import {Languages} from '../../services/constants';

@Component({
  selector: 'app-login-inst',
  templateUrl: './login-inst.component.html',
  styleUrls: ['./login-inst.component.scss']
})
export class LoginInstComponent implements OnInit {
  register = true;
  step = 0;
  cnt = 12.5;
  nameoforg: string;
  authorized = false;
  yourname: string;
  email: string;
  phone: string;
  emailconfirmed = false;
  code: string;

  code1 = '';
  code2 = '';
  code3 = '';
  code4 = '';
  err = '';
  inst: string;

  phonevalid = false;
  emailvalid = false;
  oldemail: string;
  oldphone: string;

  ukey: string;
  useremail: string;


  adm: Admin;
  try: string;

  @ViewChild('f2', {static: false}) f2: any;
  @ViewChild('f3', {static: false}) f3: any;
  @ViewChild('f4', {static: false}) f4: any;

  constructor(private hs: RoehttpService,
              public authx: AngularFireAuth,
              private is: InterfaceService,
              private router: Router,
              private db: AngularFireDatabase,
              public admin: AdminService) {
  }

  ngOnInit() {
    this.is.getTrans();
    this.admin.languageon = true;
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    this.ukey = (new Date().getTime() + offset).toString();
    console.log('ukey', this.ukey)
    this.useremail = `${this.ukey}@capptivation.com`;
    this.authx.auth.signOut()
      .then(p => {
        this.authx.auth.createUserWithEmailAndPassword(this.useremail, this.ukey)
          .then(user => {
            console.log('user', user);
          })
          .catch(err => console.log('signin error', err));
      })
      .catch(err => console.log('signout error', err));
  }

  onKeyup(i) {
    if (i === 1 && this.code1 && this.code1.length === 1) {
      this.f2.nativeElement.focus();
    }
    if (i === 2 && this.code2 && this.code2.length === 1) {
      this.f3.nativeElement.focus();
    }
  }

  onTry() {
    this.err = '';
    if (!this.try || this.try.length === 0) {
      this.err = this.admin.roct['emailphone']; // Please enter your email address or mobile phone number.
      return;
    } else if (this.try.includes('@')) {
      this.email = this.try;
      this.onEmail();
    } else {
      this.phone = this.try;
      this.onPhone();
    }
  }

  onEmailClear() {
    this.oldemail = '';
    this.err = '';
    this.emailvalid = false;
    this.code = '';
    this.code1 = '';
    this.code2 = '';
    this.code3 = '';
    this.code4 = '';
    this.inst = '';
  }

  onEmailKeyup() {
    if (this.emailvalid && (this.email !== this.oldemail)) {
      this.onEmailClear();
    }
  }

  onMobileClear() {
    this.oldphone = '';
    this.err = '';
    this.phonevalid = false;
    this.code = '';
    this.code1 = '';
    this.code2 = '';
    this.code3 = '';
    this.code4 = '';
    this.inst = '';
  }

  onMobileKeyup() {
    if (this.phonevalid && (this.phone !== this.oldphone)) {
      this.onMobileClear();
    }
  }

  onPhone() {
    this.err = '';
    this.adm = null;
    const x = this.admin.isValidPhone(this.phone);
    if (this.admin.phoneNumber && this.admin.phoneNumber.isValid()) {
      this.code = this.admin.randomString(true, 4);
      this.hs.postRFCValidPhone(this.admin.phoneNumber.number, this.code, this.yourname, 'Inst')
        .then(res => {
          if (res.adm) {
            this.adm = res.adm;
          }
        });

      this.phonevalid = true;
      this.code1 = '';
      this.code2 = '';
      this.code3 = '';
      this.code4 = '';
      this.oldphone = this.phone;
      this.inst = `${this.admin.t['vcodeemsent']} ${this.phone}.`;
    }
  }

  onEmail() {
    this.adm = null;
    this.err = '';
    this.email = this.email.toLowerCase();
    if (this.admin.validateEmail(this.email)) {
      this.code = this.admin.randomString(true, 4);
      this.hs.postROEValidEmail(this.email, this.code, 'Capptivation', 'Inst')
        .then(res => {
          if (res.adm) {
            this.adm = res.adm;
          }
        });
      this.emailvalid = true;
      this.code1 = '';
      this.code2 = '';
      this.code3 = '';
      this.code4 = '';
      this.oldemail = this.email;
      this.inst = `${this.admin.t['vcodeemsent']} ${this.email}.`;
    } else {
      this.err = this.admin.t['invemail']; // 'Invalid email address';
    }
  }

  onConfirm() {
    this.err = '';
    if (this.code === this.code1 + this.code2 + this.code3 + this.code4) {
      if (this.adm) {
        this.authx.auth.currentUser.delete()
          .then(p => {
            this.admin.admin = this.adm;
            const x = this.admin.admin.orgs.length - 1;
            this.admin.admin.org = this.admin.admin.orgs[x].organ;
            this.admin.admin.modules = this.admin.admin.orgs[x].modules;
            this.authx.auth.signInWithEmailAndPassword(this.adm.email, this.adm.password)
              .then(user => {
                this.onComplete();
              })
              .catch(err => console.log('signin error', err));
          })
          .catch(err => console.log('signout error', err));
      } else {
        this.onMobileClear();
        this.step = 7;
      }
    } else {
      this.err = this.admin.roct['vcinvalid']; //'Invalid Verification Code';
    }
  }

  onEmailConfirm() {
    this.err = '';
    if (this.code === this.code1 + this.code2 + this.code3 + this.code4) {
      this.onMobileClear();
      this.step = 7;
    } else {
      this.err = this.admin.roct['vcinvalid']; //'Invalid Verification Code';
    }
  }

  async onPhoneConfirm() {
    this.err = '';
    if (this.code === this.code1 + this.code2 + this.code3 + this.code4) {
      // create account
      console.log('adm', this.adm)
      if (this.adm) {
        this.authx.auth.currentUser.delete()
          .then(p => {
            this.authx.auth.signInWithEmailAndPassword(this.adm.email, this.adm.password)
              .then(user => {

                this.admin.admin = this.adm;
                console.log('setup admin', this.admin.admin)
                const ay = new Adminy();
                ay.modules = [];
                ay.name = this.nameoforg;
                ay.organ = this.ukey;
                console.log('ay first', ay)
                console.log('instMdoules', InstModules)
                InstModules.forEach(i => {
                  const obj = {val: true, name: i.name, code: i.code};
                  ay.modules.push(obj);
                });

                this.admin.admin.orgs.push(ay);
                this.hs.postMakeInst(this.admin.admin, null, null);
                const x = this.admin.admin.orgs.length - 1;
                this.admin.admin.org = this.admin.admin.orgs[x].organ;
                this.admin.admin.modules = this.admin.admin.orgs[x].modules;


                this.step = 8;
              })
              .catch(err => console.log('signin error', err));
          })
          .catch(err => console.log('signout error', err));
      } else {

        // need to login and get a uid

        // save the ids to IDS
        const idsemail = {
          id: `Inst` + this.email,
          idonly: this.email,
          indivukey: this.ukey,
          org: this.nameoforg,
          date: new Date().getTime(),
          users: {[this.ukey]: true}
        };

        const idsphone = {
          id: `Inst` + this.admin.phoneNumber.number,
          idonly: this.admin.phoneNumber.number,
          indivukey: this.ukey,
          org: this.nameoforg,
          date: new Date().getTime(),
          users: {[this.ukey]: true}
        };
        // create an admin and save it to Marketing
        const admin = new Admin();

        admin.name = this.yourname;
        admin.firebaseuid = this.authx.auth.currentUser.uid;
        admin.ukey = this.ukey;
        admin.email = this.useremail;
        admin.orgemail = this.email;
        admin.phone = this.admin.phoneNumber.number;
        admin.type = 'Inst';
        admin.subtype = '';
        admin.role = 'Admin';
        admin.deviceid = -1;
        admin.client = false;
        admin.org = this.nameoforg;
        admin.sponsor = '';
        admin.password = this.ukey;
        admin.programs = '';
        admin.staff = '';
        admin.locations = '';
        admin.modules = [];
        const adm = new Adminy();

        adm.modules = [];
        adm.name = this.nameoforg;
        adm.organ = this.ukey;;
        InstModules.forEach(i => {
          const obj = {val: true, name: i.name, code: i.code};
          adm.modules.push(obj);
          admin.modules.push(obj);
        });

        admin.orgs = [adm];
        this.hs.postMakeInst(admin, idsemail, idsphone);

        this.admin.admin = admin;
        this.admin.admin.org = this.admin.admin.orgs[0].organ;
        this.admin.admin.modules = this.admin.admin.orgs[0].modules;

        this.step = 8;
      }
    } else {
      this.err = this.admin.roct['vcinvalid']; // 'Invalid Verification Code';
    }
  }

  onComplete() {
    this.admin.preview = false;
    this.admin.loggedin = true;
    this.admin.roc = false;
    this.router.navigateByUrl('/instparent');

  }

  onLangChange(event) {
    if (this.admin.curlang !== Languages[event].abbr) {
      this.admin.curlang = Languages[event].abbr;
      this.admin.t = null;
      this.admin.roct = null;
      this.is.getTrans();
    }
  }
}
