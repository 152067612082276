import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AdminService} from '../services/admin.service';
import {InterfaceService, LandingBox, OrgEdition, Phone} from '../services/interface.service';
import {InstService} from '../services/inst.service';

@Component({
  selector: 'app-inst-edition',
  templateUrl: './inst-edition.component.html',
  styleUrls: ['./inst-edition.component.scss']
})
export class InstEditionComponent implements OnInit, OnChanges {
  @Input()
  cured: OrgEdition;

  @Input()
  curphone: Phone;

  @Input()
  loc = 'Inst';

  pth: string;
  pos = 0;
  toolbar: LandingBox[];

  primarycolor = '#0000FF';
  secondarycolor = '#ffffff';

  constructor(public admin: AdminService,
              public inst: InstService,
              private is: InterfaceService) {

  }

  ngOnInit() {
    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();

    this.setUp();
  }

  setUp() {
    if (this.loc === 'Orgs') {
      this.pth = `${this.loc}/${this.admin.admin.ukey}/${this.cured.ukey}/landing`;
      this.is.getList(this.pth).take(1).subscribe(landing => {
        this.toolbar = landing;
      });
    } else if (this.loc === 'Inst' && this.inst.eds && this.inst.eds.length > 0) {
        const tool = this.inst.eds.find(e => e.ukey === this.cured.ukey).landing;
        this.toolbar = Object.values(tool);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setUp();
  }


}
