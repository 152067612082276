import {Component, OnDestroy, OnInit} from '@angular/core';
import {CaseAgent, CaseProfile, CovidEmployee, Doctor, InterfaceService, Topix} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {AngularFireDatabase} from '@angular/fire/database';
import * as algoliasearch from 'algoliasearch';
import {environment} from '../../environments/environment';
import * as _ from 'lodash';
import {RoehttpService} from '../services/roehttp.service';
import {Attributes, Languages} from '../services/constants';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-corp-parent',
  templateUrl: './corp-parent.component.html',
  styleUrls: ['./corp-parent.component.scss']
})
export class CorpParentComponent implements OnInit, OnDestroy {
  confirmemployee: false;
  domain: string;
  details = false;
  topics: Topix[];
  curloc: Topix;
  typeorg = 'Business';
  pos = -1;
  surveys;
  attributes;
  attribs = Attributes;
  pick = -1;

  agents: CaseAgent[];
  curagent: CaseAgent;
  agentdetails = false;
  casedetails = false;
  unassigned: CovidEmployee[];
  emps: CovidEmployee[];



  curdoc: Doctor;
  docs: Doctor[];
  docdetails = false;
  languages = Languages;

  $sub1;
  $sub2;
  $sub3;
  $sub4;
  $sub5;
  $sub6;
  $sub7;

  aindex;
  aclient;

  cases: CaseProfile[];

  terms = '';

  searchConfig = {
    searchClient: algoliasearch(`A4YFG99HMY`, `373dbe79a969c04fca8f3ea347126b65`),
    indexName: 'covid',
    routing: true
  };

  constructor(private admin: AdminService,
              private http: RoehttpService,
              private db: AngularFireDatabase,
              public is: InterfaceService) {
  }

  ngOnInit() {
    console.log('CORP PARENT')
    this.aclient = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);
    this.aindex = this.aclient.initIndex('covid');

    this.$sub1 = this.is.getList(`CorpsPri/${this.admin.admin.org}/inbox`).subscribe(inbox => {
      this.admin.inbox = inbox;
    });

    if (this.admin.admin.subtype === 'Medical') {
      this.$sub2 = this.is.getList(`CorpsPri/${this.admin.admin.org}/docs`).subscribe(docs => {
        this.docs = docs;
      });
    }

    this.unassigned = [];
    this.$sub3 = this.is.getList(`CorpsPri/${this.admin.admin.org}/topics`).subscribe(ts => {
      this.topics = ts;

      if (this.admin.isgovt) {
        this.$sub4 = this.is.getList(`CorpsPri/${this.admin.admin.org}/agents`).subscribe(agents => {
          this.agents = agents;
        });


        if (this.topics && this.topics[0] && (!this.unassigned || this.unassigned.length === 0)) {
          this.$sub5 = this.is.getList(`CorpsPri/${this.admin.admin.org}/location/${this.topics[0].ukey}/employees`).subscribe(emps => {
            if (emps && emps.length > 0) {
              this.emps = emps;
              this.unassigned = _.orderBy(emps.filter(e => !e.agentukey), 'name', 'asc');
            }
          });
        }
      }
    });


    this.$sub6 = this.is.getList(`CorpsPri/${this.admin.admin.org}/surveys`).subscribe(surveys => {
      this.surveys = surveys;
    });

    this.$sub7 = this.is.getObject(`CorpsPri/${this.admin.admin.org}/profile`)
      .take(1)
      .subscribe(profile => {
        if (profile) {
          if (profile['type']) {
            this.typeorg = profile['type'];
          }
          if (profile['terms']) {
            this.terms = profile['terms'];
          }
          if (profile['logo']) {
            this.admin.logo = profile['logo'];
          }
          if (this.admin.isgovt) {
            if (profile && profile.quarantinePeriod) {
              this.admin.quarantinePeriod = profile.quarantinePeriod;
            }
            if (profile && profile.isolationPeriod) {
              this.admin.isolationPeriod = profile.isolationPeriod;
            }
          }
        }
      });

  }

  onAddDoctor() {
    this.curdoc = new Doctor();
    this.docdetails = true;
  }

  onSaveDoctor() {
    // todo: email check, phone number check, etc.
    if (!this.docs) {
      this.docs = [];
    }
    if (!this.curdoc.ukey) {
      this.curdoc.ukey = this.db.createPushId();
      this.docs.push(this.curdoc);
    } else {
      const x = this.docs.findIndex(d => d.ukey === this.curdoc.ukey);
      if (x > -1) {
        this.docs[x] = this.curdoc;
      }
    }
    this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/docs/${this.curdoc.ukey}`, this.curdoc);
    this.docdetails = false;
  }

  onEditDoc(i) {
    this.curdoc = this.docs[i];
    this.docdetails = true;
  }

  onDeleteDoc(i) {
    this.is.deleteObjNoLog(`CorpsPri/${this.admin.admin.org}/docs/${this.docs[i].ukey}`);
    this.docs.splice(i, 1);
  }

  onDeleteAgent() {
    // todo: implement
    console.log('not implemented');
  }


  onSaveAgent() {
    this.curagent.attributes = this.attributes.join(',');
    const path = `CorpsPri/${this.admin.admin.org}/agents/${this.curagent.ukey}`;
    this.is.setObjNoLog(`${path}`, this.curagent);
    this.agentdetails = false;
  }

  onEditAgent(i) {
    this.curagent = this.agents[i];
    this.attributes = this.curagent.attributes.split(',');
    this.agentdetails = true;
  }

  onAddCase() {
    this.casedetails = true;
  }

  getAsterix(a) {
    const at = this.unassigned[this.pick]['attributes'].split(',');
    const arr = a.split(',')
    const result = _.intersectionBy(at, arr);
    let str = '';
    if (result && result.length > 0) {
      result.forEach(r => str = str + '*');
    }
    return str;
  }

  onRemoveCase(i) {
    // this is the same code as in corp-casemgt so need to keep them synched
    this.http.getField(`CovidCases/${this.curagent.cases[i].caseukey}`)
      .take(1)
      .subscribe(caz => {
        if (caz) {
          let empukey;
          if (caz.profile.indivukey && caz.profile.indivukey.length > 0) {
            this.is.getQuery(`CorpsPri/${this.topics[0].confirmpath}`, 'indivukey', caz.profile.indivukey)
              .take(1)
              .subscribe(person => {
                if (person && person.length > 0) {
                  this.http.deleteField(`Individual/${caz.profile.indivukey}/covid/tracer`);
                  empukey = person[0].ukey;
                  this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/location/${this.topics[0].ukey}/employees/${person[0].ukey}/agentukey`, '');
                }
              });
          } else {
            // since no indivukey in CovidCases this means that the user has not downloaded the Capp
            // so can use the caseukey as the employee ukey because that's how i set it up
            empukey = caz.profile.caseukey;
            this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/location/${this.topics[0].ukey}/employees/${caz.profile.caseukey}/agentukey`, '');

          }
          this.http.deleteField(`CovidCases/${caz.profile.caseukey}/tracer`);
          if (empukey) {
            const emp = this.emps.find(e => e.ukey === empukey);
            if (emp) {
              emp.agentukey = null;
              this.unassigned = _.orderBy(this.emps.filter(e => !e.agentukey), 'name', 'asc');
            }
          }

          this.curagent.cases.splice(i, 1);
          this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/agents/${this.curagent.ukey}/cases`, this.curagent.cases);
        }
      });
  }

  onSelectCase(u: CovidEmployee, i) {
    const obj = {caseukey: u.caseukey, name: u.name};
    if (!this.curagent.cases) {
      this.curagent.cases = [];
    }
    this.curagent.cases.push(obj);
    this.curagent.cases = _.orderBy(this.curagent.cases, 'name', 'asc');
    this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/agents/${this.curagent.ukey}/cases`, this.curagent.cases);

    if (u.indivukey) {
      const trc = {agentukey: this.curagent.ukey,
                   agentname: this.curagent.name,
                   loc: this.topics[0].ukey,
                   empukey: u.ukey,
                   org: this.admin.admin.org};
      this.http.setField(`Individual/${u.indivukey}/covid/tracer`, trc);
      this.is.setObjNoLog(`CovidCases/${u.caseukey}/tracer`, trc);
      this.is.setObjNoLog(`CovidCases/${u.caseukey}/admin/${this.curagent.ukey}`, this.curagent.ukey);
    }
    this.unassigned.splice(i, 1);
    this.casedetails = false;
    this.pick = -1;
    this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/location/${this.topics[0].ukey}/employees/${u.ukey}/agentukey`, this.curagent.ukey);
  }

  getCaseNum(agent) {
    let cnt = 0;
    if (agent.cases) {
      cnt = agent.cases.length;
    }
    return cnt;
  }

  onAddAgent() {
    if (!this.topics || this.topics.length === 0) {
    } else {
      this.pos = -1;
      this.agentdetails = true;
      this.curagent = new CaseAgent();
      this.curagent.ukey = this.db.createPushId();
      this.curagent.loc = this.topics[0].ukey;
      this.curagent.attributes = '';
      this.attributes = [];
      this.curagent.cases = [];
    }
  }

  onAddLoc() {
    this.pos = -1;
    this.details = true;
    this.curloc = new Topix(this.admin.admin.org);
    this.curloc.ukey = this.db.createPushId();
    this.curloc.objectID = this.curloc.ukey;
    this.curloc.type = this.typeorg.toLowerCase();
    this.curloc.confirmpath = `${this.admin.admin.org}/location/${this.curloc.ukey}/employees`;
    this.curloc.public = true;
    this.curloc.topic = this.curloc.ukey;
  }

  onEditLoc(i) {
    console.log('edit');
    this.pos = i;
    this.details = true;
    this.curloc = this.topics[i];
  }

  onDeleteLoc(i) {
    // todo: deleting this is only a partial solution. really need to go through all the followers and delete them too
    this.aindex.deleteObject(this.topics[i].objectID);
    this.is.deleteObjNoLog(`CorpsPri/${this.admin.admin.org}/topics/${this.topics[i].ukey}`);
    this.topics.splice(i, 1);

  }

  onSurveyChange(event) {

    /*
        if (!this.curloc.prosurveydesc) {
          this.curloc.prosurveydesc
        }
    */

    this.curloc.prosurveydesc = this.surveys.find(s => s.ukey = event.value)['desc'];
  }


  onSavePeriods(isinfec, event) {
    console.log('event', event)

/*
    if (isinfec) {
      this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/profile/isolationPeriod`, event);
    } else {
      this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/profile/quarantinePeriod`, event);

    }
*/
  }

  onSaveTerms(event) {
    this.terms = event;
    this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/profile/terms`, event);
  }

  onSaveLogo(event) {
    this.admin.logo = event;
    this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/profile/logo`, event);
  }

  onSaveLoc() {
    let cntt = 0;
    this.languages.forEach(l => {
      if (l.abbr !== 'en') {
        this.admin.postTrans(l.abbr, this.curloc.description['en'].description).then(val => {
          this.curloc.description[l.abbr].description = val['data'].translations[0].translatedText;
          cntt = cntt + 1;
          if (cntt === 11) {
            this.onSaveLocComplete();
          }
        });
      }
    });
  }

  onAgentChange(event) {
    console.log('agent change', this.curagent);

  }

  onSaveLocComplete() {
    const path = `CorpsPri/${this.admin.admin.org}/topics/${this.curloc.ukey}`;
    this.curloc.location = path;
    this.curloc.policyurl = this.terms;
    this.is.setObjNoLog(`${path}`, this.curloc);
    if (this.pos === -1) {
      this.aindex.addObject(this.curloc);
      if (!this.topics) {
        this.topics = [];
      }
      this.topics.push(this.curloc);
    } else {
      this.aindex.partialUpdateObject(this.curloc, err => {
        console.log('err', err);
      });
      this.topics[this.pos] = this.curloc;
    }
    this.details = false;
  }

  onTypeChange() {
    this.is.setObjNoLog(`CorpsPri/${this.admin.admin.org}/profile/type`, this.typeorg);

  }

  onTabChange(event) {
    this.pick = -1;
    console.log('event', event);
//   this.curagent = null;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.curagent) {
      this.onSelectCase(this.unassigned[event.previousIndex], event.previousIndex);
    }
  }

  ngOnDestroy(): void {
    if (this.$sub1) {
      this.$sub1.unsubscribe()
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe()
    }
    if (this.$sub3) {
      this.$sub3.unsubscribe()
    }
    if (this.$sub4) {
      this.$sub4.unsubscribe()
    }
    if (this.$sub5) {
      this.$sub5.unsubscribe()
    }
    if (this.$sub6) {
      this.$sub6.unsubscribe()
    }
    if (this.$sub7) {
      this.$sub7.unsubscribe()
    }
  }

}
