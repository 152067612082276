import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {InterfaceService} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-all-quick',
  templateUrl: './all-quick.component.html',
  styleUrls: ['./all-quick.component.scss']
})
export class AllQuickComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  constructor(public is: InterfaceService,
              public admin: AdminService) {
    super(admin, is);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onDelete() {
    this.delete.emit();
  }

}
