import {
    AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer,
    ViewChild,
} from '@angular/core';
import {MatBottomSheet, MatDialog} from '@angular/material';
import {AngularFireDatabase} from '@angular/fire/database';
import {HttpClient} from '@angular/common/http';
import {MmPartiesComponent} from './mm-parties/mm-parties.component';
import * as _ from 'lodash';
import {InterfaceService} from '../services/interface.service';
import {Location} from '@angular/common';
import {AdminService, IncidentColors, MMIncident, MMMes, QuickFind} from '../services/admin.service';
import {ChatService} from '../services/chat.service';
import {RoehttpService} from '../services/roehttp.service';

@Component({
    selector: 'app-mm-chat',
    templateUrl: './mm-chat.component.html',
    styleUrls: ['./mm-chat.component.scss']
})
export class MmChatComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck, OnChanges {
    @Input()
    curincident: MMIncident;
    
    @Output()
    chatchange = new EventEmitter<string>();

    @ViewChild('scrollMe', {static: false}) private myScrollContainer: ElementRef;
    @ViewChild('texta', {static: false}) texta: ElementRef;


    hide = false;
    tos = '';
    messagetext: string;
    curmessage: MMMes;
    scrollheight: string;
    inputheight: string;
    rows: number;
    x = 0;
    offset = 0;
    rz = 1;
    clrs = IncidentColors;
    status = -1;
    msg: string;
    open = false;
    onpress = -1;
    bnpress = -1;
    $sub1;

    test = -1;
    last = -1;

    curkey: string;
    towhom: string;
    $sub7;
    $sub2;
    $sub8;
    ftime = true;


    constructor(private bottomSheet: MatBottomSheet,
                public rs: AdminService,
                private hs: RoehttpService,
                public cs: ChatService,
                private is: InterfaceService,
                private http: HttpClient,
                private db: AngularFireDatabase,
                private dialog: MatDialog,
                public cd: ChangeDetectorRef,
                private renderer: Renderer) {
        this.scrollheight = '92%';
        this.inputheight = '8%';

    }


    onUnHide() {
        this.hide = false;
        this.scrollToBottom();
        this.cd.detectChanges();
    }

    onHide() {
        this.hide = true;
        this.scrollToBottom();
        this.cd.detectChanges();
    }

    onBack() {
        this.rs.chat = false;
    }


    randomString() {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXTZ';
        const stringlength = 2;
        let randomstring = '';
        for (let i = 0; i < stringlength; i++) {
            const rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum, rnum + 1);
        }
        return randomstring;
    }


    otherSetup() {
        if (this.curincident) {
            this.curkey = this.curincident.ukey;
            this.towhom = '';

            const pth = `Devices/${this.rs.admin.deviceid}/convo/${this.curincident.ukey}/status`

            this.is.getObject(pth)
                .take(1).subscribe(status => {
                if (status) {
                    this.status = status;
                }
            });

            let y = 0;
            let abvs = '';
            this.rs.qf = new Object();
            this.curincident.parties.forEach(p => {
                const quickfind = new QuickFind();
                quickfind.back = this.clrs[y].back;
                quickfind.text = this.clrs[y].text;
                quickfind.abbr = p.abbreviation;

                if (!abvs) {
                    abvs = p.abbreviation;
                } else {
                    abvs = abvs + ', ' + p.abbreviation;
                }

                quickfind.pic = '';
                y = y + 1;
                this.rs.qf[p.ukey] = quickfind;
                if (!this.towhom) {
                    this.towhom = p.ukey;
                } else {
                    this.towhom = this.towhom + ',' + p.ukey;
                }
            });


            if (this.curincident.delps) {
                this.curincident.delps.forEach(p => {
                    const quickfind = new QuickFind();
                    quickfind.back = 'dddddd';
                    quickfind.text = '#000000';
                    quickfind.abbr = p.abbreviation;

                    if (!abvs) {
                        abvs = p.abbreviation;
                    } else {
                        abvs = abvs + ', ' + p.abbreviation;
                    }

                    quickfind.pic = '';
                    y = y + 1;
                    this.rs.qf[p.ukey] = quickfind;
                });
            }


            if (this.curincident.admins === this.rs.admin.deviceid.toString()) {
                this.tos = this.curincident.parties[1].name;
            } else {
                this.tos = this.curincident.parties[0].name;
            }
            console.log('tos', this.tos)
        }
    }

    ngOnInit() {
    }

    makeToWhom() {
        this.curkey = this.curincident.ukey;
        this.towhom = '';
        this.curincident.parties.forEach(p => {
            if (!this.towhom) {
                this.towhom = p.ukey;
            } else {
                this.towhom = this.towhom + ',' + p.ukey;
            }
        });
    }

    ngDoCheck() {
        if (this.texta && this.x !== this.texta.nativeElement.offsetHeight) {
            if (this.ftime) {
                this.ftime = false;
            } else {
                this.offset = this.texta.nativeElement.offsetHeight - this.x;
                this.scrollToBottom();
                this.cd.detectChanges();
            }
        }
    }


    ngAfterViewInit() {
        if (this.texta && this.x !== this.texta.nativeElement.offsetHeight) {
            this.x = this.texta.nativeElement.offsetHeight;
        }

        this.cd.detectChanges();
    }


    onParties() {
        this.bottomSheet.open(MmPartiesComponent, {});
    }

    onLoad() {
        this.scrollToBottom();
    }

    scrollToBottom(): void {
        /*
                try {
                    this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
                } catch (err) {
                }
        */
    }

    onSubmit() {
        if (this.messagetext && this.messagetext.length > 0) {
            const date = new Date();
            const dg = date.getTime();
            this.curmessage = new MMMes();

            this.curmessage = {
                f: this.rs.admin.deviceid.toString(),
                m: this.messagetext,
                d: dg,
                t: 't',
                e: '',
                b: false,
                a: ''
            };

            this.makeToWhom();
            this.is.setObjNoLog(`ROEDevices/${this.rs.admin.deviceid}/conversations/${this.curkey}/${dg}`, this.curmessage);

            let c = 'ROEDevices'
            if (this.curincident.parties[0].role === 'liftup') {
                c = 'Devices'
            }
            this.hs.postConsoleChat(this.curmessage, this.curkey, dg, this.towhom, this.curincident.parties[0].abbreviation, c);

            this.messagetext = '';
            const str = `${this.x}px`;
            this.offset = 0;

            /*
                        this.scrollToBottom();
                        */
            this.renderer.setElementStyle(this.texta.nativeElement, 'height', str);

        }
    }

    onFocus() {
        console.log('test focus');
        /*
                this.ht = 100;
                this.scrollToBottom()
        */
    }


    onHelp(codev: string) {
        // todo: need to implement
        /*
                const bottomRef = this.bottomSheet.open(MmTipComponent, {
                    data: {code: codev}
                });

                bottomRef.afterDismissed().subscribe(result => {
                    if (result) {
                        // this.mm.menu = 'compat';
                    }
                });
        */
    }


    onCl() {
        this.onpress = -1;
        this.bnpress = -1;
    }


    scrollHandler(event) {
        console.log('ev', event);
    }


    ngOnDestroy() {
        if (this.$sub7) {
            this.$sub7.unsubscribe();
        }

        if (this.$sub8) {
            this.$sub8.unsubscribe();
        }

        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }

        if (this.$sub2) {
            this.$sub2.unsubscribe()
        }
        const d = new Date().getTime();

        this.is.setObjNoLog(`Devices/${this.rs.admin.deviceid}/convo/${this.curkey}/lr`, d);

    }

    ngOnChanges() {
        console.log('change')
        if (this.$sub2) {
            this.$sub2.unsubscribe()
        }
        if (this.$sub7) {
            this.$sub7.unsubscribe();
        }

        let ftime = true;

        this.otherSetup();

        if (this.curincident) {
            this.$sub2 = this.is.getObject(`Devices/${this.rs.admin.deviceid}/frndmsg/${this.curincident.ukey}`).subscribe(m => {
                if (!ftime) {
                    if (m) {
                        this.curincident = m;
                        this.otherSetup();
                        this.cd.detectChanges();
                    }
                } else {
                    ftime = false;
                }

            });
        }

        this.$sub7 = this.cs.resloc.subscribe(val => {
            if (val) {
                this.cd.detectChanges();
            }
        });

    }
}
