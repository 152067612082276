import {Component, OnDestroy, OnInit} from '@angular/core';
import {BlockString, InterfaceService, LangStringBlock, Widget} from '../../services/interface.service';
import {Icons, Languages} from '../../services/constants';
import {AdminService} from '../../services/admin.service';

@Component({
    selector: 'app-edit-widget',
    templateUrl: './edit-widget.component.html',
    styleUrls: ['./edit-widget.component.scss']
})
export class EditWidgetComponent implements OnInit, OnDestroy {
    $sub1;
    rowidgets: Widget[];
    newwidget: Widget;
    languages = Languages;
    details = false;
    curlang: string;
    trans = false;

    icons = Icons;
    icon: string;
    primarycolor = '#99add0';
    secondarycolor = '#000000';


    constructor(public admin: AdminService,
                private is: InterfaceService) {
    }

    // todo: need to add translate ability
    ngOnInit() {
        this.curlang = this.admin.language;

        this.$sub1 = this.is.getList(`Interfaces/widgets`).subscribe(rowidgets => {
            this.rowidgets = rowidgets;
        });
    }

    onEdit(i, curlang: string) {
        this.newwidget = this.rowidgets[i];
        this.icon = this.newwidget.icon;
        console.log('neww', this.newwidget);
        this.details = true;
    }

    onAdd() {
        this.newwidget = new Widget();
        this.newwidget.abbrDesc = new LangStringBlock();
        this.languages.forEach(l => {
            this.newwidget.abbrDesc[l.abbr] = new BlockString();
            this.newwidget.abbrDesc[l.abbr].description = '';
            this.newwidget.abbrDesc[l.abbr].block = false;
        });
        this.newwidget.tag = '';
        this.newwidget.image = '';
        this.newwidget.icon = '';
        this.icon = '';

        this.details = true;
    }

    onDelete(i) {
        // todo: need to test
        this.is.deleteObjNoLog(`Interfaces/widgets/${this.rowidgets[i].ukey}`);
    }

    onLangChange(event) {
        this.curlang = this.languages[event].abbr;
    }

    async onTransEl() {
        await this.languages.forEach(lang => {
            if (lang.abbr !== 'en') {
                this.admin.postTrans(lang.abbr, this.newwidget.abbrDesc['en'].description).then(val => {
                    this.newwidget.abbrDesc[lang.abbr].description = val['data'].translations[0].translatedText;
                });
            }
        });

        setTimeout(() => {
            const pth = `Interfaces/widgets/${this.newwidget.ukey}`
            this.is.setObjNoLog(pth, this.newwidget);
        }, 3000);

    }


    onCancel() {
        this.details = false;
    }

    onSave() {
        if (this.newwidget.abbrDesc['en'].description) {
            if (!this.newwidget.ukey) {
                this.newwidget.ukey = this.newwidget.abbrDesc['en'].description;
            }
            this.is.setObjNoLog(`Interfaces/widgets/${this.newwidget.ukey}`, this.newwidget);
        }
        this.details = false;
    }

    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
    }
}
