import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {environment} from '../../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FullCalendarModule} from '@fullcalendar/angular';
import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import {PhoneObjComponent} from '../lib/phone-obj/phone-obj.component';
import {HoursComponent} from '../lib/hours/hours.component';
import {BizDayComponent} from '../lib/biz-hours/biz-day/biz-day.component';
import {BizHoursComponent} from '../lib/biz-hours/biz-hours/biz-hours.component';
import {BizHoursContainerComponent} from '../lib/biz-hours/biz-hours-container/biz-hours-container.component';
import {BizMonthComponent} from '../lib/biz-hours/biz-month/biz-month.component';
import {BizYearComponent} from '../lib/biz-hours/biz-year/biz-year.component';
import {EmojisComponent} from '../lib/emojis/emojis.component';
import {ElementDialogComponent} from '../lib/element-dialog/element-dialog.component';
import {PickTemplateComponent} from '../lib/pick-template/pick-template.component';
import {IconSelectComponent} from '../lib/icons/icon-select/icon-select.component';
import {IconsComponent} from '../lib/icons/icons.component';
import {SearchByNameComponent} from '../lib/search-by-name/search-by-name.component';
import {SearchComponent} from '../lib/search/search.component';
import {NgAisHitsModule, NgAisInstantSearchModule, NgAisModule} from 'angular-instantsearch';
import {PhoneComponent} from '../lib/phone/phone.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {HelpComponent} from '../lib/help/help.component';
import {BottomSheetComponent} from '../lib/help/bottom-sheet/bottom-sheet.component';
import {MglTimelineModule} from 'angular-mgl-timeline';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {PapaParseModule} from 'ngx-papaparse';
import {HoursDetailsComponent} from '../lib/hours-details/hours-details.component';
import {ConfirmationComponent} from '../lib/confirmation/confirmation.component';
import {GraphicsComponent} from '../lib/archive/graphics/graphics.component';
import {ArchivesComponent} from '../lib/archive/archives/archives.component';
import {VideosComponent} from '../lib/archive/videos/videos.component';
import {ArchiveWidgetComponent} from '../lib/archive/archive-widget/archive-widget.component';
import {MakeRequestComponent} from '../lib/make-request/make-request.component';
import {LanguageComponent} from '../lib/language/language.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/languages/zh_cn.js';
import {OrgbizhoursComponent} from '../lib/tabs/orgbizhours/orgbizhours.component';
import {OrgEventsComponent} from '../org-events/org-events.component';
import {HolidaysComponent} from '../lib/tabs/holidays/holidays.component';
import {CaleventComponent} from '../lib/tabs/calevent/calevent.component';
import {ROCalendarComponent} from '../lib/tabs/calendar/calendar.component';
import {EvtSimpleComponent} from '../lib/tabs/calevent/evt-simple/evt-simple.component';
import {PeriodsComponent} from '../lib/tabs/periods/periods.component';
import {EvtNoneComponent} from '../lib/tabs/calevent/evt-none/evt-none.component';
import {EvtWrapperComponent} from '../lib/tabs/calevent/evt-wrapper/evt-wrapper.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {ComEventsComponent} from '../com-events/com-events.component';
import {FlipModule} from 'ngx-flip';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {ComContainerComponent} from '../lib/biz-hours/com-container/com-container.component';
import {ComHoursComponent} from '../lib/com-hours/com-hours.component';
import {ComBizMonthComponent} from '../lib/biz-hours/com-biz-month/com-biz-month.component';
import {CappGuideComponent} from '../capp-guide/capp-guide.component';
import {UsingGuidesComponent} from '../lib/make-request/using-guides/using-guides.component';
import {EditorComponent} from '../lib/editor/editor.component';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {TopicPickComponent} from '../lib/topic-pick/topic-pick.component';
import {SurveyResultsComponent} from '../lib/survey-results/survey-results.component';
import {DateTimeComponent} from '../lib/date-time/date-time.component';
import {OrgComscenterComponent} from '../org-comscenter/org-comscenter.component';
import {OrgNotificationsComponent} from '../org-notifications/org-notifications.component';
import {OrgChatComponent} from '../org-chat/org-chat.component';
import {MmChatComponent} from '../mm-chat/mm-chat.component';
import {OrgExhibitsComponent} from '../org-exhibits/org-exhibits.component';
import {RoeXComponent} from '../notify/roe-x/roe-x.component';
import {RoeAComponent} from '../notify/roe-a/roe-a.component';
import {IncRComponent} from '../notify/inc-r/inc-r.component';
import {OrgProgramsComponent} from '../org-programs/org-programs.component';
import {RoeSurveyComponent} from '../notify/roe-survey/roe-survey.component';
import {HcProfileComponent} from '../health-parent/hc-profile/hc-profile.component';
import {HcMedsComponent} from '../health-parent/hc-meds/hc-meds.component';
import {HcWellbeingComponent} from '../health-parent/hc-wellbeing/hc-wellbeing.component';
import {HcMeasureComponent} from '../health-parent/hc-measure/hc-measure.component';
import {NgxSummernoteModule} from 'ngx-summernote';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {GalleryComponent} from '../lib/gallery/gallery.component';
import {SocialComponent} from '../notify/social/social.component';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {ROMapComponent} from '../map-custom/map-custom.component';
import {OfficeCardComponent} from '../office-card/office-card.component';
import {StaffCardComponent} from '../staff-card/staff-card.component';
import {ComCardComponent} from '../com-card/com-card.component';
import {ComHoursDetailsComponent} from '../lib/com-hours-details/com-hours-details.component';
import {AngularResizedEventModule} from 'angular-resize-event';
import {CorpNoteBodyComponent} from '../corp-note-body/corp-note-body.component';
import {RoeEventComponent} from '../notify/roe-event/roe-event.component';
import {RoeResourcesComponent} from '../notify/roe-resources/roe-resources.component';
import {AddressesComponent} from '../lib/addresses/addresses.component';
import {AppModule} from '../app.module';
import {EvtCappComponent} from '../lib/tabs/calevent/evt-capp/evt-capp.component';
import {RoeAaComponent} from '../notify/roe-aa/roe-aa.component';
import {CorpNotificationsComponent} from '../corp-notifications/corp-notifications.component';
import {MilestonesComponent} from '../milestones/milestones.component';
import {FaqsComponent} from '../faqs/faqs.component';
import {RoeFaqComponent} from '../notify/roe-faq/roe-faq.component';
import {UsersComponent} from '../users/users.component';
import {NotifyComponent} from '../notify/notify.component';
import { DelCardComponent } from '../notify/del-card/del-card.component';
import {FooterCardComponent} from '../notify/footer-card/footer-card.component';
import { RoeTemplateComponent } from '../notify/roe-template/roe-template.component';
import { CtfromiComponent } from '../notify/ctfromi/ctfromi.component';
import { CttoiComponent } from '../notify/cttoi/cttoi.component';
import { RoeTodoComponent } from '../notify/roe-todo/roe-todo.component';
import { RoeDeleventComponent } from '../notify/roe-delevent/roe-delevent.component';
import { RoeNewcontactComponent } from '../notify/roe-newcontact/roe-newcontact.component';
import { CappComponent } from '../notify/capp/capp.component';
import { AllQuickComponent } from '../notify/all-quick/all-quick.component';
import { RoeDriveinviteComponent } from '../notify/roe-driveinvite/roe-driveinvite.component';
import {DragDirective} from '../services/dragDrop.directive';
import {ComGraphicsComponent} from '../com-storage/com-graphics/com-graphics.component';
import {ExhibitCardComponent} from '../lib/exhibit-card/exhibit-card.component';
import {ComArchivesComponent} from '../com-storage/com-archives/com-archives.component';
import {SocialInviteComponent} from '../notify/social-invite/social-invite.component';
import {GlobalComponent} from '../global/global.component';
import {StepsComponent} from '../notify/steps/steps.component';
import {InstEditionComponent} from '../inst-edition/inst-edition.component';
import {ArticleCardComponent} from '../article-card/article-card.component';
import {NotesComponent} from '../lib/notes/notes.component';
import {ArticleNotifComponent} from '../article-notif/article-notif.component';
import {ExhibitGalleryComponent } from '../exhibit-gallery/exhibit-gallery.component';
import {InstDeviceComponent} from '../inst-device/inst-device.component';
import {CodeVeryifyComponent } from '../lib/code-veryify/code-veryify.component';
import {ContentGenericComponent } from '../notify/content-generic/content-generic.component';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {MapCardComponent } from '../map-card/map-card.component';
import {FlipBoxComponent} from '../lib/flip-box/flip-box.component';
import {FlipBoxTemplateComponent } from '../lib/flip-box/flip-box-template/flip-box-template.component';
import {MapCardNuggetComponent} from '../map-card-nugget/map-card-nugget.component';
import {AgmOverlays} from 'agm-overlays';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};

@NgModule({
  declarations: [
    PhoneObjComponent,
    HoursComponent,
    BizDayComponent,
    BizHoursComponent,
    BizHoursContainerComponent,
    BizMonthComponent,
    BizYearComponent,
    HoursDetailsComponent,
    EmojisComponent,
    ElementDialogComponent,
    PickTemplateComponent,
    IconsComponent,
    IconSelectComponent,
    SearchByNameComponent,
    SearchComponent,
    PhoneComponent,
    HelpComponent,
    BottomSheetComponent,
    ConfirmationComponent,

    GraphicsComponent,
    ArchivesComponent,
    VideosComponent,
    ArchiveWidgetComponent,

    MakeRequestComponent,
    UsingGuidesComponent,
    LanguageComponent,

    OrgEventsComponent,
    OrgNotificationsComponent,
    IncRComponent,
    DelCardComponent,
    FooterCardComponent,
    RoeTemplateComponent,
    RoeAComponent,
    RoeAaComponent,
    StepsComponent,
    RoeXComponent,
    RoeFaqComponent,
    RoeEventComponent,
    RoeResourcesComponent,
    CtfromiComponent,
    CttoiComponent,
    RoeTodoComponent,
    RoeDeleventComponent,
    RoeNewcontactComponent,
    CappComponent,
    AllQuickComponent,
    RoeDriveinviteComponent,

    OrgChatComponent,
    MmChatComponent,
    OrgExhibitsComponent,

    OrgComscenterComponent,
    ComEventsComponent,
    PeriodsComponent,
    OrgbizhoursComponent,
    HolidaysComponent,
    ROCalendarComponent,
    CaleventComponent,
    EvtSimpleComponent,
    EvtNoneComponent,
    EvtWrapperComponent,

    ComContainerComponent,
    ComHoursComponent,
    ComBizMonthComponent,

    CappGuideComponent,
    EditorComponent,
    TopicPickComponent,

    SurveyResultsComponent,
    DateTimeComponent,

    RoeSurveyComponent,
    OrgProgramsComponent,
    HcProfileComponent,
    HcMeasureComponent,
    HcMedsComponent,
    HcWellbeingComponent,

    GalleryComponent,
    SocialComponent,
    SocialInviteComponent,

    ROMapComponent,
    OfficeCardComponent,
    StaffCardComponent,
    ComCardComponent,
    ComHoursDetailsComponent,

    CorpNoteBodyComponent,
    AddressesComponent,
    EvtCappComponent,
    CorpNotificationsComponent,
    MilestonesComponent,
    FaqsComponent,

    UsersComponent,
    NotifyComponent,

    ComGraphicsComponent,
    ExhibitCardComponent,
    ComArchivesComponent,

    GlobalComponent,

    DragDirective,
    InstEditionComponent,
    InstDeviceComponent,
    ArticleCardComponent,
    NotesComponent,
    ArticleNotifComponent,
    ExhibitGalleryComponent,
    CodeVeryifyComponent,
    ContentGenericComponent,
    MapCardComponent,
    MapCardNuggetComponent,

    FlipBoxComponent,
    FlipBoxTemplateComponent,
  ],
    imports: [
        CommonModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),

        AngularFireModule.initializeApp(environment.firebaseTitleIXConfig),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,

        AgmOverlays,
        AgmCoreModule.forRoot({
            apiKey: environment.GOOGLE_MAPS_KEY,
            language: 'en'
        }),

        DragDropModule,


        NgAisModule.forRoot(),
        NgAisHitsModule,
        NgAisInstantSearchModule,

        PapaParseModule,
        HttpClientModule,
        FlexLayoutModule,
        FlipModule,
        FullCalendarModule,


        MatProgressBarModule,
        MatListModule,
        MatCardModule,
        MatButtonModule,
        MatMenuModule,
        MatChipsModule,
        MatIconModule,
        MatTableModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatDialogModule,
        MatInputModule,
        MatRadioModule,
        MatSidenavModule,
        MatToolbarModule,
        MatGridListModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatBottomSheetModule,
        MatSortModule,
        MatBadgeModule,
        MatSliderModule,


        ColorPickerModule,
        MglTimelineModule,
        PdfViewerModule,


        SwiperModule,

        NgxMaterialTimepickerModule,
        NgxSummernoteModule,
        AngularResizedEventModule,

      NgJsonEditorModule,

    ],
  exports: [
    HcProfileComponent,
    HcMeasureComponent,
    HcMedsComponent,
    HcWellbeingComponent,

    MatProgressBarModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTableModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDialogModule,
    MatInputModule,
    MatRadioModule,
    MatSidenavModule,
    MatToolbarModule,
    MatGridListModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatSliderModule,
    MatChipsModule,

    MatListModule,
    MatSortModule,
    FlipModule,

    DragDropModule,

    ColorPickerModule,
    MglTimelineModule,
    PdfViewerModule,
    PapaParseModule,

    AgmCoreModule,

    NgAisModule,
    NgAisHitsModule,
    NgAisInstantSearchModule,

    NgxSummernoteModule,
    NgJsonEditorModule,


    AngularFireModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    FlexLayoutModule,
    FullCalendarModule,
    FroalaEditorModule,
    FroalaViewModule,

    AngularResizedEventModule,

    PhoneObjComponent,
    HoursComponent,
    BizHoursContainerComponent,
    HoursDetailsComponent,
    EmojisComponent,
    ElementDialogComponent,
    PickTemplateComponent,
    IconsComponent,
    IconSelectComponent,
    SearchByNameComponent,
    SearchComponent,
    PhoneComponent,
    HelpComponent,
    BottomSheetComponent,

    GraphicsComponent,
    ArchivesComponent,
    VideosComponent,
    ArchiveWidgetComponent,

    MakeRequestComponent,
    LanguageComponent,

    ROCalendarComponent,
    CaleventComponent,
    HolidaysComponent,
    OrgbizhoursComponent,
    CappGuideComponent,
    EditorComponent,
    TopicPickComponent,

    NgxMaterialTimepickerModule,

    SurveyResultsComponent,
    DateTimeComponent,
    RoeSurveyComponent,

    OrgProgramsComponent,
    GalleryComponent,
    SocialComponent,
    SocialInviteComponent,


    ROMapComponent,
    OfficeCardComponent,
    StaffCardComponent,
    ComCardComponent,
    ComHoursDetailsComponent,

    CorpNoteBodyComponent,
    AddressesComponent,
    CorpNotificationsComponent,
    MilestonesComponent,
    UsersComponent,
    NotifyComponent,

    ComGraphicsComponent,
    ExhibitCardComponent,
    ComArchivesComponent,

    GlobalComponent,

    DragDirective,
    InstEditionComponent,
    InstDeviceComponent,
    CodeVeryifyComponent,
    ArticleCardComponent,
    NotesComponent,
    ArticleNotifComponent,
    ExhibitGalleryComponent,
    ContentGenericComponent,
    MapCardComponent,
    MapCardNuggetComponent,

    FlipBoxComponent,
    FlipBoxTemplateComponent,
  ],
  providers: [
    {provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG},
    GoogleMapsAPIWrapper,
  ],
  entryComponents: [
    HoursComponent,
    ComHoursComponent,
    ElementDialogComponent,
    PickTemplateComponent,
    IconSelectComponent,
    BottomSheetComponent,
    ConfirmationComponent,


  ]
})
export class SharedModule {
}
