import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import * as _ from 'lodash';
import {ArchiveLibrary, ArchiveList, InterfaceService} from '../../services/interface.service';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {FirebaseService} from '../../services/firebase.service';
import {AdminService} from '../../services/admin.service';
import {ConfirmationComponent} from '../../lib/confirmation/confirmation.component';
import {ArchiveService} from '../../services/archive.service';
import {FileHandle} from '../../services/dragDrop.directive';
import {AgmMap} from '@agm/core';
declare var google: any;

@Component({
  selector: 'app-com-archives',
  templateUrl: './com-archives.component.html',
  styleUrls: ['./com-archives.component.scss']
})
export class ComArchivesComponent implements OnInit {
  @Input()
  loc: string;

  @Input()
  archive;

  latt = -1;
  lngg = -1;
  spinner = false;
  copyright: string;
  location: string;

  archives: ArchiveList[];
  tmparchives: ArchiveList[];

  archivedetails = false;
  file: File;
  err: string;

  foldername: string;
  folderdetails = false;
  curfolder: string;
  folders: string[];

  files: FileHandle[] = [];
  select = false;
  fileTypes = [
    'text/html',
    'text/pdf',
    'text/txt',
    'text/doc',
    'text/docx',
  ];

  @ViewChild(AgmMap, {static: false}) mapElement: any;

  form = new FormGroup({
    dateofpub: new FormControl(''),
    name: new FormControl(''),
    summary: new FormControl(''),
  });

  get name() {
    return this.form.get('name');
  }

  get summary() {
    return this.form.get('summary');
  }

  get dateofpub() {
    return this.form.get('dateofpub');
  }


  constructor(private fs: FirebaseService,
              private ar: ArchiveService,
              private cd: ChangeDetectorRef,
              private dialog: MatDialog,
              private is: InterfaceService,
              private admin: AdminService) {
    this.curfolder = 'Main';
  }


  fixTmpArchives() {
    if (this.archives.length > 0) {
      this.archives.forEach(l => {
        l['date'] = new Date(l.dateofpub);
      });
      this.archives = _.orderBy(this.archives, ['date'], ['desc']);
    }


    if (!this.curfolder || this.curfolder === 'Main') {
      this.tmparchives = this.archives;
    } else {
      this.tmparchives = this.archives.filter(b => b.folder === this.curfolder);
    }
  }

  ngOnInit() {
    this.archives = [];
    if (this.archive && this.archive.documents) {
      this.archives = Object.values(this.archive.documents);
      this.fixTmpArchives();
    } else {
      this.fixTmpArchives();
    }

    this.folders = [];
    if (this.archive && this.archive.documentsfolders) {
      this.folders = Object.values(this.archive.documentsfolders);
      this.folders = _.orderBy(this.folders);
    } else {
      this.folders.push('Main');
      this.folders = _.orderBy(this.folders);
    }

    if (!this.archive) {
      this.archive = {};
    }

    if (!this.archive.documents) {
      this.archive.documents = [];
    }

    if (!this.archive.documentsfolders) {
      this.archive.documentsfolders = [];
    }
  }

  onChange(str) {
    this.curfolder = str;
    if (!this.curfolder || this.curfolder === 'Main') {
      this.tmparchives = this.archives;
    } else {
      this.archives = this.archives.filter(a => a.folder === this.curfolder);
    }
  }

  onAddArchive() {
    this.err = '';
    this.file = null;
    this.files = [];
    this.select = false;
    this.form.setValue({
      dateofpub: '',
      name: '',
      summary: '',
    });
    this.archivedetails = true;
  }

  validFileType(file) {
    for (let i = 0; i < this.fileTypes.length; i++) {
      if (file.type === this.fileTypes[i]) {
        return true;
      }
    }
    return false;
  }

  onSelect() {
    this.select = true;
    document.getElementById('filex').click();
  }

  filesDropped(files: FileHandle[]): void {
    if (this.validFileType(files[0].file)) {
      this.files = files;
      this.file = files[0].file;
      this.select = true;
      console.log('file name', this.file.name)
    }
  }

  onChangeFile(files, event) {
    this.file = files[0];
  }

  onCancel() {
    this.archivedetails = false;
  }

  onSave() {
    if (!this.name.value || !this.file) {
      this.err = 'Please complete the form.';
    } else {
      if (this.file && this.select) {
        const storageRef = firebase.storage();
        const self = this;
        const arc = new ArchiveList();
        arc.ukey = this.fs.titleIXdb.createPushId();
        arc.name = this.name.value;
        arc.dateofpub = new Date(this.dateofpub.value).toISOString();
        arc.summary = this.summary.value;
        arc.datearchived = new Date().toISOString();
        arc.folder = this.curfolder;
        const tmp = this.file.name.split('.').pop();
        const filename = `${this.loc}/${this.admin.admin.org}/archive/documents/${arc.ukey}.${tmp}`;
        arc.filename = `${arc.ukey}.${tmp}`;

        const archivecard = new ArchiveLibrary();
        archivecard.ukey = arc.ukey;
        archivecard.dateadded = arc.datearchived;
        archivecard.author = '';
        archivecard.pubdate = arc.dateofpub;

        archivecard.title = arc.name;
        archivecard.publication = '';
        archivecard.format = tmp;  // pdf, website
        archivecard.URL = '';
        archivecard.topic = '';
        archivecard.behavior = '';
        archivecard.population = '';

        archivecard.headline = '';
        archivecard.summary = arc.summary;
        archivecard.name = this.admin.admin.org;  // this is the name of the organization it applies to

        archivecard.target = '';
        archivecard.scope = '';
        archivecard.mustread = '';
        archivecard.costtoread = false;
        archivecard.rating = 0;  // this is the average rarting of users
        archivecard.surveyform = ''; // url to a feedback survey - NOT IMPLEMENTED
        archivecard.filename = `${arc.ukey}.${tmp}`;

        firebase.storage().ref().child(filename).put(self.file).then(
          v => {
            const s = `gs://library-titleix.appspot.com/${filename}`;
            storageRef.refFromURL(s).getDownloadURL().then(url => {
              arc.CapptivationURL = url;
              archivecard.CapptivationURL = url;

              if (!this.archives) {
                this.archives = [];
              }
              this.archives.push(arc);
              this.archive.documents = this.archives;
              this.ar.onSaveArchiveArchive(this.loc, this.archive.documents);
              // this.is.setObjFS(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg);
              this.fixTmpArchives();

              self.is.setObj(`Archive/${arc.ukey}`, archivecard, 'Legacy', ``);
            });
          }
        );
        this.archivedetails = false;
        this.select = false;
      } else {
        this.select = false;
      }
    }
  }

  onOpen(url) {
    window.open(url, '_blank');
  }

  onDeleteArchive(i) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: {text: 'Are you sure you want to delete this file?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.answer) {
        const filename = `${this.loc}/${this.admin.admin.org}/archive/documents/${this.archives[i].filename}`;

        const storageRef = firebase.storage();
        firebase.storage().ref().child(filename).delete().then(
          v => {
            console.log('deleted document');
          });
        const x = this.archives.findIndex(a => a.ukey === this.tmparchives[i].ukey);
        if (x > -1) {
          this.archives.splice(x, 1);
          this.tmparchives.splice(i, 1);
        }
        this.archive.archive = this.archives;
        this.ar.onSaveArchiveArchive(this.loc, this.archive.documents);
        // this.is.setObjFS(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg)

      }
    });
  }


  onSaveFolder() {
    this.folderdetails = false;
    this.folders.push(this.foldername);
    this.folders = _.orderBy(this.folders);
    this.archive.documentsfolders = this.folders;

    this.ar.onSaveArchiveArchive(this.loc, this.archive.documents);
    // this.is.setObjFS(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg);
  }

  onAddFolder() {
    this.folderdetails = true;
    this.foldername = '';
  }

  onDeleteFolder(i) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: {text: 'Are you sure you want to delete this folder? Note: If you are, all files in this folder will be moved to the Main folder'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.answer) {
        if (this.archives && this.archives.length > 0) {
          this.archives.forEach(a => {
            if (a.folder === this.folders[i]) {
              a.folder = 'Main';
              this.is.setObjNoLog(`${this.loc}/${this.admin.admin.org}/archive/documents/${a.ukey}`, a);
            }
          });
          this.onChange('Main');
        }

        this.folders.splice(i, 1);
        this.archive.documentsfolders = this.folders;
        this.ar.onSaveArchiveArchive(this.loc, this.archive.documents);
        // this.is.setObjFS(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg);
      }
    });

  }

  onCalc() {
    this.latt = -1;
    this.lngg = -1;
    this.spinner = true;
    if (this.mapElement) {
      this.mapElement._mapsWrapper.getNativeMap().then(map => {
        const geocoder = new google.maps.Geocoder();
        if (geocoder) {
          geocoder.geocode({'address': this.location}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.latt = results[0].geometry.location.lat();
              this.lngg = results[0].geometry.location.lng();
              this.cd.detectChanges();
            } else {
              console.log('no good');
            }
          });
          this.spinner = false;
        } else {
          this.spinner = false;
        }
      }).catch(err => this.spinner = false);
    } else {
      this.spinner = false;
      console.log('no good');
    }

  }

}
