import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CappSurveyForm, InterfaceService} from '../../services/interface.service';
import * as Survey from 'survey-angular';
// import * as SurveyAnalytics from 'survey-analytics';
import * as XLSX from 'xlsx';
import {AngularFireFunctions} from '@angular/fire/functions';

@Component({
    selector: 'app-survey-results',
    templateUrl: './survey-results.component.html',
    styleUrls: ['./survey-results.component.scss']
})
export class SurveyResultsComponent implements OnInit, AfterViewInit {
    @Input()
    cursurvey: CappSurveyForm;

    data: any;
    spinner = false;

    @ViewChild('surveyAnalyticsContainer', {static: false}) private surveyAnalyticsContainer: ElementRef;


    constructor(private cf: AngularFireFunctions) {
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.spinner = true;
        const json = JSON.parse(this.cursurvey.survey['en'].description);
        const survey = new Survey.SurveyModel(json);

        const p = `Surveys/${this.cursurvey.ukey}/results`;
        const getSurveyResults = this.cf.httpsCallable('getSurveyResults');
        getSurveyResults({path: p}).take(1).subscribe(res => {
            this.data = res;
            const normalizedData = this.data.map(item => {
                survey.getAllQuestions().forEach(q => {
                    if (item[q.name] === undefined) {
                        item[q.name] = '';
                    }
                });
                return item;
            });


            if (document) {
                const doc = document.getElementById('surveyAnalyticsContainer')

/*              todo: need to put this back
                const visPanel = new SurveyAnalytics.VisualizationPanel(
                    doc,
                    survey.getAllQuestions(),
                    normalizedData
                );

                visPanel.render();
*/
                this.spinner = false;
            }
        });

    }

    // this code is for downloading a csv of the selected students
    saveAs(blob, fileName) {
        const url = window.URL.createObjectURL(blob);

        const anchorElem = document.createElement('a');
        anchorElem.href = url;
        anchorElem.download = fileName;

        document.body.appendChild(anchorElem);
        anchorElem.click();

        document.body.removeChild(anchorElem);

        // On Edge, revokeObjectURL should be called only after
        // a.click() has completed, atleast on EdgeHTML 15.15048
        setTimeout(function () {
            window.URL.revokeObjectURL(url);
        }, 1000);
    }


    onDownload() {
        const fileName2 = `${this.cursurvey.name['en'].description}`;
        this.exportAsExcelFile(this.data, fileName2);
    }

    exportAsExcelFile(json: any[], excelFileName: string): void {
        const arr = Object.keys(json)
        arr.forEach(a => {
            const barr = Object.keys(json[a])
            barr.forEach(b => {
                if (typeof json[a][b] === 'object') {
                    json[a][b] = json[a][b].join(',');
                }
            });
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        XLSX.writeFile(workbook, this.toExportFileName(excelFileName));

    }

    toExportFileName(excelFileName: string): string {
        return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
    }


}
