import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-flip-box',
  templateUrl: './flip-box.component.html',
  styleUrls: ['./flip-box.component.scss']
})
export class FlipBoxComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  start: number;

  @Input()
  interval = 1000;

  @Input()
  countdown = true;

  @Input()
  offset = 0;

  hours: number;
  minutes: number;
  seconds: number;
  days: number;

  clock;
  count;

  DaysTensPlace = 0;
  DaysOnesPlace = 0;
  HoursTensPlace = 0;
  HoursOnesPlace = 0;
  MinutesTensPlace = 0;
  MinutesOnesPlace = 0;
  SecondsTensPlace = 0;
  SecondsOnesPlace = 0;

  starttime: number;

  constructor(public admin: AdminService) {
  }

  ngOnInit() {
    this.starttime = this.admin.getIntTime();
    if (this.countdown) {
      this.count = (this.starttime - this.start) / 1000;
    } else {
      this.updateTime();
    }
    this.onSetClock();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.offset) {
      this.offset = changes.offset.currentValue;
      this.updateTime()
    }
  }

  onSetClock() {
    if (!this.clock) {
      this.clock = setInterval(() => {
        if (this.countdown) {

          this.days = Math.floor(this.count / (3600 * 24));
          this.DaysOnesPlace = Math.floor((this.days / 1) % 10);

          this.DaysTensPlace = Math.floor((this.days / 10) % 10);

          const hs = Math.trunc(this.count % (3600 * 24));
          this.hours = Math.floor(hs / 3600);
          this.HoursOnesPlace = Math.floor((this.hours / 1) % 10);

          this.HoursTensPlace = Math.floor((this.hours / 10) % 10);

          const ts = Math.trunc(this.count % 3600);

          this.minutes = Math.floor(ts / 60);
          this.MinutesOnesPlace = Math.floor((this.minutes / 1) % 10);
          this.MinutesTensPlace = Math.floor((this.minutes / 10) % 10);

          this.seconds = ts % 60;
          this.SecondsOnesPlace = Math.floor((this.seconds / 1) % 10);
          this.SecondsTensPlace = Math.floor((this.seconds / 10) % 10);

          this.count = this.count + this.interval / 1000;
        } else {
          this.updateTime();
        }
      }, this.interval);
    }
  }

  updateTime() {
    const date = new Date(this.admin.getIntTime() + this.offset * 60000);
    const hs = date.getHours();
    this.HoursOnesPlace = Math.floor((hs / 1) % 10);
    this.HoursTensPlace = Math.floor((hs / 10) % 10);

    const ms = date.getMinutes();
    this.MinutesOnesPlace = Math.floor((ms / 1) % 10);
    this.MinutesTensPlace = Math.floor((ms / 10) % 10);
  }

  ngOnDestroy(): void {
    clearInterval(this.clock);
  }
}


/*
          if (this.remmin > 0) {
            if ((this.lastrem + this.remmin * 60) < this.rostate.count) {
              const ref = this.bottomSheet.open(RemindMeComponent,
                {data: {min: this.remmin, primarycolor: this.rostate.primarycolor}});

              ref.afterDismissed().take(1).subscribe(res => {
                this.lastrem = this.rostate.count;
                if (res && res.res === 'stop') {
                  this.onStop();
                }
              });
            }
          }


``
 */

