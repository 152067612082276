import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {InterfaceService} from '../../services/interface.service';

@Component({
  selector: 'app-pick-template',
  templateUrl: './pick-template.component.html',
  styleUrls: ['./pick-template.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class PickTemplateComponent implements OnInit {
    title = 'Guide';

    constructor(public is: InterfaceService,
                public dialogRef: MatDialogRef<PickTemplateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.title) {
            this.title = data.title;
        }
    }

    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close(-1);
    }

    onNoTemplate() {
        this.dialogRef.close(0);
    }

    onTemplate() {
        this.dialogRef.close(1);
    }

}