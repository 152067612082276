import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalConfig, LangStringBlock, Links, LocationData, OrgEdition} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {GeofireService} from '../services/geofire.service';
import {Languages} from '../services/constants';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss']
})
export class GlobalComponent implements OnInit {
  @Input()
  config: GlobalConfig;

  @Input()
  locations: LocationData[];

  @Input()
  editions: OrgEdition[];

  @Input()
  links: Links;

  @Output()
  save = new EventEmitter();

  curlang: string;
  globaldir = false;

  constructor(public admin: AdminService) {
    this.curlang = this.admin.language;

  }

  ngOnInit() {
  }

  onChangeLoc(event) {
    const x = this.locations.findIndex(l => l.ukey === event.value);
    if (x > -1) {
      this.config.location = this.locations[x].city + ' - ' + this.locations[x].state + ', ' + this.locations[x].country;
      if (this.locations[x].geo && this.locations[x].geo.lat !== -1) {
        this.config.lat = this.locations[x].geo.lat;
        this.config.long = this.locations[x].geo.long;
      } else {
        this.config.lat = -1;
        this.config.long = -1;
      }

    } else {
      this.config.location = '';
    }

  }

  onLangChange(i) {
    this.admin.options.language = Languages[i].abbr;
    this.curlang = Languages[i].abbr;
  }

  onTranslate() {
    this.admin.onTransEl(this.config.desc, this.curlang);
  }

  onSave() {
    this.save.emit();
  }

}
