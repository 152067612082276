import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup} from '@angular/forms';
import {InterfaceService} from '../../services/interface.service';
import {FirebaseService} from '../../services/firebase.service';
import {AdminService, Article} from '../../services/admin.service';

@Component({
    selector: 'app-addedit-article',
    templateUrl: './addedit-article.component.html',
    styleUrls: ['./addedit-article.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class AddeditArticleComponent implements OnInit {
    categories;

    form = new FormGroup({
        date: new FormControl(''),
        name: new FormControl(''),
        category: new FormControl(''),
        publication: new FormControl(''),
        author: new FormControl(''),
        headline: new FormControl(''),
        link: new FormControl(''),
        summary: new FormControl(''),
    });

    get category() {
        return this.form.get('category');
    }

    get summary() {
        return this.form.get('summary');
    }

    get headline() {
        return this.form.get('headline');
    }

    get author() {
        return this.form.get('author');
    }

    get publication() {
        return this.form.get('publication');
    }

    get date() {
        return this.form.get('date');
    }

    get name() {
        return this.form.get('name');
    }

    get link() {
        return this.form.get('link');
    }

    constructor(private is: InterfaceService,
                private fs: FirebaseService,
                private admin: AdminService,
                public dialogRef: MatDialogRef<AddeditArticleComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.is.getList(`ArticleHelpers/Categories`).take(1).subscribe(c => {
            this.categories = c.sort();
        });
    }

    onCancel() {
        this.dialogRef.close();
    }

    onSave() {
        const curarticle = new Article;
        curarticle.Id = this.fs.titleIXdb.createPushId();
        curarticle.Date = new Date(this.date.value).toISOString();
        curarticle.Name = this.admin.admin.org;
        curarticle.State = '';
        curarticle.CollegeNewspaper = '';
        curarticle.Status = '';
        curarticle.DateAdded = new Date().toISOString();
        curarticle.Current = 0;
        curarticle.Use = 0;
        curarticle.Category = this.category.value;
        curarticle.Population = '';
        curarticle.Geography = '';
        curarticle.MustRead = '';
        curarticle.Threads = '';
        curarticle.Publication = this.publication.value;
        curarticle.Author = this.author.value;
        curarticle.Lawsuit = '';
        curarticle.Headline = this.headline.value;
        curarticle.Link = this.link.value;
        curarticle.Summary = this.summary.value;
        curarticle.RaceGender = '';
        curarticle.ReferenceTitle = '';
        curarticle.ReferenceLink = '';
        curarticle.CaseTitle = '';
        curarticle.Victim = '';
        curarticle.Accused = '';
        curarticle.Role = '';
        curarticle.Arrest = '';
        curarticle.Notes = '';
        curarticle.LawyerforAccused = '';
        curarticle.LawyerforVictim = '';
        curarticle.Case = '';
        curarticle.CaseStatus = '';
        this.is.setObj(`Articles/${curarticle.Id}`, curarticle, 'Article', 'Added Article');

        this.dialogRef.close();
    }
}
