import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CAMetaMoly, CAMolyData, CAMolyInForm, CAMolyValue} from '../../services/ca';
import {AdminService} from '../../services/admin.service';
import {InterfaceService} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {RoehttpService} from '../../services/roehttp.service';

@Component({
  selector: 'app-ca-phone',
  templateUrl: './ca-phone.component.html',
  styleUrls: ['./ca-phone.component.scss']
})
export class CaPhoneComponent implements OnInit {
  @Input()
  path: string;

  @Input()
  formusesukey: string;

  @Input()
  moly: CAMolyInForm;

  @Input()
  primarycolor = '#0000FF';

  @Input()
  secondarycolor = '#ffffff';

  @Output()
  save = new EventEmitter<string>();

  data: CAMolyData;
  phonedesc: CAMetaMoly;
  phonemoly: CAMetaMoly;
  phonedescdone = false;
  phonedone = false;

  description: string;
  phone: string;
  olddescription: string;
  oldphone: string;
  code: string;

  firsttime = true;
  editphone = false;
  editdesc = false;
  phoneconfirm = false;

  phonedata: CAMolyData;
  phonedescdata: CAMolyData;
  ukey: string;

  scope = 'global';
  curlang = 'en';

  constructor(public admin: AdminService,
              private is: InterfaceService,
              public db: AngularFireDatabase,
              private http: RoehttpService) {
  }

  ngOnInit() {
    const molys = Object.values(this.moly.moly.molys);
    this.phonedesc = molys.find(m => m.jsonkey === 'phonedescription');
    this.phonemoly = molys.find(m => m.jsonkey === 'phonenumber');

    // todo: first make an attempt to get the data and if not find it, then create it
    this.is.getObject(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`)
      .take(1)
      .subscribe(val => {
        if (!val) {
          console.log('val val')
          this.setUp();
        } else {
          this.ukey = val.ukey;
          let pth;
          if (val.scope === 'global') {
            pth = `${this.path}/data/phones/${this.ukey}`;
          } else if (val.scope === 'local') {
            pth = `${this.path}/formvals/${this.formusesukey}/phones/${this.ukey}`
          }
          this.scope = val.scope;
          this.is.getObject(pth)
            .take(1)
            .subscribe(phone => {
              this.firsttime = false;
              // this.phonedesc = phone.phonedescription;
              this.phonedescdata = new CAMolyData();
              this.phonedescdata.name = this.phonedesc.name;
              this.phonedescdata.molyukey = this.phonedesc.ukey;
              this.phonedescdata.value = phone.phonedescription.value;
              this.description = this.phonedescdata.value[this.phonedescdata.value.length - 1].data;
              this.olddescription = this.description;
              this.editdesc = false;
              this.phonedescdone = true;

              // this.phonedata = phone.phonenumber;
              this.phonedata = new CAMolyData();
              this.phonedata.name = this.phonemoly.name;
              this.phonedata.molyukey = this.phonemoly.ukey;
              this.phonedata.value = phone.phonenumber.value
              this.phone =  this.phonedata.value[ this.phonedata.value.length - 1].data;
              this.oldphone = this.phone;
              this.editphone = false;
              this.phonedone = true;
            });
        }
      });
  }

  onInbox(str) {
    this.scope = str;
    this.firsttime = true
  }

  setUp() {
    this.ukey = this.db.createPushId();

    this.phonedescdata = new CAMolyData();
    this.phonedescdata.name = this.phonedesc.name;
    this.phonedescdata.molyukey = this.phonedesc.ukey;
    this.description = '';
    this.olddescription = this.description;

    if (!this.phonedescdata || !this.phonedescdata.value || this.phonedescdata.value.length === 0) {
      this.editdesc = true;
    }

    this.phonedata = new CAMolyData();
    this.phonedata.name = this.phonemoly.name;
    this.phonedata.molyukey = this.phonemoly.ukey;
    this.phone = '';
    this.oldphone = this.phone;

    if (!this.phonedata || !this.phonedata.value || this.phonedata.value.length === 0) {
      this.editphone = true;
    }
  }


  validatephone(phone): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(phone).toLowerCase());
  }

  onSave() {
    // save description
    let loc;
    if (this.scope === 'global') {
      loc = 'data';
    } else {
      loc = `formvals/${this.formusesukey}`;
    }
    this.is.setObjNoLog(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`, {ukey: this.ukey, scope: this.scope});

    const p = `${this.path}/${loc}/phones/${this.ukey}`;
    console.log('path', p);
    if (this.firsttime) {
      this.is.setObjNoLog(`${p}/phonedescription`, this.phonedescdata);
      this.is.setObjNoLog(`${p}/phonenumber`, this.phonedata);
      this.firsttime = false;
    } else {
      console.log('path', `${p}/phonedescription/value/${this.phonedescdata.value.length - 1}`);
      this.is.setObjNoLog(`${p}/phonedescription/value/${this.phonedescdata.value.length - 1}`,
        this.phonedescdata.value[this.phonedescdata.value.length - 1]);
      this.is.setObjNoLog(`${p}/phonenumber/value/${this.phonedata.value.length - 1}`,
        this.phonedata.value[this.phonedata.value.length - 1]);

    }

    // emit success
    // todo: not sure if have to emit the ukey
    this.save.emit(this.ukey);
  }

  onClickDesc() {
    this.editdesc = true;
  }

  onOptionDesc(event) {
    console.log('option', event)
    console.log('description', this.description)
    this.editdesc = false;
    this.descriptionSave();
  }

  onBlurDesc(event) {
    console.log('option')
    this.editdesc = false;
    this.descriptionSave();
  }

  onKeyUpDesc(event) {
    // todo: check if Done is triggered
    if ((event.code === 'Enter' || event.key === 'Enter' || event.code === 'Done' || event.key === 'Done') &&
      (this.description.length > 3)) {
      this.editdesc = false;
      this.descriptionSave();
    }
  }

  descriptionSave() {
    this.phonedescdone = true;
    if (this.olddescription !== this.description) {

      if (!this.phonedescdata.value) {
        this.phonedescdata.value = [];
      }

      const v = new CAMolyValue();
      v.data = this.description;

      // todo: change to international time? save the timezone?
      v.date = new Date().getTime();
      console.log('value', this.phonedescdata.value)
      this.phonedescdata.value.push(v);
      this.olddescription = this.description;
      this.editdesc = false;

      if (this.phonedone && this.phonedescdone) {
        console.log('saving');

        this.onSave();
      }
    }
  }

  phoneSave() {
    if (this.phone !== this.oldphone) {
      this.phone = this.phone.toLowerCase();

      this.admin.isValidPhone(this.phone);
      if (this.admin.phoneNumber && this.admin.phoneNumber.isValid()) {
        this.phone = this.admin.phoneNumber.number;
        this.oldphone = this.admin.phoneNumber.number;
        this.code = this.admin.randomString(false, 3);
        console.log('phone code', this.code);
        /* todo: put back
        this.hs.postRFCValidPhone(this.admin.phoneNumber.number, this.code, this.yourname, 'Inst')
            .then(res => {
              if (res.adm) {
                this.adm = res.adm;
              }
            });
        */

        this.phoneconfirm = true;
      }

    } else {
      this.editphone = false;
    }
  }

  onBlurphone(event) {
    this.editphone = false;
    this.phoneSave();
  }

  onKeyUp(event) {
    if (event.code === 'Enter' || event.key === 'Enter') {
      // todo: do an error check
      this.phoneSave();
    }
  }

  onContinuephone() {
    if (!this.phonedata.value) {
      this.phonedata.value = [];
    }

    const v = new CAMolyValue();
    v.data = this.phone;

    // todo: change to international time? save the timezone?
    v.date = new Date().getTime();
    this.phonedata.value.push(v);

    this.phonedone = true;
    this.phoneconfirm = false;
    this.editphone = false;

    // need to save it
    if (this.phonedone && this.phonedescdone) {
      this.onSave();
    }
  }


}
