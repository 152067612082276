import {Component, OnInit} from '@angular/core';
import {CappTeamMsg, InterfaceService, Key, LangStringBlock, PlaceObj, Topix} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {AdminService} from '../../services/admin.service';
import {Classifications, Icons, Languages} from '../../services/constants';
import * as _ from 'lodash';
import {RoehttpService} from '../../services/roehttp.service';
import {DomSanitizer} from '@angular/platform-browser';
import * as geohash from 'ngeohash';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';


export class Element {
  code: string;
  cat: string; // catogory: eg. financial, medical, profile,
  name: string;
  // todo: data structure for rules like type, range, etc
  // might be useful to see how SurveyJS does it.
}

@Component({
  selector: 'app-pub-topics',
  templateUrl: './pub-topics.component.html',
  styleUrls: ['./pub-topics.component.scss']
})
export class PubTopicsComponent implements OnInit {
  pubtopics: Topix[];
  curtopic: Topix;
  curlang: string;
  trans = false;
  languages = Languages;
  classifications = Classifications;
  pos = -1;
  primarycolor = '#99add0';
  secondarycolor = '#000000';
  icons = Icons;
  elements: Element[];
  curel: Element;
  detailsel = false;
  elpos = -1;

  aerial: any;
  aerialviews: any[];
  curaerial: any;


  curcams;
  clippedcam;
  junkcams;
  rawcams;
  step1cams;
  nextkey: string;

  categories = ['financial', 'medical', 'profile'];

  details = false;
  notedetails = false;
  curnote: CappTeamMsg;
  notes: CappTeamMsg[];

  after;
  cnt = 0;
  before;

  clipped: PlaceObj;
  location: string;
  ukey: string;
  weburl: string;
  googleurl: string;
  wikiurl: string;
  briturl: string;
  kingdomvar: string;
  phylumvar: string;
  classvar: string;
  kingdomarr: string[];
  phylumarr: string[];
  classarr: string[];
  src;
  channelsrc;
  channelstr: string;

  camcats = ['Airport',
    'Attraction',
    'Bay',
    'Beach',
    'Boardwalk',
    'Bridge',
    'Campground',
    'City View',
    'Coastline',
    'Countryside',
    'Harbour',
    'Holy Service',
    'Infrastructure',
    'Lake',
    'Mountain',
    'Multiple',
    'Nature',
    'Ocean',
    'Other',
    'Panorama',
    'Park',
    'Pier',
    'Public Space',
    'Railroad',
    'Resort',
    'Restaurant',
    'River',
    'Ski Area',
    'Skyline',
    'Street View',
    'Traffic Cam',
    'Vista',
    'Waterfront',
    'Weather Cam',
    'Wildlife'];

  arr = [
    {feed: 'https://ridewithgps.com/feed', ukey: 'ridewithgps', domain: 'https://ridewithgps.com/'},
    {feed: 'https://findloveandtravel.com/feed', ukey: 'findloveandtravel', domain: 'https://findloveandtravel.com/'},
    {feed: 'https://fotoeins.com/feed', ukey: 'fotoeins', domain: 'https://fotoeins.com/'},
    {feed: 'https://getlost.blog/feed', ukey: 'getlost', domain: 'https://getlost.blog/'},
    {feed: 'https://global-gallivanting.com/feed', ukey: 'global-gallivanting', domain: 'https://global-gallivanting.com/'},
    {feed: 'https://gosouthwestengland.co.uk/feed', ukey: 'gosouthwestengland', domain: 'https://gosouthwestengland.co.uk/'},
    {feed: 'https://herlifeadventures.com/feed', ukey: 'herlifeadventures', domain: 'https://herlifeadventures.com/'},
    {feed: 'https://heyeastcoastusa.com/feed', ukey: 'heyeastcoastusa', domain: 'https://heyeastcoastusa.com/'},
    {feed: 'https://hikebiketravel.com/feed', ukey: 'hikebiketravel', domain: 'https://hikebiketravel.com/'},
    {feed: 'https://hoponworld.com/feed', ukey: 'hoponworld', domain: 'https://hoponworld.com/'},
    {feed: 'https://jillonjourney.com/feed', ukey: 'jillonjourney', domain: 'https://jillonjourney.com/'},
    {feed: 'https://journeyswithjenn.com/feed', ukey: 'journeyswithjenn', domain: 'https://journeyswithjenn.com/'},
    {feed: 'https://justtravellingthrough.com/feed', ukey: 'justtravellingthrough', domain: 'https://justtravellingthrough.com/'},
    {feed: 'https://karlaroundtheworld.com/feed', ukey: 'karlaroundtheworld', domain: 'https://karlaroundtheworld.com/'},
    {feed: 'https://kidsareatrip.com/feed', ukey: 'kidsareatrip', domain: 'https://kidsareatrip.com/'},
    {feed: 'https://lafamilytravel.com/feed', ukey: 'lafamilytravel', domain: 'https://lafamilytravel.com/'},
    {feed: 'https://greyglobetrotters.com/feed', ukey: 'greyglobetrotters', domain: 'https://greyglobetrotters.com/'},
    {feed: 'https://femalesolotrek.com/feed', ukey: 'femalesolotrek', domain: 'https://femalesolotrek.com/'},
    {feed: 'https://letsgolouisville.com/feed', ukey: 'letsgolouisville', domain: 'https://letsgolouisville.com/'},
    {feed: 'https://letsjetkids.com/feed', ukey: 'letsjetkids', domain: 'https://letsjetkids.com/'},
    {
      feed: 'https://lettersfromatravelinggirl.com/feed',
      ukey: 'lettersfromatravelinggirl',
      domain: 'https://lettersfromatravelinggirl.com/'
    },
    {feed: 'https://likewhereyouregoing.com/feed', ukey: 'likewhereyouregoing', domain: 'https://likewhereyouregoing.com/'},
    {feed: 'https://luxurycruiseconnections.com/feed', ukey: 'luxurycruiseconnections', domain: 'https://luxurycruiseconnections.com/'},
    {feed: 'https://mainlymiles.com/feed', ukey: 'mainlymiles', domain: 'https://mainlymiles.com/'},
    {feed: 'https://makingtheworldsmall.com/feed', ukey: 'makingtheworldsmall', domain: 'https://makingtheworldsmall.com/'},
    {feed: 'https://manymoremaps.com/feed', ukey: 'manymoremaps', domain: 'https://manymoremaps.com/'},
    {feed: 'https://meetmeindepartures.com/feed', ukey: 'meetmeindepartures', domain: 'https://meetmeindepartures.com/'},
    {feed: 'https://misadventureswithandi.com/feed', ukey: 'misadventureswithandi', domain: 'https://misadventureswithandi.com/'},
    {feed: 'https://monkeystale.wordpress.com/feed', ukey: 'monkeystale', domain: 'https://monkeystale.wordpress.com/'},
    {feed: 'https://mylittlenomads.com/feed', ukey: 'mylittlenomads', domain: 'https://mylittlenomads.com/'},
    {feed: 'https://mypathintheworld.com/feed', ukey: 'mypathintheworld', domain: 'https://mypathintheworld.com/'},
    {feed: 'https://nextbiteoflife.com/feed', ukey: 'nextbiteoflife', domain: 'https://nextbiteoflife.com/'},
    {feed: 'https://nomadicated.com/feed', ukey: 'nomadicated', domain: 'https://nomadicated.com/'},
    {feed: 'https://ottawathingstodo.com/feed', ukey: 'ottawathingstodo', domain: 'https://ottawathingstodo.com/'},
    {feed: 'https://outdooradventuresampler.com/feed', ukey: 'outdooradventuresampler', domain: 'https://outdooradventuresampler.com/'},
    {feed: 'https://passionforhospitality.net/feed', ukey: 'passionforhospitality', domain: 'https://passionforhospitality.net/'},
    {feed: 'https://passportexplorers.com/feed', ukey: 'passportexplorers', domain: 'https://passportexplorers.com/'},
    {feed: 'https://passportsandadventures.com/feed', ukey: 'passportsandadventures', domain: 'https://passportsandadventures.com/'},
    {feed: 'https://pastlanetravels.com/feed', ukey: 'pastlanetravels', domain: 'https://pastlanetravels.com/'},
    {feed: 'https://picturesandwordsblog.com/feed', ukey: 'picturesandwordsblog', domain: 'https://picturesandwordsblog.com/'},
    {feed: 'https://pixiedustandpassports.com/feed', ukey: 'pixiedustandpassports', domain: 'https://pixiedustandpassports.com/'},
    {feed: 'https://placestovisitinengland.com/feed', ukey: 'placestovisitinengland', domain: 'https://placestovisitinengland.com/'},
    {feed: 'https://roamnc.com/feed', ukey: 'roamnc', domain: 'https://roamnc.com/'},
    {feed: 'https://placestovisitinengland.com/feed', ukey: 'placestovisitinengland', domain: 'https://placestovisitinengland.com/'},
    {feed: 'https://runthealps.com/feed', ukey: 'runthealps', domain: 'https://runthealps.com/'},
    {feed: 'https://samseesworld.com/feed', ukey: 'samseesworld', domain: 'https://samseesworld.com/'},
    {feed: 'https://seat31b.com/feed', ukey: 'seat31b', domain: 'https://seat31b.com/'},
    {feed: 'https://staugustineexplored.com/feed', ukey: 'staugustineexplored', domain: 'https://staugustineexplored.com/'},
    {feed: 'https://surfergirlonthemove.com/feed', ukey: 'surfergirlonthemove', domain: 'https://surfergirlonthemove.com/'},
    {feed: 'https://smudgedpostcard.com/feed', ukey: 'smudgedpostcard', domain: 'https://smudgedpostcard.com/'},
    {feed: 'https://thatadventurer.co.uk/feed', ukey: 'thatadventurer', domain: 'https://thatadventurer.co.uk/'},
    {feed: 'https://thebambootraveler.com/feed', ukey: 'thebambootraveler', domain: 'https://thebambootraveler.com/'},
    {feed: 'https://thedailyadventuresofme.com/feed', ukey: 'thedailyadventuresofme', domain: 'https://thedailyadventuresofme.com/'},
    {feed: 'https://thehappiestblogonearth.com/feed', ukey: 'thehappiestblogonearth', domain: 'https://thehappiestblogonearth.com/'},
    {feed: 'https://theroad-islife.com/feed', ukey: 'theroad-islife', domain: 'https://theroad-islife.com/'},
    {feed: 'https://theturkeytraveler.com/feed', ukey: 'theturkeytraveler', domain: 'https://theturkeytraveler.com/'},
    {feed: 'https://thevanescape.com/feed', ukey: 'thevanescape', domain: 'https://thevanescape.com/'},
    {
      feed: 'https://thiscrazyadventurecalledlife.com/feed',
      ukey: 'thiscrazyadventurecalledlife',
      domain: 'https://thiscrazyadventurecalledlife.com/'
    },
    {feed: 'https://thisrareearth.com/feed', ukey: 'thisrareearth', domain: 'https://thisrareearth.com/'},
    {feed: 'https://ToAndFroFam.com/feed', ukey: 'ToAndFroFam', domain: 'https://ToAndFroFam.com/'},
    {feed: 'https://trainsplanesandtuktuks.com/feed', ukey: 'trainsplanesandtuktuks', domain: 'https://trainsplanesandtuktuks.com/'},
    {
      feed: 'https://travelcliquetravelwithme.wordpress.com/feed',
      ukey: 'travelcliquetravelwithme',
      domain: 'https://travelcliquetravelwithme.wordpress.com/'
    },
    {feed: 'https://travelswiththecrew.com/feed', ukey: 'travelswiththecrew', domain: 'https://travelswiththecrew.com/'},
    {feed: 'https://traversewithtaylor.com/feed', ukey: 'traversewithtaylor', domain: 'https://traversewithtaylor.com/'},
    {feed: 'https://tricksandtrips.com/feed', ukey: 'tricksandtrips', domain: 'https://tricksandtrips.com/'},
    {feed: 'https://tripsonabbeyroad.com/feed', ukey: 'tripsonabbeyroad', domain: 'https://tripsonabbeyroad.com/'},
    {feed: 'https://tucsonfoodie.com/feed', ukey: 'tucsonfoodie', domain: 'https://tucsonfoodie.com/'},
    {feed: 'https://ummigoeswhere.com/feed', ukey: 'ummigoeswhere', domain: 'https://ummigoeswhere.com/'},
    {feed: 'https://vacationmaybe.com/feed', ukey: 'vacationmaybe', domain: 'https://vacationmaybe.com/'},
    {feed: 'https://visitsouthernspain.com/feed', ukey: 'visitsouthernspain', domain: 'https://visitsouthernspain.com/'},
    {feed: 'https://wearwhenwhatwhy.com/feed', ukey: 'wearwhenwhatwhy', domain: 'https://wearwhenwhatwhy.com/'},
    {feed: 'https://wild-hearted.com/feed', ukey: 'wild-hearted', domain: 'https://wild-hearted.com/'},
    {feed: 'https://adventurouskate.com/feed', ukey: 'adventurouskate', domain: 'https://adventurouskate.com/'},
    {feed: 'https://bea-adventurous.com/feed', ukey: 'bea-adventurous', domain: 'https://bea-adventurous.com/'},
    {feed: 'https://browneyedflowerchild.com/feed', ukey: 'browneyedflowerchild', domain: 'https://browneyedflowerchild.com/'},
    {feed: 'https://catisoutoftheoffice.com/feed', ukey: 'catisoutoftheoffice', domain: 'https://catisoutoftheoffice.com/'},
    {feed: 'https://cheftravelguide.com/feed', ukey: 'cheftravelguide', domain: 'https://cheftravelguide.com/'},
    {feed: 'https://chriscruises.com//feed', ukey: 'chriscruises', domain: 'https://chriscruises.com//'},
    {feed: 'https://clevelandtraveler.com/feed', ukey: 'clevelandtraveler', domain: 'https://clevelandtraveler.com/'},
    {feed: 'https://cruise-addicts.com//feed', ukey: 'cruise-addicts', domain: 'https://cruise-addicts.com//'},
    {feed: 'https://cruise-experience.com//feed', ukey: 'cruise-experience', domain: 'https://cruise-experience.com//'},
    {feed: 'https://cruiseblondes.com//feed', ukey: 'cruiseblondes', domain: 'https://cruiseblondes.com//'},
    {feed: 'https://cruisecotterill.com//feed', ukey: 'cruisecotterill', domain: 'https://cruisecotterill.com//'},
    {feed: 'https://cruiselifestyle.co.uk//feed', ukey: 'cruiselifestyle', domain: 'https://cruiselifestyle.co.uk//'},
    {feed: 'https://cruiselowdown.com//feed', ukey: 'cruiselowdown', domain: 'https://cruiselowdown.com//'},
    {feed: 'https://cruisemaven.com//feed', ukey: 'cruisemaven', domain: 'https://cruisemaven.com//'},
    {feed: 'https://cruisemiss.com//feed', ukey: 'cruisemiss', domain: 'https://cruisemiss.com//'},
    {feed: 'https://cruisemonkeys.com//feed', ukey: 'cruisemonkeys', domain: 'https://cruisemonkeys.com//'},
    {feed: 'https://cruiseportadvisor.com//feed', ukey: 'cruiseportadvisor', domain: 'https://cruiseportadvisor.com//'},
    {feed: 'https://cruiseshipprofiles.com//feed', ukey: 'cruiseshipprofiles', domain: 'https://cruiseshipprofiles.com//'},
    {feed: 'https://cruisethewaves.com//feed', ukey: 'cruisethewaves', domain: 'https://cruisethewaves.com//'},
    {feed: 'https://cruisetipstv.com//feed', ukey: 'cruisetipstv', domain: 'https://cruisetipstv.com//'},
    {feed: 'https://cruisewith.co.uk//feed', ukey: 'cruisewith', domain: 'https://cruisewith.co.uk//'},
    {feed: 'https://cruisewithamber.co.uk//feed', ukey: 'cruisewithamber', domain: 'https://cruisewithamber.co.uk//'},
    {feed: 'https://cruisingkids.co.uk/feed', ukey: 'cruisingkids', domain: 'https://cruisingkids.co.uk/'},
    {feed: 'https://cruisingwheelchair.com/feed', ukey: 'cruisingwheelchair', domain: 'https://cruisingwheelchair.com/'},
    {feed: 'https://cruzely.com/feed', ukey: 'cruzely', domain: 'https://cruzely.com/'},
    {feed: 'https://csginger.com/feed', ukey: 'csginger', domain: 'https://csginger.com/'},
    {feed: 'https://dabblinginjetlag.com/feed', ukey: 'dabblinginjetlag', domain: 'https://dabblinginjetlag.com/'},
    {feed: 'https://disneycruisemomblog.com//feed', ukey: 'disneycruisemomblog', domain: 'https://disneycruisemomblog.com//'},
    {feed: 'https://dogtravelbuff.com/feed', ukey: 'dogtravelbuff', domain: 'https://dogtravelbuff.com/'},
    {feed: 'https://emmacruises.com/feed', ukey: 'emmacruises', domain: 'https://emmacruises.com/'},
    {feed: 'https://exploretheriviera.com/feed', ukey: 'exploretheriviera', domain: 'https://exploretheriviera.com/'},
    {feed: 'https://exploringthegulf.com/feed', ukey: 'exploringthegulf', domain: 'https://exploringthegulf.com/'},
    {feed: 'https://familycruisecompanion.com//feed', ukey: 'familycruisecompanion', domain: 'https://familycruisecompanion.com//'},
    {feed: 'https://floridatrippers.com/feed', ukey: 'floridatrippers', domain: 'https://floridatrippers.com/'},
    {feed: 'https://footstepsontheglobe.com/feed', ukey: 'footstepsontheglobe', domain: 'https://footstepsontheglobe.com/'},
    {feed: 'https://fortheloveofwanderlust.com/feed', ukey: 'fortheloveofwanderlust', domain: 'https://fortheloveofwanderlust.com/'},
    {feed: 'https://fouraroundtheworld.com/feed', ukey: 'fouraroundtheworld', domain: 'https://fouraroundtheworld.com/'},
    {feed: 'https://guideyourtravel.com/feed', ukey: 'guideyourtravel', domain: 'https://guideyourtravel.com/'},
    {feed: 'https://iheartitaly.co/feed', ukey: 'iheartitaly', domain: 'https://iheartitaly.co/'},
    {feed: 'https://imjesstraveling.com/feed', ukey: 'imjesstraveling', domain: 'https://imjesstraveling.com/'},
    {feed: 'https://islandandalpine.com/feed', ukey: 'islandandalpine', domain: 'https://islandandalpine.com/'},
    {feed: 'https://kassidysjourney.com/feed', ukey: 'kassidysjourney', domain: 'https://kassidysjourney.com/'},
    {feed: 'https://lajollamom.com/feed', ukey: 'lajollamom', domain: 'https://lajollamom.com/'},
    {feed: 'https://LettersToBarbara.com/feed', ukey: 'LettersToBarbara', domain: 'https://LettersToBarbara.com/'},
    {feed: 'https://lifepart2andbeyond.com/feed', ukey: 'lifepart2andbeyond', domain: 'https://lifepart2andbeyond.com/'},
    {feed: 'https://make-more-adventures.com/feed', ukey: 'make-more-adventures', domain: 'https://make-more-adventures.com/'},
    {feed: 'https://McCoolTravel.com/feed', ukey: 'McCoolTravel', domain: 'https://McCoolTravel.com/'},
    {feed: 'https://MommyTravels.net/feed', ukey: 'MommyTravels', domain: 'https://MommyTravels.net/'},
    {feed: 'https://momsatsea.com//feed', ukey: 'momsatsea', domain: 'https://momsatsea.com//'},
    {
      feed: 'https://mylittleworldoftravelling.com/feed',
      ukey: 'mylittleworldoftravelling',
      domain: 'https://mylittleworldoftravelling.com/'
    },
    {feed: 'https://newenglandwithlove.com/feed', ukey: 'newenglandwithlove', domain: 'https://newenglandwithlove.com/'},
    {feed: 'https://nextstopbelgium.com/feed', ukey: 'nextstopbelgium', domain: 'https://nextstopbelgium.com/'},
    {feed: 'https://nightlifepartyguide.com/feed', ukey: 'nightlifepartyguide', domain: 'https://nightlifepartyguide.com/'},
    {feed: 'https://nocruisecontrol.com//feed', ukey: 'nocruisecontrol', domain: 'https://nocruisecontrol.com//'},
    {feed: 'https://notaboutthemiles.com/feed', ukey: 'notaboutthemiles', domain: 'https://notaboutthemiles.com/'},
    {
      feed: 'https://odditiesandcuriositiestravel.com/feed',
      ukey: 'odditiesandcuriositiestravel',
      domain: 'https://odditiesandcuriositiestravel.com/'
    },
    {
      feed: 'https://oneforthemoneytwofortheroad.com/feed',
      ukey: 'oneforthemoneytwofortheroad',
      domain: 'https://oneforthemoneytwofortheroad.com/'
    },
    {feed: 'https://ouroffbeatlife.com/feed', ukey: 'ouroffbeatlife', domain: 'https://ouroffbeatlife.com/'},
    {feed: 'https://parisunlocked.com/feed', ukey: 'parisunlocked', domain: 'https://parisunlocked.com/'},
    {feed: 'https://421adventure.com/feed', ukey: '421adventure', domain: 'https://421adventure.com/'},
    {feed: 'https://destinationlesstravel.com/feed', ukey: 'destinationlesstravel', domain: 'https://destinationlesstravel.com/'},
    {feed: 'https://fatsterbike.com/feed', ukey: 'fatsterbike', domain: 'https://fatsterbike.com/'},
    {feed: 'https://guidetobritain.com/feed', ukey: 'guidetobritain', domain: 'https://guidetobritain.com/'},
    {feed: 'https://irregularadventures.com/feed', ukey: 'irregularadventures', domain: 'https://irregularadventures.com/'},
    {feed: 'https://milestalk.com/feed', ukey: 'milestalk', domain: 'https://milestalk.com/'},
    {feed: 'https://wobblyride.com/feed', ukey: 'wobblyride', domain: 'https://wobblyride.com/'},
    {feed: 'https://www.her-odyssey.org/feed', ukey: 'her-odyssey', domain: 'https://www.her-odyssey.org/'},
    {feed: 'https://www.wandercyclist.com/feed', ukey: 'wandercyclist', domain: 'https://www.wandercyclist.com/'},
    {feed: 'https://parkrangerjohn.com/feed', ukey: 'parkrangerjohn', domain: 'https://parkrangerjohn.com/'},
    {
      feed: 'https://paulandcarolelovetotravel.com//feed',
      ukey: 'paulandcarolelovetotravel',
      domain: 'https://paulandcarolelovetotravel.com//'
    },
    {feed: 'https://paulintheparks.com/feed', ukey: 'paulintheparks', domain: 'https://paulintheparks.com/'},
    {feed: 'https://pazooktraveljournal.com/feed', ukey: 'pazooktraveljournal', domain: 'https://pazooktraveljournal.com/'},
    {feed: 'https://periodicadventures.com/feed', ukey: 'periodicadventures', domain: 'https://periodicadventures.com/'},
    {feed: 'https://phenomenalglobe.com/feed', ukey: 'phenomenalglobe', domain: 'https://phenomenalglobe.com/'},
    {feed: 'https://portexplore.me//feed', ukey: 'portexplore', domain: 'https://portexplore.me//'},
    {feed: 'https://portugalthings.com/feed', ukey: 'portugalthings', domain: 'https://portugalthings.com/'},
    {feed: 'https://postcardsandpassports.com/feed', ukey: 'postcardsandpassports', domain: 'https://postcardsandpassports.com/'},
    {feed: 'https://profcruise.com//feed', ukey: 'profcruise', domain: 'https://profcruise.com//'},
    {feed: 'https://puntacanatravelblog.com/feed', ukey: 'puntacanatravelblog', domain: 'https://puntacanatravelblog.com/'},
    {feed: 'https://rawmalroams.com/feed', ukey: 'rawmalroams', domain: 'https://rawmalroams.com/'},
    {feed: 'https://reasonstocruise.com//feed', ukey: 'reasonstocruise', domain: 'https://reasonstocruise.com//'},
    {feed: 'https://roadiscalling.com/feed', ukey: 'roadiscalling', domain: 'https://roadiscalling.com/'},
    {feed: 'https://roamingromania.com/feed', ukey: 'roamingromania', domain: 'https://roamingromania.com/'},
    {feed: 'https://routinelynomadic.com/feed', ukey: 'routinelynomadic', domain: 'https://routinelynomadic.com/'},
    {feed: 'https://savannahfirsttimer.com/feed', ukey: 'savannahfirsttimer', domain: 'https://savannahfirsttimer.com/'},
    {feed: 'https://sharingthewander.com/feed', ukey: 'sharingthewander', domain: 'https://sharingthewander.com/'},
    {feed: 'https://shesabroadagain.com/feed', ukey: 'shesabroadagain', domain: 'https://shesabroadagain.com/'},
    {feed: 'https://shesavesshetravels.com/feed', ukey: 'shesavesshetravels', domain: 'https://shesavesshetravels.com/'},
    {feed: 'https://shipmonk.co.uk/feed', ukey: 'shipmonk', domain: 'https://shipmonk.co.uk/'},
    {feed: 'https://shipsandchampagne.com/feed', ukey: 'shipsandchampagne', domain: 'https://shipsandchampagne.com/'},
    {feed: 'https://southerntrippers.com/feed', ukey: 'southerntrippers', domain: 'https://southerntrippers.com/'},
    {feed: 'https://sparkx.org/feed', ukey: 'sparkx', domain: 'https://sparkx.org/'},
    {feed: 'https://swedishnomad.com/feed', ukey: 'swedishnomad', domain: 'https://swedishnomad.com/'},
    {feed: 'https://swissfamilyfun.com/feed', ukey: 'swissfamilyfun', domain: 'https://swissfamilyfun.com/'},
    {feed: 'https://texastravel365.com/feed', ukey: 'texastravel365', domain: 'https://texastravel365.com/'},
    {feed: 'https://thebanffblog.com/feed', ukey: 'thebanffblog', domain: 'https://thebanffblog.com/'},
    {feed: 'https://thecruiseblogger.com//feed', ukey: 'thecruiseblogger', domain: 'https://thecruiseblogger.com//'},
    {feed: 'https://theetlrblog.com/feed', ukey: 'theetlrblog', domain: 'https://theetlrblog.com/'},
    {feed: 'https://thegapdecaders.com/feed', ukey: 'thegapdecaders', domain: 'https://thegapdecaders.com/'},
    {feed: 'https://thegayglobetrotter.com/feed', ukey: 'thegayglobetrotter', domain: 'https://thegayglobetrotter.com/'},
    {feed: 'https://thehikinghelper.com/feed', ukey: 'thehikinghelper', domain: 'https://thehikinghelper.com/'},
    {feed: 'https://thehikinglife.com/feed', ukey: 'thehikinglife', domain: 'https://thehikinglife.com/'},
    {feed: 'https://thenomadvisor.com/feed', ukey: 'thenomadvisor', domain: 'https://thenomadvisor.com/'},
    {feed: 'https://thenorthernboy.com//feed', ukey: 'thenorthernboy', domain: 'https://thenorthernboy.com//'},
    {feed: 'https://theredphoneboxtravels.com/feed', ukey: 'theredphoneboxtravels', domain: 'https://theredphoneboxtravels.com/'},
    {feed: 'https://thesavvyglobetrotter.com/feed', ukey: 'thesavvyglobetrotter', domain: 'https://thesavvyglobetrotter.com/'},
    {feed: 'https://theworldoverload.com/feed', ukey: 'theworldoverload', domain: 'https://theworldoverload.com/'},
    {feed: 'https://thrillingtravel.in/feed', ukey: 'thrillingtravel', domain: 'https://thrillingtravel.in/'},
    {feed: 'https://toptravelsights.com/feed', ukey: 'toptravelsights', domain: 'https://toptravelsights.com/'},
    {feed: 'https://travelconnectexperience.net/feed', ukey: 'travelconnectexperience', domain: 'https://travelconnectexperience.net/'},
    {feed: 'https://travelcruiselive.com//feed', ukey: 'travelcruiselive', domain: 'https://travelcruiselive.com//'},
    {
      feed: 'https://travelingadventuresofafarmgirl.com/feed',
      ukey: 'travelingadventuresofafarmgirl',
      domain: 'https://travelingadventuresofafarmgirl.com/'
    },
    {feed: 'https://travellovefashion.com/feed', ukey: 'travellovefashion', domain: 'https://travellovefashion.com/'},
    {feed: 'https://travelmadmum.com//feed', ukey: 'travelmadmum', domain: 'https://travelmadmum.com//'},
    {feed: 'https://travelsandtreats.com/feed', ukey: 'travelsandtreats', domain: 'https://travelsandtreats.com/'},
    {feed: 'https://traveltastic.blog/feed', ukey: 'traveltastic', domain: 'https://traveltastic.blog/'},
    {feed: 'https://tripchiefs.com/feed', ukey: 'tripchiefs', domain: 'https://tripchiefs.com/'},
    {feed: 'https://ukfamilytravel.com/feed', ukey: 'ukfamilytravel', domain: 'https://ukfamilytravel.com/'},
    {feed: 'https://ultimateontario.com/feed', ukey: 'ultimateontario', domain: 'https://ultimateontario.com/'},
    {feed: 'https://undiscoveredpathhome.com/feed', ukey: 'undiscoveredpathhome', domain: 'https://undiscoveredpathhome.com/'},
    {feed: 'https://uprootedtraveler.com/feed', ukey: 'uprootedtraveler', domain: 'https://uprootedtraveler.com/'},
    {feed: 'https://usaroadtripnow.com/feed', ukey: 'usaroadtripnow', domain: 'https://usaroadtripnow.com/'},
    {feed: 'https://veepeejay.com/feed', ukey: 'veepeejay', domain: 'https://veepeejay.com/'},
    {feed: 'https://vegancruiser.co.uk//feed', ukey: 'vegancruiser', domain: 'https://vegancruiser.co.uk//'},
    {feed: 'https://vegaslens.com/feed', ukey: 'vegaslens', domain: 'https://vegaslens.com/'},
    {feed: 'https://veggiewayfarer.com/feed', ukey: 'veggiewayfarer', domain: 'https://veggiewayfarer.com/'},
    {feed: 'https://vermontexplored.com//feed', ukey: 'vermontexplored', domain: 'https://vermontexplored.com//'},
    {feed: 'https://visitwithus.co.uk//feed', ukey: 'visitwithus', domain: 'https://visitwithus.co.uk//'},
    {feed: 'https://wakingupwild.com/feed', ukey: 'wakingupwild', domain: 'https://wakingupwild.com/'},
    {feed: 'https://wander-lush.org/feed', ukey: 'wander-lush', domain: 'https://wander-lush.org/'},
    {feed: 'https://wavesandcobblestones.com/feed', ukey: 'wavesandcobblestones', domain: 'https://wavesandcobblestones.com/'},
    {feed: 'https://wayfaringviews.com/feed', ukey: 'wayfaringviews', domain: 'https://wayfaringviews.com/'},
    {feed: 'https://wayfaringwithwagner.com/feed', ukey: 'wayfaringwithwagner', domain: 'https://wayfaringwithwagner.com/'},
    {feed: 'https://Whereintheworldisnina.com/feed', ukey: 'Whereintheworldisnina', domain: 'https://Whereintheworldisnina.com/'},
    {feed: 'https://worldbyweekend.com/feed', ukey: 'worldbyweekend', domain: 'https://worldbyweekend.com/'},
    {feed: 'https://www.aloneandabroad.com/feed', ukey: 'aloneandabroad', domain: 'https://www.aloneandabroad.com/'},
    {feed: 'https://www.americanswobsessed.com/feed', ukey: 'americanswobsessed', domain: 'https://www.americanswobsessed.com/'},
    {feed: 'https://www.backpackadventures.org/feed', ukey: 'backpackadventures', domain: 'https://www.backpackadventures.org/'},
    {feed: 'https://www.baliholidaysecrets.com/feed', ukey: 'baliholidaysecrets', domain: 'https://www.baliholidaysecrets.com/'},
    {feed: 'https://www.bon-bonvoyage.com/feed', ukey: 'bon-bonvoyage', domain: 'https://www.bon-bonvoyage.com/'},
    {feed: 'https://www.breakfastwithtiffani.com/feed', ukey: 'breakfastwithtiffani', domain: 'https://www.breakfastwithtiffani.com/'},
    {feed: 'https://www.chargetheglobe.com/feed', ukey: 'chargetheglobe', domain: 'https://www.chargetheglobe.com/'},
    {feed: 'https://www.christravelblog.com/feed', ukey: 'christravelblog', domain: 'https://www.christravelblog.com/'},
    {feed: 'https://www.christravelblog.com/feed', ukey: 'christravelblog', domain: 'https://www.christravelblog.com/'},
    {feed: 'https://www.cktravels.com/feed', ukey: 'cktravels', domain: 'https://www.cktravels.com/'},
    {feed: 'https://www.colorfuljourneys.com/feed', ukey: 'colorfuljourneys', domain: 'https://www.colorfuljourneys.com/'},
    {feed: 'https://www.disneykindalife.com/feed', ukey: 'disneykindalife', domain: 'https://www.disneykindalife.com/'},
    {feed: 'https://www.elliott.org/feed', ukey: 'elliott', domain: 'https://www.elliott.org/'},
    {feed: 'https://www.enidhi.net/feed', ukey: 'enidhi', domain: 'https://www.enidhi.net/'},
    {feed: 'https://www.funtravelog.com/feed', ukey: 'funtravelog', domain: 'https://www.funtravelog.com/'},
    {feed: 'https://www.globalgrasshopper.com/feed', ukey: 'globalgrasshopper', domain: 'https://www.globalgrasshopper.com/'},
    {feed: 'https://www.gototravelgal.com/feed', ukey: 'gototravelgal', domain: 'https://www.gototravelgal.com/'},
    {feed: 'https://www.greatwidetravel.com/feed', ukey: 'greatwidetravel', domain: 'https://www.greatwidetravel.com/'},
    {feed: 'https://www.halfwayanywhere.com/feed', ukey: 'halfwayanywhere', domain: 'https://www.halfwayanywhere.com/'},
    {feed: 'https://www.HoneyTrek.com/feed', ukey: 'HoneyTrek', domain: 'https://www.HoneyTrek.com/'},
    {feed: 'https://www.ilovenewmexicoblog.com/feed', ukey: 'ilovenewmexicoblog', domain: 'https://www.ilovenewmexicoblog.com/'},
    {feed: 'https://www.insiderfamilies.com/feed', ukey: 'insiderfamilies', domain: 'https://www.insiderfamilies.com/'},
    {feed: 'https://www.italyfoodies.com/feed', ukey: 'italyfoodies', domain: 'https://www.italyfoodies.com/'},
    {feed: 'https://www.kidsareatrip.com/feed', ukey: 'kidsareatrip', domain: 'https://www.kidsareatrip.com/'},
    {feed: 'https://www.liveawilderlife.com/feed', ukey: 'liveawilderlife', domain: 'https://www.liveawilderlife.com/'},
    {feed: 'https://www.livingoutlau.com/feed', ukey: 'livingoutlau', domain: 'https://www.livingoutlau.com/'},
    {feed: 'https://www.mygrandcanyonpark.com/feed', ukey: 'mygrandcanyonpark', domain: 'https://www.mygrandcanyonpark.com/'},
    {feed: 'https://www.nextstopwhoknows.com/feed', ukey: 'nextstopwhoknows', domain: 'https://www.nextstopwhoknows.com/'},
    {feed: 'https://www.packing-up-the-pieces.com/feed', ukey: 'packing-up-the-pieces', domain: 'https://www.packing-up-the-pieces.com/'},
    {feed: 'https://www.redfedoradiary.com/feed', ukey: 'redfedoradiary', domain: 'https://www.redfedoradiary.com/'},
    {feed: 'https://www.sotheadventurebegins.com/feed', ukey: 'sotheadventurebegins', domain: 'https://www.sotheadventurebegins.com/'},
    {feed: 'https://www.supersimplesaltylife.com/feed', ukey: 'supersimplesaltylife', domain: 'https://www.supersimplesaltylife.com/'},
    {
      feed: 'https://www.thedailyadventuresofme.com/feed',
      ukey: 'thedailyadventuresofme',
      domain: 'https://www.thedailyadventuresofme.com/'
    },
    {feed: 'https://www.thediscoveriesof.com/feed', ukey: 'thediscoveriesof', domain: 'https://www.thediscoveriesof.com/'},
    {feed: 'https://www.thefamilyfreestylers.com/feed', ukey: 'thefamilyfreestylers', domain: 'https://www.thefamilyfreestylers.com/'},
    {feed: 'https://www.themeparkhipster.com/feed', ukey: 'themeparkhipster', domain: 'https://www.themeparkhipster.com/'},
    {feed: 'https://www.theopensuitcase.com/feed', ukey: 'theopensuitcase', domain: 'https://www.theopensuitcase.com/'},
    {feed: 'https://www.travelnewyorknow.com/feed', ukey: 'travelnewyorknow', domain: 'https://www.travelnewyorknow.com/'},
    {feed: 'https://www.traxplorio.com/feed', ukey: 'traxplorio', domain: 'https://www.traxplorio.com/'},
    {feed: 'https://www.veggtravel.com/feed', ukey: 'veggtravel', domain: 'https://www.veggtravel.com/'},
    {feed: 'https://www.whereindublin.com/feed', ukey: 'whereindublin', domain: 'https://www.whereindublin.com/'},
    {feed: 'https://yourcruisegirl.com//feed', ukey: 'yourcruisegirl', domain: 'https://yourcruisegirl.com//'},
    {feed: 'https://Allears.net/feed', ukey: 'Allears', domain: 'https://Allears.net/'},
    {feed: 'https://hikingthealps.com/feed', ukey: 'hikingthealps', domain: 'https://hikingthealps.com/'},
    {feed: 'https://triptins.com/feed', ukey: 'triptins', domain: 'https://triptins.com/'},
    {feed: 'https://carmensluxurytravel.com/feed', ukey: 'carmensluxurytravel', domain: 'https://carmensluxurytravel.com/'},
    {feed: 'https://europeancitieswithkids.com/feed', ukey: 'europeancitieswithkids', domain: 'https://europeancitieswithkids.com/'},
    {feed: 'https://europeinwinter.com/feed', ukey: 'europeinwinter', domain: 'https://europeinwinter.com/'},
    {feed: 'https://familydestinationsguide.com/feed', ukey: 'familydestinationsguide', domain: 'https://familydestinationsguide.com/'},
    {feed: 'https://diningtraveler.com/feed', ukey: 'diningtraveler', domain: 'https://diningtraveler.com/'},
    {feed: 'https://365traveler.com/feed', ukey: '365traveler', domain: 'https://365traveler.com/'},
    {feed: 'https://ahlanmonica.com/feed', ukey: 'ahlanmonica', domain: 'https://ahlanmonica.com/'},
    {feed: 'https://alexandleahontour.org/feed', ukey: 'alexandleahontour', domain: 'https://alexandleahontour.org/'},
    {feed: 'https://alpinehikers.com/feed', ukey: 'alpinehikers', domain: 'https://alpinehikers.com/'},
    {feed: 'https://andaluciahiking.com/feed', ukey: 'andaluciahiking', domain: 'https://andaluciahiking.com/'},
    {feed: 'https://awalkintheworld.com/feed', ukey: 'awalkintheworld', domain: 'https://awalkintheworld.com/'},
    {feed: 'https://backpacking-travel-blog.com/feed', ukey: 'backpacking-travel-blog', domain: 'https://backpacking-travel-blog.com/'},
    {feed: 'https://backroadsandotherstories.com/feed', ukey: 'backroadsandotherstories', domain: 'https://backroadsandotherstories.com/'},
    {feed: 'https://byjinalee.com/feed', ukey: 'byjinalee', domain: 'https://byjinalee.com/'},
    {feed: 'https://cretelocals.com/feed', ukey: 'cretelocals', domain: 'https://cretelocals.com/'},
    {feed: 'https://guide2portugal.com/feed', ukey: 'guide2portugal', domain: 'https://guide2portugal.com/'},
    {feed: 'https://midwestexplored.com/feed', ukey: 'midwestexplored', domain: 'https://midwestexplored.com/'},
    {feed: 'https://milestomemories.com/feed', ukey: 'milestomemories', domain: 'https://milestomemories.com/'},
    {feed: 'https://livguine.com/feed/', ukey: 'livguine', domain: 'https://livguine.com//'},
    {feed: 'https://livingaudaciously.com/feed/', ukey: 'livingaudaciously', domain: 'https://livingaudaciously.com//'},
    {feed: 'https://navigationjunkie.blog/feed/', ukey: 'navigationjunkie', domain: 'https://navigationjunkie.blog//'},
    {feed: 'https://www.stuckonthego.com/feed/', ukey: 'stuckonthego', domain: 'https://www.stuckonthego.com//'},
    {feed: 'https://laurenslighthouse.com/feed', ukey: 'laurenslighthouse', domain: 'https://laurenslighthouse.com/'},
    {feed: 'https://roadtrippers.com/feed', ukey: 'roadtrippers', domain: 'https://roadtrippers.com/'},
    {feed: 'https://taliasbucketlist.blog/feed', ukey: 'taliasbucketlist', domain: 'https://taliasbucketlist.blog/'},
    {feed: 'https://travelmexicosolo.com/feed/', ukey: 'travelmexicosolo', domain: 'https://travelmexicosolo.com//'},
    {feed: 'https://courtneytheexplorer.com/feed', ukey: 'courtneytheexplorer', domain: 'https://courtneytheexplorer.com/'},
  ];

  options = {
    key: 'wFE7nB4D3F4C3B10B6eyznxmE2cd1rsplpfG-7c1eeF6nablA3C11B8A4D2B4F3G2F3B2==',
    charCounterCount: true,
    language: 'en',
    toolbarButtons: ['bold', 'italic', 'underline', 'outdent', 'indent', 'formatOL', 'formatUL', 'color', 'align', 'fontFamily', 'fontSize', 'html', 'insertLink']
  };


  constructor(private db: AngularFireDatabase,
              private http: RoehttpService,
              private httpx: HttpClient,
              public admin: AdminService,
              private dom: DomSanitizer,
              private is: InterfaceService) {
    this.curlang = this.admin.language;
  }

  ngOnInit() {
    this.is.getList('Topics/TempInfo/aerialview').take(1).subscribe(views => {
      this.aerialviews = views;
      console.log('VIEWS', views)
    });

    this.kingdomarr = _.orderBy([...new Set(this.classifications.map(c => c.kingdom))]);

    /*
        this.is.getList('Topics/topics').take(1).subscribe(topics => {
          this.pubtopics = _.orderBy(topics, 'name', 'asc');
        });

        this.is.getList('Elements').take(1).subscribe(elements => {
          this.elements = _.orderBy(elements, 'name', 'asc');
        });
    */

    this.is.getList(`Capptivation/Adventures/notes`)
      .take(1)
      .subscribe(notes => {
        this.notes = notes;
      });
  }

  onJunkCams() {
    this.junkcams = [];
    this.is.getObject(`Topics/TempInfo/nextkey`).take(1).subscribe(nextkey => {
      this.nextkey = nextkey;
      console.log('nextkeay', nextkey);
      this.is.getLimitToNextnKeys(21, `Topics/TempInfo/junkcams`, this.nextkey).take(1).subscribe(cams => {
        cams.splice(0, 1);
        cams.forEach(c => {
          if (c['url'] && c['url'].length) {
            c['src'] = this.dom.bypassSecurityTrustResourceUrl(c['url']);
          } else {
            const str = `https://www.youtube.com/embed/${c['ukey']}`;
            c['src'] = this.dom.bypassSecurityTrustResourceUrl(str);
          }
          c['Category'] = '';
          this.junkcams.push(c);
        });

      });
    });
  }

  onRefresh() {
    const str = `https://www.youtube.com/embed/${this.curcams[0]['ukey']}?&autoplay=1`;
    this.src = this.dom.bypassSecurityTrustResourceUrl(str);
  }

  onCams() {
    this.curcams = [];
    this.is.getObject(`Topics/TempInfo/nextkey`).take(1).subscribe(nextkey => {
      this.nextkey = nextkey;
      console.log('nextkeay', nextkey);
      this.is.getFirstSnap(`Topics/TempInfo/finalcams/${this.nextkey}`).take(1).subscribe(c => {
        console.log('c', c);
        const str = `https://www.youtube.com/embed/${c['ukey']}?&autoplay=1`;
        this.src = this.dom.bypassSecurityTrustResourceUrl(str);

        if (c['livestream'] && c['livestream'].length) {
          if (c['livestream'].includes('https://www.youtube.com/channel')) {
            c['livestream'] = `https://www.youtube.com/embed/live_stream?channel=${c['CF']}`
          }
          this.channelsrc = this.dom.bypassSecurityTrustResourceUrl(c['livestream']);
        } else {
          const s = `https://www.youtube.com/embed/live_stream?channel=${c['CF']}`;
          this.channelsrc = this.dom.bypassSecurityTrustResourceUrl(s);
        }
        if (c['Title']) {
          console.log('TTT')
          c['MyTitle'] = c['Title'];
        } else {
          console.log('ttttt')
          c['MyTitle'] = c['title'];
          c['Title'] = c['title'];
        }

        this.curcams.push(c);
      });
    });
  }

  onLiveStream() {
    this.curcams[0]['livestream'] = ` https://www.youtube.com/embed/live_stream?channel=${this.curcams[0]['CF']}`
  }

  async getCams(nextPageToken: string) {
    const after = new Date(this.after);
    const before = new Date(this.before);

    const afterISO = after.toISOString();
    const beforeISO = before.toISOString();
    await this.http.getLiveStreams(afterISO, beforeISO, nextPageToken).take(1).subscribe(vals => {
      if (vals && vals.items.length > 0) {
        vals.items.forEach(val => {
          const id = val['id']['videoId'];
          if (id && id.length) {

            this.is.getQuery('Topics/TempInfo/finalcams', 'ukey', id).take(1).subscribe(ex => {
              console.log('ex', ex);
              if (ex.length === 0) {
                const t = val['snippet']['title'];
                this.is.getQuery('Topics/TempInfo/finalcams', 'Title', t).take(1).subscribe(ts => {
                  if (ts.length === 0) {
                    this.is.setObjNoLog(`Topics/TempInfo/rawcams/${id}`, val);
                  } else {
                    console.log('already exists in final cams by title');
                  }
                });
              } else {
                console.log('Already a final cam by ukey');
              }
            });
          }
        });
      }
    });
  }

  onFetchCams() {
    this.after = new Date().getTime() - 24 * 60 * 60 * 1000;
    this.before = new Date().getTime();
    this.getCams('');
  }

  // 1. use fetch cams to get 50 new webcams from youtube and save them in rawcams
  // 2. in onLoadRawCams i fetch the cams in rawcams and a) see if i already have it and b) give it
  //    a fist look. if good, i save an obj to step1cams
  // 3. i now need to sort the cams into nice ones and utilitarian ones. if it's a nice one
  //    i need to get it's long lat and answer some questions about it and save it in final cams
  //    else i put it in somedaycams

  onLoadStep1Cams() {
    this.is.getFirst(`Topics/TempInfo/step1cams`, 1).take(1).subscribe(cams => {
      this.step1cams = [];
      cams.forEach(c => {
        const str = `https://www.youtube.com/embed/${c['ukey']}?&autoplay=1`;
        this.src = this.dom.bypassSecurityTrustResourceUrl(str);
        https://www.youtube.com/embed/live_stream?channel=3moaa-TXid0
          this.channelstr = `https://www.youtube.com/embed/live_stream?channel=${c['CF']}`;
        //const channelstr = `https://www.youtube.com/channel/${c['CF']}/live?&autoplay=1`;
        this.channelsrc = this.dom.bypassSecurityTrustResourceUrl(this.channelstr);
        c['MyTitle'] = c['Title'];
        this.step1cams.push(c);
      });
    });
  }


  onClipBoard(who: string) {
    this.clipped = null;
    navigator.clipboard.readText().then(val => {
      if (val) {
        this.clipped = JSON.parse(val);
        if (!this.clipped || !this.clipped.datapid) {
          alert('bad data in clipboard');
          this.clipped = null;
        } else {
          this.location = this.clipped.name;
          this.ukey = this.clipped.datapid;

          if (this.clipped.websiteurl) {
            this.weburl = this.clipped.websiteurl;
          }
          if (this.clipped.dataurl) {
            this.googleurl = this.clipped.dataurl;
          }

          if (this.clipped.wikipedia) {
            this.wikiurl = this.clipped.wikipedia;
          }
          if (this.clipped.britanica) {
            this.briturl = this.clipped.britanica;
          }


          if (this.clipped.lat) {
            if (this.step1cams && this.step1cams.length) {
              this.step1cams[0]['xlat'] = this.clipped.lat;
            }
            if (this.curcams && this.curcams.length) {
              this.curcams[0]['xlat'] = this.clipped.lat;
            }
          }

          if (this.clipped.long) {
            if (this.step1cams && this.step1cams.length) {
              this.step1cams[0]['xlng'] = this.clipped.long;
            }
            if (this.curcams && this.curcams.length) {
              this.curcams[0]['xlng'] = this.clipped.long;
            }
          }

          if (this.clipped.subtitle) {
            const arr = this.clipped.subtitle.split(' in ')[0];
            this.onTryGetTaxonomys(arr);
          }

        }
      }
    });
  }

  onTryGetTaxonomys(name: string) {
    if (name && name.length) {
      const lowername = name.toLowerCase();


      // lowername.includes(c.class.toLowerCase())

      const z = this.classifications.findIndex(c =>
        c.class &&
        lowername.match(`\\b${c.class.toLowerCase()}\\b`)
      );
      if (z > -1) {
        this.classvar = this.classifications[z].class;
        this.kingdomvar = this.classifications[z].kingdom;
        this.phylumvar = this.classifications[z].phylum;
      } else {
        const x = this.classifications.findIndex(c =>
          c.phylum &&
          lowername.match(`\\b${c.phylum.toLowerCase()}\\b`)
        );

        //lowername.includes(c.phylum.toLowerCase())

        if (x > -1) {
          this.phylumvar = this.classifications[x].phylum;
          this.kingdomvar = this.classifications[x].kingdom;
        } else {
          const y = this.classifications.findIndex(c =>
            lowername.match(`\\b${c.kingdom.toLowerCase()}\\b`)
          );
          //lowername.includes(c.kingdom.toLowerCase())


          if (y > -1) {
            this.kingdomvar = this.classifications[y].kingdom;
          }
        }
      }

      if (this.kingdomvar && this.kingdomvar.length) {
        this.phylumarr =
          _.orderBy([...new Set(this.classifications.filter(p => p.kingdom === this.kingdomvar).map(val => val.phylum))]);
      }

      if (this.phylumvar && this.phylumvar.length) {
        this.classarr =
          _.orderBy([...new Set(this.classifications.filter(p => p.phylum === this.phylumvar).map(val => val.class))]);
      }

    }
  }

  onKingdomChange() {
    this.phylumvar = '';
    this.phylumarr = _.orderBy([...new Set(this.classifications.filter(p => p.kingdom === this.kingdomvar).map(val => val.phylum))]);
  }

  onPhylumChange() {
    this.classvar = '';
    this.classarr =
      _.orderBy([...new Set(this.classifications.filter(p => p.phylum === this.phylumvar).map(val => val.class))]);
  }

  onClippedCam() {
    this.clippedcam = null;
    navigator.clipboard.readText().then(val => {
      if (val) {
        this.clippedcam = JSON.parse(val);
        const ukey = this.clippedcam.link.split('=')[1];
        this.is.getObject(`Topics/TempInfo/finalcams/ukey/${ukey}`).take(1).subscribe(cam => {
          if (!cam) {
            console.log('cam', this.clippedcam);
            this.step1cams = [];
            const obj = {};
            obj['CF'] = this.clippedcam.cf;
            obj['Title'] = this.clippedcam.name;
            obj['ukey'] = ukey
            obj['Description'] = '';
            const str = `https://www.youtube.com/embed/${obj['ukey']}?&autoplay=1`;
            this.src = this.dom.bypassSecurityTrustResourceUrl(str);
            const s = `https://www.youtube.com/embed/live_stream?channel=${obj['CF']}`;
            this.channelsrc = this.dom.bypassSecurityTrustResourceUrl(s);
            this.step1cams.push(obj);
          } else {
            console.log('nothing to get excited about')
          }
        })
      }
    });
  }

  onLoadRawCams() {
    this.rawcams = [];
    this.is.getFirst(`Topics/TempInfo/rawcams`, 20).take(1).subscribe(cams => {
      cams.forEach(c => {
        const id = c['id']['videoId'];
        this.is.getObject(`Topics/TempInfo/finalcams/${id}`).take(1).subscribe(final => {
          if (final) {
            console.log('Alread Exists');
            this.is.deleteObjNoLog(`Topics/TempInfo/rawcams/${id}`);
          } else {
            const obj = {};
            obj['ukey'] = id;
            obj['CF'] = c['snippet']['channelId'];
            obj['CN'] = c['snippet']['channelTitle'];
            obj['Description'] = c['snippet']['description'];
            obj['Title'] = c['snippet']['title'];
            const str = `https://www.youtube.com/embed/${obj['ukey']}?&autoplay=1`;
            obj['src'] = this.dom.bypassSecurityTrustResourceUrl(str);
            console.log(c['snippet'], 'obj', obj)
            this.rawcams.push(obj);
          }
        });
      });
    });
  }

  onSaveStep1Cam(step1cam, quality) {
    const now = new Date();
    const offset = now.getTimezoneOffset() * -1;
    const utcTime = now.getTime() + (offset * 60 * 1000);
    const date = new Date(utcTime).toISOString();
    const qty = {quality, date};
    if (!step1cam['quality']) {
      step1cam['quality'] = [];
    }
    step1cam['quality'].push(qty);
    this.is.setObjNoLog(`Topics/TempInfo/finalcams/${step1cam['ukey']}`, step1cam);

    this.is.deleteObjNoLog(`Topics/TempInfo/step1cams/${step1cam['ukey']}`);
    this.step1cams.splice(0, 1);
    this.clipped = null;
    this.location = '';
    this.ukey = '';
    this.weburl = '';
    this.googleurl = '';
    this.wikiurl = '';
    this.briturl = '';

    this.onLoadStep1Cams();
  }

  onSetType(placeID, website, name, kingdomvar, phylumvar, classvar) {
    if (website && website.length && name && name.length) {
      this.is.onPlaceMd5(placeID, website, name);
    }

    if (kingdomvar && kingdomvar.length) {
      this.is.setObjNoLog(`Places/${placeID}/taxonomy/kingdom`, kingdomvar);
    }

    if (phylumvar && phylumvar.length) {
      this.is.setObjNoLog(`Places/${placeID}/taxonomy/phylum`, phylumvar);
    }

    if (classvar && classvar.length) {
      this.is.setObjNoLog(`Places/${placeID}/taxonomy/class`, classvar);
    }
  }

  async onSaveClipped(newclipped, location, weburl, wikiurl, briturl, googleurl, kingdomvar, phylumvar, classvar) {

    const geoappify = await new Promise<any>(resolve =>
      this.http.getGeoApify(newclipped.lat, newclipped.long).take(1)
        .subscribe(translated => {
          resolve(translated);
        }));


    let hash;
    if (newclipped.long && newclipped.lat) {
      hash = geohash.encode(newclipped.lat, newclipped.long);
    }
    console.log('saving new', newclipped);
    this.is.setObjNoLog(`Places/${newclipped.datapid}/ukey`, newclipped.datapid);  // ukey
    this.is.setObjNoLog(`Places/${newclipped.datapid}/name`, location);  // name

    if (newclipped.address) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/location/address`, newclipped.address);
      this.is.setObjNoLog(`Places/${newclipped.datapid}/address`, newclipped.address); // address
    }

    if (newclipped.long) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/location/long`, newclipped.long);
    }

    if (newclipped.lat) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/location/lat`, newclipped.lat);
    }

    if (hash) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/location/hash`, hash);
      this.is.setObjNoLog(`Places/${newclipped.datapid}/hash`, hash);  // hash
    }


    const key = new Key();
    key.id = newclipped.datapid;
    key.name = 'Google';
    this.is.setObjNoLog(`Places/${newclipped.datapid}/location/keys/0`, key);

    let newlocation;

    if (geoappify && geoappify.features && geoappify.features[0] && geoappify.features[0].properties) {
      if (geoappify.features[0] && geoappify.features[0].bbox) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/bounds`, geoappify.features[0].bbox);
      }

      const openstreetmap = geoappify.features[0].properties;
      this.is.setObjNoLog(`Places/${newclipped.datapid}/openstreetmap`, openstreetmap);
      if (openstreetmap.place_id) {
        const newkey = new Key();
        newkey.id = openstreetmap.place_id;
        newkey.name = 'Open Street Map';
        this.is.pushObj(`Places/${newclipped.datapid}/location/keys`, newkey);
      }

      let country;
      if (openstreetmap.country_code) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/countrycode`, openstreetmap.country_code.toUpperCase());
        country = openstreetmap.country_code.toUpperCase();
      }
      let state;
      if (openstreetmap.state) {
        state = openstreetmap.state;
      }
      let city;
      if (openstreetmap.city) {
        city = openstreetmap.city;
      }
      if (country && country === 'US') {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/country`, openstreetmap.state);
        let loc;
        if (city && state) {
          loc = `${city}, ${state}`;
          newlocation = `${city}, ${state}`;
        } else if (city && country) {
          loc = `${state}, ${country}`;
          newlocation = `${state}, ${country}`;
        }

        if (loc) {
          this.is.setObjNoLog(`Places/${newclipped.datapid}/location/loc`, loc);
        }
        if (newlocation) {
          this.is.setObjNoLog(`Places/${newclipped.datapid}/location/location`, newlocation);
        }

      } else if (city && country) {
        newlocation = `${city}, ${country}`;
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/country`, openstreetmap.country);
        console.log('here is a problem');
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/loc`, `${city}, ${country}`);
        console.log('there is a problem');
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/location`, `${city}, ${country}`);
      }
    }

    if (newclipped.description && newclipped.description.length) {
      const desc = new LangStringBlock();
      desc.en.description = newclipped.description;
      this.is.setObjNoLog(`Places/${newclipped.datapid}/descriptions/0/desc`, desc);

      if (newclipped.descriptionsourcelink && newclipped.descriptionsourcelink.length) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/descriptions/0/source`, newclipped.descriptionsourcelink);
      }
    }

    if (newclipped.images) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/photos/0`, {url: newclipped.images, who: 'Google'}); // address
    }


    this.onSetType(newclipped.datapid, weburl, location, kingdomvar, phylumvar, classvar);

    if (weburl) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/website`, weburl); // touristurl
      this.is.onPlaceMd5(newclipped.datapid, this.weburl, this.location);
    }
    if (this.wikiurl) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/Wikipedia`, wikiurl); // website
    }

    if (this.briturl) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/Britanica`, briturl); // website
    }
    if (this.googleurl) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/GoogleSearch`, googleurl); // website
    }


    this.is.setObjNoLog(`PlaceExists/${newclipped.datapid}`, {code: 'NLG', ukey: newclipped.datapid});
  }

  onUpdateStep1Cam(i, action, quality) {
    const step1cam = _.cloneDeep(this.step1cams[i]);
    const clipped = _.cloneDeep(this.clipped);
    const location = this.location;
    const ukey = this.ukey;
    const weburl = this.weburl;
    const googleurl = this.googleurl;
    const wikiurl = this.wikiurl;
    const briturl = this.briturl;
    const kingdomvar = this.kingdomvar;
    const phylumvar = this.phylumvar;
    const classvar = this.classvar;

    if (action === 1) {
      this.is.setObjNoLog(`Topics/TempInfo/someday/${step1cam['ukey']}`, step1cam);
      this.is.deleteObjNoLog(`Topics/TempInfo/step1cams/${step1cam['ukey']}`);
      this.step1cams.splice(0, 1);
      this.onLoadStep1Cams();

    } else if (action === 2) {
      if (clipped && clipped.datapid) {
        if (!step1cam['parents']) {
          step1cam['parents'] = [];
        }
        step1cam['parents'][clipped.datapid] = clipped.datapid;
        this.is.getObject(`PlaceExists/${clipped.datapid}`).take(1).subscribe(exist => {
          if (exist) {
            this.onSaveStep1Cam(step1cam, quality);
          } else {
            // need to save the place
            this.onSaveClipped(clipped, location, weburl, wikiurl, briturl, googleurl, kingdomvar, phylumvar, classvar);
            this.onSaveStep1Cam(step1cam, quality);
          }
        });
      } else {
        this.onSaveStep1Cam(step1cam, quality);
      }

      if (step1cam['parents']) {
        const arr = Object.keys(step1cam['parents']);
        arr.forEach(a => {
          this.is.setObjNoLog(`Places/${a}/webcamz/${step1cam['ukey']}`, step1cam['ukey']);
        });
      }

    } else {
      this.is.deleteObjNoLog(`Topics/TempInfo/step1cams/${step1cam['ukey']}`);
      this.step1cams.splice(0, 1);
      this.onLoadStep1Cams();
    }
  }

  onUpdateRawCam(i, save: boolean) {
    if (save && this.rawcams[i]['ukey'] && this.rawcams[i]['ukey'].length > 5) {
      this.is.setObjNoLog(`Topics/TempInfo/step1cams/${this.rawcams[i]['ukey']}`, this.rawcams[i]);
    }
    this.is.deleteObjNoLog(`Topics/TempInfo/rawcams/${this.rawcams[i]['ukey']}`);
    this.rawcams.splice(i, 1);
  }

  onUpdateJunkCam(i, action, quality?) {
    const junkcam = _.cloneDeep(this.junkcams[i]);
    const obj = {};
    console.log('juncams', junkcam);
    obj['ukey'] = junkcam['ukey'];
    obj['CN'] = junkcam['name'];
    if (junkcam['xlat']) {
      obj['xlat'] = junkcam['xlat'];
    }

    if (junkcam['xlng']) {
      obj['xlng'] = junkcam['xlng'];
    }

    if (junkcam['url']) {
      obj['livestream'] = junkcam['url'];
    }

    if (junkcam['Category']) {
      obj['Category'] = junkcam['Category'];
    }

    if (action === 1 || action === 2) {
      this.is.getObject(`Topics/TempInfo/finalcams/${junkcam['ukey']}/ukey`).take(1).subscribe(exists => {
        if (!exists) {
          if (action === 1) {
            this.is.setObjNoLog(`Topics/TempInfo/someday/${junkcam['ukey']}`, obj);
          } else if (action === 2) {
            const now = new Date();
            const offset = now.getTimezoneOffset() * -1;
            const utcTime = now.getTime() + (offset * 60 * 1000);
            const date = new Date(utcTime).toISOString();
            const qty = {quality, date};
            obj['quality'] = [];
            obj['quality'].push(qty);

            this.is.setObjNoLog(`Topics/TempInfo/finalcams/${junkcam['ukey']}`, obj);
          }
        } else {
          const now = new Date();
          const offset = now.getTimezoneOffset() * -1;
          const utcTime = now.getTime() + (offset * 60 * 1000);
          const date = new Date(utcTime).toISOString();
          const qty = {quality, date};
          this.is.pushObj(`Topics/TempInfo/finalcams/${junkcam['ukey']}/quality`, qty);
        }

        this.is.deleteObjNoLog(`Topics/TempInfo/junkcams/${junkcam['ukey']}`);
        this.is.setObjNoLog(`Topics/TempInfo/nextkey`, junkcam['ukey']);
        this.junkcams.splice(i, 1);

      });
    } else {
      this.is.deleteObjNoLog(`Topics/TempInfo/junkcams/${junkcam['ukey']}`);
      this.is.setObjNoLog(`Topics/TempInfo/nextkey`, junkcam['ukey']);
      this.junkcams.splice(i, 1);
    }
  }

  onFixCams() {
    this.is.getFirstSnapKeys(2, `Topics/TempInfo/finalcams`, this.nextkey).take(1).subscribe(keys => {
      if (keys && keys.length === 2) {
        console.log('keys', keys)
        this.is.setObjNoLog(`Topics/TempInfo/nextkey`,keys[1])
        this.onCams();
      }
    });
  }

  onCreateParent() {
    navigator.clipboard.readText().then(val => {
      if (val) {
        const clipped = JSON.parse(val);
        if (!clipped || !clipped.datapid) {
          alert('bad data in clipboard');
        } else {
          this.is.getObject(`PlaceExists/${clipped.datapid}`).take(1).subscribe(exist => {
            if (!exist) {
              // need to save the place
              let weburl = ''
              if (clipped.websiteurl) {
                weburl = clipped.websiteurl;
              }

              let googleurl = ''
              if (clipped.dataurl) {
                googleurl = clipped.dataurl;
              }

              let wikiurl = ''
              if (clipped.wikipedia) {
                wikiurl = clipped.wikipedia;
              }

              let briturl = ''
              if (clipped.britanica) {
                briturl = clipped.britanica;
              }

              this.kingdomvar = 'Admin';
              this.phylumvar = '';
              this.classvar = '';
              if (clipped.subtitle) {
                const arr = clipped.subtitle.split(' in ')[0];
                this.onTryGetTaxonomys(arr);
              }

              const location = `${this.curcams[0]['City']}, ${this.curcams[0]['Country']}`

              this.onSaveClipped(clipped, location, weburl, wikiurl, briturl, googleurl, this.kingdomvar, this.phylumvar, this.classvar);
            }
          });

          const pid = clipped.datapid;
          if (!this.curcams[0]['parents']) {
            this.curcams[0]['parents'] = {};
          }

          this.curcams[0]['parents'][pid] = pid;
          console.log('on createParent', this.curcams[0]);
        }
      }
    });
  }

  onCreateStepParent() {
    navigator.clipboard.readText().then(val => {
      if (val) {
        const clipped = JSON.parse(val);
        if (!clipped || !clipped.datapid) {
          alert('bad data in clipboard');
        } else {
          this.is.getObject(`PlaceExists/${clipped.datapid}`).take(1).subscribe(exist => {
            if (!exist) {
              // need to save the place
              let weburl = ''
              if (clipped.websiteurl) {
                weburl = clipped.websiteurl;
              }

              let googleurl = ''
              if (clipped.dataurl) {
                googleurl = clipped.dataurl;
              }

              let wikiurl = ''
              if (clipped.wikipedia) {
                wikiurl = clipped.wikipedia;
              }

              let briturl = ''
              if (clipped.britanica) {
                briturl = clipped.britanica;
              }

              this.kingdomvar = 'Admin';
              this.phylumvar = '';
              this.classvar = '';
              if (clipped.subtitle) {
                const arr = clipped.subtitle.split(' in ')[0];
                this.onTryGetTaxonomys(arr);
              }

              const location = `${this.curcams[0]['City']}, ${this.curcams[0]['Country']}`

              this.onSaveClipped(clipped, location, weburl, wikiurl, briturl, googleurl, this.kingdomvar, this.phylumvar, this.classvar);
            }
          });


          const pid = clipped.datapid;
          if (!this.step1cams[0]['parents']) {
            this.step1cams[0]['parents'] = {};
          }
          this.step1cams[0]['parents'][pid] = pid;
        }
      }
    });
  }

  onSaveCam(cam, quality) {
    const now = new Date();
    const offset = now.getTimezoneOffset() * -1;
    const utcTime = now.getTime() + (offset * 60 * 1000);
    const date = new Date(utcTime).toISOString();
    const qty = {quality, date};
    if (!cam['quality']) {
      cam['quality'] = [];
    } else {
      cam['quality'] = Object.values(cam['quality'])
    }
    cam['quality'].push(qty);
    this.is.setObjNoLog(`Topics/TempInfo/finalcams/${cam['key']}`, cam);

//    this.is.deleteObjNoLog(`Topics/TempInfo/step1cams/${cam['key']}`);

    this.curcams.splice(0, 1);
    this.clipped = null;
    this.location = '';
    this.ukey = '';
    this.weburl = '';
    this.googleurl = '';
    this.wikiurl = '';
    this.briturl = '';

    this.onFixCams();
  }


  onUpdateCam(i, action, quality?) {
    const cam = _.cloneDeep(this.curcams[i]);
    const clipped = _.cloneDeep(this.clipped);
    const location = this.location;
    const ukey = this.ukey;
    const weburl = this.weburl;
    const googleurl = this.googleurl;
    const wikiurl = this.wikiurl;
    const briturl = this.briturl;
    const kingdomvar = this.kingdomvar;
    const phylumvar = this.phylumvar;
    const classvar = this.classvar;

    console.log('curcam', cam);
    console.log('clipped', clipped);

    if (action === 1) {
      this.is.setObjNoLog(`Topics/TempInfo/someday/${cam['key']}`, cam);
      this.is.getFirstSnapKeys(2, `Topics/TempInfo/finalcams`, this.nextkey).take(1).subscribe(keys => {
        if (keys && keys.length === 2) {
          console.log('keys', keys)
          this.is.deleteObjNoLog(`Topics/TempInfo/finalcams/${this.nextkey}`);
          this.is.setObjNoLog(`Topics/TempInfo/nextkey`,keys[1])
          this.onCams();
        }
      });

    } else if (action === 2) {
      if (clipped && clipped.datapid) {
        if (!cam['parents']) {
          cam['parents'] = [];
        }
        cam['parents'][clipped.datapid] = clipped.datapid;
        this.is.getObject(`PlaceExists/${clipped.datapid}`).take(1).subscribe(exist => {
          if (exist) {
            this.onSaveCam(cam, quality);
          } else {
            // need to save the place
            this.onSaveClipped(clipped, location, weburl, wikiurl, briturl, googleurl, kingdomvar, phylumvar, classvar);
            this.onSaveCam(cam, quality);
          }
        });
      } else {
        this.onSaveCam(cam, quality);
      }

      if (cam['parents']) {
        const arr = Object.keys(cam['parents']);
        arr.forEach(a => {
          this.is.setObjNoLog(`Places/${a}/webcamz/${cam['key']}`, cam['key']);
        });
      }

    } else {
      this.is.deleteObjNoLog(`Topics/TempInfo/finalcams/${cam['key']}`);
      // todo: need to remove from places//webccamz

      this.curcams.splice(0, 1);
      this.onFixCams();
    }
  }


  onDeleteNote(i) {

  }

  onAddMessage() {
    this.curnote = new CappTeamMsg('Capptivation/Adventures/notes', 'Capptivation', 'capptivation-message', 'Adventures');
    console.log('curnote', this.curnote);

    this.notedetails = true;
  }

  onEditNote(note: CappTeamMsg, i: number) {
    this.curnote = this.notes[i];
    this.notedetails = true;

  }

  onSaveNote() {

    if (this.curnote && this.curnote.ukey) {
      this.is.setObjNoLog(`Capptivation/Adventures/notes/${this.curnote.ukey}`, this.curnote);
      console.log('curnote', this.curnote);
      this.notedetails = true;

      this.notedetails = false;
      this.curnote = null;
    }
  }

  onDelete(i) {
    this.is.deleteObjNoLog(`Topics/topics/${this.pubtopics[i].ukey}`);
    this.is.deleteObjNoLog(`Topics/subs/${this.pubtopics[i].ukey}`);
    this.is.deleteObjNoLog(`Topics/notifications/${this.pubtopics[i].ukey}`);
    this.pubtopics.splice(i, 1);
  }

  onAdd() {
    this.pos = -1;
    this.curtopic = new Topix('');
    this.curtopic.ukey = this.db.createPushId();
    this.details = true;
  }

  onEdit(i) {
    this.pos = i;
    this.curtopic = this.pubtopics[i];
    this.details = true;
  }

  onSave() {

    this.http.postAlgoliaEntry('Topics', this.curtopic, true);

    // this.is.setObjNoLog(`Topics/topics/${this.curtopic.ukey}`, this.curtopic);

    if (this.pos === -1) {
      this.pubtopics.push(this.curtopic);
    } else {
      this.pubtopics[this.pos] = this.curtopic;
    }
    this.pubtopics = _.orderBy(this.pubtopics, 'name', 'asc');

    this.details = false;
  }

  async onTransEl() {
    await this.languages.forEach(lang => {
      if (lang.abbr !== 'en') {
        this.admin.postTrans(lang.abbr, this.curtopic.description['en'].description).then(val => {
          this.curtopic.description[lang.abbr].description = val['data'].translations[0].translatedText;
        });

      }
    });

    setTimeout(() => {
      this.is.setObjNoLog(`Topics/topics/${this.curtopic.ukey}`, this.curtopic);
    }, 3000);

  }

  onAddEl() {
    this.elpos = -1;
    this.curel = new Element();
    this.detailsel = true;
  }

  onEditEl(i) {
    this.elpos = i;
    this.curel = this.elements[i];
    this.detailsel = true;
  }

  onSaveEl() {
    this.is.setObjNoLog(`Elements/${this.curel.code}`, this.curel);
    if (this.elpos === -1) {
      this.elements.push(this.curel);
    } else {
      this.elements[this.elpos] = this.curel;
    }
    this.elements = _.orderBy(this.elements, 'name', 'asc');
    this.detailsel = false;
  }

  onDeleteEl(i) {
    this.is.deleteObjNoLog(`Elements/${this.elements[i].code}`);
    this.elements.splice(i, 1);
  }

  onAddNotification() {

  }


  onSavePlaceOnly() {
    const saveaerial = {
      videoId: this.aerial['metadata']['videoId'],
      poster: this.aerial['uris']['IMAGE'],
    };
    this.curaerial['videoId']= this.aerial['metadata']['videoId']
    this.curaerial['poster']= this.aerial['uris']['IMAGE']
    delete this.curaerial['articleId']

    console.log('this.curaerial', this.curaerial)
    console.log('saveaerial', saveaerial)
    console.log('path1', `Topics/aerialviews/${this.curaerial.placeId}`)
    console.log('path2', `Places/${this.curaerial.placeId}/aerialview`)
    this.is.setObjNoLog(`Topics/aerialviews/${this.curaerial.placeId}`, this.curaerial);
    this.is.setObjNoLog(`Places/${this.curaerial.placeId}/aerialview`, saveaerial);

    this.onDeleteAerial();

  }

  // this function will delete an aerialtemp from an article because the video was not generated
  // if there are NO other aerialtemps it will delete the article from aerialcache
  onDeleteAerialView(view) {
    console.log('VIEW', view)
    this.is.getObject(`Topics/TempInfo/aerialcache/${view.articleId}`).take(1).subscribe(art => {
      if (art) {
        console.log('what up')
        const x = art.mentions.findIndex(men => men.ukey === view.placeId)
        if (x > -1) {
          delete art.mentions[x]['aerialtemp'];
        }

        console.log('art', art)

        this.curaerial = view;
        this.onDeleteAerial();

        let arv = false;
        if (art.mentions) {
          art.mentions.forEach(m => {
            if (m['aerialtemp']) {
              arv = true;
            }
          })
        }

        // if true there are more aeriels in the pipe so save back the article
        if (arv) {
          console.log('more aeriels')
          this.is.setObjNoLog(`Topics/TempInfo/aerialcache/${art.ukey}`, art);
        } else {
          // no ore aerials fo time to propogalte
          this.is.setObjNoLog(`Topics/TempInfo/guidescache/${art.ukey}`, art)
          this.is.deleteObjNoLog(`Topics/TempInfo/aerialcache/${art.ukey}`)
        }

      } else {
        console.log('Deleteing ????')
        this.curaerial = view;
        // this.aerialviews
        this.onDeleteAerial()
      }
    })
  }

  onSaveAerial() {
    this.is.getObject(`Topics/TempInfo/aerialcache/${this.curaerial.articleId}`).take(1).subscribe(art => {
      if (art) {
        const saveaerial = {
          videoId: this.aerial['metadata']['videoId'],
          poster: this.aerial['uris']['IMAGE'],
        };
        this.curaerial['videoId']= this.aerial['metadata']['videoId']
        this.curaerial['poster']= this.aerial['uris']['IMAGE']
        delete this.curaerial['articleId']

        console.log('this.curaerial', this.curaerial)
        console.log('saveaerial', saveaerial)

        console.log('path1', `Topics/aerialviews/${this.curaerial.placeId}`)
        console.log('path2', `Places/${this.curaerial.placeId}/aerialview`)
        this.is.setObjNoLog(`Topics/aerialviews/${this.curaerial.placeId}`, this.curaerial);
        this.is.setObjNoLog(`Places/${this.curaerial.placeId}/aerialview`, saveaerial);

        const x = art.mentions.findIndex(men => men.ukey === this.curaerial.placeId)
        if (x > -1) {
          delete art.mentions[x]['aerialtemp'];
          art.mentions[x]['aerialview'] = saveaerial
          console.log('art', art)
          console.log('art path', `Topics/TempInfo/aerialcache/${art.ukey}`)
        }
        this.onDeleteAerial();

        let arv = false;
        if (art.mentions) {
          art.mentions.forEach(m => {
            if (m['aerialtemp']) {
              arv = true;
            }
          })
        }

        // if true there are more aeriels in the pipe so save back the article
        if (arv) {
          this.is.setObjNoLog(`Topics/TempInfo/aerialcache/${art.ukey}`, art);
        } else {
          // no ore aerials fo time to propogalte
          this.is.setObjNoLog(`Topics/TempInfo/guidescache/${art.ukey}`, art)
          this.is.deleteObjNoLog(`Topics/TempInfo/aerialcache/${art.ukey}`)
        }
      } else {
        console.log('cant save ')
      }
    })
  }

  onDeleteAerial() {
    console.log('delete')
    this.is.deleteObjNoLog(`Topics/TempInfo/aerialview/${this.curaerial.placeId}`);
    const x = this.aerialviews.findIndex(v => v.placeId === this.curaerial.placeId);
    if (x > -1) {
      this.aerialviews.splice(x, 1);
    }
    this.curaerial = null;
    this.aerial = null;
  }

  onOpenMap() {
    console.log('curaerial', this.curaerial)
    window.open(`https://www.google.com/maps/place/?q=place_id:${this.curaerial.placeId}`, "_blank")

  }

  getAerial(view) {
    console.log('getting aeraial')
    this.aerial = null;
    this.curaerial = null;

    const apiUrl = `https://aerialview.googleapis.com/v1beta/videos?key=${environment.GOOGLE_MAPS_KEY}&videoId=${view.videoId}`;

    this.httpx.get(apiUrl).take(1).subscribe(
      response => {
        // Handle the response data
        this.aerial = response;
        this.curaerial = view;
        console.log('curaerial', this.curaerial);
        console.log('aeria', this.aerial);
        console.log('aerial', this.aerial.uris.MP4_MEDIUM.landscapeUri);
      },
      (error) => {
        // Handle any errors
        console.error(error);
      })
  }
}
