import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList, SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {InterfaceService, Topix, Wish} from '../services/interface.service';
import {MatBottomSheet} from '@angular/material';
import {RoehttpService} from '../services/roehttp.service';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'app-insta-guide',
  templateUrl: './insta-guide.component.html',
  styleUrls: ['./insta-guide.component.scss']
})
export class InstaGuideComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  curwish: Wish;

  @Input()
  fullsize = true;

  @Input()
  articleoff = false;

  curtopix: Topix;
  flipit = false;
  backcontent: string;
  about = '';
  error = false;
  paid = false;
  icon = '';
  banner = '';

  kind = 'READ';
  isVideo = false;
  arrowwd = 0;
  arrowht = 0;

  full = true;
  share = false;

  toemail = '';
  yourname = '';
  msg = '';
  errormsg = '';


  @ViewChild('content', {static: false}) elementView: ElementRef;
  @ViewChild('contentback', {static: false}) elementViewBack: ElementRef;
  @ViewChildren('fullcard', {read: ElementRef}) fullcardlist: QueryList<ElementRef>;

  constructor(private cd: ChangeDetectorRef,
              private bottomSheet: MatBottomSheet,
              private http: RoehttpService,
              private admin: AdminService,
              private is: InterfaceService) {
  }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.curwish) {
      this.updateWish();
    }
  }

  updateWish() {
    this.onFixFormat();

    this.onFixFormat();

    this.paid = this.curwish['paid'];

    if (this.curwish.types) {
      this.curwish.types.forEach(t => {
        if (t === 'Paid') {
          this.paid = true;
        }

        if (t === 'Video') {
          this.icon = 'play_circle';
        } else if (t === 'Podcast') {
          this.icon = 'podcasts';
        }

        if (t === 'Deal') {
          this.banner = 'Deal! Deal! Deal!';
        } else if (t === 'Event') {
          this.banner = 'Event';
        }
      });
    }

    if (this.curwish.trip && this.curwish.trip.length) {
      this.curwish.trip.forEach(t => {
        if (t.aboutit) {
          this.about = t.ukey;
        }
      });
    }

    if (!this.about && this.curwish.mentions && this.curwish.mentions.length) {
      this.curwish.mentions.forEach(t => {
        if (t.aboutit) {
          this.about = t.ukey;
        }
      });
    }

    this.onCalcHt();
    this.cd.detectChanges();

  }

  ngAfterViewInit(): void {
    this.onCalcHt();
  }


  onShare() {
    this.toemail = '';
    this.yourname = '';
    this.msg = '';
    this.errormsg = '';
    this.share = true;
    this.flipit = !this.flipit;
    console.log('share');
  }

  onCalcHt(): number {
    let ht;

    if (!this.curwish['format'] && this.full && this.fullcardlist && this.fullcardlist.last && this.fullcardlist.last.nativeElement) {
      if (this.isVideo) {
        this.arrowwd = this.fullcardlist.last.nativeElement.offsetWidth / 2 - 40;
        const rect = this.fullcardlist.last.nativeElement.getBoundingClientRect();
        if (rect) {
          this.arrowht = (rect.height / 2) - 40;
        } else {
          this.arrowht = 150;
        }
      }

      ht = this.fullcardlist.last.nativeElement.offsetHeight;
      if (this.elementViewBack.nativeElement) {
        const x = this.elementViewBack.nativeElement.offsetHeight;

        if (x > ht) {
          ht = x;
        }
      }
    }
    return ht;
  }

  onLoad(event) {
    this.curwish['ht'] = this.onCalcHt();
    this.cd.detectChanges();
  }

  onDelete() {
    console.log('delete card');
  }

  onError(event) {
    this.error = true;
    this.curwish['ht'] = this.onCalcHt();
  }

  onFixFormat() {
    this.kind = 'READ';
    this.isVideo = false;
    if (this.curwish.ty === 'podcast') {
      this.kind = 'LISTEN';
    } else if (this.curwish.ty === 'vlog') {
      this.isVideo = true;
      this.kind = 'WATCH';
    } else if (this.curwish.m && !this.curwish.m.inframe) {
      this.kind = 'OPEN';
    }
  }

  resetError() {
    this.curwish['ht'] = 400;
    this.error = false;
  }

  onSendShare() {
    // todo: is toemail a real email address
    // are all the fields filled out?
    if (!this.yourname || this.yourname.length < 2) {
      this.errormsg = 'Please tell us your name.';
    } else if (!this.toemail || !this.admin.validateEmail(this.toemail)) {
      this.errormsg = 'Please enter a valid email address.';
    } else {
      // todo: eventually add a feature that connects this article to the reciever's capptivation account
      const obj = {
        deviceid: '1007442',
        email: this.toemail,
        from: this.yourname,
        subject: `${this.yourname} would like to share a travel-related article with you.`,
        publisher: this.curwish.cn,
        title: this.curwish.ssoc.en.description,
        imageurl: this.curwish.e,
        msg: this.msg,
        link: this.curwish.l,
      };
      this.http.shareArticle(obj).take(1)
        .subscribe(res => {
          if (res && res.status === 200) {
            this.toemail = '';
            this.msg = '';
          } else {

          }
        });
    }

  }

  onCancelShare() {
    this.share = false;
    this.flipit = !this.flipit;
  }

  onFlip(str) {
    this.backcontent = str;
    if (this.backcontent === 'blog') {
      if (this.curwish.g === 'Travelmags') {
        console.log('curgggg', this.curwish.g);
      } else {
        if (!this.articleoff && this.curwish.cf !== 'random') {
          if (!this.curtopix) {
            this.is.getObject(`Institutions/${this.curwish.cf}/obj`).take(1).subscribe(profile => {
              this.curtopix = profile;
            });
          }
          this.flipit = !this.flipit;
          this.cd.detectChanges();
        }
      }
    } else {
      this.flipit = !this.flipit;
      this.cd.detectChanges();
    }
  }

}
