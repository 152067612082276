import {Injectable} from '@angular/core';
/*
import * as GeoFire from 'geofire';
*/
import {GeoFire} from '../geofire/geoFire';
import {BehaviorSubject, Observable} from 'rxjs';
import {FirebaseService} from './firebase.service';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class GeofireService {

  dbRef: any;
  geoFire: any;
  geoQuery: any;
  public ar: any;

  hits = new BehaviorSubject([]);

  constructor(private fs: FirebaseService) {
    /// Reference database location for GeoFire
    if (this.fs.titleIXdb) {
      this.getDB();
    }
  }

  getDB() {
    // todo: try this: this.dbRef = firebase.database().ref('Locations/')
    this.dbRef = this.fs.titleIXdb.list('Locations/');
    this.geoFire = new GeoFire(this.dbRef.query.ref);
  }

  setGlobal(key: string, coords: Array<number>) {
    const dbRef = this.fs.titleIXdb.list('Global/');
    const geoFire = new GeoFire(dbRef.query.ref);
    geoFire.set(key, coords)
      .then(_ => console.log('location updated'))
      .catch(err => console.log('err', err));
  }

  /// Adds GeoFire data to database
  setLocation(key: string, coords: Array<number>) {
    if (!this.dbRef) {
      this.getDB();
    }
    this.geoFire.set(key, coords)
      .then(_ => console.log('location updated'))
      .catch(err => console.log('err', err));
  }


  /// Queries database for nearby locations
  /// Maps results to the hits BehaviorSubject
  getLocations(radius: number, coords: Array<number>) {
    if (!this.dbRef) {
      this.getDB();
    }
    this.ar = new Array();

    this.geoQuery = this.geoFire.query({
      center: coords,
      radius: radius
    });

    this.geoQuery.on('key_entered', (key, location, distance) => {
      let hit = {
        location: location,
        distance: distance,
        ukey: key,
        resourcelocation: 'Community.Community;' + key,
        format: 0
      };

      let currentHits = this.hits.value;
      console.log('hits', currentHits);
      this.ar.push(hit);
      currentHits.push(hit);
      this.hits.next(currentHits);
    });


  }


  updateRadius(radius) {
    if (!this.dbRef) {
      this.getDB()
    }

    this.geoQuery.updateCriteria({
      radius: radius
    });
  }

  distance(lat1, lon1, lat2, lon2) {
    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat2 - lat1) * p) / 2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p)) / 2;

    return Math.round(12742 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km
  }

}
