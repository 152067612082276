import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/auth';
import {Subscription} from 'rxjs/internal/Subscription';
import {FirebaseSyncService} from '../services/firebase-sync.service';

import {User, UserInfo} from 'firebase';
import * as firebase from 'firebase/app';
import UserCredential = firebase.auth.UserCredential;
import {InterfaceService} from '../../services/interface.service';
import {FirebaseService} from '../../services/firebase.service';
import {Admin} from '../../services/admin.service';
import {RoehttpService} from '../../services/roehttp.service';


export const EMAIL_REGEX = new RegExp(['^(([^<>()[\\]\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\.,;:\\s@\"]+)*)',
  '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
  '[0-9]{1,3}\])|(([a-zA-Z\\-0-9]+\\.)+',
  '[a-zA-Z]{2,}))$'].join(''));

export const PHONE_NUMBER_REGEX = new RegExp(/^\+(?:[0-9] ?){6,14}[0-9]$/);

@Component({
  selector: 'cap-ro-signin',
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss']
})

export class AuthComponent implements OnInit, OnDestroy {
  @Input()
  loc = 'Orgs';

  @Input()
  firebaseroot: string;

  @Input()
  guestEnabled = true;

  @Output()
  onSuccess = new EventEmitter();

  @Output()
  onComplete = new EventEmitter<Admin>();

  @Output()
  onSignInSuccess = new EventEmitter<string>();

  passwordResetWished: any;
  isLoading: boolean;
  register = false;
  emailConfirmationSent: boolean;
  emailToConfirm: string;
  admin: Admin;
  spinner = false;

  public signInFormGroup: FormGroup;
  public signUpFormGroup: FormGroup;
  public resetPasswordFormGroup: FormGroup;

  onErrorSubscription: Subscription;

  passReset = false;
  authenticationError = false;

  signInEmailFormControl: AbstractControl;
  sigInPasswordFormControl: AbstractControl;

  sigUpNameFormControl: AbstractControl;
  sigUpEmailFormControl: AbstractControl;
  sigUpPasswordFormControl: AbstractControl;
  sigUpPasswordConfirmationFormControl: AbstractControl;
  resetPasswordEmailFormControl: AbstractControl;

  nameoforg: string;
  yourname: string;
  authorized = false;
  email: string;
  mobile: string;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private _fs: FirebaseSyncService,
              private http: RoehttpService,
              private db: FirebaseService,
              private is: InterfaceService,
              public authx: AngularFireAuth) {
  }

  public ngOnInit(): void {
    this._initSignInFormGroupBuilder();
    this._initSignUpFormGroupBuilder();
    this._initResetPasswordFormGroupBuilder();
  }

  onInstRegister() {
    console.log('inst Register');
  }

  public ngOnDestroy(): void {
  }


  get color(): string {
    return this.authenticationError ? 'warn' : 'primary';
  }

  public resetPassword() {
    this.resetPasswordftn(this.resetPasswordEmailFormControl.value)
      .then(() => this.passReset = true);
  }

  public resetPasswordftn(email: string) {
    return this.authx.auth.sendPasswordResetEmail(email)
      .then(() => console.log('email sent'))
      .catch((error) => console.log(error));
  }


  private _initSignInFormGroupBuilder() {
    this.signInFormGroup = new FormGroup({});
    this.signInFormGroup.registerControl('email', this.signInEmailFormControl = new FormControl('',
      [
        Validators.required,
        Validators.pattern(EMAIL_REGEX)
      ]));
    this.signInFormGroup.registerControl('password', this.sigInPasswordFormControl = new FormControl('',
      [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(25),
      ]));
  }

  private _initSignUpFormGroupBuilder() {
    this.signUpFormGroup = new FormGroup({
      name: this.sigUpNameFormControl = new FormControl('',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
        ]),
      email: this.sigUpEmailFormControl = new FormControl('',
        [
          Validators.required,
          Validators.pattern(EMAIL_REGEX)
        ]),
      password: this.sigUpPasswordFormControl = new FormControl('',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(25),
        ])
    });
  }

  private _initResetPasswordFormGroupBuilder() {
    this.resetPasswordFormGroup = new FormGroup({
      email: this.resetPasswordEmailFormControl = new FormControl('',
        [
          Validators.required,
          Validators.pattern(EMAIL_REGEX)
        ])
    });
  }


  onMarketingSignIn() {
    this._fs.getQuery('Marketing', 'email', this.signInFormGroup.value.email.toLowerCase()).subscribe(i => {
      if (i.length === 0) {
        alert('Not a valid account, please try again');
        return;
      } else {
        this.signInWith(this.signInFormGroup.value.email,
          this.signInFormGroup.value.password);
      }
    });
  }


  onSubmit() {
    if (this.firebaseroot === 'Individual') {
      // todo: add this back if needed
      /*
                  this._fs.getObject(`Individual/${this.signUpFormGroup.value.name}/ukey`).take(1).subscribe(val => {
                      if (val) {
                          alert('This username is already being used, please try another name');
                          return false;
                      } else {
                          return this.signUp(this.signUpFormGroup.value.name,
                              this.signUpFormGroup.value.email,
                              this.signUpFormGroup.value.password);
                      }
                  });
      */
    } else {
      this._fs.getQuery('MarketingTemp', 'email', this.signInFormGroup.value.email.toLowerCase()).take(1).subscribe(ad => {
        if (ad.length > 0 && ad[0].password === this.signInFormGroup.value.password && !ad[0].firebaseuid) {
          this.admin = ad[0];
          this.signUp(this.signInFormGroup.value.name,
            this.signInFormGroup.value.email,
            this.signInFormGroup.value.password);
        } else {
          alert('You are not an authorized user.');
          return false;
        }
      });


    }
  }

  onEmailSuccess(event) {
    if (this.firebaseroot === 'Individual') {
      const s = 'I:' + this.signUpFormGroup.value.name;
      this.is.setObjNoLog(`tmp/${this.authx.auth.currentUser.uid}`, s);
      this.onSuccess.emit();
    } else {
      this.is.getObject(`Marketing/${this.authx.auth.currentUser.uid}`)
        .take(1)
        .subscribe(ad => {

          let s;
          if (ad.type === 'Colleges') {
            s = 'O:' + ad.ukey + ':' + ad.role;
          } else if (ad.type === 'Community') {
            s = 'C:' + ad.ukey + ':' + ad.role;
          } else if (ad.type === 'Security') {
            s = 'S:' + ad.ukey + ':' + ad.role;
          }
          ad.orgs.forEach(a => {
            s = s + ':' + a.organ;
          });
          this.is.setObjNoLog(`tmp/${this.authx.auth.currentUser.uid}`, s);

        });

      this.onSuccess.emit();
    }

  }

  public async signInWith(email?: string, password?: string) {
    try {
      this.isLoading = true;
      let signInResult: UserCredential;
      signInResult = await this.authx.auth.signInWithEmailAndPassword(email, password) as UserCredential;
      console.log('sinin Result', signInResult)
      await this.handleSignInSuccess(signInResult);
    } catch (err) {
      this.onSubmit();
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  public async signUp(name: string, email: string, password: string) {
    let user;
    try {
      this.spinner = true;
      this.isLoading = true;
      const userCredential: UserCredential = await this.authx.auth.createUserWithEmailAndPassword(email, password);
      user = userCredential.user;
      await user.updateProfile({
        displayName: name,
        photoURL: ''
      });

    } catch (err) {
      this.spinner = false;
      this.handleError(err);
    } finally {
      this.isLoading = false;
      if (this.admin) {
        this.admin.firebaseuid = user.uid;
        const ukey = this.admin.ukey;
        this.admin.ukey = user.uid;
        this.http.updateMarketing(this.admin, ukey)
          .then(val => {
            this.spinner = false;
            this.onComplete.emit(this.admin);
          }).catch(err => {
            this.spinner = false;
            console.log('update marketing error', err)
        });
      } else {
        this.spinner = false;
        this.onSuccess.emit();
      }
    }
  }

  public async signUpIndiv(name: string, email: string, password: string) {
    try {
      this.isLoading = true;
      const userCredential: UserCredential = await this.authx.auth.createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      await user.updateProfile({
        displayName: name,
        photoURL: ''
      });

      await user.sendEmailVerification();
      this.emailConfirmationSent = true;
      this.emailToConfirm = email;

      await this.handleSuccess(userCredential);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.isLoading = false;
    }
  }

  async handleSignInSuccess(userCredential: UserCredential) {
    this.onSignInSuccess.emit(this.signInFormGroup.value.email);
  }

  async handleSuccess(userCredential: UserCredential) {
    /*
            this.onSuccess.emit();
    */
  }

  handleError(error: any) {
    this.authenticationError = true;
    console.error(error);
  }
}
