import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  Admin, AdminService, Adminy, ComModules, ComPerson, ComPersonDetails, Module, OrgModules,
  PeopleModules
} from '../../services/admin.service';
import {FirebaseService} from '../../services/firebase.service';
import {BehaviorSubject} from 'rxjs';
import * as _ from 'lodash';
import {InterfaceService} from '../../services/interface.service';
import {MatDialog} from '@angular/material';
import {ConfirmationComponent} from '../../lib/confirmation/confirmation.component';
import {AdminTypes} from '../../services/constants';
import * as firebase from 'firebase/app';
import {environment} from '../../../environments/environment';
import UserCredential = firebase.auth.UserCredential;
import {AngularFireAuth} from '@angular/fire/auth';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent implements OnInit, OnDestroy {
  admindetails: boolean;
  orgdetails: boolean;
  admins: Admin[];
  newadmin: Admin;
  newadminy: Adminy;
  newindivadmin: ComPerson;
  admintypes = AdminTypes;
  supertype = 'Organization';
  roles = ['admin', 'adminjr'];
  adminsupertypes = ['Organization', 'Individual'];
  admintype = 'Colleges';
  peoplemodules = PeopleModules;

  anymodules: any;
  fb;
  delorgs;

  pos: number;
  orgslist: any[];
  oOrgType: any;
  $sub1: any;
  $sub2: any;
  $sub3: any;
  uid: string;
  startnum = 1005216;

  batch: any[];


  org: BehaviorSubject<string | null> = new BehaviorSubject('');
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  endAt: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');

  morgslist: any[];
  mControl: FormControl;
  mOrgType: any;
  morg: BehaviorSubject<string | null> = new BehaviorSubject('');
  mstartAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  mendAt: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');


  subform = new FormGroup({
    oControl: new FormControl(''),
  });

  get oControl() {
    return this.subform.get('oControl');
  }

  indivform = new FormGroup({
    indivfirstname: new FormControl(''),
    indivmname: new FormControl(''),
    indivlastname: new FormControl(''),
    indivzname: new FormControl(''),
    indivemail: new FormControl(''),
  });


  form = new FormGroup({
    type: new FormControl('', Validators.required),
    role: new FormControl(''),
    name: new FormControl(''),
    email: new FormControl(''),
    client: new FormControl(''),
    password: new FormControl(''),
    deviceid: new FormControl('')
  });

  get password() {
    return this.form.get('password');
  }

  get type() {
    return this.form.get('type');
  }

  get name() {
    return this.form.get('name');
  }

  get role() {
    return this.form.get('role');
  }

  get email() {
    return this.form.get('email');
  }

  get client() {
    return this.form.get('client');
  }

  get deviceid() {
    return this.form.get('deviceid');
  }

  get indivfirstname() {
    return this.indivform.get('indivfirstname');
  }

  get indivmname() {
    return this.indivform.get('indivmname');
  }

  get indivlastname() {
    return this.indivform.get('indivlastname');
  }

  get indivzname() {
    return this.indivform.get('indivzname');
  }

  get indivemail() {
    return this.indivform.get('indivemail');
  }

  constructor(private as: AdminService,
              private auth: AngularFireAuth,
              private http: HttpClient,
              private dialog: MatDialog,
              private is: InterfaceService,
              private titleIXdb: FirebaseService) {
    this.admindetails = false;
    this.orgdetails = false;
    this.mControl = new FormControl('');
    this.delorgs = new Array();

  }

  getAdmins() {
    if (this.$sub3) {
      this.$sub3.unsubscribe();
    }
    this.$sub3 = this.is.getQuery('Marketing', 'type', 'Colleges').subscribe(ms => {
      this.admins = ms;
    });
  }

  ngOnInit() {
    console.log('what the heck')
    this.fb = firebase.initializeApp(environment.firebaseTitleIXConfig, 'Secondary');
    this.getAdmins();
  }

  setValue(tmp) {
    this.form.setValue({
      type: tmp.type,
      subtype: '',
      role: tmp.role,
      name: tmp.name,
      email: tmp.email,
      password: '',
      client: tmp.client,
      deviceid: -1,
      indivukey: ''
    });
  }

  setIndivValue(tmp) {
    this.indivform.setValue({
      indivfirstname: tmp.fname,
      indivmname: tmp.mname,
      indivlastname: tmp.lname,
      indivzname: tmp.zname,
      indivemail: tmp.email,
      indivcity: tmp.city,
      indivstate: tmp.state
    });
  }

  onAddAdmin() {
    if (this.supertype === 'Organization') {
      this.newadmin = new Admin();
      this.newadmin = {
        name: '',
        ukey: '',
        firebaseuid: '',
        email: '',
        orgemail: '',
        type: '',
        subtype: '',
        orgname: '',
        role: 'adminjr',
        client: false,
        deviceid: -1,   // todo: how associate an admin's deviceid with this record?
        org: '',
        modules: new Array(),
        orgs: new Array(),
        password: '',
        indivukey: '',
        empid: []
      };
      this.setValue(this.newadmin);
    }


    if (this.supertype === 'Individual') {
      this.newindivadmin = new ComPerson();
      const details = new ComPersonDetails();
      details.fname = '';
      details.mname = '';
      details.lname = '';
      details.zname = '';
      details.deviceid = '';
      details.modules = this.peoplemodules;

      /* todo: need to fix this
                  this.newindivadmin = {
                      ukey: '',
                      details: details
                  };
      */

      this.setIndivValue(this.newindivadmin);

    }


    this.admindetails = true;
  }

  onEditAdmin(i) {
    if (this.supertype === 'Organization') {
      this.newadmin = this.admins[i];
      this.setValue(this.newadmin);
      this.admindetails = true;
    }

    if (this.supertype === 'Individual') {
      // todo: need to wire this us
      /*
                  this.newindivadmin = this.admins[i];
                  this.setIndivValue(this.newindivadmin);
                  this.admindetails = true;
      */
    }
  }

  onDeleteAdmin(i) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: {text: 'Are you sure you want to delete this admin?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.answer) {
        if (this.supertype === 'Organization') {
          let str = '';
          if (this.admins[i].type === 'Community') {
            str = 'Community';
          } else {
            str = 'Orgs';
          }
          this.admins[i].orgs.forEach(o => {
            this.is.deleteObjNoLog(`${str}/${o.organ}/${this.admins[i].role}/${this.admins[i].firebaseuid}`);
          });
          this.is.deleteObjNoLog(`Marketing/${this.admins[i].ukey}`);
        }

        this.mControl.setValue('');
      }
    });
  }

  mupdateorgslist(sc) {
    this.mOrgType = _.find(this.morgslist, {'ukey': sc});
    this.morg.next(sc);
  }

  msearch() {
    if (this.mControl.value.length === 1) {
      this.mControl.setValue(this.mControl.value.toLowerCase());
    }
    const q = this.mControl.value;
    this.mstartAt.next(q);
    this.mendAt.next(q + '\uf8ff');
  }


  updateorgslist(sc) {
    this.oOrgType = _.find(this.orgslist, {'name': sc});
    this.org.next(sc);
  }

  search() {
    if (this.oControl.value.length === 1) {
      this.oControl.setValue(this.oControl.value.toUpperCase());
    }
    const q = this.oControl.value;
    this.startAt.next(q);
    this.endAt.next(q + '\uf8ff');
  }

  onChangeSupertype() {
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
    this.mControl.setValue('');

    if (this.supertype === 'Organization') {
      this.$sub2 = this.is.getBehaviorList(this.mstartAt, this.mendAt, 'Marketing').subscribe(coms => {
          this.morgslist = coms.filter(c => c.role === 'admin');
        }
      );
    }
  }


  onAddOrg() {
    this.$sub1 = this.is.getBehaviorList(this.startAt, this.endAt, `OrgsByType/${this.type.value}`).subscribe(coms => {
        this.orgslist = coms;
      }
    );

    this.pos = -1;
    const tmp = this.type.value;
    if (tmp === 'Community') {
      this.anymodules = ComModules;
    }
    if (tmp === 'Colleges') {
      this.anymodules = OrgModules;
    }

    this.newadminy = new Adminy;
    this.newadminy.modules = new Array();
    this.orgdetails = true;
  }

  onDeleteOrg(i) {
    // Todo: need to ask if they are sure
    this.delorgs.push(this.newadmin.orgs[i].organ);
    this.newadmin.orgs.splice(i, 1);

  }


  onCancelOrg() {
    this.oControl.setValue('');
    this.$sub1.unsubscribe();
    this.orgdetails = false;
  }

  onEditOrg(i) {
    this.pos = i;
    const tmp = this.type.value;

    this.$sub1 = this.is.getBehaviorList(this.startAt, this.endAt, `OrgsByType/${this.type.value}`)
      .subscribe(coms => {
          this.orgslist = coms;
        }
      );


    if (tmp === 'Community') {
      this.anymodules = ComModules;
    } else if (tmp === 'Colleges') {
      this.anymodules = OrgModules;
    } else {
      this.anymodules = OrgModules;
    }
    const fin = this.anymodules.length;
    for (let j = 0; j < fin; j++) {
      this.anymodules[j].value = this.newadmin.orgs[i].modules[j].val;
    }
    this.orgdetails = true;
    this.oControl.setValue(this.newadmin.orgs[i].organ);
  }

  onSaveOrg() {
    if (this.pos > -1) {
      this.newadmin.orgs.splice(this.pos, 1);
      this.newadminy = new Adminy();
      this.newadminy.modules = new Array();
    }

    if (!this.newadmin.orgs) {
      this.newadmin.orgs = new Array();
    }

    this.newadminy.organ = this.oControl.value;
    this.anymodules.forEach(a => {
      const tmp = new Module();
      tmp.name = a.name;
      tmp.code = a.code;
      if (a.value) {
        tmp.val = true;
      } else {
        tmp.val = false;
      }
      this.newadminy.modules.push(tmp);
    });
    this.newadmin.orgs.push(this.newadminy);
    this.oControl.setValue('');
    this.orgdetails = false;
  }

  onCancel() {
    this.admindetails = false;
  }

  async getUID() {
    const userCredential: UserCredential = await this.fb.auth().createUserWithEmailAndPassword(this.email.value.toLowerCase(), this.password.value);
    this.uid = userCredential.user.uid;
    console.log('uid made', this.uid);
  }

  getUid() {
    if (!this.name.value || !this.email.value || !this.password.value) {

    } else {
      const url = 'https://us-central1-library-titleix.cloudfunctions.net/postFindIndivUkeyIdonly';

      this.auth.auth.currentUser.getIdToken()
        .then(authToken => {
          const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
          return this.http.post(url,
            {
              id: this.email.value.toLowerCase(),
            },
            {headers: headersv}).toPromise();
        })
        .then(res => {
          // returns indivukey if the person alreaady in the sysem
          if (res && res['indivukey']) {
            console.log('indivumey', res);
            this.deviceid.setValue(res['deviceId']);
            this.getUID();

          } else {
            // no indivukey - so before can proceed must create one.
            this.getUID();
          }
        });


    }
  }

  onSaveContinue() {
    this.newadmin.name = this.name.value;
    this.newadmin.email = this.email.value.toLowerCase();
    this.newadmin.type = this.type.value;
    this.newadmin.client = this.client.value;
    this.newadmin.role = this.role.value;
    this.newadmin.firebaseuid = this.uid;
    this.newadmin.password = this.password.value;
    this.newadmin.deviceid = this.deviceid.value;

    if (!this.newadmin.ukey) {
      this.newadmin.ukey = this.titleIXdb.titleIXdb.createPushId();
    }

    let str = '';
    let str1 = '';
    if (this.newadmin.type === 'Community') {
      str = 'Community';
      str1 = 'ComPri';
    } else {
      str = 'OrgsPri';
      str1 = 'OrgsPri';
    }

    console.log('newadmin', this.newadmin);
    if (this.newadmin.firebaseuid && this.newadmin.orgs) {
      this.newadmin.orgs.forEach(org => {
        this.is.setObjNoLog(`${str}/${org.organ}/marketing/${this.newadmin.ukey}`, this.newadmin);
        this.is.setObjNoLog(`${str1}/${org.organ}/marketing/${this.newadmin.ukey}`, this.newadmin);

        this.is.setObjNoLog(`${str}/${org.organ}/${this.newadmin.role}/${this.newadmin.firebaseuid}`, this.newadmin.ukey);
        this.is.setObjNoLog(`${str1}/${org.organ}/${this.newadmin.role}/${this.newadmin.firebaseuid}`, this.newadmin.ukey);
      });
    }

    if (this.newadmin.firebaseuid && this.delorgs) {
      this.delorgs.forEach(org => {
        this.is.deleteObjNoLog(`${str}/${org}/${this.newadmin.role}/${this.newadmin.firebaseuid}`);
        this.is.deleteObjNoLog(`${str1}/${org}/${this.newadmin.role}/${this.newadmin.firebaseuid}`);
      });
    }

    console.log('amanda', `Marketing/${this.newadmin.ukey}`)
    console.log('new admin', this.newadmin)
    this.is.setObjNoLog(`Marketing/${this.newadmin.ukey}`, this.newadmin);
  }

  onSave() {
    console.log('supertype', this.supertype)
    if (this.supertype === 'Organization') {
      this.onSaveContinue();
    }

    if (this.supertype === 'Individual') {
      this.newindivadmin.details.fname = this.indivfirstname.value;
      this.newindivadmin.details.mname = this.indivmname.value;
      this.newindivadmin.details.lname = this.indivlastname.value;
      this.newindivadmin.details.zname = this.indivzname.value;
      this.newindivadmin.details.email = this.indivemail.value;
      // todo: need to use an http function to create this individual
      // this.is.setObjNoLog(`Individual/${this.newindivadmin.ukey}/details`, this.newindivadmin.details);
    }
    this.admindetails = false;

  }


  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
    if (this.$sub3) {
      this.$sub3.unsubscribe();
    }
  }

  toString(b): string {
    return JSON.stringify(b);
  }

  onDelete(i) {
    if (this.batch[i] && this.batch[i].deviceId > 100000) {
      console.log('delete', this.batch[i].deviceId);
      console.log('delete path', `ROEDevices/${this.batch[i].deviceId}`);
      this.is.deleteObjNoLog(`ROEDevices/${this.batch[i].deviceId}`);
      this.batch.splice(i, 1);
    }
  }

  getBatch() {
    this.batch = null;
    console.log('startnum', this.startnum.toString());
    this.is.getQueryStart('ROEDevices', this.startnum.toString(), 100)
      .take(1)
      .subscribe(batch => {
        this.batch = batch;
        this.startnum = this.startnum + 10;
      });
  }
}
