import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AdminService, ComModules, CorpModules} from '../../services/admin.service';
import {Languages} from '../../services/constants';
import {BehaviorSubject} from 'rxjs';
import {FirebaseService} from '../../services/firebase.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {GeofireService} from '../../services/geofire.service';
import {RoehttpService} from '../../services/roehttp.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
  BlockString,
  ComOrg,
  LocationData,
  InterfaceService,
  LangStringBlock, Links,
  OrgLegacy,
  OrgLegacyExtra
} from '../../services/interface.service';

@Component({
  selector: 'app-corp',
  templateUrl: './corp.component.html',
  styleUrls: ['./corp.component.scss']
})
export class CorpComponent implements OnInit, OnDestroy {
  oControl: FormControl;
  pControl: FormControl;
  orgslist;
  curOrg;
  corpmodules = CorpModules;
  $sub1;
  languages = Languages;


  orgpicked: boolean;

  mode: boolean;
  corp: string;
  tmpOrg;

  org: BehaviorSubject<string | null> = new BehaviorSubject('');
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  endAt: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');

  form = new FormGroup({
    ukey: new FormControl(''),
    type: new FormControl(''),
  });

  get ukey() {
    return this.form.get('ukey');
  }

  get type() {
    return this.form.get('type');
  }

  constructor(private titleIXdb: FirebaseService,
              public authx: AngularFireAuth,
              private router: Router,
              private geo: GeofireService,
              private http: RoehttpService,
              private htp: HttpClient,
              private is: InterfaceService,
              private admin: AdminService) {
    this.oControl = new FormControl();
    this.pControl = new FormControl();
    this.orgpicked = false;
    this.corp = '';
  }


  ngOnInit() {
    this.$sub1 = this.is.getBehaviorList(this.startAt, this.endAt, 'OrgsByType/Corp/')
      .subscribe(orgs => {
        this.orgslist = orgs;
      });
  }

  updateorgslist(sc) {
    this.org.next(sc);
    let obj;
    this.titleIXdb.titleIXdb.list<any[]>('OrgsByType/Corp', ref => {
      return ref.orderByChild('ukey').equalTo(sc);
    })
      .valueChanges().subscribe(data => {
      obj = data[0];
      this.curOrg = <ComOrg> obj;
      if (this.curOrg) {
        this.is.getObjectFS(`CorpsPri/${this.curOrg.ukey}`).take(1).subscribe(org => {

          this.tmpOrg = org;
        });

        this.corp = this.curOrg.ukey;
      }
    });
  }

  search() {
    if (this.oControl.value.length === 1) {
      this.oControl.setValue(this.oControl.value.toUpperCase());
    }
    const q = this.oControl.value;
    this.startAt.next(q);
    this.endAt.next(q + '\uf8ff');
  }

  updateparentlist(sc) {
    this.org.next(sc);
    let obj;
    this.titleIXdb.titleIXdb.list<any[]>('OrgsByType/Corp', ref => {
      return ref.orderByChild('ukey').equalTo(sc);
    })
      .valueChanges().subscribe(data => {
      obj = data[0];
      this.tmpOrg.parentkey = obj.ukey;

    });
  }

  searchparent() {
    if (this.pControl.value.length === 1) {
      this.pControl.setValue(this.pControl.value.toUpperCase());
    }
    const q = this.pControl.value;
    this.startAt.next(q);
    this.endAt.next(q + '\uf8ff');
  }

  setValue(tmp) {
    this.form.setValue({
      ukey: tmp.ukey,
    });
  }

  onEditOrg() {
    this.mode = false;
    this.setValue(this.tmpOrg);
    this.orgpicked = true;
  }

  onDelOrg() {
    this.orgpicked = false;
    const ukey = this.curOrg.ukey;
    this.oControl.setValue('');
    this.corp = '';
    console.log('delete NOT IMPLEMENTED');

  }

  onAddOrg() {
    this.mode = true;
    this.tmpOrg = {};
    this.tmpOrg.ukey = '';
    this.tmpOrg.name = '';
    this.setValue(this.tmpOrg);
    this.orgpicked = true;

  }

  onSelectOrg() {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROEMarketing';

    this.authx.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
      return this.htp.post(url,
        {
          or: this.curOrg.ukey,

        },
        {headers: headersv}).toPromise();
    })
      .then(res => {
        console.log(res);
        this.is.onSelectOrg(this.curOrg.ukey, this.tmpOrg);
        this.router.navigateByUrl('corpparent');

      });
    // this.is.setObjNoLog(`Marketing/-LPgtb9Y2W3QT3WyeNbd/orgs/0/organ`, this.curOrg.ukey);

  }

  onCancel() {
    this.orgpicked = false;
  }

  onDone() {
    this.orgpicked = false;
  }


  onSave() {
    this.tmpOrg.ukey = this.ukey.value;
    this.tmpOrg.profile['type'] = this.type.value
    const pth = `CorpsPri/${this.tmpOrg.ukey}`;
    this.is.setObjNoLog(pth, this.tmpOrg);
    this.is.setObjNoLog(`OrgsByType/Corp/${this.tmpOrg.ukey}`, {'ukey': this.tmpOrg.ukey});
    this.orgpicked = false;
  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
  }

}
