import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Asset, InterfaceService} from '../../../services/interface.service';
import {AdminService} from '../../../services/admin.service';
import * as _ from 'lodash';
import {Languages} from '../../../services/constants';
import {InstService} from '../../../services/inst.service';
import {OrganizationService} from '../../../services/organization.service';
import {MatBottomSheet} from '@angular/material';
import {ArchivePickComponent} from '../archive-pick/archive-pick.component';

@Component({
  selector: 'app-archive-widget',
  templateUrl: './archive-widget.component.html',
  styleUrls: ['./archive-widget.component.scss']
})
export class ArchiveWidgetComponent implements OnInit {
  @Input()
  returnasset = false;

  @Input()
  asset: Asset;

  @Input()
  fname: string;

  @Input()
  sznote: string;

  @Input()
  loc: string;

  @Input()
  type: string;

  @Input()
  title: string;

  @Output()
  filename = new EventEmitter<string>();

  @Output()
  assets = new EventEmitter<Asset>();

  alt: string;
  url = '';
  languages = Languages;
  curlang: string;
  name: string;
  tmpassets: Asset[];
  curasset: Asset;

  constructor(public is: InterfaceService,
              private bottomSheet: MatBottomSheet,
              private og: OrganizationService,
              private inst: InstService,
              private admin: AdminService) {
    this.curlang = this.admin.language;
  }

  fillOut() {
    if (this.asset) {
      this.curasset = this.asset;
      this.name = this.asset.description[this.curlang].description;
      this.alt = this.asset.description[this.curlang].description;
    } else {
      const y = this.tmpassets.findIndex(f => f.url === this.fname);
      if (y > -1) {
        this.curasset = this.tmpassets[y];
        this.name = this.tmpassets[y].description[this.curlang].description;
        this.alt = this.tmpassets[y].description[this.curlang].description;
      } else if (this.fname && this.fname.includes('https')) {
        this.url = this.fname;
      }
    }
  }

  ngOnInit() {
    if (this.loc === 'Community') {
      if (this.type === 'graphics') {
        if (this.is.curComOrg.archive && this.is.curComOrg.archive.graphics) {
          this.tmpassets = this.is.curComOrg.archive.graphics;
        }
      }
      if (this.type === 'videos') {
        if (this.is.curComOrg.archive && this.is.curComOrg.archive.videos) {
          this.tmpassets = this.is.curComOrg.archive.videos;
        }
      }
    } else if (this.loc === 'Inst') {
      if (this.type === 'graphics') {
        if (this.inst.curInstOrg.archive && this.inst.curInstOrg.archive.graphics) {
          this.tmpassets = this.inst.curInstOrg.archive.graphics;
        }
      }
      if (this.type === 'videos') {
        if (this.inst.curInstOrg.archive && this.inst.curInstOrg.archive.videos) {
          this.tmpassets = this.inst.curInstOrg.archive.videos;
        }
      }
    } else if (this.loc === 'Orgs') {
      if (this.type === 'graphics') {
        if (this.og.curOrg.archive && this.og.curOrg.archive.graphics) {
          this.tmpassets = this.og.curOrg.archive.graphics;
        }
      }
      if (this.type === 'videos') {
        if (this.og.curOrg.archive && this.og.curOrg.archive.videos) {
          this.tmpassets = this.og.curOrg.archive.videos;
        }
      }
    }

    if (!this.tmpassets) {
      this.tmpassets = [];
    }
    this.fillOut();
  }


  onPick() {
    const bottomRef = this.bottomSheet.open(ArchivePickComponent, {
      data: {type: this.type, tmpassets: this.tmpassets}
    });

    bottomRef.afterDismissed().subscribe(ret => {
      if (ret) {
        this.fname = ret.url;
        if (this.returnasset) {
          this.asset = ret;
          this.fillOut();
          this.assets.emit(ret);
        } else {
          this.fillOut();
          this.filename.emit(ret.url);
        }
      }
    });
  }


  onKeyUp() {
    if (this.returnasset) {
      // console.log('what to do');
    } else {
      this.filename.emit(this.url);
    }
  }

  onClr() {
    this.name = '';
    this.curasset = null;
    this.url = '';
  }

  onClear() {
    this.name = '';
    this.fname = ''
    this.curasset = null;
    this.url = '';
    if (this.returnasset) {
      this.asset = null;
      this.assets.emit(null);
    } else {
      this.filename.emit('');
    }
  }

}
