import {
  Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges,
  ChangeDetectorRef, ChangeDetectionStrategy, NgZone, ViewChild
} from '@angular/core';
import {DomSanitizer, SafeUrl, SafeStyle} from '@angular/platform-browser';
import {MoveStart, Dimensions, CropperPosition, ImageCroppedEvent} from '../interfaces';
import {resetExifOrientation} from '../utils/image.utils';

@Component({
  selector: 'image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent implements OnChanges {
  @Input()
  wd = '100%';

  @Input()
  inputsize = false;

  @Input()
  inputheight: number;

  @Input()
  inputwidth: number;

  private originalImage: any;
  private moveStart: MoveStart;
  private maxSize: Dimensions;
  private originalSize: Dimensions;
  private setImageMaxSizeRetries = 0;

  safeImgDataUrl: SafeUrl | string;
  marginLeft: SafeStyle | string = '0px';
  imageVisible = false;

  diffX: number;
  diffY: number;

  wth: number;
  wid: number;
  iw: number;
  ih: number;
  fct = 1;
  mtop = 0;
  mleft = 0;
  mbottom = 0;
  mright = 0;
  x: number;
  y: number;
  facw: number;
  fach: number;
  ratio: number;

  @ViewChild('sourceImage', {static: false}) sourceImage: ElementRef;
  @ViewChild('cropperImage', {static: false}) cropperImage: ElementRef;

  @Input()
  set imageFileChanged(file: File) {
    this.initCropper();
    if (file) {
      this.loadImageFile(file);
    }
  }

  @Input()
  set imageChangedEvent(event: any) {
    this.initCropper();
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      this.loadImageFile(event.target.files[0]);
    }
  }

  @Input()
  set imageFromNativeEvent(image: string) { // Base64 format
    this.initCropper();
    this.loadBase64Image(image);
  }

  @Input()
  set imageBase64(imageBase64: string) {
    this.initCropper();
    this.loadBase64Image(imageBase64);
  }

  @Input() format: 'png' | 'jpeg' | 'bmp' | 'webp' | 'ico' = 'png';
  @Input() outputType: 'base64' | 'file' | 'both' = 'both';
  @Input() maintainAspectRatio = true;
  @Input() aspectRatio = 1;
  @Input() resizeToWidth = 0;
  @Input() roundCropper = true;
  @Input() onlyScaleDown = false;
  @Input() imageQuality = 92;
  @Input() autoCrop = true;
  @Input() cropper: CropperPosition = {
    x1: -100,
    y1: -100,
    x2: 10000,
    y2: 10000
  };

  @Output() startCropImage = new EventEmitter<void>();
  @Output() imageCropped = new EventEmitter<ImageCroppedEvent>();
  @Output() imageCroppedBase64 = new EventEmitter<string>();
  @Output() imageCroppedFile = new EventEmitter<Blob>();
  @Output() imageLoaded = new EventEmitter<void>();
  @Output() cropperReady = new EventEmitter<void>();
  @Output() loadImageFailed = new EventEmitter<void>();

  constructor(private sanitizer: DomSanitizer,
              public cd: ChangeDetectorRef,
              private zone: NgZone) {
    this.wth = window.innerWidth;
    this.initCropper();
  }

  imgMove(event) {

    if (Math.abs(event.touches[0].clientX - this.x) > 1) {
      let dirx = 1;
      if (event.touches[0].clientX > this.x) {
        dirx = -1;
      }
      if (this.mleft < 0 && dirx === -1) {
        this.mleft = this.mleft + 5;
        this.mright = this.mright + 5;
      }
      if (this.mright > 5 && dirx === 1) {
        this.mleft = this.mleft - 5;
        this.mright = this.mright - 5;
      }
      this.x = event.touches[0].clientX;
    }

    if (Math.abs(event.touches[0].clientY - this.y) > 1) {
      let diry = 1;
      if (event.touches[0].clientY > this.y) {
        diry = -1;
      }
      if (this.mtop < 0 && diry === -1) {
        this.mtop = this.mtop + 5;
        this.mbottom = this.mbottom + 5;
      }

      if (this.mbottom > 5 && diry === 1) {
        this.mtop = this.mtop - 5;
        this.mbottom = this.mbottom - 5;
      }
      this.y = event.touches[0].clientY;
    }
  }

  imgStart(event) {
    this.x = event.touches[0].clientX;
    this.y = event.touches[0].clientY;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cropper) {
      this.setMaxSize();
      this.checkCropperPosition();
      this.doAutoCrop();
      this.cd.markForCheck();
    }
    if (changes.aspectRatio && this.imageVisible) {
      this.resetCropperPosition();
    }

    if (changes.wd) {
      // this.wid = this.fac * parseInt(changes.wd, 10) * 1;
      if (!changes.wd.firstChange) {
        if (changes.wd.currentValue < 100) {
          this.wid = this.wth * changes.wd.currentValue / 100;
        } else {
          const x = (this.wth * changes.wd.currentValue / 100 - this.wth) / 2;
          this.wid = this.wth * changes.wd.currentValue / 100;
          this.mtop = x * -1;
          this.mbottom = x;
          this.mleft = x * -1;
          this.mright = x;
        }
        this.fct = changes.wd.currentValue / 100;
        this.iw = this.wid;
        this.ih = Math.min(this.wth, this.wid * this.ratio);
      } else {
        console.log('changes wd', changes);
      }
    }

  }

  private initCropper() {
    this.imageVisible = false;
    this.originalImage = null;
    this.safeImgDataUrl = 'data:image/png;base64,iVBORw0KGg'
      + 'oAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAU'
      + 'AAarVyFEAAAAASUVORK5CYII=';
    this.moveStart = {
      active: false,
      type: null,
      position: null,
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0,
      clientX: 0,
      clientY: 0
    };
    this.maxSize = {
      width: 0,
      height: 0
    };
    this.originalSize = {
      width: 0,
      height: 0
    };
    this.cropper.x1 = -100;
    this.cropper.y1 = -100;
    this.cropper.x2 = 10000;
    this.cropper.y2 = 10000;
  }

  private loadImageFile(file: File) {
    const fileReader = new FileReader();
    fileReader.onload = (event: any) => {
      const imageType = file.type;
      if (this.isValidImageType(imageType)) {
        resetExifOrientation(event.target.result)
          .then((resultBase64: string) => this.loadBase64Image(resultBase64))
          .catch(() => this.loadImageFailed.emit());
      } else {
        this.loadImageFailed.emit();
      }
    };
    fileReader.readAsDataURL(file);
  }

  private isValidImageType(type: string) {
    return /image\/(png|jpg|jpeg|bmp|gif|tiff)/.test(type);
  }

  private loadBase64Image(imageBase64: string) {
    this.safeImgDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageBase64);
    this.originalImage = new Image();
    this.originalImage.onload = () => {
      this.originalSize.width = this.originalImage.width;
      this.originalSize.height = this.originalImage.height;
      this.ratio = this.originalSize.height / this.originalSize.width;

      // sets the original width of the image
      if (this.originalSize.width < this.wth) {
        this.wid = this.originalSize.width;
        this.facw = 1;
      } else {
        this.wid = this.wth;
        this.facw = this.wid / this.originalSize.width;
      }
      this.iw = this.wid;
      this.ih = Math.min(this.wth, this.wid * this.ratio);

      this.cd.markForCheck();
    };
    this.originalImage.src = imageBase64;
  }

  imageLoadedInView(): void {
    if (this.originalImage != null) {
      this.imageLoaded.emit();
      this.setImageMaxSizeRetries = 0;
      setTimeout(() => this.checkImageMaxSizeRecursively());
    }
  }

  private checkImageMaxSizeRecursively() {
    if (this.setImageMaxSizeRetries > 40) {
      this.loadImageFailed.emit();
    } else if (this.sourceImage && this.sourceImage.nativeElement && this.sourceImage.nativeElement.offsetWidth > 0) {
      this.setMaxSize();
      this.resetCropperPosition();
      this.cropperReady.emit();
      this.cd.markForCheck();
    } else {
      this.setImageMaxSizeRetries++;
      setTimeout(() => {
        this.checkImageMaxSizeRecursively();
      }, 50);
    }
  }


  private resetCropperPosition() {
    const sourceImageElement = this.sourceImage.nativeElement;
    if (this.inputsize) {
      this.cropper.x1 = 0;
      this.cropper.x2 = this.inputwidth;
      this.cropper.y1 = 0;
      this.cropper.y2 = this.inputheight;
    } else {
      if (!this.maintainAspectRatio) {
        this.cropper.x1 = 0;
        this.cropper.x2 = sourceImageElement.offsetWidth;
        this.cropper.y1 = 0;
        this.cropper.y2 = sourceImageElement.offsetHeight;
      } else if (sourceImageElement.offsetWidth / this.aspectRatio < sourceImageElement.offsetHeight) {
        this.cropper.x1 = 0;
        this.cropper.x2 = sourceImageElement.offsetWidth;
        const cropperHeight = sourceImageElement.offsetWidth / this.aspectRatio;
        this.cropper.y1 = (sourceImageElement.offsetHeight - cropperHeight) / 2;
        this.cropper.y2 = this.cropper.y1 + cropperHeight;
      } else {
        this.cropper.y1 = 0;
        this.cropper.y2 = sourceImageElement.offsetHeight;
        const cropperWidth = sourceImageElement.offsetHeight * this.aspectRatio;
        this.cropper.x1 = 0; // (sourceImageElement.offsetWidth - cropperWidth) / 2;
        this.cropper.x2 = sourceImageElement.offsetWidth; //this.cropper.x1 + cropperWidth;
      }
    }

    this.doAutoCrop();
    this.imageVisible = true;
  }

  startMove(event: any, moveType: string, position: string | null = null) {
    this.moveStart = Object.assign({
      active: true,
      type: moveType,
      position: position,
      clientX: this.getClientX(event),
      clientY: this.getClientY(event)
    }, this.cropper);
    if (moveType === 'move') {
      this.diffX = this.cropper.x2;
      this.diffY = this.cropper.y2;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  moveImg(event: any) {
    if (this.moveStart.active) {
      event.stopPropagation();
      event.preventDefault();
      this.setMaxSize();
      if (this.moveStart.type === 'move') {
        this.move(event);
        this.checkCropperPosition();
      }
      this.cd.detectChanges();
    }
  }

  private setMaxSize() {
    const sourceImageElement = this.sourceImage.nativeElement;
    this.maxSize.width = sourceImageElement.offsetWidth;
    this.maxSize.height = sourceImageElement.offsetHeight;
    this.marginLeft = this.sanitizer.bypassSecurityTrustStyle('calc(50% - ' + this.maxSize.width / 2 + 'px)');
  }


  private checkCropperPosition() {
    if (this.cropper.x1 < 0) {
      this.cropper.x1 = 0;
    }

    if (this.cropper.y1 < 0) {
      this.cropper.y1 = 0;
    }

    if (this.cropper.x1 + this.cropper.x2 > this.maxSize.width) {
      this.cropper.x1 = this.maxSize.width - this.cropper.x2;
    }

    if (this.cropper.y1 + this.cropper.y2 > this.maxSize.height) {
      this.cropper.y1 = this.maxSize.height - this.cropper.y2;
    }
  }

  @HostListener('document:mouseup')
  @HostListener('document:touchend')
  moveStop() {
    if (this.moveStart.active) {
      this.moveStart.active = false;
      this.doAutoCrop();
    }
  }

  private move(event: any) {
    const diffX = this.getClientX(event) - this.moveStart.clientX;
    const diffY = this.getClientY(event) - this.moveStart.clientY;

    this.cropper.x1 = this.moveStart.x1 + diffX;
    this.cropper.y1 = this.moveStart.y1 + diffY;
  }


  private doAutoCrop() {
    if (this.autoCrop) {
      this.crop();
    }
  }

  crop(): ImageCroppedEvent | Promise<ImageCroppedEvent> | null {
    if (this.sourceImage.nativeElement && this.originalImage != null) {
      this.startCropImage.emit();
      const imagePosition = this.getImagePosition();
      const width = imagePosition.x2; // - imagePosition.x1;
      const height = imagePosition.y2;  // - imagePosition.y1;
      const resizeRatio = this.getResizeRatio(width);
      const resizedWidth = Math.floor(width * resizeRatio);
      const resizedHeight = Math.floor(height * resizeRatio);

      const cropCanvas = document.createElement('canvas') as HTMLCanvasElement;
      cropCanvas.width = resizedWidth;
      cropCanvas.height = resizedHeight;

      const ctx = cropCanvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(
          this.originalImage,
          imagePosition.x1,
          imagePosition.y1,
          width,
          height,
          0,
          0,
          resizedWidth,
          resizedHeight
        );
        return this.cropToOutputType(cropCanvas, resizedWidth, resizedHeight, imagePosition);
      }
    }
    return null;
  }

  private getImagePosition(): CropperPosition {
    const sourceImageElement = this.sourceImage.nativeElement;
    const ratio = this.originalSize.width / sourceImageElement.offsetWidth;
    return {
      x1: Math.round((this.cropper.x1) * ratio) - this.mleft * ratio,
      y1: Math.round(this.cropper.y1 * ratio) - this.mtop * ratio,
      x2: Math.min(Math.round(this.cropper.x2 * ratio), this.originalSize.width),
      y2: Math.min(Math.round(this.cropper.y2 * ratio), this.originalSize.height)
    };
  }

  private cropToOutputType(cropCanvas: HTMLCanvasElement, resizedWidth: number, resizedHeight: number, imagePosition: CropperPosition): ImageCroppedEvent | Promise<ImageCroppedEvent> {
    const output: ImageCroppedEvent = {
      width: resizedWidth,
      height: resizedHeight,
      cropperPosition: Object.assign({}, this.cropper),
      imagePosition: imagePosition
    };
    switch (this.outputType) {
      case 'file':
        return this.cropToFile(cropCanvas)
          .then((result: Blob | null) => {
            output.file = result;
            this.imageCropped.emit(output);
            return output;
          });
      case 'both':
        output.base64 = this.cropToBase64(cropCanvas);
        return this.cropToFile(cropCanvas)
          .then((result: Blob | null) => {
            output.file = result;
            this.imageCropped.emit(output);
            return output;
          });
      default:
        output.base64 = this.cropToBase64(cropCanvas);
        this.imageCropped.emit(output);
        return output;
    }
  }

  private cropToBase64(cropCanvas: HTMLCanvasElement): string {
    const imageBase64 = cropCanvas.toDataURL('image/' + this.format, this.getQuality());
    this.imageCroppedBase64.emit(imageBase64);
    return imageBase64;
  }

  private cropToFile(cropCanvas: HTMLCanvasElement): Promise<Blob | null> {
    return this.getCanvasBlob(cropCanvas)
      .then((result: Blob | null) => {
        if (result) {
          this.imageCroppedFile.emit(result);
        }
        return result;
      });
  }

  private getCanvasBlob(cropCanvas: HTMLCanvasElement): Promise<Blob | null> {
    return new Promise((resolve) => {
      cropCanvas.toBlob(
        (result: Blob | null) => this.zone.run(() => resolve(result)),
        'image/' + this.format,
        this.getQuality()
      );
    });
  }

  private getQuality(): number {
    return Math.min(1, Math.max(0, this.imageQuality / 100));
  }

  private getResizeRatio(width: number): number {
    return this.resizeToWidth > 0 && (!this.onlyScaleDown || width > this.resizeToWidth)
      ? this.resizeToWidth / width
      : 1;
  }

  private getClientX(event: any) {
    return event.clientX != null ? event.clientX : event.touches[0].clientX;
  }

  private getClientY(event: any) {
    return event.clientY != null ? event.clientY : event.touches[0].clientY;
  }
}
