import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InterfaceService} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-del-card',
  templateUrl: './del-card.component.html',
  styleUrls: ['./del-card.component.scss']
})
export class DelCardComponent implements OnInit {
  @Output()
  delete = new EventEmitter();

  @Input()
  i: number;

  constructor(public is: InterfaceService,
              public admin: AdminService) { }

  ngOnInit(): void {
  }

  onDelete() {
    this.delete.emit();
  }

}
