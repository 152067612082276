import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {
    Checklist,
    DisplayContact, IncidentComOrg, IncidentEntry, IncidentForm, InterfaceService, LinkHolder, PartnerContact, PartnerRequest, Party,
    Relationship
} from '../../services/interface.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {BehaviorSubject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {FirebaseService} from '../../services/firebase.service';
import {ConfirmationComponent} from '../confirmation/confirmation.component';
import {MatDialog} from '@angular/material';
import * as _ from 'lodash';
import {Experiences} from '../../services/constants';

export class Partner {
    ukey: string;
    name: string;
    contacts: string[];
    messages: string[];
    events: string[];
}

@Component({
    selector: 'app-make-request',
    templateUrl: './make-request.component.html',
    styleUrls: ['./make-request.component.scss']
})
export class MakeRequestComponent implements OnInit, OnDestroy {
    @Input()
    loc: string;

    @Input()
    dest: string;

    @Input()
    orgtype: string;

    @Output()
    closeit = new EventEmitter<IncidentForm>();

    curincident: IncidentForm;
    incidents: IncidentForm[];
    primarycolor = '#99add0';
    secondarycolor = '#000000';

    links: LinkHolder[];
    experiences = Experiences;
    relationships = Relationship;
    displaycontacts: DisplayContact[];
    partnerdetails: boolean;
    partnerrequest: boolean;
    contact: boolean;
    contactdetails: boolean;
    incidentdetails = false;
    contacts: PartnerContact[];
    stafflist;
    curcontact: PartnerContact;
    curPartner: PartnerRequest;
    note: string;
    err1 = false;
    oControl: FormControl;
    sControl: FormControl;
    orgslist;
    $sub1;
    $sub2;
    $sub3;
    $sub4;
    $sub5;
    $sub6;
    partners: PartnerRequest[];
    rqsts: PartnerRequest[];
    pending: PartnerRequest[];
    denied: PartnerRequest[];
    deleted: PartnerRequest[];
    cnt: number;

    org: BehaviorSubject<string | null> = new BehaviorSubject('');
    startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
    endAt: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');

    staff: BehaviorSubject<string | null> = new BehaviorSubject('');
    startAtStaff: BehaviorSubject<string | null> = new BehaviorSubject('');
    endAtStaff: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');

    form = new FormGroup({
        confidentiality: new FormControl(''),
        clientname: new FormControl(''),
        clientemail: new FormControl(''),
        clientphone: new FormControl(''),
        clientrelationship: new FormControl(''),
        incidentlocation: new FormControl(''),
        incidentdateandtime: new FormControl(''),
        incidenttype: new FormControl(''),
        serviceperformed: new FormControl(''),
        othersinformed: new FormControl(''),
        notes: new FormControl(''),
    });

    get clientname() {
        return this.form.get('clientname');
    }

    get clientemail() {
        return this.form.get('clientemail');
    }

    get clientphone() {
        return this.form.get('clientphone');
    }

    get clientrelationship() {
        return this.form.get('clientrelationship');
    }

    get incidentdateandtime() {
        return this.form.get('incidentdateandtime');
    }

    get incidenttype() {
        return this.form.get('incidenttype');
    }

    get incidentlocation() {
        return this.form.get('incidentlocation');
    }

    get serviceperformed() {
        return this.form.get('serviceperformed');
    }

    get othersinformed() {
        return this.form.get('othersinformed');
    }

    get notes() {
        return this.form.get('notes');
    }

    get confidentiality() {
        return this.form.get('confidentiality');
    }

    constructor(private admin: AdminService,
                private dialog: MatDialog,
                private fs: FirebaseService,
                private auth: AngularFireAuth,
                public is: InterfaceService) {
        this.oControl = new FormControl();
        this.sControl = new FormControl();

    }


    ngOnInit() {
        // todo: expand ot other types
        this.$sub1 = this.is.getBehaviorList(this.startAt, this.endAt, `OrgsByType/${this.orgtype}`).subscribe(orgs => {
            this.orgslist = orgs;
        });

        this.$sub2 = this.is.getList(`${this.loc}/${this.admin.admin.org}/partners/request`).subscribe(rqsts => {
            this.rqsts = rqsts.filter(r => r.org === this.admin.admin.org && r.status === 'Pending');
            this.pending = rqsts.filter(r => r.org !== this.admin.admin.org && r.status === 'Pending');
            this.partners = rqsts.filter(r => r.status === 'Approved');
            if (this.loc === 'Community') {
                this.partners = _.orderBy(this.partners, ['who'], ['asc']);
                console.log('adsf')
                this.$sub5 = this.is.getList(`Partners/Community/${this.admin.admin.org}`).subscribe(lins => {
                    this.links = [];
                    if (lins.length > 0) {
                        lins.forEach(l => {
                            const linkholder = new LinkHolder();
                            linkholder.link = l;
                            linkholder.partner = false;
                            if (this.partners && this.partners.length > 0) {
                                const x = this.partners.findIndex(p => p.who === l || p.org === l);
                                if (x > -1) {
                                    linkholder.partner = true;
                                }
                            }
                            this.links.push(linkholder);
                        });
                    }
                })
            } else {
                this.partners = _.orderBy(this.partners, ['org'], ['asc']);
            }

            console.log('p', this.partners)
            this.denied = rqsts.filter(r => r.status === 'Denied');
            this.deleted = rqsts.filter(r => r.status === 'Deleted');
        });

        this.$sub3 = this.is.getList(`Lists/${this.admin.admin.org}/contacts`).subscribe(cnts => {
            this.contacts = cnts;
        });

        if (this.loc === 'Community') {
            this.$sub4 = this.is.getBehaviorList(this.startAtStaff, this.endAtStaff, `Lists/${this.admin.admin.org}/jobs`).subscribe(s => {
                this.stafflist = s;
            });
        } else {
            this.$sub4 = this.is.getBehaviorList(this.startAtStaff, this.endAtStaff, `Orgs/${this.admin.admin.org}/resources/staff`).subscribe(s => {
                this.stafflist = s;
            });
        }


    }

    setValue() {
        this.form.setValue({
            confidentiality: this.curincident.confidentiality,
            clientname: this.curincident.clientname,
            clientemail: this.curincident.clientemail,
            clientphone: this.curincident.clientphone,
            clientrelationship: this.curincident.clientrelationship,
            incidentlocation: this.curincident.incidentlocation,
            incidentdateandtime: this.curincident.incidentdateandtime,
            incidenttype: this.curincident.incidenttype,
            serviceperformed: this.curincident.serviceperformed,
            othersinformed: this.curincident.othersinformed,
            notes: this.curincident.notes,
        });
        this.incidentdetails = true;
    }

    onDeleteIncident(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this report?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                const pr = this.rqsts[i];
                this.is.deleteObj(`Community/${this.admin.admin.org}/partners/${this.curPartner.org}/incidents/${this.incidents[i].ukey}`, 'Delete', 'Delete incident report');
                this.is.deleteObj(`Community/${this.admin.admin.org}/incidents/${this.incidents[i].ukey}`, 'Legacy', '');
                this.is.deleteObj(`Incidents/${this.incidents[i].ukey}`, 'Legacy', '');
                this.is.deleteObj(`Orgs/${this.curPartner.org}/partners/${this.admin.admin.org}/incidents/${this.incidents[i].ukey}`, 'Legacy', '');
            }
        });
    }

    onEditIncident(i) {
        this.curincident = this.incidents[i];
        this.setValue();
    }

    onAddIncident() {
        this.curincident = new IncidentForm();
        this.curincident.confidentiality = false;
        this.curincident.clientname = '';
        this.curincident.clientemail = '';
        this.curincident.clientphone = '';
        this.curincident.clientrelationship = '';
        this.curincident.incidentlocation = '';
        this.curincident.incidentdateandtime = '';
        this.curincident.incidenttype = '';
        this.curincident.serviceperformed = '';
        this.curincident.othersinformed = '';
        this.curincident.notes = '';
        this.curincident.status = 'Pending';
        this.setValue();
    }

    onAddPartner() {
        this.partnerrequest = true;
    }

    onSubCal(ev, i) {
        this.is.setObj(`${this.loc}/${this.admin.admin.org}/partners/request/${this.partners[i].ukey}/subcalendar`, ev.checked, 'Partner', 'Calendar subscription')
    }

    onCancel() {
        this.partnerrequest = false;
        this.err1 = false;
    }

    setPR(pr: PartnerRequest) {
        this.is.setObj(`Partnerships/${pr.path}/admin1`, this.auth.auth.currentUser.uid, 'Legacy', '');
        pr.theirpath = `${this.loc}/${this.admin.admin.org}`;
        this.is.setObj(`${this.dest}/${this.oControl.value}/partners/request/${pr.ukey}`, pr, 'Partner', 'Partner Request');
        pr.theirpath = `${this.dest}/${this.oControl.value}`;
        this.is.setObj(`${this.loc}/${this.admin.admin.org}/partners/request/${pr.ukey}`, pr, 'Legacy', '');
        this.partnerrequest = false;
    }

    onSubmit() {
        const x = this.orgslist.findIndex(o => o.ukey === this.oControl.value);
        let valid = false;
        if (x > -1) {
            valid = true;
        }
        if (!valid) {
            this.err1 = true;
        } else {
            this.err1 = false;
            const pr = new PartnerRequest();
            pr.date = new Date().toISOString();
            pr.name = this.admin.admin.name;
            pr.email = this.admin.admin.email;

            pr.ukey = this.fs.titleIXdb.createPushId();
            pr.status = 'Pending';
            pr.subcalendar = false;
            pr.who = this.oControl.value;
            if (this.loc === 'Community') {
                this.is.getObject(`${this.loc}/${this.admin.admin.org}/orglegacyextra/kwabid`).take(1).subscribe(com => {
                    this.is.getObject(`${this.dest}/${this.oControl.value}/profile/orglegacyextra/kwabid`).take(1).subscribe(org => {
                        pr.path = `${com},${org}`;
                        pr.org = `${this.admin.admin.org}`;
                        this.setPR(pr);
                    });
                });
            } else {
                this.is.getObject(`${this.loc}/${this.admin.admin.org}/profile/orglegacyextra/kwabid`).take(1).subscribe(org => {
                    this.is.getObject(`${this.dest}/${this.oControl.value}/orglegacyextra/kwabid`).take(1).subscribe(com => {
                        pr.org = `${this.admin.admin.org}`;
                        pr.path = `${com},${org}`;
                        this.setPR(pr);
                    });
                });
            }
        }
    }


    onCancelPartner() {
        if (this.$sub6) {
            this.$sub6.unsubscribe();
        }
        this.partnerdetails = false;
    }


    subIncidents(str) {
        this.$sub6 = this.is.getList(`${this.loc}/${this.admin.admin.org}/partners/${str}/incidents`).subscribe(incs => {
            console.log(`${this.loc}/${this.admin.admin.org}/partners/${str}/incidents`, incs)
            this.incidents = incs;
        });
    }

    onEditPartner(i) {
        this.curPartner = this.partners[i];

      let str = '';
        if (this.loc === 'Community') {
            str = this.curPartner.who;
        } else {
            str = this.curPartner.org;
        }
        this.subIncidents(str);

        this.displaycontacts = [];
        this.is.getList(`Lists/${str}/contacts`).take(1).subscribe(c => {
            if (c && c.length > 0) {
                c.forEach(con => {
                    if (this.loc === 'Community') {
                        this.is.getObject(con.path).take(1).subscribe(ind => {
                            const newdc = new DisplayContact();
                            newdc.name = con.name;
                            newdc.title = ind.title;
                            newdc.email = ind.email;
                            newdc.phones = ind.phones;
                            newdc.note = con.note;
                            this.displaycontacts.push(newdc);
                        });
                    } else {
                        // todo: need to make this read an http function
                        /*this.is.getObject(`Individual/${con.ukey}/details/jobs/${con.org}`).take(1).subscribe(ind => {
                            const newdc = new DisplayContact();
                            newdc.name = con.name;
                            newdc.title = ind.title;
                            newdc.email = ind.email;
                            newdc.phones = ind.phones;
                            newdc.note = con.note;
                            this.displaycontacts.push(newdc);
                        });*/
                    }

                });
            }
        });
        this.partnerdetails = true;
    }

    onRemoveRequest(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to remove this request?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                const pr = this.rqsts[i];

                this.is.deleteObj(`${this.dest}/${pr.who}/partners/request/${pr.ukey}`, 'Partner', 'Remove request');
                this.is.deleteObj(`${this.loc}/${this.admin.admin.org}/partners/request/${pr.ukey}`, 'Legacy', '');
            }
        });

    }

    onCancelContact() {
        this.contact = false;
    }

    onCancelContactDetails() {
        this.contactdetails = false;
    }


    onDeleteContact(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this contact?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                this.is.deleteObj(`Lists/${this.admin.admin.org}/contacts/${this.contacts[i].ukey}`, `Legacy Contact`, ``);
            }
        });


    }

    onEditContacts(i) {
        this.curcontact = this.contacts[i];
        this.sControl.setValue(this.curcontact.name);
        this.note = this.curcontact.note;
        this.contactdetails = true;
    }

    onAddContact() {
        this.curcontact = new PartnerContact;
        this.curcontact.name = '';
        this.curcontact.path = '';
        this.curcontact.org = this.admin.admin.org;
        this.curcontact.note = '';
        this.contactdetails = true;
    }

    onSaveContactDetails() {
        const x = this.stafflist.findIndex(s => s.fullname === this.sControl.value);
        if (x > -1) {
            this.curcontact.name = this.sControl.value;
            this.curcontact.ukey = this.stafflist[x].ukey;
            if (this.loc === 'Community') {
                this.curcontact.path = `Lists/${this.admin.admin.org}/jobs/${this.curcontact.ukey}`;
            } else {
                this.curcontact.path = `Orgs/${this.admin.admin.org}/resources/staff/${this.curcontact.ukey}`;
            }

            if (!this.note) {
                this.curcontact.note = '';
            } else {
                this.curcontact.note = this.note;
            }

            this.is.setObj(`Lists/${this.admin.admin.org}/contacts/${this.curcontact.ukey}`, this.curcontact, 'Partner', 'Created partner contact');
            this.contactdetails = false;
        }

    }


    onContact() {
        this.contact = true;
    }

    onRemoveDeleted(i) {
        const pr = this.deleted[i];
        this.is.deleteObj(`${this.loc}/${this.admin.admin.org}/partners/request/${pr.ukey}`, 'Legacy', '');
    }

    onRemoveDeny(i) {
        const pr = this.denied[i];
        this.is.deleteObj(`${this.loc}/${this.admin.admin.org}/partners/request/${pr.ukey}`, 'Legacy', '');
    }


    onDeletePartner(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this partnership?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                const pr = this.partners[i];
                pr.status = 'Deleted';
                pr.date = new Date().toISOString();

                this.is.setObj(`${this.dest}/${pr.org}/partners/request/${pr.ukey}`, pr, 'Legacy', '');
                this.is.deleteObj(`${this.loc}/${this.admin.admin.org}/partners/request/${pr.ukey}`, 'Partner', 'Delete partnership');
            }
        });
    }


    onDeleteRequest(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to deny this request?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                const pr = this.pending[i];
                pr.status = 'Denied';
                pr.date = new Date().toISOString();

                this.is.setObj(`${this.dest}/${pr.org}/partners/request/${pr.ukey}`, pr, 'Legacy', '');
                this.is.deleteObj(`${this.loc}/${this.admin.admin.org}/partners/request/${pr.ukey}`, 'Partner', 'Denied partner request');
            }
        });
    }

    onApprove(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Accept request?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                const pr = this.pending[i];

                pr.status = 'Approved';
                pr.date = new Date().toISOString();
                this.is.setObj(`Partnerships/${pr.path}/admin2`, this.auth.auth.currentUser.uid, 'Legacy', '');

                this.is.setObj(`${this.dest}/${pr.org}/partners/request/${pr.ukey}/status`, pr.status, 'Partner', 'Partner Request');
                this.is.setObjNoLog(`${this.dest}/${pr.org}/partners/request/${pr.ukey}/date`, pr.date);

                this.is.setObjNoLog(`${this.loc}/${this.admin.admin.org}/partners/request/${pr.ukey}/status`, pr.status);
                this.is.setObjNoLog(`${this.loc}/${this.admin.admin.org}/partners/request/${pr.ukey}/date`, pr.date);

            }
        });
    }

    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
        if (this.$sub2) {
            this.$sub2.unsubscribe();
        }
        if (this.$sub3) {
            this.$sub3.unsubscribe();
        }
        if (this.$sub4) {
            this.$sub4.unsubscribe();
        }
        if (this.$sub6) {
            this.$sub6.unsubscribe();
        }
    }

    updateorgslist(sc) {
        this.oControl.setValue(sc);
    }

    search() {
        if (this.oControl.value && this.oControl.value.length === 1) {
            this.oControl.setValue(this.oControl.value.toUpperCase());
        }
        const q = this.oControl.value;
        this.startAt.next(q);
        this.endAt.next(q + '\uf8ff');
    }

    updatestafflist(sc) {
        this.sControl.setValue(sc);
    }

    searchstaff() {
        if (this.sControl.value && this.sControl.value.length === 1) {
            this.sControl.setValue(this.sControl.value.toUpperCase());
        }
        const q = this.sControl.value;
        this.startAtStaff.next(q);
        this.endAtStaff.next(q + '\uf8ff');
    }

    onCancelIncident() {
        this.incidentdetails = false;
    }

    makeDesc(inc): string {
        let str = '';
        if (inc.incidentdateandtime) {
            str = inc.incidentdateandtime;
        }
        if (inc.incidentlocation) {
            if (str) {
                str = `${str} - ${inc.incidentlocation}`;
            } else {
                str = inc.incidentlocation;
            }
        }
        return str;
    }


    onCreateIncident() {
        let incvar;
        incvar = new IncidentEntry;
        this.is.getObject('Counters/incidentcounter').take(1).subscribe( cnt => {
            this.cnt = cnt + 1;
            this.is.setObj('Counters/incidentcounter', cnt + 1, 'Legacy', '');
            const incidentid = 'com' + this.cnt.toString();
            incvar.ukey = incidentid;
            incvar.dateofentry = new Date().toISOString();
            if (this.curincident.clientname) {
                incvar.name = this.curincident.clientname;
            } else {
                incvar.name = 'Anonymous';
            }
            if (this.curincident.clientphone) {
                incvar.phone = this.curincident.clientphone;
            } else {
                incvar.phone = '';
            }
            if (this.curincident.clientemail) {
                incvar.email = this.curincident.clientemail;
            } else {
                incvar.email = '';
            }
            incvar.status = 'Submitted';
            if (!incvar.reports) {
                incvar.reports = [];
            }
            console.log('invar', `Orgs/${this.admin.admin.org}/incidents/${incidentid}`, incvar);

            this.fs.titleIXdb.object(`Orgs/${this.admin.admin.org}/incidents/${incidentid}`).set(incvar).then(val => {
                this.curincident.status = 'Accepted';
                this.is.setObj(`Orgs/${this.admin.admin.org}/incidents/${incidentid}/reports/${this.curincident.ukey}`, this.curincident, 'Legacy', '');
                this.is.setObj(`Orgs/${this.admin.admin.org}/partners/${this.curPartner.org}/incidents/${this.curincident.ukey}/status`, 'Accepted', 'Legacy', '');
                this.is.setObj(`Community/${this.curPartner.org}/partners/${this.admin.admin.org}/incidents/${this.curincident.ukey}/status`, 'Accepted', 'Legacy', '');
                this.is.deleteObj(`Orgs/${this.admin.admin.org}/partneralert/${this.curPartner.org}`, 'Legacy', '');

                if (incvar.name !== 'Anonymous') {
                    console.log('anonymous')
                    const party = new Party;
                    party.name = incvar.name;
                    party.phone = incvar.phone;
                    party.email = incvar.email;
                    party.deviceid = -1;
                    party.role = '';
                    this.is.setObj(`Orgs/${this.admin.admin.org}/incidents/${incidentid}/parties/${this.fs.titleIXdb.createPushId()}`, party, 'Incident', 'Create party');
                }


            });

            const timelinex = new Checklist();
            timelinex.date =  new Date().getTime();
            timelinex.name = `Incident`;
            timelinex.description = `Filed by ${this.curPartner.who}.`;
            timelinex.days = 0;
            timelinex.respondent = false;
            timelinex.complainant = false;
            timelinex.items = [];
            timelinex.respondentcompleted = false;
            timelinex.complainantcompleted = false;
            timelinex.respondentdatecompleted = '';
            timelinex.complainantdatecompleted = '';
            timelinex.respondenthow = '';
            timelinex.complainanthow = '';

            timelinex.topic = 'Report';
            timelinex.ukey = this.curincident.ukey;
            this.is.setObj(`Orgs/${this.admin.admin.org}/incidents/${incidentid}/timeline/${timelinex.ukey}`, timelinex, 'Incident', 'Add to timeline');
        });

        this.incidentdetails = false;

    }

    isAlert(str): boolean {
        let val = false;
        if (this.loc !== 'Community' && this.admin.partneralerts && this.admin.partneralerts.length > 0) {
            const x = this.admin.partneralerts.findIndex(p => p === str);
            if (x > -1) {
                val = true;
            }
        }
        return val;
    }

    onSaveIncident() {
        let gonext = false;
        this.curincident.confidentiality = this.confidentiality.value;
        this.curincident.clientname = this.clientname.value;
        this.curincident.clientemail = this.clientemail.value;
        this.curincident.clientphone = this.clientphone.value;
        this.curincident.clientrelationship = this.clientrelationship.value;
        this.curincident.incidentlocation = this.incidentlocation.value;
        this.curincident.incidentdateandtime = this.incidentdateandtime.value;
        this.curincident.incidenttype = this.incidenttype.value;
        this.curincident.serviceperformed = this.serviceperformed.value;
        this.curincident.othersinformed = this.othersinformed.value;
        this.curincident.notes = this.notes.value;
        this.curincident.date = new Date().toISOString();
        if (!this.curincident.ukey) {
            gonext = true;
            this.curincident.ukey = this.fs.titleIXdb.createPushId();
        }

        this.is.setObj(`Community/${this.admin.admin.org}/partners/${this.curPartner.who}/incidents/${this.curincident.ukey}`, this.curincident, 'Partner', 'Incident report');
        this.is.setObj(`Orgs/${this.curPartner.who}/partners/${this.admin.admin.org}/incidents/${this.curincident.ukey}`, this.curincident, 'Partner', 'Incident report');
        this.is.setObj(`Orgs/${this.curPartner.who}/partneralert/${this.admin.admin.org}`, this.admin.admin.org, 'Legacy', '');

        const comorg = new IncidentComOrg;
        comorg.community = this.admin.admin.org;
        comorg.org = this.curPartner.org;
        comorg.date = this.curincident.date;
        comorg.incidenttype = this.curincident.incidenttype;
        comorg.ukey = this.curincident.ukey;
        this.is.setObj(`Incidents/partners/${this.curincident.ukey}`, comorg, 'Legacy', '');

        if (gonext) {
            this.closeit.emit(this.curincident);
        }
        this.incidentdetails = false;
    }

}
