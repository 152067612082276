import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {InterfaceService, Wish} from '../services/interface.service';
import {RoehttpService} from '../services/roehttp.service';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'app-publisher-card',
  templateUrl: './publisher-card.component.html',
  styleUrls: ['./publisher-card.component.scss']
})
export class PublisherCardComponent implements OnInit {
  @Input()
  curwish: Wish;

  @Output()
  deleteguide = new EventEmitter();

  kind = 'READ';
  isVideo = false;
  editdesc = false;
  olddesc: string;
  oldimg: string;
  oldtitle: string;


  constructor(private http: RoehttpService,
              private is: InterfaceService,
              public admin: AdminService) {
  }

  onFixFormat() {
    this.kind = 'READ';
    this.isVideo = false;
    if (this.curwish.ty === 'podcast') {
      this.kind = 'LISTEN';
    } else if (this.curwish.ty === 'vlog') {
      this.isVideo = true;
      this.kind = 'WATCH';
    } else if (this.curwish.m && !this.curwish.m.inframe) {
      this.kind = 'OPEN';
    }
  }

  onEdit() {
    this.olddesc = this.curwish.fullmsg.en.description;
    this.oldtitle = this.curwish.ssoc.en.description;
    this.oldimg = this.curwish.e;
    this.editdesc = !this.editdesc;
  }

  onRead() {
    window.open(this.curwish.l, '_blank');
  }

  ngOnInit() {
    this.onFixFormat();


  }

  onFixSingleGuide() {
    if (this.olddesc !== this.curwish.fullmsg.en.description) {
      this.http.rssfixInFrame(this.curwish, this.curwish.fullmsg.en.description, 'desc').take(1).subscribe(res => {
        console.log('resy', res);
      });
    }

    if (this.oldtitle !== this.curwish.ssoc.en.description) {
      this.http.rssfixInFrame(this.curwish, this.curwish.ssoc.en.description, 'title').take(1).subscribe(res => {
        console.log('resy', res);
      });
    }

    if (this.oldimg !== this.curwish.e) {
      console.log('oldimage')
      this.http.rssfixInFrame(this.curwish, this.curwish.e, 'image').take(1).subscribe(res => {
        console.log('resy', res);
      });
    }

  }

  onDeleteGuide() {
    this.http.delTravelGuide(this.curwish).take(1).subscribe(res => {
      if (res && res.status === 200) {
        this.deleteguide.emit();
      } else {
        console.log('did not delete for some reason');
      }
    });
  }
}
