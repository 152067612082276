import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CAMetaMoly, CAMolyData, CAMolyInForm} from '../../services/ca';
import {Address, InterfaceService} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import * as _ from 'lodash';
import {RoehttpService} from '../../services/roehttp.service';
import {Observable} from 'rxjs';
import {FirebaseService} from '../../services/firebase.service';
import {AdminService} from '../../services/admin.service';

declare var google: any;

@Component({
  selector: 'app-ca-addresses',
  templateUrl: './ca-addresses.component.html',
  styleUrls: ['./ca-addresses.component.scss']
})
export class CaAddressesComponent implements OnInit {
  @Input()
  path: string;

  @Input()
  formusesukey: string;

  @Input()
  moly: CAMolyInForm;

  @Input()
  primarycolor = '#0000FF';

  @Input()
  secondarycolor = '#ffffff';

  @Output()
  save = new EventEmitter<string>();

  @Output()
  editting = new EventEmitter();

  molysin: CAMetaMoly[];
  adddetails = false;
  latt = -1;
  lngg = -1;
  err: string;
  edit = -1;
  ukey: string;
  firsttime = true;

  scope = 'global';
  curlang = 'en';
  data: CAMolyData[];
  posx: number;
  addresses: any[];

  constructor(private is: InterfaceService,
              private fs: FirebaseService,
              private admin: AdminService,
              private http: RoehttpService,
              private cd: ChangeDetectorRef,
              private db: AngularFireDatabase) {
  }

  ngOnInit() {
    this.molysin = _.orderBy(this.moly.moly.molys[0].molys, 'pos', 'asc');

    this.addresses = [];
    // get list of all addresses
    console.log('formuseku', `${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`)
    this.is.getList(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`)
      .take(1)
      .subscribe(val => {
        if (!val) {
          // this will be the ukey of the address
          this.posx = 0;
          this.ukey = this.db.createPushId();
        } else {
          this.addresses = [];
          const adarr = [];
          val.forEach(v => {
            console.log('vvvvv', v)
            let pth;
            if (v.scope === 'global') {
              pth = `${this.path}/data/addresses/${v.ukey}`;
              // todo: use the observable equivalent of Promises all.
            } else if (v.scope === 'local') {
              pth = `${this.path}/formvals/${this.formusesukey}/addresses/${v.ukey}`;
            }

            const p = this.fs.titleIXdb.object<any>(pth).valueChanges();
            adarr.push(p);

            Observable.zip(...adarr).take(1).subscribe(vals => {
              vals.forEach(vex => {
                vex['ukey'] = v.ukey;
                vex['scope'] = v.scope;
                const card = [];
                if (vex['street1'] && vex['street1'].value && vex['street1'].value[vex['street1'].value.length - 1]) {
                  card.push(vex['street1'].value[vex['street1'].value.length - 1]);
                }
                if (vex['street2'] && vex['street2'].value && vex['street2'].value[vex['street2'].value.length - 1]) {
                  card.push(vex['street2'].value[vex['street2'].value.length - 1]);
                }
                let city;
                if (vex['city'] && vex['city'].value) {
                  city = vex['city'].value[vex['city'].value.length - 1];
                }
                let state;
                if (vex['stateabbr'] && vex['stateabbr'].value) {
                  state = vex['stateabbr'].value[vex['stateabbr'].value.length - 1];
                }
                let zip;
                if (vex['uszip'] && vex['uszip'].value) {
                  zip = vex['uszip'].value[vex['uszip'].value.length - 1];
                }

                let country;
                if (vex['country'] && vex['country'].value) {
                  country = vex['country'].value[vex['country'].value.length - 1];
                }
                const cityline = this.admin.makeFullCityLine(city, state, zip, country);
                card.push(cityline);

                vex['card'] = card;
                console.log('vex', vex)
                console.log('card', card)

                this.addresses.push(vex);
                console.log('addresses', this.addresses[0]);

              });
            });

          });
        }
      });
  }

  onAdd() {
    this.edit = -1;
    this.data = [];
    // this.ukey =
    // this.scope = v.scope;

    this.molysin.forEach(m => {
      const descdata = new CAMolyData();
      descdata.ukey = m.jsonkey;
      descdata.name = m.name;
      descdata.molyukey = m.ukey;
      descdata.value = [];
      this.data.push(descdata);
    });
    this.adddetails = true;
    this.editting.emit(true);
  }

  onDelete(i) {
    this.addresses.splice(i, 1);
  }

  onEdit(i) {
    // this.ukey =
    // this.scope = v.scope;

    this.edit = i;
    this.adddetails = true;
    this.editting.emit(true);
  }

  onSaveFinish() {
    // save description
    let loc;
    if (this.scope === 'global') {
      loc = 'data';
    } else {
      loc = `formvals/${this.formusesukey}`;
    }

    this.is.setObjNoSnack(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`, {ukey: this.ukey, scope: this.scope});
    const p = `${this.path}/${loc}/addresses/${this.ukey}`;

    if (this.firsttime) {
      this.data.forEach(d => {

        console.log('d', d);
        if (!d.value) {
          d.value = [];
        }
        if (!d.value[this.posx]) {
          // @ts-ignore
          d.value[this.posx] = '';
        }

        this.is.setObjNoSnack(`${p}/${d.ukey}`, d);
      });

      this.firsttime = false;
    } else {
      /*      console.log('path', `${p}/emaildescription/value/${this.emaildescdata.value.length - 1}`);
            this.is.setObjNoSnack(`${p}/emaildescription/value/${this.emaildescdata.value.length - 1}`,
              this.emaildescdata.value[this.emaildescdata.value.length - 1]);
            this.is.setObjNoSnack(`${p}/emailaddress/value/${this.emaildata.value.length - 1}`,
              this.emaildata.value[this.emaildata.value.length - 1]);
*/
    }

    console.log('saved');
    this.adddetails = false;
    this.editting.emit(false);
    this.cd.detectChanges();
    // emit success
    // todo: not sure if have to emit the ukey
    this.save.emit(this.ukey);

  }


  onMap(location) {
    // const location = this.curadd.street1 + ', ' + this.curadd.city + ', ' + this.curadd.state + ' ' + this.curadd.zip;
    const geocoder = new google.maps.Geocoder();
    if (geocoder) {
      geocoder.geocode({'address': location}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const x = this.molysin.findIndex(m => m.jsonkey === 'lat');
          if (x > -1) {
            this.data[x].value.push(results[0].geometry.location.lat());
          }
          // results[0].geometry.location.lat();
          const y = this.molysin.findIndex(m => m.jsonkey === 'lng');
          if (y > -1) {
            this.data[y].value.push(results[0].geometry.location.lng());
          }

          this.http.getCounty(results[0].geometry.location.lat(), results[0].geometry.location.lng()).take(1).subscribe(res => {
            if (res) {
              console.log('res county', res);
            }
            this.onSaveFinish();
          });

        } else {
          console.log('no good');
          // todo: need to have an error message that its not a valid address?
          this.onSaveFinish();
        }
      });
    } else {
      // todo: need to have an error message that its not a valid address?
      this.onSaveFinish();
    }
  }

  onSave() {
    this.err = '';
    const addrdescukey = this.molysin.find(m => m.jsonkey === 'addrdesc').ukey;
    const addrdesc = this.data.find(d => d.molyukey === addrdescukey).value;
    if (!addrdesc) {
      this.err = 'Please enter an address description.';
      return;
    }

    const street1ukey = this.molysin.find(m => m.jsonkey === 'street1').ukey;
    const street1 = this.data.find(d => d.molyukey === street1ukey).value;

    const cityukey = this.molysin.find(m => m.jsonkey === 'city').ukey;
    const city = this.data.find(d => d.molyukey === cityukey).value;

    const stateukey = this.molysin.find(m => m.jsonkey === 'stateabbr').ukey;
    const state = this.data.find(d => d.molyukey === stateukey).value;

    const zipukey = this.molysin.find(m => m.jsonkey === 'uszip').ukey;
    const zip = this.data.find(d => d.molyukey === zipukey).value;

    if (!street1 || !city || !state || !zip) {
      this.err = 'Please enter a complete address';
      return;
    }

    const countryukey = this.molysin.find(m => m.jsonkey === 'country').ukey;
    const country = this.data.find(d => d.molyukey === countryukey).value;

    let location = street1 + ', ' + city + ', ' + state + ' ' + zip;

    if (country) {
      location = location + ' ' + country;
    }

    this.onMap(location);

  }

  onCancel() {
    this.adddetails = false;
    this.editting.emit(false);
  }

  onInbox(str) {
    this.scope = str;
    this.firsttime = true;
  }

  onSaveFormMoly(event, moly) {
    const ukey = this.molysin.find(m => m.jsonkey === moly.jsonkey).ukey;
    const tmp = this.data.find(d => d.molyukey === ukey);
    if (!tmp.value) {
      tmp.value = [];
    }
    tmp.value[this.posx] = event;
    console.log('tmp', tmp);
  }

  notHidden(moly): boolean {
    if (this.moly.moly.molys[0].hidden && moly.jsonkey && this.moly.moly.molys[0].hidden.includes(moly.jsonkey)) {
      return false;
    } else {
      return true;
    }
  }
}
