import {Injectable} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Observable} from 'rxjs';
import {ComOrg, Menu} from './interface.service';

@Injectable({
    providedIn: 'root'
})
export class TranslateService {

    constructor(private titleIXdb: FirebaseService) {
    }

    createTranslation(path: string, text: string): Observable<any> {

        this.titleIXdb.titleIXdb.object(path + 'en').set(text);
        this.titleIXdb.titleIXdb.object(path + 'translate').set(true);
        return this.titleIXdb.titleIXdb.object(path).valueChanges();
    }

    createExpandTranslation(path: string, menu: Menu): Observable<any> {

        menu.langDesc.translate = true;
        menu.nameDesc.translate = true;
        this.titleIXdb.titleIXdb.object(path).set(menu);

        return this.titleIXdb.titleIXdb.object(path).valueChanges();
    }

    createResourceTranslation(path: string, obj): Observable<any> {

        obj.langDesc.translate = true;
        obj.nameDesc.translate = true;
        this.titleIXdb.titleIXdb.object(path).set(obj);

        return this.titleIXdb.titleIXdb.object(path).valueChanges();
    }



}
