import { Component, OnInit } from '@angular/core';
import {InterfaceService} from '../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {RoehttpService} from '../services/roehttp.service';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'app-org-filters',
  templateUrl: './org-filters.component.html',
  styleUrls: ['./org-filters.component.scss']
})
export class OrgFiltersComponent implements OnInit {
  filter: string;
  details = false;

    constructor(public is: InterfaceService,
                private http: RoehttpService,
                private db: AngularFireDatabase,
                public admin: AdminService) {
    }

    ngOnInit() {
        if (!this.is.filters || this.is.filters.length === 0) {
            this.is.getFilters();
        }
    }

    onAdd() {
        this.filter = '';
        this.details = true;
    }

    onDelete(i) {
        this.is.filters.splice(i, 1);
        this.is.setObjNoLog(`Orgs/${this.admin.admin.org}/filters`, this.is.filters);
    }

    onSave() {
        this.is.filters.push(this.filter);
        this.is.setObjNoLog(`Orgs/${this.admin.admin.org}/filters`, this.is.filters);
        this.filter = '';
        this.details = false;
    }

}
