import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BizHour} from '../../../services/bizhours.service';

@Component({
    selector: 'app-biz-hours',
    templateUrl: './biz-hours.component.html',
    styleUrls: ['./biz-hours.component.css']
})
export class BizHoursComponent implements OnInit {
    tmmin: string;
    tmmax: string;

    @Input()
    block: BizHour;

    @Output()
    flipHour = new EventEmitter<any>();

    @Output()
    deleteHour = new EventEmitter<any>();

    @Output()
    addBlock = new EventEmitter<any>();


    constructor() {
    }

    ngOnInit() {
        const t = new Date(this.block.time);
        t.setMinutes(t.getMinutes() + this.block.len);
    }

    onClick(event, flip) {
        this.block.open = flip;
        event.stopPropagation();
        this.flipHour.emit();
    }

    onInputClick(event) {
        event.stopPropagation();

    }

    onBlur(val) {
        const t = new Date(this.block.time);
        const begin = t.getTime() + this.block.len * 60000;
        const newend = new Date (new Date(this.block.time).toDateString() + ' ' + val);
        const newlen = begin - newend.getTime();
        this.block.len = Math.round(newlen / 60000);
        this.block.tm = val
        this.block.time = newend;
/*
        t.setMinutes();


        if (x > t) {
            this.block.tm = this.tmmax;
        }
*/
        console.log('block', this.block);

    }

    onDelete(event) {
        event.stopPropagation();
        this.deleteHour.emit();
    }

    onAdd(event) {
        event.stopPropagation();
        this.addBlock.emit();
    }

}
