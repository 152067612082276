import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/forkJoin';



import {LoginComponent} from './security/login/login.component';
import {LoginStartComponent} from './security/login-start/login-start.component';
import {AuthComponent} from './security/author/auth.component';
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';
import {EmailConfirmationComponent} from './security/email-confirmation/email-confirmation.component';
import {OrgsComponent} from './capptivation/orgs/orgs.component';
import {ComorgComponent} from './capptivation/comorg/comorg.component';
import {NewOrgComponent} from './org-dashboard/new-org/new-org.component';
import {AddeditArticleComponent} from './org-dashboard/addedit-article/addedit-article.component';
import {CominheritComponent} from './community/cominherit/cominherit.component';
import {CommunityComponent} from './community/community.component';
import {ComChildDialogComponent} from './community/com-child-dialog/com-child-dialog.component';
import {OrgProfileComponent} from './org-profile/org-profile.component';
import {MmPartiesComponent} from './mm-chat/mm-parties/mm-parties.component';
import {GetDeviceIdComponent} from './lib/get-device-id/get-device-id.component';
import {AdminListComponent} from './capptivation/admins-list/admin-list.component';
import {ErrmsgComponent} from './capptivation/errmsg/errmsg.component';
import {ImportArticlesComponent} from './capptivation/import/import-articles.component';
import {MakeHolidayComponent} from './capptivation/make-holiday/make-holiday.component';
import {ComParentComponent} from './com-parent/com-parent.component';
import {EditWidgetComponent} from './capptivation/edit-widget/edit-widget.component';
import {OrgFiltersComponent} from './org-filters/org-filters.component';
import {PubTopicsComponent} from './capptivation/pub-topics/pub-topics.component';
import {HealthParentComponent} from './health-parent/health-parent.component';
import {CorpParentComponent} from './corp-parent/corp-parent.component';
import {CorpComponent} from './capptivation/corp/corp.component';
import {CaFormsComponent} from './capptivation/ca-forms/ca-forms.component';
import {InvPermsComponent} from './inv-perms/inv-perms.component';
import {InvitesComponent} from './invites/invites.component';
import { InviteCreateComponent } from './invite-create/invite-create.component';
import { InviteButtonComponent } from './invite-button/invite-button.component';
import {HelpBottomComponent} from './help-bottom/help-bottom.component';
import { InstParentComponent } from './inst-parent/inst-parent.component';
import { ArchivePickComponent } from './lib/archive/archive-pick/archive-pick.component';
import { WidgetsComponent } from './widgets/widgets.component';
import { LoginInstComponent } from './security/login-inst/login-inst.component';
import { AssetsComponent } from './capptivation/assets/assets.component';
import {AddressComponent} from './capptivation/forms/address/address.component';
import {EmploymentComponent} from './capptivation/forms/employment/employment.component';
import {GenformComponent} from './capptivation/forms/genform/genform.component';
import {IdentifiersComponent} from './capptivation/forms/identifiers/identifiers.component';
import { CaSpanComponent } from './capptivation/ca-span/ca-span.component';
import { CaEmailComponent } from './capptivation/ca-email/ca-email.component';
import { CaPhoneComponent } from './capptivation/ca-phone/ca-phone.component';
import { CaStringComponent } from './capptivation/ca-string/ca-string.component';
import { CaFormgenComponent } from './capptivation/ca-formgen/ca-formgen.component';
import { CaLinkComponent } from './capptivation/ca-link/ca-link.component';
import { CaAddressesComponent } from './capptivation/ca-addresses/ca-addresses.component';
import { CaUsernameComponent } from './capptivation/ca-username/ca-username.component';
import { CaStringLiteComponent } from './capptivation/ca-string-lite/ca-string-lite.component';
import {CaProfilepicComponent} from './capptivation/ca-profilepic/ca-profilepic.component';
import {ImageCropperComponent} from './services/imagecropper/component/image-cropper.component';
import { BlogComponent } from './blog/blog.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import { BlogContentComponent } from './blog/blog-content/blog-content.component';
import { BlogCardComponent } from './blog/blog-card/blog-card.component';
import { BlogCardbigComponent } from './blog/blog-cardbig/blog-cardbig.component';
import { BlogGuideComponent } from './blog-guide/blog-guide.component';
import {TitleCasePipe} from '@angular/common';
import { PublisherComponent } from './publisher/publisher.component';
import { PublisherCardComponent } from './publisher-card/publisher-card.component';
import { InstaGuideComponent } from './insta-guide/insta-guide.component';
import { FestivalsComponent } from './festivals/festivals.component';

@NgModule({
  declarations: [
    AppComponent,

    LoginComponent,
    LoginStartComponent,
    AuthComponent,
    EmailConfirmationComponent,
    OrgsComponent,
    ComorgComponent,
    AdminListComponent,

    OrgProfileComponent,
    AddeditArticleComponent,
    NewOrgComponent,

    ComParentComponent,
    CommunityComponent,
    CominheritComponent,
    ComChildDialogComponent,

    MmPartiesComponent,
    GetDeviceIdComponent,
    ErrmsgComponent,
    ImportArticlesComponent,
    MakeHolidayComponent,
    EditWidgetComponent,
    OrgFiltersComponent,
    PubTopicsComponent,
    HealthParentComponent,
    CorpParentComponent,
    CorpComponent,

    CaFormsComponent,
    AddressComponent,
    EmploymentComponent,
    GenformComponent,
    IdentifiersComponent,

    InvPermsComponent,
    InvitesComponent,
    InviteCreateComponent,
    InviteButtonComponent,

    HelpBottomComponent,

    InstParentComponent,

    ArchivePickComponent,

    WidgetsComponent,

    LoginInstComponent,

    AssetsComponent,

    CaSpanComponent,

    CaEmailComponent,

    CaPhoneComponent,

    CaStringComponent,

    CaFormgenComponent,

    CaLinkComponent,

    CaAddressesComponent,

    CaUsernameComponent,

    CaStringLiteComponent,

    CaProfilepicComponent,

    ImageCropperComponent,

    BlogComponent,

    BlogContentComponent,

    BlogCardComponent,

    BlogCardbigComponent,

    BlogGuideComponent,

    PublisherComponent,

    PublisherCardComponent,

    InstaGuideComponent,

    FestivalsComponent,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatPasswordStrengthModule,
    SwiperModule,

    SharedModule,
  ],
  entryComponents: [
    AddeditArticleComponent,
    HelpBottomComponent,

    NewOrgComponent,
    CominheritComponent,
    ComChildDialogComponent,
    GetDeviceIdComponent,

    ArchivePickComponent
  ],
  providers: [
    TitleCasePipe
  ],
  exports: [
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}

/*
to build: set admin.mock = false;
to build: node --max_old_space_size=12000 ./node_modules/.bin/ng build
*/
