import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AngularFireAuth} from '@angular/fire/auth';
import {RoehttpService} from '../../services/roehttp.service';
import {AdminService} from '../../services/admin.service';

@Component({
    selector: 'app-get-device-id',
    templateUrl: './get-device-id.component.html',
    styleUrls: ['./get-device-id.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GetDeviceIdComponent implements OnInit {
    getid = true;
    code1: string;
    code2: string;
    code3: string;
    deviceid: string;
    cd: string;

    @ViewChild('f2', {static: false}) f2: any;
    @ViewChild('f3', {static: false}) f3: any;


    constructor(public ht: RoehttpService,
                public admin: AdminService,
                public dialogRef: MatDialogRef<GetDeviceIdComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }


    onSubmit() {
        if (this.deviceid && this.deviceid[0] === 'C') {
            this.getid = false;
            this.cd = this.admin.randomString(false, 3);
            this.deviceid = this.deviceid.substring(1);
            this.ht.postROEValidDeviceId(this.deviceid, this.cd);
        }
    }

    onKeyup(i) {
        if (i === 1 && this.code1 && this.code1.length === 1) {
            this.f2.nativeElement.focus();
        }
        if (i === 2 && this.code2 && this.code2.length === 1) {
            this.f3.nativeElement.focus();
        }
    }

    onCancel() {
        this.dialogRef.close();
    }

    onSubmitCode() {
        if (this.code1 + this.code2 + this.code3 === this.cd) {
            this.admin.admin.deviceid = parseInt(this.deviceid, 10);
            this.ht.postROESetDeviceId(this.admin.admin.deviceid, this.admin.admin.ukey, this.admin.admin.firebaseuid);
            this.dialogRef.close(true);
        }
    }
}
