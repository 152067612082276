import {Component, Input, OnInit} from '@angular/core';
import {computeDistanceBetween} from 'spherical-geometry-js';
import {ComOrg} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
declare var google: any;

@Component({
    selector: 'app-com-card',
    templateUrl: './com-card.component.html',
    styleUrls: ['./com-card.component.css']
})
export class ComCardComponent implements OnInit {
    @Input()
    m: ComOrg;

    @Input()
    curloc = 0;

    @Input()
    curprog = 0

    @Input()
    userlat: number;

    @Input()
    userlong: number;

    distance = -1;

    constructor(public admin: AdminService) {
    }

    calcDistance() {
        //  todo: this is a cludge because what if more than one locations????
        if (this.userlat && this.userlong && this.m.locations[0].geo.lat && this.m.locations[0].geo.long) {
            const x = computeDistanceBetween(
                new google.maps.LatLng(this.m.locations[0].geo.lat, this.m.locations[0].geo.long),
                new google.maps.LatLng(this.userlat, this.userlong));
            this.distance = Math.round(x * 0.000621371 * 100) / 100;
            console.log('distance', this.distance)
        }
    }

    ngOnInit() {
        console.log('m', this.m.locations[this.curloc])
        this.calcDistance();

    }

}
