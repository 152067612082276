import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BizHoursContainerComponent} from '../../../lib/biz-hours/biz-hours-container/biz-hours-container.component';
import {AdminService} from '../../../services/admin.service';
import {BizhoursService, BizYear} from '../../../services/bizhours.service';

@Component({
    selector: 'app-orgbizhours',
    templateUrl: './orgbizhours.component.html',
    styleUrls: ['./orgbizhours.component.scss']
})
export class OrgbizhoursComponent implements OnInit {
    @Input()
    pth: string;

    regulardays: any[];
    hours: any[];
    twentfourseven: boolean;
    picked: boolean;
    tmpYear: BizYear;
    isdialog = false;

    @ViewChild(BizHoursContainerComponent, {static: false}) bizhrcontainer: BizHoursContainerComponent;


    constructor(private admin: AdminService,
                private bz: BizhoursService) {
        this.picked = false;
    }

    ngOnInit() {
        this.fetchhours(`${this.pth}/${this.admin.admin.org}`);
    }


    fetchhours(ukey) {
        this.bz.getregulardayz(ukey + '/regulardays').take(1).subscribe(hrs => {
            this.regulardays = hrs;

            this.bz.gettwentfoursevenz(ukey + '/twentyfourseven').take(1).subscribe(s => {
                this.twentfourseven = s;

                this.bz.getbizhourz(ukey + '/hours').take(1).subscribe(h => {
                    this.hours = h;
                    // this.bizhours = this.setuphours(this.bizhours);
                    this.tmpYear = this.admin.setuphours(this.hours, this.regulardays, this.twentfourseven);
                });
            });
        });
    }

    onSave() {
        this.bizhrcontainer.savebz();
    }
}
