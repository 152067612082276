import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {InterfaceService, LangStringBlock, Slg, SlgDesc} from '../../services/interface.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {SlgDescriptionsComponent} from '../slg-descriptions/slg-descriptions.component';
import {ConfirmationComponent} from '../confirmation/confirmation.component';
import FroalaEditor from 'froala-editor';
import {Languages} from '../../services/constants';
import {TranslateService} from '../../services/translate.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  slg: Slg;

  @Input()
  title;

  @Input()
  primaryService: string;

  @Input()
  langDesc: LangStringBlock;

  @Input()
  curlang: string;

  @Input()
  options;

  @Output()
  update = new EventEmitter<LangStringBlock>()

  $sub1;
  editor;
  languages = Languages;

  str = '';
  path = 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/';

  // @ViewChild('ed', {static: false}) ed: ElementRef;
  // @ViewChild(FroalaEditor) directiveRef?: FroalaEditor;

  constructor(private slgs: InterfaceService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              public cdialog: MatDialog,
              public admin: AdminService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.editor && this.editor.html) {
      this.editor.html.set(this.langDesc[this.curlang].description);
    } else {
      this.str = this.langDesc[this.curlang].description;
    }

  }

  onLangChange(i) {
    this.curlang = this.languages[i].abbr;
    this.options.language = this.curlang;
    this.str = this.langDesc[this.curlang].description;
  }

  ngAfterViewInit() {
    this.editor = new FroalaEditor('div#ed', {}, () => {
    });

    if (this.primaryService) {
      this.$sub1 = this.slgs.getObject('Interfaces/slgs/' + this.primaryService).subscribe(val => {
        this.slg = val;
      });
    }
  }

  onSave(): LangStringBlock {
    this.langDesc[this.curlang].description = this.editor.html.get();
    return this.langDesc;
  }

  async onTransEl() {
    await this.languages.forEach(lang => {
      if (lang.abbr !== this.curlang && !this.langDesc[lang.abbr].block) {
        this.admin.postTrans(lang.abbr, this.langDesc[this.curlang].description).then(val => {
          this.langDesc[lang.abbr].description = val['data'].translations[0].translatedText;
          // this is a cludge because of the way google does translates
          this.langDesc[lang.abbr].description = this.langDesc[lang.abbr].description.replace(/Assets\//g, 'Assets%2F')
          this.langDesc[lang.abbr].description = this.langDesc[lang.abbr].description.replace(/\/archive\/graphics\//g, '%2Farchive%2Fgraphics%2F')
          this.langDesc[lang.abbr].description = this.langDesc[lang.abbr].description.replace(/Inst\//g, 'Inst%2F')
        });
      }
    });

    this.snackBar.open('Translation complete', '', {
      duration: 1000,
    });
  }

  onTrans() {
    // this.langDesc[this.curlang].description = this.editor.html.get();
    this.onTransEl();
  }

  // https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Inst/1607035265141/archive/graphics%2Fwarren.jpeg?alt=media
  // https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Inst%2F1607035265141%2Farchive%2Fgraphics%2Fwarren.jpeg?alt=media
  // https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Insts%2F1607035265141%2Farchive%2Fgraphics%2Fwarren.jpeg?alt=media

  onPick() {
    const dialogRef = this.dialog.open(SlgDescriptionsComponent, {
      width: '800px',
      data: {slgdesc: this.slg.slgarray, curlang: this.curlang}
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.langDesc = this.slg.slgarray[result.pos].description;
        }
      }
    );

  }

  onContribute() {
    const dialogRef = this.cdialog.open(ConfirmationComponent, {
      width: '400px',
      data: {text: 'Your description will be added to our database for use by others.'}
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result && result.answer) {

          const x = this.slg.slgarray.findIndex(c => (c.source === this.admin.admin.org));
          if (x < 0) {
            const tmp = new SlgDesc();
            tmp.source = this.admin.admin.org;
            tmp.description = this.langDesc;
            tmp.contributor = this.admin.admin.ukey;
            this.slg.slgarray.push(tmp);
          } else {
            this.slg.slgarray[x].description = this.langDesc;
          }
          this.slgs.setObj('Interfaces/slgs/' + this.primaryService + '/slgarray', this.slg.slgarray, 'Slug', '');
        }
      }
    );
  }

  onUpdate(event) {
    this.langDesc[this.curlang].description = event;
    this.update.emit(this.langDesc);
  }


  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
  }

}
