import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InterfaceService} from '../../services/interface.service';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-ctfromi',
  templateUrl: './ctfromi.component.html',
  styleUrls: ['./ctfromi.component.css']
})
export class CtfromiComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;


  @Output()
  delete = new EventEmitter();

  constructor(public admin: AdminService,
              public is: InterfaceService) {
    super(admin, is);
  }

  ngOnInit() {
    super.ngOnInit();

  }

  onDelete() {
    this.delete.emit();
  }


}
