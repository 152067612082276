import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import {AdminService} from '../../../services/admin.service';
import {InterfaceService, Invitation} from '../../../services/interface.service';
import {FirebaseService} from '../../../services/firebase.service';

@Component({
    selector: 'app-holidays',
    templateUrl: './holidays.component.html',
    styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent implements OnInit {
    @Input()
    pth: string;

    holidaydetails: boolean;
    tmpholidays: Invitation[];
    curholiday: Invitation;

    form = new FormGroup({
        title: new FormControl('', Validators.required),
        start: new FormControl(''),
    });

    get title() {
        return this.form.get('title');
    }

    get start() {
        return this.form.get('start');
    }

    constructor(private admin: AdminService,
                private is: InterfaceService,
                private titleIXdb: FirebaseService) {
        this.holidaydetails = false;
    }

    ngOnInit() {
        this.tmpholidays = [];
        this.is.getList(`${this.pth}/${this.admin.admin.org}/holidays`).take(1).subscribe(holidays => {
            this.tmpholidays = holidays;
            this.tmpholidays = _.orderBy(this.tmpholidays, ['start'], ['asc']);
        });
    }

    setValue(tmp) {

        this.form.setValue({
            title: tmp.title,
            start: tmp.start,
        });
    }

    onDeleteHoliday(i) {
        // Todo: need to ask if they are sure
        this.is.deleteObj(`${this.pth}/${this.admin.admin.org}/holidays/${this.tmpholidays[i].ukey}`, 'Holiday', '');
    }

/*
    onEditHoliday(i) {
        this.curholiday = this.tmpholidays[i];
        this.setValue(this.curholiday);
        this.holidaydetails = true;
    }
*/

    onAddHoliday() {
        this.curholiday = new Invitation();
        this.curholiday.ukey = this.titleIXdb.titleIXdb.createPushId();
        this.curholiday.title = '';
        this.curholiday.start = new Date().getTime();
        this.curholiday.end = new Date().getTime();
        this.curholiday.allDay = true;
        this.curholiday.gspoints = 0;
        this.curholiday.gspId = '';
        this.curholiday.pubev = false;

        this.setValue(this.curholiday);
        this.holidaydetails = true;
    }

    onCancel() {
        this.holidaydetails = false;
    }

    onContinueImport(c) {
        this.is.getList(`Holidays/${c}`).take(1).subscribe(hols => {
            hols.forEach(h => {
                const x = this.tmpholidays.findIndex(t => t.title === h.title);
                if (x === -1) {
                    this.tmpholidays.push(h)
                    this.is.setObj(`${this.pth}/${this.admin.admin.org}/holidays/${h.ukey}`, h, 'Import', 'Holiday');
                }
            })


            this.tmpholidays = _.orderBy(this.tmpholidays, ['start'], ['asc']);


        })
    }

    onImport() {
        if (this.pth === 'Community') {
            this.onContinueImport(this.is.curComOrg.locations[0].country);
        } else {
            this.is.getObject(`Orgs/${this.admin.admin.org}/profile/orgdata/country`).take(1).subscribe(c => {
                this.onContinueImport(c);
            })
        }
    }

    onSave() {
        this.curholiday.title = this.title.value;
        this.curholiday.start = new Date(this.start.value).getTime();

        this.tmpholidays.push(this.curholiday);
        this.tmpholidays = _.orderBy(this.tmpholidays, ['start'], ['asc']);

        this.holidaydetails = false;
        this.is.setObj(`${this.pth}/${this.admin.admin.org}/holidays/${this.curholiday.ukey}`, this.curholiday, 'Holiday', this.curholiday.title);
    }

}
