import { Component, OnInit } from '@angular/core';
import {InterfaceService, OrgEdition, Topix} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {OrganizationService} from '../services/organization.service';

@Component({
  selector: 'app-org-comscenter',
  templateUrl: './org-comscenter.component.html',
  styleUrls: ['./org-comscenter.component.scss']
})
export class OrgComscenterComponent implements OnInit {
  caller: string;
  topics: Topix[];
  eds: OrgEdition[];
  loc = 'Orgs';


  constructor(public admin: AdminService,
              private og: OrganizationService,
              private is: InterfaceService) { }

  ngOnInit() {

    if (this.loc === 'Orgs') {
      this.eds = this.og.curOrg.editions;
    }

    this.is.getList(`OrgsPri/${this.admin.admin.org}/topics`).take(1).subscribe(ts => {
      this.topics = ts;
      console.log('topics', this.topics)
    });

    this.is.getList(`Orgs/${this.admin.admin.org}/profile/orgeditions`)
      .take(1)
      .subscribe(e => {
        this.eds = e;
      });

    this.caller = `OrgsPri/${this.admin.admin.org}`;
  }

}
