import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import {InterfaceService} from '../../services/interface.service';
import {CAMetaData} from '../../services/ca';
import {AngularFireDatabase} from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class CaServices {
  loc = 'CA';
  path = 'CA/users';
  validators: any[];

  indivukey = '-M90vORAvGP9bq37sK-v';
  iduser = '';
  idname = '-LjaOMdyLf7YlbZ8ulw3';
  idprofile = `-LjzEoWrrwz1vlow8Xdm`;
  idemployment = `-LsIdscKktnpQ4pDn_w7`;


  validator;
  profilepics = {};
  metadata: CAMetaData[];

  constructor(private fs: InterfaceService,
              private db: AngularFireDatabase,) {
  }

  async getValue(user, ukey) {
    const list = await this.fs.getPromiseList(`${this.path}/${user}/data/${ukey}`);
    let val;
    if (list && list[list.length - 1] && list[list.length - 1].value && list[list.length - 1].value[0] && list[list.length - 1].value[0]['val']) {
      val = list[list.length - 1].value[0]['val'];
    }
    return val;
  }

  getPromiseQuery(pth, child, key): Promise<any> {
    return this.db.list(pth,
      ref => ref.orderByChild(child).equalTo(key))
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async getName(user, id) {
    let val;
    const iduk = await this.getPromiseQuery(`${this.path}/${user}/datamap`, 'idukey', id);
    if (iduk && iduk[0] && iduk[0].ukey) {
      const list = await this.fs.getPromiseList(`${this.path}/${user}/data/${iduk[0].ukey}`);
      if (list && list[list.length - 1] && list[list.length - 1].value && list[list.length - 1].value[0] && list[list.length - 1].value[0]['val']) {
        val = list[list.length - 1].value[0]['val'];
      }
    }
    return val;

    // return list[list.length - 1].value[0]['val'];
  }


  async getValuePos(user, ukey, pos) {
    const list = await this.fs.getPromiseList(`${this.path}/${user}/data/${ukey}`);
    let val;
    if (list && list[pos] && list[pos].value && list[pos].value[0] && list[pos].value[0]['val']) {
      val = list[pos].value[0]['val'];
    }
    return val;
  }

  getMetaData() {
    this.fs.getList('CA/metadata').pipe(take(1)).subscribe(list => {
      this.metadata = _.orderBy(list, 'label', 'asc');
    });
  }

}
