import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import {DomSanitizer} from '@angular/platform-browser';
import {CappPolicy, InterfaceService, LangStringBlock} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import FroalaEditor from 'froala-editor';
import {TranslateService} from '../../services/translate.service';
import {Languages} from '../../services/constants';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  list;
  cats;
  adverts;
  emojis;
  empath = 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2F';
  adpath = 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Adverts%2F';
  path = 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Assets%2F';
  catpath = this.path + 'catalog-icons%2F';


  policies: CappPolicy[];
  curpolicy: CappPolicy;
  details = false;
  trans = false;

  policy: LangStringBlock;
  editor;
  languages = Languages;
  pos: number;


  options = {
    key: 'wFE7nB4D3F4C3B10B6eyznxmE2cd1rsplpfG-7c1eeF6nablA3C11B8A4D2B4F3G2F3B2==',
    charCounterCount: true,
    language: 'en',
    heightMin: 300,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'color', 'align', 'formatOL', 'formatUL',
      'outdent', 'indent', 'spellChecker', 'html']
  };

  constructor(private db: AngularFireDatabase,
              private is: InterfaceService,
              public admin: AdminService,
              private cd: ChangeDetectorRef,
              private dm: DomSanitizer) {
  }

  ngOnInit() {
    this.policies = [];
    this.is.getList('PolicyDesc')
      .take(1)
      .subscribe(ps => {
        this.policies = ps;
      });

    this.adverts = [];
    firebase.storage().ref('Adverts').listAll().then(list => {
      // console.log('list', list)
      list.items.forEach(i => {
        const x = this.dm.bypassSecurityTrustUrl(this.adpath + i.name + '?alt=media');
        this.adverts.push({path: x, name: i.name});
      });
    }).catch(err => {
      console.log('error', err);
    });

    this.emojis = [];
    firebase.storage().ref('Emojis').listAll().then(list => {
      // console.log('list', list)
      list.items.forEach(i => {
        const x = this.dm.bypassSecurityTrustUrl(this.empath + i.name + '?alt=media');
        this.emojis.push({path: x, name: i.name});
      });
    }).catch(err => {
      console.log('error', err);
    });

    this.list = [];
    firebase.storage().ref('Assets').listAll().then(list => {
      // console.log('list', list)
      list.items.forEach(i => {
        const x = this.dm.bypassSecurityTrustUrl(this.path + i.name + '?alt=media');
        this.list.push({path: x, name: i.name});
      });
    }).catch(err => {
      console.log('error', err);
    });

    this.cats = [];
    firebase.storage().ref('Assets/catalog-icons').listAll().then(list => {
      // console.log('list', list)
      list.items.forEach(i => {
        const x = this.dm.bypassSecurityTrustUrl(this.catpath + i.name + '?alt=media');
        this.cats.push({path: x, name: i.name});
      });
    }).catch(err => {
      console.log('error', err);
    });

  }

  onAddPolicy() {
    this.pos = -1;
    this.details = true;
    this.policy = new LangStringBlock();
    this.curpolicy = new CappPolicy();

  }


  onEditPolicy(i) {
    this.pos = i;
    this.details = true;
    this.curpolicy = this.policies[i];
    this.policy = new LangStringBlock();
    this.is.getObject(`Policies/${this.curpolicy.ukey}-en`)
      .take(1)
      .subscribe(p =>
        this.policy['en'].description = p
      );
  }

  onDeletePolicy(i) {

  }


  async onTransEl() {
    await this.languages.forEach(lang => {
      if (lang.abbr !== 'en') {
        this.admin.postTrans(lang.abbr, this.curpolicy.name['en'].description).then(val => {
          this.curpolicy.name[lang.abbr].description = val['data'].translations[0].translatedText;
        });

        this.admin.postTrans(lang.abbr, this.policy['en'].description).then(val => {
          this.policy[lang.abbr].description = val['data'].translations[0].translatedText;
        });

      }
    });
  }


  onSavePolicy() {

    if (this.curpolicy && this.curpolicy.ukey && this.curpolicy.ukey.length > 6) {
      this.editor = new FroalaEditor('div#ed', {}, () => {
      });

      this.policy['en'].description = this.editor.html.get();

      // todo: need to work out a better way to translate
      // this.onTransEl();
      if (this.pos === -1) {
        if (!this.policies) {
          this.policies = [];
          this.policies.push(this.curpolicy);
        }
      } else {
        this.policies[this.pos] = this.curpolicy;
      }

      this.is.setObjNoLog(`PolicyDesc/${this.curpolicy.ukey}`, this.curpolicy);
      this.is.setObjNoLog(`Policies/${this.curpolicy.ukey}-en`, this.policy['en'].description)

/*
      this.languages.forEach(l => {
            console.log('policy', this.policy[l.abbr].description);
            this.is.setObjNoLog(`Policies/${this.curpolicy.ukey}-${l.abbr}`, this.policy[l.abbr].description)
      })
*/

      this.details = false;
      this.cd.detectChanges();
      console.log('policy', this.policy);
      console.log('curpolicy', this.curpolicy);

    }
  }
}
