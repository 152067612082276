import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {BehaviorSubject} from 'rxjs';
import {InterfaceService} from './interface.service';
import * as _ from 'lodash';
import {AdminService, MMMes} from './admin.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    $sub4;
    $sub5;
    $sub6;
    res: boolean;

    lastmess = 0;
    oldchat: string;

    private resSource = new BehaviorSubject<boolean>(this.res);
    resloc = this.resSource.asObservable();


    constructor(private db: AngularFireDatabase,
                private is: InterfaceService,
                private rs: AdminService) {
    }


    onChat(iukey: string) {
        const pth = `ROEDevices/${this.rs.admin.deviceid}/conversations/${iukey}`;

        if (this.oldchat !== iukey) {
            this.oldchat = iukey;

            if (this.$sub4) {
                this.$sub4.off();
            }
            if (this.$sub5) {
                this.$sub5.off();
            }
            if (this.$sub6) {
                this.$sub6.off();
            }

            this.rs.messages = [];
            this.rs.key = -1;
            this.$sub4 = this.db.list(pth).query;
            this.$sub4.on('child_added', val => {
                const m: MMMes = val.val();
                console.log('m', m)
                // this bit of code is used to determine if it's time to show the data time stamp in the chat window sd = showdate
                if (m.d > this.lastmess + 6000000) {
                    m['sd'] = true;
                    this.lastmess = m.d;
                } else {
                    m['sd'] = false;
                }
                this.rs.messages.push(m);
                this.resSource.next(true);
            });


            this.$sub5 = this.db.list(pth).query;
            this.$sub5.on('child_changed', val => {
                const x = this.rs.messages.findIndex(m => m.d === val.val()['d']);
                if (x > -1) {
                    this.rs.messages[x].e = val.val()['e'];
                    this.resSource.next(true);
                }
            });


            this.$sub6 = this.db.list(pth).query;
            this.$sub6.on('child_removed', val => {
                const x = this.rs.messages.findIndex(m => m.d === val.val()['d']);
                if (x > -1) {
                    if (this.rs.messages[x].t === 'k' && this.rs.messages[x].f !== this.rs.admin.deviceid.toString()) {
                        this.rs.key = -1;
                    }
                    this.rs.messages.splice(x, 1);
                    this.resSource.next(true);
                }
            });

        }
    }



    onDestroy() {
        if (this.$sub4) {
            this.$sub4.off();
        }
        if (this.$sub5) {
            this.$sub5.off();
        }
        if (this.$sub6) {
            this.$sub6.off();
        }

    }
}
