import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Address} from '../../services/interface.service';
import {AgmMap} from '@agm/core';
import {RoehttpService} from '../../services/roehttp.service';
declare var google: any;

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {
  @Input()
  addresses: Address[] = [];

  @Input()
  onlyone = false;


  @Output()
  saveit = new EventEmitter<Address[]>()

  @Output()
  adding = new EventEmitter();

  details = false;
  curadd: Address;
  latt = -1;
  lngg = -1;
  err: string;
  edit = -1;

  // todo: need to connect addresses to the data dictionary
  @ViewChild(AgmMap, {static: false}) mapElement: any;

  constructor(private cd: ChangeDetectorRef,
              private http: RoehttpService) { }

  ngOnInit(): void {
    if (!this.addresses) {
      this.addresses = [];
    }
  }

  onDelete(i) {
    this.addresses.splice(i, 1);
    this.saveit.emit(this.addresses);
  }

  onEdit(i) {
    this.curadd = this.addresses[i];
    this.edit = i;
    this.details = true;
  }

  onMap() {
    this.curadd.lat = -1;
    this.curadd.long = -1;
    const location = this.curadd.street1 + ', ' + this.curadd.city + ', ' + this.curadd.state + ' ' + this.curadd.zip;;
    if (this.mapElement) {
      this.mapElement._mapsWrapper.getNativeMap().then(map => {
        const geocoder = new google.maps.Geocoder();
        if (geocoder) {
          geocoder.geocode({'address': location}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.curadd.lat = results[0].geometry.location.lat();
              this.curadd.long = results[0].geometry.location.lng();
              this.http.getCounty(this.curadd.lat, this.curadd.long).take(1).subscribe(res => {
                if (res) {
                  this.curadd.county = res;
                }
                this.cd.detectChanges()
              });
            } else {
              console.log('no good');
            }
          });
        }
      }).catch(err => console.log('no good'));
    }
  }

  onAdd() {
    this.edit = -1;
    this.curadd = new Address();
    this.details = true;
    this.adding.emit();
  }

  onSave() {
    if (!this.curadd.oneline) {
      this.err = 'Please add a description.';
      return;
    }
    this.details = false;
    if (this.edit === -1) {
      this.addresses.push(this.curadd);
    } else {
      this.addresses[this.edit] = this.curadd;
    }
    this.saveit.emit(this.addresses);
  }

  onCancel() {
    this.details = false;
  }

}
