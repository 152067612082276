import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import * as algoliasearch from 'algoliasearch';

@Component({
    selector: 'app-search-by-name',
    templateUrl: './search-by-name.component.html',
    styleUrls: ['./search-by-name.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class SearchByNameComponent implements OnInit, OnDestroy {
    @Output()
    orgpicked = new EventEmitter<string>();

    selected = -1;

    searchConfig = {
        searchClient: algoliasearch(`A4YFG99HMY`, `373dbe79a969c04fca8f3ea347126b65`),
        indexName: 'Community',
        routing: true
    };

    constructor() {
    }


    ngOnInit() {
    }

    onSelect(hit, i) {
        this.selected = i;
        this.orgpicked.emit(hit.name);
    }


    ngOnDestroy() {
    }

}

