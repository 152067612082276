import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CappTeamMsg, InterfaceService} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {AngularFireDatabase} from '@angular/fire/database';

@Component({
  selector: 'app-roe-aa',
  templateUrl: './roe-aa.component.html',
  styleUrls: ['./roe-aa.component.scss']
})
export class RoeAaComponent  extends RoeTemplateComponent implements OnInit, AfterViewInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  @Output()
  changehorse = new EventEmitter();

  colone = '25%';
  coltwo = '75%';
  primarycolor: string;
  secondarycolor: string;
  from: string;
  flipit = false;
  ht: number;
  profile: string;
  err: string;

  ask = false;
  dys = 0;

  @ViewChild('content', {static: false}) elementView: ElementRef;

  constructor(public is: InterfaceService,
              private cd: ChangeDetectorRef,
              private db: AngularFireDatabase,
              public admin: AdminService) {
    super(admin, is);
    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();
  }

  ngOnInit() {
    super.ngOnInit();

    const x = Math.round(9000 / this.admin.appwidth);
    this.colone = `${x}%`;
    this.coltwo = `${100 - x}%`;
    this.from = this.n.cn.replace('Survivor', 'ROE');

    if (this.n.ty === 'Code-invited') {
      const d = new Date().getTime();
      this.dys = this.admin.calcDays(this.n.date);
      if (this.dys >= 7) {
        console.log('invited days', this.dys);
        this.ask = true;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.elementView.nativeElement) {
      this.ht = this.elementView.nativeElement.offsetHeight;
      this.cd.detectChanges();
    }
  }

  onDelete() {
    this.delete.emit();
  }


}
