import {Injectable} from '@angular/core';
import {Admin, AdminService, MMMes} from './admin.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AngularFireAuth} from '@angular/fire/auth';
import {CappTeamMsg, InterfaceService, NotificationForm} from './interface.service';
import * as firebase from 'firebase/app';
import {Observable} from 'rxjs';
import {AngularFireFunctions} from '@angular/fire/functions';
import {AngularFireDatabase} from '@angular/fire/database';



@Injectable({
  providedIn: 'root'
})
export class RoehttpService {

  constructor(public admin: AdminService,
              private cf: AngularFireFunctions,
              private is: InterfaceService,
              private db: AngularFireDatabase,
              public authx: AngularFireAuth,
              private http: HttpClient) {
  }

  shareArticle(obj: any): Observable<any> {
    const field = this.cf.httpsCallable('shareArticle');
    return field({
      email: obj.email,
      from: obj.from,
      subject: obj.subject,
      publisher: obj.publisher,
      title: obj.title,
      imageurl: obj.imageurl,
      msg: obj.msg,
      link: obj.link,
    })
  }

  getViatorPhotos(seoId): Observable<any> {
    const getphotos = this.cf.httpsCallable('adventures-getViatorPhotos');
    return getphotos({
      seoId,
      uid: this.authx.auth.currentUser.uid
    });
  }

  viatorProductsSearch(destId, tags, highestPrice, startDate, endDate, start, count): Observable<any> {
    console.log('im here', destId)
    const viatorproductssearch = this.cf.httpsCallable('adventures-viatorProductsSearch');
    return viatorproductssearch({
      destId,
      tags,
      highestPrice,
      startDate,
      endDate,
      start,
      count,
      uid: this.authx.auth.currentUser.uid
    });
  }

  /*
      const addIncident = firebase.functions().httpsCallable('addIncidentRole');
      addIncident({incidentId: '11262019NOEC', deviceId: 1002646}).then(result => {
      console.log('res', result)
  });
  */

  getMeta(link): Observable<any> {
    const url = `https://api.microlink.io/?url=${link}`;
    return this.http.get(url);
  }


  getTouristFeed(name: string, objectID: string, feed: string, icon: string): Promise<any> {
    const url = `https://us-central1-library-titleix.cloudfunctions.net/rssFeeds-getTouristFeed?name=${name}&objectID=${objectID}&feed=${feed}&icon=${icon}&numdays=300`;
    return this.authx.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
      return this.http.get(url, {headers: headersv}).toPromise();
    });

  }

  getSinglePhoto(datapid: string) {
    const getarticledata = 'https://us-central1-library-titleix.cloudfunctions.net/extension-getSinglePhoto';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(getarticledata,
          {
            datapid
          },
          {headers: headersv}).toPromise();
      })
      .then(res => {
        console.log('RES', res)
        return res;
      });
  }

  parseArticle(url: string, domain: string) {
    const getarticledata = 'https://us-central1-library-titleix.cloudfunctions.net/rssFeeds-parseArticle';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        const test = 'test'
        return this.http.post(getarticledata,
          {
            url,
            domain
          },
          {headers: headersv}).toPromise();
      })
      .then(res => {
        console.log('RES', res)
        return res;
      });
  }




  getArticleData(url: string) : Promise<any> {
    const getarticledata = 'https://us-central1-library-titleix.cloudfunctions.net/rssFeeds-getArticleData';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(getarticledata,
          {
            url
          },
          {headers: headersv}).toPromise();
      })
  }

  rssfixInFrame(curguide, thefix, typeoffix): Observable<any> {
    const field = this.cf.httpsCallable('rssFeeds-fixInFrame');
    return field({
      uid: this.authx.auth.currentUser.uid,
      curguide,
      thefix,
      typeoffix
    })
  }

  delTravelGuide(curwish): Observable<any> {
    const field = this.cf.httpsCallable('rssFeeds-delTravelGuide');
    return field({
      deviceId: 1003663,
      uid: this.authx.auth.currentUser.uid,
      curwish
    })
  }

  delGuide(curguide): Observable<any> {
    const delguide = this.cf.httpsCallable('rssFeeds-delGuide');
    return delguide({
      curguide,
      uid: this.authx.auth.currentUser.uid
    });
  }

  getMMUsernameValid(newun: string, ukey: string, oldun: string): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getMMUsernameValid';
    // If true, then we can calculate a compatiblity index;
    return this.authx.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

      const keys = `${newun}+${ukey}+${oldun}`;
      const paramsv = new HttpParams().set('keys', keys);
      return this.http.get(url, {headers: headersv, params: paramsv});
    });
  }

  /*
    getTimeZone(lat, lng, key): Promise<any>  {
      const url = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/timezone/json';

      const str = `${lat},${lng}`;
      const tm = Math.trunc(this.admin.getIntTime() / 1000).toString();
      const params = new HttpParams()
        .set('location', str)
        .set('timestamp', tm)
        .set('key', key);

      return this.authx.auth.currentUser.getIdToken()
        .then(authToken => {
          const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

          return this.http
            .get(url, {headers: headersv, params});
        }).then(res => {
          return res;
        });
    }
  */

  goPropogate() {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/rssFeeds-getPropogate'
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        const test = 'test'
        return this.http.post(url,
          {
            test
          },
          {headers: headersv}).toPromise();
      })
      .then(res => {
        return res;
      });
  }

  getTimeZone(lat, lng, key) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getGoogleTimeZone';
    const loc = `${lat},${lng}`;
    const tz = new Date().getTimezoneOffset();
    const tzx = new Date().getTime() + tz * 60000;

    const tm = Math.trunc(tzx / 1000).toString();
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            loc,
            tm
          },
          {headers: headersv}).toPromise();
      })
      .then(res => {
        return res;
      });
  }




  propGuide(curguide): Observable<any> {
    const propguide = this.cf.httpsCallable('rssFeeds-propGuide');
    return propguide({
      curguide,
      uid: this.authx.auth.currentUser.uid
    });
  }

  wikiCall(wikiurl, placeId): Observable<any> {
    const getwiki = this.cf.httpsCallable('rssFeeds-wikiCall');
    return getwiki({
      wikiurl,
      placeId,
      uid: this.authx.auth.currentUser.uid
    });
  }

  getWiki(name): Observable<any> {
    const getwiki = this.cf.httpsCallable('placesFtn-getWiki');
    return getwiki({
      name,
      uid: this.authx.auth.currentUser.uid
    });
  }

  scrapeBlog(url: string) : Promise<any>  {
    const urlftn = 'https://us-central1-library-titleix.cloudfunctions.net/extension-scrapeBlog'
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(urlftn,
          {
            url
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }


  getWineSpec(url: string) : Promise<any>  {
    const urlftn = 'https://us-central1-library-titleix.cloudfunctions.net/extension-getWineSpectator'
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(urlftn,
          {
            url
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }

  getFestival(url: string) : Promise<any>  {
    const urlftn = 'https://us-central1-library-titleix.cloudfunctions.net/extension-getFestival'
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(urlftn,
          {
            url
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }


  compareRecipes() : Promise<any>  {
   const url = 'https://us-central1-library-titleix.cloudfunctions.net/extension-compareRecipes'
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            test: 'test'
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }

  getYelpMatch(name, address1, state, country): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getYelpMatch';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            name,
            address1,
            state,
            country,
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }

  getYelp(long, lat, categories, offset, limit, radius): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getYelp';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            long,
            lat,
            categories,
            offset,
            limit,
            radius
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }

  getFourSquare(lat, long, query): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getFourSquare';
    const ll = `${lat},${long}`
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            ll,
            query
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }

  getViator(cursor: string): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/adventures-viatorIngest';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            cursor,
            count: 500,
          },
          {headers: headersv}).toPromise();
      });

  }

  getRoute(origin, destination, mode, transmode): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getGoogleRoute';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            origin,
            destination,
            mode,
            transmode
          },
          {headers: headersv}).toPromise();
      });

  }

  /*
    getRoute(origin, destination, mode, transmode, key): Promise<any> {
      const url = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/directions/json';

      const params = new HttpParams()
        .set('origin', origin)
        .set('destination', destination)
        .set('mode', mode)
        .set('transit_mode', transmode)
        .set('key', key);

      //
      // AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ

      return this.authx.auth.currentUser.getIdToken()
        .then(authToken => {
          const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

          return this.http
            .get(url, {headers: headersv, params});
        }).then(res => {
          return res;
        });
    }
  */


  postMakeInst(admin, idsemail, idsphone): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postMakeInst';

    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            admin,
            idsemail,
            idsphone
          },
          {headers: headersv}).toPromise();
      })
      .then(res => {
        console.log(res);
        return res;
      });
  }


  postListAssets(folderpath: string): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/listAssets';

    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            folderpath
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        console.log('getlist', res);
        return res;
      });
    ;
  }

  getMapLink(long, lat): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getMapLink';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            long,
            lat
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }

  updateMarketing(admin: Admin, ukey: string): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/updateMarketing';

    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            admin,
            ukey
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
    ;
  }

  postRFCValidPhone(phone, code, fullname, app: string): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postRFCValidPhone';

    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            fullname,
            phone,
            code,
            app,
            lang: this.admin.curlang
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }


  isvalidUN(contact): Promise<any> {
    console.log('isvalid');
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/isvalidUN';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            contact
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        console.log('isvalid UN', res);
        return res;
      });
  }

  sendInviteCollab(msg: CappTeamMsg, id, name, type, indivukey): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/sendInviteCollab';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            msg,
            name,
            type,
            id,
            indivukey
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        console.log('isvalid UN', res);
        return res;
      });
  }

  postROEValidEmail(email: string, vcode: string, name: string, app: string): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROEValidEmail';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            adminemail: 'Capptivation@Capptivation.com',
            email,
            vcode,
            name,
            app,
          },
          {headers: headersv}).toPromise();
      }).then(res => {
        return res;
      });
  }


  instRegister(adminuid, marketingukey, deviceId, mirror, indivukey): Observable<any> {
    const instregister = this.cf.httpsCallable('instRegister');
    return instregister({
      adminuid,
      marketingukey,
      indivukey,
      mirror,
      orgukey: this.admin.admin.org,
      deviceId,
      uid: this.authx.auth.currentUser.uid
    });
  }


  deviceIdTest(keys: string[]): Observable<any> {
    console.log('test');
    const deviceidtest = this.cf.httpsCallable('deviceIdTest');
    return deviceidtest({
        keys,
        uid: this.authx.auth.currentUser.uid
      }
    );
  }


  deleteOrgUser(deviceId, org, appukey): Observable<any> {
    const deleteorguser = this.cf.httpsCallable('deleteOrgUser');
    return deleteorguser({
        deviceId,
        org,
        appukey,
        uid: this.authx.auth.currentUser.uid
      }
    );
  }


  updateStatus(status: boolean, deviceId, ukey) {
    const updatestatus = this.cf.httpsCallable('updateEditionStatus');
    return updatestatus({
      status,
      deviceId,
      ukey,
      uid: this.authx.auth.currentUser.uid
    })
      .take(1)
      .subscribe(res => {
        console.log('resalute', res);
      });
  }


  getURIEmail(urlv, reporternamev, pathv): Promise<any> {
    console.log('meta');
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getURIEmail';

    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            url: urlv,
            name: reporternamev,
            path: pathv,
          },
          {headers: headersv}).toPromise();
      })
      .then(res => {
        console.log('getURIEmail', res);
        return res;
      });

  }

  getURIMeta(urlv): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getURIMeta';
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            url: urlv,
          },
          {headers: headersv}).toPromise();
      })
      .then(res => {
        return res;
      });
  }

  sFTP() {
    const sftp = this.cf.httpsCallable('parseCSV');
    return sftp({data: 'test.csv'});
  }

  getDistance(mode: string, lat1, lon1, lat2, lon2): Observable<any> {
    const getdistance = this.cf.httpsCallable('placesFtn-getDistance');
    return getdistance(
      {
        lat1,
        lon1,
        lat2,
        lon2,
        mode,
        uid: this.admin.authx.auth.currentUser.uid
      });

    // const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=41.1660869,-73.7833029&destinations=41.170904,-73.777643&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ&mode=bicycling`
    // return this.http.get(url);
  }

  /*

  https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=40.7128,-74.0060&radius=100&keyword=liquor store&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ
*/

  // e.g.  itemnum: 1621447448000,
  parserRSS(url, loc, name): Observable<any> {
    const parserguides = this.cf.httpsCallable('rssFeeds-parserRSS');
    return parserguides(
      {
        uid: this.admin.authx.auth.currentUser.uid,
        name,
        loc,
        url
      });
  }


  getGuideImage(url): Observable<any> {
    const getguideimage = this.cf.httpsCallable('rssFeeds-getGuideImage');
    return getguideimage(
      {
        uid: this.admin.authx.auth.currentUser.uid,
        url
      });
  }



  checkLiveStream(id: string): Observable<any> {
    const url = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${id}&type=video&eventType=live&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ`;
    return this.http.get(url);
  }




//    const url = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&eventType=live&maxResults=50&publishedAfter=${after}&publishedBefore=${before}&type=video&videoCategoryId=19&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ`;
  getLiveStreams(after, before, nextPageToken): Observable<any> {
    console.log('after', after);
    console.log('before', before);
    // const url = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=webcam%20live&type=video&eventType=live&publishedAfter=${after}&publishedBefore=${before}&maxResults=50&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ`;

    const url = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=webcam%20live&type=video&eventType=live&publishedAfter=${after}&maxResults=100&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ`;
    return this.http.get(url);

/*
    if (nextPageToken && nextPageToken.length) {
      const url = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=25&q=webcam%20live&type=video&eventType=live&key=[YOUR_API_KEY]&publishedAfter=${after}&publishedBefore=${before}&maxResults=50&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ`;
      // const url = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&eventType=live&publishedAfter=${after}&publishedBefore=${before}&maxResults=50&pageToken=${nextPageToken}&type=video&videoCategoryId=19&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ`;
      console.log('path second', url);
      return this.http.get(url);
    } else {
//      const url = `https://youtube.googleapis.com/youtube/v3/search?type=video&videoCategoryId=19&part=snippet&eventType=live&publishedAfter=${after}&publishedBefore=${before}&maxResults=50&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ`;
 //     const url = `https://youtube.googleapis.com/youtube/v3/search?type=video&videoCategoryId=19&part=snippet&eventType=live&publishedAfter=${after}&maxResults=50&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ`;
        const url = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=webcam%20live&type=video&eventType=live&publishedAfter=${after}&maxResults=50&key=AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ`;

      console.log('path first', url);
      return this.http.get(url);

    }
*/
  }

  // Get Live Event
  // https://youtube.googleapis.com/youtube/v3/search?part=snippet&eventType=live&type=video&videoCategoryId=24&videoDefinition=high&videoDimension=2d&videoType=any&fields=items(id(videoId),snippet(channelId,channelTitle,title,thumbnails(default)))&key=[YOUR_API_KEY]

  // Get the end time of the live event
  //https://www.googleapis.com/youtube/v3/videos?part=liveStreamingDetails&id=[VIDEO_ID]&key=[YOUR_API_KEY]

  getAppUsers(editionukey): Observable<any> {
    const getusers = this.cf.httpsCallable('getAppUsers');
    return getusers({org: this.admin.admin.org, editionukey});
  }

  isIds(path: string, id: string): Observable<any> {
    const isids = this.cf.httpsCallable('isIds');
    return isids({path, id, uid: this.admin.authx.auth.currentUser.uid});
  }

  inServiceArea(indivukey: string, sa: string): Observable<any> {
    const inservicearea = this.cf.httpsCallable('inServiceArea');
    return inservicearea({indivukey, sa, uid: this.admin.authx.auth.currentUser.uid});
  }


  updateAlgoliaEntry(index, obj) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/updateAlgoliaEntry';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            obj,
            index,
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));
  }

  postAlgoliaEntry(index, obj, add: boolean) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postAlgoliaEntry';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            obj,
            add,
            index,
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));
  }


  getCovidContacts(indivukey: string, topic: string, covidfor?: string): Observable<any> {
    const getcovidcontacts = this.cf.httpsCallable('getCovidContacts');
    return getcovidcontacts({
      indivukey,
      topic,
      covidfor,
      uid: this.admin.authx.auth.currentUser.uid
    });
  }

  deleteCappTeamNote(notev: NotificationForm, pathv: string): Observable<any> {
    const publish = this.cf.httpsCallable('deleteCappTeamNote');
    return publish({note: notev, path: pathv});
  }

  publishCappTeamNote(notev: NotificationForm, pathv: string, eventforv: string): Observable<any> {
    const publish = this.cf.httpsCallable('publishCappTeamNote');
    return publish({note: notev, path: pathv, eventfor: eventforv});
  }

  answerQuestion(cappmsg: CappTeamMsg) {
    const answer = this.cf.httpsCallable('answerQuestion');
    return answer({cappmsg, uid: this.admin.authx.auth.currentUser.uid});
  }

  deleteField(path: string): Observable<any> {
    const deletefield = this.cf.httpsCallable('deleteField');
    return deletefield({path, uid: this.admin.authx.auth.currentUser.uid});
  }

  listUsers() {
    const listusers = this.cf.httpsCallable('listUsers');
    return listusers({uid: this.admin.authx.auth.currentUser.uid});
  }


  deleteCovidId(id) {
    const deleteCovidId = this.cf.httpsCallable('deleteCovidId');
    return deleteCovidId({id, uid: this.admin.authx.auth.currentUser.uid});
  }

  sendCorpEmail(msg: CappTeamMsg) {
    const sendcorpnotification = this.cf.httpsCallable('sendCorpEmail');
    return sendcorpnotification({msg, uid: this.admin.authx.auth.currentUser.uid});
  }

  sendCorpNotification(msg: CappTeamMsg) {
    const sendcorpnotification = this.cf.httpsCallable('sendCorpNotification');
    return sendcorpnotification({msg, uid: this.admin.authx.auth.currentUser.uid});
  }

  getField(pathv: string): Observable<any> {
    const getfield = this.cf.httpsCallable('getField');
    return getfield({path: pathv, uid: this.admin.authx.auth.currentUser.uid});
  }

  setField(pathv: string, valv) {
    const getfield = this.cf.httpsCallable('setField');
    getfield({path: pathv, val: valv, uid: this.admin.authx.auth.currentUser.uid}).take(1).subscribe(res => {
      // Read result of the Cloud Function.
      console.log('setField', res);
      // ...
    });
  }


  deleteCollab(edition, contentukey) {
    const deletecollab = this.cf.httpsCallable('deleteCollab');
    deletecollab({
      edition,
      contentukey,
      uid: this.authx.auth.currentUser.uid
    }).take(1).subscribe(val => {
      console.log('val delete code inivd');
    });

  }

  deleteInviteCollab(pth, loc) {
    const deleteinvitecollab = this.cf.httpsCallable('deleteInviteCollab');
    deleteinvitecollab({
      pth,
      loc,
      uid: this.authx.auth.currentUser.uid
    }).take(1).subscribe(val => {
      console.log('val delete');
    });
  }

  ROEPushNotification(curnotev: NotificationForm) {
    const sub = firebase.functions().httpsCallable('ROEPushNotification');
    sub({curnotev}).then(res => {
      // Read result of the Cloud Function.
      console.log('res', res);
      // ...
    });

  }


  addIncidentRole(deviceidv, incidentidv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/addIncidentRole';

    this.authx.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
      return this.http.post(url,
        {
          deviceId: deviceidv,
          incidentId: incidentidv,
        },
        {headers: headersv}).toPromise();
    })
      .then(res => console.log(res));
  }


  postROIncidentApproved(deviceidv, phonev, emailv, incidentidv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROIncidentApproved';

    this.authx.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
      return this.http.post(url,
        {
          deviceid: deviceidv,
          incidentid: incidentidv,
          phone: phonev,
          email: emailv,
          org: this.admin.admin.org
        },
        {headers: headersv}).toPromise();
    })
      .then(res => console.log(res));
  }

  postROEDeleteStaff(ukeyv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROEDeleteStaff';

    this.authx.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
      return this.http.post(url,
        {
          ukey: ukeyv,
          org: this.is.curComOrg.ukey
        },
        {headers: headersv}).toPromise();
    })
      .then(res => console.log(res));
  }

  postROEStaff(curjobv, curpersondetailsv, indivukeyv, ukeyv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROEStaff';

    this.authx.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
      return this.http.post(url,
        {
          org: this.admin.admin.org,
          curjob: curjobv,
          curpersondetails: curpersondetailsv,
          indivukey: indivukeyv,
          ukey: ukeyv,
        },
        {headers: headersv}).toPromise();
    })
      .then(res => console.log(res));
  }

  postROInterventionsRec(deviceidv, recv, staffukeyv, curlangv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROInterventionsRec';

    this.authx.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
      return this.http.post(url,
        {
          org: this.admin.admin.org,
          staffukey: staffukeyv,
          rec: recv,
          curlang: curlangv
        },
        {headers: headersv}).toPromise();
    })
      .then(res => console.log(res));
  }


  postGuideUser(publisherv, namev, ukeyv, userv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postGuideUser';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            publisher: publisherv,
            guide: namev,
            guideukey: ukeyv,
            user: userv
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));
  }


  postAlgoliaCommunity(index, delukey, name, objectID, tags, description, icon) {
    const url = `https://us-central1-library-titleix.cloudfunctions.net/postAlgoliaCommunity`;

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

        return this.http.post(url, {
            delukey,
            index,
            name,
            objectID,
            tags,
            description,
            publisher: this.admin.admin.org,
            icon
          },
          {headers: headersv}).toPromise();
      })
      .then(res => {
        return res['message'];
      });
  }

  postROEDenied(deviceidv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROEDenied';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            deviceId: deviceidv,
            org: this.is.curComOrg.ukey
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));
  }


  postROEApprove(deviceidv, staffukeyv, ukeyv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROEApprove';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            deviceId: deviceidv,
            ukey: ukeyv,
            staffukey: staffukeyv,
            org: this.admin.admin.org
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));
  }

  getGoogleJustPhotos(photos: string[]) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/getGoogleJustPhotos';

    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            individual: '',
            photos,
          },
          {headers: headersv}).toPromise();
      })
      .then(res => {
        return res;
      });
  }


  postROEDelete(deviceidv, staffukeyv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROEDelete';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            deviceId: deviceidv,
            staffukey: staffukeyv,
            org: this.admin.admin.org
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));
  }

  postConsoleChat(curmsgv: MMMes, incukeyv: string, lwv: number, tov: string, namev: string, pathv: string) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postConsoleChat';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            name: namev,
            from: curmsgv.f,
            date: curmsgv.d,
            msg: curmsgv.m,
            type: curmsgv.t,
            emoji: curmsgv.e,
            fade: curmsgv.b,
            anon: curmsgv.a,
            incidentukey: incukeyv,
            towhom: tov,
            lw: lwv,
            path: pathv
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));

  }


  postROESetDeviceId(deviceidv, ukeyv, uidv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROESetDeviceId';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            deviceid: deviceidv,
            ukey: ukeyv,
            uid: uidv
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));
  }


  postROEValidDeviceId(deviceidv, vcodev) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROEValidDeviceId';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            deviceid: deviceidv,
            vcode: vcodev,
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));
  }


  postMMChat(curmsgv: MMMes, incukeyv: string, lwv: number, tov: string, namev: string) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postMMChat';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            name: namev,
            from: curmsgv.f,
            date: curmsgv.d,
            msg: curmsgv.m,
            type: curmsgv.t,
            emoji: curmsgv.e,
            fade: curmsgv.b,
            anon: curmsgv.a,
            incidentukey: incukeyv,
            towhom: tov,
            lw: lwv,
            path: 'ROEDevices'
          },
          {headers: headersv}).toPromise();
      })
      .then(res => console.log(res));

  }

  getBoundary(ukey: string): Promise<any> {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/extension-getBoundar';
    console.log('get boundray1', ukey)
    return this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
        return this.http.post(url,
          {
            ukey
          },
          {headers: headersv}).toPromise();
      })
  }

  getGeoApify(lat, long): Observable<any> {
    const url = `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${long}&apiKey=2f65ff8f98784263a0f839093968f612`;
    return this.http.get(url);
  }

  getCounty(lat, long): Observable<any> {
    const url = `https://geo.fcc.gov/api/census/area?lat=${lat}&lon=${long}&format=json`;

    // todo: i feel like i need some error checking here if it returns an error
    return this.http.get(url).map(res => res['results'][0]['county_name']);
  }

  getOverRide(indivukey, profile): Observable<any> {
    const updateuse = this.cf.httpsCallable('getOverRide');
    return updateuse({
      indivukey,
      profile,
      uid: this.authx.auth.currentUser.uid
    });
  }


  doesCovidCaseExist(phoneNumber, email): Observable<any> {
    const doesCovidCase = this.cf.httpsCallable('doesCovidCaseExist');
    return doesCovidCase({
      phoneNumber,
      email,
      uid: this.authx.auth.currentUser.uid,
    });
  }

  createNoIndivukeyCovidCase(name, topic, agentukey, caseukey, phoneNumber, email, clen, tracer): Observable<any> {
    const createCovidCase = this.cf.httpsCallable('createNoIndivukeyCovidCase');
    return createCovidCase({
      name,
      clen,
      topic,
      phoneNumber,
      agentukey,
      tracer,
      email,
      caseukey,
      org: this.admin.admin.org,
      uid: this.authx.auth.currentUser.uid,
    });
  }


  /*
    getCounty(): Observable<any> {
      const url = 'https://geo.fcc.gov/api/census/area?lat=40.778042&lon=-273.8966049&format=json';

      return this.http.get(url).map(res => res['results'][0]['county_name']);
    }
  */


}
