import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminService} from '../../../services/admin.service';
import {LocationData, Invitation} from '../../../services/interface.service';
import {BizDay, BizHour, BizYear} from '../../../services/bizhours.service';
import * as _ from 'lodash';
import {Days} from '../../../services/constants';

@Component({
    selector: 'app-biz-day',
    templateUrl: './biz-day.component.html',
    styleUrls: ['./biz-day.component.css']
})
export class BizDayComponent implements OnInit, AfterViewInit {
    @Input()
    curYear: BizYear;

    @Input()
    curLocation: LocationData;

    @Input()
    curperiod: string;

    @Input()
    curDay: BizDay;

    @Input()
    periods: Invitation[];

    @Input()
    headDate: string;

    @Output()
    clear = new EventEmitter<any>();

    @Output()
    done = new EventEmitter<any>();

    @Output()
    save = new EventEmitter<any>();

    @Output()
    updateCurDay = new EventEmitter<BizDay>();

    days = Days;
    err: string;


    constructor(private admin: AdminService) {
    }

    ngOnInit() {
      console.log('curday', this.curDay)
    }

    ngAfterViewInit() {

    }

    onFix() {
        this.err = '';
        if (!this.curDay.blocks || this.curDay.blocks.length === 0) {
            this.err = 'Error 1';
        } else {
            let cnt = 1;
            this.curDay.blocks.forEach(b => {
                const s = new Date(b.time);
                b.tm = s.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
                if (cnt < this.curDay.blocks.length) {
                    const e = new Date(this.curDay.blocks[cnt].time).getTime();
                    const len = e - s.getTime();
                    if (len < 1) {
                        this.err = 'Error 2';
                        return;
                    } else {
                      b.len = Math.round(len / (1000 * 60));
                    }
                } else {
                    // this is the last hour
                    const hr = new Date(this.curDay.blocks[this.curDay.blocks.length - 1].time).getHours();
                    console.log('hr', hr);
                    const min = new Date(this.curDay.blocks[this.curDay.blocks.length - 1].time).getMinutes();
                    console.log('min', min)
                    b.len = Math.round(24 * 60 - (hr * 60 + min));
                    console.log('b last hour', b)
                }
                cnt = cnt + 1;
            })

            this.isGreen();
            this.admin.curperiod = this.curperiod;
            if (this.headDate) {
                this.save.emit();
            } else {
                console.log(2)
                console.log('curdays', this.curDay.blocks)
                this.done.emit();
            }
        }
    }

    onDone() {
        // this.onFix();
      this.done.emit();

    }

    regDay(i) {
        let x;
        if (this.curYear) {
            if (this.headDate) {
                x = this.days.findIndex(d => d === this.headDate)
                this.curDay = _.cloneDeep(this.curYear.regulardays[i]);
                this.curYear.regulardays[x] = this.curDay;
            } else {
                x = this.curDay.day.getDay();
                this.curDay = _.cloneDeep(this.curYear.regulardays[i]);
                console.log('curd', this.curDay)
            }
        } else {
            if (this.headDate) {
                x = this.days.findIndex(d => d === this.headDate)
                this.curDay = _.cloneDeep(this.curLocation.regdays[i]);
                this.curLocation.regdays[x] = this.curDay;
            } else {
                x = this.curDay.day.getDay();
                this.curDay = _.cloneDeep(this.curLocation.regdays[i]);
                console.log('curd', this.curDay)
            }
        }
        this.updateCurDay.emit(this.curDay);
    }

    onSave() {
        this.onFix();
    }

    isGreen(): boolean {
        let x = false;
        this.curDay.blocks.forEach(block => {
            if (block.open) {
                x = true;
            }
        });

        return x;
    }

    isOpen() {
        if (this.isGreen()) {

            this.curDay.open = true;
        } else {
            this.curDay.open = false;
        }
    }

    onClear() {
        this.clear.emit();
    }

    onAdd(i) {
        const val = Math.round(this.curDay.blocks[i].len / 2);

        const newhr = new BizHour();
        newhr.len = this.curDay.blocks[i].len - val;
        newhr.description = '';
        newhr.open = false;
        newhr.time = new Date(this.curDay.blocks[i].time);
        newhr.time.setMinutes(newhr.time.getMinutes() + val + 1);
        newhr.tm = newhr.time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
        this.curDay.blocks[i].len = val;
        this.curDay.blocks.splice(i + 1, 0, newhr);
    }

    onDelete(i) {
        this.curDay.blocks.splice(i, 1);
        this.isOpen();
    }

}
