import {Component, Input, OnInit} from '@angular/core';
import {InterfaceService, LandingBox, OrgEdition, Phone} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {InstService} from '../services/inst.service';
import {RoehttpService} from '../services/roehttp.service';

@Component({
  selector: 'app-inst-device',
  templateUrl: './inst-device.component.html',
  styleUrls: ['./inst-device.component.scss']
})
export class InstDeviceComponent implements OnInit {
  @Input()
  curphone: Phone;
  pth: string;
  pos = 0;
  toolbar: LandingBox[];
  control = 'notify';
  step = 1;

  phoneNumber: string;
  err: string;
  tries = 0;
  code: string;
  resy;
  adminy: string;
  spinner = false;

  primarycolor = '#0000FF';
  secondarycolor = '#ffffff';

  constructor(public admin: AdminService,
              private http: RoehttpService,
              public inst: InstService,
              private is: InterfaceService) {
  }

  ngOnInit() {
    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();
  }

  // TODO: ask if they want messages mirrored to their device

  onContinuePhone() {
    this.admin.admin.deviceid = this.resy.deviceId;
    this.admin.admin.indivukey = this.resy.indivukey;
    const keys = Object.keys(this.resy.admn);
    console.log('keys')
    if (keys && keys.length > 0) {
      // this.is.setObjNoLog(`Marketing/${this.admin.admin.ukey}/deviceid`, this.resy.deviceId);
      this.adminy = keys[0];
      this.spinner = true;
      this.http.instRegister(keys[0], this.admin.admin.ukey, this.resy.deviceId, false, this.resy.indivukey)
        .take(1)
        .subscribe(res => {
          this.spinner = false;
          console.log('registered', res);
          this.step = 4;
        });
    } else {
      this.step = 1;
      this.tries = this.tries + 1;
    }
  }

  onMirror() {
    this.spinner = true;
    this.http.instRegister(this.adminy, this.admin.admin.ukey, '', true, '')
      .take(1)
      .subscribe(res => {
        this.spinner = false;
        console.log('registered', res);
        this.step = 1;
      });
  }

  onContinue() {
    this.err = '';
    this.resy = null;
    if (!this.phoneNumber) {
      this.err = this.admin.t['invphone']; // 'Invalid phone number.';
    }

    this.admin.isValidPhone(this.phoneNumber);
    if (this.admin.phoneNumber && this.admin.phoneNumber.isValid()) {
      this.code = this.admin.randomString(false, 3);
      console.log('code', this.code);
      this.spinner = true;
      this.http.postRFCValidPhone(this.admin.phoneNumber.number, this.code, 'Capptivation', 'Register')
        .then(res => {
          console.log('resy', res);
          if (res && res.info) {
            this.resy = res.info;
          }
          this.spinner = false;
          this.step = 3;
        });
    } else {
      this.err = this.admin.t['invphone']; // 'Invalid phone number.';
      this.tries = this.tries + 1;
      if (this.tries > 2) {
        this.err = 'You are permitted 3 attempts. Please try later.';
        return;
      }
    }
  }

  onRegister() {
    console.log('on register')
  }

  onTopic() {
    console.log('on topic')
  }
}
