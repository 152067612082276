import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-mm-parties',
  templateUrl: './mm-parties.component.html',
  styleUrls: ['./mm-parties.component.scss']
})
export class MmPartiesComponent implements OnInit {

  constructor(public ro: AdminService,
              private bottomSheetRef: MatBottomSheetRef<MmPartiesComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
  }

}

