import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { take } from 'rxjs/operators';
import {CAMetaData, CAMolyVal} from '../../../services/ca';
import {CaServices} from '../../ca-services/ca-services';
import {InterfaceService} from '../../../services/interface.service';

@Component({
    selector: 'app-genform',
    templateUrl: './genform.component.html',
    styleUrls: ['./genform.component.css']
})
export class GenformComponent implements OnInit {
    @Input()
    iduser: string;

    @Input()
    idukey: string;

    @Output()
    close = new EventEmitter();

    fields: any[];
    name: string;
    options = false;
    optz: string;
    metadata: CAMetaData[];
    msg: string;
    val;
    details = false;


    dryers: any[];
    dryer: string;


    constructor(public gs: CaServices,
                private fs: InterfaceService) {
    }

    ngOnInit() {
        this.fs.getObject(`Meta/vforms/forms/${this.idukey}`).pipe(take(1)).subscribe(obj => {
            this.name = obj.name;

            // todo: this is an ugly cludge
            if (this.name === 'Dryer') {
                this.gs.getName(this.idukey, '-LkWDNFWY1qVGsyXL1VH').then(val => {
                    this.val = val;
                    if (!val) {
                        this.msg = 'Tap here if yould you like use the specs of your current dryer.';
                    } else {
                        this.msg = 'We have filled parts of this form based on the specs of your current dryer.';
                    }
                });
            }


            const carr = Object.keys(obj.fields);
            this.fields = [];
            carr.forEach(c => {
                this.fields.push(obj.fields[c]);
            });
            this.metadata = [];
            this.fields.forEach(f => {
                let str = 'CA/';
                if (f.src === 'CA') {
                    str = str + 'metadata';
                } else {
                    str = str + `vforms/forms/${this.idukey}/newfields`;
                }
                this.fs.getQuery(str, 'idukey', f.idukey).pipe(take(1)).subscribe(val => {
                    const tmp = val[0];
                    tmp['val'] = '';
                    tmp['vukey'] = '';
                    tmp['opts'] = [];
                    if (tmp.type === 'pick') {
                        tmp.pntr = tmp.pntr.split(',');
                    }

                    this.fs.getQuery(`${this.gs.path}/${this.gs.iduser}/datamap`, 'idukey', tmp.idukey).pipe(take(1)).subscribe(dm => {
                        if (dm && dm.length > 0) {
                            this.fs.getList(`${this.gs.path}/${this.gs.iduser}/data/${dm[0].ukey}`).pipe(take(1)).subscribe(vs => {
                                // todo: this doesn't work for picks or langs
                                tmp['val'] = vs[vs.length - 1]['value'][0]['val'];
                                tmp['vukey'] = vs[vs.length - 1].vukey;

                                // this next bit of code is a sample cludge to show smart form functionality
                                if (tmp.idukey === this.gs.idname) {
                                    const optarr = [];
                                    optarr.push({val: tmp['val'], ukey: tmp['ukey'], idukey: tmp['idukey']});

                                    this.fs.getQuery(`${this.gs.path}/${this.gs.iduser}/datamap`, 'idukey', this.gs.idprofile).pipe(take(1)).subscribe(profs => {
                                        if (profs && profs.length > 0) {
                                            profs.forEach(p => {
                                                this.fs.getList(`${this.gs.path}/${this.gs.iduser}/data/${p.ukey}`).pipe(take(1)).subscribe(u => {
                                                    const l = u[u.length - 1]['value']['0']['val'].split(',')[0];
                                                    this.fs.getList(`${this.gs.path}/${this.gs.iduser}/data/${l}`).pipe(take(1)).subscribe(q => {
                                                        optarr.push({
                                                            val: q[q.length - 1]['value']['0']['val'],
                                                            ukey: q[q.length - 1]['ukey'],
                                                            idukey: q[q.length - 1]['idukey']
                                                        });
                                                        console.log('optarr', optarr);
                                                        tmp['opts'] = optarr;
                                                        this.metadata.push(tmp);

                                                    });
                                                });
                                            });
                                        }
                                    });
                                } else {
                                    this.metadata.push(tmp);
                                }
                                // end of cludge

                                // todo: put this back when kill the cludge
                                // this.metadata.push(tmp);
                            });
                        } else {
                            this.metadata.push(tmp);
                        }
                    });


                });
            });
        });
    }

    onOptions(event, i) {
        const x = this.metadata[i]['opts'].findIndex(m => m.ukey === event);
        const id = this.metadata[i]['opts'][x]['idukey'];
        this.fs.getQuery(`Meta/metadata`, 'idukey', id).pipe(take(1)).subscribe(g => {
            g[0]['val'] = this.metadata[i]['opts'][x]['val'];
            g[0]['vukey'] = this.metadata[i]['opts'][x]['vukey'];
            g[0]['opts'] = this.metadata[i]['opts'];
            this.metadata[i] = g[0];
            this.options = false;
        });
    }

    onCancel() {
        this.close.emit();
    }

    onSave() {
        this.metadata.forEach(m => {
            const obj = {label: m.label, val: m['val'], vukey: m['vukey'], ukey: m.ukey};
            this.fs.setObjNoLog(`Meta/rforms/${this.idukey}/${this.gs.iduser}/fields/${m.ukey}`, obj);
        });
        const tracking = this.fs.getId();
        this.fs.setObjNoLog(`Meta/rforms/${this.idukey}/${this.gs.iduser}/tracking`, tracking);
        this.fs.setObjNoLog(`Meta/rforms/${this.idukey}/${this.gs.iduser}/date`, new Date().getTime());
        this.fs.setObjNoLog(`Meta/rforms/${this.idukey}/${this.gs.iduser}/idukey`, this.idukey);
        // todo: THIS IS HUGE - cannot save user id so that a corp can figure out who it is, rather the userid has to be a tmp token that the cloud manages and it has to tbe diferent every time
        this.fs.setObjNoLog(`Meta/rforms/${this.idukey}/${this.gs.iduser}/user`, this.gs.iduser);
        this.fs.setObjNoLog(`Meta/rforms/${this.idukey}/${this.gs.iduser}/name`, this.name);
        const date = new Date().getTime();
        this.fs.setObjNoLog(`${this.gs.path}/${this.gs.iduser}/rforms/${this.idukey}`,
            {name: this.name, tracking, status: {[date]: {date, status: 'submitted'}}, idukey: this.idukey, date});

        this.close.emit();
    }

    onAddDryer() {
        this.msg = '';
        this.fs.getList('CA/products/dryer').pipe(take(1)).subscribe(dryers => {
            this.dryers = dryers;
        });

        this.details = true;
    }



    onSaveDryer() {
        // save the moly and the datampa
        const tmp = new CAMolyVal();

        tmp.value = [];
        tmp.value[0] = {val: this.val};
        tmp.idukey = this.idukey;
        tmp.u = this.fs.getId();
        tmp.cdate = new Date().getTime(); // this is the date the val was edited or created
        tmp.cukey = this.iduser; // this is the ukey of the data creator
        tmp.vdate = -1; // this is the date the val was validated
        tmp.vukey = ''; // this is the ukey of the data validator
        tmp.cukeys = []; // array of forms that can create the atom val
        tmp.rukeys = []; // array of forms that can read the atom val
        tmp.uukeys = []; // array of forms that can update the atom val
        tmp.dukeys = []; // array of forms that can delete the atom val

        // this.fs.setObj(`${gs.pth}/${this.iduser}/${tmp.ukey}/${tmp.cdate}`, this.name);
        // this.fs.setObj(`${gs.pth}/${this.iduser}/datamap/${this.fs.getId()}`, {idukey: tmp.idukey, ukey: tmp.ukey});

        // todo: get the specs and add them to this form
        this.fs.getQuery(`Meta/products/dryer`, 'model', this.val).pipe(take(1)).subscribe(dryer => {
            this.metadata[0]['val'] = dryer[0].width;
            this.metadata[1]['val'] = dryer[0].color;
            this.metadata[3]['val'] = dryer[0].power;
        })

        this.details = false;
    }
}
