import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AdminService} from '../services/admin.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private admin: AdminService,
                private router: Router) {

    }

    permission(r): boolean {
        return this.admin.permission(r);
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.admin.loggedin) {
            return this.permission(route.routeConfig.path);
        }
        this.router.navigate(['']);
        return false;
    }

}


