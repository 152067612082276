import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CappTeamMsg, InterfaceService} from '../../services/interface.service';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-roe-event',
  templateUrl: './roe-event.component.html',
  styleUrls: ['./roe-event.component.scss']
})
export class RoeEventComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  @Output()
  msg = new EventEmitter<string>();

  primarycolor: string;
  secondarycolor: string;

  constructor(
    public is: InterfaceService,
    public admin: AdminService,
  ) {
    super(admin, is);

  }

  ngOnInit() {
    super.ngOnInit();


    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();
  }

}
