import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BizDay, BizHour, BizhoursService, BizMonth, BizYear, BizYearCont} from '../../../services/bizhours.service';
import {Days, DaysPerMonth} from '../../../services/constants';
import {LocationData, InterfaceService, Invitation} from '../../../services/interface.service';
import {AdminService} from '../../../services/admin.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-com-container',
  templateUrl: './com-container.component.html',
  styleUrls: ['./com-container.component.scss']
})
export class ComContainerComponent implements OnInit {
  curDay: BizDay;
  headDate: string;
  days = Days;
  periods: Invitation[];
  holidays: Invitation[];
  curperiod: string;
  months;
  dayspermonth = DaysPerMonth;
  curYear: BizYear;


  @Input()
  curLocation: LocationData;

  @Input()
  isdialog: boolean;

  @Output()
  picked = new EventEmitter<boolean>();

  @Output()
  close = new EventEmitter<any>();

  @Output()
  save = new EventEmitter<LocationData>();

  constructor(private is: InterfaceService,
              private dbRef: AngularFirestore,
              private bz: BizhoursService,
              public admin: AdminService) {
    this.curperiod = 'All dates';
    this.periods = [];
    this.holidays = [];

    /*
            if (this.admin.admin.org) {
                this.is.getList('Orgs/' + this.admin.admin.org + '/periods').take(1).subscribe(p => {
                    this.periods = p;
                    const allperiod = new Invitation();
                    allperiod.title = 'All dates';
                    allperiod.start = moment().toISOString();
                    allperiod.end = moment().add(365, 'days').toISOString();
                    this.periods.unshift(allperiod);
                });
            }
    */
  }

  ngOnInit() {
    if (!this.curLocation.regdays) {
      this.curLocation.regdays = this.createRegDays();
    }

    let pos = -1;
    let len = -1;
    const curday = new Date();
    const mth = curday.getMonth();
    const year = curday.getFullYear();
    this.months = new Array();
    for (let i = 0; i < 12; i++) {
      const month = new BizMonth();
      month.month = new Date(year, mth + i, 1, 0, 0, 0, 0);
      month.days = new Array();

      const y = month.month.getFullYear();
      const m = month.month.getMonth();
      for (let d = 0; d < this.dayspermonth[m]; d++) {
        const dy = new Date(y, m, d + 1, 0, 0, 0, 0);
        const dyval = new Date(dy).getTime();
        if (this.curLocation.days) {
          const x = this.curLocation.days.findIndex(dz => new Date(dz.day).getTime() === dyval);
          if (x > -1) {
            month.days.push(this.curLocation.days[x]);
          } else {
            const nbd = new BizDay();
            nbd.open = false;
            nbd.day = dy;
            nbd.color = '#000000';
            month.days.push(nbd);
          }
        } else {
          const nbd = new BizDay();
          nbd.open = false;
          nbd.day = dy;
          nbd.color = '#000000';
          month.days.push(nbd);
        }
      }
      this.months.push(month);
    }

    this.curYear = new BizYear();
    this.curYear.regulardays = this.curLocation.regdays;
    this.curYear.months = this.months;
    this.curYear.twentyfourseven = this.curLocation.twentyfourseven;

  }

  onCancel() {
    this.close.emit();
  }

  onClear() {
    /* new */
    this.curYear.months.forEach(mth => {
      mth.days.forEach(day => {
        day.open = false;
        if (day.blocks && day.blocks.length > 0) {
          day.blocks.forEach(b => {
            b.description = '';
            b.open = false;
          });
        }
      });
    });

    /* old
        const dy = new Date(this.curDay.day);
        const tm = dy.getTime();
        const month = dy.getMonth();
        const date = dy.getDate() - 1;
        const mth = new Date().getMonth();
        let xmonth = month - mth;
        if (xmonth < 0) {
          xmonth = 12 - mth + month;
        }


        const x = this.curLocation.days.findIndex(d => new Date(d.day).getTime() === tm);
        if (x > -1) {

          this.months[xmonth].days[date].open = false;
          delete this.months[xmonth].days[date].blocks;
          this.curLocation.days.splice(x, 1);
          this.curDay = null;
        }
    */

  }

  onSave() {
    const bd = [];

    this.curYear.months.forEach(month => {
      month.days.forEach(day => {
        if (day.open) {
          bd.push(day);
        }
      });
    });
    /* old
        this.months.forEach(month => {
          month.days.forEach(day => {
            if (day.open) {
              bd.push(day);
            }
      });
    });
     */

    this.curLocation.days = bd;

    this.save.emit(this.curLocation);
  }

  clickDay(event) {
    this.headDate = '';
    this.curDay = event.d;
    if (!this.curDay.blocks) {
      this.curDay.blocks = this.admin.makeBlocks();
    }

    this.picked.emit(true);
  }

  pickDay(event) {
    this.headDate = '';
    this.curDay = event.d;
    this.picked.emit(true);
  }

  createRegDays() {
    const rds = [];
    const newdays = [];
    for (let x = 0; x < 7; x = x + 1) {
      const newday = new BizDay();
      newday.color = '#000000';
      newday.day = new Date();
      newday.open = false;
      newday.holiday = false;

      const newblocks = new Array();
      // make a regalar day with all blanks;
      const blocks = new Array();
      const b = new BizHour();
      b.time = new Date();
      b.open = false;
      b.time.setHours(0, 0, 0, 0);
      b.len = 359;
      /*
                      b.tm = b.time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*!/, '$1');
      */
      b.tm = b.time.toTimeString().substring(0, 8);
      blocks.push(b);

      for (let ix = 6; ix < 20; ix++) {
        const bh = new BizHour();
        bh.time = new Date();
        bh.open = false;
        bh.len = 59;
        bh.time.setHours(ix, 0, 0);
        /*
                            bh.tm = bh.time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*!/, '$1');
        */
        bh.tm = bh.time.toTimeString().substring(0, 8);
        blocks.push(bh);
      }

      newday.blocks = blocks;
      newdays.push(newday);
      rds.push(blocks);
    }

    return newdays;

  }

  regDay(i) {
    this.headDate = this.days[i];
    this.curDay = this.curLocation.regdays[i];
    this.picked.emit(true);

  }

  onDayCancel() {
    this.curDay = null;
    this.picked.emit(false);
  }

  updateCurDay(event) {
    this.curDay = event;
  }

  onDaySave() {

    /* old
        this.picked.emit(false);
        let startdate: Date;
        let enddate: Date;
        if (this.periods) {
          this.periods.forEach(p => {
              if (p.title === this.admin.curperiod) {
                startdate = new Date(p.start);
                enddate = new Date(p.end);
              }
            }
          );
        } else {
          startdate = new Date();
          enddate = new Date();
          enddate.setTime(enddate.getTime() + 730 * 86400000);
        }
        this.headDate = '';
        this.curDay = null;
    */


    const startdate = new Date();
    const enddate = new Date();
    enddate.setTime(enddate.getTime() + 730 * 86400000);

    this.curYear.months.forEach(month => {
      month.days.forEach(day => {
        if (day.day >= startdate && day.day <= enddate) {
          if (this.headDate === this.days[day.day.getDay()]) {
            day.open = this.curDay.open;
            const newBlocks = _.cloneDeep(this.curDay.blocks);
            // this.curDay.blocks.slice();
            day.blocks = newBlocks;
          }
        }
      });
    });

    console.log('this.curyear', this.curYear);

    this.headDate = '';
    this.curDay = null;
  }

}
