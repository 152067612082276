import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {InterfaceService} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-capp',
  templateUrl: './capp.component.html',
  styleUrls: ['./capp.component.css']
})
export class CappComponent extends RoeTemplateComponent implements OnInit {

  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  colone = '34%';
  coltwo = '66%';

  constructor(public is: InterfaceService,
              public admin: AdminService) {
    super(admin, is);
  }

  ngOnInit() {
    super.ngOnInit();

    const x = Math.round(9000 / this.admin.appwidth);
    this.colone = `${x}%`;
    this.coltwo = `${100 - x}%`;
  }



}
