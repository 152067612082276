import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {InterfaceService, TopixMember} from '../../services/interface.service';
import {RoehttpService} from '../../services/roehttp.service';

export class CappProfile {
    prFullName: string;
    prMobilePhone: string;
    prBirthDate: string;
    prSSN: string;
    prGenderAtBirth: string;
    prInstID: string;
}

@Component({
    selector: 'app-hc-profile',
    templateUrl: './hc-profile.component.html',
    styleUrls: ['./hc-profile.component.scss']
})
export class HcProfileComponent implements OnInit, OnChanges {
    @Input()
    curclient: TopixMember;

    @Input()
    curteam;

    curprofile: CappProfile;
    date: Date;

    constructor(private http: RoehttpService,
                private is: InterfaceService) {
    }

    ngOnInit() {
        if (this.curteam.topics) {
            // todo: error here: what if arr.length > 1
            const arr = Object.keys(this.curteam.topics);
            this.http.getField(`Topics/topics/${arr[0]}`)
                .take(1)
                .subscribe(topic => {
                    let str = '';
                    if (topic) {
                        if (this.curclient.isprincipal) {
                            str = topic.prosurvey;
                        } else {
                            str = topic.teamsurvey;
                        }

                        if (str) {
                            this.http.getField(`Surveys/${str.split('/')[1]}/results/${this.curclient.roedeviceid}`)
                                .take(1)
                                .subscribe(p => {
                                    if (p) {
                                       this.curprofile = p;
                                       console.log('curprofile', this.curprofile)
                                       if (this.curprofile.prBirthDate) {
                                           this.date = new Date(this.curprofile.prBirthDate);
                                           console.log('date', this.date)
                                       }
                                    } else {
                                        // todo: handle this error
                                    }
                                });
                        } else {
                            // todo: handle error
                        }
                    } else {
                        // todo: handle this error somehow
                    }
                });
        } else {
            // todo: handle this error somehow
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        const carr = Object.keys(changes);
        if (carr && carr.length > 0) {
            carr.forEach(c => {
                if (c === 'curclient') {
                    console.log('do something')
                }
            });
        }
    }


}
