import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {GuideElements} from '../../services/constants';

@Component({
    selector: 'app-element-dialog',
    templateUrl: './element-dialog.component.html',
    styleUrls: ['./element-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ElementDialogComponent implements OnInit {
    guideelements = GuideElements;
    type: string;

    constructor(public dialogRef: MatDialogRef<ElementDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.type = '';
    }

    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close();
    }

    onSave() {
        this.dialogRef.close(this.type);
    }

}
