import {Component, Input, OnInit} from '@angular/core';
import {NewsArticle} from '../services/interface.service';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'app-article-notif',
  templateUrl: './article-notif.component.html',
  styleUrls: ['./article-notif.component.scss']
})
export class ArticleNotifComponent implements OnInit {
  @Input()
  curarticle: NewsArticle;

  @Input()
  i: number;

  @Input()
  curlang = 'en';

  primarycolor: string;
  secondarycolor: string;

  constructor(public admin: AdminService) { }

  ngOnInit() {
    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();
  }

  onPubURL() {
    if (this.curarticle.template.publisherurl) {
      window.open(this.curarticle.template.publisherurl, '_blank');
    }
  }

}
