import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {InterfaceService} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {AdminService} from '../../services/admin.service';

@Component({
    selector: 'app-roe-a',
    templateUrl: './roe-a.component.html',
    styleUrls: ['./roe-a.component.css']
})
export class RoeAComponent  extends RoeTemplateComponent implements OnInit, AfterViewInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  @Output()
  changehorse = new EventEmitter();

  colone = '25%';
  coltwo = '75%';
  primarycolor: string;
  secondarycolor: string;
  from: string;
  flipit = false;
  ht: number;
  iht: number;
  days: number;
  name: string;
  note: string;

  @ViewChild('content', {static: false}) elementView: ElementRef;
  @ViewChild('backcontent', {static: false}) backcontent: ElementRef;

  constructor(public is: InterfaceService,
              private cd: ChangeDetectorRef,
              private db: AngularFireDatabase,
              public admin: AdminService) {
    super(admin, is);
    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();
  }

  ngOnInit() {
    super.ngOnInit();

    const x = Math.round(9000 / this.admin.appwidth);
    this.colone = `${x}%`;
    this.coltwo = `${100 - x}%`;
    this.from = this.n.cn.replace('Survivor', 'ROE');
    this.iht = this.n.l.height * ((this.admin.appwidth * 0.94 - 40) / this.n.l.width);
    if (this.n.l.rsvp) {
      this.days = this.admin.calcDays(this.n.l.rsvp) + 1;
    }

    let app = this.n.app;
    if (app.includes('/')) {
      app = app.split('/')[1];
    }
  }

  ngAfterViewInit(): void {
    if (this.elementView && this.elementView.nativeElement) {
      this.ht = this.elementView.nativeElement.offsetHeight;

      if (this.backcontent && this.backcontent.nativeElement) {
        const x = this.backcontent.nativeElement.offsetHeight;
        if (this.ht < x) {
          this.ht = x;
        }
      } else {
        if (this.ht < 270) {
          this.ht = 270;
        }
      }
      this.cd.detectChanges();
    }
  }

  onDelete() {
    this.delete.emit();
  }
}
