import {Component, Input, OnInit} from '@angular/core';
import {Days} from '../../services/constants';
import {TimeCapture} from '../hours-details/hours-details.component';
import {ComOrg} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';

@Component({
    selector: 'app-com-hours-details',
    templateUrl: './com-hours-details.component.html',
    styleUrls: ['./com-hours-details.component.css']
})
export class ComHoursDetailsComponent implements OnInit {

    dayweek = Days;
    topline: string;
    opendetails: boolean;
    days: string[];
    times: TimeCapture[];

    @Input()
    m: ComOrg;

    @Input()
    curloc = 0;

    @Input()
    side = false;

    constructor(private admin: AdminService) {
        this.topline = '';
        this.times = new Array();
    }

    convertmins(m): string {
        const minutes = m % 60;
        const hours = Math.floor(m / 60);

        const mstr = (minutes < 10 ? '0' : '') + minutes;
        let hstr = (hours < 10 ? '' : '') + hours;

        let t = 'am'
        if (hours > 11) {
            let h = hours;
            if (hours > 12) {
                h = hours - 12;
            } else {
                h = hours;
            }
            hstr = (h < 10 ? '' : '') + h;
            t = 'pm';
        }
        return hstr + ':' + mstr + t;
    }

    fillTimes(curday, fixday, day) {
        this.times = [];
        let blocks;

        let x = -1;
        if (this.m.locations[this.curloc].days) {
            x = this.m.locations[this.curloc].days.findIndex(d => new Date(d.day).getTime() === fixday);
        }
        if (x > -1) {
            blocks = this.m.locations[this.curloc].days[x].blocks;
        } else {
            if (this.m.locations[this.curloc].regdays) {
                blocks = this.m.locations[this.curloc].regdays[day].blocks;
            } else {
                blocks = this.m.locations[0].regdays[day].blocks;
            }
        }


        if (blocks && blocks.length > 0) {
            blocks.forEach(hr => {
                if (hr.open) {
                    const pieces = hr.tm.split(':');
                    const t = new TimeCapture();
                    t.start = pieces[0] * 60 + pieces[1] * 1;
                    t.end = pieces[0] * 60 + pieces[1] * 1 + hr.len;
                    this.times.push(t);
                }
            });
        }

    }

    firstStep(curday, fixday, day) {
        this.fillTimes(curday, fixday, day);
        if (this.times.length > 0) {
            this.days.push(
                this.dayweek[day] + ': ' +
                this.convertmins(this.times[0].start)
                + ' to ' +
                this.convertmins(this.times[this.times.length - 1].end + 1 * 0));
        } else {
            this.days.push(`${this.dayweek[day]}: ${this.admin.t['closed']}`);
        }

    }


    fillOutDays() {
        this.days = [];
        let curday = new Date().setHours(0, 0, 0, 0);

        const mcurday = new Date();
        const mth = mcurday.getMonth();
        const year = mcurday.getFullYear();
        const date = mcurday.getDate();
        const fixday = new Date(year, mth, date, 0, 0, 0, 0).getTime();

        for (let x = 0; x < 7; x++) {
            const day = new Date(curday).getDay();
            if (this.m.locations[this.curloc].twentyfourseven) {
                this.days.push(`${this.dayweek[day]}: ${this.admin.t['open']} 24/7`);
            } else {
                if (this.m.locations[this.curloc].closedholidays) {
                    let j = -1;
                    if (this.m.holidays) {
                        j = this.m.holidays.findIndex(h => new Date(h.start).getTime() === fixday)
                    }
                    if (j > -1) {
                        this.days.push(`${this.dayweek[day]}: ${this.admin.t['closed']}`);
                    } else {
                        this.firstStep(curday, fixday, day)
                    }
                } else {
                    this.firstStep(curday, fixday, day)
                }
            }
            curday = curday + 86400000;
        }

    }

    getTodayHours(): string {
        let str = '';
        const curday = new Date().setHours(0, 0, 0, 0);
        const curtime = new Date().getHours() * 60 + new Date().getMinutes();
        const mcurday = new Date();
        const mth = mcurday.getMonth();
        const year = mcurday.getFullYear();
        const date = mcurday.getDate();
        const fixday = new Date(year, mth, date, 0, 0, 0, 0).getTime();

        const day = new Date(curday).getDay();
        let open = false;
        this.fillTimes(curday, fixday, day);


        if (this.times) {
            this.times.forEach(t => {
                if (curtime >= t.start && curtime <= t.end) {
                    open = true;
                }
            });
        }

        if (!open && this.times) {
            let openagain;
            this.times.reverse().forEach(t => {
                if (curtime < t.start) {
                    openagain = t.start;
                }
            });

            let openuntil;
            this.times.forEach(t => {
                if (curtime < t.end) {
                    openuntil = t.end;
                }
            });

            str = this.admin.t['closednow'];
            if (openagain) {
                str = `${str}\n ${this.admin.t['opensat']} ${this.convertmins(openagain)}`;
                console.log('str', str)
            }

            /*
                        if (openuntil) {
                            str = str + ' - ' + this.convertmins(openuntil + 1);
                        }
            */
        }

        if (open) {
            str = 'Open';

            let openuntil;
            this.times.forEach(t => {
                if (curtime < t.end) {
                    openuntil = t.end;
                }
            });

            if (openuntil) {
                str = `${str} ${this.admin.t['until']} ${this.convertmins(openuntil + 1 * 0)}`;
            }
        }

        return str;
    }

    ngOnInit() {
        if (this.m.locations[this.curloc].twentyfourseven) {
            this.topline = `${this.admin.t['hours']}: ${this.admin.t['open']} 24/7`;
        } else {
            this.topline = `${this.admin.t['hours']}: ${this.getTodayHours()}`;
        }

        this.fillOutDays();
    }
}
