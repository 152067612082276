import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {InterfaceService, Office, Org, OrgEdition, OrgProfile, Policy, PublicStaff} from './interface.service';
import {AdminService} from './admin.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  prefix = 'Orgs';
  tempprefix = 'Orgs';
  curOrg: Org;

  _curorg = new BehaviorSubject<string>(null);
  curorg$ = this._curorg.asObservable();


  constructor(private is: InterfaceService,
              public admin: AdminService,
              private db: AngularFireDatabase) {
  }

  getEditionCategories(ukey) {
    const obj = this.curOrg[ukey].categories;
    let cats;
    if (typeof obj === 'object') {
      cats = Object.values(this.curOrg[ukey].categories)
    }
    return cats;
  }

  setupArchive() {
    if (this.curOrg.archive) {
      if (this.curOrg.archive.graphics && (typeof this.curOrg.archive.graphics === 'object')) {
        this.curOrg.archive.graphics = Object.values(this.curOrg.archive.graphics);
      }
      if (this.curOrg.archive.videos && (typeof this.curOrg.archive.videos === 'object')) {
        this.curOrg.archive.videos = Object.values(this.curOrg.archive.videos);
      }
      if (this.curOrg.archive.documents && (typeof this.curOrg.archive.documents === 'object')) {
        this.curOrg.archive.documents = Object.values(this.curOrg.archive.documents);
      }
    }
  }

  getCurOrg() {
    this.is.getObject(`${this.prefix}/${this.admin.admin.org}`)
      .take(1)
      .subscribe(org => {
        this.curOrg = org;
        if (this.curOrg.editions) {
          this.curOrg.editions = Object.values(this.curOrg.editions);
        }
        this.setupArchive();
        this._curorg.next('true');
      });
  }


  onSavePolicies(policies: Policy[]) {
    policies.forEach(o => {
      this.is.setResourceObject(this.admin.admin.org, `${this.prefix}/${this.admin.admin.org}/resources/policies/${o.ukey}`, o, 'Policy', '');
    });
  }

  onSaveStaff(staff: PublicStaff[]) {
    staff.forEach(o => {
      this.is.setResourceObject(this.admin.admin.org, `${this.prefix}/${this.admin.admin.org}/resources/staff/${o.ukey}`, o, 'Staff', '');
    });
  }

  onSaveOffices(offices: Office[]) {
    offices.forEach(o => {
      this.is.setResourceObject(this.admin.admin.org, `${this.prefix}/${this.admin.admin.org}/resources/offices/${o.ukey}`, o, 'Office', '');
    });
  }

  onSaveEdition(orgedition: OrgEdition) {
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/editions/${orgedition.ukey}`, orgedition);
  }

  onSaveProfile(tmporg: OrgProfile) {
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/profile`, tmporg);
  }

  onSaveArchiveGraphics(graphics) {
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/archive/graphics`, graphics);
  }

  onSaveArchiveVideos(videos) {
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/archive/videos`, videos);
  }

  onSaveArchiveArchive(objs) {
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/archive/documents`, objs);
  }

  onSaveCategory(edukey, tmpcategory) {
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/${edukey}/categories/${tmpcategory.ukey}`, tmpcategory);
    this.curOrg[edukey].categories[tmpcategory.ukey] = tmpcategory;
  }

  onDelEdition(ukey) {
    const x = this.curOrg.editions.findIndex(e => e.ukey === ukey);
    if (x > -1) {
      this.curOrg.editions.splice(x, 1);
    }
    this.is.deleteObjNoLog(`${this.prefix}/${this.admin.admin.org}/${ukey}`);
    this.is.deleteObjNoLog(`${this.prefix}/${this.admin.admin.org}/editions/${ukey}`);
  }

  onDelCategory(edukey, catukey) {
    // todo: needs to be tested
    this.is.deleteObjNoLog(`Orgs/${this.admin.admin.org}/${edukey}/categories/${catukey}`);
    this.curOrg[edukey].categories[catukey] = null;
  }
}
