import {Component, OnDestroy, OnInit} from '@angular/core';
import {InterfaceService, Phone} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {InstService} from '../services/inst.service';

@Component({
  selector: 'app-inst-parent',
  templateUrl: './inst-parent.component.html',
  styleUrls: ['./inst-parent.component.scss']
})
export class InstParentComponent implements OnInit, OnDestroy {
  phonelist: Phone[];
  $sub1;
  cured = 0;

  constructor(public is: InterfaceService,
              public inst: InstService,
              public admin: AdminService) {
  }

  ngOnInit() {
    if (!this.inst.curInstOrg) {
      this.inst.getCurInstOrg();
    }

    if (!this.admin.t) {
      this.is.getObject(`Trans/CARES/${this.admin.curlang}`).take(1).subscribe(trans => {
        this.admin.t = trans;
      });
    }

    if (!this.admin.curphone) {
      this.$sub1 = this.is.getList('Interfaces/phones').take(1).subscribe(p => {
          this.phonelist = p;
          this.admin.curphone = this.phonelist[0];
          this.admin.appwidth = this.phonelist[0].internalwidth;
        }
      );
    }

  }

  ngOnDestroy(): void {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
  }


}
