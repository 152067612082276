import {Component, Input, OnInit} from '@angular/core';
import {Days} from '../../services/constants';
import {AdminService} from '../../services/admin.service';

export class TimeCapture {
  start: number;
  end: number;
}

@Component({
  selector: 'app-hours-details',
  templateUrl: './hours-details.component.html',
  styleUrls: ['./hours-details.component.scss']
})
export class HoursDetailsComponent implements OnInit {
  dayweek = Days;
  topline: string;
  opendetails: boolean;
  days: string[];
  times: TimeCapture[];

  @Input()
  hours;

  @Input()
  side = false;

  constructor(private admin: AdminService) {
    this.topline = '';
    this.times = new Array();
  }

  convertmins(m): string {
    const minutes = m % 60;
    const hours = Math.floor(m / 60);

    const mstr = (minutes < 10 ? '0' : '') + minutes;
    let hstr = (hours < 10 ? '' : '') + hours;

    let t = 'am';
    if (hours > 11) {
      let h = hours;
      if (hours > 12) {
        h = hours - 12;
      } else {
        h = hours;
      }
      hstr = (h < 10 ? '' : '') + h;
      t = 'pm';
    }
    return hstr + ':' + mstr + t;
  }

  fillTimes(curday) {
    this.times = [];
    if (this.hours && this.hours.hours && this.hours.hours[curday] && this.hours.hours[curday].length > 0) {
      this.hours.hours[curday].forEach(hr => {
        if (hr.open) {
          const pieces = hr.tm.split(':');
          const t = new TimeCapture();
          t.start = pieces[0] * 60 + pieces[1] * 1;
          t.end = pieces[0] * 60 + pieces[1] * 1 + hr.len;
          this.times.push(t);
        }
      });
    }
  }

  getTodayHours(): string {
    let str = '';
    const curday = new Date().setHours(0, 0, 0, 0);
    const curtime = new Date().getHours() * 60 + new Date().getMinutes();

    let open = false;
    this.fillTimes(curday);

    if (this.times) {
      this.times.forEach(t => {
        if (curtime >= t.start && curtime <= t.end) {
          open = true;
        }
      });
    }

    if (!open && this.times) {
      let openagain;
      this.times.reverse().forEach(t => {
        if (curtime < t.start) {
          openagain = t.start;
        }
      });

      let openuntil;
      this.times.forEach(t => {
        if (curtime < t.end) {
          openuntil = t.end;
        }
      });

      str = 'Closed Now'; // this.admin.t['closednow'];
      if (openagain) {
        str = `${str}\n ${this.admin.t['opensat']} ${this.convertmins(openagain)}`;
      }

      /*
                  if (openuntil) {
                      str = str + ' - ' + this.convertmins(openuntil + 1);
                  }
      */
    }

    if (open) {
      str = 'Open';

      let openuntil;
      this.times.forEach(t => {
        if (curtime < t.end) {
          openuntil = t.end;
        }
      });
      if (openuntil) {
        str = `${str} ${this.admin.t['until']} ${this.convertmins(openuntil + 1 * 0)}`;
      }
    }

    return str;
  }

  fillOutDays() {
    this.days = new Array();
    let curday = new Date().setHours(0, 0, 0, 0);
    for (let x = 0; x < 7; x++) {
      const day = new Date(curday).getDay();
      if (this.hours.twentyfourseven) {

        this.days.push(`${this.dayweek[day]}: Open 24 hours`);
        // this.days.push(`${this.dayweek[day]}: ${this.admin.t['open']} 24/7`);
      } else {
        this.fillTimes(curday);
        if (this.times.length > 0) {
          this.days.push(
            this.dayweek[day] + ': ' +
            this.convertmins(this.times[0].start)
            + ' to ' +
            // todo: should this be zeroed out????
            this.convertmins(this.times[this.times.length - 1].end + 1 * 0));
        } else {
          this.days.push(`${this.dayweek[day]}: Closed`);
//         this.days.push(`${this.dayweek[day]}: ${this.admin.t['closed']}`);
        }
      }
      curday = curday + 86400000;
    }

  }

  ngOnInit() {
    if (this.hours.twentyfourseven) {
      this.topline = 'Hours: Open 24/7';
    } else {
      this.topline = `Hours: ${this.getTodayHours()}`;
    }

    this.fillOutDays();
  }

}
