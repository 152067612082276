import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {InterfaceService} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {CappAd} from '../services/invite.service';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.scss']
})
export class InvitesComponent implements OnInit, AfterViewInit {
  ads: CappAd[];
  curad: CappAd;
  detailsad = false;
  curlang = 'en';
  step = 0;

  constructor(private admin: AdminService,
              private ds: DomSanitizer,
              private is: InterfaceService) {

  }

  ngOnInit() {
    this.is.getList(`OrgsPri/${this.admin.admin.org}/ads`)
      .take(1)
      .subscribe(ads => {
        this.ads = ads;
      });
  }

  ngAfterViewInit(): void {
  }

  onEditAd(inv: CappAd) {
    this.curad = inv;
  }

  onAddAd() {
    this.detailsad = true;
  }

  onDeleteAd(i) {
    this.is.deleteObjNoLog(`Invites/${this.ads[i].ukey}`);
    this.ads.splice(i, 1);
  }



}
