import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AdminService} from '../services/admin.service';
import {InterfaceService} from '../services/interface.service';
import {ChatService} from '../services/chat.service';
import {MatDialog} from '@angular/material';
import {GetDeviceIdComponent} from '../lib/get-device-id/get-device-id.component';

@Component({
    selector: 'app-org-chat',
    templateUrl: './org-chat.component.html',
    styleUrls: ['./org-chat.component.scss']
})
export class OrgChatComponent implements OnInit {
    oldikey;

    constructor(public admin: AdminService,
                private cdr: ChangeDetectorRef,
                public cs: ChatService,
                public dialog: MatDialog,
                private is: InterfaceService) {
    }

    ngOnInit() {
        if (this.admin.admin.deviceid) {
            this.admin.setupChat();
        } else {
            const dialogRef = this.dialog.open(GetDeviceIdComponent, {
                width: '300px',
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.admin.setupChat();
                } else {
                    // todo: what to do if user hits cancel -> send to profile with router
                }
            });
        }
    }



    getDate(lr) {
        return new Date(lr);
    }

    onChatCheck(i) {
        return this.admin.conarr[this.admin.incidents[i].ukey];

    }

    onChat(i) {
        this.admin.curincident = this.admin.incidents[i];
        if (!this.oldikey || (this.oldikey !== this.admin.curincident.ukey) ) {
            const d = new Date().getTime();
            this.oldikey =  this.admin.curincident.ukey;
            this.is.setObjNoLog(`ROEDevices/${this.admin.admin.deviceid}/convo/${this.admin.curincident.ukey}/lr`, d);
        }
        this.cs.onChat(this.admin.curincident.ukey);
        this.cdr.detectChanges();
    }

}
