import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AdminModules,
  AdminService,
  ComModules,
  CorpModules,
  HCModules,
  InstModules,
  InvModules,
  OrgModules
} from './services/admin.service';
import {FirebaseService} from './services/firebase.service';
import {environment} from '../environments/environment';
import * as firebase from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {AngularFireDatabase} from '@angular/fire/database';
import {InterfaceService} from './services/interface.service';
import {DeviceService} from './services/device.service';
import {InstService} from './services/inst.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  editionsopen = false;
  widgetsopen = false;
  mods = OrgModules;
  hcmods = HCModules;
  corpmods = CorpModules;
  instmods = InstModules;
  adminmods = AdminModules;
  commods = ComModules;
  invmods = InvModules;
  inc = false;
  screenWidth: number;
  howmany: number;


  constructor(private fs: FirebaseService,
              private afAuth: AngularFireAuth,
              public is: InterfaceService,
              private inst: InstService,
              private db: AngularFireDatabase,
              private deviceService: DeviceService,
              private router: Router,
              public admin: AdminService) {
    this.deviceService.initPlatform().then((deviceOS) => {
      if (deviceOS === 'ios' || deviceOS === 'android') {
        console.log('smartphone');
      } else {
      }
    });
    this.admin.loggedin = false;
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
  }

  onChange(event) {
    this.inst.getCurInstOrg();
  }

  getName() {
    if (this.admin.admin.orgname) {
      return this.admin.admin.orgname;
    } else {
      return this.admin.admin.org;
    }
  }


  ngOnDestroy() {
    this.afAuth.auth.signOut();
  }

  howMany() {
    this.howmany = -1;
    if (this.admin.inbox && this.admin.inbox.length) {
      this.howmany = this.admin.inbox.filter(i => !i['read']).length;
    }
    if (this.howmany > 0) {
      return true;
    } else {
      return false;
    }
  }

  onLogOut() {
    /*
            this.admin.loggedin = false;
    */
    this.admin.preview = true;
    this.afAuth.auth.signOut().then(p => {
      this.router.navigate(['loginstart']);
    });
  }

  ngOnInit() {
    this.fs.initTitleIXFirebaseApp(environment.firebaseTitleIXConfig, environment.firebaseTitleIXName);

    this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

    /*
            this.db.object('Amock').valueChanges().take(1).subscribe(mock => {
                this.admin.mock = <boolean>mock;
                this.router.navigateByUrl('loginstart');
            });
    */
    this.router.navigateByUrl('loginstart');
  }

  onEditionJump(rte) {
    this.editionsopen = false;
    this.router.navigateByUrl(rte);
  }


  onExpand() {
    this.editionsopen = !this.editionsopen;
  }

  onWidgetJump(rte) {
    this.widgetsopen = false;
    this.router.navigateByUrl(rte);
  }


  onWidgetExpand() {
    this.widgetsopen = !this.widgetsopen;
  }


  onComOrg() {
    this.is.getObject(`Community/${this.admin.nextcom}`).take(1).subscribe(tmpOrg => {
      this.is.onSelectOrg(this.admin.nextcom, tmpOrg);
      this.router.navigateByUrl('comparent');
    });
  }
}

