import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CAMolyData, CAMolyInForm, CAMolyValue} from '../../services/ca';
import {InterfaceService} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {ImageCroppedEvent} from '../../services/imagecropper/interfaces';
import * as firebase from 'firebase/app';
import {AngularFireStorage} from '@angular/fire/storage';

export class EmojiPic {
  name: string;
  path: string;
  edition: string;
}

@Component({
  selector: 'app-ca-profilepic',
  templateUrl: './ca-profilepic.component.html',
  styleUrls: ['./ca-profilepic.component.scss']
})
export class CaProfilepicComponent implements OnInit {
  @Input()
  path: string;

  @Input()
  formusesukey: string;

  @Input()
  moly: CAMolyInForm;

  @Input()
  primarycolor = '#0000FF';

  @Input()
  secondarycolor = '#ffffff';

  @Input()
  toc = false;


  @Input()
  showContinue = true;

  @Input()
  conwithout = true;

  @Output()
  save = new EventEmitter<string>();

  @Output()
  plused = new EventEmitter<boolean>();

  @Output()
  propic = new EventEmitter<string>();

  @Output()
  continue = new EventEmitter();

  firsttime = true;
  editdesc = false;
  oldphotourl: string;
  descdata: CAMolyData;

  scope = 'global';
  curlang = 'en';
  ukey: string;

  //////
  getpic = false;
  saving = false;
  instr = 'Tap on the plus to pick a photograph';
  ic = false;
  size = 96;
  inputsize = false;
  inputheight = 96;
  inputwidth = 96;
  wd = '100%';
  sd = 100;

  croppedImage: any = '';
  rawBase64ProfileImage: string;

  emojipics: EmojiPic[];

  // todo: this is probably description
  photourl: string;
  cansave = false;
  imageChangedEvent: any = '';

  curemoji: EmojiPic;
  pos = -1;
  picpos = -1;


  deviceId = 1003663;
  indivukey = '-M7seJGDqVSWJndmt754';

  @ViewChild('file', {static: false}) file: any;


  constructor(private is: InterfaceService,
              private cd: ChangeDetectorRef,
              private afStorage: AngularFireStorage,
              private db: AngularFireDatabase) {
  }

  getEmojiPics() {
    if (!this.emojipics) {
      this.is.getList(`ROEDevices/${this.deviceId}/emojipics`).take(1).subscribe(pics => {
        this.emojipics = pics;
      });
    }
  }

  onGetPic(event, e, i) {
    event.stopPropagation();
    this.getpic = e.path;
    this.picpos = i;
  }

  ngOnInit() {
    this.getEmojiPics();

    this.is.getObject(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`)
      .take(1)
      .subscribe(val => {
        if (!val) {
          this.setUp();
        } else {
          this.ukey = val.ukey;
          let pth;
          if (val.scope === 'global') {
            pth = `${this.path}/data/${this.ukey}`;
          } else if (val.scope === 'local') {
            pth = `${this.path}/formvals/${this.formusesukey}/${this.ukey}`;
          }
          this.scope = val.scope;
          this.is.getObject(pth)
            .take(1)
            .subscribe(res => {
              console.log('res', res);
              this.firsttime = false;
              this.descdata = new CAMolyData();
              this.descdata.name = this.moly.moly.name;
              this.descdata.molyukey = this.moly.moly.ukey;
              this.descdata.value = res.value;
              this.photourl = this.descdata.value[this.descdata.value.length - 1].data;
              this.oldphotourl = this.photourl;
              this.editdesc = false;
            });
        }
      });
  }

  setUp() {
    this.ukey = this.db.createPushId();

    this.descdata = new CAMolyData();
    this.descdata.name = this.moly.moly.name;
    this.descdata.molyukey = this.moly.moly.ukey;
    this.descdata.value = [];
    this.photourl = '';
    this.oldphotourl = this.photourl;
    this.editdesc = true;
  }

  onInbox(str) {
    this.scope = str;
    this.firsttime = true;
  }

  onClickDesc() {
    this.editdesc = true;
  }


  descriptionSave() {
    if (this.oldphotourl !== this.photourl) {

      if (!this.descdata.value) {
        this.descdata.value = [];
      }

      const v = new CAMolyValue();
      v.data = this.photourl;

      // todo: change to international time? save the timezone?
      v.date = new Date().getTime();
      this.descdata.value.push(v);
      this.oldphotourl = this.photourl;
      this.editdesc = false;

      let loc;
      if (this.scope === 'global') {
        loc = 'data';
      } else {
        loc = `formvals/${this.formusesukey}`;
      }
      this.is.setObjNoLog(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`, {ukey: this.ukey, scope: this.scope});

      const p = `${this.path}/${loc}/${this.ukey}`;
      if (this.firsttime) {
        this.is.setObjNoLog(p, this.descdata);
        this.firsttime = false;
      } else {
        this.is.setObjNoLog(`${p}/value/${this.descdata.value.length - 1}`,
          this.descdata.value[this.descdata.value.length - 1]);
      }

      // emit success
      // todo: not sure if have to emit the ukey
      this.save.emit(this.ukey);
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.cansave = true;
    this.croppedImage = event.base64;
  }


  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }

  onSliderChange(event) {
    this.wd = event.value; // `${event.value}%`;
    this.cd.detectChanges();
  }

  onPlus() {
    this.ic = true;
    this.plused.emit(true);
  }

  onSave() {
    this.sd = 100;
    this.saving = true;
    const self = this;
    const ukey = this.db.createPushId();
    const filename = `Indivs/${this.deviceId}/${ukey}.png`;

    const fileRef = this.afStorage.ref(filename);
    const data = this.croppedImage.split(',');
    const storageRef = firebase.storage();
    const uploadTask = fileRef.putString(data[1], 'base64', {contentType: 'image/png'});
    uploadTask.then(
      v => {
        const s = `gs://library-titleix.appspot.com/${filename}`;
        storageRef.refFromURL(s).getDownloadURL().then(url => {
          const uarr = url.split('=');
          // image here uarr[2];
          // self.fs.setObj(`Matchme/users/${self.mm.curuserukey}/profile/kp/d`, uarr[2]);
          self.photourl = self.croppedImage;
          // self.imageChangedEvent = null;
          // self.rawBase64ProfileImage = '';
          self.saving = false;
          self.instr = '';

          self.cansave = false;
          self.ic = false;
          self.rawBase64ProfileImage = null;
          self.imageChangedEvent = null;
          console.log('url', url);

          self.propic.emit(url);
        });
      }
    ).catch(err => {
      self.ic = false;
      self.saving = false;
    });
  }

  onClear() {
    this.ic = true;
    this.ic = true;
    this.instr = '';
    this.file.nativeElement.value = null;
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  onCancel() {
    this.sd = 100;
    this.cansave = false;
    this.rawBase64ProfileImage = null;
    this.ic = false;
  }

  onContinue() {
    this.continue.emit();
  }

  onGetBack(e) {
    this.curemoji = e;
  }
}
