import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CappTeamMsg, InterfaceService} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-roe-template',
  templateUrl: './roe-template.component.html',
  styleUrls: ['./roe-template.component.scss']
})
export class RoeTemplateComponent implements OnInit {
  @Input()
  n: CappTeamMsg;

  @Output()
  delete = new EventEmitter<boolean>();

  constructor(public admin: AdminService,
              public is: InterfaceService) { }

  ngOnInit(): void {
    if (!this.n['checked']) {
      this.is.getObject(`Notifications/${this.n.ukey}/data`)
        .take(1)
        .subscribe(stat => {
          this.n['checked'] = true;
          if (stat && stat.action && stat.action === 'D') {
            this.delete.emit();
          }
        });
    }
  }

}
