export const environment = {
    production: false,
    firebaseTitleIXName: 'TitleIX',
    titleixUrl:  'https://us-central1-library-titleix.cloudfunctions.net',
    firebaseTitleIXConfig: {
        apiKey: 'AIzaSyCZYeiSVEp-gqu4av3j1277i9d7gIqsQcg',
        authDomain: 'library-titleix.firebaseapp.com',
        databaseURL: 'https://library-titleix.firebaseio.com',
        projectId: 'library-titleix',
        storageBucket: 'library-titleix.appspot.com',
        messagingSenderId: '60444556162'
    },
    firebaseSchoolName: 'Capptivation University',
    firebaseSchoolConfig: {
        apiKey: 'AIzaSyAov_R3rPLDg7UBWZd81A0nV1NoPn0Wt3I',
        authDomain: 'capptivation-university.firebaseapp.com',
        databaseURL: 'https://capptivation-university.firebaseio.com',
        projectId: 'capptivation-university',
        storageBucket: '',
        messagingSenderId: '1022253557061'
    },
    MAPBOX_API_KEY: 'pk.eyJ1IjoicmljaGFyZGF0Y2FwcCIsImEiOiJja2NkZW93OTYwZHg4MnpwcXlycmZwdjllIn0.TZytt1WakDgCiPi1mZqC_A',
    GOOGLE_MAPS_KEY: 'AIzaSyC2bBTexjMea7XU6T3IPdWGdDIl-u99pWQ',
    GOOGLE_ROADS_API: 'AIzaSyBJoTccRZmbhQbbn9bTuzmXGGYnHHi0fFo',
    algolia : {
        apiKey: `373dbe79a969c04fca8f3ea347126b65`,
        appId: `A4YFG99HMY`
    }
}
