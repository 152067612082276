import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'app-events',
  templateUrl: './org-events.component.html',
  styleUrls: ['./org-events.component.scss']
})
export class OrgEventsComponent implements OnInit {
  url: string;
  loc = 'Orgs'

  constructor(private admin: AdminService,
              private router: Router) {
    this.url = this.router.url;
  }

  ngOnInit() {
    this.loc = this.admin.getLoc(this.url);

    if (this.loc === 'Inst') {
      this.loc = 'InstPri';
    }
  }

}
