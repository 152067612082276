import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CappTeamMsg, InterfaceService} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';

@Component({
    selector: 'app-roe-x',
    templateUrl: './roe-x.component.html',
    styleUrls: ['./roe-x.component.css']
})
export class RoeXComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  @Output()
  changehorse = new EventEmitter();

  from: string;
  colone = '34%';
  coltwo = '66%';
  lnk: string;

  primarycolor;

  constructor(public is: InterfaceService,
              public admin: AdminService) {
    super(admin, is);

  }

  ngOnInit() {
    super.ngOnInit();

    this.primarycolor = this.admin.getPrimary();

    const x = Math.round(9000 / this.admin.appwidth);
    this.colone = `${x}%`;
    this.coltwo = `${100 - x}%`;

    if (this.n.l) {
      this.lnk = this.n.l.replace('thumb_', '');
    }
  }



}
