import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Invitation} from '../../../services/interface.service';
import {BizDay, BizMonth} from '../../../services/bizhours.service';
import * as moment from 'moment';

@Component({
    selector: 'app-com-biz-month',
    templateUrl: './com-biz-month.component.html',
    styleUrls: ['./com-biz-month.component.scss']
})
export class ComBizMonthComponent implements OnInit {
    dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    daypermonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    weeks: any[];
    isSingleClick = true;
    shortholidays: Invitation[];

    @Input()
    holidays: Invitation[];

    @Input()
    tfour: boolean;

    @Input()
    curMonth: BizMonth;

    @Output()
    clickDay = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
        const mth = this.curMonth.month.getMonth();
        const y = this.curMonth.month.getFullYear();

        this.shortholidays = new Array();
        this.holidays.forEach(h => {
            const d = moment(h.start);
            if (d.month() === mth && d.year() === y) {
                this.shortholidays.push(h);
            }
        });

        const startday = this.curMonth.month.getDay();


        this.weeks = new Array();
        this.weeks[0] = new Array();
        let cnt = 0;

        for (let i = 0; i < startday; i++) {
            const newday = new BizDay();
            newday.day = new Date();
            newday.color = '#D3D3D3';
            newday.day.setDate(this.curMonth.month.getDate() - startday + i);

            this.weeks[0].push(newday);
        }

        for (let i = 0; i < 7 - startday; i++) {
            this.weeks[0].push(this.curMonth.days[i]);
            cnt = cnt + 1;
        }

        const numadditionwaleeks = Math.ceil((this.daypermonth[this.curMonth.month.getMonth()] + startday) / 7) - 1;

        this.curMonth.days.forEach(day => {
                this.shortholidays.forEach(h => {
                    const d = moment(h.start);
                    if (d.date() === day.day.getDate()) {
                        day.holiday = true;
                    }
                });
            }
        );


        for (let m = 0; m < (numadditionwaleeks - 1); m++) {
            this.weeks[m + 1] = new Array();
            for (let d = 0; d < 7; d++) {
                this.weeks[m + 1].push(this.curMonth.days[cnt]);
                cnt = cnt + 1;
            }
        }

        this.weeks[this.weeks.length] = new Array();
        let lastweek = this.daypermonth[this.curMonth.month.getMonth()] - cnt;


        for (let i = 0; i < lastweek; i++) {
            this.weeks[numadditionwaleeks].push(this.curMonth.days[i + cnt]);
        }

        let year = this.curMonth.month.getFullYear();
        for (let i = 0; i < 7 - lastweek; i++) {
            let newday = new BizDay();
            newday.day = new Date();
            newday.color = '#D3D3D3';
            let mth = this.curMonth.month.getMonth() + 1;
            if (this.curMonth.month.getMonth() === 11) {
                mth = 0;
                year = this.curMonth.month.getFullYear() + 1;
            }
            newday.day.setFullYear(year, mth, i + 1);

            this.weeks[numadditionwaleeks].push(newday);
        }

    }

    dayClick(event, d: BizDay) {
        this.isSingleClick = true;
        setTimeout(() => {
            if (this.isSingleClick) {
                this.clickDay.emit({event, d});
            }
        }, 200);

    }


}
