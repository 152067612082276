import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent implements OnInit {
  @Input()
  loc = 'Orgs';

  @Output()
  widgetopen = new EventEmitter()

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onWidgetJump(rte) {
    // this.widgetsopen = false;
    this.widgetopen.emit();
    console.log('rte rte', rte)
    this.router.navigateByUrl(rte);
  }


}
