import {Component, Input, OnInit} from '@angular/core';
import {CappTeamMsg, Faq, InterfaceService, LangStringBlock, OrgEdition, Topix, Widget} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {RoehttpService} from '../services/roehttp.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  @Input()
  topics: Topix[];

  @Input()
  eds: OrgEdition[];

  topic;
  d: number;
  questions: CappTeamMsg[];
  details = false;
  curlang: string;
  curmsg: CappTeamMsg;
  response = false;
  faq;
  widgets;
  picked: Widget;

  config = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['font', ['bold', 'italic', 'underline']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };


  constructor(public admin: AdminService,
              private http: RoehttpService,
              private is: InterfaceService,
              private db: AngularFireDatabase) {

  }

  ngOnInit() {
    this.d = new Date().getTime();
    this.curlang = this.admin.language;

    this.is.getList(`Orgs/${this.admin.admin.org}/questions`)
      .take(1)
      .subscribe(questions => {
        this.questions = questions;
        console.log('questions', this.questions);
      });

    this.is.getQuery(`Orgs/${this.admin.admin.org}/widgets`, 'tag', 'faq')
      .take(1)
      .subscribe(w => {
        this.widgets = w;
        console.log('widgets', this.widgets);
      });
  }

  onDelete(i) {

  }

  onAdd() {

  }


  onSend() {
    if (this.picked && this.faq) {
      // saves the question/answer to FAQs
      const obj = new Faq();
      obj.ukey = this.curmsg.ukey;
      obj.question = this.curmsg.fullmsg;
      obj.subject = this.curmsg.ssoc;
      obj.author = this.admin.admin.name;
      obj.answer = this.curmsg.l.response;

      obj.subject['de'].description = '';
      this.is.setObjNoLog(`Orgs/${this.admin.admin.org}/FAQS/${this.picked.ukey}/${this.curmsg.ukey}`, obj);
    }

    if (!this.response) {
      // send to just the questioner
      console.log('answering question')
      this.http.answerQuestion(this.curmsg);
    } else {
      // send to everybody who has subscribed to a topic
      if (this.topic) {
        console.log('this topic', this.topic);
        // send to the topic
        // this function triggers a cloud function called postTopixNotification
        this.curmsg.topics = this.topic;
        this.http.setField(`Topics/notifications/${this.curmsg.topics}/${this.curmsg.ukey}`, this.curmsg);

        // todo: delete the original notification in the questioner
      }
    }

    this.is.deleteObjNoLog(`Orgs/${this.admin.admin.org}/questions/${this.curmsg.ukey}`);

    this.curmsg = null;
  }

  onRespond(n) {
    this.curmsg = n;
    if (!this.curmsg.l) {
      this.curmsg.l = {};
      this.curmsg.l.org = this.admin.admin.org;
      this.curmsg.l.response = new LangStringBlock();
    }
  }
}
