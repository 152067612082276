import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AgmMap, LatLngBounds} from '@agm/core';
import {
  Geo,
  InterfaceService,
  MapCategory,
  MapCategoryElement,
} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {DateService} from '../services/date.service';

declare const google: any;


@Component({
  selector: 'capp-map-custom',
  templateUrl: './map-custom.component.html',
  styleUrls: ['./map-custom.component.scss']
})
export class ROMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  mapukey: string;

  @Input()
  appht: number;

  @Input()
  curcategory: number;

  @Output()
  mapclicked = new EventEmitter<number[]>();

  mapcategories: MapCategory[];
  curlang = 'en';
  cat = '';
  opencat = false;
  userlat: number;
  userlong: number;
  curmarker: number;
  markerstate: boolean;
  mesel: boolean;
  friendsel: boolean;
  friendaddress: string;
  haveuserpos: boolean;
  $sub1;
  $sub2;
  tmpOrgGeo: Geo;
  long: number;
  lat: number;
  bounds;
  newheight = 0;
  friendlong: number;
  friendlat: number;
  cur;
  curelement: MapCategoryElement;


  redmarker = 'http://ron.capptivation.com/assets/mapmarkers/red-dot.png';
  bluemarker = 'http://ron.capptivation.com/assets/mapmarkers/blue-dot.png';
  centericon = 'http://ron.capptivation.com/assets/mapmarkers/topcenter.png';
  meicon = 'http://ron.capptivation.com/assets/mapmarkers/me.png';
  bluemeicon = 'http://ron.capptivation.com/assets/mapmarkers/blueman.png';
  friendsicon = 'http://ron.capptivation.com/assets/mapmarkers/friends.png';
  mystyle = [
    {
      featureType: 'poi.school',
      elementType: 'geometry.fill',
      stylers: [
        {
          'color': '#ffeb3b'
        },
        {
          'saturation': 15
        },
        {
          'lightness': 75
        }
      ]
    }
  ];

  @ViewChild(AgmMap, {static: false}) mapElement: any;

  menu = [{icon: 'filter_center_focus', opt: 'recenter', name: 'Re-center Map'},
    {icon: 'my_location', opt: 'mylocation', name: 'My Location'},
    {icon: 'accessibility', opt: 'friend', name: 'Find Friend'}];


  constructor(private is: InterfaceService,
              private ds: DateService,
              public admin: AdminService) {
    this.curmarker = -1;
    this.markerstate = false;
    this.userlat = -1;
    this.userlong = -1;
    this.mesel = false;
    this.friendsel = false;
    this.friendaddress = '';
    this.haveuserpos = false;

  }

  ngOnInit() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userlat = position.coords.latitude;
        this.userlong = position.coords.longitude;
        this.haveuserpos = true;
      }, err => {
        this.menu.splice(1, 1);
      });
    }
  }

  fixbnds(lat, long) {
    const bnds = this.bounds;
    const latLng = new google.maps.LatLng(lat, long);
    bnds.extend(latLng);
    this.bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(bnds.getSouthWest().lat(), bnds.getSouthWest().lng()),
      new google.maps.LatLng(bnds.getNorthEast().lat(), bnds.getNorthEast().lng())
    );
  }

  fillCat() {
    if (this.mapcategories &&
      this.mapcategories[this.curcategory] &&
      this.mapcategories[this.curcategory].elements &&
      this.mapcategories[this.curcategory].elements.length > 0) {
      this.mapcategories[this.curcategory].elements.forEach(el => {
        if (el.path.includes('offices')) {
          this.is.getObject(el.path).take(1).subscribe(office => {
            el.lat = office.lat;
            el.long = office.long;
            el.link = office;
            this.fixbnds(el.lat, el.long);
          });
        }

        if (el.path.includes('staff')) {
          this.is.getObject(el.path).take(1).subscribe(staff => {
            el.lat = staff.lat;
            el.long = staff.long;
            el.link = staff;
            this.fixbnds(el.lat, el.long);
          });
        }

        // todo: what if there are more than one locations???? this is broken
        if (el.path.includes('Community')) {
          // todo: this isn't the right way to do this since it reads from database - wont work if offline
          this.is.getObjectFS(el.path).take(1).subscribe(val => {
            if (val) {
              el.lat = val.locations[0].geo.lat;
              el.long = val.locations[0].geo.long;
              el.link = val;
              this.fixbnds(el.lat, el.long);
            }
          });
        }

      });
    }
  }

  ngAfterViewInit() {
    this.$sub1 = this.is.getObject(`Orgs/${this.admin.admin.org}/profile/geo`).take(1).subscribe(geo => {
      this.tmpOrgGeo = geo;
      this.$sub2 = this.is.getList(`Orgs/${this.admin.admin.org}/maps/${this.mapukey}`).subscribe(mcats => {
        if (mcats && mcats.length > 0) {
          this.mapcategories = mcats;
          this.curcategory = 0;
          this.cat = this.mapcategories[this.curcategory].nameDesc[this.curlang].description;
          this.fillCat();
        }
      });

      this.long = this.tmpOrgGeo.long * 1;
      this.lat = this.tmpOrgGeo.lat * 1;
      this.ds.calcOffsets(this.long, this.lat);
      this.tmpOrgGeo.backcolor = '#ffeb3b';
      this.tmpOrgGeo.selcolor = '#776d10';
      if (this.mapElement) {
        this.mapElement._mapsWrapper.getNativeMap().then(map => {
          this.bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(this.tmpOrgGeo.south * 1, this.tmpOrgGeo.west * 1),
            new google.maps.LatLng(this.tmpOrgGeo.north * 1, this.tmpOrgGeo.east * 1)
          );
        });
      }
    });
  }

  onResize(event) {
    this.newheight = event.newHeight;
  }

  onBoundsChange(ev) {
    /*
    this.mlng = ev.b.b;
    this.mlat = (ev.f.b + ev.f.f) / 2;
    */
  }

  onApply(ev) {
    this.mapclicked.emit(ev.coords);
  }

  onClose() {
    this.newheight = 0;
    this.curmarker = -1;
    this.curelement = null;
    this.markerstate = false;
  }

  onmarkerClick(i) {
    if (this.markerstate) {
      this.onClose();
    } else {
      this.cur = this.mapcategories[this.curcategory].elements[i];
      this.markerstate = true;
    }
  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
  }

  onSelChange(event) {
    event.stopPropagation();
    // todo: verify that this is correct
    const x = this.mapcategories.findIndex(m => m.nameDesc[this.curlang].description === this.cat);
    if (x > -1) {
      this.curcategory = x;
    }
  }


}
