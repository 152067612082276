import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Exhibit, InterfaceService, NotificationForm, OrgEdition} from '../services/interface.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireDatabase} from '@angular/fire/database';
import * as firebase from 'firebase/app';
import {RoehttpService} from '../services/roehttp.service';
import {AdminService} from '../services/admin.service';
import {DomSanitizer} from '@angular/platform-browser';
import {OrganizationService} from '../services/organization.service';
import {FileHandle} from '../services/dragDrop.directive';
import {Router} from '@angular/router';
import {InstService} from '../services/inst.service';

@Component({
  selector: 'app-org-exhibits',
  templateUrl: './org-exhibits.component.html',
  styleUrls: ['./org-exhibits.component.scss']
})
export class OrgExhibitsComponent implements OnInit {
  exhibitsarray;
  firsttmp = false;
  exhibitdetails = false;
  exhibitdescription: string;
  exhibittags: string;
  gotfile = '';
  filername = '';
  th = '';
  first = false;

  colone: Exhibit[];
  coltwo: Exhibit[];
  colthree: Exhibit[];

  sec = '';

  onepos = -1;
  twopos = -1;
  threepos = -1;

  candel = false;
  path = `https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/`;
  suffix = `?alt=media`;

  exhibitsread = false;
  exhibitsadd = false;
  exhibitsdelete = false;
  exhibitsdownload = false;
  posarr = -1;
  posex = -1;
  curpos = -1;
  thumb = '';
  caller = 'Orgs';

  typeex = 'Image';
  link = '';
  typeexs = ['Image', 'Link', 'PDF', 'Video', 'Other'];

  images = '.gif,.png,.jpg,.jpeg,.bmp,.tif,.tiff,.svg';

  fileTypes = [
    'video/ogg',
    'video/webm',
    'video/mp4'];

  fileITypes = [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg',
    'image/svg+xml',
  ];

  filetypes: any;
  files: FileHandle[] = [];


  spinner = false;
  err = '';
  image;
  thumbfilename = '';
  fname = '';
  url: string;

  edition: string;

  eds: OrgEdition[];
  exhibitnumcols = 1;
  flexone: string;
  flextwo: string;
  flexthree: string;
  doshow = true;

  category = 'Exhibit';
  filter = 'All';

  constructor(private admin: AdminService,
              private http: HttpClient,
              private inst: InstService,
              private rs: RoehttpService,
              private ds: DomSanitizer,
              public og: OrganizationService,
              private cd: ChangeDetectorRef,
              private auth: AngularFireAuth,
              private router: Router,
              private is: InterfaceService,
              private db: AngularFireDatabase) {

    this.filetypes = this.images;
    this.url = this.router.url;
  }

  validFileType(file) {
    for (let i = 0; i < this.fileTypes.length; i++) {
      if (file.type === this.fileTypes[i]) {
        return true;
      }
    }
    return false;
  }

  filesDropped(files): void {
    this.changeListener([files[0].file]);
  }

  validImageType(file) {
    for (let i = 0; i < this.fileITypes.length; i++) {
      if (file.type === this.fileITypes[i]) {
        return true;
      }
    }
    return false;
  }

  changeListener(files) {
    this.spinner = true;

    const file = files[0];
    this.err = '';
    if (this.typeex === 'Video' && !this.validFileType(file)) {
      this.err = 'Invalid video file format, must be: .mp4, .ogg, or .webm';
      return;
    }

    if (this.typeex === 'Image' && !this.validImageType(file)) {
      this.err = 'Invalid video file format, must be: ' + this.images;
      return;
    }

    this.fname = file.name;
    let ext = file.name.split('.')[1];
    const ukey = this.db.createPushId();


    if (this.sec && this.typeex === 'Video') {
      const secfilename = `${this.caller}Pri/${this.admin.admin.org}/exhibitsec/${this.edition}/thumb_${ukey}`;
      this.is.setObjNoLog(secfilename, this.sec);
    }

    // this is where store an uploaded file to a cloud storage bucket
    const filename = `${this.caller}/${this.admin.admin.org}/exhibits/${this.edition}/${ukey}.${ext}`;

    if (this.typeex === 'PDF') {
      ext = 'png';
    } else if (this.typeex === 'Video' || file.name.toLowerCase().includes('.gif')) {
      ext = 'jpg';
    }

    this.thumbfilename = '';
    if (ext !== 'svg' && this.typeex !== 'Other') {
      const org = this.admin.admin.org.replace(/\s/g, '%20');
      console.log('org', org)
      this.thumbfilename = `${this.path}${this.caller}%2F${org}%2Fexhibits%2F${this.edition}%2Fthumb_${ukey}.${ext}${this.suffix}`;
    }

    const storageRef = firebase.storage();
    firebase.storage().ref().child(filename).put(file).then(
      v => {
        const s = `gs://library-titleix.appspot.com/${filename}`;
        storageRef.refFromURL(s).getDownloadURL().then(url => {
          this.gotfile = url;

          if (this.sec && this.typeex === 'Video') {
/*
            const secfilename = `${this.caller}Pri/${this.admin.admin.org}/exhibitsec/${this.edition}/thumb_${ukey}`;
            this.is.setObjNoLog(secfilename, this.sec);
*/

            this.spinner = true;
            const waiting = `${this.caller}Pri/${this.admin.admin.org}/exhibitsec/${this.edition}/thumbcr_${ukey}`;
            const $sub1 = this.is.getObject(waiting).subscribe(val => {
              if (val) {
                this.spinner = false;
                this.first = true;
                $sub1.unsubscribe();
                this.is.deleteObjNoLog(waiting);
              }
            });
          } else {
            console.log('spinner gotfile', this.gotfile);
            this.spinner = false;
          }

        });
      }
    );
  }

  // https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Orgs%2FNorthwest%20Vista%20College%2Fexhibits%2F-LwntZjDEH1eBEAz2BZS%2Fthumb_-Lx1wwMWj2tNifMlYSQU.jpg?alt=media&token


  onChange() {
    this.err = '';
    this.image = null;
    this.link = '';
    this.gotfile = null;
    //     typeexs = ['Image', 'Link', 'PDF', 'Video', 'Other'];
    if (this.typeex === 'Image') {
      this.filetypes = this.images;
    } else if (this.typeex === 'PDF') {
      this.filetypes = '.pdf';
    } else if (this.typeex === 'Video') {
      this.filetypes = '.ogg,.webm,.mp4';
    }
  }

  // if request.auth.token.incidentId.includes(incidentId);

  // https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Orgs%2FNorthwest%20Vista%20College%2Fexhibits%2F-LwntZjDEH1eBEAz2BZS%2Fthumb_-Mh0I8HcSfOHym_7Yl96.9.png?alt=media
  // https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Orgs%2FNorthwest%20Vista%20College%2Fexhibits%2F-LwntZjDEH1eBEAz2BZS%2Fthumb_-Mh0I8HcSfOHym_7Yl96.png?alt=media

  postScreenShot(urlv) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postScreenShot';
    this.auth.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
      return this.http.post(url,
        {
          url: urlv,
        },
        {headers: headersv}).toPromise();
    }).then(res => {
      this.spinner = false;
      if (!res['image']) {
        this.err = 'Something went wrong. Please make sure the link is correct.';
      }
      this.image = 'data:image/png;base64,' + res['image'];
    }).catch(err => {
      this.err = 'Something went wrong. Please make sure the link is correct.';
      this.spinner = false;

    });
  }

  onAddLink() {
    this.err = '';

    const urltest = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port
      '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i');

    if (urltest.test(this.link)) {
      this.spinner = true;
      this.postScreenShot(this.link);
    } else {
      this.err = 'Please enter a valid url.';
    }
  }


  saveComplete(filepath, thumbpath) {
    this.exhibitsarray[this.posarr].description = this.exhibitdescription;
    this.exhibitsarray[this.posarr].tags = this.exhibittags;
    this.exhibitsarray[this.posarr].filepath = filepath;
    this.exhibitsarray[this.posarr].thumbpath = thumbpath;
    this.exhibitsarray[this.posarr].type = this.typeex;
    if (this.category) {
      this.exhibitsarray[this.posarr].app = this.category;
    }
    if (this.link) {
      this.exhibitsarray[this.posarr].link = this.link;
    } else if (filepath) {
      this.exhibitsarray[this.posarr].link = filepath;
    }

    this.exhibitsarray[this.posarr].users = null; // this concept doesn't work outside of the cappteams construct.

    // when an exhibit is create it automatically triggers the postExhibitNotification cloud function
    this.is.setObjNoLog(`${this.caller}Pri/${this.admin.admin.org}/exhibits/${this.edition}/${this.exhibitsarray[this.posarr].ukey}`, this.exhibitsarray[this.posarr]);


    this.gotfile = null;
    this.exhibitdetails = false;
    this.spinner = false;

  }

  onSaveExhibit() {
    this.spinner = true;

    // gghh

    if (this.gotfile && this.typeex !== 'Link') {
      this.saveComplete(this.gotfile, this.thumbfilename);
    }

    if (this.image && this.typeex === 'Link') {
      const lnk = this.db.createPushId();
      const filename = `${this.caller}/${this.admin.admin.org}/exhibits/${this.edition}/${lnk}.png`;
      if (this.sec) {
        const secfilename = `${this.caller}Pri/${this.admin.admin.org}/exhibitsec/${this.edition}/${lnk}`;
        this.is.setObjNoLog(secfilename, this.sec);
      }

      const thumbfilename = `${this.path}${this.caller}%2F${this.admin.admin.org}%2Fexhibits%2F${this.edition}%2Fthumb_${lnk}.png${this.suffix}`;

      const storageRef = firebase.storage();
      const data = this.image.split(',');
      const uploadTask = firebase.storage().ref().child(filename).putString(data[1], 'base64', {contentType: 'image/png'});
      uploadTask.then(
        v => {
          this.saveComplete('', thumbfilename);
        }
      ).catch(err => {
        this.spinner = false;
      });
    } else if ((this.th && this.th.length > 0) && this.typeex === 'Link') {
      this.saveComplete('', this.th);
    }
  }

  onSelChange(event) {
    if (this.exhibitsarray[this.posarr] && !this.exhibitsarray[this.posarr].users) {
      this.exhibitsarray[this.posarr].users = [];
    }
  }

  onCancel() {
    this.gotfile = null;
    this.firsttmp = false;
    this.exhibitdetails = false;
    this.image = null;
    this.link = '';
  }

  onAdd(i) {
    if (!this.exhibitsarray) {
      this.exhibitsarray = [];
    }
    const exh = new Exhibit();
    exh.date = new Date().getTime();
    exh.description = '';
    exh.filepath = '';
    exh.thumbpath = '';
    exh.type = '';
    exh.link = '';
    exh.creator = this.admin.admin.deviceid.toString();
    exh.ukey = this.db.createPushId();
    exh.users = [];
    exh.edition = this.edition;
    exh.likes = 0;
    exh.heart = false;
    exh.orgpath = `${this.caller}Pri/${this.admin.admin.org}/exhibits/${this.edition}/${exh.ukey}`;
    exh.creatorname = 'Admin'; // this.admin.admin.name;
    exh.commentpath = `Comments/${this.edition}`;
    this.exhibitsarray.push(exh);

    this.posex = i;
    this.posarr = this.exhibitsarray.length - 1;
    this.exhibitdescription = '';
    this.exhibittags = '';
    this.typeex = 'Image';
    this.link = '';
    this.fname = '';
    this.th = '';
    this.exhibitdetails = true;
    this.firsttmp = true;
  }

  onDelete(e) {
    const x = this.exhibitsarray.findIndex(ex => ex.ukey === e.ukey);

    if (e.type === 'Link') {
      let delval = e.thumbpath.replace(this.path, '');
      delval = delval.split('?')[0].replace(/%2F/g, '/').replace(/%20/g, ' ');
      firebase.storage().ref().child(delval).delete();
    } else {
      let delfile = e.filepath.replace(this.path, '');
      delfile = delfile.split('?')[0].replace(/%2F/g, '/').replace(/%20/g, ' ');
      firebase.storage().ref().child(delfile).delete();

      let delval = e.thumbpath.replace(this.path, '');
      delval = delval.split('?')[0].replace(/%2F/g, '/').replace(/%20/g, ' ');
      firebase.storage().ref().child(delval).delete();

      if (e.type === 'PDF') {
        // delete the non thumb image
        delval = delval.replace('thumb_', '');
        firebase.storage().ref().child(delval).delete();
      }

    }
    this.is.deleteObjNoLog(`${this.caller}Pri/${this.admin.admin.org}/exhibits/${this.edition}/${e.ukey}`);

    this.exhibitsarray.splice(x, 1);
    this.updateEx();
  }

  makePth(i) {
    if (this.exhibitsarray[i].type === 'Link') {
      return this.exhibitsarray[i].link;
    } else {
      return this.exhibitsarray[i].filepath;
    }
  }

  onViewColOne(i, event) {
    if (!this.exhibitdetails) {
      this.onepos = i;
      this.twopos = -1;
      this.threepos = -1;

      if (this.colone[i].filepath) {
        window.open(this.colone[i].filepath, '_blank');
      } else if (this.colone[i].link) {
        window.open(this.colone[i].link, '_blank');
      }
    }
  }

  onViewColTwo(i) {
    if (!this.exhibitdetails) {
      this.onepos = -1;
      this.twopos = i;
      this.threepos = -1;

      if (this.coltwo[i].filepath) {
        window.open(this.coltwo[i].filepath, '_blank');
      } else if (this.coltwo[i].link) {
        window.open(this.coltwo[i].link, '_blank');
      }
    }
  }

  onViewColThree(i) {
    if (!this.exhibitdetails) {
      this.onepos = -1;
      this.twopos = -1;
      this.threepos = i;

      if (this.colthree[i].filepath) {
        window.open(this.colthree[i].filepath, '_blank');
      } else if (this.colthree[i].link) {
        window.open(this.colthree[i].link, '_blank');
      }
    }
  }

  onFilter() {
    console.log('filter', this.filter)
    this.updateEx();
  }

  updateEx() {
    this.colone = [];
    this.coltwo = [];
    this.colthree = [];
    if (this.exhibitsarray && this.exhibitsarray.length > 0) {
      let cnt = 0;
      this.exhibitsarray.forEach(ex => {
        console.log('ex.app', ex.app)
        if (this.filter === 'All' || !this.filter ||
           (this.filter === 'Exhibit' && (!ex.app || ex.app === 'Exhibit')) ||
           (this.filter === 'Other' && ex.app === 'Other')   ) {


          ex['exhibitsdelete'] = false;
          ex['exhibitsdownload'] = false;

          if (ex.filepath.includes('.svg')) {
            ex['svgpath'] = this.ds.bypassSecurityTrustResourceUrl(ex.filepath);
          }

          if (this.exhibitnumcols === 1) {
            this.colone.push(ex);
            this.flexone = '100%';
            this.flextwo = '0%';
            this.flexthree = '0%';
          } else if (this.exhibitnumcols === 2) {
            const mod = cnt % 2;
            if (mod === 0) {
              this.colone.push(ex);
            } else if (mod === 1) {
              this.coltwo.push(ex);
            }
            this.flexone = '50%';
            this.flextwo = '50%';
            this.flexthree = '0%';
          } else if (this.exhibitnumcols === 3) {
            const mod = cnt % 3;
            if (mod === 0) {
              this.colone.push(ex);
            } else if (mod === 1) {
              this.coltwo.push(ex);
            } else {
              this.colthree.push(ex);
            }
            this.flexone = '33%';
            this.flextwo = '33%';
            this.flexthree = '34%';

          }
          cnt = cnt + 1;
        }
      });
    }

  }

  getExList() {
    this.is.getList(`${this.caller}Pri/${this.admin.admin.org}/exhibits/${this.edition}`)
      .subscribe(exs => {
        this.exhibitsarray = exs;
        console.log('exhibitsarra', this.exhibitsarray)
        this.updateEx();
      });
  }

  showExhibits(i) {
    this.posex = i;
    this.edition = this.eds[i].ukey;
    this.getExList();
  }

  onTryAgain() {
    if (this.thumbfilename) {
      const old = this.thumbfilename;
      this.thumbfilename = '';
      this.cd.detectChanges();
      setTimeout(() => {
        this.thumbfilename = old;
        this.cd.detectChanges();
        console.log('trying', this.thumbfilename)
      }, 1000);
    }
  }

  onErrorTmp() {
    if (this.firsttmp) {
      console.log('first time');
      this.doshow = false;
      this.spinner = true;
      setTimeout(() => {
        this.doshow = true;
        this.firsttmp = false;
        this.spinner = false;
      }, 2000);
    }
  }


  // called when an image doesnt exist
  onError(a) {
    if (this.first) {
      a['dont'] = true;
      this.spinner = true;
      setTimeout(() => {
        a['dont'] = false;
        this.first = false;
        this.spinner = false;
      }, 4000);
    }
  }

  ngOnInit() {
    this.caller = this.admin.getLoc(this.url);

    if (this.caller === 'Orgs') {
      this.eds = this.og.curOrg.editions;
      return;
    }

    if (this.caller === 'Inst') {
      this.edition = this.inst.curInstOrg.ukey;
      this.posex = 0;
      this.getExList();
      return;
    }

  }

}

/*

1. Everybody has their own stream
-- I think this means have to have each user to have a stream. could be triggered by an on create -> so everything automatically done in the cloud

2. only the creator and the admin can delete an exhibit
3. exhibits can have likes
4. exhibits can have comments (this will go unimplemented for now)
5. todo: check if link is good. if not delete? the exhibit or somehow mark it as a deadlink
-- https://stackoverflow.com/questions/10926880/using-javascript-to-detect-whether-the-url-exists-before-display-in-iframe
-- console.firebase.google.com/u/0/project/library-titleix/database/library-titleix/data/
6. allow sorting by: date uploaded, creator, host (this is the edition that created the exhibit), likes, etc.
7. need ability to upload a file to storage and  make that an exhibit
-- todo: how deal with docs that dont have a natural thumbnail like a doc file
8. store uploaded docs in common storage bucket
8. when an exhibit is uploaded or deleted add or remove the pushnotification.

*/
