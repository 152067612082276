import {Component, Input, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {CappTeamMsg, InterfaceService} from '../../services/interface.service';

@Component({
  selector: 'app-footer-card',
  templateUrl: './footer-card.component.html',
  styleUrls: ['./footer-card.component.scss']
})
export class FooterCardComponent implements OnInit {
  @Input()
  n: CappTeamMsg;

  @Input()
  i: number;

  constructor(public admin: AdminService,
              public is: InterfaceService) { }

  ngOnInit(): void {
  }

  getApp(str) {
    let val = str;
    if (str && str.includes('/')) {
      val = str.split('/')[0];
    }
    return val;
  }

}
