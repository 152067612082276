import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {AdminService} from '../../../services/admin.service';
import {GSP, InterfaceService, Invitation} from '../../../services/interface.service';
import {ConfirmationComponent} from '../../../lib/confirmation/confirmation.component';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import {Calendar} from '@fullcalendar/core';
import {FirebaseService} from '../../../services/firebase.service'; // for dateClick


@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class ROCalendarComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    pth: string;

    tmpall: Invitation[];
    tmpevents: Invitation[];
    tmpholidays: Invitation[];
    tmpx: Invitation[];
    tmpperiods: Invitation[];
    curevent: Invitation;
    eventdetails: boolean;
    edit: boolean;
    tmppoints: number;
    pos: number;
    curtime: string;
    mouse = false;
    curlang: string;

    calendarPlugins = [timeGrigPlugin, dayGridPlugin, interactionPlugin];

    $sub1;
    $sub2;
    $sub3;

    timeval = '11:30AM'

    form = new FormGroup({
        title: new FormControl(''),
        start: new FormControl(''),
        time: new FormControl(''),
        end: new FormControl(''),
        gspoints: new FormControl(''),
        pubev: new FormControl(''),
    });

    get title() {
        return this.form.get('title');
    }

    get start() {
        return this.form.get('start');
    }

    get time() {
        return this.form.get('time');
    }

    get end() {
        return this.form.get('end');
    }

    get pubev() {
        return this.form.get('pubev');
    }

    get gspoints() {
        return this.form.get('gspoints');
    }

    constructor(private admin: AdminService,
                private cd: ChangeDetectorRef,
                private titleIXdb: FirebaseService,
                private dialog: MatDialog,
                private is: InterfaceService) {
        this.curlang = this.admin.language;
    }

    eventClick(ev) {
        if (this.tmpall && this.tmpall.length > 0) {
            this.pos = this.tmpall.findIndex(t => t.title === ev.event.title);
            if (this.pos > -1) {
                this.edit = true;
                this.eventdetails = true;
                this.curevent = this.tmpall[this.pos];
                const off = new Date().getTimezoneOffset() / 60;
                const hr = new Date(this.curevent.start).getHours();
                const min = new Date(this.curevent.start).getMinutes();
                this.curtime = `${hr}:${min}`;
                this.setValue();
            }
        }
    }

    onDelete() {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this event?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                this.is.deleteObj(`${this.pth}/${this.admin.admin.org}/events/${this.curevent.ukey}`, 'Delete', `Delete Event`);
                if (this.curevent.gspId) {
                    this.is.deleteObj(`${this.pth}/${this.admin.admin.org}/GSP/programs/${this.curevent.gspId}`, 'Delete', `Delete GSP Program`);
                }
                this.eventdetails = false;

            }
        });
    }

    onMouseEnter(ev) {
        this.curevent = this.tmpall.find(t => t.title === ev.event.title);
        this.mouse = true;
    }

    /*
        onMouseLeave(ev) {
            this.mouse = false;
        }
    */

    setValue() {
        this.mouse = false;

        this.tmppoints = this.curevent.gspoints;
        if (!this.curevent.gspoints) {
            this.curevent.gspoints = 0;
        }
        this.form.setValue({
            title: this.curevent.title,
            time: this.curtime,
            start: this.curevent.start,
            end: this.curevent.end,
            pubev: this.curevent.pubev,
            gspoints: this.curevent.gspoints,
        });
        this.eventdetails = true;
    }

    onDayClick(ev) {
        this.edit = false;
        this.curevent = new Invitation();
        this.curevent.ukey = '';
        this.curevent.title = '';
        this.curevent.start = new Date(ev.date).getTime();
        this.curtime = '12:00';
        this.curevent.end = this.curevent.start;
        this.curevent.pubev = false;
        this.curevent.gspoints = 0;
        this.curevent.gspId = '';
        this.setValue();
    }

    onCancel() {
        this.eventdetails = false;
    }

    onSave() {
        if (this.time.value) {
            if (!this.curevent.ukey) {
                this.curevent.ukey = this.titleIXdb.titleIXdb.createPushId();
            }
            const d = new Date(new Date(this.start.value).getFullYear(),
                new Date(this.start.value).getMonth(),
                new Date(this.start.value).getDate(),
                parseInt(this.time.value.split(':')[0], 10),
                parseInt(this.time.value.split(':')[1], 10),
                0, 0)
            this.curevent.start = d.getTime();
            this.curevent.title = this.title.value;

            const e = new Date(new Date(this.end.value).getFullYear(),
                new Date(this.end.value).getMonth(),
                new Date(this.end.value).getDate(),
                12, 0, 0, 0)
            this.curevent.end = e.getTime();

            this.curevent.pubev = this.pubev.value;
            this.curevent.gspoints = this.gspoints.value;
            if (this.curevent.gspoints !== this.tmppoints) {
                if (this.curevent.gspId) {
                    this.is.updateObj(`${this.pth}/${this.admin.admin.org}/GSP/programs/${this.curevent.gspId}`, {points: this.curevent.gspoints}, 'GSP', 'Editted GSP Program');
                } else {
                    const g = new GSP();
                    g.points = this.curevent.gspoints;
                    g.ukey = this.curevent.ukey;
                    // todo: should translate
                    g.name[this.curlang].description = this.curevent.title;
                    g.date = new Date(this.curevent.start).getTime();
                    g.creator = this.admin.admin.name;
                    g.category = 'Event';
                    this.curevent.gspId = g.ukey;
                    this.is.setObj(`${this.pth}/${this.admin.admin.org}/GSP/programs/${this.curevent.gspId}`, g, 'GSP', 'Create GSP Program');
                }
            }

            if (this.edit) {
                this.is.updateObj(`${this.pth}/${this.admin.admin.org}/events/${this.curevent.ukey}`, this.curevent, 'Calendar', `Editted: ${this.curevent.title}`);

            } else {
                this.is.setObj(`${this.pth}/${this.admin.admin.org}/events/${this.curevent.ukey}`, this.curevent, 'Calendar', `Added: ${this.curevent.title}`);
            }

            this.eventdetails = false;
            this.cd.detectChanges();
            console.log('tmpall', this.tmpall)
        }
    }


    clickButton(ev) {
        this.eventsFC();
    }

    updateEvent(ev) {
    }

    ngAfterViewInit() {
    }


    ngOnInit() {
        this.eventsFC();
    }

    eventsFC() {
        this.$sub1 = this.is.getList(`${this.pth}/${this.admin.admin.org}/holidays`).take(1).subscribe(holidays => {
            this.tmpholidays = holidays;

            this.$sub2 = this.is.getList(`${this.pth}/${this.admin.admin.org}/periods`).take(1).subscribe(periods => {
                this.tmpx = periods;

                this.tmpperiods = new Array();
                this.tmpx.forEach(p => {
                    const tmp = jQuery.extend({}, p);
                    tmp.start = p.end;
                    tmp.end = -1;
                    tmp.title = 'End: ' + tmp.title;

                    p.end = -1;
                    p.title = 'Start:' + p.title;

                    this.tmpperiods.push(p);
                    this.tmpperiods.push(tmp);
                });

                this.$sub3 = this.is.getList(`${this.pth}/${this.admin.admin.org}/events`).subscribe(events => {
                    this.tmpall = [];

                    this.tmpevents = events;

                    this.tmpholidays.forEach(p => {
                        this.tmpall.push(p);
                    });

                    this.tmpperiods.forEach(p => {
                        this.tmpall.push(p);
                    });

                    this.tmpevents.forEach(p => {
                        // todo: not sure i made the right decision in commenting out these lines
                        // let startTime = new Date(p.start);
                        // startTime = new Date(startTime.getTime());
                        // p.start = startTime; // moment(startTime).toISOString();

                        this.tmpall.push(p);
                    });


                    this.is.getQuery(`${this.pth}/${this.admin.admin.org}/partners/request`, 'subcalendar', true).take(1).subscribe(ps => {
                        console.log('ps', ps);
                        if (ps && ps.length > 0) {
                            let cnt = 0
                            ps.forEach(p => {

                                this.is.getList(`Calendars/${p.theirpath.split('/')[1]}`).take(1).subscribe(es => {
                                    es.forEach(e => {
                                        let startTime = new Date(e.start);
                                        startTime = new Date(startTime.getTime());
                                        e.start = moment(startTime).toISOString();
                                        e.backgroundColor = '#FF00FF'
                                        this.tmpall.push(e);
                                    });
                                    cnt = cnt + 1;
                                    if (cnt === ps.length) {
                                        this.tmpall = _.orderBy(this.tmpall, ['start'], ['asc']);
                                    }
                                });
                            });
                        } else {
                            this.tmpall = _.orderBy(this.tmpall, ['start'], ['asc']);
                        }
                    })





                });

            });

        });
    }

    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }

        if (this.$sub2) {
            this.$sub2.unsubscribe();
        }

        if (this.$sub3) {
            this.$sub3.unsubscribe();
        }
    }
}
