import {Component, Input, OnInit} from '@angular/core';
import {CappTeamMsg, NewsArticle, NewsTemplate} from '../services/interface.service';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss']
})
export class ArticleCardComponent implements OnInit {
  @Input()
  curarticle: NewsArticle;

  @Input()
  height: number;

  @Input()
  curlang = this.admin.curlang;

  @Input()
  width: number;

  constructor(public admin: AdminService) { }

  ngOnInit() {
  }

  onPubURL() {
    if (this.curarticle.template.publisherurl) {
      window.open(this.curarticle.template.publisherurl, '_blank');
    }
  }

  makeAuthors(): string {
    const names = [];
    this.curarticle.authors.forEach(a => {
      names.push(a.name);
    })
    return names.join(',');
  }

}
