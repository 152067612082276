import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Invitation} from '../../../../services/interface.service';

@Component({
  selector: 'app-evt-capp',
  templateUrl: './evt-capp.component.html',
  styleUrls: ['./evt-capp.component.scss']
})
export class EvtCappComponent implements OnInit, AfterViewInit {
  @Input()
  curInv: Invitation;

  flipit = false;
  ht: number;
  iht: number;
  curlang = 'en';
  primarycolor: string;
  secondarycolor: string;

  note: string;
  days: number;

  @ViewChild('content', {static: false}) elementView: ElementRef;


  constructor(private cd: ChangeDetectorRef,) {
    this.primarycolor = '#4054af';
    this.secondarycolor = '#FFFFFF';
  }

  ngOnInit() {
    this.iht = this.curInv.height * ((450 * 0.94 - 40) / this.curInv.width);

  }

  ngAfterViewInit(): void {
    if (this.elementView.nativeElement) {
      this.ht = this.elementView.nativeElement.offsetHeight;
      this.cd.detectChanges();

    }
  }
}
