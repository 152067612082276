import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {TmpInherit} from '../../services/interface.service';

@Component({
    selector: 'app-inherit',
    templateUrl: './inherit.component.html',
    styleUrls: ['./inherit.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InheritComponent implements OnInit {
    attribs: any;
    poffices: TmpInherit;
    pstaff: TmpInherit;
    ppolicies: TmpInherit;

    constructor(public dialogRef: MatDialogRef<InheritComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.attribs = data.attribs;
        this.poffices = data.offices;
        this.pstaff = data.staff;
        this.ppolicies = data.policies;
    }

    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close();
    }

    onSave() {
        this.dialogRef.close({attribs: this.attribs, offices: this.poffices, staff: this.pstaff, policies: this.ppolicies});
    }

}
