import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CappTeamMsg, InterfaceService} from '../../services/interface.service';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {AdminService} from '../../services/admin.service';

// Inc-R = this notice is for when a user has asked an admin to join an incident
// Inc-A = this notice is for when a user has been approved by an admin to join an incident

@Component({
  selector: 'app-inc-r',
  templateUrl: './inc-r.component.html',
  styleUrls: ['./inc-r.component.css']
})
export class IncRComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;


  @Output()
  delete = new EventEmitter();

  colone = '25%';
  coltwo = '75%';

  constructor(public is: InterfaceService,
              public admin: AdminService) {
    super(admin, is);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const x = Math.round(9000 / this.admin.appwidth);
    this.colone = `${x}%`;
    this.coltwo = `${100 - x}%`;

  }

  onDelete() {
    this.delete.emit();
  }
}
