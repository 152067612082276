// CAForms are comprised of elements - this is a global registry
import {Address, LangString, LangStringBlock} from './interface.service';

// array is a special type that doesnt have periodicity.
// this is a way to store an array of anything like an array of photos
export const CAPeriodType = [
  'period',
  'point',
  'array',
];

// https://www.google.com/travel/things-to-do?dest_src=ut&dest_mid=%2Fm%2F02rgb1&dest_state_type=main&tcfs=Ei0KCS9tLzAycmdiMRIgSGF3YWnKu2kgVm9sY2Fub2VzIE5hdGlvbmFsIFBhcms#ttdm=19.336829_-155.195695_11&ttdmf=%25252Fg%25252F122tpr2j
// https://www.google.com/travel/things-to-do?dest_src=ut&dest_mid=%2Fm%2F02rgb1
// https://www.google.com/travel/things-to-do?dest_src=ut&dest_mid=%2Fm%2F03l2n&dest_state_type=main&tcfs=EhMKCC9tLzAzbDJuEgdIb3VzdG9u#ttdm=19.336829_-155.195695_11
// https://www.google.com/travel/things-to-do?dest_src=ut&dest_mid=%2Fm%2F03l2n&dest_state_type=main&tcfs=EhMKCC9tLzAzbDJuEgdIb3VzdG9u#ttdm=29.598103_-95.281227_10
// todo: move these to firebase so that they can be changed easily for everybody
export const CAPrimatives = [
  'asset', // asset is a capptivation construct for things like photos
  'asset_array', // array of assets -> this might work could expand to string_array, number_array etct
  'boolean',
  'date',
  'daterange',
  'emailaddress',
  'file',
  'lnglat',
  'link',
  'moly',
  'number',
  'pick',
  'profilepic',
  'pickedit',
  'phonenumber',
  'string',
  'ukey',
  'username',
  'us zip' // us 5-digit zip code
];

// todo: move these to firebase so that they can be changed easily for everybody
export const CAUnitTypes = [
  'na',
  'currency',
  'weight',
  'temperature',
  'speed',
];

// todo: move these to firebase so that they can be changed easily for everybody
export const CAUnitDomain = [
  'generic',
  'health',
  'finance',
  'science',
  'sports'
]

export class CACreator {
  cdate: number; // this is the date the val was edited or created
  cukey: string; // this is the indivukey of the data creator
  cnote: LangStringBlock;  // explanation for why edit was made

  constructor(ukey) {
    this.cdate = new Date().getTime();  // todo: convert to international time
    this.cukey = ukey;
    this.cnote = new LangStringBlock();
  }
}

export class CAUnit {
  ukey: string;
  name: LangStringBlock;
  creators: CACreator[];
  abbr: string;          // abbreviation or symbol (eg $)
  desc: LangStringBlock; // what’s the unit used for
  type: string;          // CAUnitType
  domain: string;        // CAUnitDomain e.g. generic, health, finance
  range: any;            // todo: some description of the range of valid values; null if not applicable
  intraconversion: any;  // todo: some way to represent how to convert the unit within itself, for example, miles to feet; null if not applicable
  interconversion: any;  // todo: way to convert from one unit to another
  iconpath: string;      // path to icon repository where there are icon(s) that represent the unit
  version: string;

  constructor() {
    this.name = new LangStringBlock();
    this.desc = new LangStringBlock();
    this.creators = [];
  }
}

export class CAMetaMoly {
  ukey: string;
  name: LangStringBlock;
  desc: LangStringBlock; // documentation
  version: string; // the version number of the meta atom
  creators: CACreator[];
  period: string; // point, period, array
  span: string; // RRule

  jsonkey: string;
  primitive: string; // string, number, pick, moly
  scope: string; // local, global, org
  // global means the data is stored in Individual//data
  // local means it's stored in Individual//formuses
  // org means it's stored in Individual//formorgs/${org} - ie data to a specific organization NOT Implemented

  // todo: this should be LangStringBlock
  pickvals: string[]; // if type === pick
  pos: number; // this is used to define the position of a moly in a moly of type moly
  hidden: string; // string of comma jsonkeys tht identify molys that are hidden from the user when the primitive === moly
  unit: string; // $, lbs, miles, atom, n.a.

  molys: CAMetaMoly[];
  molyuses: LangStringBlock[]; // everytime a moly is used by another moly or delete this field needs to be updated
  formuses: LangStringBlock[]; // everytime a moly is used by another moly or delete this field needs to be updated
  notes: CAComment[];

  constructor() {
    this.molys = [];
    this.molyuses = [];
    this.formuses = [];
    this.notes = [];
    this.creators = [];
    this.version = '0.0.1';
    this.name = new LangStringBlock();
    this.desc = new LangStringBlock();
  }
}

export class CAMolyInForm {
  moly: CAMetaMoly;
  req: boolean; // === true if moly is required
  link: string;
  formonly: boolean; // === true if the moly is unique to th form (e.g preferred dryer color)
  // if true then store the moly not in Individual/${indivukey}/data but in Indvidual/${indivukey}/form/{formukey}/{molyukey}
  pos: number;
}

export class CAMetaForm {
  ukey: string; // id of the form
  name: LangStringBlock; // name of the form
  desc: LangStringBlock; // description of the form
  terms: string; // pointer to terms and conditions
  version: string; // the version number of the meta atom
  creators: CACreator[];

  expiration: number; // date after which the form cant be used; -1 = doesn’t exp
  molys: CAMolyInForm[]; // the array of molecules that comprise the form
  icon: string;
  perms: CAPermForm; // this is the description of the permissions re form CRUD

  constructor() {
    this.name = new LangStringBlock();
    this.desc = new LangStringBlock();
    this.expiration = -1;
    this.molys = [];
  }
}

export class CAColRow {
  cols: number;
  rows: number;
  molyukey: string;

  constructor() {
    this.cols = 1;
    this.rows = 1;
  }
}

export class CAFormUse {
  ukey: string; // identifier of this form use
  formukey: string; // id of the form
  version: string; // the version number of the meta atom
  name: LangStringBlock; // name of the form
  desc: LangStringBlock; // description of what the form is being used for
  creators: CACreator[];
  expiration: number; // date at after which the form cant be used; -1 = doesn’t exp
  path: string; // this is the path where the data is stored;

  // todo: all these are form formatting options
  toc: boolean; // true === include table of contents
  toctemplate: string; // identifies which formatting template to use for the toc
  tocafter: boolean; // true === dont show toc until after all required fields have been input

  primarycolor: string;
  secondarycolor: string;
  logo: string;
  perms: CAPermForm; // this is the description of the permissions re form CRUD

  constructor() {
    this.name = new LangStringBlock();
    this.desc = new LangStringBlock();
    this.expiration = -1;
  }
}

// todo: should i add a string[] for copypermission
// todo: should i add a pntr to an encryption key? -> not sure how this would work.
export class CAValidate {
  vdate: number; // this is the date the val was validated
  vukey: string; // this is the ukey of the data validator
  vexp: number; // the date when the validation expires, -1 means it doesn't expire
}

export class CAMolyValue {
  data: any; // this data can be an object. eg: {phonenumber: , phonedescription: }
  date: number;
  scope: string; // 'local' then store the data in Individual//formuse,
  // 'global', store in Individual//data
  // only can be set If the metamoly scope is global and not specific to the form (ie local).
  validate: CAValidate;
  annotations: CAAnnotation[];

  constructor() {
    this.scope = 'global';
  }
}

// This is what is stored at Individual/${indivukey}/data/
export class CAMolyData {
  ukey: string; // ukey
  name: LangStringBlock; // this is where store an alias for easy reading
  molyukey: string; // this points to the controlling CAMetaMoly
  value: CAMolyValue[]; // this structure has to be worked on to show how can have multiple languages for example

  constructor() {
    this.name = new LangStringBlock();
    this.value = []; // this structure has to be worked on to show how can have multiple languages for example
  }
}


/*
objtype = person, car, lifeinsurance, healthinsurance, boat, house, etc - any and all things tangible and otherwise that have a relationship to a person
maybe need an objrelationship field too: child, friend, parent, owned, rent, lease,
 */
export class DataObj {
  dataukey: string;
  objtype: string; // this describes what the obj is see objtypes above
  l: any; // this is the pointer to the object
}

// todo: should i add a string[] for copypermission
// todo: should i add a pntr to an encryption key? -> not sure how this would work.
export class CAMolyVal {
  u: string; // ukey
  label?: string; // this is where store an alias for easy reading
  idukey: string; // this points to the controlling CAMetaData
  value: any[]; // this structure has to be worked on to show how can have multiple languages for example

  // todo: should put this in an Validate structure
  // question: should the validate status history be saved? I think yes. it would help in dispute resolution
  vdate: number; // this is the date the val was validated
  vukey: string; // this is the ukey of the data validator
  vexp: number; // the date when the validation expires, -1 means it doesn't expire

  // all these ukey arrays will work perfectly with Capp Teams and managing permissions
  cukeys; // array of forms that can create the atom val
  rukeys; // array of forms that can read the atom val
  uukeys; // array of forms that can update the atom val
  dukeys; // array of forms that can delete the atom val
  lukeys; // allows person to save value locally
  kukeys; // allows person to make a copy of value
  cdate: number; // this is the date the val was edited or created
  cukey: string; // this is the ukey of the data creator


  constructor() {
    this.u = ''; // ukey
    this.label = ''; // this is where store an alias for easy reading
    this.idukey = ''; // this points to the controlling CAMetaData
    this.value = []; // this structure has to be worked on to show how can have multiple languages for example
    this.vdate = -1; // this is the date the val was validated
    this.vukey = ''; // this is the ukey of the data validator
    // all these ukey arrays will work perfectly with Capp Teams and managing permissions
    this.cukeys = []; // array of forms that can create the atom val
    this.rukeys = []; // array of forms that can read the atom val
    this.uukeys = []; // array of forms that can update the atom val
    this.dukeys = []; // array of forms that can delete the atom val
    this.lukeys = []; // allows person to save value locally
    this.kukeys = []; // allows person to make a copy of value
    this.cdate = -1; // this is the date the val was edited or created
  }
}


export class CAMolyType {
  name: string;
  note: string;
}

export class CAPermForm {
  meta: any;
  formperms: any[]; // TODO: define how this structure works
}

export class CAFormMoly {
  molyukey: string;
  molyname: LangStringBlock;
  perms: string; // the values controlled by the user to determine access
  crudxs: string; // of type CRUDXS, if CR means the form owner requires the ability to create and read; most often it will be just R
  permrequired: boolean; // means the user must give the crudxs permission asked for or form is dead
  responserequired: boolean; // means the form must have a value
  note: LangStringBlock; // information note to the user

  constructor() {
    this.molyname = new LangStringBlock();
    this.note = new LangStringBlock();
  }
}

// todo: questions about edit history
// todo: question about language support

// molyukey	molylabel	molydesc	molytype	molycat	cukey	formukeys	notes



export class CAMetaData {
  idukey: string; // this is the id
  label?: string;
  period?: string; // length of time the value is good for, for example annual, monthly, etc.
  unit?: string; // $, lbs, miles, atom etc. - if unit === lang then CAMolyVal should hold translations
  status?: string; // active, inactive, todo: are other statuses possible
  pntr?; // various purposes for example used in form members
  desc?: string; // documentation
  type?: string; // storage type: string, number,                  form
  cukey?: string; // ukey of the creator of the data
  cdate?: number; // date data was defined
  ukey?: string;
  formonly: boolean; // if true, then this element must be part of a form.
  span: CASpan;
  // todo: need to add validators like range, choices, etc
}

// todo: the equivalent of lang is currency. one value can be expressed in many currencies.
export class CASpan {
  type: string;
  month?: number;
  year?: number;
  minute?: number;  // this level of detail could be for stock prices or medical information
  second?: number;
  day?: number;
}



export class CARelationship {
  link: string; // paths to other CA resources
  desc: string; // description of the relationship
}

export class CMTag {
  t: string; // title of tagging memory
  p: string;  //  ukey of tagging memory - for example /${memukey}/cs/${experienceukey}/m/${photoukey}
}

export class CAMolyDoc extends CAMolyVal {
  l: string; // url address of the photo
  tg?: CMTag[]; // what experiences/memories reference this photo - will be very helpful later on for sorting
  cn: string;   // name of the creator

  title?: LangString;   // title
  desc?: LangString; // description of the doc - can have multiple translations {en: 'Engligh', fr: 'French', etc}
  /////////
  th?: string; // thumb pointer to the thumbnail image, may not have one.
  dateup?: number; // Date Upload
  d?: number; // Date the doc was created
  format?: string; // E.g. png, pdf, doc, etc
  creator?: string; // ukey of the person who created the doc
  copyright?: string; // copyrighrt notice
  loc?: Address; // Location Object // particularly useful for photos
  srch?: string; // comma deliminated string of tags used for searching
  res?: CARelationship[];  // array of relationships. this is used to tie stuff together
  coms?: CAComment[];  // these are comments associated with the document


  constructor() {
    super()
    this.l = ''
    this.tg = []; // what experiences/memories reference this photo - will be very helpful later on for sorting
    this.cn = '';   // name of the creator

    this.title = new LangString();   // title
    this.desc = new LangString(); // description of the doc - can have multiple translations {en: 'Engligh', fr: 'French', etc}
    /////////
    this.th = ''; // thumb pointer to the thumbnail image, may not have one.
    this.dateup = new Date().getTime(); // Date Upload
    this.d = new Date().getTime(); // Date the doc was created
    this.format = ''; // E.g. png, pdf, doc, etc
    this.creator = ''; // ukey of the person who created the doc
    this.copyright = ''; // copyrighrt notice
    this.loc = new Address(); // Location Object // particularly useful for photos
    this.srch = ''; // comma deliminated string of tags used for searching
    this.res = [];  // array of relationships. this is used to tie stuff together
    this.coms = [];
  }

}

// todo: i'm thinking this might be useful for data annotations
// todo: they sort of float independently and can attach to data somehow
// todo: that is, they aren't attached permanently the way CAComments are
export class CAAnnotation  extends CAMolyVal {
  // todo: a lot of work has to be put into how the links work
  links: string[];  // an array of links to data that might be interested in it
  title: object;
  desc: object;
  date: number;
  creator: string;
  ukey: string;
}

export class CAComment  extends CAMolyVal {
  desc: string;
  date: number;
  creator: string;
  moreukey?: string; // pointer to more info
}
