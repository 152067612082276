import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {CaServices} from '../../ca-services/ca-services';
import {CAMolyVal} from '../../../services/ca';
import {CahttpService} from '../../../services/cahttp.service';
import {InterfaceService} from '../../../services/interface.service';
import * as algoliasearch from 'algoliasearch';

const searchClient = algoliasearch(
  environment.algolia.appId,
  environment.algolia.apiKey
);

export class ComJob {
    jobid: string;
    ukey: string;
    organization: string;
    startdate: number;
    enddate: number;
    reason?: string;
    present: boolean;
    title: string;
    description: string;
    phones: PhoneObj[];
    phoneprivate: boolean;
    photo?: string;
    email: string;
    emailprivate: boolean;
    volunteer: boolean;
    address: Address;
    acceptemail?: boolean;
    locations?: string;
    programs?: string;
    cappguide?: boolean;
}

export class PhoneObj {
    phdescription: string;
    digits: string;
    private: boolean;
    notify: boolean;
}

export class Address {
    street1: string;  // street1
    street2: string;  // street2
    city: string;  // city
    state: string;  // state
    country?: string;  // country
    zip: string;  // zipt
    county?: string; // points to the CA form
    long?: number;
    lat?: number;
}

@Component({
    selector: 'app-employment',
    templateUrl: './employment.component.html',
    styleUrls: ['./employment.component.css']
})
export class EmploymentComponent implements OnInit {
    @Input()
    iduser: string;

    @Input()
    validator: string;

    @Input()
    jdate: number;

    @Input()
    isform = true;

    @Input()
    date: number;

    @Input()
    parent: CAMolyVal;

    @Input()
    pos = 0;


    @Output()
    valid = new EventEmitter<boolean>();

    curJob: ComJob;
    newJob: ComJob;
    spinner = false;
    firsttime: boolean;
    selected = -1;
    show = false;
    validate = false;

    searchConfig = {
      indexName: 'Community',
      searchClient,
      routing: true
    };


    form = new FormGroup({
        organization: new FormControl(''),

        startdate: new FormControl(''),
        enddate: new FormControl(''),
        present: new FormControl(''),
        reason: new FormControl(''),


        email: new FormControl(''),
        jobid: new FormControl(''),
        title: new FormControl(''),
    });


    get organization() {
        return this.form.get('organization');
    }

    get title() {
        return this.form.get('title');
    }

    get email() {
        return this.form.get('email');
    }

    get jobid() {
        return this.form.get('jobid');
    }

    get startdate() {
        return this.form.get('startdate');
    }

    get enddate() {
        return this.form.get('enddate');
    }

    get present() {
        return this.form.get('present');
    }

    get reason() {
        return this.form.get('reason');
    }

    constructor(private gs: CaServices,
                private dialog: MatDialog,
                private cahttp: CahttpService,
                private fs: InterfaceService) {
    }

    setValue(job: ComJob) {
        this.form.setValue({
            organization: job.organization,
            startdate: new Date(job.startdate),
            enddate: new Date(job.enddate),
            present: job.present,
            reason: job.reason,

            email: job.email,
            title: job.title,
            jobid: job.jobid,
        });
    }

    save(ukey) {
        let validator = '';
        if (this.validate) {
            const x = this.gs.validators.findIndex(v => v.name === this.newJob.organization);
            if (x > -1) {
                validator = this.gs.validators[x].idukey;
            }
        }


        this.cahttp.postCASaveEmployment('CA/users',
            this.iduser,
            this.curJob,
            this.newJob,
            ukey,
            this.firsttime,
            this.gs.idemployment,
            this.parent,
            this.validate,
            validator)
            .then(res => {
                console.log(res);
                this.spinner = false;
            });
    }

    public onSave() {
        this.spinner = true;
        this.newJob = new ComJob();
        this.newJob.jobid = this.jobid.value;
        this.newJob.ukey = '';
        this.newJob.organization = this.organization.value;
        this.newJob.startdate = new Date(this.startdate.value).getTime();
        if (this.enddate.value) {
            this.newJob.enddate = new Date(this.enddate.value).getTime();
        }
        this.newJob.reason = this.reason.value;
        this.newJob.present = this.present.value;
        this.newJob.title = this.title.value;
        this.newJob.description = '';
        this.newJob.phones = [];
        this.newJob.phoneprivate = false;
        this.newJob.photo = '';
        this.newJob.email = this.email.value;
        this.newJob.emailprivate = false;
        this.newJob.volunteer = false;
        this.newJob.address = new Address();
        this.newJob.acceptemail = true;
        this.newJob.locations = '';
        this.newJob.programs = '';
        this.newJob.cappguide = false;

        let ukey = this.fs.getId();
        if (!this.firsttime) {
            ukey = this.curJob.ukey;
        }

        this.save(ukey);
    }

    ngOnInit() {
        this.spinner = true;
        console.log('fetchpos', this.pos);
        this.cahttp.postCAFetchEmployment('CA/users', this.iduser, this.gs.idemployment, this.pos).then(val => {
            if (val['curjob']) {
                this.firsttime = false;
                this.curJob = val['curjob'];
            } else {
                this.firsttime = true;
                this.curJob = new ComJob();
                this.curJob.jobid = '';
                this.curJob.ukey = '';
                this.curJob.organization = '';
                this.curJob.startdate = new Date().getTime();
                this.curJob.enddate = null;
                this.curJob.reason = '';
                this.curJob.present = true;
                this.curJob.title = '';
                this.curJob.description = '';
                this.curJob.phones = [];
                this.curJob.phoneprivate = false;
                this.curJob.photo = '';
                this.curJob.email = '';
                this.curJob.emailprivate = false;
                this.curJob.volunteer = false;
                this.curJob.address = new Address();
                this.curJob.acceptemail = true;
                this.curJob.locations = '';
                this.curJob.programs = '';
                this.curJob.cappguide = false;
            }
            this.setValue(this.curJob);
            this.spinner = false;
        });
    }

    onSelect(hit, i) {
        this.selected = i;
        this.organization.setValue(hit.name);
        this.show = false;
    }

    onInValid() {
        this.valid.emit(false);
    }

    onValid() {
        console.log('valid', this.validator);
        this.cahttp.postCAValidate(this.gs.path, this.iduser, this.curJob.ukey, this.jdate, this.validator)
          .then(val => {
        });
        this.valid.emit(true);
    }

}
