import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {CappTeamMsg} from '../../services/interface.service';
import {AgmMap} from '@agm/core';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-roe-faq',
  templateUrl: './roe-faq.component.html',
  styleUrls: ['./roe-faq.component.scss']
})
export class RoeFaqComponent implements OnInit, AfterViewInit {
  @Input()
  curmsg: CappTeamMsg;

  primarycolor: string;
  secondarycolor: string;
  flipit = false;
  ht: number;
  curlang = 'en';

  @ViewChild('content', {static: false}) elementView: ElementRef;

  constructor(public admin: AdminService,
              private bottomSheet: MatBottomSheet,
              private cd: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    if (this.elementView.nativeElement) {
      this.ht = this.elementView.nativeElement.offsetHeight;
      this.cd.detectChanges();
    }
  }

  ngOnInit(): void {
    this.curlang = this.admin.language;

    this.primarycolor = '#4054af';
    this.secondarycolor = '#FFFFFF';
  }

}
