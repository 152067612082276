import {Component, Input, OnInit} from '@angular/core';
import {Invitation} from '../../../../services/interface.service';
import {AdminService} from '../../../../services/admin.service';

@Component({
  selector: 'app-evt-simple',
  templateUrl: './evt-simple.component.html',
  styleUrls: ['./evt-simple.component.scss']
})
export class EvtSimpleComponent implements OnInit {
    @Input()
    curInv: Invitation;

    @Input()
    curlang: string;

    @Input()
    start;

    @Input()
    end;

    @Input()
    time;

    public textBox: any;
    public invitationText: any;
    public invitationStyle: any;
    public invitationTitle: any;

    constructor(public admin: AdminService) {
    }

    ngOnInit() {
        console.log('curinv', this.curInv)
        this.textBox = {
            // background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) )',
            'text-align': 'center',
            'border-radius': '10px',
            'width': '100%'
        };
        this.invitationText = {
            width: '100%',
            'word-wrap': 'break-word',
            overflow: 'hidden',
            'text-transform': 'uppercase',
            'text-align': 'center',
            'font-size': '1.75vw',
        };
        this.invitationTitle = {
            padding: '10px',
            'letter-spacing': '2px',
            'text-transform': 'uppercase',
            'text-align': 'center',
            'font-size': '2.5vw',
        };
        this.invitationStyle = {
            height: '300px',
            width: '100%',
            // background: 'url(https://cdn.pixabay.com/photo/2018/05/30/15/31/rustic-3441673_960_720.jpg)',
            // 'background': 'linear-gradient(-45deg, #30CFD0 50%, #330867 50%)',
            'background-size': 'cover',
            '-webkit-clip-path': 'polygon(0% 0%, 100% 0, 100% 75%, 51% 100%, 0 75%)',
            'shape-outside': 'polygon(0% 0%, 100% 0, 100% 75%, 51% 100%, 0 75%)',
            'border-radius': '15px',
            'box-shadow': '10px 10px 15px -5px rgba(0,0,0,0.22)',
        };


        console.log('style', this.invitationStyle)
    }
}