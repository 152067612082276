import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase';

@Component({
    selector: 'cap-email-verfify',
    templateUrl: './email-confirmation.component.html',
    styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {

    @Input()
    email: string;

    @Output()
    success = new EventEmitter();

    constructor(public auth: AngularFireAuth) {
    }

    ngOnInit() {
    }

    onSuccess(event) {
        const user = this.auth.auth.currentUser;
        user.reload().then(val => {
            if (user.emailVerified) {
                this.success.emit();
            }
        });
    }

}
