import {Component, OnInit} from '@angular/core';
import {CAFormMoly, CAMetaForm, CAPermForm} from '../services/ca';
import {InterfaceService} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {RoehttpService} from '../services/roehttp.service';

@Component({
  selector: 'app-inv-perms',
  templateUrl: './inv-perms.component.html',
  styleUrls: ['./inv-perms.component.scss']
})
export class InvPermsComponent implements OnInit {
  details = false;
  curpermform: CAPermForm;
  permforms: CAPermForm[];
  indivukey: string;

  // todo: generalize
  curlang = 'en';

  constructor(public admin: AdminService,
              private http: RoehttpService,
              private is: InterfaceService) {
  }

  ngOnInit() {
    console.log('pop')
    this.is.getObject(`ROEDevices/${this.admin.admin.deviceid}/indivukey`)
      .take(1)
      .subscribe(iu => {
        this.indivukey = iu;
        this.is.getObject(`Individual/${this.indivukey}/forms`)
          .take(1)
          .subscribe(forms => {
            this.permforms = Object.values(forms);
          });
      });
  }

  onAdd() {

  }

  onEdit(i) {
    this.curpermform = this.permforms[i];
    console.log('curper', this.curpermform)
    this.details = true;
  }

  onDelete(i) {

  }

  onChange(p: CAFormMoly, str, event) {
    if (event.checked) {
      if (!p.perms.includes(str)) {
        p.perms = p.perms + str;
      }
    } else {
      if (p.perms.includes(str)) {
        p.perms = p.perms.replace(str, '');
      }
    }
    console.log('p.perms', p.perms)
  }


}
