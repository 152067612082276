import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from '../services/admin.service';
import {InterfaceService, LangStringBlock} from '../services/interface.service';
import {DomSanitizer} from '@angular/platform-browser';
import {AngularFireDatabase} from '@angular/fire/database';
import {RoehttpService} from '../services/roehttp.service';


export class InterventionRec {
    name: string;
    ukey: string;
    phone?: string;
    email?: string;
    guides: GuideSent[];
}

export class GuideSent {
    date: number;
    guidename: LangStringBlock;
    guideukey: string;
    instruction?: string;
    org: string;
    program: string;
}


@Component({
    selector: 'app-capp-guide',
    templateUrl: './capp-guide.component.html',
    styleUrls: ['./capp-guide.component.scss']
})
export class CappGuideComponent implements OnInit, OnDestroy {
    @Input()
    guides;

    safestr;
    startpage = 0;
    read = false;
    height = 670;
    width = 375;
    curlang = 'en';
    guideukey = '';
    count = 0;

    err: string;
    name: string;
    phone: string;
    email: string;
    staffukey: string;
    instruction: string;
    recs: InterventionRec[];
    rec: InterventionRec;

    phoneNumber;
    $sub1;

    // todo: this is a cludge
    deviceid = 1002646;

    constructor(private is: InterfaceService,
                private roehttp: RoehttpService,
                private ds: DomSanitizer,
                private db: AngularFireDatabase,
                public admin: AdminService) {
        this.curlang = this.admin.language;
    }

    ngOnInit() {
        this.$sub1 = this.is.getList(`ROEDevices/${this.deviceid}/recs`).subscribe(recs => {
            this.recs = recs;
        });

        this.is.getObject(`ROEDevices/${this.deviceid}/roeapproved/${this.is.curComOrg.ukey}`)
            .take(1)
            .subscribe(ukey => {
                this.staffukey = ukey;
            });
    }

    makesafestr() {
        let id = -1;
        if (this.deviceid && this.deviceid > 0) {
            id = this.deviceid;
            const str = `<lift-up curlang="${this.curlang}"
                                  org="${this.is.curComOrg.ukey}"
                                  caller="roe"
                                  startpage="${this.startpage}"
                                  height="${this.height}"
                                  width="${this.width}"
                                  read="${this.read}"
                                  deviceid="${id}"
                                  guideukey="Guides/${this.guideukey}"></lift-up>`;
            this.safestr = this.ds.bypassSecurityTrustHtml(str);
        } else {
            console.log('no id');
            // todo: Input Your Device Id
        }
    }

    onClear() {
        this.name = '';
        this.email = '';
        this.phone = '';
        this.rec = null;
        this.instruction = '';
    }

    onSelChange(event) {
        this.name = this.rec.name;
        this.email = this.rec.email;
        this.phone = this.rec.phone;
    }


    onSubmit() {
        this.err = ''
        if (!this.name) {
            this.err = 'Please enter a name.'
            return;
        }
        if (this.email && this.email.length > 0) {
            this.email = this.email.toLocaleLowerCase();
        }
        const ve = this.admin.validateEmail(this.email);

        if (!ve) {
            if (!this.phone) {
                this.err = 'Please enter a valid email address or cell phone number.';
            } else {
                this.phoneNumber = this.admin.parsePhoneNumberFromString(this.phone, 'US');
                if (!this.phoneNumber || !this.phoneNumber.isValid()) {
                    this.err = 'Please enter a valid email address or cell phone number.';
                    return;
                }
                this.phone = this.phoneNumber.number;
            }
        }


        if (this.rec) {
            const d = new Date().getTime();
            if (!this.email) {
                this.rec.email = '';
            } else {
                this.rec.email = this.email;
            }
            if (!this.phone) {
                this.rec.phone = '';
            } else {
                this.rec.phone = this.phone;
            }

            this.guides.forEach(guide => {
                if (guide['check']) {
                    const g = new GuideSent();
                    g.date = d;
                    if (!this.instruction) {
                        this.instruction = '';
                    }
                    g.instruction = this.instruction;
                    g.org = guide.pubby;
                    g.guidename = guide.name;
                    g.program = guide.program;
                    g.guideukey = guide.ukey;
                    this.rec.guides.push(g);
                }
            });
        } else {
            this.rec = new InterventionRec();
            this.rec.name = this.name;
            if (!this.email) {
                this.rec.email = '';
            } else {
                this.rec.email = this.email;
            }
            if (!this.phone) {
                this.rec.phone = '';
            } else {
                this.rec.phone = this.phone;
            }
            if (!this.instruction) {
                this.instruction = '';
            }
            this.rec.ukey = this.db.createPushId()
            this.rec.guides = [];
            const d = new Date().getTime();
            this.guides.forEach(guide => {
                if (guide['check']) {
                    const g = new GuideSent();
                    g.date = d;
                    g.org = guide.pubby;
                    g.instruction = this.instruction;
                    g.guidename = guide.name;
                    g.guideukey = guide.ukey;
                    g.program = guide.program;
                    this.rec.guides.push(g);
                }
            });
        }
        this.is.setObjNoLog(`ROEDevices/${this.deviceid}/recs/${this.rec.ukey}`, this.rec);
        this.roehttp.postROInterventionsRec(this.deviceid, this.rec, this.staffukey, this.curlang)

        // 1. Sender Profile
        // 2. search for email and phone number to link to a ROEDevice and add it to horses carousel
        /*

        TMpDevices
            ukey - rec

        querry TmpROEDevices for email
        qurry  TmpROEDevices for phone

         */


        // 3. If not then put it in a temp place to be picked up later
        // 4. Save activity to org
        // 5. let the publisher know that someone has sent a guide

        this.onClear();
        this.count = 0;
        this.guides.forEach(g => {
            g['check'] = false;
        })
    }

    onView(event, i) {
        this.guideukey = this.guides[i].ukey;
        this.makesafestr()
        event.stopPropagation();
    }

    onChange(event, i) {
        if (event.checked) {
            this.count = ++this.count;
        } else {
            this.count = --this.count;
        }
        this.guides[i]['check'] = event.checked;
    }

    onClick(event) {
        event.stopPropagation();
    }

    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
    }

}
