import {Component, Input, OnInit} from '@angular/core';
import {Invitation} from '../../../../services/interface.service';
import {AdminService} from '../../../../services/admin.service';

@Component({
    selector: 'app-evt-none',
    templateUrl: './evt-none.component.html',
    styleUrls: ['./evt-none.component.scss']
})
export class EvtNoneComponent implements OnInit {
    @Input()
    curInv: Invitation;

    @Input()
    curlang: string;

    @Input()
    start;

    @Input()
    end;

    @Input()
    time;


    constructor(public admin: AdminService) {
    }

    ngOnInit() {
        console.log('end', this.end)
    }

}
