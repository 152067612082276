import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
/*
import * as firebase from 'firebase';
*/
import * as firebase from 'firebase/app';
import 'firebase/storage';

import {AdminService} from '../../../services/admin.service';


@Component({
    selector: 'app-icon-select',
    templateUrl: './icon-select.component.html',
    styleUrls: ['./icon-select.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class IconSelectComponent implements OnInit {
    havePhoto: boolean;
    photoFile: File;
    curPhoto: string;
    fileTypes = [
        'image/jpeg',
        'image/pjpeg',
        'image/png'
    ];


    constructor(private admin: AdminService,
                public dialogRef: MatDialogRef<IconSelectComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.curPhoto = data.icon;
    }

    ngOnInit() {
    }





    validFileType(file) {
        for (let i = 0; i < this.fileTypes.length; i++) {
            if (file.type === this.fileTypes[i]) {
                return true;
            }
        }
        return false;
    }

    onChangePhoto(files) {
        const photohere = document.getElementById('photohere');
        if (this.validFileType(files[0])) {
            this.photoFile = files[0];
            this.havePhoto = true;
            const myReader: FileReader = new FileReader();
            myReader.onload = function (e) {
                const dataURL = myReader.result;
                photohere.setAttribute('src', dataURL.toString());
            };
            myReader.readAsDataURL(files[0]);
        }
    }

    onRemovePhoto() {
        this.havePhoto = false;
        this.photoFile = null;
        // todo: move this over to google cloud storage
        this.curPhoto = 'http://ron.capptivation.com/assets/icons/custom-icon.png';
        const photohere = document.getElementById('photohere');
        photohere.setAttribute('src', this.curPhoto);
    }


    onCancel() {
        this.dialogRef.close();
    }

    onSave() {
        // todo: this needs to be tested
        if (this.photoFile) {
            const filename = `Assets/catalog-icons/${this.photoFile.name}`;
            const storageRef = firebase.storage().ref().child(filename)
                .put(this.photoFile);
            this.dialogRef.close({icon: this.photoFile.name});

/*
            const s = `gs://library-titleix.appspot.com/${filename}`;
            firebase.storage().refFromURL(s).getDownloadURL().then(url => {
                this.dialogRef.close({icon: url});
            });
*/
        } else {
            this.dialogRef.close({icon: this.photoFile.name});
        }
    }


}
