import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GeofireService} from '../../services/geofire.service';
import {
  Card, CaresResource, Category, ComOrg, InterfaceService, Invitation, JumpLink, Office, OrgProfile, Policy,
  PublicStaff
} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';
import {
  CampusTypes, CampusTypesAbbrv, CampusTypesLongLat, MediumResourceTypes, NonCampusRT, ResourceTypes, ResourceTypesAbbrv,
  ShortResourceTypes,
  TemplateResourceTypes
} from '../../services/constants';

const CampusOnly = [
  'Categories',
  'RO Reports',
  'RO Surveys',
  'RO Guides',
  'RO Widgets',
  'RO Courses',
  'Campus Resources'
];

const LocalResourceTypes = [
  'Local Resources - By Distance',
  'Local Resources - By Name'
];

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input()
  caller: string;

  @Input()
  curedukey: string;

  @Input()
  rtsub: string;

  @Input()
  ctsub: string;

  @Input()
  useresourcetypesnocats: string;

  @Input()
  usesourcetypesabbrv;

  @Input()
  usecampustypeabbrv;

  @Input()
  legacy;

  @Input()
  longlatfileter = false;

  @Output()
  rtchange = new EventEmitter<string>();

  @Output()
  ctchange = new EventEmitter<string>();

  @Output()
  arrchange = new EventEmitter<any>();

  @Output()
  orgpicked = new EventEmitter<string>();

  arr: any;
  long: number;
  lat: number;
  rtypes;

  resourcetypes = ResourceTypes;
  shortresourcetypes = ShortResourceTypes;
  mediumresourcetype = MediumResourceTypes;
  templateresourcetypes = TemplateResourceTypes;
  localresourcetypes = LocalResourceTypes;
  campustypes = CampusTypes;

  resourcetypesabbrv = ResourceTypesAbbrv;
  campustypesabbrv = CampusTypesAbbrv;
  noncampusrt = NonCampusRT;

  campusonly = CampusOnly;

  nowgetloc = false;
  categories: Category[];
  tmpcategories: Category[];
  curcategory: Category;
  offices: Office[];
  videos: Card[];
  invitations: Invitation[];
  roreps;
  rosurveys;
  rowidgets;
  roguides;
  staff: PublicStaff[];
  cards: Card[];
  links: JumpLink[];
  policies: Policy[];
  local: ComOrg[];
  state: ComOrg[];
  national: ComOrg[];
  comorgs: ComOrg[];
  tmporg: ComOrg;
  loading: boolean;

  legacycatalogs;
  locpick: string;

  markers: CaresResource[];
  radius: number;

  $sub1;
  $sub2;
  $sub3;
  $sub4;
  $sub5;
  $sub6;
  $sub7;
  $sub8;
  $sub9;
  $sub10;
  $sub11;
  $sub12;


  constructor(private geo: GeofireService,
              private is: InterfaceService,
              private admin: AdminService) {
    this.loading = false;
    this.rtsub = null;
    this.radius = 5;
  }

  ngOnInit() {
    if (this.longlatfileter) {
      this.campustypes = CampusTypesLongLat;
    }

    if (this.useresourcetypesnocats === 'CampusOnly') {
      this.resourcetypes = this.campusonly;
    }

    if (this.useresourcetypesnocats === 'Org') {
      this.resourcetypes = this.shortresourcetypes;
    }

    if (this.useresourcetypesnocats === 'Community') {
      this.resourcetypes = this.noncampusrt;
    }

    if (this.useresourcetypesnocats === 'Template') {
      this.resourcetypes = this.templateresourcetypes;
    }

    if (this.usesourcetypesabbrv) {
      this.resourcetypes = this.resourcetypesabbrv;
    }

    if (this.usecampustypeabbrv) {
      this.campustypes = this.campustypesabbrv;
    }

    if (this.caller === 'app-roapp-search') {
      this.rtypes = this.shortresourcetypes;
    } else if (this.caller === 'medium') {
      this.rtypes = this.mediumresourcetype;
    } else {
      this.rtypes = this.resourcetypes;
    }


    this.$sub6 = this.geo.hits.subscribe(hits => {
      this.markers = hits;
    });

    if (this.useresourcetypesnocats === 'Community') {
      this.nowgetloc = false;
    } else {
      this.$sub1 = this.is.getObject(`Orgs/${this.admin.admin.org}/profile/geo`).take(1).subscribe(geo => {
        if (geo) {
          this.long = geo.long * 1;
          this.lat = geo.lat * 1;
          this.geo.getLocations(this.radius * 1, [this.lat, this.long]);
        }
      });

      this.$sub2 = this.is.getList(`Orgs/${this.admin.admin.org}/resources/cards`).take(1).subscribe(cards => {
        this.cards = cards;
      });

      this.$sub3 = this.is.getList(`Orgs/${this.admin.admin.org}/resources/links`).take(1).subscribe(links => {
          this.links = links;
        }
      );

      this.$sub4 = this.is.getList(`Orgs/${this.admin.admin.org}/resources/policies`).take(1).subscribe(policies => {
          this.policies = policies;
        }
      );

      this.$sub5 = this.is.getList(`Orgs/${this.admin.admin.org}/resources/staff`).take(1).subscribe(staff => {
          if (this.longlatfileter) {
            this.staff = staff.filter(o => o.long !== 0);
          } else {
            this.staff = staff;
          }
        }
      );

      this.$sub6 = this.is.getList(`Orgs/${this.admin.admin.org}/resources/offices`).take(1).subscribe(offices => {
        if (this.longlatfileter) {
          this.offices = offices.filter(o => o.long !== 0);
        } else {
          this.offices = offices;
        }
      });

      this.$sub7 = this.is.getList(`Orgs/${this.admin.admin.org}/resources/videos`).take(1).subscribe(videos => {
        this.videos = videos;
      });

      this.$sub8 = this.is.getList(`Orgs/${this.admin.admin.org}/resources/invitations`).take(1).subscribe(invitations => {
        this.invitations = invitations;
      });

      if (this.caller === 'legacy') {
        console.log('legacy', this.legacy);
        // todo: not implemented
        /*
                        this.$sub1 = this.is.getList(`LegacyCatalogs/${this.collegeid}`).take(1).subscribe(c => {
                            this.legacycatalogs = c;
                        });
        */

      } else {
        this.$sub9 = this.is.getList(`Orgs/${this.admin.admin.org}/${this.curedukey}/categories`).take(1).subscribe(cats => {
          this.categories = cats;
          this.curcategory = this.categories[0];
          this.tmpcategories = cats;
          console.log('tmp categories', this.tmpcategories)
          // this.tmpcategories.splice(0, 1);
        });
      }

      this.$sub10 = this.is.getList(`Orgs/${this.admin.admin.org}/reports`).take(1).subscribe(roreps => {
        this.roreps = roreps;
      });

      this.is.getList(`Orgs/${this.admin.admin.org}/surveys`).take(1).subscribe(rosurveys => {
        this.rosurveys = rosurveys;
      });

      this.$sub11 = this.is.getList(`Orgs/${this.admin.admin.org}/guideslist`).take(1).subscribe(roguides => {
        this.roguides = roguides;
      });

      this.$sub12 = this.is.getList(`Orgs/${this.admin.admin.org}/widgets`).take(1).subscribe(rowidgets => {
        this.rowidgets = rowidgets;
      });

      // todo: add courses
    }
  }

  /*
      onSearchByName() {
          const dialogRef = this.dialog.open(SearchByNameComponent, {
              width: '500px',
              data: {iconrequired: false}
          });

          dialogRef.afterClosed().subscribe(result => {
                  if (result) {
                      if (result) {
                          this.os.getComOrg(result).take(1).subscribe(org => {
                              this.tmporg = org;
                              this.arr = new Array();
                              this.arr.push(this.tmporg);
                          });
                      }
                  }
              }
          );
      }
  */

  onChangeLoc(val) {
    this.nowgetloc = false;
    this.long = val.geo.long * 1;
    this.lat = val.geo.lat * 1;
    this.geo.getLocations(this.radius * 1, [this.lat, this.long]);
  }

  onOrgPick(ev) {
    this.orgpicked.emit(ev);
  }

  public onResourceType(ev) {
    this.ctsub = '';
    this.rtsub = ev;
    switch (this.rtsub) {
      case 'Campus Resources':
        this.onChangeCampus('Offices');
        break;
      case 'Categories':
        this.arr = this.tmpcategories;
        this.arrchange.emit(this.arr);
        break;
      case 'RO Reports':
        this.arr = this.roreps;
        this.arrchange.emit(this.arr);
        break;
      case 'RO Surveys':
        this.arr = this.rosurveys;
        this.arrchange.emit(this.arr);
        break;
      case 'RO Guides':
        this.arr = this.roguides;
        this.arrchange.emit(this.arr);
        break;
      case 'RO Widgets':
        console.log('rowid', this.rowidgets);
        this.arr = this.rowidgets;
        console.log('arrw', this.arr);
        this.arrchange.emit(this.arr);
        break;
      case 'Local':
        this.arr = this.local;
        this.arrchange.emit(this.arr);
        break;
      case 'Local Resources - By Distance':
        this.comorgs = [];
        if (this.is.curComOrg) {
          if (this.is.curComOrg.locations && this.is.curComOrg.locations.length > 0) {
            this.nowgetloc = true;
          } else {
            // todo: not sure what to do here since the community resource has no location.
          }
        } else {
          // todo: what is the curorg location????
          if (this.markers && this.markers.length > 0) {
            this.arr = this.markers;
            this.arrchange.emit(this.arr);
          }

        }
        break;
      case 'Local Resources - By Name':
        // don't have to do anything here.
        break;
      case 'State Resources':
        // todo: totally messed up
        /*
                        this.loading = true;
                        console.log('state', this.profile.orgdata.state)
                        this.is.getQuery('Community', 'locations/0/geo/servicearea/states', this.profile.orgdata.state).take(1).subscribe(s => {
                            console.log(s)
                            this.loading = false;
                            this.arr = s;
                            this.arrchange.emit(this.arr);
                        });
        */
        break;
      case 'National Resources':
      // todo: totally messed up
      /*
                      this.loading = true;
                      this.is.getQuery('Community', 'geo/servicearea/countries', this.profile.orgdata.country).take(1).subscribe(s => {
                          this.loading = false;
                          this.arr = s;
                          this.arrchange.emit(this.arr);
                      });
                      break;
      */
      default:
        this.arr = [];
        break;
    }
    this.rtchange.emit(this.rtsub);
  }

  onChangeCategory(ev) {
    this.ctsub = ev;
    this.ctchange.emit(this.ctsub);
  }

  onChangeCampus(ev) {
    this.ctsub = ev;
    this.ctchange.emit(this.ctsub);

    switch (ev) {
      case 'Offices':
        this.arr = this.offices;
        break;
      case 'Staff':
        this.arr = this.staff;
        break;
      case 'Links':
        this.arr = this.links;
        break;
      case 'Cards':
        this.arr = this.cards;
        break;
      case 'Policies':
        this.arr = this.policies;
        break;
      case 'Videos':
        this.arr = this.videos;
        break;
      case 'Invitations':
        this.arr = this.invitations;
        break;
    }
    this.arrchange.emit(this.arr);
  }


  /*
      onSearch() {
          this.geo.getLocations(this.radius * 1, [this.lat, this.long]);
          this.onResourceType('Local Resources - By Distance');

          // this.geo.updateRadius(this.radius * 1);
      }
  */

  onRadiusChange() {
    this.geo.getLocations(this.radius * 1, [this.lat, this.long]);
    // this.onResourceType('Local Resources - By Distance');
  }


  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
    if (this.$sub3) {
      this.$sub3.unsubscribe();
    }
    if (this.$sub4) {
      this.$sub4.unsubscribe();
    }
    if (this.$sub5) {
      this.$sub5.unsubscribe();
    }
    if (this.$sub6) {
      this.$sub6.unsubscribe();
    }
    if (this.$sub7) {
      this.$sub7.unsubscribe();
    }
    if (this.$sub8) {
      this.$sub8.unsubscribe();
    }
    if (this.$sub9) {
      this.$sub9.unsubscribe();
    }
    if (this.$sub10) {
      this.$sub10.unsubscribe();
    }
    if (this.$sub11) {
      this.$sub11.unsubscribe();
    }
    if (this.$sub12) {
      this.$sub12.unsubscribe();
    }
  }

}
