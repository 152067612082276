import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CappTeamMsg, CappTeamParty, CovidEmployee, InterfaceService, Invitation, Topix} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import {RoehttpService} from '../services/roehttp.service';
import {AngularFireDatabase} from '@angular/fire/database';
import * as _ from 'lodash';

@Component({
  selector: 'app-corp-note-body',
  templateUrl: './corp-note-body.component.html',
  styleUrls: ['./corp-note-body.component.scss']
})
export class CorpNoteBodyComponent implements OnInit {
  @Input()
  survey;

  @Input()
  pth = 'CorpsPri';

  @Input()
  shortcut = '';

  @Input()
  filter = '';

  @Input()
  people: CovidEmployee[];

  @Input()
  pbody = '50%';

  @Input()
  canwork = false;

  @Input()
  parties: CappTeamParty[];

  @Input()
  topic;

  @Input()
  appt;

  @Input()
  milestones;

  @Input()
  caller: string;

  @Input()
  notetype: string;

  @Input()
  curmsg: CappTeamMsg;

  @Output()
  cancel = new EventEmitter<any>();

  err: string;
  link: string;
  spinner = false;
  typeorg: string;
  authors;
  note: string;
  notes: CappTeamMsg[];
  notetypes;
  smalllogo: string;

  triggers = [
    {code: 'Node', name: 'None'},
    {code: 'Welcome', name: 'Welcome'},
    {code: 'Profile', name: 'Profile'},
    {code: 'Goodbye', name: 'Goodbye'},
    {code: 'Invitation', name: 'Invitation'},
    {code: 'Tested Positive', name: 'Tested Positive'},
    {code: 'Tested Negative', name: 'Tested Negative'},
    {code: 'Agent Introduction', name: 'Agent Introduction'},
    {code: 'Change Infectious Period', name: 'Change Infectious Period'},
    {code: 'Chron', name: 'Chron'},
  ];

  confettistyle = [
    {code: 'none', name: 'None'},
    {code: 'shower', name: 'Shower'},
    {code: 'pride', name: 'School Pride'}
  ];

  config = {
    placeholder: '',
    tabsize: 2,
    dialogsInBody: true,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['font', ['bold', 'italic', 'underline']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
      ['link']
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };

  constructor(public admin: AdminService,
              private db: AngularFireDatabase,
              public is: InterfaceService,
              private http: RoehttpService,) {
  }

  ngOnInit() {
    this.notetypes = [];

    const tmp = 'https://www.nejm.org/doi/full/10.1056/NEJMp2026045';


    this.notetypes.push({value: 'news', name: 'Share the news'});
    this.notetypes.push({value: 'plain', name: 'Text only'});
    this.notetypes.push({value: 'gallery', name: 'Gallery'});
    this.notetypes.push({value: 'invitation"', name: 'Invitation'});


    if (!this.caller.includes('pubnotifications')) {
      this.notetypes.push({value: 'appointment', name: 'Appointment'});
      this.notetypes.push({value: 'reminder2', name: 'Reminder'});
      this.notetypes.push({value: 'canwork', name: 'work2'});
      this.notetypes.push({value: 'event', name: 'Event'});
      this.notetypes.push({value: 'eventreminder', name: 'Event reminder'});
      this.notetypes.push({value: 'email', name: 'Send to email address'});
    }


    if (this.caller.includes('employees')) {
      this.notetypes.push({value: 'gender', name: 'Generic'});
    }

    if (this.admin.admin.subtype === 'Medical') {
      this.notetypes.push({value: 'medreminder2', name: 'Medical Reminder'});
    }

    if ((this.admin.admin.subtype === 'PubHealth') &&
      !this.caller.includes('pubnotifications')) {
      this.notetypes.push({value: 'intro', name: 'Agent introduction'});
      this.notetypes.push({value: 'changeq', name: 'Change end quarantine'});
      this.notetypes.push({value: 'removeq', name: 'Remove quarantine'});
    }

    if (this.is.surveys && this.is.surveys.length > 0) {
      this.notetypes.push({value: 'survey', name: 'Survey'});
    }


    this.notes = [];
    this.is.getObject(`${this.pth}/${this.admin.admin.org}/profile/type`)
      .take(1)
      .subscribe(ty => {
        if (ty) {
          this.typeorg = ty;
        }
      });

    if (this.shortcut) {
      this.is.getList(`${this.pth}/${this.admin.admin.org}/notifications/${this.topic.ukey}`).subscribe(notes => {
        if (notes && notes.length > 0) {
          this.notes = _.orderBy(notes, ['date'], ['desc']);
          this.curmsg = this.notes.find(n => n.ty === this.shortcut);
          this.onGenericChange();
        }
      });
    } else {
      if (this.curmsg && this.curmsg.g) {
        this.link = this.curmsg.g;
      }
    }

  }

  onGallery(event) {
    this.curmsg.l = event;
  }


  getTitle(n: CappTeamMsg): string {
    if (n.s) {
      return n.s;
    } else if (n.dl) {
      return n.dl;
    } else {
      return 'No subject';
    }
  }



  onGenericChange() {
    this.notetype = this.curmsg.ty;

    if (this.curmsg.ty === 'survey') {
      this.survey = this.is.surveys.find(s => this.curmsg.dl.includes(s.ukey));
    }

    // (this.curmsg.ty === 'appointment' || this.curmsg.ty.includes('reminder')) &&
    if (this.appt) {
      if (this.appt.name) {
        this.curmsg.alt = this.appt.name;
      }
      if (this.appt.indivukey) {
        this.curmsg.e = this.appt.indivukey;
      }
      if (this.appt.agentpp) {
        this.curmsg.i = this.appt.agentpp;
      }
      if (this.curmsg.dl) {
        if (this.appt.agent) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{agent}}/g, this.appt.agent);
        }
        if (this.appt.agentemail) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{agentemail}}/g, this.appt.agentemail);
        }
        if (this.appt.agentphone) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{agentphone}}/g, this.appt.agentphone);
        }
        if (this.appt.org) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{org}}/g, this.appt.org);
        }
        if (this.appt.date) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{date}}/g, this.appt.date);
        }
        if (this.appt.qdate) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{qdate}}/g, this.appt.qdate);
        }

        if (this.appt.time) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{time}}/g, this.appt.time);
        }
        if (this.appt.name) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{name}}/g, this.appt.name);
        }
        if (this.appt.minutes) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{minutes}}/g, this.appt.minutes);
        }
        if (this.appt.location) {
          this.curmsg.dl = this.curmsg.dl.replace(/{{location}}/g, this.appt.location);
        }
        if (this.appt.arrivetime) {
          const d = new Date(this.appt.arrivetime).toLocaleTimeString();
          this.curmsg.dl = this.curmsg.dl.replace(/{{arrivetime}}/g, d);
        }
      }

      if (this.curmsg.fullmsg) {
        if (this.appt.agent) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{agent}}/g, this.appt.agent);
        }
        if (this.appt.agentemail) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{agentemail}}/g, this.appt.agentemail);
        }
        if (this.appt.agentphone) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{agentphone}}/g, this.appt.agentphone);
        }
        if (this.appt.org) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{org}}/g, this.appt.org);
        }
        if (this.appt.date) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{date}}/g, this.appt.date);
        }
        if (this.appt.qdate) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{qdate}}/g, this.appt.qdate);
        }
        if (this.appt.time) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{time}}/g, this.appt.time);
        }
        if (this.appt.name) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{name}}/g, this.appt.name);
        }
        if (this.appt.minutes) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{minutes}}/g, this.appt.minutes);
        }
        if (this.appt.location) {
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{location}}/g, this.appt.location);
        }
        if (this.appt.arrivetime) {
          const d = new Date(this.appt.arrivetime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
          this.curmsg.fullmsg['en'].description = this.curmsg.fullmsg['en'].description.replace(/{{arrivetime}}/g, d);
        }
      }

      if (this.curmsg.msg) {
        if (this.appt.agent) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{agent}}/g, this.appt.agent);
        }
        if (this.appt.agentemail) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{agentemail}}/g, this.appt.agentemail);
        }
        if (this.appt.agentphone) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{agentphone}}/g, this.appt.agentphone);
        }
        if (this.appt.org) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{org}}/g, this.appt.org);
        }
        if (this.appt.date) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{date}}/g, this.appt.date);
        }
        if (this.appt.qdate) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{qdate}}/g, this.appt.qdate);
        }

        if (this.appt.time) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{time}}/g, this.appt.time);
        }
        if (this.appt.name) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{name}}/g, this.appt.name);
        }
        if (this.appt.minutes) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{minutes}}/g, this.appt.minutes);
        }
        if (this.appt.location) {
          this.curmsg.msg = this.curmsg.msg.replace(/{{location}}/g, this.appt.location);
        }
        if (this.appt.arrivetime) {
          const d = new Date(this.appt.arrivetime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
          this.curmsg.msg = this.curmsg.msg.replace(/{{arrivetime}}/g, d);
        }
      }

      if (this.curmsg.ty === 'changeq' && this.appt.qdate) {
        this.curmsg.l = this.appt.qdate;
      }

      if (this.curmsg.ty === 'eventreminder') {
        this.curmsg.l = new Invitation();
        this.curmsg.l.end = this.appt.appt + 15 * 60 * 1000;
        this.curmsg.l.start = this.appt.appt;
        this.curmsg.l.title = this.curmsg.dl;
        this.curmsg.l.headlineDesc['en'].description = this.curmsg.fullmsg;
        this.curmsg.l.pitchDesc = this.curmsg.fullmsg;
        this.curmsg.l.ukey = this.db.createPushId();
      }

      if (this.appt && this.curmsg.ty === 'appointment') {
        console.log('msgukey', this.appt.msgukey);
        this.curmsg.g = this.appt.msgukey;
      }

      if (this.appt.temp && (this.curmsg.ty === 'medreminder2' || this.curmsg.ty === 'reminder2')) {
        this.curmsg.l = this.appt.temp;
        this.curmsg.x = null;
        this.curmsg.fullmsg['en'].description = this.curmsg.msg;
      }

      console.log('curmsg', this.curmsg);

    }
  }

  onTargetChange() {
    if (this.notetype === 'generic' && (!this.notes || this.notes.length === 0)) {
      this.is.getList(`${this.pth}/${this.admin.admin.org}/notifications/${this.topic.ukey}`).subscribe(notes => {
        if (notes && notes.length > 0) {
          this.notes = _.orderBy(notes, ['date'], ['desc']);
        }
      });
    } else {
      console.log('topic change', this.topic)
      this.curmsg = null;
      this.err = '';

      if (this.topic && this.topic.name) {
        this.curmsg = new CappTeamMsg(this.topic.location, this.topic.name, this.notetype, 'Covid');
        if (this.topic.icon) {
          this.curmsg.i = this.topic.icon;
        }
        if (this.topic.org) {
          this.curmsg.dl = this.topic.org;
        }
      } else {

        this.is.eds.forEach(e => {
          if (e.topics && e.topics.length > 0) {
            e.topics.forEach(t => {
              if (t === this.topic) {
                this.curmsg = new CappTeamMsg('', t, this.notetype, `${this.admin.admin.org}/${e.ukey}`);
              }
            });
          }
        });
      }

      if (this.notetype === 'email' && !this.topic.adminemail) {
        this.err = 'This type of notification requires an Admin email to be added to the Location profile.';
      }
    }
  }

  onLink() {
    const regex = new RegExp(/^(http|https):\/\/[^ "]+$/);
    if (!regex.test(this.link)) {
      this.err = 'Please enter a valid link.';
      return;
    }

    this.err = '';
    this.curmsg.g = '';

    if (this.notetype === 'plain' || this.notetype === 'gallery') {
      this.curmsg.g = this.link;
    } else if (this.notetype === 'news') {
      this.curmsg.alt = '';
      this.curmsg.l = null;
      this.curmsg.e = '';
      this.curmsg.s = '';
      this.curmsg.msg = '';

      this.spinner = true;
      this.http.getURIMeta(this.link)
        .then(reslt => {
          this.spinner = false;
          let res;
          if (reslt && reslt.twitter) {
            res = reslt.twitter;
          }

          if (res) {
            if (res.image) {
              this.curmsg.g = res.image;
            }
            if (res['image:alt']) {
              this.curmsg.alt = res['image:alt'];
            }

            this.curmsg.e = this.link;

            if (res.title) {
              this.curmsg.s = res.title;
            }

            if (res.description) {
              this.curmsg.msg = res.description;
            }

            this.curmsg.l = {};
            if (res['app:name:googleplay']) {
              this.curmsg.l.journal = res['app:name:googleplay'];
            }
            if (res.site) {
              this.curmsg.l.journal = res.site.replace('@', '');
            }
          }

          if (reslt && reslt.extra && reslt.extra[0]) {
            const data = reslt.extra[0];
            if (data && data.publisher && data.publisher.name) {
              this.curmsg.l.journal = data.publisher.name;
            }

            if (!res.image && data && data.image && data.image.url) {
              console.log('image.url', data.image.url);
              this.curmsg.g = data.image.url;
            }


            if (data && data.author) {
              this.authors = [];
              if (data.author.name) {
                this.curmsg.l.author = data.author.name;
                const arr = Object.values(data.author);
                if (arr && arr.length > 0) {
                  const u = arr.find(r => (r as string).toLowerCase().includes('http'));
                  this.authors.push({uri: u, name: data.author.name});
                }
              } else if (data.author.length > 0) {
                this.curmsg.l.author = data.author.map(a => a.name).join(', ');
                data.author.forEach(a => {
                  const arr = Object.values(a);
                  if (arr && arr.length > 0) {
                    const u = arr.find(r => (r as string).toLowerCase().includes('http'));
                    this.authors.push({uri: u, name: a.name});
                  }
                });
              }
            }
            if (data && data.dateCreated) {
              this.curmsg.l.dateCreated = data.dateCreated;
            }
            if (data && data.datePublished) {
              this.curmsg.l.dateCreated = data.datePublished;
            }

            if (!this.curmsg.g && data && data.image) {
              this.curmsg.g = data.image;
            }
          }
        }).catch(err => {
        this.spinner = false;
        this.err = 'We\'re sorry. We could not find this url:' + this.link;
      });
    }
  }

  onGuideChange(event) {
    this.curmsg['guide'] = event.value;  // this is the full path to the guide
  }

  onSurveyChange() {
    this.curmsg.x = `${this.pth}/${this.admin.admin.org}/surveys/${this.survey.ukey}`;  // this is the full path to an widget, event  or app
  }

  onSmallLogo(event) {
    this.smalllogo = event;
    if (this.smalllogo && this.smalllogo.length > 0) {
      this.curmsg.i = this.smalllogo;
    }

  }

  onPost(post: boolean) {

    this.err = '';
    if (this.notetype === 'email') {
      if (!this.topic.adminemail) {
        this.err = 'This type of notification requires an Admin email to be added to the Location profile.';
        return;
      } else {
        console.log('topic email', this.topic.adminemail);
        this.curmsg.cf = this.topic.adminemail;
      }
    }

    if (this.typeorg) {
      // todo: might not be the right place to store typeorg, if we want the message to do deep linking????
      this.curmsg.dt = this.typeorg;
    }

    if (!this.curmsg.ukey) {
      this.curmsg.ukey = this.db.createPushId();
    }

    if (this.curmsg.c && this.curmsg.c === 'none') {
      this.curmsg.c = null;
    }


    if (post) {
      if (this.curmsg && (this.curmsg.ty === 'gallery' ||
        this.curmsg.ty === 'invitation') && (!this.curmsg.g || this.curmsg.g.length === 0)) {
        this.err = 'Please add an image to the notification.';
        return;
      }

      if (this.curmsg.date === 9000000000000000) {
        this.curmsg.date = new Date().getTime();
        this.is.deleteObjNoLog(`${this.caller}/${this.curmsg.topics}/${this.curmsg.ukey}`);
        this.curmsg.ukey = '';
      }

      if (!this.curmsg.s) {
        this.makeNote(false);
      }

      this.curmsg.topics = this.topic.ukey;  // this is so know who to push it to.

      if (this.curmsg.orgsukeys) {
        const carr = Object.keys(this.curmsg.orgsukeys);
        if (carr && carr.length > 0) {
          this.curmsg.orgsukeys = carr;
        }
      }

      if (this.caller.includes('employees')) {
        this.curmsg['filter'] = this.filter;
        this.curmsg.parties = this.parties;
        // this function sends the notification to the parties.
        if (this.notetype === 'email') {
          this.curmsg['corp'] = this.people.map(p => p.email).join(',');
          const pt = this.caller.replace('employees', 'notifications');
          this.http.sendCorpEmail(this.curmsg);
          this.curmsg.parties = null;
          this.is.setObjNoLog(`${this.pth}/${pt}/${this.curmsg.date}`, this.curmsg);
        } else {
          this.curmsg.cf = this.topic.confirmpath;
          this.curmsg['corp'] = this.people.map(p => p.ukey).join(',');

          this.http.sendCorpNotification(this.curmsg);
        }
      } else {
        // this function triggers a cloud function called postTopixNotification
        // it's in this function that a notification chain is triggered for a Topic Organization like the WSJ.
        this.is.setObjNoLog(`${this.caller}/${this.curmsg.topics}/${this.curmsg.ukey}`, this.curmsg);
      }

      if (this.authors && this.authors.length > 0) {
        this.authors.forEach(a => {
          this.http.getURIEmail(a.uri, a.name, `${this.caller}/${this.curmsg.topics}/${this.curmsg.ukey}`);
        });
      }

    } else {
      // save but don't post
      this.curmsg.date = 9000000000000000;

      if (this.curmsg.orgsukeys) {
        const carr = Object.keys(this.curmsg.orgsukeys);
        if (carr && carr.length > 0) {
          this.curmsg.orgsukeys = carr;
        }
      }

      if (this.caller.includes('OrgsPri')) {
        this.is.eds.forEach(e => {
          if (e.topics && e.topics.length > 0) {
            e.topics.forEach(t => {
              if (t === this.topic) {
                this.curmsg.app = `${this.admin.admin.org}/${e.ukey}`;
                this.is.setObjNoLog(`${this.caller}/templates/${e.ukey}/${this.curmsg.ukey}`, this.curmsg);
              }
            });
          }
        });
      } else {
        console.log('path', `${this.caller}/${this.curmsg.topics}/${this.curmsg.ukey}`);
        this.curmsg.topics = this.topic.ukey;  // this is so know who to push it to.
        this.is.setObjNoLog(`${this.caller}/${this.curmsg.topics}/${this.curmsg.ukey}`, this.curmsg);
      }


      if (!this.notes) {
        this.notes = [];
      }
      this.notes.unshift(this.curmsg);

    }
    this.cancel.emit(this.curmsg);
    this.curmsg = null;

  }


  makeCard(url: string, res) {
    this.curmsg = new CappTeamMsg(this.topic.edition, this.topic.name, this.notetype, 'Covid');
    if (this.topic.icon) {
      this.curmsg.i = this.topic.icon;
    }

    if (url) {
      this.curmsg.e = url;
    }
    this.curmsg.fullmsg['en'].description = this.note;

    if (res) {
      if (res.image) {
        this.curmsg.g = res.image;
      }
      if (res['image:alt']) {
        this.curmsg.alt = res['image:alt'];
      }
      if (res.url) {
        this.curmsg.e = res.url;
      }
      if (res.title) {
        this.curmsg.s = res.title;
      }
      if (res.description) {
        this.curmsg.msg = res.description;
      }
      if (res['app:name:googleplay']) {
        this.curmsg.l = res['app:name:googleplay'];
      }
    }
  }

  makeNote(preview: boolean) {
    if (!this.note) {
      this.err = 'Please enter a note.';
      return;
    }

    if (this.link) {
      // check if valid htttps
      const regex = new RegExp(/^(http|https):\/\/[^ "]+$/);
      if (!regex.test(this.link)) {
        this.err = 'Please enter a valid link.';
        return;
      }

      this.spinner = true;
      // todo: check if valid img or gif???

      this.http.getURIMeta(this.link)
        .then(res => {
          console.log('res', res);
          // todo: what if res is bad?
          this.spinner = false;
          this.makeCard(this.link, res);
        }).catch(err => {
        // todo: need to handle error
        this.spinner = false;
        this.err = 'We\'re sorry. We could not find this url:' + this.link;
      });
    } else {
      this.makeCard('', '');
    }
  }

  onCancel() {
    this.curmsg = null;
    this.notetype = '';
    this.cancel.emit(null);
  }

}
