import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {BizYear} from '../../services/bizhours.service';

@Component({
    selector: 'app-hours',
    templateUrl: './hours.component.html',
    styleUrls: ['./hours.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HoursComponent implements OnInit {
    curYear: BizYear;
    isdialog = true;


    constructor(public dialogRef: MatDialogRef<HoursComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.curYear = <BizYear>data.curYear;
    }

    ngOnInit() {
    }

    onCancel() {
        this.dialogRef.close();
    }

    onSave(curYear) {
        this.dialogRef.close(curYear);
    }


}
