import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    BlockString, ComOrg, LocationData, Geo, InterfaceService, Invitation, LangStringBlock, LinkHolder, Links, OrgLegacy,
    OrgLegacyExtra,
    RFC
} from '../services/interface.service';
import * as $ from 'jquery';
import {BehaviorSubject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {FirebaseService} from '../services/firebase.service';
import {MatDialog} from '@angular/material';
import {AngularFireAuth} from '@angular/fire/auth';
import {AdminService, Adminy} from '../services/admin.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import {ComChildDialogComponent} from './com-child-dialog/com-child-dialog.component';
import {CominheritComponent} from './cominherit/cominherit.component';
import {ConfirmationComponent} from '../lib/confirmation/confirmation.component';
import {ComOrgAttribs, ComRelationships, Languages, RelationshipToParent} from '../services/constants';
import {FullCalendarComponent} from '@fullcalendar/angular';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit, OnDestroy {


    languages = Languages;
    attribs = ComOrgAttribs;
    comrelationships = ComRelationships;
    primarycolor = '#99add0';
    secondarycolor = '#000000';

    reltoparent = RelationshipToParent;
    orgslist: any[];
    parentOrgType: any;
    parentComOrg: ComOrg;
    tmpComOrg: ComOrg;
    children: string[];
    tmp: ComOrg;


    tmpall: Invitation[];
    curevent: Invitation;
    eventdetails: boolean;
    edit: boolean;
    pos: number;
    curtime: string;

    $sub6;

    $sub0;
    $sub1;
    $sub2;
    $sub3;
    $sub4;
    $sub5;

    org: BehaviorSubject<string | null> = new BehaviorSubject('');
    startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
    endAt: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');

    calform = new FormGroup({
        title: new FormControl(''),
        start: new FormControl(''),
        time: new FormControl(''),
        end: new FormControl(''),
        allday: new FormControl(''),
    });

    get title() {
        return this.calform.get('title');
    }

    get start() {
        return this.calform.get('start');
    }

    get time() {
        return this.calform.get('time');
    }

    get end() {
        return this.calform.get('end');
    }

    get allday() {
        return this.calform.get('allday');
    }

    form = new FormGroup({
        parrel: new FormControl(''), // parent relationship
        oControl: new FormControl(''),
    });


    get parrel() {
        return this.form.get('parrel');
    }

    get oControl() {
        return this.form.get('oControl');
    }

    constructor(private titleix: FirebaseService,
                public dialog: MatDialog,
                private afAuth: AngularFireAuth,
                private titleIXdb: FirebaseService,
                private is: InterfaceService,
                public admin: AdminService) {
    }

    ngOnInit() {

        if (this.admin.editchild) {
            this.admin.admin.org = this.admin.partentofcurrentorg;
            this.admin.editchild = false;
        }

        if (this.is.curComOrg) {
            this.tmpComOrg = this.is.curComOrg;
            this.setValue(this.tmpComOrg);
            this.primarycolor = this.is.curComOrg.orglegacy.primarycolor;
            this.secondarycolor = this.is.curComOrg.orglegacy.secondarycolor;

            // todo: can get rid of this after final import
            if (!this.tmpComOrg.emailextentions) {
                this.tmpComOrg.emailextentions = '';
            }

        } else {
            this.$sub1 = this.is.getObject(`Community/${this.admin.admin.org}`).subscribe(org => {
                this.is.curComOrg = org;
                this.tmpComOrg = this.is.curComOrg;
                this.setValue(this.tmpComOrg);
                this.primarycolor = this.is.curComOrg.orglegacy.primarycolor;
                this.secondarycolor = this.is.curComOrg.orglegacy.secondarycolor;

                // todo: can get rid of this after final import
                if (!this.tmpComOrg.emailextentions) {
                    this.tmpComOrg.emailextentions = '';
                }

            });
        }


        this.$sub4 = this.is.getList(`Community/${this.admin.admin.org}/children`).subscribe(s => {
            this.children = s;
        });

        this.$sub3 = this.is.getBehaviorList(this.startAt, this.endAt, 'OrgsByType/Community').subscribe(coms => {
                this.orgslist = coms;
            }
        );


        if (!this.orgslist) {
            this.orgslist = new Array();
        }


        this.filldata();

        this.eventsFC();

    }

    updateorgslist(sc) {
        this.parentOrgType = _.find(this.orgslist, {'name': sc});
        this.org.next(sc);
    }

    search() {
        if (this.oControl.value.length === 1) {
            this.oControl.setValue(this.oControl.value.toUpperCase());
        }
        const q = this.oControl.value;
        this.startAt.next(q);
        this.endAt.next(q + '\uf8ff');
    }

    filldata() {
        this.$sub0 = this.is.getObject(`Community/${this.admin.admin.org}`).subscribe(o => {
            this.is.curComOrg = o;
            this.children = [];
            if (o && o.children) {
                this.children = o.children;
            }
            this.setValue(this.is.curComOrg);
        });


        /*
                if (!this.admin.editchild) {
                    this.$sub3 = this.is.getList(`Community/${this.admin.admin.org}/children`).subscribe(c => {
                        this.children = c;
                    });
                }
        */
    }

    onPick(i) {
        this.admin.editchild = false;
        this.admin.admin.org = this.admin.admin.orgs[i].organ;
        this.admin.partentofcurrentorg = '';
        this.admin.admin.modules = this.admin.admin.orgs[i].modules;
        this.filldata();

    }

    onEdit() {
        this.admin.admin.org = this.oControl.value;
        this.filldata();
    }

    onEditOrg(i) {
        const x = this.admin.admin.orgs.findIndex(o => o.organ === this.children[i]);
        if (x > -1) {
            this.admin.admin.org = this.admin.admin.orgs[x].organ;
            this.admin.admin.modules = this.admin.admin.orgs[x].modules;
            this.filldata();
        }
    }


    copyvals() {
        this.is.getObject(`Community/${this.oControl.value}`).take(1).subscribe(p => {
            this.parentComOrg = p;
            this.attribs.forEach(at => {
                if (at.val) {
                    switch (at.name) {
                        case 'Profile':
                            this.tmpComOrg.orglegacy.name = this.parentComOrg.orglegacy.name;
                            this.tmpComOrg.orglegacy.nickname = this.parentComOrg.orglegacy.nickname;
                            this.tmpComOrg.langDesc = this.parentComOrg.langDesc;
                            this.tmpComOrg.langHead = this.parentComOrg.langHead;
                            break;
                        case 'Location':
                            break;
                        case 'services':
                            // todo
                            /*
                                                        comservices: string;
                                                        comlanguages: string;
                                                        comcommunities: string;
                                                        comtags: string;
                                                        comemojis: string;
                                                        cappmail: boolean;
                                                        ronpartner: boolean;
                                                        confidential?: boolean;
                            */

                            break;
                        case 'Phones' :
                            // Todo
                            break;
                        case 'Hours':
                            // Todo
                            // this.fetchhours(this.parentorg);
                            break;
                        case 'Links':
                            this.tmpComOrg.links.url = this.parentComOrg.links.url;
                            this.tmpComOrg.links.facebook = this.parentComOrg.links.facebook;
                            this.tmpComOrg.links.twitter = this.parentComOrg.links.twitter;
                            this.tmpComOrg.links.instagram = this.parentComOrg.links.instagram;
                            this.tmpComOrg.links.linkedin = this.parentComOrg.links.linkedin;
                            this.tmpComOrg.links.wikilink = this.parentComOrg.links.wikilink;
                            break;
                        case 'Graphics':
                            this.tmpComOrg.orglegacy.logo = this.parentComOrg.orglegacy.logo;
                            this.tmpComOrg.orglegacy.photo = this.parentComOrg.orglegacy.photo;
                            this.tmpComOrg.orglegacy.logo = this.parentComOrg.orglegacy.logo;
                            this.tmpComOrg.orglegacy.secondarycolor = this.parentComOrg.orglegacy.secondarycolor;
                            this.tmpComOrg.orglegacy.primarycolor = this.parentComOrg.orglegacy.primarycolor;
                            break;
                    }
                }
            });

            this.setValue(this.tmpComOrg);
        });
    }

    setValue(tmp: ComOrg) {
        if (tmp) {
            if (!tmp.parentrelationship) {
                tmp.parentrelationship = '';
            }
            this.form.setValue({
                parrel: tmp.parentrelationship,
                oControl: tmp.parentkey,
            });
        }
    }

    onInherit() {
        const dialogRef = this.dialog.open(CominheritComponent, {
            data: {attribs: this.attribs}
        });

        dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.attribs = result;
                    this.copyvals();
                }
            }
        );
    }

    onCancel() {
        this.oControl.setValue('');
    }

    onSave() {
        if (!this.is.curComOrg.parentkey && this.oControl.value) {
            this.is.getList(`Community/${this.oControl.value}/children`).take(1).subscribe(children => {
                children.push(this.is.curComOrg.ukey);
                this.is.setObj(`Community/${this.oControl.value}/children`, children, 'Legacy', '');
            });
        } else if (this.oControl.value && this.is.curComOrg.parentkey && this.is.curComOrg.parentkey !== this.oControl.value) {
            this.is.getList(`Community/${this.is.curComOrg.parentkey}/children`).take(1).subscribe(children => {
                if (children.length > 0) {
                    const x = children.findIndex(c => c === this.is.curComOrg.ukey);
                    if (x > -1) {
                        children.splice(x, 1);
                    }
                    this.is.setObj(`Community/${this.is.curComOrg.parentkey}/children`, children, 'Legacy', '');
                }
            });

            this.is.getList(`Community/${this.oControl.value}/children`).take(1).subscribe(children => {
                children.push(this.is.curComOrg.ukey);
                this.is.setObj(`Community/${this.oControl.value}/children`, children, 'Legacy', '');
            });
        } else if (!this.oControl.value && this.is.curComOrg.parentkey) {
            this.is.getList(`Community/${this.is.curComOrg.parentkey}/children`).take(1).subscribe(children => {
                if (children.length > 0) {
                    const x = children.findIndex(c => c === this.is.curComOrg.ukey);
                    if (x > -1) {
                        children.splice(x, 1);
                    }
                    this.is.setObj(`Community/${this.is.curComOrg.parentkey}/children`, children, 'Legacy', '');
                }
            });
        }

        this.is.curComOrg.parentkey = this.oControl.value;
        this.is.curComOrg.parentrelationship = this.parrel.value;
    }

    createComOrg(name) {
        this.tmp = new ComOrg();
        this.tmp.locations = [];
        this.tmp.programs = [];

        this.tmp.links = new Links;
        this.tmp.abbrDesc = new LangStringBlock;
        this.tmp.langDesc = new LangStringBlock;
        this.languages.forEach(l => {
            this.tmp.langDesc[l.abbr] = new BlockString;
            this.tmp.langDesc[l.abbr].description = '';
            this.tmp.langDesc[l.abbr].block = false;

            this.tmp.abbrDesc[l.abbr] = new BlockString;
            this.tmp.abbrDesc[l.abbr].description = '';
            this.tmp.abbrDesc[l.abbr].block = false;
        });
        this.tmp.parentkey = this.admin.admin.org;
        this.tmp.publickey = '';
        this.tmp.active = false;
        this.tmp.ukey = name;
        this.tmp.icon = '';
        this.tmp.type = '';
        this.tmp.links.url = '';
        this.tmp.links.facebook = '';
        this.tmp.links.pinterest = '';
        this.tmp.links.googleplus = '';
        this.tmp.links.twitter = '';
        this.tmp.links.instagram = '';
        this.tmp.links.linkedin = '';
        this.tmp.links.wikilink = '';

        this.tmp.orglegacy = new OrgLegacy;
        this.tmp.orglegacy.nickname = '';
        this.tmp.orglegacy.name = name;
        this.tmp.orglegacy.nickname = '';
        this.tmp.orglegacy.logo = '';
        this.tmp.orglegacy.smalllogo = '';
        this.tmp.orglegacy.photo = '';
        this.tmp.orglegacy.primarycolor = '#99add0';
        this.tmp.orglegacy.secondarycolor = '#000000';

        this.tmp.orglegacyextra = new OrgLegacyExtra;
        this.tmp.orglegacyextra.is24 = false;
        this.tmp.orglegacyextra.kwabid = 10000;  // todo: need a way of generating a kwabid
        this.tmp.orglegacyextra.resource_super_type_slug = 'organization';
        this.tmp.orglegacyextra.schedule = null;
        this.tmp.orglegacyextra.short_description = '';

        const comorg = new LocationData;
        comorg.cappmail = false;
        comorg.geo.county = '';
        comorg.programs = '';
        comorg.comtags = '';
        comorg.contactname = '';
        comorg.contactemail = '';
        comorg.comemojis = '';
        comorg.street1 = '';
        comorg.street2 = '';
        comorg.city = '';
        comorg.state = '';
        comorg.zip = '';
        comorg.country = '';

        comorg.geo = new Geo;
        comorg.geo.timezone = '';
        comorg.geo.rad = 0.4;
        this.tmp.locations.push(comorg);

    }

    onDeleteOrg(i) {
        // remove the child from the admins list of orgs in Marketing
        const x = this.admin.admin.orgs.findIndex(a => a.organ === this.children[i]);
        if (x > -1) {
            this.admin.admin.orgs.splice(x, 1);
            this.is.setObj(`Marketing/${this.admin.admin.ukey}/orgs`, this.admin.admin.orgs, 'Legacy', '');

        }

        // delete the child
        this.is.deleteObj(`Community/${this.children[i]}`, 'Legacy', '');

        // remove child from list of children
        this.children.splice(i, 1);
        this.is.setObj(`Community/${this.admin.admin.org}/children`, this.children, 'Legacy', '');


    }

    onAddOrg() {
        const dialogRef = this.dialog.open(ComChildDialogComponent, {
            width: '450px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.is.getObject(`Community/${result}`).take(1).subscribe(o => {
                    if (o) {
                        if (o.CARES) {
                            delete o.CARES;
                        }
                        if (o.devices) {
                            delete o.devices;
                        }
                        if (o.events) {
                            delete o.events;
                        }
                        if (o.guides) {
                            delete o.guides;
                        }
                        if (o.people) {
                            delete o.people;
                        }
                        if (o.license) {
                            delete o.license;
                        }


                        o.ukey = result;
                        o.active = false;
                        o.parentkey = this.admin.admin.org;
                        o.lastupdate = new Date().getTime();
                        o.orgdata.name = result;
                        o.admin[`${this.afAuth.auth.currentUser.uid}`] = true;
                        this.children.push(result);
                        this.is.setObj(`Community/${this.admin.admin.org}/children`, this.children, 'Legacy', '');
                        this.is.setObj(`Community/${result}`, o, 'Legacy', '');

                    } else {
                        this.createComOrg(result);
                        this.is.setObj(`Community/${result}`, this.tmp, 'Legacy', '');
                        this.children.push(result);
                        this.is.setObj(`Community/${this.admin.admin.org}/children`, this.children, 'Legacy', '');

                        this.is.setObj(`Community/${result}/admin/${this.afAuth.auth.currentUser.uid}`, true, 'Legacy', '');
                    }

                    //  todo: not sure if this works
                    const ad = new Adminy;
                    ad.organ = result;
                    ad.modules = this.admin.admin.modules;
                    this.admin.admin.orgs.push(ad);
                    this.is.setObj(`Marketing/${this.admin.admin.ukey}/orgs`, this.admin.admin.orgs, 'Legacy', '');
                });
            }
        });
    }


    eventClick(ev) {
        this.pos = this.tmpall.findIndex(t => t.title === ev.event.title);
        if (this.pos > -1) {
            this.edit = true;
            this.eventdetails = true;
            this.curevent = this.tmpall[this.pos];
            const off = new Date().getTimezoneOffset() / 60;
            console.log('off', off);
            const hr = new Date(this.curevent.start).getHours() + off;
            const min = new Date(this.curevent.start).getMinutes();
            this.curtime = `${hr}:${min}`;
            this.setCalValue();
        }
    }

    setCalValue() {
        this.calform.setValue({
            title: this.curevent.title,
            time: this.curtime,
            start: this.curevent.start,
            end: this.curevent.end,
            allday: this.curevent.allDay,
        });
        this.eventdetails = true;
    }

    onDayClick(ev) {
        this.edit = false;
        this.curevent = new Invitation();
        this.curevent.ukey = '';
        this.curevent.title = '';
        this.curevent.start = new Date(ev.date.format('ll')).getTime();
        this.curtime = '12:00';
        this.curevent.end = this.curevent.start;
        this.curevent.allDay = false; // false means show time
        this.curevent.gspoints = 0;
        this.curevent.gspId = '';
        this.setCalValue();
    }

    onCalCancel() {
        this.eventdetails = false;
    }

    onCalSave() {
        if (this.time.value || this.allday.value) {
            if (!this.curevent.ukey) {
                this.curevent.ukey = this.titleIXdb.titleIXdb.createPushId();
            }

            console.log('start', this.start.value);
            this.curevent.start = new Date(new Date(this.start.value).getFullYear(),
                new Date(this.start.value).getMonth(),
                new Date(this.start.value).getDate(),
                parseInt(this.time.value.split(':')[0], 10),
                parseInt(this.time.value.split(':')[1], 10),
                0, 0).getTime();
            this.curevent.title = this.title.value;
            this.curevent.end = this.end.value;
            this.curevent.allDay = this.allday.value;


            if (this.edit) {
                this.is.updateObj(`Community/${this.admin.admin.org}/events/${this.curevent.ukey}`, this.curevent, 'Calendar', `Editted: ${this.curevent.title}`);

            } else {
                this.is.setObj(`Community/${this.admin.admin.org}/events/${this.curevent.ukey}`, this.curevent, 'Calendar', `Added: ${this.curevent.title}`);
            }
            this.eventdetails = false;
        }
    }


    clickButton(ev) {
        this.eventsFC();
    }

    onDelete() {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this event?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                this.is.deleteObj(`Community/${this.admin.admin.org}/events/${this.curevent.ukey}`, 'Delete', `Delete Event`);
                this.eventdetails = false;

            }
        });
    }

    eventsFC() {
        this.$sub6 = this.is.getList('Community/' + this.admin.admin.org + '/events').subscribe(events => {
            this.tmpall = [];
            events.forEach(p => {
                /*
                                        p.start = moment(p.start).toISOString();
                */
                let startTime = new Date(p.start);
                startTime = new Date(startTime.getTime() - (startTime.getTimezoneOffset() * 60000));
                p.start = moment(startTime).toISOString();

                this.tmpall.push(p);
            });

            this.tmpall = _.orderBy(this.tmpall, ['start'], ['asc']);

/*
            if (this.ucCalendar) {
                this.ucCalendar.fullCalendar('removeEvents', function () {
                    return true;
                });
                this.ucCalendar.fullCalendar('renderEvents', this.tmpall, false);
            }
*/

        });

    }

    ngOnDestroy() {
        if (this.$sub0) {
            this.$sub0.unsubscribe();
        }
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
        if (this.$sub2) {
            this.$sub2.unsubscribe();
        }
        if (this.$sub3) {
            this.$sub3.unsubscribe();
        }
        if (this.$sub4) {
            this.$sub4.unsubscribe();
        }
        if (this.$sub5) {
            this.$sub5.unsubscribe();
        }
        if (this.$sub6) {
            this.$sub6.unsubscribe();
        }

    }
}
