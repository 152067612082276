import {Component, Input, OnInit} from '@angular/core';
import {computeDistanceBetween} from 'spherical-geometry-js';
import {CirclePng, ConfidentialPng, OctagonPng} from '../ro-cards-lib/cards/cards-service.service';
import {PublicStaff} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
declare var google: any;

@Component({
    selector: 'app-staff-card',
    templateUrl: './staff-card.component.html',
    styleUrls: ['./staff-card.component.scss']
})
export class StaffCardComponent implements OnInit {
    circlepng = CirclePng;
    octagonpng = OctagonPng;
    confidentialpng = ConfidentialPng;

    @Input()
    staff: PublicStaff;

    @Input()
    userlat: number;

    @Input()
    userlong: number;

    distance = -1;

    constructor(public admin: AdminService) {
    }

    getName(staff) {
        return this.admin.makeName(staff);
    }

    calcDistance() {
        if (this.userlat && this.userlong && this.staff.lat && this.staff.long) {
            const x = computeDistanceBetween(
                new google.maps.LatLng(this.staff.lat, this.staff.long),
                new google.maps.LatLng(this.userlat, this.userlong));
            this.distance = Math.round(x * 0.000621371 * 100) / 100;
            console.log('distance', this.distance)
        }
    }

    ngOnInit() {
        this.calcDistance();
    }

}

