import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireStorage} from '@angular/fire/storage';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input()
  multiple = true;

  @Output()
  gals = new EventEmitter<any>()

  gallery = [];


  details = false;
  err: string;
  desc: string;
  link: string;

  filetypes = '.gif,.png,.jpg,.jpeg,.bmp,.tif,.tiff,.svg';
  fname: string;
  gotfile = '';

  internetdetails = false;
  file;


  constructor(private st: AngularFireStorage,
              private db: AngularFireDatabase) {
  }

  ngOnInit(): void {
  }

  onDelete(i) {
    this.fname = '';
    this.err = '';
    if (!this.internetdetails) {
      this.st.storage.refFromURL(this.gallery[i].url).delete();
    }
    this.gallery.splice(i, 1);
    this.gals.emit(this.gallery);
  }


  addFromInternet() {
    this.desc = '';
    this.link = '';
    this.internetdetails = true;
  }


  changeListener(files, event) {
    this.details = true;
    this.file = files[0];
  }

  changeListenerOne(files) {
    this.file = files[0];
    this.saveImage()
  }

  saveInternetImage() {
    this.err = '';
    // todo: add this back to the conditional below: || !this.rostate.validLink(this.link)
    if (!this.link) {
      this.err = 'Please enter a valid internet address.';
      return;
    }

    const arr = this.filetypes.split(',')
    let okay = false;
    arr.forEach(a => {
      if (this.link.includes(a)) {
        okay = true;
      }
    });

    if (!okay) {
      this.err = 'Please enter an internet address for a graphic.';
      return;
    }

    this.gallery.push({url: this.link, desc: this.desc});
    this.gals.emit(this.gallery);
    this.fname = this.link.split('/').pop();
    if (this.fname.includes('?')) {
      this.fname = this.fname.split('?')[0];
    }
    this.desc = '';
    this.link = '';
    if (this.multiple) {
      this.internetdetails = false;
    }
  }

  saveImage() {
    this.fname = this.file.name;
    const ext = this.file.name.split('.')[1]
    const ukey = this.db.createPushId();
    // this is where store an uploaded file to a cloud storage bucket
    const filename = `Notifications/${ukey}.${ext}`;
    const storageRef = firebase.storage();
    firebase.storage().ref().child(filename).put(this.file).then(
      v => {
        const s = `gs://library-titleix.appspot.com/${filename}`;
        storageRef.refFromURL(s).getDownloadURL().then(url => {
          this.gotfile = url;
          this.gallery.push({url: this.gotfile, desc: this.desc});
          this.gals.emit(this.gallery);
          this.desc = '';
        });
      }
    );
  }

  onSave() {
    this.err = '';

    if (!this.desc) {
      this.err = 'Please enter a description of the graphic.';
      return;
    }

    this.saveImage();
    this.details = false;
  }

  onCancel() {
    this.details = false;
  }


}
