import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CAMolyData, CAMolyInForm, CAMolyValue} from '../../services/ca';
import {InterfaceService} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';

@Component({
  selector: 'app-ca-link',
  templateUrl: './ca-link.component.html',
  styleUrls: ['./ca-link.component.scss']
})
export class CaLinkComponent implements OnInit {
  @Input()
  path: string;

  @Input()
  formusesukey: string;

  @Input()
  moly: CAMolyInForm;

  @Input()
  primarycolor = '#0000FF';

  @Input()
  secondarycolor = '#ffffff';

  @Input()
  toc = false;

  @Output()
  save = new EventEmitter<string>();

  firsttime = true;
  editdesc = false;
  olddescription: string;
  description: string;
  descdata: CAMolyData;

  scope = 'global';
  curlang = 'en';
  ukey: string;

  constructor(private is: InterfaceService,
              private db: AngularFireDatabase) {
  }

  ngOnInit() {
    console.log('moly', this.moly)
    console.log('link path', `${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`)
    this.is.getObject(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`)
      .take(1)
      .subscribe(val => {
        if (!val) {
          this.setUp();
        } else {
          this.ukey = val.ukey;
          let pth;
          if (val.scope === 'global') {
            pth = `${this.path}/data/${this.ukey}`;
          } else if (val.scope === 'local') {
            pth = `${this.path}/formvals/${this.formusesukey}/${this.ukey}`
          }
          this.scope = val.scope;
          this.is.getObject(pth)
            .take(1)
            .subscribe(res => {
              console.log('res', res)
              this.firsttime = false;
              this.descdata = new CAMolyData();
              this.descdata.name = this.moly.moly.name;
              this.descdata.molyukey = this.moly.moly.ukey;
              this.descdata.value = res.value;
              this.description = this.descdata.value[this.descdata.value.length - 1].data;
              this.olddescription = this.description;
              this.editdesc = false;
            });
        }
      });
  }

  setUp() {
    this.ukey = this.db.createPushId();

    this.descdata = new CAMolyData();
    this.descdata.name = this.moly.moly.name;
    this.descdata.molyukey = this.moly.moly.ukey;
    this.descdata.value = [];
    this.description = '';
    this.olddescription = this.description;
    this.editdesc = true;
  }

  onInbox(str) {
    this.scope = str;
    this.firsttime = true;
  }

  onClickDesc() {
    this.editdesc = true;
  }

  onKeyUp(event) {
    // todo: check if Done is triggered
    if ((event.code === 'Enter' || event.key === 'Enter' || event.code === 'Done' || event.key === 'Done') &&
      (this.description.length > 3)) {
      this.editdesc = false;
      this.descriptionSave();
    }
  }

  descriptionSave() {
    if (this.olddescription !== this.description) {

      if (!this.descdata.value) {
        this.descdata.value = [];
      }

      const v = new CAMolyValue();
      v.data = this.description;

      // todo: change to international time? save the timezone?
      v.date = new Date().getTime();
      this.descdata.value.push(v);
      this.olddescription = this.description;
      this.editdesc = false;

      let loc;
      if (this.scope === 'global') {
        loc = 'data';
      } else {
        loc = `formvals/${this.formusesukey}`;
      }
      this.is.setObjNoLog(`${this.path}/formuses/${this.formusesukey}/${this.moly.moly.ukey}`, {ukey: this.ukey, scope: this.scope});

      const p = `${this.path}/${loc}/${this.ukey}`;
      if (this.firsttime) {
        this.is.setObjNoLog(p, this.descdata);
        this.firsttime = false;
      } else {
        this.is.setObjNoLog(`${p}/value/${this.descdata.value.length - 1}`,
          this.descdata.value[this.descdata.value.length - 1]);
      }

      // emit success
      // todo: not sure if have to emit the ukey
      this.save.emit(this.ukey);
    }
  }
}
