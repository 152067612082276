import { Injectable } from '@angular/core';
import {LangStringBlock} from './interface.service';

export class CappAd {
  ukey: string;
  name: LangStringBlock;
  height: number;
  front: CappFace;
  back: CappFace;

  constructor() {
    this.height = 200;
    this.name = new LangStringBlock();
    this.front = new CappFace();
    this.back = new CappFace();
  }
}

export class CappFace {
  background: object; // CappBackground;
  elements: CappElement[];

  constructor() {
    this.background = {};
    this.background['src'] = '';
    this.background['backgroundcolor'] = '#FFFFFF';
    this.elements = [];
  }
}

export class CappButton {
  backcolor: string;
  link: string;
  radius: number;
  padding: number;

  constructor() {
    this.backcolor = '#0000FF';
    this.radius = 25;
    this.padding = 6;
    this.link = '';
  }
}

export class CappElement {
  type: string;
  buttonstyle: CappButton;
  ukey: string;
  desc: LangStringBlock;
  top: number;
  left: number;
  width: number;
  height: number;

  constructor(type) {
    this.type = type;
    this.desc = new LangStringBlock();
    if (type === 'button') {
      this.buttonstyle = new CappButton();
      this.desc['en'].description = 'Button';
    }

    this.top = 20;
    this.left = 20;
    this.width = 30;
    this.height = 30;
  }
}


@Injectable({
  providedIn: 'root'
})
export class InviteService {

  constructor() { }
}
