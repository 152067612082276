import {Injectable} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {AdminService} from './admin.service';

export class BizHourSave {
  time: number;
  open: boolean;
  description: string;
  tm: string;
  len: number;
}

export class BizYear {
  regulardays: BizDay[];
  twentyfourseven: boolean;
  months: BizMonth[];
}

export class BizYearCont {
  regulardays;
  twentyfourseven: boolean;
  hours;
}

export class BizHour {
  time: Date;
  open: boolean;
  description: string;
  tm: string;
  len: number;
}


export class BizDay {
  day: Date;
  open: boolean;
  holiday: boolean;
  blocks: BizHour[];
  color: string;

  constructor() {
  }
}

export class BizMonth {
  month: Date;
  days: BizDay[];
}

@Injectable({
  providedIn: 'root'
})
export class BizhoursService {

  constructor(public snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private titleIXdb: FirebaseService) {
  }

  getUTCStartDay(date?: Date): number {
    let d;
    if (!date) {
      d = new Date();
    } else {
      d = date;
    }
    const utcYear =  d.getUTCFullYear();
    const utcMonth = d.getUTCMonth();
    const utcDay = d.getUTCDate();

    return Date.UTC(utcYear, utcMonth, utcDay, 0, 0, 0);
  }

  getregulardayz(path): Observable<any[]> {
    return this.titleIXdb.titleIXdb.list<any>(path).valueChanges();
  }

  gettwentfoursevenz(path): Observable<boolean> {
    return this.titleIXdb.titleIXdb.object<boolean>(path).valueChanges();
  }

  getbizhourz(path): Observable<any[]> {
    return this.titleIXdb.titleIXdb.list<any>(path).snapshotChanges().map(
      function(arr) {
        return arr.map(snap =>
          Object.assign(snap.payload.val(), {ukey: snap.key}));
      }
    );
  }

  savebizhours(ukey: string, bizhours: BizYear) {
    let op = false;
    this.snackBar.open('Data being saved', '', {
      duration: 100
    });


    const hld24 = 'Orgs/' + ukey + '/twentyfourseven';
    if (bizhours.twentyfourseven) {
      op = true;
      this.titleIXdb.titleIXdb.object(hld24).set(bizhours.twentyfourseven)
        .then(res => {

          this.snackBar.open('Data saved', '', {
            duration: 1000,
          });

        })
        .catch(err => {

          this.snackBar.open('Error:', err, {
            duration: 1000,
          });
        });
    } else {
      this.titleIXdb.titleIXdb.object(hld24).remove();
    }

    let i = 0;
    bizhours.regulardays.forEach(day => {

      const tmpblocks = new Array();

      day.blocks.forEach(block => {
        const tmpblock = new BizHourSave();
        tmpblock.time = block.time.getTime();
        tmpblock.len = block.len;
        if (block.description) {
          tmpblock.description = block.description;
        } else {
          tmpblock.description = '';
        }
        tmpblock.open = block.open;
        tmpblock.tm = block.tm;
        tmpblocks.push(tmpblock);
      });

      const hlddays = 'Orgs/' + ukey + '/regulardays/';
      this.titleIXdb.titleIXdb.object(hlddays + i).set(tmpblocks)
        .then(res => {

          this.snackBar.open('Data saved', '', {
            duration: 1000,
          });

        })
        .catch(err => {

          this.snackBar.open('Error:', err, {
            duration: 1000,
          });

        });
      i = i + 1;
    });

    this.snackBar.open('Data being saved.', '', {
      duration: 1000,
    });

    const tz = 'Orgs/' + ukey + '/tz/';
    this.titleIXdb.titleIXdb.object(tz).set(new Date().getTimezoneOffset());

    const hldhrs = 'Orgs/' + ukey + '/hours/';
    this.titleIXdb.titleIXdb.object(hldhrs).remove();
    bizhours.months.forEach(months => {
      months.days.forEach(day => {

        const tmpblocks = new Array();

        day.blocks.forEach(block => {
          const tmpblock = new BizHourSave();
          tmpblock.time = block.time.getTime();
          tmpblock.len = block.len;
          if (block.description) {
            tmpblock.description = block.description;
          } else {
            tmpblock.description = '';
          }
          tmpblock.open = block.open;
          if (block.open) {
            op = true;
          }

          tmpblock.tm = block.tm;
          tmpblocks.push(tmpblock);
        });

        /* * * */
        const ky = this.getUTCStartDay(day.day);  //revert to: day.day.getTime();
        /* * * */
        this.titleIXdb.titleIXdb.object(hldhrs + ky).set(tmpblocks)
          .then(res => {
            this.snackBar.open('Data saved', '', {
              duration: 10,
            });
          })
          .catch(err => {
            this.snackBar.open('Error:', err, {
              duration: 10,
            });
          });
      });
    });

    if (!op) {
      this.titleIXdb.titleIXdb.object('Orgs/' + ukey + '/hours').remove();
    }
  }

  savebuisinesshoursContSF(path: string, istwofour, ukey, bizhours: BizYearCont) {
    let op = false;
    const dbRef = this.afs;
    if (bizhours && !istwofour) {
      const rd = [];
      bizhours.regulardays.forEach(day => {
        const tmpblocks = new Array();

        day.forEach(block => {
          const tmpblock = new BizHourSave();
          tmpblock.time = block.time.getTime();
          tmpblock.len = block.len;
          if (block.description) {
            tmpblock.description = block.description;
          } else {
            tmpblock.description = '';
          }
          tmpblock.open = block.open;
          tmpblock.tm = block.tm;
          tmpblocks.push(tmpblock);
        });

        rd.push({tmpblocks});
      });

      /*
                  dbRef.doc(`Community/${path}/schedule/${ukey}`).set({regulardays: JSON.parse(JSON.stringify(rd))})
                      .then(data => {
                      })
                      .catch(err => {
                          console.log('not saved', err);
                      });
      */

      const dz = {};
      if (bizhours.hours) {
        const carr = Object.keys(bizhours.hours);

        carr.forEach(c => {
          dz[c] = JSON.stringify(bizhours.hours[c]);
        });
        dbRef.doc(`Schedule/${ukey}`).set(dz);
      }
    }
  }


  savebuisinesshours(pth: string, bizhours: BizYear) {
    let op = false;

    this.titleIXdb.titleIXdb.object(pth + '/twentyfourseven').set(bizhours.twentyfourseven)
      .then(data => {
        op = true;
      })
      .catch(err => {
        console.log('not saved', err);
      });

    const rd = [];
    bizhours.regulardays.forEach(day => {
      const tmpblocks = new Array();

      day.blocks.forEach(block => {
        const tmpblock = new BizHourSave();
        tmpblock.time = block.time.getTime();
        tmpblock.len = block.len;
        if (block.description) {
          tmpblock.description = block.description;
        } else {
          tmpblock.description = '';
        }
        tmpblock.open = block.open;
        tmpblock.tm = block.tm;
        tmpblocks.push(tmpblock);
      });

      rd.push(tmpblocks);
    });

    const tz = pth + '/tz';
    this.titleIXdb.titleIXdb.object(tz).set(new Date().getTimezoneOffset());


    this.titleIXdb.titleIXdb.object(pth + '/regulardays').set(rd)
      .then(data => {
      })
      .catch(err => {
        console.log('not saved', err);
      });
    this.titleIXdb.titleIXdb.object(pth + '/months').remove();
    this.titleIXdb.titleIXdb.object(pth + '/hours').remove();


    const dz = [];
    bizhours.months.forEach(months => {
      months.days.forEach(day => {
        const tmpblocks = new Array();
        day.blocks.forEach(block => {
          const tmpblock = new BizHourSave();
          tmpblock.time = block.time.getTime();
          tmpblock.len = block.len;
          if (block.description) {
            tmpblock.description = block.description;
          } else {
            tmpblock.description = '';
          }
          tmpblock.open = block.open;
          if (block.open) {
            op = true;
          }
          tmpblock.tm = block.tm;
          tmpblocks.push(tmpblock);
        });

        // day.day.getTime()
        dz[this.getUTCStartDay(day.day)] = tmpblocks;
      });

      this.titleIXdb.titleIXdb.object(pth + '/hours').set(dz)
        .then(data => {
        })
        .catch(err => {
          console.log('not saved', err);
        });
    });

  }

}
