///<reference path="constants.ts"/>
import {Injectable} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CaServices} from '../capptivation/ca-services/ca-services';
import {environment} from '../../environments/environment';

export class Constants {
  static postCAContact = `${environment.titleixUrl}/postCAContact`;
  static postCARequestFriend = `${environment.titleixUrl}/postCARequestFriend`;
  static postCAAcceptFriend = `${environment.titleixUrl}/postCAAcceptFriend`;
  static postCACreateUser = `${environment.titleixUrl}/postCACreateUser`;
  static postCACreateToken = `${environment.titleixUrl}/postCACreateToken`;
}

@Injectable({
    providedIn: 'root'
})
export class CahttpService {

    constructor(public fs: FirebaseService,
                private db: AngularFireDatabase,
                private gs: CaServices,
                private auth: AngularFireAuth,
                private http: HttpClient) {
    }

    //       this.ht.postCACreateUser(phonetypev, phonenumv, phonedescv, this.name.value, this.type.value, emailtypev, email, emaildesc)
    /*
        this.ht.postCACreateUser('phone', '+19144332000', 'Cell',
        'Richard Zandi', 'Person',
        'email', 'richard@capptivation.com', 'Primary')
    */

    postGetPathPayloadQuerry(pathv, fieldv, keyv): Promise<string> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postGetPathPayloadQuerry';

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        path: pathv,
                        field: fieldv,
                        key: keyv
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                let val = null;
                if (res && res['data']) {
                    val = Object.values(res['data']);
                }
                return val;
            });
    }

    postGetPathPayload(pathv): Promise<string> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postGetPathPayload';

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        path: pathv
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res['val'];
            });
    }

    postUpdateContact(addv, teamv, iduserv, tukeyv): Promise<boolean> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postUpdateContact';

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        add: addv,
                        team: teamv,
                        iduser: iduserv,
                        tukey: tukeyv,
                        loc: 'CA/users'
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }

    postPathDelete(pathv): Promise<boolean> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postPathDelete';

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        path: pathv,
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }

    postPathPayload(pathv, payloadv): Promise<boolean> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postPathPayload';

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        path: pathv,
                        payload: payloadv,
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }

    postCAValidate(locv, userv, ukeyv, jdatev, validatorv): Promise<boolean> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postCAValidate';

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        loc: locv,
                        user: userv,
                        ukey: ukeyv,
                        jdate: jdatev,
                        validator: validatorv
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }


    postCACreateId(deviceidv, identifierv, ukeyv): Promise<boolean> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postCACreateId';

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        deviceid: deviceidv,
                        identifier: identifierv,
                        ukey: ukeyv
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }


    postCASaveEmployment(locv, iduserv, curjobv, newjobv, ukeyv, firsttimev, idukeyv, parentv, validatev, validatorv): Promise<boolean> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postCASaveEmployment';


        return this.auth.auth.currentUser.getIdToken().then(authToken => {
            const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
            return this.http.post(url,
                {
                    iduser: iduserv,
                    curjob: curjobv,
                    newjob: newjobv,
                    ukey: ukeyv,
                    firsttime: firsttimev,
                    idukey: idukeyv,
                    loc: locv,
                    metakey: this.gs.idemployment,
                    parent: parentv,
                    validate: validatev,
                    validator: validatorv
                },
                {headers: headersv}).toPromise();
        }).then(res => {
            return res as boolean;
        });

    }

    postCAFetchEmployment(locv, userv, idukeyv, posv): Promise<boolean> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postCAFetchEmployment';

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        user: userv,
                        idukey: idukeyv,
                        loc: locv,
                        pos: posv
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }

    postCACreateUser(phonetypev, phonenumv, phonedescv, namev, usertypev, emailtypev, emailv, emaildescv): Promise<boolean> {
        const url = Constants.postCACreateUser;

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        phonetype: phonetypev,
                        phonenum: phonenumv,
                        phonedesc: phonedescv,
                        name: namev,
                        usertype: usertypev,
                        emailtype: emailtypev,
                        email: emailv,
                        emaildesc: emaildescv
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }


    postCAAcceptFriend(iduserv, secuserv, namev, mytokenv): Promise<boolean> {
        const url = Constants.postCAAcceptFriend;

        console.log('url', url);
        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        iduser: iduserv,
                        secuser: secuserv,
                        name: namev,
                        mytoken: mytokenv,
                        loc: 'CA/users'
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }

    postCARequestFriend(iduserv, unv, namev): Promise<boolean> {
        const url = 'https://us-central1-library-titleix.cloudfunctions.net/postCARequestFriend';

        let profilepicv = '';
        if (this.gs.profilepics['happy']) {
            profilepicv = this.gs.profilepics['happy'];
        } else if (this.gs.profilepics['normal']) {
            profilepicv = this.gs.profilepics['normal'];
        }

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        iduser: iduserv,
                        username: unv,
                        name: namev,
                        profilepic: profilepicv
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }

    postCAContact(iduserv, userv): Promise<boolean> {
        const url = Constants.postCAContact;

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        iduser: iduserv,
                        user: userv,
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }

    postCACreateToken(): Promise<boolean> {
        const url = Constants.postCACreateToken;

        return this.auth.auth.currentUser.getIdToken()
            .then(authToken => {
                const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});

                return this.http.post(url, {
                        uid: this.auth.auth.currentUser.uid,
                    },
                    {headers: headersv}).toPromise();
            })
            .then(res => {
                return res as boolean;
            });
    }
}
