import {Component, Input, OnInit} from '@angular/core';
import {computeDistanceBetween} from 'spherical-geometry-js';
import {CirclePng, ConfidentialPng, OctagonPng} from '../ro-cards-lib/cards/cards-service.service';
import {Office} from '../services/interface.service';
import {AdminService} from '../services/admin.service';

declare var google: any;

@Component({
    selector: 'app-office-card',
    templateUrl: './office-card.component.html',
    styleUrls: ['./office-card.component.scss']
})
export class OfficeCardComponent implements OnInit {
    circlepng = CirclePng;
    octagonpng = OctagonPng;
    confidentialpng = ConfidentialPng;

    @Input()
    off: Office;

    @Input()
    userlat: number;

    @Input()
    userlong: number;

    distance = -1;

    constructor(public admin: AdminService) {
    }

    calcDistance() {
        if (this.userlat && this.userlong && this.off.lat && this.off.long) {
            const x = computeDistanceBetween(
                new google.maps.LatLng(this.off.lat, this.off.long),
                new google.maps.LatLng(this.userlat, this.userlong));
            this.distance = Math.round(x * 0.000621371 * 100) / 100;
            console.log('distance', this.distance)
        }
    }

    getBuild(off) {
        return this.admin.makeBuildingString(off);
    }

    ngOnInit() {
        this.calcDistance();
    }

}
