import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Wish} from '../../services/interface.service';
import {MapboxService} from '../../services/mapbox.service';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent implements OnInit, OnChanges {
  @Input()
  s: Wish;

  @Input()
  parentrect;

  @Input()
  scrollpos;

  @Input()
  selectedukey: string;

  @Output()
  newselectedukey = new EventEmitter<Wish>();

  @Output()
  gallery = new EventEmitter<Wish>();


  curlang = 'en';

  config = {
    slidesPerView: 1,
    direction: 'horizontal',
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    allowTouchMove: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    a11y: true,
    preventClicks: true,
    preventClicksPropagation: false,
  };

  rect;

  daycolor = '#0000FF';
  titlecolor = '#0000FF';
  iconcolor = '#6868fc';
  activities: number[];

  @ViewChild('activitybox', {static: false}) activitybox: ElementRef;


  constructor(private mapbox: MapboxService) {

  }

  ngOnInit() {
    this.activities = [];
    for (let i = 0; i < this.s.e.activitynumber; i++) {
      this.activities.push(i + 1);
    }
  }

  onImgError(i) {

  }

  onGallery() {
    this.gallery.emit(this.s)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.activitybox && (this.selectedukey !== this.s.ukey)) {
      this.rect = this.activitybox.nativeElement.getBoundingClientRect();
      if (this.rect &&
        this.parentrect &&
        Math.abs(this.rect.y - this.parentrect.y) > -30 &&
        Math.abs(this.rect.y - this.parentrect.y) < 30) {
        this.newselectedukey.emit(this.s);
      }
    }
  }

}
