import {Component, OnDestroy, OnInit} from '@angular/core';
import {BlockString, Edition, Editionx, InterfaceService, LangStringBlock} from '../../services/interface.service';
import {Languages} from '../../services/constants';
import * as _ from 'lodash';
import {TranslateService} from '../../services/translate.service';
import {AdminService} from '../../services/admin.service';

const fetch = require('node-fetch');

@Component({
  selector: 'app-errmsg',
  templateUrl: './errmsg.component.html',
  styleUrls: ['./errmsg.component.scss']
})
export class ErrmsgComponent implements OnInit, OnDestroy {
  code: string;
  msg: string;
  msgs;
  $sub1;
  $sub2;
  $sub3;
  $sub4;
  languages = Languages;

  // langpth = `TransLang/ROC`;
  langpth = `TransLang/CARES`;

  // pth = `Trans/ROC`;
  pth = `Trans/CARES`;
  compath = 'Components/CARES';
  components: string[];
  details = false;
  coms;
  edit = false;
  comname: string;
  comdetails = false;
  msgbloc = false;
  helpbloc = false;
  msgblocks;
  helpblocks;
  eds: Edition[];
  lookup: string;

  config = {
    placeholder: '',
    tabsize: 2,
    dialogsInBody: true,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['font', ['bold', 'italic', 'underline']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
      ['codeview'],
      ['link'],
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };

  idx = [
    1002492,
    1002493,
    1002494,
    1002495,
    1002497,
    1002498,
    1002501,
    1002502,
    1002503,
    1002504,
    1002505,
    1002506,
    1002532,
    1002533,
    1002534,
    1002535,
    1002538,
    1002540,
    1002541,
    1002542,
    1002544,
    1002545,
    1002643,
    1002552,
    1002695,
    1002722,
    1002735,
    1002736,
    1002789,
  ]


  /*
    CovidPrecautions
    CovidFrequencies
    CappTeamRoles
    Races
    Genders
    Preferences
    Disabilities
    Experiences
    Focum
    AdvJob
    RepeatOptions
    Behaviors
    Topics
    Days
    Months
    Attributes
    CovidUpdates
    Sections
  */

  /*
    arr = [
      {icon: 'notifications', name: 'Notifications', value: true, desc: 'notify'},
      {icon: 'event', name: 'Events', value: true, desc: 'events'},
      {icon: 'list', name: 'Todos', value: true, desc: 'todo'},
      {icon: 'photo_camera', name: 'Exhibits', value: true, desc: 'exhibits'},
      {icon: 'insert_drive_file', name: 'Shared Drive', value: true, desc: 'drive'},
      {icon: 'date_range', name: 'Coverage', value: true, desc: 'coverage'},
      {icon: 'mood', name: 'Wellbeing', value: true, desc: 'wellbeing'},
      {icon: 'local_hospital', name: 'Medicines', value: true, desc: 'medicines'},
      {icon: 'notes', name: 'Notes', value: true, desc: 'notes'},
      {icon: 'chat', name: 'Team Chat', value: true, desc: 'chat'},
      {icon: 'chat', name: 'Member Chat', value: true, desc: 'mmmsgs'},
      {icon: 'contacts', name: 'Parties', value: true, desc: 'parties'},
      {icon: 'contacts', name: 'Contacts', value: true, desc: 'contacts'},
      {icon: 'waves', name: 'LiftUp', value: true, desc: 'liftup'}
    ];
  */

  /*  arr = [
      {name: 'Care', code: 'care'},
      {name: 'Educational', code: 'educational'},
      {name: 'Errand', code: 'errand'},
      {name: 'Legal', code: 'legal'},
      {name: 'Medical', code: 'medical'},
      {name: 'Other', code: 'other'},
      {name: 'Professional', code: 'professional'},
      {name: 'Social', code: 'social'},
    ];*/

  arr = [
    'English',
    'Spanish',
    'Chinese',
    'Vietnamese',
    'Arabic',
    'French',
    'Korean',
    'Russian',
    'German',
    'Portuguese',
    'Italian',
    'Polish'
  ];

  /*
    transArray() {
      const a = 'Sections';
      this.languages.forEach(lang => {

        this.arr.forEach(c => {
          if (lang.abbr !== 'en') {
            this.admin.postTrans(lang.abbr, c.name).then(val => {
              const x = this.arr.findIndex(h => h.name === c.name);
              this.is.setObjNoLog(`TransCon/Capp/${lang.abbr}/${a}/${x}/name`, val['data'].translations[0].translatedText);
              this.is.setObjNoLog(`TransCon/Capp/${lang.abbr}/${a}/${x}/icon`, c.icon);
              this.is.setObjNoLog(`TransCon/Capp/${lang.abbr}/${a}/${x}/desc`, c.desc);
              this.is.setObjNoLog(`TransCon/Capp/${lang.abbr}/${a}/${x}/value`, c.value);
            });


          } else {
            const x = this.arr.findIndex(h => h === c);
            this.is.setObjNoLog(`TransCon/Capp/${lang.abbr}/${a}/${x}`, c);
          }
        });


      });

    }
  */

  /*
   this.arr.forEach(c => {
          if (lang.abbr !== 'en') {
            this.admin.postTrans(lang.abbr, c.name).then(val => {
              c.name = val['data'].translations[0].translatedText;
              const x = this.arr.findIndex(h => h.name === c.name);
              this.is.setObjNoLog(`TransCon/Capp/${lang.abbr}/${a}/${x}`, c);
            });
          } else {
            const x = this.arr.findIndex(h => h.name === c.name);
            this.is.setObjNoLog(`TransCon/Capp/${lang.abbr}/${a}/${x}`, c);
          }
        });
   */


  transSimpleArray() {
    const a = 'Languages';
    this.languages.forEach(lang => {
      this.arr.forEach(c => {
        if (lang.abbr !== 'en') {
          this.admin.postTrans(lang.abbr, c).then(val => {
            const k = val['data'].translations[0].translatedText;
            const x = this.arr.findIndex(h => h === c);
            this.is.setObjNoLog(`TransCon/Capp/${lang.abbr}/${a}/${x}`, k);
          });
        } else {
          const x = this.arr.findIndex(h => h === c);
          this.is.setObjNoLog(`TransCon/Capp/${lang.abbr}/${a}/${x}`, c);
        }
      });
    });
  }


  constructor(private ts: TranslateService,
              private admin: AdminService,
              public is: InterfaceService) {
    this.is.setObjNoLog(`Marketing/individual`, this.admin.admin);

  }


  async onTrans(e: Editionx) {
    await this.languages.forEach(lang => {
      if (lang.abbr !== 'en') {
        this.admin.postTrans(lang.abbr, e.name['en'].description).then(val => {
          e.name[lang.abbr].description = val['data'].translations[0].translatedText;
        });

        this.admin.postTrans(lang.abbr, e.description['en'].description).then(val => {
          e.description[lang.abbr].description = val['data'].translations[0].translatedText;
        });
      }
    });

  }


  /*
    onSaveEd() {
      this.eds.forEach(e => {
        this.is.setObjNoLog(`Editions/${e.ukey}`, e);
      });
    }
  */

  /*
  beacons mock data

      this.is.setObjNoLog(`Beacons/00112233445566778899`,
      {name: 'Daniel NYC',
        uuid: '00112233445566778899',
        lat: 40.7667891,
        long: -73.9697749,
        logo: 'https://s3.amazonaws.com/ideaworksite/daniellogocopy-1564786397571.svg'})

   */


  ngOnInit() {
    this.is.getList(`Ids`).subscribe(val => {
      const lst = val.filter(v => v.idonly.includes('arisaemat@gmail.com'))
      console.log('ids', lst)
    })
    // kwab cell: +
    // -MCFVv-ZWiP99gkr9zvw  1004193
    // -M90vORAvGP9bq37sK-v 1003916
    // -MFSl_H7k5V158UVc-WM 1004618
    // -MR0ZEQWvxbXctxFUCnT 1005679
    // 1005452
/*
    console.log('inst')
    this.is.getQuery(`Individual`, 'admin', '-MKC5ODnwdCQ6UMi69tV')
      .take(1)
      .subscribe(results => {
        console.log('results', results)
      })
*/



/*
    console.log('trying to delete');
    for (let x = 0; x < 100; x++) {
      console.log('path', `ROEDevices/${x + 1005578}`);
      this.is.deleteObjNoLog(`ROEDevices/${x + 1005578}`);
    }

*/





    /*
          const e = new Editionx();
          e.code = 'Predictions';
          e.ukey = 'Predictions';
          e.active = true;
          e.name = new LangStringBlock();
          e.name['en'].description = 'Predictions';
          e.description = new LangStringBlock();
          e.description['en'].description = 'How well can you predict the future';
          console.log('e', e)
          e.logo = '';
          e.order = 7;
          this.onTrans(e);
          this.is.setObjNoLog(`Editions/Predictions`, e)
          console.log('edsition', e)
    */

    /* this.eds.forEach(e => {
       this.onTrans(e);
       console.log('e', e);
     });*/


    this.$sub2 = this.is.getList(`${this.compath}/list`).subscribe(coms => {
      if (coms && coms.length > 0) {
        this.components = _.orderBy(coms, 'asc');
      } else {
        this.components = [];
      }
    });

    this.$sub1 = this.is.getListWithUkeys(`${this.pth}/en`).subscribe(l => {
      this.msgs = l;
    });

    this.$sub3 = this.is.getListWithUkeys(`${this.langpth}`).subscribe(l => {
      this.msgblocks = l;
    });

    this.$sub4 = this.is.getListWithUkeys(`TransHelp`).subscribe(l => {
      this.helpblocks = l;
    });

  }

  onAddComponent() {
    this.comname = '';
    this.comdetails = true;
  }

  onDelComponent(i) {
    this.is.deleteObjNoLog(`${this.compath}/list/${this.components[i]}`);
  }

  onSaveComponent() {
    this.is.setObjNoLog(`${this.compath}/list/${this.comname}`, this.comname);
    this.comdetails = false;
  }

  onSave() {
    if (this.compath && this.code) {
      if (this.coms) {
        this.is.setObjNoLog(`${this.compath}/codes/${this.code}`, this.coms.join(','));
      }

      // this must trigger a cloud function???
      if (this.msgbloc) {
        const m = new LangStringBlock();
        m['en'].description = this.msg;
        if (this.edit) {
          this.is.deleteObjNoLog(`${this.langpth}/${this.code}`);
        }
        this.is.setObjNoLog(`${this.langpth}/${this.code}`, m);
      } else if (this.helpbloc) {
        const m = new LangStringBlock();
        m['en'].description = this.msg;
        if (this.edit) {
          this.is.deleteObjNoLog(`TransHelp/${this.code}`);
        }
        this.is.setObjNoLog(`TransHelp/${this.code}`, m);
      } else {
        this.is.setObjNoLog(`${this.pth}/en/${this.code}`, this.msg);
      }
      this.details = false;
    }
  }

  onEditMsg(i) {
    this.edit = true;
    this.msgbloc = false;
    this.helpbloc = false;

    this.coms = [];
    this.code = this.msgs[i].ukey;
    this.msg = this.msgs[i].val;
    console.log('path', this.compath)
    console.log('msg', this.msg)
    this.is.getObject(`${this.compath}/codes/${this.code}`)
      .take(1)
      .subscribe(coms => {
        if (coms) {
          this.coms = coms.split(',');
        }
      });
    this.details = true;
  }

  onEditMsgBlock(i) {
    this.edit = true;
    this.msgbloc = true;
    this.helpbloc = false;

    this.coms = [];
    this.code = this.msgblocks[i].ukey;
    this.msg = this.msgblocks[i].val['en'].description;
    this.is.getObject(`${this.compath}/codes/${this.code}`)
      .take(1)
      .subscribe(coms => {
        if (coms) {
          this.coms = coms.split(',');
        }
      });
    this.details = true;
  }

  onEditHelpBlock(i) {
    this.edit = true;
    this.msgbloc = false;
    this.helpbloc = true;
    this.coms = [];
    this.code = this.helpblocks[i].ukey;
    this.msg = this.helpblocks[i].val['en'].description;
    this.is.getObject(`${this.compath}/codes/${this.code}`)
      .take(1)
      .subscribe(coms => {
        if (coms) {
          this.coms = coms.split(',');
        }
      });
    this.details = true;
  }

  onAddMsg() {
    this.msgbloc = false;
    this.helpbloc = false;
    this.edit = false;
    this.details = true;
    this.code = '';
    this.msg = '';
  }

  onAddMsgBlock() {
    this.msgbloc = true;
    this.helpbloc = false;
    this.edit = false;
    this.details = true;
    this.code = '';
    this.msg = '';
  }

  onLookupHelp() {
    if (this.lookup) {
      const m = this.helpblocks.find(h => h.ukey === this.lookup);
      if (m) {
        this.edit = true;
        this.msgbloc = false;
        this.helpbloc = true;
        this.coms = [];
        this.code = m.ukey;
        this.msg = m.val['en'].description;
        this.is.getObject(`${this.compath}/codes/${this.code}`)
          .take(1)
          .subscribe(coms => {
            if (coms) {
              this.coms = coms.split(',');
            }
          });
        this.details = true;
      }
    }
  }

  onAddHelp() {
    this.helpbloc = true;
    this.msgbloc = false;
    this.edit = false;
    this.details = true;
    this.code = '';
    this.msg = '';
  }

  onDelMsg(i) {
    this.is.deleteObjNoLog(`${this.pth}/en/${this.msgs[i].ukey}`);
  }

  onDelMsgBlock(i) {
    this.is.deleteObjNoLog(`${this.langpth}/${this.msgblocks[i].ukey}`);
  }

  onDelHelpBlock(i) {
    if (this.helpblocks[i].ukey) {
      this.is.deleteObjNoLog(`TransHelp/${this.helpblocks[i].ukey}`);
    }
  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
    if (this.$sub3) {
      this.$sub3.unsubscribe();
    }
    if (this.$sub4) {
      this.$sub4.unsubscribe();
    }
  }

}
