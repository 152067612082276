import {Component, Input, OnInit} from '@angular/core';
import {CappButton} from '../services/invite.service';

@Component({
  selector: 'app-invite-button',
  templateUrl: './invite-button.component.html',
  styleUrls: ['./invite-button.component.scss']
})
export class InviteButtonComponent implements OnInit {
  @Input()
  button: CappButton;

  @Input()
  buttonname: string;

  constructor() { }

  ngOnInit() {
  }

}
