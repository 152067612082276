import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {ActivatedRoute, Router} from '@angular/router';
import {Admin, AdminService} from '../../services/admin.service';
import {InterfaceService} from '../../services/interface.service';
import {StorageService} from '../../services/storage.service';
import {RoehttpService} from '../../services/roehttp.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    type: string;
    ad: any;
    $sub1: any;
    $sub2: any;

    loc: string;
    dospin = false;
    step = 0;

    test = new EventEmitter<boolean>();

    constructor(private afAuth: AngularFireAuth,
                private route: ActivatedRoute,
                private admin: AdminService,
                private is: InterfaceService,
                private http: RoehttpService,
                private router: Router) {
        this.route.params.take(1).subscribe(params => {
            this.type = params.type;
            this.loc = params.loc;
        });
    }

    ngOnInit() {
    }

    logout() {
        this.afAuth.auth.signOut();
    }

    onFinish() {
        this.admin.admin.org = this.admin.admin.orgs[0].organ;
        this.admin.getIncidentAlerts();
        this.admin.admin.modules = this.admin.admin.orgs[0].modules;
        this.admin.loggedin = true;

        this.dospin = false;
        if (this.admin.admin.type === 'Colleges' || this.admin.admin.type === 'Admin') {
            if (this.admin.admin.deviceid) {
                this.router.navigateByUrl('profile');
            } else {
                if (this.is.devicePlatform === 'ios' || this.is.devicePlatform === 'android') {
                    // todo: use get storage to see if there is a roe deviceid
                } else {
                    // todo: need to get the user to 1: download ROE and 2: log into the console again
                }
            }
        } else if (this.admin.admin.type === 'Healthcare') {
            this.router.navigateByUrl('healthparent');
        } else if (this.admin.admin.type === 'Community') {
            this.router.navigateByUrl('/comparent');
        } else {
            this.router.navigateByUrl('clerydashboard'); // this is security
        }
    }

    // this function is called by auth component signup method to complete the login after the first time a new admin logs in
    onComplete(ad: Admin) {
      this.admin.language = 'en';
      this.admin.languageon = true;
      this.admin.fulllanguage = 'English';
      this.dospin = true;
      this.admin.admin = ad;
      this.onFinish();
    }

    onSuccess(event) {
        this.admin.language = 'en';
        this.admin.languageon = true;
        this.admin.fulllanguage = 'English';
        this.dospin = true;

        this.is.getObject(`Marketing/${this.afAuth.auth.currentUser.uid}`)
          .subscribe(ad => {
            if (ad) {
                this.admin.admin = ad;
                this.onFinish();
            } else {
                console.log('got here y')
            }
        }, error => {
            console.log('errror fail', error)
          });

    }

    onMarketing(ad: Admin) {
        this.admin.admin = ad;
        this.admin.admin.org = this.admin.admin.orgs[0].organ;
        this.admin.getIncidentAlerts();
        this.admin.admin.modules = this.admin.admin.orgs[0].modules;

        if (this.admin.admin.type === 'Colleges' || this.admin.admin.type === 'Admin') {
           this.admin.loggedin = true;
           this.router.navigateByUrl('profile');

/*          don't need this yet; need when trying to link an admin to a device
            if (this.admin.admin.deviceid > -1) {
                this.admin.loggedin = true;
                this.router.navigateByUrl('profile');
            } else {
                StorageService.getItem('roe-device_id').then(roeDeviceId => {
                    if (roeDeviceId.value && roeDeviceId.value !== 'undefined') {
                        this.admin.admin.deviceid = parseInt(roeDeviceId.value, 10);
                        this.http.setField(`OrgsPri/${this.admin.admin.org}/marketing/${this.admin.admin.ukey}`, this.admin.admin);
                        // todo: marketing not updated ????
                        this.admin.loggedin = true;
                        this.router.navigateByUrl('profile');
                    } else {
                        if (this.is.devicePlatform === 'ios' || this.is.devicePlatform === 'android') {
                            this.step = 1;
                        } else {
                            // get the user to  log into the console on their smartphone
                            this.step = 2;
                        }
                    }
                });
            }
*/


        } else if (this.admin.admin.type === 'Healthcare') {
            this.admin.loggedin = true;
            this.router.navigateByUrl('healthparent');
        } else if (this.admin.admin.type === 'Community') {
            this.admin.loggedin = true;
            this.router.navigateByUrl('comparent');
        } else {
            this.admin.loggedin = true;
            this.router.navigateByUrl('clerydashboard');
        }

        if (this.admin.admin.type === 'Admin') {
            this.is.nextCom()
        }
    }

    // 1002646

    onSignInSuccess(event) {
        // event is the email a person signed in with
        const user = this.afAuth.auth.currentUser;
        this.admin.languageon = true;
        this.admin.language = 'en';
        this.admin.fulllanguage = 'English';
        // todo: use a callable function to get ad and then tighten security rules for Marketing allowing neither reading nor writing
        console.log('looking for', `Marketing/${this.afAuth.auth.currentUser.uid}`)
        this.is.getObject(`Marketing/${this.afAuth.auth.currentUser.uid}`)
          .take(1)
          .subscribe(ad => {
            if (ad) {
                if (ad.firebaseuid === user.uid) {
                    this.onMarketing(ad);
                } else {
                    this.admin.loggedin = true;
                    // todo: none of this has been tested!!!!!!!!!!!!
                    if (ad.orgs && ad.orgs[0] && ad.orgs[0].organ) {
                        if (ad.type === 'Community') {
                            // this write should trigger this cloud function: adminComJrUpdate
                            // todo: this need to be updateObj
                            this.http.setField(`Community/${ad.orgs[0].organ}/marketing/${ad.ukey}/firebaseuid`, user.uid);
                        } else {
                            this.http.setField(`Marketing/${ad.ukey}/firebaseuid`, user.uid);
                            console.log('path', `OrgsPri/${ad.orgs[0].organ}/marketing/${ad.ukey}/firebaseuid`)
                            this.http.setField(`OrgsPri/${ad.orgs[0].organ}/marketing/${ad.ukey}/firebaseuid`, user.uid);
                        }
                    }
                }
            } else {
                console.log('Cant find');
            }
        });

    }

    ngOnDestroy() {
    }


}
