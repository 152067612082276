import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CappTeamMsg, InterfaceService, LangStringBlock, LMSNotification, Topix} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-corp-notifications',
  templateUrl: './corp-notifications.component.html',
  styleUrls: ['./corp-notifications.component.scss']
})
export class CorpNotificationsComponent implements OnInit, OnDestroy {
  @Input()
  caller: string;

  @Input()
  pth = 'CorpsPri';

  @Input()
  topics: Topix[];


  details = false;
  notes: CappTeamMsg[];
  curmsg: CappTeamMsg;
  $sub1;
  // todo: temporary cludge; should use the Topix structure
  topic;
  notetype: string;
  err: string;
  link: string;
  note: string;
  surveys;
  survey;
  guides;
  milestones;
  pthy: string;

  // @ViewChild('gallery') private gallery: GalleryComponent;


  constructor(private admin: AdminService,
              public is: InterfaceService) {
  }

  ngOnInit() {
    if (this.topics) {
      this.topic = this.topics[0];
      this.onSelChange();
    } else {
      this.is.getList(`${this.pth}/${this.admin.admin.org}/topics`).take(1).subscribe(ts => {
        this.topics = ts;
        if (this.topics && this.topics.length === 1) {
          this.topic = this.topics[0];
        }
        this.onSelChange();
      });
    }

    if (!this.is.gl || this.is.gl.length === 0) {
      this.is.getOrgGuideList();
    }

    this.is.getList(`${this.pth}/${this.admin.admin.org}/surveys`).take(1).subscribe(surveys => {
      this.surveys = surveys;
    });
  }

  onSelChange() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }

    if (this.topic) {
      if (this.topic.ukey) {
        this.pthy = `${this.caller}/${this.topic.ukey}`;
      } else if (this.caller.includes('OrgsPri')) {
        let ed;
        this.is.eds.forEach(e => {
          if (e.topics && e.topics.length > 0) {
            e.topics.forEach(t => {
              if (t === this.topic) {
                ed = e.ukey;
              }
            });
          }
        })
        this.pthy = `${this.caller}/templates/${ed}`;
        this.is.getList(`OrgsPri/${this.admin.admin.org}/milestonedefs/${ed}`)
          .take(1)
          .subscribe(ms => {
            this.milestones = ms;
          });


      } else {
        return;
      }
    } else {
      return;
    }
    this.$sub1 = this.is.getList(this.pthy).subscribe(notes => {
      this.notes = [];
      if (notes && notes.length > 0) {
        this.notes = _.orderBy(notes, ['date'], ['desc']);
        /*
                this.notes.forEach(n => {
                  if (!n.ssoc) {
                    n.ssoc = new LangStringBlock();
                    n.ssoc['en'].description = n.dl;
                  }
                });
        */


      }
    });
  }

  getTitle(n: CappTeamMsg): string {
    if (n.ssoc) {
      return n.ssoc['en'].description;
    } else if (n.s) {
      return n.s;
    } else if (n.dl) {
      return n.dl;
    } else {
      return 'No subject';
    }
  }

  onAdd() {
    this.curmsg = null;
    this.notetype = '';
    this.details = true;
  }

  onDelete(i) {
    this.is.deleteObjNoLog(`${this.pthy}/${this.notes[i].ukey}`);
    this.notes.splice(i, 1);
  }

  onEdit(n, i) {
    this.curmsg = n;
    if (n.date === 9000000000000000) {
      this.notetype = this.curmsg.ty;
      if (this.curmsg.ty === 'survey') {
        this.survey = this.surveys.find(s => this.curmsg.dl.includes(s.ukey));
      }
      this.details = true;
    }
  }


  ngOnDestroy(): void {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
  }
}
