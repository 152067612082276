import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RoeTemplateComponent} from '../roe-template/roe-template.component';
import {InterfaceService} from '../../services/interface.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent extends RoeTemplateComponent implements OnInit {
  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  primarycolor: string;
  secondarycolor: string;

  constructor(public is: InterfaceService,
              private cd: ChangeDetectorRef,
              private db: AngularFireDatabase,
              public admin: AdminService) {
    super(admin, is);
    this.primarycolor = this.admin.getPrimary();
    this.secondarycolor = this.admin.getSecondary();
  }

  ngOnInit() {
    super.ngOnInit();

  }

}
