import {Component, OnInit} from '@angular/core';
import {InterfaceService} from '../../../services/interface.service';
import {MatTableDataSource} from '@angular/material';
import * as _ from 'lodash';
import {AdminService} from '../../../services/admin.service';

@Component({
    selector: 'app-using-guides',
    templateUrl: './using-guides.component.html',
    styleUrls: ['./using-guides.component.scss']
})
export class UsingGuidesComponent implements OnInit {
    pSource = new MatTableDataSource([]);
    pColumns = ['date', 'user', 'guide'];
    contact;
    user: string;

    uSource = new MatTableDataSource([]);
    uColumns = ['date', 'guidename', 'sponsor'];
    parentusages;
    usages;
    curlang: string;
    pos = -1

    constructor(public admin: AdminService,
                public is: InterfaceService) {
        this.curlang = this.admin.language;
    }

    ngOnInit() {
        this.is.curComOrg.guideusers =  _.orderBy(this.is.curComOrg.guideusers, 'date', 'desc');
        this.pSource.data = this.is.curComOrg.guideusers

        this.is.getList(`Community/${this.is.curComOrg.ukey}/roeguideusages`).take(1).subscribe(usages => {
            this.parentusages = usages;
        });

    }

    onSelect(i) {
        this.pos = i;
        this.user = this.is.curComOrg.guideusers[i].user;
        this.is.getObject(`GuideContact/${this.is.curComOrg.guideusers[i].user}`).take(1).subscribe(c => {
            this.contact = c;
        })

        this.usages = this.parentusages.filter(p => p.sponsor === this.user);
        this.uSource = this.usages;


    }


    sortData(event) {
        this.is.curComOrg.guideusers = _.orderBy(this.is.curComOrg.guideusers, event.active, event.direction)
        this.pSource = this.is.curComOrg.guideusers;
    }


    sortUData(event) {
        this.usages = _.orderBy(this.usages, event.active, event.direction)
        this.uSource = this.usages;
    }


}
