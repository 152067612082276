import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InterfaceService, OrgEdition, ROEUser} from '../services/interface.service';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {FirebaseService} from '../services/firebase.service';
import {AdminService} from '../services/admin.service';
import {Papa} from 'ngx-papaparse';
import {RoehttpService} from '../services/roehttp.service';
import {ConfirmationComponent} from '../lib/confirmation/confirmation.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  @Input()
  cured: OrgEdition;

  $sub1;
  search: string;
  filter: string;
  users: ROEUser[];
  userAll = 0;

  userSource = new MatTableDataSource([]);
  // todo: need to add a studentid field
  userColumns = [ 'photo', 'name', 'email', 'lastread', 'deviceId', 'status', 'delete'];


  d: number;

  constructor(private is: InterfaceService,
              private fs: FirebaseService,
              private dialog: MatDialog,
              private http: RoehttpService,
              private admin: AdminService,
              private papa: Papa) {
    this.d = new Date().getTime();
  }

  countDays(date): number {
    if (date) {
      return Math.trunc((this.d - date) / (24 * 60 * 60 * 1000));
    } else {
      return;
    }
  }


  onDelete(i) {
    console.log('i', i, this.userSource.data[i])
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '300px',
      data: {text: 'Are you sure you want to delete this user? NOT YET IMPLEMENTED'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.answer) {
        console.log('deviceId',  this.userSource.data[i].deviceId)
        console.log('org', this.admin.admin.org);
        console.log('appukey', this.cured.ukey);
        this.http.deleteOrgUser(this.userSource.data[i].deviceId,  this.admin.admin.org, this.cured.ukey);
      }
    });


  }


  onFill() {
    this.$sub1 = this.is.getList(`OrgsPri/${this.admin.admin.org}/ROEDevices/${this.cured.ukey}`)
      .take(1)
      .subscribe(users => {
      this.search = '';
      this.filter = 'All';
      this.users = users;

      this.userSource.data = this.users;
      if (this.users.length) {
        this.userAll = this.users.length;
      }
    });
  }

  ngOnInit() {
    this.onFill();
  }

  ngOnDestroy(): void {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
  }

  onStatus(status: boolean, i) {
    if (status) {
      // restore the user
      this.userSource.data[i].status = null;
      this.is.deleteObjNoLog(`OrgsPri/${this.admin.admin.org}/ROEDevices/${this.cured.ukey}/${this.userSource.data[i].deviceId}/status`);
    } else {
      // block the user
      this.userSource.data[i].status = 'block';
      this.is.setObjNoLog(`OrgsPri/${this.admin.admin.org}/ROEDevices/${this.cured.ukey}/${this.userSource.data[i].deviceId}/status`, 'block');
    }
    this.http.updateStatus(status, this.userSource.data[i].deviceId, this.cured.ukey)
  }

}
