import {ChangeDetectorRef, Injectable} from '@angular/core';
import {
  GlobalConfig,
  InstOrg,
  InstProfile,
  InterfaceService,
  JobList,
  LangStringBlock,
  Links,
  LocationData,
  OrgEdition
} from './interface.service';
import {AdminService} from './admin.service';
import {AngularFireDatabase} from '@angular/fire/database';
import * as _ from 'lodash';
import {GeofireService} from './geofire.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstService {
  curInstOrg: InstOrg;
  eds;
  prefix = 'Inst';
  priprefix = 'InstPri';
  tempprefix = 'Inst'; // should be InstTemp
  updateprofile = false;
  updateeditions = false;
  updateeds = false;
  updatelocations = false;
  updatelinks = false;
  updateglobal = false;

  _curinst = new BehaviorSubject<string>(null);
  curinst$ = this._curinst.asObservable();

  constructor(private is: InterfaceService,
              private db: AngularFireDatabase,
              private geo: GeofireService,
              public admin: AdminService) { }

  getArchive() {
    this.is.getObject(`${this.prefix}/${this.admin.admin.org}/archive`)
      .take(1)
      .subscribe(arch => {
        if (arch) {
          this.curInstOrg.archive = arch;
        } else {
          this.curInstOrg.archive = {};
        }
      });
  }

  refreshEditions() {
    this.curInstOrg.editions = null;
    this.is.getList(`${this.prefix}/${this.admin.admin.org}/editions`)
      .take(1)
      .subscribe(pe => {
        this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/editions`, pe)
        this.curInstOrg.editions = _.cloneDeep(pe);
        if (pe && pe.length > 0) {
          this.eds = [];
          pe.forEach(p => {
            this.is.getObject(`${this.prefix}/${this.admin.admin.org}/${p.ukey}`)
              .take(1)
              .subscribe(e => {
                this.eds.push(e);
                this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/${e.ukey}`, e);
              });
          });
        }

        this.updateeditions = false;
      });
  }

  getEditions() {
    this.is.getList(`${this.tempprefix}/${this.admin.admin.org}/editions`)
      .take(1)
      .subscribe(pr => {
        if (pr) {
          this.curInstOrg.editions = pr;
          if (pr && pr.length > 0) {
            this.eds = [];
            pr.forEach(p => {
              this.is.getObject(`${this.tempprefix}/${this.admin.admin.org}/${p.ukey}`)
                .take(1)
                .subscribe(e => {
                  console.log('e', e)
                  this.eds.push(e);
                })
            })
          }
          this.updateprofile = true;
        } else {
          this.refreshEditions();
        }
      });
  }



  refreshLinks() {
    this.curInstOrg.links = new Links();
    this.is.getList(`${this.prefix}/${this.admin.admin.org}/links`)
      .take(1)
      .subscribe(p => {
        if (p) {
          this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/links`, p)
          this.curInstOrg.links = _.cloneDeep(p);
        } else {
          this.curInstOrg.links = new Links();
        }
        this.updatelinks = false;
      });
  }

  getLinks() {
    this.is.getObject(`${this.tempprefix}/${this.admin.admin.org}/links`)
      .take(1)
      .subscribe(pr => {
        if (pr) {
          this.curInstOrg.links = pr;
          this.updatelinks = true;
        } else {
          console.log('lins');
          this.refreshLinks();
        }
      });
  }

  refreshProfile() {
    this.curInstOrg.profile = null;
    this.is.getObject(`${this.prefix}/${this.admin.admin.org}/profile`)
      .take(1)
      .subscribe(p => {
        if (p) {
          this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/profile`, p)
          this.curInstOrg.profile = _.cloneDeep(p);
          this.admin.admin.orgname = this.curInstOrg.profile.name;
          if ( this.curInstOrg.profile.curlang) {
            this.admin.curlang = this.curInstOrg.profile.curlang;
          } else {
            this.admin.curlang = 'en';
          }
        } else {
          this.curInstOrg.profile = new InstProfile(this.admin.curlang);
          this.admin.curlang = 'en';
        }
        this.updateprofile = false;
      });
  }

  getProfile() {
    this.is.getObject(`${this.tempprefix}/${this.admin.admin.org}/profile`)
      .take(1)
      .subscribe(pr => {
        if (pr) {
          this.curInstOrg.profile = pr;
          this.admin.curlang = this.curInstOrg.profile.curlang;
          this.admin.admin.orgname = this.curInstOrg.profile.name;
          this.updateprofile = true;
        } else {
          this.refreshProfile();
        }
      });
  }

  refreshGlobal() {
    this.curInstOrg.global = null;
    this.is.getObject(`${this.prefix}/${this.admin.admin.org}/global`)
      .take(1)
      .subscribe(p => {
        if (p) {
          this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/global`, p)
          this.curInstOrg.global = _.cloneDeep(p);
        } else {
          this.createGlobal();
        }
        this.updateglobal = false;
      });
  }

  getGlobal() {
    this.is.getObject(`${this.tempprefix}/${this.admin.admin.org}/global`)
      .take(1)
      .subscribe(gl => {
        if (gl) {
          this.curInstOrg.global = gl;
          this.updateglobal = true;
        } else {
          this.refreshGlobal();
        }
      });
  }

  refreshLocations() {
    this.is.getObject(`${this.prefix}/${this.admin.admin.org}/locations`)
      .take(1)
      .subscribe(l => {
        if (l) {
          this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/locations`, l)
          this.curInstOrg.locations = l;
        } else {
          console.log('what to do here')
        }
        this.updatelocations = false;
      });
  }

  getLocations() {
    this.is.getList(`${this.tempprefix}/${this.admin.admin.org}/locations`)
      .take(1)
      .subscribe(locs => {
        if (locs) {
          this.curInstOrg.locations = locs;
          this.updatelocations = true;
        } else {
          this.refreshLocations();
        }
      });
  }

  getCurInstOrg() {
    // todo: use the tempprefix
    this.is.getObject(`${this.prefix}/${this.admin.admin.org}/ukey`)
      .take(1)
      .subscribe(instukey => {
        if (instukey) {
          this._curinst.next(this.admin.admin.org);

          this.curInstOrg = new InstOrg();
          this.curInstOrg.ukey = instukey;
          this.getArchive();
          this.getProfile();

          this.getLocations();
          this.getEditions();
          console.log('instukey', instukey)
          this.getLinks();
          console.log('links')
          this.getGlobal();
          if (!this.is.contacts || this.is.contacts.length === 0) {
            this.is.getContacts(this.curInstOrg.ukey);
          }
        } else {
          this.curInstOrg = new InstOrg();
          this.curInstOrg.ukey = this.db.createPushId();
          this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/admin/${this.admin.admin.firebaseuid}`, this.admin.admin.ukey);
          this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/admin/${this.admin.admin.firebaseuid}`, this.admin.admin.ukey);
          this.is.setObjNoLog(`${this.priprefix}/${this.admin.admin.ukey}/admin/${this.admin.admin.firebaseuid}`, this.admin.admin.ukey);
          this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/ukey`, this.curInstOrg.ukey);
          this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/ukey`, this.curInstOrg.ukey);
          this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/profile`, this.curInstOrg.profile);
          this.getProfile();
          this.getLocations();
        }
      });
  }

  delLocation(ukey) {
    this.is.deleteObjNoLog(`Locations/${ukey}`);
  }

  delLoc(location: LocationData) {
    this.updatelocations = true;
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/locations/${location.ukey}`, location);
  }

  onSaveEdition(orgedition: OrgEdition) {
    this.updateeditions = true;
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/${orgedition.ukey}/ukey`, orgedition.ukey);
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/editions/${orgedition.ukey}`, orgedition);
  }

  onDelEdition(ukey) {
    const x = this.curInstOrg.editions.findIndex(e => e.ukey === ukey);
    if (x > -1) {
      this.curInstOrg.editions.splice(x, 1);
    }
    this.is.deleteObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/${ukey}`);
    this.is.deleteObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/editions/${ukey}`);
  }

  saveProfileGlobal(checked) {
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/profile/globaldir`, checked);
  }

  saveProfile(profile: InstProfile) {
    this.updateprofile = true;
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/profile`, profile);
  }

  saveLinks(links: Links) {
    this.updatelinks = true;
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/links`, links);
  }

  createGlobal() {
    this.curInstOrg.global = new GlobalConfig();
    this.curInstOrg.global.name = this.curInstOrg.profile.name;
    if (this.curInstOrg.profile.desc) {
      this.curInstOrg.global.desc = this.curInstOrg.profile.desc;
      this.curInstOrg.global.showdesc = true;
    }
    if (this.curInstOrg.profile.attributes) {
      this.curInstOrg.global.attributes = this.curInstOrg.profile.attributes;
    }
    if (this.curInstOrg.profile.smalllogo) {
      this.curInstOrg.global.smalllogo = this.curInstOrg.profile.smalllogo;
      this.curInstOrg.global.logo = true;
    }
    this.is.getObject(`${this.tempprefix}/${this.admin.admin.org}/links`)
      .take(1)
      .subscribe(l => {
        this.curInstOrg.global.links = l;
      })

    this.curInstOrg.global.editionukey = '';
    this.curInstOrg.global.topicukey = '';
    this.curInstOrg.global.profileukey = '';
    this.curInstOrg.global.orgtype = 'Inst'; // eg. Inst, Orgs, Community
    this.curInstOrg.global.objectID = this.curInstOrg.ukey;  // indivukey for an individual - also used by Algolia index

    if (this.curInstOrg.global.name) {
      this.saveGlobal(this.curInstOrg.global);
    }
  }

  deleteGlobal() {
    this.updateglobal = true;
    this.is.deleteObjNoLog(`Global/${this.curInstOrg.global.objectID}`)
    this.is.deleteObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/global`);
  }

  saveGlobal(global: GlobalConfig) {
    this.updateglobal = true;
    if (global.lat !== -1) {
      this.geo.setGlobal(global.objectID, [global.lat, global.long]);
    }
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/global`, global);
  }

  saveLocations() {
    this.updatelocations = true;
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/locations`, this.curInstOrg.locations);
  }

  saveEds() {
    this.updateeds = true;
    if (this.eds && this.eds.length > 0) {
      this.eds.forEach(e => {
        this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/${e.ukey}`, e);
      })
    }
  }

  onPublishEds() {
    this.updateeds = false;
    if (this.eds && this.eds.length > 0) {
      this.eds.forEach(e => {
        this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/${e.ukey}`, e);
        this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/${e.ukey}`, e);
      });
    }
  }

  onPublishLinks() {
    this.updateprofile = false;
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/links`, this.curInstOrg.links);
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/links`, this.curInstOrg.links);
  }

  onPublishProfile() {
    this.updateprofile = false;
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/profile`, this.curInstOrg.profile);
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/profile`, this.curInstOrg.profile);
  }

  onPublishLocations() {
    this.updatelocations = false;
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/locations`, this.curInstOrg.locations);
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/locations`, this.curInstOrg.locations);
  }

  onPublishGlobal() {
    this.updateglobal = false;
    this.is.setObjNoLog(`${this.tempprefix}/${this.admin.admin.org}/global`, this.curInstOrg.global);
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/global`, this.curInstOrg.global);
  }

  onSaveArchiveVideos(videos) {
    if (!this.curInstOrg.archive) {
      this.curInstOrg.archive = {};
    }
    this.curInstOrg.archive.videos = videos;
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/archive/videos`, this.curInstOrg.archive.videos);
  }

  onSaveArchiveGraphics(graphics) {
    if (!this.curInstOrg.archive) {
      this.curInstOrg.archive = {};
    }
    this.curInstOrg.archive.graphics = graphics;
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/archive/graphics`, this.curInstOrg.archive.graphics);
  }

  onSaveArchiveArchive(objs) {
    if (!this.curInstOrg.archive) {
      this.curInstOrg.archive = {};
    }
    this.curInstOrg.archive.archive = objs;
    this.is.setObjNoLog(`${this.prefix}/${this.admin.admin.org}/archive/documents`, this.curInstOrg.archive.archive);
  }


}
