import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  homeoffset: number;
  myoffset: number;
  tzoff: number;

  constructor(private http: HttpClient) {

  }

  calcOffsets(long, lat) {
    const path = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=${new Date().getTime()}&key=${environment.GOOGLE_MAPS_KEY}`
    console.log('path', path)
    this.http.get(path)
      .take(1)
      .subscribe(data => {
        console.log('time data', data);
        this.homeoffset = data['rawOffset'] * 1000 + + data['dstOffset'] * 1000;
      });
    this.myoffset = new Date().getTimezoneOffset();
    this.tzoff = -1 * 60 * 60 * 1000;
    console.log('tzoff', this.tzoff);
    console.log('myoffset', this.myoffset);
  }


}
