import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import {CAMolyVal} from '../../../services/ca';
import {InterfaceService} from '../../../services/interface.service';
import {AddressType} from '../../ca-services/ca-constants';
import {CaServices} from '../../ca-services/ca-services';
import {ConfirmationComponent} from '../../../lib/confirmation/confirmation.component';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
    @Input()
    label: string;

    @Input()
    iduser: string;

    @Input()
    val: string;

    @Input()
    formid: string;

    @Input()
    validator: string;

    @Input()
    vukey: string;

    @Input()
    parent: CAMolyVal;

    @Output()
    close = new EventEmitter();

    adtypes = AddressType;


    form = new FormGroup({
        adtype: new FormControl(''),
        street1: new FormControl(''),
        street2: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        zip: new FormControl(''),
        country: new FormControl(''),
        county: new FormControl(''),
        long: new FormControl(''),
        lat: new FormControl(''),
        note: new FormControl('')
    });

    get lat() {
        return this.form.get('lat');
    }

    get long() {
        return this.form.get('long');
    }

    get adtype() {
        return this.form.get('adtype');
    }

    get street1() {
        return this.form.get('street1');
    }

    get street2() {
        return this.form.get('street2');
    }

    get city() {
        return this.form.get('city');
    }

    get state() {
        return this.form.get('state');
    }

    get zip() {
        return this.form.get('zip');
    }

    get country() {
        return this.form.get('country');
    }

    get county() {
        return this.form.get('county');
    }

    get note() {
        return this.form.get('note');
    }

    constructor(private gs: CaServices,
                private dialog: MatDialog,
                private fs: InterfaceService) {
    }

    async ngOnInit() {
        console.log('val', this.val)
        if (this.val) {
            const carr = this.val.split(',');
            this.gs.getValue(this.iduser, carr[0]).then(val => {
                this.street1.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[1]).then(val => {
                this.street2.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[2]).then(val => {
                this.city.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[3]).then(val => {
                this.state.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[4]).then(val => {
                this.zip.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[5]).then(val => {
                this.country.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[6]).then(val => {
                this.county.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[7]).then(val => {
                this.adtype.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[8]).then(val => {
                this.long.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[9]).then(val => {
                this.lat.setValue(val);
            });
            this.gs.getValue(this.iduser, carr[10]).then(val => {
                this.note.setValue(val);
            });
        }
    }

    getId(str): string {
        const x = this.gs.metadata.findIndex(m => m.label === str);
        return this.gs.metadata[x].idukey;
    }

    saveMolyVal(ukey, str, value, parent, cdate) {
        const tmp = new CAMolyVal();
        tmp.value = [];
        if (!value) {
            tmp.value.push({val: ''});
        } else {
            tmp.value.push({val: value});
        }
        const id = this.getId(str);
        tmp.idukey = id;
        tmp.label = str;
        tmp.u = ukey;
        tmp.cdate = cdate; // this is the date the val was edited or created
        tmp.cukey = this.iduser; // this is the ukey of the data creator todo: this is a cludge
        tmp.vdate = -1; // this is the date the val was validated
        tmp.vukey = ''; // this is the ukey of the data validator
        tmp.cukeys = []; // array of forms that can create the atom val
        tmp.rukeys = []; // array of forms that can read the atom val
        tmp.uukeys = []; // array of forms that can update the atom val
        tmp.dukeys = []; // array of forms that can delete the atom val

        this.fs.setObjNoLog(`${this.gs.path}/${this.iduser}/data/${tmp.u}/${cdate}`, tmp);

        // only need to set the datamap if the moly is the parent which means it has no parent
        if (!parent) {
            if (!this.val) {
                this.fs.setObjNoLog(`${this.gs.path}/${this.iduser}/datamap/${this.fs.getId()}`,
                    {idukey: id, ukey: tmp.u, val: this.street1.value});
            }  this.fs.getQuery(`${this.gs.path}/${this.iduser}/datamap`, 'idukey', id)
                .pipe(take(1))
                .subscribe(h => {
                    if (h) {
                        const x = h.findIndex(hh => hh.ukey === this.formid);
                        this.fs.setObjNoLog(`${this.gs.path}/${this.iduser}/datamap/${h[x]['ukeyx']}/val`, this.street1.value);
                    }
                });
        }

        // user this to store parent string
        return tmp.u;
    }


    public onSave() {
        const cdate = new Date().getTime();
        let pntr = '';
        if (this.val) {
            // if this.val has a value then editting an existing address
            const carr = this.val.split(',');
            this.saveMolyVal(carr[0], 'Street', this.street1.value, this.formid, cdate);
            this.saveMolyVal(carr[1], 'Street', this.street2.value, this.formid, cdate);
            this.saveMolyVal(carr[2], 'City', this.city.value, this.formid, cdate);
            this.saveMolyVal(carr[3], 'State', this.state.value, this.formid, cdate);
            this.saveMolyVal(carr[4], 'Zip', this.zip.value, this.formid, cdate);
            this.saveMolyVal(carr[5], 'Country', this.country.value, this.formid, cdate);
            this.saveMolyVal(carr[6], 'County', this.county.value, this.formid, cdate);
            this.saveMolyVal(carr[7], 'Address Type', this.adtype.value, this.formid, cdate);
            this.saveMolyVal(carr[8], 'Longitude', this.long.value, this.formid, cdate);
            this.saveMolyVal(carr[9], 'Latitude', this.lat.value, this.formid, cdate);
            this.saveMolyVal(carr[10], 'Form Description', this.note.value, this.formid, cdate);

            if (this.parent) {
                this.fs.setObjNoLog(`${this.gs.path}/${this.iduser}/data/${this.parent.u}/${cdate}`, this.parent);
            } else {
                this.saveMolyVal(this.formid, 'Address', this.val, '', cdate);
            }
        } else {
            const parent = this.fs.getId();
            this.formid = parent;
            pntr = this.saveMolyVal(this.fs.getId(), 'Street', this.street1.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'Street', this.street2.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'City', this.city.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'State', this.state.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'Zip', this.zip.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'Country', this.country.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'County', this.county.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'Address Type', this.adtype.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'Longitude', this.long.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'Latitude', this.lat.value, parent, cdate);
            pntr = pntr + ',' + this.saveMolyVal(this.fs.getId(), 'Form Description', this.note.value, parent, cdate);
            this.saveMolyVal(parent, 'Address', pntr, '', cdate);
        }

        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Do you want to validate this data?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                this.fs.setObjNoLog(`Meta/validate/${this.validator}/${this.iduser}/${cdate}`, {
                    date: cdate,
                    data: this.getId('Address'),
                    ukey: this.formid
                });
            }
            this.close.emit();

        });
    }

    onCancel() {
        this.close.emit();
    }

    onHelp(event) {
        event.stopPropagation()
        console.log('fish')
    }

}
