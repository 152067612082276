import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CappTeamMsg} from '../../services/interface.service';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-content-generic',
  templateUrl: './content-generic.component.html',
  styleUrls: ['./content-generic.component.scss']
})
export class ContentGenericComponent implements OnInit {
  @Input()
  curmsg: CappTeamMsg;

  @Input()
  i: number;

  @Output()
  delete = new EventEmitter();

  constructor(public admin: AdminService) { }

  ngOnInit() {
  }

  onDelete() {
    this.delete.emit()
  }

}
