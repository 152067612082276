import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import FroalaEditor from 'froala-editor';
import {AngularFireDatabase} from '@angular/fire/database';
import * as moment from 'moment';
import {BlockString, GSP, InterfaceService, Invitation, LangStringBlock, Rsvp, Widget} from '../../../services/interface.service';
import {Languages} from '../../../services/constants';
import {AdminService} from '../../../services/admin.service';
import {FirebaseService} from '../../../services/firebase.service';
import {AgmMap} from '@agm/core';
import {DomSanitizer} from '@angular/platform-browser';

declare var google: any;


@Component({
  selector: 'app-calevent',
  templateUrl: './calevent.component.html',
  styleUrls: ['./calevent.component.scss']
})
export class CaleventComponent implements OnInit, OnDestroy {
  @Input()
  pth: string;

  curInv: Invitation;
  invdetails = false;
  trans = false;
  invits: Invitation[];
  $sub1;
  $sub2;
  curlang: string;
  languages = Languages;
  err: string;
  res = false;
  loco = 'Orgs';
  curresource = -1;
  column = true;
  repeat = false;
  pubev = false;
  start: number;
  rsvp;
  end;
  lngg = 0;
  latt = 0;
  rsvps: Rsvp[];
  yes = 0;
  nothanks = 0;


  // todo: i can infinitely expand this
  reps = [
    'Daily',
    'Weekly',
    'Bi-weekly',
    'Same day every month',
  ];

  fields = [
    'headline',
    'body',
    'location',
    'contact',
    'sponsor'
  ];


  styles = [
    'none',
    'simple',
    'capp'
  ];

  perms = {
    'none': {
      useBody: true,
      useHead: true,
      useLoc: true,
      useContact: true,
      useSponsor: true,
      usePhoto: true,
      useLink: true,
      useGsp: true,
      useTime: true,
      useBack: true,
      useResPath: true
    },
    'simple': {
      useBody: true,
      useHead: true,
      useLoc: true,
      useContact: true,
      useSponsor: true,
      usePhoto: true,
      useLink: true,
      useGsp: true,
      useTime: true,
      useBack: true,
      useResPath: true
    },
    'capp': {
      useBody: true,
      useHead: true,
      useLoc: true,
      useContact: true,
      useSponsor: true,
      usePhoto: true,
      useLink: true,
      useGsp: true,
      useTime: true,
      useBack: true,
      useResPath: true
    }
  };

  title: string;
  field: string;
  currsvp = -1;
  img;

  useresourcetypesnocats = 'Org';
  pickcom: string;
  arr: any;
  rtsub: string;
  ctsub: string;

  options = {
    key: 'wFE7nB4D3F4C3B10B6eyznxmE2cd1rsplpfG-7c1eeF6nablA3C11B8A4D2B4F3G2F3B2==',
    enter: FroalaEditor.ENTER_DIV,
    charCounterCount: true,
    language: this.curlang,
    toolbarButtons: ['bold', 'italic', 'underline', 'outdent', 'indent', 'formatOL', 'formatUL', 'color', 'align', 'fontFamily', 'fontSize', 'html', 'insertLink']
  };

  @ViewChild(AgmMap, {static: false}) mapElement: any;


  constructor(public admin: AdminService,
              private ds: DomSanitizer,
              private titleIXdb: FirebaseService,
              private db: AngularFireDatabase,
              public is: InterfaceService) {
    this.curlang = this.admin.language;
  }

  ngOnInit() {
    if (this.pth === 'InstPri') {
      this.loco = 'Inst';
    } else {
      this.loco = this.pth;
    }

    this.$sub1 = this.is.getList(`${this.pth}/${this.admin.admin.org}/events`).subscribe(is => {
      this.invits = _.orderBy(is, [`start`], ['asc']);
    });
  }

  onDom(url) {
    return this.ds.bypassSecurityTrustUrl(url);
  }

  onEdit(i) {
    this.curInv = this.invits[i];
    console.log('curinv', this.curInv);
    this.start = this.curInv.start;
    this.end = this.curInv.end;
    if (this.curInv.rsvp) {
      this.rsvp = new Date(this.curInv.rsvp);
    }
    if (this.curInv.repeat && this.curInv.repeat.length > 0) {
      this.repeat = true;
    } else {
      this.repeat = false;
    }
    this.invdetails = true;

  }

  onChangeStart(ev) {
    // this.start = ev;
    console.log('event', new Date(ev))
    this.curInv.start = ev;
  }


  onSaveRes() {
    if (this.rtsub === 'Campus Resources') {
      this.curInv.respath = `${this.pth}/${this.admin.admin.org}/resources/${this.ctsub.toLocaleLowerCase()}/${this.arr[this.curresource].ukey}`;
    } else {
      if (this.pickcom) {
        this.curInv.respath = `Community/${this.pickcom}`;
      } else {
        this.curInv.respath = `Community/${this.arr[this.curresource].ukey}`;
      }

    }
    this.res = false;
  }

  onRepeat(event) {
    if (!this.repeat) {
      this.curInv.repeat = '';
      this.curInv.end = -1;
    } else {
      // todo 1: implement repeat
      // todo 2: need copy the implementation to Capp-ROE
      const d = new Date().setHours(8, 0, 0, 0);
      this.curInv.end = new Date(d).getTime() + 24 * 60 * 60 * 1000;
    }
  }

  onAdd() {
    this.curInv = new Invitation();
    this.curInv.sponsor = ''; // this.admin.admin.ukey; // this is used by CappTeam Program - so we can find out who the service worker is
    this.repeat = false;
    this.start = this.curInv.start;
    this.end = this.curInv.end;
    this.rsvp = this.curInv.rsvp;
    this.invdetails = true;
  }

  onDelete(i) {
    const ukey = this.invits[i].ukey;
    this.invits.splice(i, 1);
    this.is.deleteObj(`${this.pth}/${this.admin.admin.org}/events/${ukey}`, 'Calendar', 'Delete event');
    this.is.deleteObjNoLog(`Calendars/${this.admin.admin.org}/${this.curInv.ukey}`);

  }

  str_pad(n): string {
    return `00${n}`.slice(-2);
  }

  onRender() {
    if (this.field === 'headline') {
      const headeditor = new FroalaEditor('div#head', {}, () => {
      });
      this.curInv.headlineDesc[this.curlang].description = headeditor.html.get();
    }


    if (this.field === 'body') {
      const pitcheditor = new FroalaEditor('div#pitch', {}, () => {
      });
      this.curInv.pitchDesc[this.curlang].description = pitcheditor.html.get();
    }

    if (this.field === 'location') {
      const locationeditor = new FroalaEditor('div#location', {}, () => {
      });
      this.curInv.location = locationeditor.html.get();
      if (this.curInv.location) {
        this.onCalc();
      }
    }

    if (this.field === 'contact') {
      const contacteditor = new FroalaEditor('div#contact', {}, () => {
      });
      this.curInv.contact = contacteditor.html.get();
    }

    if (this.field === 'sponsor') {
      const sponsoreditor = new FroalaEditor('div#sponsor', {}, () => {
      });
      this.curInv.sponsor = sponsoreditor.html.get();
    }

    this.field = '';

    if (!this.curInv.ukey) {
      this.curInv.ukey = this.db.createPushId();
    }
  }

  onCalc() {
    if (this.mapElement) {
      this.mapElement._mapsWrapper.getNativeMap().then(map => {
        const geocoder = new google.maps.Geocoder();
        if (geocoder) {
          geocoder.geocode({'address': this.curInv.location}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.curInv.lat = results[0].geometry.location.lat();
              this.curInv.long = results[0].geometry.location.lng();
            } else {
              console.log('no good');
            }
          });
        }
      }).catch(err => console.log('no good'));
    } else {
      console.log('no good');
    }

  }

  onRsvp(i) {
    console.log('currsvp', this.invits[i])
    if (this.currsvp === -1 || this.currsvp !== i) {
      this.currsvp = i
      this.rsvps = null;
      if (this.$sub2) {
        this.$sub2.unsubscribe();
      }
      this.curInv = this.invits[i];
      console.log('rsvp, path', `${this.pth}/${this.admin.admin.org}/rsvp/${this.invits[i].ukey}`)
      this.$sub2 = this.is.getList(`${this.pth}/${this.admin.admin.org}/rsvp/${this.invits[i].ukey}`).subscribe(rs => {
        this.rsvps = rs;
        if (this.rsvps && this.rsvps.length > 0) {
          console.log('rsvps', this.rsvps.filter(r => r.attend))
          this.yes = this.rsvps.filter(r => r.attend).length;
          this.nothanks = this.rsvps.length - this.yes;

          console.log('rsvps yes', this.yes);
          console.log('no thanks', this.nothanks)
        }
      });
    }

  }

  onPhoto(event) {
    this.curInv.photo = event;
    this.img = new Image();
    this.img.src = this.curInv.photo;
    console.log('height', this.img.height)
  }

  onSave() {
    this.err = '';

    this.onRender();

    if (this.curInv.link && !this.admin.validLink(this.curInv.link)) {
      this.err = 'Not a valid url (e.g. https://...)';
    }

    if (!this.curInv.title || !this.curInv.start) {
      this.err = 'A title and start date are required';
    } else {
      if (!this.curInv.ukey) {
        this.curInv.ukey = this.titleIXdb.titleIXdb.createPushId();
      }

      if (this.curInv.photo && this.img) {
        this.curInv.height = this.img.height;
        this.curInv.width = this.img.width;
      }

      if (this.rsvp) {
        console.log('save rsvp', this.rsvp);
        this.curInv.rsvp = new Date(this.rsvp).getTime();
      }

      if (this.end) {
        this.curInv.end = new Date(this.end).getTime();
      }

      this.is.setObj(`${this.pth}/${this.admin.admin.org}/events/${this.curInv.ukey}`, this.curInv, 'Calendar', 'Add/edit event');

      this.is.deleteObjNoLog(`Calendars/${this.admin.admin.org}/${this.curInv.ukey}`);
      if (this.curInv.pubev) {
        this.is.setObjNoLog(`Calendars/${this.admin.admin.org}/${this.curInv.ukey}`, this.curInv);
      }

      this.invdetails = false;
      this.curlang = 'en';
    }

  }

  async onTransEl() {
    await this.languages.forEach(lang => {
      if (lang.abbr !== 'en') {
        this.admin.postTrans(lang.abbr, this.curInv.headlineDesc['en'].description).then(val => {
          this.curInv.headlineDesc[lang.abbr].description = val['data'].translations[0].translatedText;
        });

        this.admin.postTrans(lang.abbr, this.curInv.pitchDesc['en'].description).then(val => {
          this.curInv.pitchDesc[lang.abbr].description = val['data'].translations[0].translatedText;
        });
      }
    });

    setTimeout(() => {
      const pth = `${this.pth}/${this.admin.admin.org}/events/${this.curInv.ukey}`;
      this.is.setObjNoLog(pth, this.curInv);
    }, 3000);

  }

  onLangChange(event) {
    this.curlang = this.languages[event].abbr;
    const editor = new FroalaEditor('div#head', {}, () => {
    });
    if (!(editor && editor.length === 0)) {
      editor.html.set(this.curInv.headlineDesc[this.curlang].description);
    }

    const learneditor = new FroalaEditor('div#body', {}, () => {
    });
    if (!(learneditor && learneditor.length === 0)) {
      learneditor.html.set(this.curInv.pitchDesc[this.curlang].description);
    }

  }


  onCancel() {
    this.curlang = 'en';
    this.invdetails = false;
  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
  }

}
