import { Injectable } from '@angular/core';
import { take, map, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import * as ngeohash from 'ngeohash';
import {AngularFireDatabase} from '@angular/fire/database';
import {InterfaceService, Wish} from './interface.service';

interface LatLng {
  latitude: number;
  longitude: number;
}


@Injectable({
  providedIn: 'root'
})
export class GeoArticlesService {

  usedGeoHashes: string[];


  constructor(private db: AngularFireDatabase,
              private is: InterfaceService) { }

  async getusedGeoHashes(): Promise<void> {
    if (!this.usedGeoHashes) {
      await new Promise<void>((resolve, reject) => {
        this.is.getList(`HashArticlesArray`).pipe(take(1)).subscribe({
          next: list => {
            this.usedGeoHashes = list;
            console.log('used', this.usedGeoHashes.length)
            resolve();
          },
          error: reject
        });
      });
    }
  }

  /**
   * Returns all 9-character geohashes within a given radius of a latitude and longitude.
   */
  private geohashesWithinRadius(center: LatLng, radius: number): Observable<string[]> {
    const radiusInMeters = radius * 1609.34; // Convert miles to meters
    const degreesPerMeterLatitude = 1 / 111000; // Approximately 111 km per degree latitude

    // Convert radius in meters to degrees
    const radiusDegreesLatitude = radiusInMeters * degreesPerMeterLatitude;
    const radiusDegreesLongitude = radiusDegreesLatitude / Math.cos(center.latitude * Math.PI / 180);

    // Calculate steps needed to cover the radius
    const stepsLat = Math.ceil(radiusDegreesLatitude / (4.77 / 1000)); // Convert 4.77 meters to degrees
    const stepsLon = Math.ceil(radiusDegreesLongitude / (4.77 / 1000)); // Convert 4.77 meters to degrees

    const centralGeohash = ngeohash.encode(center.latitude, center.longitude, 6);
    let geohashes: string[] = [centralGeohash];

    // Generate geohashes in a grid around the center
    for (let latSteps = -stepsLat; latSteps <= stepsLat; latSteps++) {
      for (let lonSteps = -stepsLon; lonSteps <= stepsLon; lonSteps++) {
        const neighbor = ngeohash.encode(
          center.latitude + latSteps * (4.77 / 1000),
          center.longitude + lonSteps * (4.77 / 1000),
          6
        );
        if (!geohashes.includes(neighbor)) {
          geohashes.push(neighbor);
        }
      }
    }

    return of([...new Set(geohashes)]);
  }

  getBoundaryGeohashes(geohashes: string[]): { first: string, last: string } {
    const sortedGeohashes = geohashes.sort();
    return {
      first: sortedGeohashes[0],
      last: sortedGeohashes[sortedGeohashes.length - 1]
    };
  }

  filterArticlesByGeohash(articleLists: any[], validGeohashes: string[]): any[] {
    return articleLists.filter(article => validGeohashes.includes(articleLists['ux']));
  }

  /**
   * Fetches articles for all geohashes within a radius from the specified center.
   */


  public fetchArticlesForGeohashesOld(center: LatLng, radius: number): Observable<any[]> {
    console.log('START', center, radius)
    return this.geohashesWithinRadius(center, radius).pipe(
      take(1),
      switchMap(geohashes => {
        const { first, last } = this.getBoundaryGeohashes(geohashes);
        return this.db.list<any>(`HashArticles`, ref =>
          ref.orderByKey().startAt(first).endAt(last)
        ).valueChanges().pipe(
          take(1),
          map(articleLists => {
            console.log('articleLists', articleLists.length)
            // Assuming articleLists is an array of ArticleList
            const arr = this.filterArticlesByGeohash(articleLists, geohashes)

            console.log('arr', arr.length)
            // Flatten the array of article lists into a single array of articles
            return arr.reduce((acc, curr) => [...acc, ...curr], []);
          }),
          catchError(error => {
            console.error('Error fetching articles:', error);
            return of([]); // Return an empty array on error
          })
        );
      })
    );
  }


  public async fetchArticlesForGeohashes(center: any, radius: number): Promise<Observable<Wish[][]>> {
    await this.getusedGeoHashes();

    return this.geohashesWithinRadius(center, radius).pipe(
      mergeMap(geohashes => {
        const fetchGeohashes = geohashes.filter(gh => this.usedGeoHashes.includes(gh));

        if (fetchGeohashes.length === 0) {
          return of([]); // Return an empty observable array if no geohashes to fetch
        }

        // Create an array of Observables for each geohash
        const observables: Observable<Wish[]>[] = fetchGeohashes.map(gh => {
          return this.db.list<Wish>(`HashArticles/${gh}`).valueChanges().pipe(take(1));
        });

        // Combine all Observables into one
        return forkJoin(observables).pipe(
          map(articleArrays => articleArrays.filter(articlesAtHash => articlesAtHash.length > 0))
        );
      }),
      catchError(error => {
        console.error('Error fetching articles:', error);
        return of([]); // Return an empty array on error
      })
    );
  }
}
