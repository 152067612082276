import {Component, OnInit} from '@angular/core';
import {InterfaceService, Key, LangStringBlock, PlaceLoc, PlaceObj, Wish} from '../services/interface.service';
import {RoehttpService} from '../services/roehttp.service';
import * as _ from 'lodash';
import * as geohash from 'ngeohash';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AdminService} from '../services/admin.service';

declare var google: any;

@Component({
  selector: 'app-festivals',
  templateUrl: './festivals.component.html',
  styleUrls: ['./festivals.component.scss']
})
export class FestivalsComponent implements OnInit {

  curfestival: any;
  address: string;
  festivalyear: number;
  festivalstart: number;
  festivalend: number;
  festivaltimeoffsethours = 0;
  adddate = false;
  addvenue = false;
  addyear = false;
  addphoto = false;
  err: string;
  loc: string;
  clipped: PlaceObj;
  curevent: Wish;
  ukey: string;
  fullname: string;
  countryrequired: string;
  countryname: string;
  curPhoto: any;

  location: any;
  wikiurl: string;
  briturl: string;
  googleurl: string;

  hit;
  jumpto = '';
  includein = false;

  addloc = false;
  adding: string;
  aboutit = false;
  kingdomvar = '';
  phylumvar = '';
  classvar = '';
  addingtrip: boolean;

  phylumarr = ['Festival', 'Fair', 'Carnival', 'Parade', 'Exhibition'];

  arr: any;
  classarr = [
    {phylum: 'Festival', class: 'Art'},
    {phylum: 'Festival', class: 'Balloon'},
    {phylum: 'Festival', class: 'Beer'},
    {phylum: 'Festival', class: 'Cabaret'},
    {phylum: 'Festival', class: 'Comedy'},
    {phylum: 'Festival', class: 'Cultural'},
    {phylum: 'Festival', class: 'Dance'},
    {phylum: 'Festival', class: 'Fall'},
    {phylum: 'Festival', class: 'Film'},
    {phylum: 'Festival', class: 'Fire'},
    {phylum: 'Festival', class: 'Food'},
    {phylum: 'Festival', class: 'Food & Spirits'},
    {phylum: 'Festival', class: 'Food & Wine'},
    {phylum: 'Festival', class: 'Harvest'},
    {phylum: 'Festival', class: 'Kite'},
    {phylum: 'Festival', class: 'Music'},
    {phylum: 'Festival', class: 'Nature'},
    {phylum: 'Festival', class: 'Other'},
    {phylum: 'Festival', class: 'Poetry'},
    {phylum: 'Festival', class: 'Religious'},
    {phylum: 'Festival', class: 'Renaissance Fair'},
    {phylum: 'Festival', class: 'Spirits'},
    {phylum: 'Festival', class: 'Sports'},
    {phylum: 'Festival', class: 'Street'},
    {phylum: 'Festival', class: 'Wellness'},
    {phylum: 'Festival', class: 'Wine'},
    {phylum: 'Festival', class: 'Winter'},
    {phylum: 'Fair', class: 'County Fair'},
    {phylum: 'Fair', class: 'Other'},
    {phylum: 'Fair', class: 'Renaissance Fair'},
    {phylum: 'Fair', class: 'State Fair'},
    {phylum: 'Parade', class: 'Memorial Day'},
    {phylum: 'Parade', class: 'Other'},
    {phylum: 'Fashion', class: 'Other'},
    {phylum: 'Exhibition', class: 'Boat'},
    {phylum: 'Exhibition', class: 'Car'},
    {phylum: 'Exhibition', class: 'Fashion'},
    {phylum: 'Exhibition', class: 'Consumer Electronics'},
    {phylum: 'Exhibition', class: 'Other'},
  ];


  // store dates in events: Wish[]
  // date, findate, name (year)
  // store venues in mentions:
  // placeobj[];
  // store photos in photos: GooglePhoto[]: who, url, caption: string;

  // how do i store them? i can use an eventhash and then an events queue then delete old event from the events queue and use the info
  // there to delete the eventhash; but dont i want to display event happening NOW????


  constructor(
    private http: RoehttpService,
    public admin: AdminService,
    private is: InterfaceService) {
  }

  ngOnInit(): void {
    this.onAddFestivals();
  }

  // todo
  onEditFestival() {
    // this.ticketurl = '';
  }

  onOpenWebSite() {
    // todo: check if this.curfestival['moreinfo']['website'] is a valid website
    if (this.curfestival && this.curfestival['moreinfo'] && this.curfestival['moreinfo']['website']) {
      window.open(this.curfestival['moreinfo']['website'], '_blank');
    }
  }

  onPhylumChange() {
    this.arr = this.classarr.filter(c => c['phylum'] === this.curfestival['taxonomy']['phylum']).map(res => res['class']);
  }

  onDeleteFestival() {
    this.is.deleteObjNoLog(`Topics/TempInfo/Festivals/${this.curfestival.ukey}`);
    this.curfestival = null;
    this.onAddFestivals();
  }

  onSkip() {
    this.is.setObjNoLog(`Topics/TempInfo/FestivalsSkip/${this.curfestival.ukey}`, this.curfestival);
    this.is.deleteObjNoLog(`Topics/TempInfo/Festivals/${this.curfestival.ukey}`);
    this.curfestival = null;
    this.onAddFestivals();
  }

  onAddFestivals() {
    if (!this.curfestival) {
      this.is.getFirst('Topics/TempInfo/Festivals', 1).take(1).subscribe(fest => {
        this.curfestival = fest[0] as Wish;
        if (!this.curfestival.fullmsg) {
          this.curfestival.fullmsg = new LangStringBlock();
        }
        this.curfestival.moreinfo['ticketurl'] = '';
        this.onPhylumChange();
        console.log('curgestival', this.curfestival);
      });
    }
  }

  onSaveFestival() {
    // todo: need to do error checks that all the fields are filled out properly
    this.is.setObjNoLog(`Topics/Festivals/${this.curfestival.ukey}`, this.curfestival);
    this.is.deleteObjNoLog(`Topics/TempInfo/Festivals/${this.curfestival.ukey}`);
    this.curfestival = null;
    this.onAddFestivals();

  }

  onAddphoto() {
    this.addphoto = true;
  }


  onAddVenue() {
    this.addvenue = true;
    this.clipped = new PlaceObj();
  }

  onDeleteFestivals(i: number) {


  }

  onSaveYear() {
    console.log('onSaveYea 1r');
  }


  onSavePhoto() {

  }

  onFesChangeStart(event: any) {
    console.log('event', event);
    //this.start = event;
    this.curevent.start = new Date(event).getTime();
  }

  onFesChangeEnd(event: any) {
    // this.end = event;
    this.curevent.findate = new Date(event).getTime();
  }

  onAddYear() {
    this.curevent = new Wish('', null);
    this.curevent.start = new Date().getTime();
    this.curevent.findate = new Date().getTime();
    this.addyear = true;
  }

  onClipBoard(who: string) {
    this.clipped = null;
    navigator.clipboard.readText().then(val => {
      if (val) {
        try {
          this.clipped = JSON.parse(val);
          this.onFinishBoard(who);
        } catch (error) {
          console.error('Error parsing JSON:', error);
          // Handle the error here
        }
      }
    });
  }

  async onSavePlace(caller: string) {
    const newclipped = _.cloneDeep(this.clipped);

    const geoappify = await new Promise<any>(resolve =>
      this.http.getGeoApify(newclipped.lat, newclipped.long).take(1)
        .subscribe(translated => {
          resolve(translated);
        }));

    if (geoappify && geoappify.features && geoappify.features[0] && geoappify.features[0].properties) {
      console.log('geoappify', geoappify);
      const hit = {} as {
        about: boolean,
        propogate: boolean,
        showonmap: boolean,
        loc: string,
        ukey: string,
        objectID: string,
        hash: string,
        name: string,
        long: number,
        lat: number,
        country: string
      };
      hit['about'] = false;
      hit['propogate'] = false;
      hit['showonmap'] = false;
      hit['lat'] = newclipped.lat;
      hit['long'] = newclipped.long;
      hit['name'] = newclipped.name;

      let hash;
      if (newclipped.long && newclipped.lat) {
        hash = geohash.encode(newclipped.lat, newclipped.long);
        hit['hash'] = hash;
      }

      hit['objectID'] = newclipped.datapid;
      hit['ukey'] = newclipped.datapid;
      this.is.setObjNoLog(`Places/${newclipped.datapid}/ukey`, newclipped.datapid);  // ukey
      this.is.setObjNoLog(`Places/${newclipped.datapid}/name`, newclipped.name);  // name

      if (newclipped.address) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/address`, newclipped.address);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/address`, newclipped.address); // address
      }

      if (newclipped.long) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/long`, newclipped.long);
      }

      if (newclipped.lat) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/lat`, newclipped.lat);
      }

      if (hash) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/hash`, hash);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/hash`, hash);  // hash
      }

      const key = new Key();
      key.id = newclipped.datapid;
      key.name = 'Google';
      this.is.setObjNoLog(`Places/${newclipped.datapid}/location/keys/0`, key);

      if (geoappify.features[0] && geoappify.features[0].bbox) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/bounds`, geoappify.features[0].bbox);
      }

      const openstreetmap = geoappify.features[0].properties;
      this.is.setObjNoLog(`Places/${newclipped.datapid}/openstreetmap`, openstreetmap);
      if (openstreetmap.place_id) {
        const newkey = new Key();
        newkey.id = openstreetmap.place_id;
        newkey.name = 'Open Street Map';
        this.is.pushObj(`Places/${newclipped.datapid}/location/keys`, newkey);
      }

      let country;
      if (openstreetmap.country_code) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/countrycode`, openstreetmap.country_code.toUpperCase());
        country = openstreetmap.country_code.toUpperCase();
      }
      let state;
      if (openstreetmap.state) {
        state = openstreetmap.state;
      }
      let city;
      if (openstreetmap.city) {
        city = openstreetmap.city;
      }
      if (country && country === 'US') {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/country`, openstreetmap.state);
        let loc;
        let newlocation;
        if (city && state) {
          loc = `${city}, ${state}`;
          newlocation = `${city}, ${state}`;
        } else if (city && country) {
          loc = `${state}, ${country}`;
          newlocation = `${state}, ${country}`;
        }

        hit['country'] = openstreetmap.state;

        if (loc) {
          this.is.setObjNoLog(`Places/${newclipped.datapid}/location/loc`, loc);
          hit['loc'] = this.location['loc'];
        }
        if (newlocation) {
          this.is.setObjNoLog(`Places/${newclipped.datapid}/location/location`, newlocation);
        }

      } else {
        // todo: what the fuch
        hit['country'] = openstreetmap.country;
        const newlocation = `${city}, ${country}`;
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/country`, openstreetmap.country);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/loc`, `${city}, ${country}`);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/location`, `${city}, ${country}`);
      }

      if (newclipped.description && newclipped.description.length) {
        const desc = new LangStringBlock();
        desc.en.description = newclipped.description;
        this.is.setObjNoLog(`Places/${newclipped.datapid}/descriptions/0/desc`, desc);

        if (newclipped.descriptionsourcelink && newclipped.descriptionsourcelink.length) {
          this.is.setObjNoLog(`Places/${newclipped.datapid}/descriptions/0/source`, newclipped.descriptionsourcelink);
        }

      }

      if (newclipped.images) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/photos/0`, {url: newclipped.images, who: 'Google'}); // address
      }

      if (this.wikiurl) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/Wikipedia`, this.wikiurl); // website
      }
      if (this.briturl) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/Britanica`, this.briturl); // website
      }
      if (this.googleurl) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/GoogleSearch`, this.googleurl); // website
      }

      this.is.setObjNoLog(`PlaceExists/${newclipped.datapid}`, {code: 'NLG', ukey: newclipped.datapid});

      // todo: what to do with hit????

      this.clipped = null;

      return hit;

    } else {
      this.clipped = null;
      return null;
    }
  }

  onCancelLoc() {
    this.addloc = false;
  }

  async onSaveLocation() {
    this.err = '';
    if (this.location && (!this.countryname || this.countryname.length === 0)) {
      console.log('error');
      this.err = 'Please enter the country.';
      return;
    }

    let hit;
    if (this.location) {
      // means that this venue is already in the databsae
      hit = {
        aboutit: false,
        country: this.countryname,
        hash: this.location['hash'],
        lat: this.location['lat'],
        long: this.location['long'],
        name: this.clipped.name,
        objectID: this.ukey,
        ukey: this.ukey,
        loc: this.loc,
        propogate: false,
        showonmap: false
      };
    } else {
      await this.onSavePlace('location').then(res => {
        hit = res;
      });
      //this.onSaveClipped('mention', param);
    }

    if (hit) {
      if (!this.curfestival.trip) {
        this.curfestival.trip = [];
      }
      this.curfestival.trip.push(hit);
      console.log(this.curfestival.trip);

      this.onCancelLoc();
    } else {
      this.err = 'This place cannot be added to Locations. Please click Cancel and try again.';
    }
  }

  onClear() {
    this.countryname = '';
    this.location = null;
    this.ukey = '';
    this.clipped = null;
  }


  async onSaveVenue() {
    this.err = '';
    if (this.location && (!this.countryname || this.countryname.length === 0)) {
      console.log('error');
      this.err = 'Please enter the country.';
      return;
    }

    this.addvenue = false;
    let hit;
    if (this.location) {
      // means that this venue is already in the databsae
      hit = {
        aboutit: false,
        country: this.countryname,
        hash: this.location['hash'],
        lat: this.location['lat'],
        long: this.location['long'],
        name: this.clipped.name,
        objectID: this.ukey,
        ukey: this.ukey,
        loc: this.location['loc'],
        propogate: false,
        showonmap: false
      };

      if (this.clipped.description && this.clipped.description.length) {
        const desc = new LangStringBlock();
        desc.en.description = this.clipped.description;
        this.is.setObjNoLog(`Places/${this.ukey}/descriptions/0/desc`, desc);

        if (this.clipped.descriptionsourcelink && this.clipped.descriptionsourcelink.length) {
          this.is.setObjNoLog(`Places/${this.ukey}/descriptions/0/source`, this.clipped.descriptionsourcelink);
        }

      }

    } else {
      await this.onSavePlace('mention').then(res => {
        hit = res;
      });
    }

    if (hit) {
      if (!this.curfestival.mentions) {
        this.curfestival.mentions = [];
      }

      this.curfestival.mentions.push(hit);
      this.onClear();
      console.log('MENTIONS', this.curfestival.mentions);
    } else {
      this.err = 'This place cannot be added to Venues. Please click Cancel and try again.';
    }

  }

  onDeleteVenue(i: number) {
    this.curfestival.mentions.splice(i, 1);
    console.log('Delete venue');
  }

  onGetHitPlace(plid: string, clipped?: any) {
    this.ukey = plid;
    this.location = null;
    this.is.getObject(`Places/${this.ukey}/location`).take(1).subscribe(location => {
      if (location) {
        this.location = location;
        this.loc = this.location.loc;
        this.countryname = this.location.country;
        console.log('location', this.location);
      }
    });

  }

  onAddDate() {
    this.adddate = true;
  }

  onDeleteDate(i: number) {
    this.curfestival.events.splice(i, 1);
  }

  onCancelDate() {
    this.curevent = null;
    this.addyear = false;
  }

  onSaveDate() {
    // todo: put in date checking stuff
    if (!this.curfestival.events) {
      this.curfestival.events = [];
    }
    this.curfestival.events.push(this.curevent);
    this.curfestival.events = _.orderBy(this.curfestival.events, ['start'], 'asc');
    console.log('EVENTS', this.curfestival.events);
    this.onCancelDate();
  }

  onFinishBoard(who: string) {
    if (!this.clipped || !this.clipped.datapid) {
      alert('bad data in clipboard');
      this.clipped = null;
    } else {
      if (!this.ukey || this.clipped.datapid !== this.ukey) {
        this.ukey = this.clipped.datapid;
      }
      this.is.getObject(`PlaceExists/${this.ukey}/ukey`).take(1).subscribe(ukey => {
        if (ukey && ukey.includes('alais')) {
          const key = ukey.replace('alais', '');
          this.ukey = key;
          this.onGetHitPlace(key, true);
        } else {
          this.onGetHitPlace(this.ukey, true);
        }
      });
    }
  }

  onAddImage() {
    //photo = {who: this.photocredit, caption, url: this.curfestival.e};
    this.curPhoto = {
      who: '',
      caption: '',
      url: ''
    };
    this.addphoto = true;
  }

  onCancelImage() {
    this.addphoto = false;
    this.curPhoto = null;
  }

  onSaveImage() {
    // todo: check to make sure the url is valid html
    this.curPhoto;
    if (!this.curfestival.photos) {
      this.curfestival.photos = [];
    }
    this.curfestival.photos.push(this.curPhoto);
    this.onCancelImage();
  }

  dropPhotos(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curfestival.photos, event.previousIndex, event.currentIndex);
  }

  onDeletePhoto(i: number) {
    this.curfestival.photos.splice(i, 1);
  }

  dropvenues(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curfestival.mentions, event.previousIndex, event.currentIndex);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curfestival.trip, event.previousIndex, event.currentIndex);
  }

  onOpen(ukey: string) {
    window.open(`https://www.google.com/maps/place/?q=place_id:${ukey}`, '_blank');
  }

  onDeleteLoc(i: number, addtrip: boolean) {
    if (addtrip) {
      this.curfestival.pops.splice(i, 1);
    } else {
      this.curfestival.trip.splice(i, 1);
    }
  }

  onAddLoc() {
    this.loc = '';
    this.countryname = '';
    this.aboutit = false;
    this.countryrequired = '';
    this.ukey = null;
    this.kingdomvar = '';
    this.phylumvar = '';
    this.classvar = '';

    this.addloc = true;
  }


  placeExists(obj, isPOI) {
    // need to calculate the code
    let code = '';
    if (obj['name']) {
      code = 'N';
    }
    if (obj['location']) {
      code = 'NL';
    }
    if (obj['location'] && obj['location']['lat']) {
      code = 'NLG';
    }
    if (obj['location'] && obj['location']['lat'] && obj['location']['bounds']) {
      code = code + 'LGB';
    }
    if (obj['chain']) {
      code = code + 'C';
    }


    if (!isPOI) {
      // TODO: the X means that later I need to revisit the ukey and add the locality to the GEO database as well as get the chains
      // when i am done, i need to removed the X from code and add a C (indicating chain updates)
      code = code + 'X';
    }

    if (obj['ukey']) {
      this.is.setObjNoLog(`PlaceExists/${obj['ukey']}`, {code, ukey: obj['ukey']});
    }
  }

  saveMoreObj(ukey, obj) {

    if (obj.weburl) {
      this.is.setObjNoLog(`Places/${ukey}/moreinfo/website`, obj.weburl);
    }

    if (obj.wikiurl) {
      this.is.setObjNoLog(`Places/${ukey}/moreinfo/Wikipedia`, obj.wikiurl); // website
    }
    if (obj.briturl) {
      this.is.setObjNoLog(`Places/${ukey}/moreinfo/Britanica`, obj.briturl); // website
    }
    if (obj.googleurl) {
      this.is.setObjNoLog(`Places/${ukey}/moreinfo/GoogleSearch`, obj.googleurl); // website
    }

    if (obj.altnames) {
      this.is.setObjNoLog(`Places/${ukey}/altnames`, obj.altnames); // website
    }


  }

  savePlaceObj(obj, place_id, isPOI, moreobj) {
    this.is.setObjNoLog(`Topics/TempInfo/fixplaces/${place_id}`, obj)
  }


  onLocation(trip: boolean) {
    this.address = '';

    // if it's not already in Places
    const request = {
      placeId: this.ukey,
      fields: [
        'place_id',
        'address_components',
        'formatted_address',
        'name',
        'types',
        'geometry',
        'utc_offset_minutes',
        'photos',
        'website'
      ]
    };


    const service = new google.maps.places.PlacesService($('#service-helper').get(0));
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (place.place_id !== this.ukey) {
          console.log('error');
        } else {
          console.log('place id: ', place.place_id);
        }

        // 1. create a PlaceLoc
        const newloc = new PlaceLoc();
        this.address = place.formatted_address;
        const country = this.admin.getCountry(place.address_components, true);
        const state = this.admin.getState(place.address_components, true);
        const city = this.admin.getCity(place.address_components, true);
        if (country === 'US') {
          if (city) {
            newloc.loc = `${city}, ${state}`;
            newloc.location = `${city}, ${state}`;
          } else {
            newloc.loc = `${state}, ${country}`;
            newloc.location = `${state}, ${country}`;
          }
        } else {
          newloc.loc = `${city}, ${country}`;
          newloc.location = `${city}, ${country}`;
        }

        newloc.long = place.geometry.location.lng();
        newloc.lat = place.geometry.location.lat();
        if (place.geometry.bounds) {
          newloc.bounds = place.geometry.bounds;
        }
        if (place.geometry.viewport) {
          newloc.viewport = place.geometry.viewport;
        }
        newloc.hash = geohash.encode(newloc.lat, newloc.long);
        newloc.address = place.formatted_address; // this is an address
        newloc.utc_offset_minutes = place.utc_offset_minutes;
        newloc.keys = [];
        const key = new Key();
        key.id = place.place_id;
        key.name = 'Google';
        newloc.keys.push(key);
        let website = '';
        if (place.website) {
          website = place.website;
        }

        let type = '';
        if (place.types) {
          if (place.types[0].includes('sublocality') || place.types[0].includes('neighborhood')) {
            type = 'neighborhood';
          } else if (place.types[0].includes('locality')) {
            type = 'locality';
          } else if (place.types[0].includes('country')) {
            type = 'country';
          } else {
            type = 'adminreg';
          }
        }

        const hash = geohash.encode(place.geometry.location.lat(), place.geometry.location.lng());

        this.hit = {
          objectID: place.place_id,
          hash,
          ukey: place.place_id,
          name: place.name,
          long: place.geometry.location.lng(),
          lat: place.geometry.location.lat(),
          jumpto: this.jumpto,
          includein: this.includein,
          aboutit: this.aboutit
        };
        this.aboutit = false;

        // save record to Places
        if (place.photos && place.photos.length > 0) {
          const parr = [];
          let googlephotos;
          place.photos.forEach(p => {
            parr.push(p.getUrl());
          });
          this.http.getGoogleJustPhotos(parr).then(
            photosreturn => {

              const photos = Object.values(photosreturn);
              if (photos && photos.length > 0) {
                googlephotos = [];
                photos.forEach(p => {
                  googlephotos.push({url: p, who: 'Google'});
                });
                this.hit['photo'] = photos[0];
              }

              if (trip) {
                if (!this.curfestival.trip) {
                  this.curfestival.trip = []
                }
                this.curfestival.trip.push(this.hit);
              } else {
                if (!this.curfestival.mentions) {
                  this.curfestival.mentions = []
                }
                this.curfestival.mentions.push(this.hit);
                console.log('curfestical', this.curfestival)
              }

              const obj = {
                ukey: place.place_id,
                hash: newloc.hash,
                name: place.name,
                location: newloc,
                address: place.formatted_address,
                website,
                types: place.types,
                type,
                photos: googlephotos
              };

              const moreobj = {
                weburl: '',
                wikiurl: this.wikiurl,
                briturl: this.briturl,
                googleurl: this.googleurl,
                altnames: ''
              };

              console.log('obj', obj)
              console.log('moreobj', moreobj)
              this.savePlaceObj(obj, place.place_id, false, moreobj);

              this.wikiurl = '';
              this.briturl = '';
              this.googleurl = '';
            });


        } else {

          const obj = {
            ukey: place.place_id,
            hash: newloc.hash,
            name: place.name,
            address: place.formatted_address,
            location: newloc,
            types: place.types,
            type,
            website
          };

          const moreobj = {
            weburl: '',
            wikiurl: this.wikiurl,
            briturl: this.briturl,
            googleurl: this.googleurl,
            altnames: ''
          };

          console.log('obj', obj)
          console.log('moreobj', moreobj)

          this.savePlaceObj(obj, place.place_id, false, moreobj);

          this.wikiurl = '';
          this.briturl = '';
          this.googleurl = '';

          if (trip) {
            if (!this.curfestival.trip) {
              this.curfestival.trip = []
            }
            this.curfestival.trip.push(this.hit);
          } else {
            if (!this.curfestival.mentions) {
              this.curfestival.mentions = []
            }
            this.curfestival.mentions.push(this.hit);
          }

        }


        this.location = '';
        this.jumpto = '';
        this.includein = false;
        this.ukey = '';
        this.onCloseLoc();
      }
    });
  }


  onCompleteHit(loc, ukey, photo: string, type: any, trip: boolean) {

    if (!this.curfestival.trip) {
      this.curfestival.trip = [];
    }
    if (!this.curfestival.mentions) {
      this.curfestival.mentions = [];
    }

    const nm = this.location;

    let lat = loc.lat;
    if (loc && loc.lat && (typeof loc.lat === 'string')) {
      lat = parseFloat(loc.lat);
    }
    let long = loc.long;
    if (loc && loc.long && (typeof loc.long === 'string')) {
      long = parseFloat(loc.long);
    }

    this.hit = {
      objectID: ukey,
      loc: loc.loc,
      photo,
      hash: loc.hash,
      propogate: true,
      showonmap: true,
      aboutit: this.aboutit,
      jumpto: '',
      ukey,
      name: nm,
      long,
      lat,
    };

    // if it's already in Places
    if (trip) {
      if (!this.curfestival.trip) {
        this.curfestival.trip = []
      }
      this.curfestival.trip.push(this.hit);
    } else {
      if (!this.curfestival.mentions) {
        this.curfestival.mentions = []
      }
      this.curfestival.mentions.push(this.hit);
    }
    this.onCloseLoc();
  }


  onGetGooglePlace(plid: string, trip: boolean) {
    this.ukey = plid;
    this.briturl = '';
    this.googleurl = '';
    this.wikiurl = '';

    this.is.getObject(`Places/${this.ukey}`).take(1).subscribe(place => {
      if (place && place.location) {


        if (place.type && (place.type === 'POI') && place.location && place.location.name) {
          this.location = place.location.name;
        }

        if (place.moreinfo && place.moreinfo.Wikipedia) {
          this.wikiurl = place.moreinfo.Wikipedia;

          if (place.location.keys) {
            const karr = Object.values(place.location.keys) as any;
            let nowiki = true;
            karr.forEach(k => {
              if (k.name === 'wikiDataId') {
                nowiki = false;
              }
            });

            if (nowiki) {
              this.http.wikiCall(this.wikiurl, place.ukey).take(1).subscribe(ret => {
                console.log('wikiCall', this.wikiurl, place.ukey);
              });
            }
          }

        }

        if (place.moreinfo && place.moreinfo.GoogleSearch) {
          this.googleurl = place.moreinfo.GoogleSearch;
        }


        if (place.moreinfo && place.moreinfo.Britanica) {
          this.briturl = place.moreinfo.Britanica;
        }


        let photo = '';
        if ((place.moreinfo || place.guides || place.repos) && place.photos) {
          const photos = Object.values(place.photos);
          photo = photos[0]['url'];
        }

        this.onCompleteHit(place.location, this.ukey, photo, place.type, trip);
      }
    });
  }


  onFinishGoogle(trip: boolean) {
    if (!this.clipped || !this.clipped.datapid) {
      this.onCloseLoc();
      alert('The information in the clipboard is not in the proper format. Please consider completing this form manually.');
      return;
    } else {
      if (!this.ukey || this.clipped.datapid !== this.ukey) {
        if (this.location && this.location.length) {
          alert('the copied place is not the same as the location. using copied location');
        }
        this.location = this.clipped.name;
        this.ukey = this.clipped.datapid;
      }

      this.is.getObject(`PlaceExists/${this.ukey}/ukey`).take(1).subscribe(ukey => {
        if (ukey && ukey.includes('alais')) {
          const key = ukey.replace('alais', '');
          this.ukey = key;
          this.onGetGooglePlace(key, trip);
        } else {
          this.onLocation(trip);
        }
      });
    }
  }


  onCloseLoc() {
    this.adding = null;
    this.addingtrip = false;
    this.location = '';
  }

  onGoogle(trip: boolean) {
    this.clipped = null;
    navigator.clipboard.readText().then(val => {
      if (val) {
        try {
          this.clipped = JSON.parse(val);
          this.location = '';
          this.aboutit = false;
          this.ukey = null;

          this.onFinishGoogle(trip);
        } catch (error) {
          // Handle the error here
          this.onCloseLoc();
          alert('The information in the clipboard is not in the proper format. Please consider completing this form manually.');
          return;
        }
      }
    });
  }


}
