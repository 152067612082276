import { Injectable } from '@angular/core';
import RBush from 'rbush';
import {InterfaceService} from './interface.service';
import {catchError, map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';

// Define interfaces for RBush items and Firebase location data
interface RBushItem {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  id: string;
}

interface RBushWithArea extends RBushItem {
  area: number;
}

interface LocationData {
  long: number;
  lat: number;
  country?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TouristBoardIncludeService {
  private rbushIndex: RBush<RBushWithArea> = new RBush<RBushWithArea>();
  private idToAreaMap: Map<string, number> = new Map<string, number>(); // Correct initialization

  private rbushJsonUrl = 'assets/rbush.json';


  // Define a mapping of country synonyms to their standard names
  private COUNTRY_SYNONYMS: { [key: string]: string } = {
    'czechia': 'czech republic',
    'czech republic': 'czech republic',
    'south korea': 'republic of korea',
    'republic of korea': 'republic of korea',
    'viet nam': 'vietnam',
    'vietnam': 'vietnam',
    'north korea': "democratic people's republic of korea",
    "democratic people's republic of korea": "democratic people's republic of korea",
    'russia': 'russian federation',
    'russian federation': 'russian federation',
    'syria': 'syrian arab republic',
    'syrian arab republic': 'syrian arab republic',
    'brunei': 'brunei darussalam',
    'brunei darussalam': 'brunei darussalam',
    'moldova': 'republic of moldova',
    'republic of moldova': 'republic of moldova',
    'iran': 'islamic republic of iran',
    'islamic republic of iran': 'islamic republic of iran',
    'laos': "lao people's democratic republic",
    "lao people's democratic republic": "lao people's democratic republic",
    'macedonia': 'north macedonia',
    'north macedonia': 'north macedonia',
    'england': 'united kingdom',
    'wales': 'united kingdom',
    'scotland': 'united kingdom',
    'northern ireland': 'united kingdom'
    // Add more synonyms as needed
  };

  constructor(
    private http: HttpClient,
    private interfaceService: InterfaceService // Ensure this service is implemented
  ) {
    this.initializeRbush();
  }

  /**
   * Initializes the RBush spatial index by fetching and processing rbush.json.
   */
  private initializeRbush(): void {
    this.http.get<any>(this.rbushJsonUrl).pipe(take(1)).subscribe(
      (rbushData) => {
        try {
          console.log('got the date')
          const leafNodes: RBushItem[] = this.extractLeafNodes(rbushData);
          console.log(`Extracted ${leafNodes.length} leaf nodes from RBush JSON.`);

          const itemsToInsert: RBushWithArea[] = [];

          leafNodes.forEach((node) => {
            const { minX, minY, maxX, maxY, id } = node;

            // Validate latitude bounds
            if (minY > maxY) {
              console.warn(
                `Invalid latitude bounds for node ID ${id}: minY(${minY}) > maxY(${maxY}). Skipping node.`
              );
              return;
            }

            // Handle antimeridian crossing
            if (minX > maxX) {
              console.log(
                `Bounding box for node ID ${id} crosses the antimeridian. Splitting into two bounding boxes.`
              );

              // First bounding box: minX to 180
              const area1 = (180 - minX) * (maxY - minY);
              itemsToInsert.push({
                minX,
                minY,
                maxX: 180,
                maxY,
                id,
                area: area1,
              });
              this.idToAreaMap.set(id, (this.idToAreaMap.get(id) || 0) + area1);

              // Second bounding box: -180 to maxX
              const area2 = (maxX - -180) * (maxY - minY);
              itemsToInsert.push({
                minX: -180,
                minY,
                maxX,
                maxY,
                id,
                area: area2,
              });
              this.idToAreaMap.set(id, (this.idToAreaMap.get(id) || 0) + area2);
            } else {
              // Bounding box does not cross the antimeridian
              const area = (maxX - minX) * (maxY - minY);
              itemsToInsert.push({
                minX,
                minY,
                maxX,
                maxY,
                id,
                area,
              });
              this.idToAreaMap.set(id, (this.idToAreaMap.get(id) || 0) + area);
            }
          });

          console.log('itemsToInsert', itemsToInsert)
          this.rbushIndex.load(itemsToInsert);
          console.log(`RBush spatial index initialized with ${itemsToInsert.length} entries.`);
        } catch (error) {
          console.error(`Error initializing RBush index: ${error}`);
          throw error;
        }
      },
      (error) => {
        console.error(`Failed to fetch rbush.json: ${error}`);
        throw error;
      }
    );
  }

  /**
   * Recursively extracts all leaf nodes from the RBush JSON structure.
   * @param node - The current node in the RBush JSON.
   * @returns An array of RBushItem representing leaf nodes.
   */
  private extractLeafNodes(node: any): RBushItem[] {
    const leafNodes: RBushItem[] = [];
    const stack: any[] = [node];

    while (stack.length > 0) {
      const currentNode = stack.pop();
      if (currentNode.children && Array.isArray(currentNode.children)) {
        stack.push(...currentNode.children);
      } else {
        // Leaf node
        if (
          currentNode.minX !== undefined &&
          currentNode.minY !== undefined &&
          currentNode.maxX !== undefined &&
          currentNode.maxY !== undefined &&
          currentNode.id !== undefined
        ) {
          leafNodes.push({
            minX: parseFloat(currentNode.minX),
            minY: parseFloat(currentNode.minY),
            maxX: parseFloat(currentNode.maxX),
            maxY: parseFloat(currentNode.maxY),
            id: currentNode.id.toString(),
          });
        } else {
          console.warn(`Skipping node with missing fields: ${JSON.stringify(currentNode)}`);
        }
      }
    }

    return leafNodes;
  }

  /**
   * Normalizes a country name by stripping accents, converting to lowercase,
   * and mapping synonyms to their standard names.
   * @param name - The country name to normalize.
   * @returns The normalized country name.
   */
  private normalizeCountry(name: string): string {
    if (!name) {
      return 'N.A.';
    }
    const nameClean = this.stripAccents(name);
    return this.COUNTRY_SYNONYMS[nameClean] || nameClean;
  }

  /**
   * Removes accents from a given string and converts it to lowercase.
   * @param text - The text to process.
   * @returns The processed text.
   */
  private stripAccents(text: string): string {
    try {
      return text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim();
    } catch (e) {
      console.warn(`Failed to strip accents from text '${text}': ${e}`);
      return text.toLowerCase().trim();
    }
  }

  /**
   * Parses the 'id' field, which may contain multiple IDs separated by commas.
   * @param idField - The raw 'id' field from the CSV or data source.
   * @returns An array of individual IDs.
   */
  private parseIds(idField: string | null | undefined): string[] {
    if (!idField) {
      return [];
    }
    try {
      // Remove leading and trailing triple quotes and whitespace
      const idClean = idField.replace(/^["']+|["']+$/g, '').trim();
      const ids = idClean.split(',').map(id => id.trim()).filter(id => id.length > 0);
      return ids;
    } catch (e) {
      console.warn(`Failed to parse ids '${idField}': ${e}`);
      return [];
    }
  }

  /**
   * Retrieves the country for a given tourist board ID from Firebase.
   * Caches results to minimize Firebase reads.
   * @param touristBoardId - The tourist board ID.
   * @param dbRefCache - A cache map to store previously fetched countries.
   * @returns An Observable that emits the normalized country name or null.
   */
  private getTouristBoardCountry(touristBoardId: string, dbRefCache: Map<string, string | null>): Observable<string | null> {
    if (dbRefCache.has(touristBoardId)) {
      return of(dbRefCache.get(touristBoardId));
    }

    return this.interfaceService.getObject(`Institutions/${touristBoardId}/destination/country`).pipe(
      take(1),
      map(country => {
        if (country) {
          const countryNormalized = this.normalizeCountry(country);
          dbRefCache.set(touristBoardId, countryNormalized);
          console.log(`Fetched country '${countryNormalized}' for tourist board '${touristBoardId}'`);
          return countryNormalized;
        } else {
          console.warn(`No country found for tourist board '${touristBoardId}'.`);
          dbRefCache.set(touristBoardId, null);
          return null;
        }
      }),
      catchError(error => {
        console.error(`Failed to retrieve country for tourist board '${touristBoardId}': ${error}`);
        dbRefCache.set(touristBoardId, null);
        return of(null);
      })
    );
  }

  /**
   * Retrieves taxonomy data for a given place key from Firebase.
   * @param key - The unique key representing a place.
   * @returns An Observable that emits the taxonomy data as a JSON string or 'N.A.'.
   */
  private getTaxonomy(key: string): Observable<string> {
    return this.interfaceService.getObject(`Places/${key}/taxonomy`).pipe(
      take(1),
      map(taxonomyData => {
        if (taxonomyData) {
          return JSON.stringify(taxonomyData, null, 2); // Pretty print with 2-space indentation
        } else {
          console.warn(`No taxonomy found for key '${key}'.`);
          return 'N.A.';
        }
      }),
      catchError(error => {
        console.error(`Failed to retrieve taxonomy for key '${key}': ${error}`);
        return of('N.A.');
      })
    );
  }

  /**
   * Retrieves and filters tourist board IDs for a given place key (ukey).
   * Only includes tourist boards within the same country as the place.
   * @param ukey - The unique key representing a place.
   * @returns A Promise that resolves to an array of filtered tourist board IDs.
   */
  public async getFilteredTouristBoardIds(ukey: string): Promise<string[]> {
    try {
      // Step 1: Fetch location data for the place
      const locationData: LocationData | null = await this.interfaceService.getObject(`Places/${ukey}/location`).pipe(take(1)).toPromise();

      if (!locationData || typeof locationData.long !== 'number' || typeof locationData.lat !== 'number') {
        console.warn(`Invalid or missing location data for ukey '${ukey}'. Returning ['N.A.'].`);
        return ['N.A.'];
      }

      const { long: longitude, lat: latitude, country: placeCountryRaw } = locationData;
      const placeCountry = this.normalizeCountry(placeCountryRaw || 'N.A.');

      console.log(`Retrieved location for ukey '${ukey}': Longitude=${longitude}, Latitude=${latitude}, Country=${placeCountry}`);

      // Step 2: Define a point as a degenerate bounding box
      const pointBBox = {
        minX: longitude,
        minY: latitude,
        maxX: longitude,
        maxY: latitude,
      };

      // Step 3: Search for intersecting tourist board bounding boxes
      const possibleMatches: RBushWithArea[] = this.rbushIndex.search(pointBBox);

      // Step 4: Extract unique tourist board IDs
      const uniqueIds: string[] = Array.from(new Set(possibleMatches.map(item => item.id)));

      if (uniqueIds.length === 0) {
        console.log(`No containing tourist boards found for ukey '${ukey}'. Returning ['N.A.'].`);
        return ['N.A.'];
      }

      // Step 5: Fetch countries for each tourist board ID
      const dbRefCache: Map<string, string | null> = new Map();

      const countryPromises: Promise<{ id: string, country: string | null }>[]= uniqueIds.map(id => {
        return this.getTouristBoardCountry(id, dbRefCache).toPromise().then(country => ({ id, country }));
      });

      const countries = await Promise.all(countryPromises);

      // Step 6: Filter tourist board IDs based on matching country
      const filteredIds: string[] = countries
        .filter(tb => tb.country === placeCountry)
        .map(tb => tb.id);

      if (filteredIds.length === 0) {
        console.log(`No tourist boards in the same country ('${placeCountry}') for ukey '${ukey}'. Returning ['N.A.'].`);
        return ['N.A.'];
      }

      console.log(`Filtered tourist boards for ukey '${ukey}': ${filteredIds.join(', ')}`);
      return filteredIds;
    } catch (error) {
      console.error(`Error processing ukey '${ukey}': ${error}. Returning ['N.A.'].`);
      return ['N.A.'];
    }
  }

  public async getFilteredTouristBoardIdsLoc(locationData: LocationData): Promise<string[]> {
    try {
      // Step 1: Fetch location data for the place

      const { long: longitude, lat: latitude, country: placeCountryRaw } = locationData;
      const placeCountry = this.normalizeCountry(placeCountryRaw || 'N.A.');

      // Step 2: Define a point as a degenerate bounding box
      const pointBBox = {
        minX: longitude,
        minY: latitude,
        maxX: longitude,
        maxY: latitude,
      };

      // Step 3: Search for intersecting tourist board bounding boxes
      const possibleMatches: RBushWithArea[] = this.rbushIndex.search(pointBBox);

      // Step 4: Extract unique tourist board IDs
      const uniqueIds: string[] = Array.from(new Set(possibleMatches.map(item => item.id)));

      if (uniqueIds.length === 0) {
        console.log(`No containing tourist boards. Returning ['N.A.'].`);
        return ['N.A.'];
      }

      // Step 5: Fetch countries for each tourist board ID
      const dbRefCache: Map<string, string | null> = new Map();

      const countryPromises: Promise<{ id: string, country: string | null }>[]= uniqueIds.map(id => {
        return this.getTouristBoardCountry(id, dbRefCache).toPromise().then(country => ({ id, country }));
      });

      const countries = await Promise.all(countryPromises);

      // Step 6: Filter tourist board IDs based on matching country
      const filteredIds: string[] = countries
        .filter(tb => tb.country === placeCountry)
        .map(tb => tb.id);

      if (filteredIds.length === 0) {
        console.log(`No tourist boards in the same country ('${placeCountry}'). Returning ['N.A.'].`);
        return ['N.A.'];
      }

      console.log(`Filtered tourist boards: ${filteredIds.join(', ')}`);
      return filteredIds;
    } catch (error) {
      console.error(`Error processing': ${error}. Returning ['N.A.'].`);
      return ['N.A.'];
    }
  }


  /**
   * Retrieves tourist board IDs for a given unique key (ukey).
   * @param ukey - The unique key representing a place.
   * @returns A Promise that resolves to an array of tourist board IDs.
   */
  public getTouristBoardIds(ukey: string): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.interfaceService.getObject(`Places/${ukey}/location`).pipe(
        take(1)
      ).subscribe(
        (data) => {
          const locationData: LocationData | null = data;

          if (locationData && typeof locationData.long === 'number' && typeof locationData.lat === 'number') {
            const longitude = locationData.long;
            const latitude = locationData.lat;
            const country = locationData.country || 'N.A.';

            console.log(
              `Retrieved location for ukey '${ukey}': Longitude=${longitude}, Latitude=${latitude}, Country=${country}`
            );

            // Define a point as a degenerate bounding box
            const pointBBox = {
              minX: longitude,
              minY: latitude,
              maxX: longitude,
              maxY: latitude,
            };

            // Search for intersecting bounding boxes
            const possibleMatches: RBushWithArea[] = this.rbushIndex.search(pointBBox);

            // Extract unique IDs
            const uniqueIds: string[] = Array.from(new Set(possibleMatches.map((item: RBushWithArea) => item.id)));

            if (uniqueIds.length === 0) {
              console.log(`No containing tourist boards found for ukey '${ukey}'. Returning ['N.A.'].`);
              resolve(['N.A.']);
              return;
            }

            // Sort IDs based on their total area (ascending)
            uniqueIds.sort((a, b) => {
              const areaA = this.idToAreaMap.get(a) || 0;
              const areaB = this.idToAreaMap.get(b) || 0;
              return areaA - areaB;
            });

            console.log(`Found containing tourist boards for ukey '${ukey}': ${uniqueIds.join(', ')}`);
            resolve(uniqueIds);
          } else {
            console.warn(`No valid location data found for ukey '${ukey}'. Returning ['N.A.'].`);
            resolve(['N.A.']);
          }
        },
        (error) => {
          console.error(`Error processing ukey '${ukey}': ${error}. Returning ['N.A.'].`);
          resolve(['N.A.']);
        }
      );
    });
  }

  /**
   * Filters an array of tourist board IDs to include only those that match the country's place.
   * @param ukey - The unique key representing a place.
   * @param touristBoardIds - An array of tourist board IDs to filter.
   * @returns A Promise that resolves to an array of filtered tourist board IDs.
   */
  public async filterTouristBoardsByCountry(ukey: string, touristBoardIds: string[]): Promise<string[]> {
    try {

      // Step 1: Fetch location data for the place
      const locationData: LocationData | null = await this.interfaceService.getObject(`Places/${ukey}/location`).pipe(take(1)).toPromise();

      if (!locationData || typeof locationData.country !== 'string') {
        console.warn(`Invalid or missing location data for ukey '${ukey}'. Returning empty array.`);
        return [];
      }

      const placeCountry = this.normalizeCountry(locationData.country);

      console.log(`Place country for ukey '${ukey}': ${placeCountry}`);

      // Step 2: Fetch and normalize countries for each tourist board ID
      const dbRefCache: Map<string, string | null> = new Map();

      const countryPromises: Promise<{ id: string, country: string | null }>[]= touristBoardIds.map(id => {
        return this.getTouristBoardCountry(id, dbRefCache).toPromise().then(country => ({ id, country }));
      });

      const countries = await Promise.all(countryPromises);

      // Step 3: Filter tourist board IDs based on matching country
      const filteredIds: string[] = countries
        .filter(tb => tb.country === placeCountry)
        .map(tb => tb.id);

      console.log(`Filtered tourist boards for ukey '${ukey}': ${filteredIds.join(', ')}`);

      return filteredIds;
    } catch (error) {
      console.error(`Error filtering tourist boards by country for ukey '${ukey}': ${error}`);
      return [];
    }
  }
}
