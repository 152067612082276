import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {PhoneObj} from '../../services/admin.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-phone-obj',
    templateUrl: './phone-obj.component.html',
    styleUrls: ['./phone-obj.component.scss']
})
export class PhoneObjComponent implements OnInit {
    @Input()
    phns: PhoneObj[];

    @Output()
    phonesave = new EventEmitter<PhoneObj[]>();

    phonedetails = false;
    editphonedetails = -1;
    newphone: PhoneObj;


    phform = new FormGroup({
        phdescription: new FormControl(''),
        digits: new FormControl(''),
        private: new FormControl(''),
        notify: new FormControl(''),
    });

    get phdescription() {
        return this.phform.get('phdescription');
    }

    get digits() {
        return this.phform.get('digits');
    }

    get private() {
        return this.phform.get('private');
    }

    get notify() {
        return this.phform.get('notify');
    }

    constructor() {

    }

    ngOnInit() {
        if (!this.phns) {
            this.phns = [];
        }
    }

    setPhValue(tmp: PhoneObj) {
        this.phform.setValue({
            phdescription: tmp.phdescription,
            digits: tmp.digits,
            private: tmp.private,
            notify: tmp.notify
        });
    }

    onCancelPhone() {
        this.phonedetails = false;
        this.editphonedetails = -1;
    }

    onDeletePhone(i) {
        // Todo: need to ask if they are sure
        this.phns.splice(i, 1);
        this.phonesave.emit(this.phns);
    }


    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.phns, event.previousIndex, event.currentIndex);
        this.phonesave.emit(this.phns);
    }

    onEditPhone(i) {
        this.editphonedetails = i;
        this.newphone = this.phns[i];
        this.setPhValue(this.newphone);
        this.phonedetails = true;
    }

    onAddPhone() {
        this.newphone = new PhoneObj;
        this.newphone.phdescription = '';
        this.newphone.digits = '';
        this.newphone.notify = false;
        this.newphone.private = false;
        this.setPhValue(this.newphone);
        this.phonedetails = true;
    }

    onSavePhone() {
        this.newphone.phdescription = this.phdescription.value;
        this.newphone.digits = this.digits.value;
        this.newphone.private = this.private.value;
        this.newphone.notify = this.notify.value;

        if (this.editphonedetails === -1) {
            this.phns.push(this.newphone);
        } else {
            this.phns[this.editphonedetails] = this.newphone;
        }
        this.phonesave.emit(this.phns);
        this.phonedetails = false;
        this.editphonedetails = -1;
    }

}
