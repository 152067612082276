import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {FormControl, FormGroup} from '@angular/forms';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import {FirebaseService} from '../../../services/firebase.service';
import {ArchiveLibrary, ArchiveList, InterfaceService} from '../../../services/interface.service';
import {AdminService} from '../../../services/admin.service';
import {ConfirmationComponent} from '../../confirmation/confirmation.component';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'app-archives',
    templateUrl: './archives.component.html',
    styleUrls: ['./archives.component.scss']
})
export class ArchivesComponent implements OnInit, OnDestroy {
    @Input()
    loc: string;

    archives: ArchiveList[];
    tmparchives: ArchiveList[];

    $sub1;
    $sub2;
    archivedetails = false;
    file: File;
    err: string;

    foldername: string;
    folderdetails = false;
    curfolder: string;
    folders: string[];


    form = new FormGroup({
        dateofpub: new FormControl(''),
        name: new FormControl(''),
        summary: new FormControl(''),
    });

    get name() {
        return this.form.get('name');
    }

    get summary() {
        return this.form.get('summary');
    }

    get dateofpub() {
        return this.form.get('dateofpub');
    }


    constructor(private fs: FirebaseService,
                private dialog: MatDialog,
                private is: InterfaceService,
                private admin: AdminService) {
        this.curfolder = 'Main';
    }

    ngOnInit() {
        this.$sub1 = this.is.getList(`${this.loc}/${this.admin.admin.org}/archive/documents`).subscribe(al => {
            if (al.length > 0) {
                al.forEach(l => {
                    l['date'] = new Date(l.dateofpub);
                });
                this.archives = _.orderBy(al, ['date'], ['desc']);
                if (!this.curfolder || this.curfolder === 'Main') {
                    this.tmparchives = this.archives;
                    console.log('tmp', this.tmparchives)
                } else {
                    this.archives = this.archives.filter(a => a.folder === this.curfolder);
                }

            }
        });

        this.$sub2 = this.is.getList(`${this.loc}/${this.admin.admin.org}/archive/documentsfolders`).subscribe(f => {
            this.folders = f;
            this.folders.push('Main');
            this.folders = _.orderBy(this.folders);
        });
    }

    onChange(str) {
        this.curfolder = str;
        if (!this.curfolder || this.curfolder === 'Main') {
            this.tmparchives = this.archives;
        } else {
            this.archives = this.archives.filter(a => a.folder === this.curfolder);
        }

    }

    onAddArchive() {
        this.err = '';
        this.form.setValue({
            dateofpub: '',
            name: '',
            summary: '',
        });
        this.archivedetails = true;
    }

    onChangeFile(files, event) {
        this.file = files[0];
    }

    onCancel() {
        this.archivedetails = false;
    }

    onSave() {
        if (!this.name.value || !this.file) {
            this.err = 'Please complete the form.'
        } else {
            const storageRef = firebase.storage();
            const self = this;
            const arc = new ArchiveList();
            arc.ukey = this.fs.titleIXdb.createPushId();
            arc.name = this.name.value;
            arc.dateofpub = new Date(this.dateofpub.value).toISOString();
            arc.summary = this.summary.value;
            arc.datearchived = new Date().toISOString();
            arc.folder = this.curfolder;
            const tmp = this.file.name.split('.').pop();
            const filename = `${this.loc}/${this.admin.admin.org}/archive/documents/${arc.ukey}.${tmp}`;
            console.log('filename', filename);

            const archivecard = new ArchiveLibrary;
            archivecard.ukey = arc.ukey;
            archivecard.dateadded = arc.datearchived;
            archivecard.author = '';
            archivecard.pubdate = arc.dateofpub;

            archivecard.title = arc.name;
            archivecard.publication = '';
            archivecard.format = tmp;  // pdf, website
            archivecard.URL = '';
            archivecard.topic = '';
            archivecard.behavior = '';
            archivecard.population = '';

            archivecard.headline = '';
            archivecard.summary = arc.summary;
            archivecard.name = this.admin.admin.org;  // this is the name of the organization it applies to

            archivecard.target = '';
            archivecard.scope = '';
            archivecard.mustread = '';
            archivecard.costtoread = false;
            archivecard.rating = 0;  // this is the average rarting of users
            archivecard.surveyform = ''; // url to a feedback survey - NOT IMPLEMENTED

            firebase.storage().ref().child(filename).put(self.file).then(
                v => {
                    const s = `gs://library-titleix.appspot.com/${filename}`;
                    storageRef.refFromURL(s).getDownloadURL().then(url => {
                        arc.CapptivationURL = url;
                        archivecard.CapptivationURL = url;
                        self.is.setObj(`${this.loc}/${self.admin.admin.org}/archive/documents/${arc.ukey}`, arc, 'Archive', `Archived: ${arc.name}`);
                        self.is.setObj(`Archive/${arc.ukey}`, archivecard, 'Legacy', ``);
                    });
                }
            );
            this.archivedetails = false;
        }
    }


    onSaveFolder() {
        this.folderdetails = false;
        this.folders.push(this.foldername);
        const arr = this.folders.filter(f => f !== 'Main');
        this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/documentsfolders`, arr, 'Archive', 'Created graphic folder');
    }

    onAddFolder() {
        this.folderdetails = true;
        this.foldername = '';
    }

    onDeleteFolder(i) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            width: '300px',
            data: {text: 'Are you sure you want to delete this folder? Note: If you are, all files in this folder will be moved to the Main folder'}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.answer) {
                if (this.archives && this.archives.length > 0) {
                    this.archives.forEach(a => {
                        if (a.folder === this.folders[i]) {
                            a.folder = 'Main';
                            this.is.setObjNoLog(`${this.loc}/${this.admin.admin.org}/archive/documents/${a.ukey}`, a);
                        }
                    });
                    this.onChange('Main');
                }

                this.folders.splice(i, 1);
                const arr = this.folders.filter(f => f !== 'Main');
                this.is.setObj(`${this.loc}/${this.admin.admin.org}/archive/documentsfolders`, arr, 'Archive', 'Deleted folder');

            }
        });

    }



    ngOnDestroy() {
        if (this.$sub1) {
            this.$sub1.unsubscribe();
        }
        if (this.$sub2) {
            this.$sub2.unsubscribe();
        }
    }

}
