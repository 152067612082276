import { Injectable } from '@angular/core';
import {InterfaceService} from './interface.service';
import {InstService} from './inst.service';
import {OrganizationService} from './organization.service';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {

  constructor(private is: InterfaceService,
              private os: OrganizationService,
              private inst: InstService) { }

  onSaveArchiveVideo(loc: string, videos) {
    if (loc === 'Community') {
      this.is.setObjFS(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg);
    } else if (loc === 'Inst') {
      this.inst.onSaveArchiveVideos(videos);
    } else if (loc === 'Orgs') {
      this.os.onSaveArchiveVideos(videos);
    }
  }

  onSaveArchiveGraphics(loc: string, graphics) {
    if (loc === 'Community') {
      this.is.setObjFS(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg);
    } else if (loc === 'Inst') {
      this.inst.onSaveArchiveGraphics(graphics);
    } else if (loc === 'Orgs') {
      this.os.onSaveArchiveGraphics(graphics);
    }
  }

  onSaveArchiveArchive(loc: string, objs) {
    if (loc === 'Community') {
      this.is.setObjFS(`Community/${this.is.curComOrg.ukey}`, this.is.curComOrg);
    } else if (loc === 'Inst') {
      this.inst.onSaveArchiveArchive(objs);
    } else if (loc === 'Orgs') {
      console.log('obbs', objs)
      this.os.onSaveArchiveArchive(objs);
    }
  }


}
