import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ExGallery} from '../services/interface.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {AdminService} from '../services/admin.service';

@Component({
  selector: 'app-exhibit-gallery',
  templateUrl: './exhibit-gallery.component.html',
  styleUrls: ['./exhibit-gallery.component.scss']
})
export class ExhibitGalleryComponent implements OnInit, OnChanges {
  @Input()
  gallery: ExGallery;

  @Input()
  column = true;

  @Input()
  slide = false;

  ix = 0;


  public config: SwiperConfigInterface = {
    slidesPerView: 1,
    direction: 'horizontal',
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    a11y: true,
    preventClicks: true,
    preventClicksPropagation: false,
  };

  constructor(public admin: AdminService) { }

  ngOnInit() {
  }

  onGetBackground(str) {
    return 'url(' + str + ')';
  }

  onClick(event, g) {
    console.log('event', event);
    if (this.column) {
      g.cols = (g.cols + 1);
      if (g.cols === this.gallery.cols + 1) {
        g.cols = 1;
      }
    } else {
      g.rows = (g.rows + 1);
      if (g.rows === 4) {
        g.rows = 1;
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.isPointerOverContainer) {
      moveItemInArray(this.gallery.items, event.previousIndex, event.currentIndex);
    } else {
      this.gallery.items.splice(event.previousIndex, 1);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes)
  }

  onSlide(i) {
    this.slide = true;
    this.ix = i;
  }

  onClose() {
    this.slide = false
  }

}
