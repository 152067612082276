import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  CappTeamMsg,
  GSP,
  InterfaceService,
  Invitation,
  LangStringBlock,
  NotificationForm,
  OrgEdition,
  Topix
} from '../services/interface.service';
import {AdminService} from '../services/admin.service';
import * as _ from 'lodash';
import {AngularFireDatabase} from '@angular/fire/database';
import {Icons} from '../services/constants';
import {RoehttpService} from '../services/roehttp.service';
import {EditorComponent} from '../lib/editor/editor.component';
import {OrganizationService} from '../services/organization.service';


@Component({
  selector: 'app-org-notifications',
  templateUrl: './org-notifications.component.html',
  styleUrls: ['./org-notifications.component.scss']
})
export class OrgNotificationsComponent implements OnInit, OnDestroy {
  @Input()
  topics: Topix[];

  topic: Topix;


  details = false;
  notes: NotificationForm[];
  curnote: NotificationForm;
  $sub1;
  $sub2;
  title: string;
  link: string;
  body: string;
  primarycolor = '#99add0';
  social = false;
  secondarycolor = '#000000';
  icons = Icons;
  icon: string;
  curlang: string;
  tmplangDesc: LangStringBlock;
  edition: string;
  graphic: string;
  pushit = true;

  programs: GSP[];
  program: string;

  gsp = false;
  gspprograms: GSP[];
  gspprogram: string;

  invits: Invitation[];
  nshow: CappTeamMsg;


  survey: string;
  guide: string;
  report: string;
  event: string;
  widget = false;
  option: string;
  loc = 'Orgs';
  eds: OrgEdition[];
  options = ['Event',
    'Guide',
    'Report',
    'Survey'];

  @ViewChild(EditorComponent, {static: false}) editor: EditorComponent;

  // todo: shouldn't i support multiple languages here????
  // this would mean both title and topic would be LangStringBlock
  // it also means that there'd have to be different topic subscriptions based on language preference - this could be rather difficult to implement

  constructor(public is: InterfaceService,
              private og: OrganizationService,
              public admin: AdminService) {
    this.curlang = this.admin.language;
  }

  ngOnInit() {
    if (this.loc === 'Orgs') {
      this.eds = this.og.curOrg.editions;
    }

    this.is.getList(`Orgs/${this.admin.admin.org}/widgets`).take(1).subscribe(r => {
      this.gspprograms = r.filter(w => w.tag === 'gsp');
    });


    this.$sub1 = this.is.getList(`OrgsPri/${this.admin.admin.org}/notifications`).subscribe(notes => {
      this.notes = [];
      if (notes && notes.length > 0) {
        this.notes = _.orderBy(notes, ['date'], ['desc']);
      }
    });

  }

  onChangePushIt(event) {
    if (!this.pushit) {
      this.topic = null;
    } else {
      this.topic = this.topics[0];
    }
  }

  onChangeGSP(event) {
    if (!this.gsp) {
      this.gspprogram = '';
    } else {
      this.gspprogram = this.gspprograms[0].ukey;
    }
  }

  onOptionChange() {
    if (this.option === 'Survey') {
      if (!this.is.surveys || this.is.surveys.length === 0) {
        this.is.getOrgSurveys('Orgs');
      }
      return;
    }

    if (this.option === 'Guide') {
      if (!this.is.gl || this.is.gl.length === 0) {
        this.is.getOrgGuideList();
      }
    }

    if (this.option === 'Report') {
      if (!this.is.rl || this.is.rl.length === 0) {
        this.is.getOrgReportList();
      }
    }


    if (this.option === 'Event' && !this.invits) {
      this.$sub2 = this.is.getList(`Orgs/${this.admin.admin.org}/events`).subscribe(is => {
        this.invits = _.orderBy(is, [`start`], ['asc']);
      });

    }
  }

  onAdd() {
    this.curnote = new NotificationForm('', '');
    this.topic = this.topics[0];
    this.title = '';
    this.link = '';
    this.option = '';
    this.widget = false;
    this.body = '';
    this.tmplangDesc = new LangStringBlock();

    this.details = true;
  }

  onSelChange() {
    console.log('sel change');
  }

  onGSPSelChange(event) {
    this.is.getList(`Orgs/${this.admin.admin.org}/${this.gspprogram}/programs`).subscribe(p => {
      this.programs = _.orderBy(p, ['date'], ['asc']);
    });
  }

  onRescind() {
    this.is.setObjNoLog(`Notifications/${this.nshow.ukey}/admin`, this.admin.admin.firebaseuid);
    this.is.setObjNoLog(`Notifications/${this.nshow.ukey}/data/action`, 'D');
    this.is.deleteObjNoLog(`OrgsPri/${this.admin.admin.org}/notifications/${this.nshow.ukey}`);

    this.nshow = null;
  }

  onDelete(i) {
    this.is.deleteObjNoLog(`OrgsPri/${this.admin.admin.org}/notifications/${this.notes[i].ukey}`);
  }

  onSendNotification() {


    // todo: need to fix to pick All or multiple select
    this.curnote.caller = this.edition;
    this.curnote.body = this.body;

    if (this.editor) {
      this.curnote.cappteammsg.fullmsg = this.editor.onSave();
    }

    if (this.topic) {
      this.curnote.to = this.topic.ukey;
    }

    if (this.gspprogram) {
      this.curnote.gspWidgetUkey = this.gspprogram;  // for community resource this equals the name of the location
      this.curnote.gspProgram = this.program;
    }
    this.curnote.subject = this.title;
    if (this.graphic) {
      this.curnote.icon = this.graphic;
      this.curnote.cappteammsg.g = this.graphic;
      this.curnote.cappteammsg.e = this.link;
      this.curnote.cappteammsg.ty = 'ROE-G';
    } else if (this.icon) {
      this.curnote.icon = this.icon;
      this.curnote.cappteammsg.g = this.icon;
      this.curnote.cappteammsg.ty = 'ROE-A';
    } else {
      this.curnote.icon = '';
      this.curnote.cappteammsg.g = '';
      this.curnote.cappteammsg.ty = 'ROE-A';
    }

    console.log('curnote', this.curnote);


    // todo: need to implement all types of attachments (eg. Guide, Report, event, a todo item
    if (this.widget) {
      this.curnote.cappteammsg.dt = this.option;
      if (this.option === 'Survey') {
        this.curnote.cappteammsg.dl = `Orgs/${this.admin.admin.org}/surveys/${this.survey}`;  // this is the full path to an widget, event  or app
      }

      if (this.option === 'Event') {
        this.curnote.cappteammsg.dl = `Orgs/${this.admin.admin.org}/events/${this.event}`;  // this is the full path to an widget, event  or app
        const x = this.invits.findIndex(inv => inv.ukey === this.event);
        this.curnote.cappteammsg.l = this.invits[x];
        if (this.graphic) {
          this.curnote.cappteammsg.ty = 'ROE-EG';
        } else {
          this.curnote.cappteammsg.ty = 'ROE-EA';
        }
      }

      if (this.option === 'Guide') {
        this.curnote.cappteammsg.dl = `Guides/${this.guide}`;  // this is the full path to an widget, event  or app
      }

      if (this.option === 'Report') {
        this.curnote.cappteammsg.dl = `Orgs/${this.admin.admin.org}/reports/${this.report}`;  // this is the full path to an widget, event  or app
      }
    }


    this.curnote.cappteammsg.cn = 'Admin'; // this.topic;
    this.curnote.cappteammsg.s = this.title;
    this.curnote.cappteammsg.msg = this.body;
    this.curnote.cappteammsg.app = `${this.admin.admin.org}/${this.curnote.caller}`;
    console.log('curnote', this.curnote);
    console.log('curpate', `OrgsPri/${this.admin.admin.org}/notifications/${this.curnote.ukey}`);
// this save triggers this ROEPushNotification cloud function
    this.is.setObjNoLog(`OrgsPri/${this.admin.admin.org}/notifications/${this.curnote.ukey}`, this.curnote);
    this.details = false;


  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
  }
}
