import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList, SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {InterfaceService, Topix, Wish} from '../services/interface.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatBottomSheet} from '@angular/material';
import {ROAppSettingsComponent} from '../ro-lib/roapp-settings/roapp-settings.component';
import {AdminService} from '../services/admin.service';
import {RoehttpService} from '../services/roehttp.service';


@Component({
  selector: 'app-blog-guide',
  templateUrl: './blog-guide.component.html',
  styleUrls: ['./blog-guide.component.scss']
})
export class BlogGuideComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  curwish: Wish;

  @Input()
  fullsize = true;

  @Input()
  articleoff = false;

  curtopix: Topix;
  flipit = false;
  backcontent: string;
  about = '';
  hasread = -1;
  hasarchived = -1;
  hasbookmarked = -1;
  error = false;
  paid = false;
  icon = '';
  banner = '';

  kind = 'READ';
  isVideo = false;
  arrowwd = 0;
  arrowht = 0;

  full = true;
  share = false;

  toemail = '';
  yourname = '';
  msg = '';
  errormsg = '';


  @ViewChild('content', {static: false}) elementView: ElementRef;
  @ViewChild('contentback', {static: false}) elementViewBack: ElementRef;
  @ViewChildren('fullcard', {read: ElementRef}) fullcardlist: QueryList<ElementRef>;

  constructor(private cd: ChangeDetectorRef,
              private bottomSheet: MatBottomSheet,
              private http: RoehttpService,
              private admin: AdminService,
              private is: InterfaceService) {
  }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.curwish) {
      this.updateWish();
    }
  }

  updateWish() {
    this.onFixFormat();

    this.onFixFormat();

    this.paid = this.curwish['paid'];

    if (this.curwish.types) {
      this.curwish.types.forEach(t => {
        if (t === 'Paid') {
          this.paid = true;
        }

        if (t === 'Video') {
          this.icon = 'play_circle';
        } else if (t === 'Podcast') {
          this.icon = 'podcasts';
        }

        if (t === 'Deal') {
          this.banner = 'Deal! Deal! Deal!';
        } else if (t === 'Event') {
          this.banner = 'Event';
        }
      });
    }

    if (this.curwish.trip && this.curwish.trip.length) {
      this.curwish.trip.forEach(t => {
        if (t.aboutit) {
          this.about = t.ukey;
        }
      });
    }

    if (!this.about && this.curwish.mentions && this.curwish.mentions.length) {
      this.curwish.mentions.forEach(t => {
        if (t.aboutit) {
          this.about = t.ukey;
        }
      });
    }

    this.onCalcHt();
    this.cd.detectChanges();

  }

  ngAfterViewInit(): void {
    this.onCalcHt();
  }


  onShare() {
    this.toemail = '';
    this.yourname = '';
    this.msg = '';
    this.errormsg = '';
    this.share = true;
    this.flipit = !this.flipit;
    console.log('share');
  }

  onCalcHt(): number {
    let ht;

    if (!this.curwish['format'] && this.full && this.fullcardlist && this.fullcardlist.last && this.fullcardlist.last.nativeElement) {
      if (this.isVideo) {
        this.arrowwd = this.fullcardlist.last.nativeElement.offsetWidth / 2 - 40;
        const rect = this.fullcardlist.last.nativeElement.getBoundingClientRect();
        if (rect) {
          this.arrowht = (rect.height / 2) - 40;
        } else {
          this.arrowht = 150;
        }
      }

      ht = this.fullcardlist.last.nativeElement.offsetHeight;
      if (this.elementViewBack.nativeElement) {
        const x = this.elementViewBack.nativeElement.offsetHeight;

        if (x > ht) {
          ht = x;
        }
      }
    }
    return ht;
  }

  onLoad(event) {
    this.curwish['ht'] = this.onCalcHt();
    this.cd.detectChanges();
  }

  onDelete() {
    console.log('delete card');
  }

  onError(event) {
    this.error = true;
    this.curwish['ht'] = this.onCalcHt();
  }

  onFixFormat() {
    this.kind = 'READ';
    this.isVideo = false;
    if (this.curwish && this.curwish.ty && this.curwish.ty === 'podcast') {
      this.kind = 'LISTEN';
    } else if (this.curwish && this.curwish.ty && this.curwish.ty === 'vlog') {
      this.isVideo = true;
      this.kind = 'WATCH';
    } else if (this.curwish.m && !this.curwish.m.inframe) {
      this.kind = 'OPEN';
    }
  }

  resetError() {
    this.curwish['ht'] = 400;
    this.error = false;
    this.cd.detectChanges();
  }

  onSendShare() {
    // todo: is toemail a real email address
    // are all the fields filled out?
    if (!this.yourname || this.yourname.length < 2) {
      this.errormsg = 'Please tell us your name.';
    } else if (!this.toemail) {
      this.errormsg = 'Please enter a valid email address.';
    } else {

      // first check to see if more than one email;
      let goodemail = true;
      const earr = []
      if (this.toemail.includes(",")) {
        const emails = this.toemail.split(',')
        if (emails.length) {
          emails.forEach(e => earr.push(e.trim()))
          earr.forEach(e => {
            if (!this.admin.validateEmail(e)) {
              goodemail = false
              this.errormsg = 'One or more of the email address is not valid.';
            }
          })

        } else {
          this.errormsg = 'Please enter a valid email address.';
        }
      } else if (!this.admin.validateEmail(this.toemail.trim())) {
        goodemail = false;
        this.errormsg = 'Please enter a valid email address.';
      } else {
        earr.push(this.toemail.trim())
      }

      console.log(goodemail, 'earr', earr)
      // todo: eventually add a feature that connects this article to the reciever's capptivation account

      if (goodemail) {
        const tmp = this.msg;
        earr.forEach(e => {
          const obj = {
            deviceid: '1007442',
            email: e,
            from: this.yourname,
            subject: `${this.yourname} would like to share a travel-related article with you.`,
            publisher: this.curwish.cn,
            title: this.curwish.ssoc.en.description,
            imageurl: this.curwish.e,
            msg: tmp,
            link: this.curwish.l,
          };

          this.http.shareArticle(obj).take(1)
            .subscribe(res => {
              if (res && res.status === 200) {
                console.log('sent')
              } else {
                // TODO: add an notification
                console.log('failed to send')
              }
            });
        })
        this.toemail = '';
        this.msg = '';
      }


    }

  }

  onCancelShare() {
    this.share = false;
    this.flipit = !this.flipit;
  }

  onFlip(str) {
    this.backcontent = str;
    if (this.backcontent === 'blog') {
      if (this.curwish.g === 'Travelmags') {
        console.log('curgggg', this.curwish.g);
      } else {
        if (!this.articleoff && this.curwish.cf !== 'random') {
          if (!this.curtopix) {
            this.is.getObject(`Institutions/${this.curwish.cf}/obj`).take(1).subscribe(profile => {
              this.curtopix = profile;
            });
          }
          this.flipit = !this.flipit;
          this.cd.detectChanges();
        }
      }
    } else {
      this.flipit = !this.flipit;
      this.cd.detectChanges();
    }
  }

}


/*  @Input()
  curguide: Wish;

  @Input()
  fullsize = true;

  classz = 'mat-elevation-z2 guidecard';
  urlz = 'backheight';
  curlang = 'en';
  flipit = false;
  cardheight: number;
  desc: string;
  img: string;


  constructor(private is: InterfaceService, private dom: DomSanitizer) { }

  ngOnInit() {
    this.cardheight = 450;

    // this.curguide.e = this.dom.bypassSecurityTrustUrl(this.curguide.e)

    // todo: this is a total clude
    this.img = `https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Rss%2F${this.curguide.cf}%2Fcardimg.png?alt=media`


    if (this.curguide.ty === 'feed') {
      this.is.getObject(`Topics/Travelblogs/${this.curguide.cf}/description/en/description`)
        .take(1)
        .subscribe(desc => {
          this.desc = desc;
        })
/!*
      this.is.getObject(`Travelblogs/${this.curguide.cn}/description/en`)
        .take(1)
        .subscribe(desc => {
          this.desc = desc;
        })
*!/
    }
  }

  ngAfterViewInit() {
  }

  getLocs(pops: any): string {
    if (pops.length === 1) {
      return ` from ${pops[0].name}`;
    } else if (pops.length === 2) {
      return ` from ${pops[0].name} to ${pops[1].name}`
    } else {
      return '';
    }

  }

  isConnect(str): boolean {
    if (str && (str.includes('Short Trips') ||
      str.includes('Cruises') ||
      str.includes('Hiking') ||
      str.includes('Biking') ||
      str.includes('Road Trips') ||
      str.includes('Air Travel'))) {
      return true;
    } else {
      return false;
    }
  }



}*/
