import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material';
import {IconSelectComponent} from './icon-select/icon-select.component';
import {AdminService} from '../../services/admin.service';

@Component({
    selector: 'app-icons',
    templateUrl: './icons.component.html',
    styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit {
    exp: number;

    @Input()
    primarycolor: string;

    @Input()
    secondarycolor: string;

    @Input()
    icons;

    @Input()
    icon: string;

    @Output()
    change = new EventEmitter<string>();


    constructor(private admin: AdminService,
                public dialog: MatDialog) {
        this.exp = 0;
    }

    onOpen(event) {
        this.exp = 1;
    }

    ngOnInit() {
    }

    makeFileName(str: string): string {
        return `https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Assets%2Fcatalog-icons%2F${str}?alt=media`;
    }


    onCustom() {
        const dialogRef = this.dialog.open(IconSelectComponent, {
            width: '240px',
            data: {icon: this.icons[0].img}
        });

        dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.icon = result.icon;
                    this.change.emit(this.icon);
                }
            }
        );
    }


    onClick(ic, i) {
        console.log('ic', i)
        if (i === 0) {
            this.onCustom();
        } else {
            this.icon = ic;
            this.exp = 0;
            this.change.emit(this.icon);
        }
    }

}
